import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchInvoiceHeader } from '../search-header';
import { SearchInvoiceLine } from '../search-line';
import { SearchAssortment } from '../search-assortment';
import { Router, RouterModule } from '@angular/router';
import { FormGroup,FormControl,FormBuilder, Validators} from "@angular/forms";
import { CreateShipmentService } from './create-shipment.service';
import { NavigationLinkParameterService } from '../../../common/navigation-link-parameters-service';
import { SearchInvoiceResponseModel } from '../../../common/search-invoice-response-model';
import { UtilsService } from '../../../common/utils-service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-create-shipment',
  templateUrl: './create-shipment.component.html',
  styleUrls: ['../../../../assets/css/bootstrap.css', '../../../../assets/css/rds-bil-style.css', './create-shipment.component.css']
})
export class CreateShipmentComponent implements OnInit {

  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  @ViewChild('closeBtn1', { static: false }) closeBtn1: ElementRef;
  @ViewChild('modal',{static: false}) modal:any;
  // @ViewChild('confirmBtn',{ static: false}) confirmBtn: ElementRef;

  additionalInfoForm: FormGroup;
  searchInvoiceDetails: any;
  searchInvoiceLineDetails: any[] = [];
  searchInvoiceAssortment: SearchAssortment[] = [];
  cmpshpBtn=false;
  stpshpBtn=false;
  artnumber: any;
  assortData: any = {};
  confMsg:string;
  note:string;
  btnName:string;
  remarks:string='';
  responseMessage1:string;
  responseMessage:string;
  cls:string;
  userCode:string;
  assortArray: any[];
  groupCode: string;
  invoiceNumber:string;
  active:boolean = true; 

  totPayableBillAmt:boolean =false;
  extraPayout:boolean =false;
  detentionCharge:boolean =false;
  docketCharge:boolean =false;
  otherDeductionAmount:boolean =false;
  deductionAmount:boolean =false;
  enable:Boolean=true;
  billRecDate:any;
  shipmentForm: FormGroup;

  names: any;
  err: Boolean=false;
  ratePerCartoon:string;
  submitted = false;
  finalArray=[];
  checked = false;
  num:any;
  cartCountArray=[];
  lrgrCountArray=[];
  noerr:Boolean =false;
  // modalCls:string="";

  constructor(private createShipmentService: CreateShipmentService,private datePipe: DatePipe, private router: Router,
    private navigationLinkParameterService: NavigationLinkParameterService,private fb: FormBuilder,private utilsService: UtilsService,elementRef:ElementRef) { 
      
    const sessUsername = sessionStorage.getItem('currentUser');
    this.userCode=JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
      this.buildForm();
    }

  ngOnInit() {
    this.noerr=false;
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
    //this.modalCls="";
    this.searchInvoiceDetails = this.navigationLinkParameterService.createShipmentArray;
    this.navigationLinkParameterService.createShipmentArray="";
    console.log(this.searchInvoiceDetails);
    this.invoiceNumber=this.navigationLinkParameterService.invoiceID;
    console.log(this.invoiceNumber);
    this.ratePerCartoon="";
    this.createShipmentForm();
    this.num=this.searchInvoiceDetails.length;
    if(this.num>0){
      this.checked = true;
    }
    this.cartCountArray=[];
    this.lrgrCountArray=[];
    this.createShipmentService.getTransporterName().subscribe(
      (data: any) => {
        // console.log(data);
        this.names = data.transporterModels[0];
        // console.log(this.names);
        
      }
    );
    // this.viewInvoiceService.getInvoiceLineDetails(this.searchInvoiceDetails.challanInvoiceNumber).subscribe(
    //   (response: SearchInvoiceResponseModel) => {
    //     this.searchInvoiceLineDetails = response.dataList[0].invoiceLinesList;
    //     this.loaDer.nativeElement.style.display = 'none';
    //   }
    // );
    // this.searchInvoiceLineDetails = this.navigationLinkParameterService.invoiceDetails.invoiceLinesList;
    // console.log(this.searchInvoiceDetails.invStatus);
    // if(this.searchInvoiceDetails.invStatus === 'DISPATCHED' || this.searchInvoiceDetails.invStatus === 'SHIPMENT-INITIATED'){
    //   this.cmpshpBtn=true;
    // }
    // if(this.searchInvoiceDetails.invStatus === 'SHIPMENT-INITIATED' && this.searchInvoiceDetails.invoiceType !== 'DISPATCH_IMPORT'){
    //   console.log(this.searchInvoiceDetails.invoiceType);
    //   this.stpshpBtn=true;
    // }
    // this.getInvoiceDetails(this.invoiceNumber);
  }
  createShipmentForm(){
      this.shipmentForm = this.fb.group({
        transporterName: ['Please Select', [Validators.required]],
        vehicleNumber: ['', [Validators.required]],
        ratePerCarton:[''],
        // lrGrNumber:['',[Validators.required]],
        lrGrDate:['',[Validators.required]]
    });
  }
  get fval() {
    return this.shipmentForm.controls;
  }
  buildForm(): void {
    //updated by debamala dated 28-7-20
    this.additionalInfoForm = this.fb.group({
      freightAddlId:[""],
      sourceShopCode:new FormControl({value: '', disabled: true}),
      destShopCode:new FormControl({value: '', disabled: true}),
      partyNumber:new FormControl({value: '', disabled: true}),
      invoiceNumber: new FormControl({value: '', disabled: true}),
      deductionAmount: [""],
      otherDeductionAmount: [""],
      docketCharge: [""],
      detentionCharge: [""],
      extraPayout: [""],
      totPayableBillAmt: [""],
      transporterBillNo: ["", [Validators.maxLength(30)]],
      billRecDate: [""],
      remark2:["", [Validators.maxLength(200)]],
      remark1:["", [Validators.maxLength(200)]],
      createdBy: [""],
      createdDate:[""],
      lastUpdatedBy:[""],
      lastUpdatedDate:[""],
      isActive:[""]
    });
  }
  getCarton(p,invoiceId){
    console.log(p);
    console.log(invoiceId);
    let invoiceIndex = this.cartCountArray.findIndex(x => x.invoiceId ===invoiceId);
    // console.log(invoiceIndex);
    
    // console.log(invoiceIndex);
    if(invoiceIndex !== -1)
    {
      let cartonArr={
        'invoiceId':invoiceId,
        'cartCount':p
      }
      this.cartCountArray[invoiceIndex]=cartonArr;
    }
    console.log(this.cartCountArray);
  }
  getLrGrNum(p,invoiceId,destShopCode,partyNumber){
    console.log(p);
    console.log(invoiceId);
    let comb= destShopCode+partyNumber;
    let indexesOf12 = []
    // let invoiceIndex = this.lrgrCountArray.findIndex(x => x.invoiceId ===invoiceId);
    let invoiceIndex = this.lrgrCountArray.findIndex(x => x.destParty ===comb);
    this.lrgrCountArray.forEach(function(elem, index, array) {
      if (elem.destParty === comb) {indexesOf12.push(index)}
      return indexesOf12
  })
  
    console.log(indexesOf12);
    // if(invoiceIndex !== -1)
    // {
     indexesOf12.forEach(dataIndex =>{
       console.log(dataIndex);
      let lrgrArr={
        'invoiceId':invoiceId,
        'destParty':destShopCode+partyNumber,
        'lrgrNum':p
      }
      this.lrgrCountArray[dataIndex]['lrgrNum']=p;
      this.searchInvoiceDetails[dataIndex]['lrGrNumber']=p;
      
    });
    // }
    console.log(this.searchInvoiceDetails);
  }
  
  isDisabledCarton(invoice){
    //  console.log(invoice);
    // this.cartCountArray.push(invoice.cartCount);
      // if(invoice.artType === 'CONSUMABLES'){
        let cartonArr={
          'invoiceId':invoice.invoiceId,
          'cartCount':invoice.cartCount
        }
        let invoiceIndex = this.cartCountArray.findIndex(x => x.invoiceId ===invoice.invoiceId);
        if(invoiceIndex == -1){
          this.cartCountArray.push(cartonArr);
        }
        
        return false;
      // }else{
      //   return true;
      // }

      return false;
  }
  
  isDisabledLrgr(invoice){
    //  console.log(invoice);
    // this.cartCountArray.push(invoice.cartCount);
      // if(invoice.artType === 'CONSUMABLES'){
        let lrgrArr={
          'invoiceId':invoice.invoiceId,
          'destParty':invoice.destShopCode+invoice.partyNumber,
          'lrgrNum':invoice.lrGrNumber
        }
        let comb=invoice.destShopCode+invoice.partyNumber;
        let invoiceIndex = this.lrgrCountArray.findIndex(x => x.invoiceId ===invoice.invoiceId);
        // let invoiceIndex = this.lrgrCountArray.findIndex(x => x.destParty === comb);
        if(invoiceIndex == -1){
          this.lrgrCountArray.push(lrgrArr);
        }
        console.log(this.lrgrCountArray);
        return false;
      // }else{
      //   return true;
      // }
  }
  getInvoiceDetails(invoiceNumber){
    this.responseMessage="";
    let details={
      "invoiceNumber": invoiceNumber
      }
    this.createShipmentService
    .getInvoiceDetails(details)
    .subscribe(
      (response: any) => {
        if(response.status=="Success"){
          this.enable=true;
        console.log(response['freightAdditionalInfoList'][0]);
        this.searchInvoiceDetails=response['freightAdditionalInfoList'][0];
        let  dateOfYear1="";
        // const myDate = new Date(this.searchInvoiceDetails.billRecDate);
        // console.log(myDate);
        // console.log(myDate.getMonth());
        // console.log(myDate.getDate());
        // const mnth = ('0' + (myDate.getMonth() + 1)).slice(-2);
        // const day = ('0' + myDate.getDate()).slice(-2);
        //  dateOfYear1 = [day,mnth, myDate.getFullYear()].join('/');
        
        if(this.searchInvoiceDetails.billRecDate !=""){
          let d=this.searchInvoiceDetails.billRecDate.split("-");
          console.log(d);
          dateOfYear1 = [d[0],d[1], d[2]].join('/');
          // dateOfYear1 =this.datePipe.transform(this.searchInvoiceDetails.billRecDate, 'MM/dd/yyyy');
        }
        console.log(dateOfYear1);
        this.active=(this.searchInvoiceDetails.isActive=='Y')?true:false;
        this.additionalInfoForm.controls["freightAddlId"].setValue(this.searchInvoiceDetails.freightAddlId);
        this.additionalInfoForm.controls["sourceShopCode"].setValue(this.searchInvoiceDetails.sourceShopCode);
        this.additionalInfoForm.controls["destShopCode"].setValue(this.searchInvoiceDetails.destShopCode);
        this.additionalInfoForm.controls["partyNumber"].setValue(this.searchInvoiceDetails.partyNumber);
        this.additionalInfoForm.controls["invoiceNumber"].setValue(this.searchInvoiceDetails.invoiceNumber);
        this.additionalInfoForm.controls["deductionAmount"].setValue(this.searchInvoiceDetails.deductionAmount);
        this.additionalInfoForm.controls["otherDeductionAmount"].setValue(this.searchInvoiceDetails.otherDeductionAmount);
        this.additionalInfoForm.controls["docketCharge"].setValue(this.searchInvoiceDetails.docketCharge);
        this.additionalInfoForm.controls["detentionCharge"].setValue(this.searchInvoiceDetails.detentionCharge);
        this.additionalInfoForm.controls["extraPayout"].setValue(this.searchInvoiceDetails.extraPayout);
        this.additionalInfoForm.controls["totPayableBillAmt"].setValue(this.searchInvoiceDetails.totPayableBillAmt);
        this.additionalInfoForm.controls["transporterBillNo"].setValue(this.searchInvoiceDetails.transporterBillNo);
        this.additionalInfoForm.controls["billRecDate"].setValue(dateOfYear1);
        this.additionalInfoForm.controls["remark1"].setValue(this.searchInvoiceDetails.remark1);
        this.additionalInfoForm.controls["remark2"].setValue(this.searchInvoiceDetails.remark2);
        this.additionalInfoForm.controls["createdBy"].setValue(this.searchInvoiceDetails.createdBy);
        this.additionalInfoForm.controls["createdDate"].setValue(this.searchInvoiceDetails.createdDate);
        this.additionalInfoForm.controls["lastUpdatedBy"].setValue(this.userCode);
        this.additionalInfoForm.controls["lastUpdatedDate"].setValue(this.searchInvoiceDetails.lastUpdatedDate);
        this.additionalInfoForm.controls["isActive"].setValue(this.searchInvoiceDetails.isActive);
        this.billRecDate=this.searchInvoiceDetails.billRecDate;
      }else{
        this.enable=false;
        this.responseMessage="Additional information not available for this invoice.";
      }
      },
      (err) => {
        this.assortArray = [];
      }
    );
  }

  onClickBack() {
    this.navigationLinkParameterService.createShipmentArray=this.searchInvoiceDetails;
    this.router.navigate(['/rds-search-invoice']);
  }

  printInvoice() {
    this.router.navigate(['/rds-invoice']);
  }

  checkStatus(status){
    console.log(status)
  }
  dtFrmtChange(val){
    console.log(val);
    let billReciveDate =this.datePipe.transform(val, 'dd-MM-yyyy');
    console.log(billReciveDate);
    this.billRecDate=billReciveDate;
  }
  onChangeTrcode(val){
    console.log(val);
    if(val !=='Please Select'){
      this.err=false;
      this.ratePerCarton(val);
    }else{
      this.err=true;
    }
  }
  ratePerCarton(transCode){
    // this.loaDer1.nativeElement.style.display = 'block';
    this.ratePerCartoon="";
  //   const details: any = {
  //     "frightType" : "S",
  //     "transporterCode" : transCode,
  //     "exLocation" : this.shopCityName,
  //     "toLocationCode" : this.invoiceModel.destShopCode,
  //     "activeStatus" : "Y",
  //     "perCartonYn" : "Y"
  // }
  //   this.searchInvoiceService.getRatePerCarton(details).subscribe(
  //     (data: any) => {
  //       // console.log(data);
        
  //       if(data!=''){
  //         // console.log(data[0]['rateCb']);
  //         this.ratePerCartoon = data[0]['rateCb'];
  //       }
  //       //  this.ratePerCartoon = data;
  //       this.shipmentForm.controls["ratePerCarton"].setValue(this.ratePerCartoon);
  //     }
  //   );
    
  }
  createShipment(formData: any){
    // console.log(this.shipmentForm.value);
    // console.log(this.err);
    this.responseMessage="";
    
    this.submitted = true;
    this.checked = false;
    this.finalArray=[];
    console.log('called');
    // return false;
    console.log(this.shipmentForm.value);
    if(this.shipmentForm.value.transporterName == 'Please Select'){
       this.err=true;
       this.checked=true;
      return;
    }
    if (this.shipmentForm.invalid) {
      // this.err=true;
      this.checked=true;
      return;
    }else{
      this.cartCountArray.forEach((val, index) => {
        console.log(val.cartCount);
        
        if(val.cartCount == '' || val.cartCount <= 0){
          this.checked=true;
          this.responseMessage="Invoice with editatble carton number should not be blank or non numeric.";
          return false;
        }
      });
      this.lrgrCountArray.forEach((value, index) => {
        console.log(value.lrgrNum);
        
        if(value.lrgrNum == '' || value.lrgrNum == null){
          this.checked=true;
          this.responseMessage="LR GR Number should not be blank.";
          return false;
        }
      });
      // this.confirmBtn.nativeElement.click();
      if(!this.checked){
        this.noerr=true;
        //this.modalCls="in";
      }
      
      // console.log('call');
      // this.modal.open();
    }
  }
  confirmModal(operation){
    this.noerr=false;
    //this.modalCls="";
    console.log(operation);
    // return false;
    if(operation !=1){
      this.checked=true;
      return;
    }else{
      this.loaDer.nativeElement.style.display = 'block';
      this.checked=false;
    // return false;
    let i=0;
    this.searchInvoiceDetails.forEach((val, index) => {
      let indexofInvoice = this.cartCountArray.findIndex(x => x.invoiceId==val.invoiceId);
      // let indexofInvoice=this.cartCountArray[val.invoiceId];
      console.log(indexofInvoice);
      console.log(this.cartCountArray);
      let cartCount=(indexofInvoice!== -1)?this.cartCountArray[indexofInvoice].cartCount:val.cartCount;
       console.log(cartCount);
      let data: any ={
        "transporterCode":this.shipmentForm.value.transporterName,
        "lrGrNumber":val.lrGrNumber,
        "vehicleNumber":this.shipmentForm.value.vehicleNumber,
        "lrGrDate":this.datePipe.transform(this.shipmentForm.value.lrGrDate,"dd/MM/yyyy"),
        "userId":this.userCode,
        "sourceShopCode":val.sourceShopCode,
        "invoiceNumber":val.challanInvoiceNumber,
        "invoiceFlag":val.invoiceFlag,
        "invoiceIds":val.invoiceId,
        "ratePerCarton":this.shipmentForm.value.ratePerCarton,
        "cartCount":cartCount
      };
      this.finalArray.push(data);
  });
  
    console.log(this.finalArray);
    
    // return false;
    this.createShipmentService.asnCreation(this.finalArray).subscribe(
      (response: any) => {
        // console.log(JSON.stringify(response.body));
        let res=JSON.parse(response.body);
        // console.log(res.status);
        if (res.status !== 'FAILURE') {
          this.responseMessage = res.message;
          this.checked = false;
          this.navigationLinkParameterService.createShipmentArray="";
          this.navigationLinkParameterService.createShipmentMessage=res.message;
          this.loaDer.nativeElement.style.display = 'none';
          this.router.navigate(['/rds-search-invoice']);
          // this.invoiceBySearchCriteria(this.invoiceModel);
        } else {
          this.loaDer.nativeElement.style.display = 'none';
          this.responseMessage = res.message;
          this.checked = true;
        }
      //  this.invoiceBySearchCriteria(this.invoiceModel);
      }
    );
    }
  }
  forCreateShipment(state,invoiceRow){
    // console.log(state);
    // console.log(invoiceRow);
    // console.log(this.createShipmentArray);
    console.log(this.lrgrCountArray);
    let index = this.searchInvoiceDetails.findIndex(x => x.invoiceId ===invoiceRow.invoiceId);
    let index1 = this.lrgrCountArray.findIndex(x => x.invoiceId ===invoiceRow.invoiceId);
      console.log(index);
    if(state =='add'){
      if(index==-1){
        this.searchInvoiceDetails.push(invoiceRow);
      }
    }else{
      
      if(index!=-1){
        this.searchInvoiceDetails.splice(index, 1);
      }
      if(index1!=-1){
        this.lrgrCountArray.splice(index1, 1);
      }
    }
    
    console.log(this.searchInvoiceDetails);
    console.log(this.lrgrCountArray);
    this.num=this.searchInvoiceDetails.length;
    if(this.num>0){
      this.checked = true;
    }
  }
  update(){
    
    console.log(this.billRecDate);
    // this.additionalInfoForm.controls["billRecDate"].setValue(billReciveDate);
    let savePayload={
      "freightAddlId":this.searchInvoiceDetails.freightAddlId,
      "sourceShopCode":this.searchInvoiceDetails.sourceShopCode,
      "destShopCode":this.searchInvoiceDetails.destShopCode,
      "partyNumber":this.searchInvoiceDetails.partyNumber,
      "invoiceNumber": this.searchInvoiceDetails.invoiceNumber,
      "deductionAmount": this.additionalInfoForm.value.deductionAmount,
      "otherDeductionAmount":this.additionalInfoForm.value.otherDeductionAmount,
      "docketCharge": this.additionalInfoForm.value.docketCharge,
      "detentionCharge": this.additionalInfoForm.value.detentionCharge,
      "extraPayout": this.additionalInfoForm.value.extraPayout,
      "totPayableBillAmt":this.additionalInfoForm.value.totPayableBillAmt,
      "transporterBillNo": this.additionalInfoForm.value.transporterBillNo,
      "billRecDate": this.billRecDate,
      "remark2":this.additionalInfoForm.value.remark2,
      "remark1":this.additionalInfoForm.value.remark1,
      "createdBy": this.additionalInfoForm.value.createdBy,
      "createdDate":this.additionalInfoForm.value.createdDate,
      "lastUpdatedBy":this.userCode,
      "lastUpdatedDate":this.additionalInfoForm.value.lastUpdatedDate,
      "isActive":this.searchInvoiceDetails.isActive
    }
    console.log(savePayload);
    this.createShipmentService.updateAdditionalInfo(savePayload).subscribe(
      (response: any) => {
        this.loaDer.nativeElement.style.display = 'none';
        
        if(response.message == "Success"){
          this.responseMessage = "Information updated successfully";
          this.searchInvoiceDetails=savePayload;
        }else{
          this.responseMessage = "Information updatation failed";
        }
        
      }
    )
  }
  // onClickView(index) {
  //   this.searchInvoiceAssortment = [];
  //   this.searchInvoiceLineDetails[index].invoiceLineAssortments.forEach(
  //     assort => {
  //       let newObj = new SearchAssortment();
  //       newObj.artSize = assort.artSize;
  //       newObj.pairQty = assort.pairQty;
  //       this.searchInvoiceAssortment.push(newObj);
  //     }
  //   );
  // }
  activeStatus(){
    this.updateSatus('Y');
  }
  inactiveStatus(){
    this.updateSatus('N');
  }
  updateSatus(status:any){
    console.log(status);
    console.log(this.searchInvoiceDetails);
    let billReciveDate =this.datePipe.transform(this.searchInvoiceDetails.billRecDate, 'dd-MM-yyyy');
    console.log(billReciveDate);
    // this.additionalInfoForm.controls["billRecDate"].setValue(billReciveDate);
    let savePayload={
      "freightAddlId":this.searchInvoiceDetails.freightAddlId,
      "sourceShopCode":this.searchInvoiceDetails.sourceShopCode,
      "destShopCode":this.searchInvoiceDetails.destShopCode,
      "partyNumber":this.searchInvoiceDetails.partyNumber,
      "invoiceNumber": this.searchInvoiceDetails.invoiceNumber,
      "deductionAmount": this.additionalInfoForm.value.deductionAmount,
      "otherDeductionAmount":this.additionalInfoForm.value.otherDeductionAmount,
      "docketCharge": this.additionalInfoForm.value.docketCharge,
      "detentionCharge": this.additionalInfoForm.value.detentionCharge,
      "extraPayout": this.additionalInfoForm.value.extraPayout,
      "totPayableBillAmt":this.additionalInfoForm.value.totPayableBillAmt,
      "transporterBillNo": this.additionalInfoForm.value.transporterBillNo,
      "billRecDate": billReciveDate,
      "remark2":this.additionalInfoForm.value.remark2,
      "remark1":this.additionalInfoForm.value.remark1,
      "createdBy": this.additionalInfoForm.value.createdBy,
      "createdDate":this.additionalInfoForm.value.createdDate,
      "lastUpdatedBy":this.userCode,
      "lastUpdatedDate":this.additionalInfoForm.value.lastUpdatedDate,
      "isActive":status
    }
    console.log(savePayload);
    this.createShipmentService.updateAdditionalInfo(savePayload).subscribe(
      (response: any) => {
        this.loaDer.nativeElement.style.display = 'none';
        
        if(response.message == "Success"){
          this.responseMessage = "Information updated successfully";
          this.searchInvoiceDetails=savePayload;
          this.active=(status=='Y')?true:false;
        }else{
          this.responseMessage = "Information updatation failed";
        }
        
      }
    )
  }
  shipmentOperation(operation){
    console.log(operation);
    this.responseMessage1 ='';
      if(operation === 'STOP'){
        console.log('if');
        this.btnName="Stop";
        this.note="cancellation reason";
        this.confMsg="Are you sure you want to stop the shipment?";
        this.cls="fa fa-times";
      }else{
        console.log('else');
        this.btnName="Complete";
        this.note="Completeion note";
        this.confMsg="Are you sure you want to complete the shipment?";
        this.cls="fa fa-check";
      }
  }
  setASNAssortmentDataPopup(artnumber) {
    console.log(artnumber);
      this.artnumber = artnumber;
      this.assortArray = [];
      let firstletter = artnumber.charAt(0);
      console.log(this.searchInvoiceLineDetails);
      const index = this.searchInvoiceLineDetails.findIndex((x) => x.artNumber === artnumber);
      console.log(index);
      // if (index != -1) {
      //     this.assortData = this.searchInvoiceLineDetails[index];
      //     console.log(this.assortData);
      //     let details = [{ "name": "letterOfArticle", "value": firstletter }];
      //     this.createShipmentService
      //     .getSizeDetails(details)
      //     .subscribe(
      //       (response: any) => {
      //         if (response.status == "Success") {
      //           this.groupCode = response.dataList[0].groupCode;
      //           this.assortArray = [];
      //           response.dataList.forEach(data => {
      //             let assortmentArray = this.assortData.invoiceLineAssortments;
      //             let found = assortmentArray.findIndex(element => element.artSize == data.artSize);
      //             if (found !== -1) {
      //               let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWiseQty: assortmentArray[found].pairQty };
      //               this.assortArray.push(assortmentData);
      //             }
      //           });
      //         }
      //       },
      //       (err) => {
      //         this.assortArray = [];
      //       }
      //     );
      //   }
    }

    approveOrRejectStatusChange(status) {
      console.log(status);
      if (this.remarks === '') {
        if(status !== 'Complete'){
          this.responseMessage1 = "Please enter cancel reason.";
        }else{
          this.responseMessage1 = "Please enter note.";
        }
        
        
      } else {
        this.loaDer.nativeElement.style.display = "block";
        if(status === 'Complete'){
          let details = [
            {
              "userId": this.userCode,
              "invoiceIds": this.searchInvoiceDetails.invoiceId,
              "completionNote": this.remarks
            }
          ];
          this.createShipmentService.completeShipment(details).subscribe(
            (response: any) => {
              this.loaDer.nativeElement.style.display = "none";
              if (response.status === "SUCCESS") {
                setTimeout(() => {
                  
                  this.closeBtn1.nativeElement.click();
                  this.navigationLinkParameterService.searchInvoiceHeaderData.searchData='';
                  this.navigationLinkParameterService.searchInvoiceHeaderData.resultData='';
                  this.onClickBack();
                }, 2000);
              } else {
                
                this.closeBtn1.nativeElement.click();
                this.responseMessage = "Something Error Happened.";
              }
            },
            (err) => {
              
              this.closeBtn1.nativeElement.click();
              this.responseMessage = err;
              this.loaDer.nativeElement.style.display = "none";
            },
            () => {
              
              this.closeBtn1.nativeElement.click();
              this.loaDer.nativeElement.style.display = "none";
            }
          );
        }else{
          let details1 = [
            {"name":"invoiceId", "value":this.searchInvoiceDetails.invoiceId},
            {"name":"userCode","value":this.userCode},
            {"name":"challanInvoiceNumber","value":this.searchInvoiceDetails.challanInvoiceNumber },
            {"name":"cancelledReason", "value":this.remarks }
          ];
          this.createShipmentService.stopShipment(details1).subscribe(
            (response: any) => {
              console.log(response);
              this.loaDer.nativeElement.style.display = "none";
              if (response.status === "SUCCESS") {
                setTimeout(() => {
                  
                  this.closeBtn1.nativeElement.click();
                  this.navigationLinkParameterService.searchInvoiceHeaderData.searchData='';
                  this.navigationLinkParameterService.searchInvoiceHeaderData.resultData='';
                  this.onClickBack();
                }, 2000);
              } else {
                
                this.closeBtn1.nativeElement.click();
                this.responseMessage = "Something Error Happened.";
              }
            },
            (err) => {
              
              this.closeBtn1.nativeElement.click();
              this.responseMessage = err;
              this.loaDer.nativeElement.style.display = "none";
            },
            () => {
              
              this.closeBtn1.nativeElement.click();
              this.loaDer.nativeElement.style.display = "none";
            }
          );
        }
      }
    }

    numberCheck(event, name) { 
      var regExpr = /^\d+\.\d{2}$/;
      // var RegularExpression  =  new RegExp(^\d+(\.\d{1,2})?$);
      var userText = event.target.value;
      // console.log(event);
      // console.log(name);
       console.log(userText);
      this.totPayableBillAmt=false;
      this.extraPayout=false;
      this.detentionCharge=false;
      this.docketCharge=false;
      this.otherDeductionAmount=false;
      this.deductionAmount=false;
      if (userText != "") {
        var validNumber = new RegExp(/^\d*\.?\d*$/);
        if (!isNaN(userText)) {
          event.target.value = userText;
        } else {
          event.target.value ="";
            if (name == "totPayableBillAmt") {
              this.totPayableBillAmt=true;
            }
            if (name == "extraPayout") {
              this.extraPayout=true;
            }
            if (name == "detentionCharge") {
              this.detentionCharge=true;
            }
            if (name == "docketCharge") {
              this.docketCharge=true;
            }
            if (name == "otherDeductionAmount") {
              this.otherDeductionAmount=true;
            }
            if (name == "deductionAmount") {
              this.deductionAmount=true;
            }
          
        }
        if (event.target.value.indexOf('.') == -1) { return; }
        // console.log(event.target.value.length);
        // console.log(event.target.value.indexOf('.'));
        var lth=new String(userText.split(".")[1]);
        // console.log(lth.length);
    
        if (lth.length > 2) {
              // event.target.value = parseFloat(event.target.value).toFixed(2);
              event.target.value =parseFloat(userText).toFixed(2);
                
            // if (name == "totPayableBillAmt") {      
            //       this.totPayableBillAmt=true;
            // }
            // if (name == "extraPayout") {
            //   this.extraPayout=true;
            // }
            // if (name == "detentionCharge") {
            //   this.detentionCharge=true;
            // }
            // if (name == "docketCharge") {
            //   this.docketCharge=true;
            // }
            // if (name == "otherDeductionAmount") {
            //   this.otherDeductionAmount=true;
            // }
            // if (name == "deductionAmount") {
            //   this.deductionAmount=true;
            // }
          }
      }
    }

}
