/*
 * This class is replica of corresponding java enitiy class Purchase Order Lines. 
 * It is used for capturing purchase order-lines information from search-purchase order Form.
 */
import { PurchaseOrderLinesDistributions } from './po-lines-distributions';
export class PurchaseOrderLines {
     id:number;
     artNumber:string;
     artName:string;
     poArtQty:number;
     stdArtCost:number;
     artMRP:number;
     poArtPrice:number;
     shippedQty:number;
     receivedQty:number;    
     cancelledQty:number;
     deliveryEndDate:string;
     deliveryEndDateFinal:string;
     poLineStatus:string;
     suppRefNumber:number;   
     buyerId:number;   
     deliveryFromWeek:string;
     deliveryToWeek:string;
     categoryNumber:number;
     categoryName:string;
     delvStdDate:string;
     lastUpdatedBy:string;
     lastUpdateDate:string;
     uom:string;
     fob:string;
     cancelReason:string;
     deliveryOldStartDate:string;
     deliveryOldEndDate:string;
     deliveryFromWeekFinal:string;
     deliveryToWeekFinal:string;
     deliveryStartDateFinal:string;
     oldStartWeek:string;
     oldEndWeek:string;
     componentId:string;
     componentCode:string;
     color:string;
     customer:string;
     componentStatus:string;
     componentEndDate:string;
     componentName:string;
     country:string;
     customerArticleNumber:string;
     myDateFrom:Date;
     myDateTo:Date;	
     currencyCode:string;	
      minDateFrom:Date;	
      locationName:string;
      isStatusPending:boolean;
     minDateTo:Date;
     outstandingQty:string;
     onWayQty:string;
     cancelFlag:string;
     poLineDistributions: PurchaseOrderLinesDistributions[]=[];
     constructor(){}
}
