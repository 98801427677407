export class Requisition {
     reqNumber:string;
     conceptCode:string;
     divison:string;
     suppName:string;
     reqStatus:string;
     createdon:string;
     acceptedby:string;
     categoryNumber:string;
     artNumber:string;
     artName:string;
     delvFrmWk:string;
     delvToWk:string;
     productionMonth:string;
     fromPrDate:Date;
     toPrDate:Date;
     fromPaDate:Date;
     toPaDate:Date;
     productionYear:string;
     suppliernumber:string;
     categoryName:string;
     subCategoryName:string;
     suppCode:string;
     conceptJDECode:string;
     poType:string;
    constructor(){}
    }