import { UtilsService } from '@/common/utils-service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DefectiveArticlesService } from './defective-articles.service';

@Component({
  selector: 'app-defective-articles',
  templateUrl: './defective-articles.component.html',
  styleUrls: ['./defective-articles.component.css','../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css']
})
export class DefectiveArticlesComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  statusMessage: boolean;
  responseMessage: string;
  currentPage : number = 1;
  itemPerPage:number = 100;
  defectiveArtSearchForm : FormGroup;
  defectiveArtSearchResult : FormGroup;
  userCode : string;
  reqFlag: boolean = false;
  isDisplayResult: boolean = false;
  conceptCodeList:any[] = [];
  shopNumberLov:any[] = [];
  factoryCodeLov:any[] = [];
  constructor(private fb: FormBuilder,private router: Router,
    private _defectiveArtService:DefectiveArticlesService,private _utils:UtilsService) {
    this.defectiveArtSearchForm = this.buildSearchForm();
    this.defectiveArtSearchResult = this.buildResultForm();
     let sessUsername = sessionStorage.getItem('currentUser');
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
  }

  ngOnInit(): void {
    var conceptQueryMas = [{
      name: "email",
      value: this.userCode,
    }];
    setTimeout(() => {
    // console.log("Started fetching concept master details");  
    // this._defectiveArtService.getConcepts(conceptQueryMas).subscribe(
    //   (data: any) => {
    //     this.conceptCodeList = data.dataList.filter(list => list.division == "RETAIL");
    //   },
    //   (err) => {
    //     this.responseMessage = err;
    //   },() => {
    //     console.log("Concept code list fetched");
    //   }
    // );

    // Shop Number List impl starts - Added by Aveet on 25-Mar-2022
    let rdcDeatails = [{"name":"userCode", "value": this.userCode},

                      {"name":"shopAgencyCode", "value":"5"}];
    this._defectiveArtService.getRDCdata(rdcDeatails).subscribe(
      (data : any)=>{
        this.shopNumberLov = data.dataList;
      },(err) => {
        this.responseMessage = err;
      },() => {
        console.log("Shop Number list fetched");
      }
    );
    // Shop Number List impl ends - Added by Aveet on 25-Mar-2022

    // Factory code list impl starts - Added by Aveet on 30-Mar-2022
    let facDeatils = [{"name":"shopAgencyCode","value":"0"}];
    this._defectiveArtService.getFactoryCode(facDeatils).subscribe(
      (data : any)=>{
        this.factoryCodeLov = data.dataList;
      },(err) => {
        this.responseMessage = err;
      },() => {
        console.log("Factory Code list fetched");
      }
    );
    // Factory Code list impl ends -  Added by Avet on 30-Mar-2022


    this.loaDer.nativeElement.style.display = 'none';
    },100);
  }

  buildSearchForm(){
    return this.fb.group({
      // invoiceNumber: [''],
      // grnNumber: [''],
      rdcDepotShopNumber: [null],
      factoryCode: [null],
      articleNumber: [''],
    });
  }

  buildResultForm(): FormGroup {
    return this.fb.group({ defectiveArticles: this.fb.array([]) });
  }

  onSearch(searchForm){
    console.log(searchForm.value);
    this.defectiveArtSearchResult = this.buildResultForm();
    this.isDisplayResult = false;
    this.statusMessage = false;
    let control = <FormArray>this.defectiveArtSearchResult.controls.defectiveArticles;
    let requestParam = this._utils.createRequestParametersModelArray(this.defectiveArtSearchForm.getRawValue());
    this.loaDer.nativeElement.style.display = 'block';
    this._defectiveArtService.searchDefectiveData(requestParam).subscribe(
      (response:any) => {
        console.log(response);
        if(response.status.includes("Success")){
          this.isDisplayResult = true;
          response.dataList.forEach(
            d => {
              control.push(this.fb.group({
              // invoiceNumber: [{ value: d.invoiceNumber, disabled: true }],
              // grnNumber: [{ value: d.grnNumber, disabled: true }],
              // consignorCode:[{value:d.consignorCode, disabled: true}],
              rdcDepotShopNumber:[{value:d.rdcDepotShopNumber, disabled: true}],
              conceptCode: [{ value: d.conceptCode, disabled: true }],
              articleNumber: [{ value: d.articleNumber, disabled: true }],
              factoryCode: [{value: d.factoryCode, disabled: true}],
              artSize:[{ value: d.artSize, disabled: true}],
              pairs: [{value: Number(d.pairs) - Number(d.fulFilledPairs), disabled: true}],
              // fulFilledPairs:[{value: d.fulFilledPairs, disabled: true}],
              // shortagePairs: [{value: d.shortagePairs, disabled: true}],
              mrp: [{value: d.mrp, disabled: true}],
              // stdCost: [{value: d.stdCost, disabled: true}]
              }));
            }
          );
        } else{
          this.statusMessage = true;
          this.isDisplayResult = false;
          this.responseMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      }, (err) => {
        this.statusMessage = true;
        this.responseMessage = err;
        this.isDisplayResult = false;
        this.loaDer.nativeElement.style.display = 'none';
      }, () => {
        console.log("Search data fetching complete");
        this.loaDer.nativeElement.style.display = 'none';
      }
    )
  }

  downloadTemplate(){
    console.log("Download started");
    this.statusMessage = false;
    this.responseMessage='';
    let requestParam = this._utils.createRequestParametersModelArray(this.defectiveArtSearchForm.getRawValue());
    this.loaDer.nativeElement.style.display = 'block';
    this._defectiveArtService.getDownloadTemplate(requestParam);
    this.loaDer.nativeElement.style.display = 'none';
  }

  resetForm(){
    this.defectiveArtSearchForm = this.buildSearchForm();
    this.defectiveArtSearchResult = this.buildResultForm();
    this.statusMessage = false;
    this.responseMessage = '';
    this.isDisplayResult = false;
    this.reqFlag = false;
  }

  onClickBack() { this.router.navigate(['']); }

  private getAbsoluteIndex(index: any) {
    let currentPageNumber = this.currentPage;
    let absoluteIndex = this.itemPerPage * (currentPageNumber - 1) + index;
    return absoluteIndex;
  }

}

