export class unBlockArticleForm {
    
    shopLoaction:string;
    artType:string;
    concept:string;
    artNumber:string;
    stockType:string;
   
   
    constructor(){}
}