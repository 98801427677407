import { Component, OnInit } from '@angular/core';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { PurchaseOrderLines } from '../search-po/po-lines';
import { RequestParameterModel } from '../common/request-parameter-model';
import { ViewShipmentReceipt } from './view-shipment-details';
import { PurchaseOrderLinesDistributions } from '../search-po/po-lines-distributions';
import { ViewShipmentReceiptDetailsService } from './view-shipment-receipt-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateViewShipmentResponseModel } from '../common/view-Shipment-distribution-response-model';
import { ViewShipmentReceiptDistribution } from './view-shipment-details-distribution';
import { stringify } from 'querystring';


@Component({
  selector: 'app-view-shipment-receipt-details',
  templateUrl: './view-shipment-receipt-details.component.html',
  //styleUrls: ['./view-shipment-receipt-details.component.css']
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css']
})
export class ViewShipmentReceiptDetailsComponent implements OnInit {
  poNumber: string;
  viewShipmentDetails = new ViewShipmentReceipt();
  viewShipmentLines: ViewShipmentReceipt[] = [];
  private _requestParameterModelArray: RequestParameterModel[];
  distributionDetailsLine: ViewShipmentReceiptDistribution[] = [];
  division: string;

  constructor(private _navigationLinkParameterService: NavigationLinkParameterService, private _viewShipmentService1: ViewShipmentReceiptDetailsService, private route: ActivatedRoute,
    private _router: Router) { }

  onClickBack() {
    this._router.navigate(['view-shipment-receipt']);
  }


  ngOnInit() {

    this.poNumber = this._navigationLinkParameterService.podetails;
    this.division = this._navigationLinkParameterService.id;
    //let searchedPoNumber = this.poNumber;
    console.log('dIVISION' + this.division);
    if (this._navigationLinkParameterService.details != null && this._navigationLinkParameterService.details.length > 0) {
      this._requestParameterModelArray = [];
      console.log('details' + this._navigationLinkParameterService.details);
      this._requestParameterModelArray = this._navigationLinkParameterService.details;
      console.log(' ngOnInit :  _requestParameterModelArray : ' + this._requestParameterModelArray);
      this._requestParameterModelArray.forEach(
        requestParameter => {
          console.log(' name ' + requestParameter.name + ' value ' + requestParameter.value);

          if ('artNumber' == requestParameter.name) {
            this.viewShipmentDetails.artNumber = requestParameter.value;
          }
          if ('artName' == requestParameter.name) {
            this.viewShipmentDetails.artName = requestParameter.value;
          }
          if ('poArtQty' == requestParameter.name) {
            this.viewShipmentDetails.orderedQuantity = requestParameter.value;
          }
          if ('id' == requestParameter.name) {
            this.viewShipmentDetails.id = requestParameter.value;
          }
        }
      );

      this._viewShipmentService1.getViewShipmentServiceCriteria(this.viewShipmentDetails.id).subscribe(
        (response: UpdateViewShipmentResponseModel) => {

          response.dataList.forEach(
            search => {
              let distributionDetails = new ViewShipmentReceiptDistribution();
              distributionDetails.rdcCode = search.rdcCode;
              distributionDetails.rdcName = search.rdcName;
              distributionDetails.orderedQuantity = search.orderedQuantity;
              distributionDetails.shippedQuantity = search.shippedQuantity;
              distributionDetails.receivedQuantity = search.receivedQuantity;
              distributionDetails.outstandingQuantity = search.outstandingQuantity;
              distributionDetails.onwayQuantity = search.onwayQuantity;
              distributionDetails.cancelledQuantity = search.cancelledQuantity;
              this.distributionDetailsLine.push(distributionDetails);
            }
          );
          console.log('uuuuuuu'+JSON.stringify(response['dataList']));
        }
        
      )
    }
  }
}































