import { Injectable } from '@angular/core';
import { BilApiUrl } from '../../common/bil-api-url';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { UtilsService } from '../../common/utils-service';
@Injectable({
  providedIn: 'root'
})
export class RequisitionDetailsService {
  private _search_requisition_line_url: string = BilApiUrl.SEARCH_REQUISITION_LINES_URL;
  private _createpo_from_requisition_detailse_url: string = BilApiUrl.CREATE_PO_FROM_REQUISITION_DETAILS_URL;
  constructor(private _http: HttpClient, private _utilsService: UtilsService) { }
  
  findRequisitionLinesByRequisitionId(requistionIdJSONObject:any){
   return this._http.post(this._search_requisition_line_url ,requistionIdJSONObject);
  }

  createPOFromRequisitionDetails(createPOPayloadJson : any){
    console.log( ' createPOFromRequisitionDetails :  input ' + createPOPayloadJson );
  //  const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8').set( 'Access-Control-Allow-Origin','*');
  
    return this._http.post(this._createpo_from_requisition_detailse_url ,createPOPayloadJson, { responseType: 'text' as 'json' });
  }

}
