import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BilApiUrl } from '../common/bil-api-url';
@Injectable({
  providedIn: 'root'
})
export class DistributionDetailMultipleService {
  private _location_url = BilApiUrl.SEARCH_LOCATION_URL;
  private _assortment_url: string = BilApiUrl.ASSORTMENT_CODE_URL;
  private _assortment_validate_url: string = BilApiUrl.ASSORTMENT_CODE_VALIDDATE_URL;
  private _add_requisition_distribution_url: string = BilApiUrl.ADD_REQUISITION_DISTRIBUTION;
  private _fetch_editable_assortment_packages_url: string = BilApiUrl.FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL;
  private _add_po_distribution_url: string = BilApiUrl.ADD_PO_DISTRIBUTION;
  private requisition_size_info_url: string = BilApiUrl.REQUISITION_SIZE_INFO;
  private _assortment_type_url = 'assets/static-data/assortmentType.json';
  constructor(private _http: HttpClient) { }
 /* getAssortmentTypes() {
    console.log(' Log File :  DistributionDetailService : Function : getAssortmentTypes')
    return this._http.get(this._assortment_type_url);
  }*/

  getAssortmenstsHardCode(assortment_code_param) {
    // let jsonPackMasterInput = [
    //   { artSize: 'pair13', pairQuantity: 6 },
    //   { artSize: 'pair15', pairQuantity: 2 },
    //   { artSize: 'pair7', pairQuantity: 4 },
    //   { artSize: 'pair9', pairQuantity: 10 },
    //   { artSize: 'pair11', pairQuantity: 8 }
    // ]
    return 'A000287';
  }

  getLocations(location_param){
    return this._http.post(this._location_url,location_param);
  }
 
  getAssortmensts(assortment_code_param){
   // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
   let headers={
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
}
    return this._http.post(this._assortment_url,assortment_code_param,headers);
  }

  getAssortmenstsValidate(assortment_code_param){
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
    let headers={
     headers: new HttpHeaders({
         'Content-Type': 'application/json'
     })
 }
     return this._http.post(this._assortment_validate_url,assortment_code_param,headers);
   }

  saveDistribution(requisition_distribution_param){
    return this._http.post(this._add_requisition_distribution_url,requisition_distribution_param);
  }

    getEditableAssortmentPackagesForSolidAssortmentType(editable_assortment_packages_param){   
    let payload : any [] = [];
    payload.push(editable_assortment_packages_param);
    let jObj =   JSON.stringify(payload) ;   
    const response = this._http.post(this._fetch_editable_assortment_packages_url,payload);
    return response;
   }

   savePoDistribution(requisition_po_distribution_param){
    return this._http.post(this._add_po_distribution_url,requisition_po_distribution_param);
  }

  getRequisitionSizeInfo(requisition_size_param){
    return this._http.post(this.requisition_size_info_url,requisition_size_param);
  }
 

}
