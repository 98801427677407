import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { UtilsService } from '../../common/utils-service';
import { Router, RouterModule } from '@angular/router';
import { Concept } from '../../common/concept';
import { BlockArticleService } from './block-article.service';
import { BlockArticleForm } from './article-form';
import { Search } from './data';


@Component({
  selector: 'app-block-article',
  templateUrl: './block-article.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', './block-article.component.css']
})
export class BlockArticleComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  concepts: Concept[];
  segment: string;
  userCode: string;
  shopLoactions: any[] = [];
  articleModel = new BlockArticleForm();
  dataDiv = new Search();
  enable: boolean = false;
  constructor(private BlockArticleService: BlockArticleService,private _utilsService: UtilsService,private _router: Router) { }

  ngOnInit() {

    this.articleModel.concept = null;
    this.articleModel.shopLoaction = null;

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{"name":"email","value":JSON.parse(sessUsername).groupAssignmentModels[0].userCode}];
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.BlockArticleService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
      }
    );
  }

  onSelect() {

    let divisionCode: string;
    this.concepts.filter(cs => cs.concCode == this.articleModel.concept).forEach(
      div => {
        if (div.division == 'RETAIL') {
          divisionCode = '5';
        }
        else {
          divisionCode = '4';
        }
        let json_string_search = JSON.stringify(div);
        let json_obj = JSON.parse(json_string_search);
        this.dataDiv.division = json_obj.division;
        this.segment = json_obj.division;
      }
    )
    let detail = {
      "shopCloseYrWk1": "000000",
      "shopAgencyCode": divisionCode,
      "userEmail": this.userCode,
      "conceptCode": this.articleModel.concept
    }
    console.log(detail);
    let requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    this.BlockArticleService.getLocations(requestParameterModel).subscribe(
      (res: any) => {
        this.shopLoactions = [];
        res.dataList.forEach(
          shopLoaction => {
            this.enable = true;
            this.shopLoactions.push(shopLoaction);
          }
        );
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }


  /*  ------------------------Back button method----------------------------- */
     
onClickBack() {
      
  this._router.navigate(['']);
}
}

