export class AssortmentDetails {
    pckAssortmentId:string;
    articleSize:string;
    pairQty:string;
    createdBy:string;
    createdDate:string;
    lastUpdatedBy:string;
    lastUpdateDate:string;
    sizeLabel:string;
    constructor(){}
}