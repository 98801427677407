// tslint:disable-next-line: class-name
export class updateTransporterModel {
    shipmentId: string;
    transpoterCode: string;
    lrGrNumber: string;
    lrdate: Date;
    vehicleNumber: string;
    remarks: string;
    asnStatus: string;
    constructor() {}
    }

