import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class RdcMaintenanceService {

  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  rdcMaintenance_search_url: string = RdsBilApiUrl.SEARCH_RDC_MAINTENANCE;
  rdcMaintenance_save_url: string = RdsBilApiUrl.SAVE_RDC_MAINTENANCE;

  constructor(private http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this.http.post(this.concept_url, conceptQueryMas);
  }
  
  getSearchResponse(details){
    return this.http.post(this.rdcMaintenance_search_url, details);
  }

  getSaveResponse(details){
    return this.http.post(this.rdcMaintenance_save_url, details);
  }

}
