// tslint:disable-next-line: class-name
export class gstVarList {

    gstId: number;
    minPrice: number;
    maxPrice: number;
    gstRate: number;
    igstRate: number;
    cgstRate: number;
    sgstRate: number;
    activeFlag: boolean;
    effectiveDate: Date;
    effectiveEndDate: Date;
    lastUpdatedBy: string;
    lastUpdated: Date;
    cessValue: number;
    constructor() { }
}
