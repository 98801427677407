import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ClaimSlipsService } from './claim-slips.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claim-slips',
  templateUrl: './claim-slips.component.html',
  styleUrls: ['./claim-slips.component.css','../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css']
})
export class ClaimSlipsComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  
  slipForm : FormGroup;
  slipResultForm : FormGroup;
  userName : string;
  factory : any;
  RDC: any;
  reqFlag: boolean = false;
  statusMessage: string;
  responseMessage: string;
  isDisplayResult: boolean = false;
  currentPage : number = 1;
  itemPerPage:number = 100;

  constructor(private _cliamSlipsService : ClaimSlipsService,private fb: FormBuilder,private router: Router) {
    this.slipForm = this.buildSlipForm();
    this.slipResultForm = this.buildSlipResultForm();
     let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    // this.userName = "user.test4";
   }

  ngOnInit() {
   let facDeatils = [{"name":"shopAgencyCode",

                      "value":"0"}]
    let RDCdeatails = [{"name":"userCode", "value": this.userName},

                      {"name":"shopAgencyCode", "value":"5"}]                  
    setTimeout(() => {
      this._cliamSlipsService.getFactory(facDeatils).subscribe(
        (data : any)=>{
          this.factory = data.dataList;
        }
      );
      this._cliamSlipsService.getRDCdata(RDCdeatails).subscribe(
        (data : any)=>{
          this.RDC = data.dataList;
        }
      );
      this.loaDer.nativeElement.style.display = 'none';
    },100);
  }

  buildSlipForm(){
    return this.fb.group({
      factory: [null,[Validators.required]],
      RDC: [null,[Validators.required]]
    });
  }

  buildSlipResultForm(): FormGroup {
    return this.fb.group({ slip: this.fb.array([]) });
  }

  onSearch(formdata : any){
    if(this.slipForm.invalid){
      this.reqFlag = true;
    }
    else{
      this.slipResultForm = this.buildSlipResultForm();
      this.statusMessage='';
      this.responseMessage='';
      let reqDetails = { 
        "factoryCode" : formdata.factory,
        "rdcDepotShopNumber"  : formdata.RDC
              }

      this.loaDer.nativeElement.style.display = 'block';
      console.log(this.slipForm.get('factory').value);
      console.log('in else')
      let control = <FormArray>this.slipResultForm.controls.slip;
    this._cliamSlipsService.getSearchResult(reqDetails).subscribe(
      (response: any) => {
        console.log(response.dataList);console.log('response.dataList');
        if(response.dataList != ''){
        let details = [];
        details = response.dataList;
        this.isDisplayResult = true;
        this.responseMessage='';
        details.forEach(
          d=>{
            console.log(' search response ' + JSON.stringify(d));
            control.push(this.fb.group({
              storeNo: [{ value: d.storeShopNumber, disabled: true }],
              RDCno: [{ value: d.rdcDepotShopNumber, disabled: true }],
              concept:[{value:d.conceptCode, disabled: true}],
              articleNo:[{value:d.articleNumber, disabled: true}],
              factoryCode: [{ value: d.factoryCode, disabled: true }],
              totalCartons: [{value: d.totalCartoons, disabled: true}],
              articleSize:[{ value: d.artSize, disabled: true}],
              pairs: [{value: d.pairs, disabled: true}],
              fulFilledPairs:[{value: d.fulFilledPairs, disabled: true}],
              claimedAmount: [{value: d.claimedAmount, disabled: true}],
              claimSlipNo: [{value: d.claimSlipNo, disabled: true}]
              
            }));
          }
        );}
        else{
          this.statusMessage='error';
          this.responseMessage= 'No Data Found';
          this.isDisplayResult = false;
          this.loaDer.nativeElement.style.display = 'none';
        }
        console.log('control');console.log(control);
      }, (error) => {
        console.error('some error occured! while fetching discounts from server');
        this.slipResultForm = this.buildSlipResultForm();
        this.isDisplayResult = false;
        this.statusMessage = 'error';
        this.responseMessage = error;
        this.loaDer.nativeElement.style.display = 'none';
      }, () => {
        console.info('all disounts fetched. ');
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
    }
  }

  private getAbsoluteIndex(index: any) {
    let currentPageNumber = this.currentPage;
    let absoluteIndex = this.itemPerPage * (currentPageNumber - 1) + index;
    return absoluteIndex;
  }

  resetForm(){
    this.slipForm = this.buildSlipForm();
    this.slipResultForm = this.buildSlipResultForm();
    this.statusMessage = '';
    this.responseMessage = '';
    this.isDisplayResult = false;
    this.reqFlag = false;
  }

  downloadTemplate(){
    this.statusMessage='';
    this.responseMessage='';
    let reqDetails = { 
      "factoryCode" : this.slipForm.get('factory').value,
      "rdcDepotShopNumber"  :  this.slipForm.get('RDC').value
            }
    this.loaDer.nativeElement.style.display = 'block';
    this._cliamSlipsService.getDownloadTemplate(reqDetails);  
    
    this.loaDer.nativeElement.style.display = 'none';
   
  }

  onClickBack() { this.router.navigate(['']); }

}
