import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class SearchGinService {

  gin_search_url: string = RdsBilApiUrl.GIN_SEARCH;
  gin_update_url: string = RdsBilApiUrl.GIN_NUMBER;

  constructor(private _http: HttpClient) { }

  getGinBySearchCriteria(data)
    {
      return this._http.post(this.gin_search_url, data);      
    }

    updateGINData(data)
    {
      return this._http.post(this.gin_update_url, data);      
    }
}
