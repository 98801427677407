import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GstMasterService } from '../gst-master/gst-master.service';
import { gstVarList } from '../gst-master/gstVarList';
import { ResponseMessage } from '../../common/response-message';
import { DatePipe } from '@angular/common';
import { UtilsService } from '../../common/utils-service';
import { MustMatch } from './must-match.validator';
import * as $ from 'jquery';

@Component({
  selector: 'app-gst-master',
  templateUrl: './gst-master.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css']
})

export class GstMasterComponent implements OnInit {

  constructor(private gstmasterservice: GstMasterService, private formBuilder: FormBuilder, private _utilsService: UtilsService) {
    this.dateTime.setDate(this.dateTime.getDate());
  }

  get fval() {
    return this.registerForm.controls;
  }

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('transForm', { static: true }) formValues;
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  updateModel = new gstVarList();
  updateModel1 = [];
  gstvalues;
  isDisabled: any;
  isValidDate: boolean;
  registerForm: FormGroup;
  statusMessage: string;
  submitted = false;
  // tslint:disable-next-line: no-inferrable-types
  public toggleButton: boolean = true;
  responsemessage: string;
  responseMessage: string;
  error: any = { isError: false, errorMessage: '' };
  pipe = new DatePipe('en-US');
  activeflag: string;
  sumVal: number;
  dateTime = new Date();
  userCode: string;
  gstId: any;

  ngOnInit() {
    const sessUsername = sessionStorage.getItem('currentUser');
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.gstList();

    this.registerForm = this.formBuilder.group({
      minPrice: ['', Validators.required],
      maxPrice: ['', Validators.required],
      igstRate: ['', Validators.required],
      cgstRate: ['', Validators.required],
      sgstRate: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      effectiveEndDate: ['',Validators.required]
    }, {validator: this.dateLessThan('effectiveDate', 'effectiveEndDate')});

    //this.updateSum();
  }

  dateLessThan(effectiveDate: string, effectiveEndDate: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[effectiveDate];
      let t = group.controls[effectiveEndDate];
      if (f.value > t.value) {
        return {
          dates: "Effective End Date should not be smaller than  Effective Date"
        };
      }
      return {};
    }
}

  updateSum() {
    this.sumVal = Number(this.registerForm.value.cgstRate) + Number(this.registerForm.value.sgstRate);
    this.registerForm.controls["igstRate"].setValue(
      this.sumVal
    );
  }

  setEffectiveEndDate(value, index) {
    console.log(index);
    const myDate = new Date(value);
    const mySimpleFormat = this.pipe.transform(myDate, 'yyyy-MM-ddT00:00:00.000+0000');
    // console.log(myDate);
    // tslint:disable-next-line: triple-equals
    this.gstvalues.filter(cs => cs.gstId == index).forEach(
      setDate => {
        setDate.effectiveEndDate = mySimpleFormat;
        console.log(setDate);
      }
    );
  }

  gstList() {
    this.gstmasterservice.getGstListValues().subscribe(
      (data: any) => {
        this.gstvalues = data.dataList;

      });
      this.responseMessage=this.responsemessage;
      this.responsemessage="";
  }

  enable() {
    this.toggleButton = false;
    this.isDisabled = false;
  }

  // Add new
  gstMasterAddNew() {
    // const config = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
    this.submitted = true;
    console.log(this.registerForm);
    if (this.registerForm.invalid) {
      return;
    }
    // const date = new Date();
    // // const effectiveDate = this.registerForm.value.effectiveDate;
    // const effectiveEndDate = this.registerForm.value.effectiveEndDate;

    // if (date < effectiveEndDate) {
    //   this.activeflag = 'Y';
    // } else {
    //   this.activeflag = 'N';
    // }
    this.activeflag = 'Y';
    if (parseFloat(this.registerForm.value.maxPrice) < parseFloat(this.registerForm.value.minPrice)) {
      this.responsemessage = 'Max price should be greater than min price.';
      return false;
    } else {
      const details = [{
        minPrice: this.registerForm.value.minPrice,
        maxPrice: this.registerForm.value.maxPrice,
        igstRate: this.registerForm.value.igstRate,
        cgstRate: this.registerForm.value.cgstRate,
        sgstRate: this.registerForm.value.sgstRate,
        activeFlag: this.activeflag,
        lastUpdatedBy: this.userCode,
        gstRate: this.registerForm.value.igstRate,
        effectiveDate: this._utilsService.transformDate1(this.registerForm.value.effectiveDate),
        effectiveEndDate: this._utilsService.transformDate1(this.registerForm.value.effectiveEndDate),
        cessValue: ''
      }];
      

      this.loaDer.nativeElement.style.display = 'block';
      //let index = this.gstvalues.findIndex(i=>i.minPrice == details[0].minPrice);
      let users = this.gstvalues.filter(x => x.minPrice == details[0].minPrice && x.maxPrice == details[0].maxPrice && this._utilsService.transformDate1(x.effectiveDate)==this._utilsService.transformDate1(this.registerForm.value.effectiveDate) && this._utilsService.transformDate1(x.effectiveEndDate)== this._utilsService.transformDate1(this.registerForm.value.effectiveEndDate));
      if(users.length > 0){
        var r = confirm("Existing active GST record with same Max and Min price will be overwriiten, do you want to proceed?");
      } 
      if (r == true || users.length == 0) {
        this.gstmasterservice.getAddNewDetails(details).subscribe(
        (response: any) => {
          this.gstvalues.push(this.registerForm.value);
          if(response.status == 'Success'){
          // this.responsemessage = 'Data Updated Successfully';
          this.responsemessage=response.message;
          } else{
            this.responsemessage = response.message;
          }          
          this.gstList();
          // tslint:disable-next-line: only-arrow-functions
          setTimeout(() => {
            $('#btnclose').click();
            this.registerForm.reset();
          }, 3000);
          $('#btnSubmit').attr('disabled');
        },
        (err) => {
          this.responseMessage = err;
        });
      } 
    }
  }


  compareTwoDates() {
    const effectiveDate = this.registerForm.value.effectiveDate;
    const effectiveEndDate = this.registerForm.value.effectiveEndDate;

    if (effectiveDate > effectiveEndDate) {
      this.responsemessage = 'Effective End Date should not be smaller than  Effective Date';
      return false;
    }
  }

  // Update end effecitive date

  onUpdateEndEffectiveDate() {
    // this.loaDer.nativeElement.style.display = 'block';
    this.isValidDate = true;
    console.log(JSON.stringify(this.gstvalues));
    this.gstmasterservice.saveData(this.gstvalues).subscribe(
      (response: any) => {
        // tslint:disable-next-line: triple-equals
        if (response.status == 'Success') {
          this.responseMessage = response.message;
        } else {
          this.responseMessage = response.message;
        }
      }
    );
  }

  onSaveNewEntry() {
  }

  numberCheck(event, name) {
    //var regExpr = /[^0-9]/g;
    //var regExpr = /[^0-9.]/g;
    var regExpr = /[^a-zA-Z0-9-. ]/g;
    var userText = event.target.value;
    var userInputArray = userText.split(".");  
    if (userText != "") {
      if (name == "minPrice") {
        this.registerForm.controls["minPrice"].setValue(
          userText.replace(regExpr, '')
        );        
        if(userInputArray.length == 2 && userInputArray[1].length > 1 && event.keyCode != 8){
          // this.registerForm.controls["minPrice"].setValue(
          //   parseFloat(userText).toFixed(2)
          // );
          return false;
        }
      }
      if (name == "maxPrice") {
        this.registerForm.controls["maxPrice"].setValue(
          userText.replace(regExpr, '')
        );
        if(userInputArray.length == 2 && userInputArray[1].length > 1 && event.keyCode != 8){
          return false;
        }
      }
      if (name == "cgstRate") {
        this.registerForm.controls["cgstRate"].setValue(
          userText.replace(regExpr, '')
        );
        if(userInputArray.length == 2 && userInputArray[1].length > 1 && event.keyCode != 8){
          return false;
        }
      }
      if (name == "sgstRate") {
        this.registerForm.controls["sgstRate"].setValue(
          userText.replace(regExpr, '')
        );
        if(userInputArray.length == 2 && userInputArray[1].length > 1 && event.keyCode != 8){
          return false;
        }
      }
      if (name == "igstRate") {
        this.registerForm.controls["igstRate"].setValue(
          userText.replace(regExpr, '')
        );
        if(userInputArray.length == 2 && userInputArray[1].length > 1 && event.keyCode != 8){
          return false;
        }
      }
    }
  }

  declineGst(gstId) {
    this.gstId = gstId;
  }

  declineStatusChange(status) {
      this.loaDer.nativeElement.style.display = "block";
      var details = [
        {
          "gstId" : this.gstId,
          "lastUpdatedBy": this.userCode,
        }
      ];
      this.gstmasterservice.GSTStatusChange(details).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          if (response.status == "Success") {
            setTimeout(() => {
              this.closeBtn.nativeElement.click();
              this.responseMessage = "GST deactivated successfully.";
              this.gstList();
            }, 2000);            
          } else {
            this.closeBtn.nativeElement.click();
            this.responseMessage = "Something Error Happened.";
          }
        },
        (err) => {
          this.closeBtn.nativeElement.click();
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.closeBtn.nativeElement.click();
          this.loaDer.nativeElement.style.display = "none";
        }
      );
    }

}

