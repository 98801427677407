import { OrderType } from './../../common/orderType';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { UpdatePoService } from './update-po.service';
import { PurchaseOrderHeaderWithLines } from '../po-header-with-lines';
import { PurchaseOrderLines } from '../po-lines';
import { UpdatePoResponseModel } from '../../common/update-po-response-model';
import { ActivatedRoute, Router } from '@angular/router';
// import { SearchPoResponseModel } from '../../common/search-po-response-model';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ITEMS_PER_PAGE } from '../../common/field-name-list';
import { DeleteDetails } from '../../common/delete-details';
import { ResponseMessage } from '../../common/response-message';
import { SUCCESS_MSG } from '../../common/field-name-list';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-update-po',
  templateUrl: './update-po.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css', './update-po.component.css'],
  providers: [UpdatePoService]


})
export class UpdatePoComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  constructor(private _updatePoService: UpdatePoService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private route: ActivatedRoute,
    private _router: Router, private _utilsService: UtilsService, private datePipe: DatePipe) { }
  poNumber: string;
  supplierDetails: string;
  searchedPoDetails = new PurchaseOrderHeaderWithLines();
  searchedPoLines: PurchaseOrderLines[] = [];
  currentPage: number = 1;
  requestParameterModel: RequestParameterModel[];
  arr = ['fromDate', 'toDate'];
  userRole: string;
  checkHiphen: string;
  coCode: any;
  id: number;
  poLineStatus: string;
  remarks: string;
  selectedIndex: number;
  responseMessage: string;
  weekOfYear1: string = "";
  dateOfYear1: string = "";
  weekOfYear2: string = "";
  dateOfYear2: string = "";
  initialdeliveryStartDateFinal: string[] = [];
  initialdeliveryEndDateFinal: string[] = [];
  username: string;
  lastUpdateDate: string;
  currentDate: Date;
  orderType: string;
  poStatus: string;
  myDateFrom: any;
  myDateTo: any;
  myDate1: any;
  minDateTo: any;
  orderedQty: any;
  amount: number = 0;
  updatedAmount: string;
  flagCheck: any;
  dateExtensionValue: number;
  articleNumber: any;
  articlePrevoiusCost: any;
  articleUpdatedCost: any;

  articlePreviousSuppNumber: any;
  articleUpdatedSuppNumber: any;

  articlePreviousMrp: any;
  articleUpdatedMrp: any;


  myFunc() {
    // this.poStatus = this._navigationLinkParameterService.podetails.poStatus; 
    this.poStatus = this.searchedPoDetails.poStatus;
    this.flagCheck = this._navigationLinkParameterService.navigateFlag;
    this.currentDate = new Date();
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.amount = 0;
    let myDate = new Date(this.currentDate);
    let year = this.currentDate.getFullYear();
    let mnth = ("0" + (myDate.getMonth() + 1)).slice(-2);
    let day = ("0" + myDate.getDate()).slice(-2);
    this.lastUpdateDate = [day, mnth, year].join("/");
    this.poNumber = this._navigationLinkParameterService.id;
    //this.orderedQty = this._navigationLinkParameterService.podetails.orderedQty;
    this.checkHiphen = this.poNumber.toString().substr(1, 1);
    this._updatePoService.configParamList().subscribe(
      (res: any) => {
        let configArray: any[] = res.configParamModels;
        configArray.filter(cs => cs.paramName == 'PO_EXTENSION_PERIOD').forEach(
          eachParam => {
            this.dateExtensionValue = (parseInt(eachParam.paramValue)) / 30;
          }
        )
      });

    this._updatePoService.getPurchaseOrderBySearchCriteria(this.poNumber).subscribe(
      (response: UpdatePoResponseModel) => {
        response.dataList.forEach(
          searchPo => {
            if (this.poNumber === searchPo.poNumber) {
              this.searchedPoDetails = searchPo;
              this.supplierDetails = this.searchedPoDetails.partyShortName + ' / ' + this.searchedPoDetails.partyNumber;
              this.orderType = this.searchedPoDetails.poType;
              this.searchedPoLines = searchPo.poLines;
              //sorting perform on artname and art number
              this.searchedPoLines.sort((a, b) => {
                return a.artName.localeCompare(b.artName) || a.artNumber.localeCompare(b.artNumber);
              });
              this.searchedPoLines.forEach(
                poType => {
                  // if(poType.poLineStatus == 'Pending Approval' || poType.poLineStatus == 'Pending Cancel' || poType.poLineStatus == 'Pending Recall'){
                  //   poType.isStatusPending = true;
                  // }
                  // else{
                  //   poType.isStatusPending = false;
                  // }
                  poType.locationName = poType.poLineDistributions[0].rdcName;
                  let outstanding: number = 0;
                  outstanding = poType.poArtQty - poType.cancelledQty;
                  if (this.searchedPoDetails.poType != 'I') {
                    this.amount = this.amount + (outstanding * Number(poType.stdArtCost));
                  }
                  else {
                    this.amount = this.amount + (outstanding * Number(poType.fob));
                    if (poType.fob == null) {
                      poType.fob = '0';
                    }
                  }
                }
              );
              var multiplier = Math.pow(10, 2);
              this.amount = (Math.round(this.amount * multiplier) / multiplier);
              this.updatedAmount = this.calculateDecimalValue(this.amount);
              if (this.searchedPoDetails.poType != 'I') {
                this.searchedPoLines.forEach(
                  poType => {
                    poType.currencyCode = 'INR';
                  }
                )
              }
              for (var i = 0; i < this.searchedPoLines.length; i++) {
                this.initialdeliveryStartDateFinal[i] = this.searchedPoLines[i].deliveryStartDateFinal;
                this.initialdeliveryEndDateFinal[i] = this.searchedPoLines[i].deliveryEndDateFinal;
                this.searchedPoLines[i].deliveryOldStartDate = this.initialdeliveryStartDateFinal[i];
                this.searchedPoLines[i].deliveryOldEndDate = this.initialdeliveryEndDateFinal[i];
                let dS: string[] = this.searchedPoLines[i].deliveryStartDateFinal.split('/');
                let dSTo: string[] = this.searchedPoLines[i].deliveryEndDate.split('/');
                let dS1 = dS[2] + '/' + dS[1] + '/' + dS[0];
                let dS2 = dSTo[2] + '/' + dSTo[1] + '/' + dSTo[0];
                let integer = parseFloat(dS[0]);
                var fromDate = new Date(parseInt(dS[2]), parseInt(dS[1]) - 1, parseInt(dS[0]));
                var restritedFromDate = fromDate.setMonth(fromDate.getMonth() + 2);
                var restrictFromDay = new Date(fromDate.getFullYear(), fromDate.getMonth() + 2, fromDate.getDay());
                this.myDateFrom = new Date(restritedFromDate);
                this.searchedPoLines[i].myDateFrom = new Date(restritedFromDate);
                var toDate = new Date(parseInt(dSTo[2]), parseInt(dSTo[1]) - 1, parseInt(dSTo[0]));
                var restritedToDates = toDate.setMonth(toDate.getMonth() + this.dateExtensionValue);
                var restrictToDays = new Date(toDate.getFullYear(), toDate.getMonth() + 2, toDate.getDay());
                this.searchedPoLines[i].myDateTo = new Date(restritedToDates);

                let minDateFromConversion: string[] = this.initialdeliveryStartDateFinal[i].split('/');
                let minDateToConversion: string[] = this.initialdeliveryEndDateFinal[i].split('/');
                let x = parseInt("60");

                let minDateFrom = (new Date(parseInt(minDateFromConversion[2]), parseInt(minDateFromConversion[1]) - 1, parseInt(minDateFromConversion[0])));
                this.searchedPoLines[i].minDateFrom = minDateFrom;
                if (myDate.getTime() < this.searchedPoLines[i].minDateFrom.getTime() || myDate.getTime() > this.searchedPoLines[i].minDateFrom.getTime()) {
                  this.searchedPoLines[i].minDateFrom = myDate;
                }

                this.minDateTo = (new Date(parseInt(minDateToConversion[2]), parseInt(minDateToConversion[1]) - 1, parseInt(minDateToConversion[0])));
                this.searchedPoLines[i].minDateTo = this.minDateTo;
                if (myDate.getTime() < this.searchedPoLines[i].minDateTo.getTime() || myDate.getTime() > this.searchedPoLines[i].minDateTo.getTime()) {
                  this.searchedPoLines[i].minDateTo = myDate;
                }
                let outstandingQuant = this.searchedPoLines[i].poArtQty - this.searchedPoLines[i].shippedQty - this.searchedPoLines[i].cancelledQty;
                let onwayQuant = this.searchedPoLines[i].shippedQty - this.searchedPoLines[i].receivedQty;
                if (onwayQuant < 0) {
                  this.searchedPoLines[i].onWayQty = '0';
                }
                else {
                  this.searchedPoLines[i].onWayQty = (onwayQuant).toString();
                }
                if (outstandingQuant < 0) {
                  this.searchedPoLines[i].outstandingQty = '0';
                }
                else {
                  this.searchedPoLines[i].outstandingQty = (outstandingQuant).toString();
                }
              }
            }
          }
        );
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
      }
    );
  }

  calculateDecimalValue(value): string {
    let val = value.toString();
    let index = val.indexOf('.');
    let afterDecimal;
    if (index >= 0) {
      let valueList = val.split('.');
      if (valueList[1] > 0) {
        if (valueList[1].length >= 2) {
          afterDecimal = valueList[1].substr(0, 2);
        }
        else {
          afterDecimal = valueList[1] + '0';
        }
        let resultValue = valueList[0] + '.' + afterDecimal;
        console.log('result' + resultValue);
        return resultValue;
      } else {
        return valueList[0];
      }
    }
    else {
      return val;
    }
  }

  ngOnInit() {
    this.myFunc();
  }

  cancelPurchaseOrderLine(index) {
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    this.searchedPoLines.splice(index, 1);
  }

  onClickBack() {
    console.log(this.flagCheck);
    if (this.flagCheck == 'poList') {
      this._router.navigate(['po-list']);
    }
    else {
      this._router.navigate(['search-po']);
    }
  }

  viewCompletedDistributionDetailsPage(index, lineId) {
    var letpolines = [];
    this.searchedPoDetails.poLines.forEach(k => {
      letpolines.push(k);
    });
    let distributionDetails = {
      letpolines: letpolines,
      lineId: lineId,
      division: this.searchedPoDetails.division,
      conceptCode: this.searchedPoDetails.conceptCode,
      poHeaderId: this.searchedPoDetails.id,
      poType: this.searchedPoDetails.poType,
      isPrinted: this.searchedPoDetails.isPrinted
    }

    this._navigationLinkParameterService.distributionDetails = distributionDetails;
    this._router.navigate(['/distribution-detail-po']);
  }

  disableButton(polineStat, cancelFlag, concc, orderedqty, cancelqty, receiveqty, shippedQty) {
    var iof = this.coCode.indexOf(concc);
    this.poStatus = this.searchedPoDetails.poStatus;
    let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
    let userRoleIndexIT = this.userRole.indexOf('IT');
    let outstanding = (parseInt(orderedqty) - parseInt(cancelqty));
    let recvqty = parseInt(receiveqty);
    let isOtbProcessed = this.searchedPoDetails.isOtbProcessed;
    if (isOtbProcessed == 'Y' || (parseInt(shippedQty) >= parseInt(orderedqty)) || cancelFlag == 'Y') {
      return true;
    }
    else {
      if ((cancelFlag == 'N' || cancelFlag == null) && (polineStat == 'New' || polineStat == 'In-Transit') &&
        (this.poStatus == 'New' || this.poStatus == 'Approved' || this.poStatus == 'In-Transit' || this.poStatus == 'Rejected') &&
        (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-' && iof >= 0 && (outstanding != recvqty)) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  cancelPurchaseOrder() {
    this.responseMessage = '';
    let poType: string = 'poLine';
    let details = {
      "poLineStatus": this.poLineStatus,
      "cancelReason": this.remarks,
      "action": "Cancel"
    }
    let shippedQty: number;
    let recvQty: number;
    this.searchedPoLines.filter(cs => cs.id == this.id).forEach(
      searchList => {
        shippedQty = Number(searchList.shippedQty);
        recvQty = Number(searchList.receivedQty);
      }
    )
    if (shippedQty >= recvQty) {
      let deleteDetails = new DeleteDetails();
      this.requestParameterModel = [];
      this.requestParameterModel = this._utilsService.createRequestParametersModelArray(details);
      deleteDetails.type = poType;
      deleteDetails.id = this.id;
      deleteDetails.updatedDataList = this.requestParameterModel;
      this._updatePoService.getPoDeleted(deleteDetails).subscribe(
        (response: ResponseMessage) => {
          if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
            this.responseMessage = 'PO Line Cancelled Successfully';
            this.remarks = '';
          }
          else {
            this.responseMessage = "Error Occured";
          }
          this.searchedPoLines = [];
          this.myFunc();
        }
      )
    }
    else {
      this.responseMessage = 'Received Quantity is greater than Shipped/Ordered Quantity';
    }
  }

  settingIndex(Id, poStat, updateFlag) {
    // this.selectedIndex = index;
    this.id = Id;
    this.poLineStatus = poStat;
    this.responseMessage = '';
    this.remarks = '';

    if (updateFlag == true) {
      this.updatedValues(Id);
    }

  }

  updatedValues(Id) {
    this.responseMessage = '';
    this.articleNumber = null;
    this.articlePrevoiusCost = null;
    this.articleUpdatedCost = null;

    this.articlePreviousSuppNumber = null;
    this.articleUpdatedSuppNumber = null;

    this.articlePreviousMrp = null;
    this.articleUpdatedMrp = null;

    let line = this.searchedPoLines.filter(cs => cs.id == Id)[0];
    let details = [{
      "name": "art_no",
      "value": line.artNumber
    }];
    console.log(details);

    this._updatePoService.getPoDetailsView(this.orderType, details).subscribe(
      (response: any) => {
        if (this.orderType == 'I') {
          this.articleNumber = response.dataList[0].artNo;
          this.articlePrevoiusCost = line.fob;
          response.dataList.forEach(fobData => {
            if (line.currencyCode == fobData.currency) {
              this.articleUpdatedCost = this.calculateDecimalValue(response.dataList[0].price);
            }
          });
          this.articlePreviousMrp = line.poArtPrice;
          this.articleUpdatedMrp = this.calculateDecimalValue(response.dataList[0].mrp);

          this.articlePreviousSuppNumber = line.suppRefNumber;
          this.articleUpdatedSuppNumber = response.dataList[0].artSupplierNo;
        }
        else {
          this.articleNumber = response.artMasterResponseModels[0].art_no;
          this.articlePrevoiusCost = line.stdArtCost;
          this.articleUpdatedCost = this.calculateDecimalValue(response.artMasterResponseModels[0].art_stnd_cost);

          this.articlePreviousMrp = line.poArtPrice;
          this.articleUpdatedMrp = this.calculateDecimalValue(response.artMasterResponseModels[0].art_price_mrp);

          this.articlePreviousSuppNumber = line.suppRefNumber;
          this.articleUpdatedSuppNumber = response.artMasterResponseModels[0].art_supplier_no;
        }
      }
    )
  }



  changeDate(value, index, key) {
    this.responseMessage = '';
    let myDate = new Date(value);
    let weekNo: string;
    let dateTimeFormatStr: string = 'dd/MM/yyyy';
    let changedDateStr = this.datePipe.transform(myDate, dateTimeFormatStr);
    this._updatePoService.getWeekAndYear(changedDateStr).subscribe(
      (res: any) => {
        let week: string = res.dataList[0].bataWeek.toString();
        if (week.length == 1) {
          week = '0' + week;
        }
        weekNo = res.dataList[0].bataYear.toString() + week;
        let mnth = ("0" + (myDate.getMonth() + 1)).slice(-2);
        let day = ("0" + myDate.getDate()).slice(-2);
        this.dateOfYear1 = [day, mnth, myDate.getFullYear()].join("/");
        if (key == 'fromDate') {
          this.searchedPoLines[index].deliveryFromWeekFinal = weekNo;
          this.searchedPoLines[index].deliveryStartDateFinal = this.dateOfYear1;
        }
        else {
          this.searchedPoLines[index].deliveryToWeekFinal = weekNo;
          this.searchedPoLines[index].deliveryEndDateFinal = this.dateOfYear1;
        }
      }
    );
  }

  // setDeliveryFromWeek(value, index) {
  //   this.responseMessage = '';
  //   let myDate = new Date(value);
  //   let weekNo = this.getWeekNumber(myDate).toString();
  //   let year = value.getFullYear();
  //   let mnth = ("0" + (myDate.getMonth() + 1)).slice(-2);
  //   let day = ("0" + myDate.getDate()).slice(-2);
  //   if(weekNo.length == 1){
  //     weekNo = '0'+weekNo;
  //   }
  //   this.weekOfYear1 = year + weekNo;
  //   this.dateOfYear1 = [day, mnth, myDate.getFullYear()].join("/");
  //   this.searchedPoLines[index].deliveryFromWeek = this.weekOfYear1;
  //   this.searchedPoLines[index].delvStdDate = this.dateOfYear1;
  //   //this.searchedPoLines[index].deliveryOldStartDate = this.initialdelvStdDate[index];
  // }

  // getWeekNumber(d: Date): number {
  //   d = new Date(+d);
  //   d.setHours(0, 0, 0);
  //   d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  //   var yearStart = new Date(d.getFullYear(), 0, 1);
  //   var weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
  //   return weekNo;
  // }

  // setDeliveryToWeek(value, index) {
  //   this.responseMessage = '';
  //   let myDate = new Date(value);
  //   let weekNo = this.getWeekNumber(myDate).toString();
  //   let year = value.getFullYear();
  //   let mnth = ("0" + (myDate.getMonth() + 1)).slice(-2);
  //   let day = ("0" + myDate.getDate()).slice(-2);
  //   if(weekNo.length == 1){
  //     weekNo = '0'+weekNo;
  //   }
  //   this.weekOfYear2 = year + weekNo;
  //   this.dateOfYear2 = [day, mnth, myDate.getFullYear()].join("/");
  //   this.searchedPoLines[index].deliveryToWeek = this.weekOfYear2;
  //   this.searchedPoLines[index].deliveryEndDate = this.dateOfYear2;
  //   //this.searchedPoLines[index].deliveryOldEndDate = this.initialdeliveryEndDate[index];
  // }

  creationDateCheck(index) {
    let details = {
      "poLineStatus": this.searchedPoLines[index].poLineStatus,
      "action": "dateEdit",
      "deliveryFromWeekFinal": this.searchedPoLines[index].deliveryFromWeekFinal,
      "deliveryToWeekFinal": this.searchedPoLines[index].deliveryToWeekFinal,
      "deliveryStartDateFinal": this.searchedPoLines[index].deliveryStartDateFinal,
      "deliveryEndDateFinal": this.searchedPoLines[index].deliveryEndDateFinal,
      "deliveryOldStartDate": this.searchedPoLines[index].deliveryOldStartDate,
      "deliveryOldEndDate": this.searchedPoLines[index].deliveryOldEndDate,
      "lastUpdateBy": this.username,
      "lastUpdateDate": this.lastUpdateDate
    }
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(details);
    let updateLineDetails = new DeleteDetails();
    updateLineDetails.id = this.searchedPoLines[index].id;
    updateLineDetails.type = 'poLine';
    updateLineDetails.updatedDataList = this.requestParameterModel;
    this._updatePoService.saveLineDetails(updateLineDetails).subscribe(
      (response: ResponseMessage) => {
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = 'PO Line Data saved successfully';
          this.searchedPoLines = [];
          this.myFunc();
        } else if (response != null && response.status != null && "UPE001" === response.errorCode.toUpperCase()) {
          this.responseMessage = response.message;
        }
      }
    );
    this.dateOfYear1 = "";
    this.weekOfYear1 = "";
    this.dateOfYear2 = "";
    this.weekOfYear2 = "";
  }

  saveChanges(index) {
    this.responseMessage = '';
    let poCreatedOn: any[] = this.searchedPoDetails.poCreateDate.split('/');
    let CreatedDate = new Date(poCreatedOn[2], poCreatedOn[1], poCreatedOn[0]);
    let deliveryFrmDate: any[] = this.searchedPoLines[index].deliveryStartDateFinal.split('/');
    let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1], deliveryFrmDate[0]);
    let deliveryEndDateFinal: any[] = this.searchedPoLines[index].deliveryEndDateFinal.split('/');
    let etDate = new Date(deliveryEndDateFinal[2], deliveryEndDateFinal[1], deliveryEndDateFinal[0]);
    if (this.searchedPoDetails.reqNumber != null && this.searchedPoDetails.poType == 'D') {
      if (stDate.getTime() <= etDate.getTime()) {
        this.creationDateCheck(index);
      }
      else {
        this.responseMessage = 'Delivery From Date Should be less than or equal to Delivery To Date';
      }
    }
    else {
      if (CreatedDate.getTime() <= stDate.getTime() && CreatedDate.getTime() <= etDate.getTime()) {
        if (stDate.getTime() <= etDate.getTime()) {
          this.creationDateCheck(index);
        }
        else {
          this.responseMessage = 'Delivery From Date Should be less than or equal to Delivery To Date';
        }
      }
      else {
        this.responseMessage = 'Delivery From Date and Delivery To Date should be greater than PO create date';
      }
    }
  }

  // checkDisable(poLineStatus) {
  //   this.poStatus = this.searchedPoDetails.poStatus;
  //   if (this.poStatus == 'New' || this.poStatus == 'Pending Cancel' || this.poStatus == 'Cancelled'
  //     || this.poStatus == 'Pending Recall' || this.poStatus == 'Pending Approval')  { 
  //     return true;
  //   }
  //   else{
  //   if ((poLineStatus == 'New' || poLineStatus == 'In-Transit') && this.checkHiphen != '-' && this.poStatus == 'Approved') {
  //     return false;
  //   }
  //   else {
  //     return true;
  //   }
  // }
  // }

  disableButton1() {
    this.poStatus = this.searchedPoDetails.poStatus;
    if (this.checkHiphen != '-' && this.poStatus != 'Pending Cancel') {
      return false;

    }
    else {
      return true;
    }
  }

  disabledFuncfromDate(concc, poLineStatus) {
    var iof = this.coCode.indexOf(concc);
    let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
    let userRoleIndexIT = this.userRole.indexOf('IT');
    this.poStatus = this.searchedPoDetails.poStatus;
    let isPrinted = this.searchedPoDetails.isPrinted;
    let isOtbProcessed = this.searchedPoDetails.isOtbProcessed;
    if (this.poStatus == 'Pending Cancel' || this.poStatus == 'Cancelled' || this.poStatus == 'Closed'
      || this.poStatus == 'Pending Recall' || poLineStatus == 'Cancelled' || poLineStatus == 'Pending Approval'
      || poLineStatus == 'Pending Recall' || poLineStatus == 'Closed' || isOtbProcessed == 'Y') {
      return true;
    }
    else {
      if ((this.poStatus == 'Approved' || this.poStatus == 'Pending Approval' || this.poStatus == 'Rejected') && isPrinted == 'Y' && (poLineStatus != 'New' || poLineStatus != 'In-Transit')) {
        return true;
      }
      else if ((this.poStatus == 'Approved' && (isPrinted == 'N' || isPrinted == null) && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-') ||
        (this.poStatus == 'New' && (isOtbProcessed == 'N' || isOtbProcessed == null) && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-') ||
        (this.poStatus == 'Rejected' && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-') ||
        (this.poStatus == 'Pending Approval' && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-')) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  disabledFuncToDate(concc, poLineStatus) {
    var iof = this.coCode.indexOf(concc);
    let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
    let userRoleIndexIT = this.userRole.indexOf('IT');
    this.poStatus = this.searchedPoDetails.poStatus;
    let isOtbProcessed = this.searchedPoDetails.isOtbProcessed;
    if (this.poStatus == 'Pending Cancel' || this.poStatus == 'Cancelled' || this.poStatus == 'Closed'
      || this.poStatus == 'Pending Recall' || poLineStatus == 'Cancelled' || poLineStatus == 'Pending Approval'
      || poLineStatus == 'Pending Recall' || poLineStatus == 'Closed' || isOtbProcessed == 'Y') {
      return true;
    }
    else {
      if (((this.poStatus == 'Approved' || this.poStatus == 'In-Transit') && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-') ||
        (this.poStatus == 'New' && (isOtbProcessed == 'N' || isOtbProcessed == null) && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-') ||
        (this.poStatus == 'Rejected' && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-') ||
        (this.poStatus == 'Pending Approval' && (poLineStatus == 'New' || poLineStatus == 'In-Transit' || poLineStatus == 'Approved') && iof >= 0 && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && this.checkHiphen != '-')) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  updateTable() {

    this.responseMessage = '';
    console.log(this.id);
    // let poType: string = 'poLine';
    let details = {
      "type": "poLine",
      "id": this.id,
      "userCode":this.username
    }
    console.log(details);

    this._updatePoService.getPoUpdated(details).subscribe(
      (response: ResponseMessage) => {
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = response.message;
        }
        else {
          this.responseMessage = response.message;
        }
        this.searchedPoLines = [];
        this.myFunc();
      }
    )
  }

  vendorEnableDisable(poStatus, isOtbProcessed, poLineStatus) {
    let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
    let userRoleIndexIT = this.userRole.indexOf('IT');

    if ((isOtbProcessed == 'Y' || (poStatus != 'Approved' && poStatus != 'New') || poLineStatus != 'New')) {
      return true;
    } else if (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) {
      return false;
    } else {
      return true;
    }
  }

}
