import { Injectable } from '@angular/core';
import { RequestParameterModel } from './request-parameter-model';
import { AssortmentDetails } from '../search-packing-master/assortment-details';
@Injectable()
export class NavigationLinkParameterService{
    constructor() { }
    id: string;
    details:RequestParameterModel[];
    distributionDetails:any = {distributionPageTitle:'',distributionPageDataArray:[],isPreviousPageRequisition:false};
    assortmentDetails : AssortmentDetails[] = [];
    artNumber: any;
    tot_details : any;
    podetails: any;
    lineId: any;
    flag:string;
    navigateFlag:any;
    searchHeaderData:any;
    searchPOHeaderData:any;
    pendingPoSearchData:any;
    poHeaderId:any;
    viewLocationPoNumber:any;
    ccSearchData:any;
	
	
	
	//RDS
	
	invoiceDetails:any;
    challanInvoiceID : string;
    challanInvoicePoDetails: any;
    poDetails:any;
    viewPOlist : any;
    updatePOlist : any;

    searchInvoiceHeaderData: any;
    searchasnHeaderData: any;
    grndetails:any;
    resupdetails:any;
    invoicedetails:any;
    asndetails:any;

    searchGRNHeaderData: any;
    grnNOdetails: any;
    challanAsnDetails: any;
    supplyNo: any;
    createGRNFlag: any;

    freightDetails:any;
    searchFreightHeaderData:any;

    invoiceID:any;
    searchResupHeaderData:any;
    challanResupDetails:any;

    totalCarton:any;
    asnPageNumber:Number;
    invoiceNumber:string;
    invStatus:string;

    createShipmentArray:any;
    createShipmentMessage:any;
}
