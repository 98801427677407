import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReportHeader } from './report-header';
import { RdsReportShortageService } from './rds-report-shortage.service';
import { ReportGeneration } from '@/common/report-generation';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ReportSearch } from './report-header-search';
import { Concept } from '../../common/concept';
import { ReportNames } from '../../common/reports-names';
import { OrderType } from '../../common/orderType';

@Component({
  selector: 'app-rds-report-shortage',
  templateUrl: './rds-report-shortage.component.html',
  styleUrls: ['./rds-report-shortage.component.css']
})
export class RdsReportSHORTAGEComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myform', { static: false }) myForm: ElementRef;
  enable = false;
  shopEnable = true;
  regionEnable = true;
  distEnable = true;
  destinationEnable = false;
  dateEnable = false;
  weekEnable = false;
  regions = [{ name: 'North (N)', value: 'N' },
  { name: 'South (S)', value: 'S' },
  { name: 'East (E)', value: 'E' },
  { name: 'West (W)', value: 'W' }];
  articleType = [{name:'Fresh',value:'FRESH'},
                 {name:'Defective',value:'DEFECTIVE'},
                 {name:'Claimed',value:'CLAIMED'}];
  reportModel = new ReportHeader();
  requestParameterModel: RequestParameterModel[];
  searchParameterModel = new ReportSearch();
  concepts: Concept[];
  conceptswithAll: any[] = [];
  conceptsForRetail: any[] = [];
  reportNames: ReportNames[];
  username: string;
  coCode: any;
  responseMessage: string;
  sucessMeg1: string;
  suppNoLength: boolean = false;
  asyncSuccess: string = 'Failed';
  loginUserEmail: string;
  generatebutton: string = 'enable';
  sucessMeg: string;
  showDiv = {
    Daily: false,
    Week: false,
    Fortnightly: false,
    Monthly: false
  }
  showDiv1 = {
    Factory: false,
    Vendor: false,
  }
  showReportList = {
    Reportname: false
  }
  fromWeek: string;
  errorMessage: string;
  payloadBataWeek = {};
  selectedValue = 'M';
  fromDate: string;
  arr = ['fromDate', 'toDate'];
  selectedVal: String;
  date: Date;
  toDate: string;
  ShopCodes: any[] = [];
  DistrictCodes: any[] = [];
  names: any[];
  onlyPerfix: any[] = [];
  uniquerper: any[] = [];
  disableReportList = true;
  selectedPrefix: string;
  selectedList: any[] = [];

  constructor(private _router: Router, private _generateReportService: RdsReportShortageService,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
    // this.searchParameterModel.stockTypeDesc = this.articleType[0].name;
    // this.searchParameterModel.stockTypeCode = this.articleType[0].value;

    this.reportModel.reportFormatType = 'xlsx';

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.loginUserEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        this.reportNames = data;
        this.reportNames.forEach(element => {
          let reportPrefixOnly: {} = element.reportName.split("_");
          let rsp_prf = {
            "perfix": reportPrefixOnly[0],
            "reportName": reportPrefixOnly[1],
            "reportKey": element.reportKey
          };
          this.onlyPerfix.push(rsp_prf);
        });
        this.uniquerper = [...new Set(this.onlyPerfix.map(x => x.perfix))];
        this.onSelectPerfix("SHORTAGE");
      }
    )

    this._generateReportService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        this.concepts.forEach(
          concept => {
            this.conceptswithAll.push(concept);
            if (concept.division == 'RETAIL' && concept.concCode != 'N') {
              this.conceptsForRetail.push(concept);
            }
          }
        );
        let conc = new Concept();
        conc.concCode = 'ALL';
        conc.concept = 'ALL';
        conc.description = 'ALL CONCEPT';
        this.conceptswithAll.unshift(conc);
        //this.searchParameterModel.concept = 'ALL';
      }
    )
  }

  clearErrorMsg() {
    this.errorMessage = '';
    this.responseMessage = '';
  }

  onSelectConcept(value) {
    this.clearErrorMsg();
    this.loaDer.nativeElement.style.display = 'block';
    this.ShopCodes = [];
    this.DistrictCodes = [];
    let divisionCode: string;
    this.searchParameterModel.concept = undefined;
    this.concepts.filter(cs => cs.concCode === this.searchParameterModel.conceptCode).forEach(
      div => {
        if (value != 'ALL') { 
        this.searchParameterModel.concept = (div.concCode +'-'+ div.description);
        }
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
      }
    )
    let detail: any;
    if (value != 'ALL') {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': divisionCode,
        'userCode': this.username,
        'conceptCode': this.searchParameterModel.conceptCode
      }
    }
    else {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': '5',
        'userCode': this.username
      }
    }
    const requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.distEnable = false;
      this.regionEnable = false;
      this._generateReportService.getLocations(requestParameterModel).subscribe(
        (res: any) => {
          this.ShopCodes = [];
          this.DistrictCodes = [];
          if (res.dataList != null) {
            res.dataList.forEach(
              data => {
                this.ShopCodes.push(data);
                this.DistrictCodes.push(data);
                this.searchParameterModel.regionCode = undefined;
                this.searchParameterModel.rdcCode = undefined;
              }
            );
            // const details =
            // {
            //   "shopNumber": "ALL",
            //   "shopName": "All RDC CODE"
            // };
            // this.ShopCodes.unshift(details);
          }
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    else {
      this.shopEnable = true;
      this.distEnable = true;
      this.regionEnable = true;
      this.searchParameterModel.regionCode = undefined;
      this.searchParameterModel.rdcCode = undefined;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  public async OnClickGenerate(myForm): Promise<void> {
    this.responseMessage = '';
    this.errorMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.loaDer.nativeElement.style.display = 'block';
    let switchFlag: any;
    let reportKey: any;
    let reportName: any;
    this.sucessMeg = 'Success';
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        switchFlag = report.switchingFlag;
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    let userDetails = {
      "name": "userId",
      "value": this.username
    }
    this.requestParameterModel.push(userDetails);
    console.log('requesting parameters inside updatedDatalist' + JSON.stringify(this.requestParameterModel));
    let details = new ReportGeneration();
    if (switchFlag == 'N') {
      console.log('Report will get generated online');
      if(this.searchParameterModel.conceptCode != null){
      details.reportFormatType = this.reportModel.reportFormatType;
      details.reportInputRequestList = this.requestParameterModel;
      this.callingServiceWithoutBlob(details);
      this.loaDer.nativeElement.style.display = 'none';
    } else{
      this.errorMessage = 'Concept Code is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
    }
  }
    else {
      console.log('Report will get generated offline');
      let detail = {
        "name": "reportKey",
        "value": reportKey
      }
      let nameDetail = {
        "name": "reportName",
        "value": reportName
      }
      this.requestParameterModel.push(detail);
      this.requestParameterModel.push(nameDetail);
      console.log(JSON.stringify(this.requestParameterModel));
      this._generateReportService.offlineReportGeneration(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          if (response.status == 'success') {
            // this.responseMessage = response.message;
            this.asyncSuccess = 'success';
            this.responseMessage = "Your report will be generated soon, you will be notified over mail. You can check the progress in View Report Status.";
          }
          else {
            this.asyncSuccess = 'Failed';
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.asyncSuccess = 'Failed';
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
  }

  callingServiceWithoutBlob(details) {
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    let reportDetails =
    {
      "name": "reportName",
      "value": reportName
    }
    this.requestParameterModel.push(reportDetails);
    let reportKeySelected =
    {
      "name": "reportKey",
      "value": reportKey
    }
    this.requestParameterModel.push(reportKeySelected);
    this.loginUserEmail
    let userSelectedEmail =
    {
      "name": "userEmail",
      "value": this.loginUserEmail
    }
    this.requestParameterModel.push(userSelectedEmail);
    if (this.searchParameterModel.conceptCode === 'ALL') {
      let index = this.requestParameterModel.findIndex(cs => cs.name == 'conceptCode');
      this.requestParameterModel.splice(index, 1);
    }
    details.reportInputRequestList = this.requestParameterModel;
    console.log(JSON.stringify(details));

      if (this.searchParameterModel.rdcCode != null || this.searchParameterModel.regionCode != null) {

        if ((this.searchParameterModel.fromWeek != null && this.searchParameterModel.toWeek != null) ||
        (this.searchParameterModel.fromDate != undefined && this.searchParameterModel.toDate != undefined)) { 

          if (this.reportModel.reportKey === 'S_FV') {
            console.log(this.searchParameterModel.stockTypeCode);
            console.log(this.searchParameterModel.stockTypeDesc);

            let statusCheck: string;
           // statusCheck = this.datecheck(this.searchParameterModel.fromDate, this.searchParameterModel.toDate);
           statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.fromWeek, this.searchParameterModel.toWeek,'From Week','To Week');
            if (statusCheck == 'success') {
              if(this.searchParameterModel.stockTypeCode != null && this.searchParameterModel.stockTypeDesc != null){

              this.callReportService(details);
            }
            else{
              this.errorMessage = "Stock Type is REQUIRED";
            }
            
          } else{
            this.errorMessage = statusCheck;
          }
          }
        else {
          this.callReportService(details);
        }
      } 
      else {
        this.errorMessage = 'Either "From Week & To Week" / "From Date & To Date" is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
      } 
    }else {
        this.errorMessage = 'Either "RDC Code" / "Region" is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
      }
  }

  callReportService(details) {
    this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.reportKey).subscribe(
      (response: any) => {
        if (response.status == 'Success') {
          this.asyncSuccess = 'success';
          this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          this.loaDer.nativeElement.style.display = 'none';
          this.generatebutton = 'disable';
          this.delay(5000).then(any => {
            this.generatebutton = 'enable';
          });
        }
        else {
          this.asyncSuccess = 'Failed';
          this.errorMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.asyncSuccess = 'Failed';
        this.errorMessage = err;
        this.loaDer.nativeElement.style.display = 'none';
      });
  }

  changeDate(value, key) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    let myDate = new Date(value);
    let dateOfYear1 = this._utilsService.transformDateSearchchange(myDate);
    if (key == 'fromDate') {
      this.searchParameterModel.fromDate = dateOfYear1;
    }
    else {
      this.searchParameterModel.toDate = dateOfYear1;
    }
    if (value != '0: undefined') {
      this.weekEnable = true;
    }
    else {
      this.weekEnable = false;
    }
  }

  changeSelectedDate(todate, fromdate) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    // let deliveryToDate: any[] = todate.split('/');
    // let etDate = new Date(deliveryToDate[2], deliveryToDate[1], deliveryToDate[0]);
    this.requestParameterModel.filter(cs => cs.name == 'fromDate' || cs.name == 'toDate').forEach(
      each => {
        let deliveryFrmDate: any[] = each.value.split('/');
        let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1, deliveryFrmDate[0]);
        each.value = this._utilsService.transformDateSearchchange(stDate);
      }
    )
  }

  datecheck(fromDate, toDate) {
    this.clearErrorMsg();
    let status: boolean = false;
    if (fromDate != undefined || toDate != undefined) {
      if (new Date(fromDate).getTime() > 0 && new Date(toDate).getTime() > 0) {
        console.log(fromDate);
        console.log(toDate);
        if ((new Date(fromDate).getTime() == new Date(toDate).getTime()) || (fromDate == toDate)) {
          console.log('equal');
          status = true;
        }
        else if ((new Date(fromDate).getTime() < new Date(toDate).getTime())) {
          console.log('greater');
          status = true;
        }
        else {
          console.log('smaller');
          status = false;
          this.errorMessage = "From Date should be smaller than To Date";
          this.loaDer.nativeElement.style.display = 'none';
        }
      }
      else {
        this.errorMessage = "Please select both dates";
        this.loaDer.nativeElement.style.display = 'none';
      }
    }
    else {
      status = true;
    }
    return status;
  }

  onClickReset() {
    this.searchParameterModel = new ReportSearch();
    console.log('reset report search details');
    //this.searchParameterModel.concept = 'ALL';
    // this.searchParameterModel.stockTypeDesc = this.articleType[0].name;
    // this.searchParameterModel.stockTypeCode = this.articleType[0].value;

    this.responseMessage = '';
    this.errorMessage = "";
    this.sucessMeg1 = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.regionEnable = true;
    this.enable = false;
    this.shopEnable = true;
    this.distEnable = true;
    this.dateEnable = false;
    this.weekEnable = false;
    this.searchParameterModel.region = "";
    this.showReportList.Reportname = false;
  }

  setData() {
    console.log('removed');
    this.reportModel.reportFormatType = 'xlsx';
    this.responseMessage = '';
    this.sucessMeg1 = '';
    this.errorMessage = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    // this.searchParameterModel.stockTypeDesc = this.articleType[0].name;
    // this.searchParameterModel.stockTypeCode = this.articleType[0].value;

    this.onClickReset();
    //this.searchParameterModel.concept = 'ALL';
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  regionChangeShopCode(regionVal) {
    this.clearErrorMsg();
    if (regionVal != '0: undefined') {
      this.shopEnable = true;
      this.distEnable = true;
    }
    else {
      this.shopEnable = false;
      this.distEnable = false;
    }
  }

  // onBlurWeek(value) {
  //   if (value != null) {
  //     this.dateEnable = true;
  //   }
  //   else {
  //     this.dateEnable = false;
  //   }
  // }

  changeDistrictCode(distValue) {
    this.clearErrorMsg();
    if (distValue != '0: undefined') {
      this.regionEnable = true;
      this.shopEnable = true;
    }
    else {
      this.regionEnable = false;
      this.shopEnable = false;
    }
  }

  onBlurCode(value) {
    this.clearErrorMsg();
    if (value != '0: undefined') {
      this.regionEnable = true;
      this.distEnable = true;
    }
    else {
      this.regionEnable = false;
      this.distEnable = false;
    }
  }
  
  onSelectPerfix(event) {
    // if(event != undefined){
    //  this.showReportList.Reportname = true;
    // }
    console.log(event);
    this.clearErrorMsg();
    this.selectedList = [];
    this.onlyPerfix.filter(cs => cs.perfix === event).forEach(
      reportkN => {
        this.selectedList.push(reportkN);

      }
    )
    console.log(this.selectedList);

  }

  stockChange(val){
    this.errorMessage = "";
    console.log(val);
    // if(val == this.articleType[0].name){
    //   this.searchParameterModel.stockTypeCode = this.articleType[0].value;
    // }else if(val == this.articleType[1].name){
    //   this.searchParameterModel.stockTypeCode = this.articleType[1].value;
    //   console.log(this.searchParameterModel.stockTypeCode);
    // }else{
    //   this.searchParameterModel.stockTypeCode = this.articleType[2].value;
    // }
    let value = val.split(':')[1].trim();
    this.articleType.filter(cs => cs.name == value).forEach(
      each => {
        this.searchParameterModel.stockTypeCode = each.value;
      }
    );
    console.log(this.searchParameterModel.stockTypeCode);
  }

  currentDate() {
    this.clearErrorMsg();
    this.date = new Date();
    this.searchParameterModel.toDate = this._utilsService.transformDate1(this.date);
    this.searchParameterModel.fromDate = this._utilsService.transformDate1(this.date);
    console.log(this.toDate);
  }

}
