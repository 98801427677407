import { Batches } from '@/batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FreightMasterService } from './freight-master.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@/common/utils-service';
import { importsavevar } from '@/common/import-save-var';
import { searchreq } from './search-req-payload';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';

@Component({
  selector: 'app-route-master',
  templateUrl: './freight-master.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', './freight-master.component.css']
})
export class FreightMasterComponent implements OnInit {

  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  routeForm: FormGroup;
  routeResultForm: FormGroup;
  profileForm: FormGroup;
  isDisplayResult: boolean = true;
  responseMessage: any;
  statusMessage:string;
  concepts: any;
  downloadUrlList: Batches[] = [];
  downloadUrl: string;
  checkService = false;
  uploadFileName = 'No File Chosen';
  uploadKey = 'FREIFGT_MASTER';
  searchData = new searchreq();
  isPrimary: boolean = false;
  routeDetails: any;
  headerData:any;
  userCode:any;
  
  details = [];
  updateDetails = [];

  constructor(private fb: FormBuilder,private _router: Router,private freightMasterService: FreightMasterService, private httpClient: HttpClient,private _utilsService: UtilsService,private navigationLinkParameterService: NavigationLinkParameterService) { 
    this.routeForm = this.buildrouteForm();
    this.routeResultForm = this.buildrouteResultForm();
    this.profileForm = this.buildProfileForm();
    // let sessUsername = sessionStorage.getItem('currentUser');
    // this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
  }

  ngOnInit() {
    setTimeout(() => {
   
    const sessUsername = sessionStorage.getItem('currentUser');
    console.log(sessUsername);
    const conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.profileForm.get('uploadedBy').setValue(JSON.parse(sessUsername).groupAssignmentModels[0].userCode);
     this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
     console.log(this.userCode);
    // this.inventoryModel.userCode = this.userCode;
    this.routeDetails=this.navigationLinkParameterService.freightDetails;
    this.headerData=this.navigationLinkParameterService.searchFreightHeaderData;
    this.navigationLinkParameterService.searchFreightHeaderData="";
    this.navigationLinkParameterService.freightDetails="";
    console.log(this.headerData);

    if(this.headerData !== undefined && this.headerData !== ''){
      this.routeForm = this.buildrouteForm();
      this.onSubmit(this.headerData);
      // return this.fb.group({
      //   frightType: [this.headerData.frightType],
      //   toLocationCode:[this.headerData.toLocationCode],
      //   typeOfSupply: [this.headerData.typeOfSupply],
      //   // transporterCode: [null],
      //   transporterName:[this.headerData.transporterName],
      //   exLocation:[this.headerData.exLocation],
      //   toLocation:[this.headerData.toLocation],
      //   supplierCode:[this.headerData.supplierCode],
      //   sourcingPartners:[this.headerData.sourcingPartners],
  
      // });
    }else{
      console.log("Else");
    }
    this.freightMasterService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        console.log(this.concepts); console.log('concepts');
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }, 100);
  }

  buildrouteForm() {
    if(this.headerData !== undefined){
      if(this.headerData.frightType ==="P"){
        this.isPrimary=true;
      }
      return this.fb.group({
        frightType: [this.headerData.frightType],
        toLocationCode: [this.headerData.toLocationCode],
        typeOfSupply: [this.headerData.typeOfSupply?this.headerData.typeOfSupply:''],
        // transporterCode: [null],
        transpoterName:[this.headerData.transpoterName],
        exLocation:[this.headerData.exLocation],
        toLocation:[this.headerData.toLocation],
        supplierCode:[this.headerData.supplierCode],
        sourcingPartners:[this.headerData.sourcingPartners],
  
      });

    }else{
      return this.fb.group({
        frightType: [""],
        toLocationCode: "",
        typeOfSupply: [""],
        // transporterCode: [null],
        transpoterName:"",
        exLocation:"",
        toLocation:"",
        supplierCode:"",
        sourcingPartners:"",
  
      });
    }
  }

  buildrouteResultForm(): FormGroup {
    return this.fb.group({ route: this.fb.array([]) });
  }

  buildProfileForm(): FormGroup {
    return this.fb.group({
      name: [''],
      file: [''],
      uploadKey: [''],
      uploadedBy: ['']
    });
  }

  onSubmit(formData: any) {

    console.log(formData);
    if(formData.frightType===""){
      this.statusMessage='error';
      this.responseMessage= 'Please select freight type.';
      return false;
    }else{
    this.details = [];
    this.routeResultForm = this.buildrouteResultForm();
    this.searchData = formData;
    console.log(this.searchData);
    let control = <FormArray>this.routeResultForm.controls.route;
    this.loaDer.nativeElement.style.display = 'block';
    this.freightMasterService.getSearch(this.searchData).subscribe(
      (response : any) => {
        console.log(response);
        if(response!== ""){
        this.isDisplayResult = true;
        this.responseMessage='';
        this.details = response;
        console.log(this.details);
        // response.forEach(
        //   rms => {
        //     control.push(this.fb.group({
        //       transporterCode:[{ value: rms.transporterCode, disabled: true}],
        //       transpoterName:[{ value: rms.transpoterName, disabled: true}],
        //       exLocation:[{ value: rms.exLocation, disabled: true}],
        //       toLocationCode: [{ value: rms.toLocationCode, disabled: true}],
        //       toLocation: [{ value: rms.toLocation, disabled: true}],
        //       supplierCode: [{ value: rms.supplierCode, disabled: true}],
        //       sourcingPartners: [{ value: rms.sourcingPartners, disabled: true}],
        //       typeOfSupply: [{ value: rms.typeOfSupply, disabled: true}],
        //       ftwrRtlCb: [{ value: rms.ftwrRtlCb, disabled: true}],
        //       ftwrWslCb: [{ value: rms.ftwrWslCb, disabled: true}],
        //       nonFtwrRtlCb: [{ value: rms.nonFtwrRtlCb, disabled: true}],
        //       nonFtwrWslCb: [{ value: rms.nonFtwrWslCb, disabled: true}],
        //       rateCb: [{ value: rms.rateCb, disabled: true}],
        //       groupCode: [{ value: rms.groupCode, disabled: true}],
        //       fullTruckLoadYn: [{ value: rms.fullTruckLoadYn, disabled: true}],
        //       perCartonYn: [{ value: rms.perCartonYn, disabled: true}],
        //       activeStatus:[{value:rms.activeStatus, disabled:true}],
        //     }))
        //   }
        // );
      }
        else{
          console.log('in else');
          this.statusMessage='error';
          this.responseMessage= 'No Data Found';
          this.isDisplayResult = false;
          this.loaDer.nativeElement.style.display = 'none';
          this.details = [];
        }
      }
      ,
      (error) => {
        console.error('some error occured! while fetching freight from server');
        console.log(error);
        this.statusMessage='error';
          this.responseMessage= 'No Data Found';
          this.isDisplayResult = false;
          this.loaDer.nativeElement.style.display = 'none';
          this.details = [];
        // this.routeResultForm = this.buildrouteResultForm();
        // this.isDisplayResult = false;
        // this.loaDer.nativeElement.style.display = 'none';
      }, () => {
        console.info('all data fetched. ');
        this.loaDer.nativeElement.style.display = 'none';}
    );
      }
  }


  onEdit(fm:any){
    console.log(fm);
  }
  resetForm(){
    this.isDisplayResult = false;
    
    this.responseMessage='';
    this.statusMessage='';
    this.buildrouteResultForm();
    this.routeForm = this.buildrouteForm();
    this.routeResultForm = this.buildrouteResultForm();
    this.profileForm = this.buildProfileForm();
    this.uploadFileName = 'No File Chosen';
    this.myInputVariable.nativeElement.value = '';
    this.details=[];
    this.updateDetails = [];
    this.isPrimary = false;
  }

  public async downloadTemplate(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    // this.UploadMsg = '';
    this.freightMasterService.getDownloadUrl().subscribe(
      (res: BatchesData) => {
        console.log(res);
        this.downloadUrlList = res['configParamModels'];
        console.log(this.downloadUrlList);
        this.downloadUrlList.forEach(element => {
        if (element.paramName === 'FREIGHT_MASTER') {
          console.log('FREIGHTMaster');
          this.downloadUrl = element.paramValue;
          console.log(this.downloadUrl);
          this.getDownloadFile(this.downloadUrl);
          return this.checkService = true;
        }
      });
      }
    );
  }

  public async getDownloadFile(downloadUrl): Promise<void> {
    
      // const fullDownloadUrl: string = downloadUrl + '' + 'ROUTE_MASTER' + '.xls';
      // console.log(fullDownloadUrl);
      const file = await this.httpClient.get<Blob>(downloadUrl, { responseType: 'blob' as 'json' }).toPromise();
      const blob = file;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'FREIGHT_MASTER' + '.xls';
      anchor.href = url;
      anchor.click();
      window.URL.revokeObjectURL(url);
      this.loaDer.nativeElement.style.display = 'none';

    
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
      this.profileForm.get('uploadKey').setValue(this.uploadKey);
      console.log(this.myInputVariable.nativeElement.value);
      console.log(this.myInputVariable.nativeElement.files);
    }
  }

  onChangeFreight(freight) {
    this.headerData=[];
    this.headerData.frightType=freight;
    this.buildrouteForm();
    this.routeForm = this.buildrouteForm();
    this.details=[];
    console.log(freight);
    if(freight === 'P') {
      this.isPrimary=true;
    }else{
      this.isPrimary=false;
    }
  }

  changeStatus(data){
    console.log(data);
    this.updateDetails=data;
  }
  updateStatus(stat){
    console.log(stat);
    console.log(this.updateDetails);
    this.updateDetails['activeStatus']=stat;
    console.log(this.updateDetails);
    this.freightMasterService.updateStatus(this.updateDetails).subscribe(
      (response : any) => {
        console.log(response);
        if(response!== ""){
          this.statusMessage='success';
        this.responseMessage='Data inactive successfully.';
        setTimeout(() => {
          this.onSubmit(this.searchData);
        },1000);
        
      }
        else{
          console.log('in else');
          this.statusMessage='error';
          this.responseMessage= 'Data inactive not successful';

          this.loaDer.nativeElement.style.display = 'none';
        }
      }
      ,
      (error) => {
        console.error('some error occured! while fetching freight from server');
        console.log(error);
        // this.routeResultForm = this.buildrouteResultForm();
        this.isDisplayResult = false;
        this.loaDer.nativeElement.style.display = 'none';
      }, () => {
        console.info('all data fetched. ');
        this.loaDer.nativeElement.style.display = 'none';}
    );
    
  }
  fileSubmit(){
  this.statusMessage = '';
  this.responseMessage = '';
  if (this.myInputVariable.nativeElement.files.length === 0) {
    this.responseMessage = 'Please select file';
    this.statusMessage = 'error';
    return false;
  } else {
    const newFileArray: any[] = this.profileForm.get('file').value.name.split('.');
    const newFileFormat = newFileArray[0].replace(/\s+/g, '') + '_' + this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
    const formData = new FormData();

    formData.append('name', this.profileForm.get('name').value);
    formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData.append('uploadedBy',this.userCode);
    formData.append('uploadKey', this.profileForm.get('uploadKey').value);
    
    this.loaDer.nativeElement.style.display = 'block';

    this.freightMasterService.upload(formData).subscribe(
      (response: importsavevar) => {
        this.responseMessage = '';
        this.statusMessage = '';
        if (response.status === 'success') {
          this.responseMessage = 'File is uploaded successfully';
          this.statusMessage = 'success';
          // this.enable = true;
          this.onGenerate();
        } else {
          this.responseMessage = response.message;
          // this.responseMessage.replace(/\\n/g, '<br/>');
          this.statusMessage = 'error';
          // this.enable = false;
        }
       this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
        // this.responseMessage.replace(/\\n/g, '<br/>');
        this.statusMessage = 'error';
        this.loaDer.nativeElement.style.display = 'none';
        // this.enable = false;
      }
    );
    this.uploadFileName = 'No File Chosen';
    this.profileForm = this.buildProfileForm();
    console.log(this.myInputVariable.nativeElement.value);
    this.myInputVariable.nativeElement.value = '';
    
  }
}

viewFreightDetails(freightDetails) {
  console.log(freightDetails);
  this.navigationLinkParameterService.freightDetails = freightDetails;
  this.navigationLinkParameterService.searchFreightHeaderData = this.searchData;
  this._router.navigate(['/freight-details']);

  }
  searchPayload() {
    const searchNavigationHold = {
      searchData: this.searchData,
      resultData: this.details
    };
    return searchNavigationHold;
  }
  onGenerate() {

    this.statusMessage = '';
    this.responseMessage = '';
    // this.UploadMsg = '';
  
    const uploadkeyValues: any = { 
       
        "uploadedBy": this.userCode,
        "uploadKeyValues" : this.uploadKey
      }
     
    this.loaDer.nativeElement.style.display = 'block';
    const importPo: any = this.freightMasterService.getGenerate(uploadkeyValues).subscribe(
      (response: any) => {
        if (response.status == 'Success') {
          this.responseMessage = "Freight information generated in System";
          this.statusMessage = 'success';
        } else {
          this.responseMessage = response.message;
          // this.responseMessage.replace(/\\n/g, '<br/>');
          this.statusMessage = 'error';
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
        // this.responseMessage.replace(/\\n/g, '<br/>');
        this.statusMessage = 'error';
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
    // this.enable = false;
  }
}
