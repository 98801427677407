export class AsnHeader {

    supplyNo: string;
    fromchallanInvoiceDT: Date;
    tochallanInvoiceDT: Date;
    consignorCode: string;
    asnStatus: string;
    inventoryStatus: string;
    // fromWeekchallanInvoiceDT: string;
    // toWeekchallanInvoiceDT: string;    
    // isGinSearch: string;
    // transportName: string;
    // transportCode: number;
    // resupplyNumber: string;
    // asnNumber: string;
    // challanInvoiceDT: string;
    // invoiceValue: string;
    
    // consignorName: string;
    // consigneeCode: string;
    // consigneeName: string;
    // cartonNumber: string;
    // shopNumber: string;
    // ewayBillNumber: string;
    // lastUpdatedBy: string;
    // shipmentId: any;
    userCode: string;
    bataWeekTo:string;
    bataWeekFrom:string;
    asnNumber:string;
    yrWeekFrom:string;
    yrWeekTo:string;
    partyNumber:string;
    consignorType:string;
    cartonNumber:string;
    searchType:string;
    pageNumber:Number;
    pageSize:Number;
    recordCount:any;
    pairQuantity:any;
}

