import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RequestParameterModel } from '../../common/request-parameter-model'
import { BilApiUrl } from '../../common/bil-api-url';
import { PO_NUMBER } from '../../common/field-name-list';
import { DeleteDetails } from '@/common/delete-details';

@Injectable({
  providedIn: 'root'
})
export class UpdatePendingPoService {

  purchase_order_details_url: string = BilApiUrl.PENDING_PO_DETAILS_URL;
  delete_po_url = BilApiUrl.DELETE_DETAILS;
  date_change_url: string = BilApiUrl.DATE_CHANGE;

  constructor(private _http: HttpClient) { }

  /*
 This class call the PO api service which return the matching 
 purchase orders as per the search criteria.
 */
  getPurchaseOrderBySearchCriteria(purchaseOrderNumber: string) {

    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = PO_NUMBER;
    requestParameterModel.value = purchaseOrderNumber;
    purchase_order_search_parameters.push(requestParameterModel);
    return this._http.post(this.purchase_order_details_url, purchase_order_search_parameters);
  }

  getPoDeleted(delete_purchase_order_parameters: DeleteDetails) {
    //  console.log('Details getting passed to service'+JSON.stringify(delete_purchase_order_parameters));
    //console.log('Header id that is getting deleted'+delete_purchase_order_parameters.id);
    return this._http.post(this.delete_po_url, delete_purchase_order_parameters);
  }

  getWeekAndYear(datedetails) {
    let details = {
      "name": "date",
      "value": datedetails
    }
    return this._http.post(this.date_change_url, details);
  }
}
