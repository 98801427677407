/*REQUISITION START*/

/*PA DATE ERROR MESSAGE */
export const FROM_PA_DATE__REQUIRED : string = 'From Invoice Date is required.';
export const TO_PA_DATE__REQUIRED : string = 'To Invoice Date is required.';
export const PA_DATE__GREATER : string = 'To Invoice Date must be greater than From Invoice Date';

/* PR DATE ERROR MESSAGE */
export const FROM_PR_DATE__REQUIRED : string = 'From Invoice Date is required.';
export const TO_PR_DATE__REQUIRED : string = 'To Invoice Date is required.';
export const PR_DATE__GREATER : string = 'To Invoice Date must be greater than From Invoice Date';

/*REQUISITION END*/

/* ADMIN PAGES */
export const NEGATIVE : string = 'Negative numbers not allowed.';
