export class RequisitionAssortment {
    poAssortmentId: number;
    articleSize: number;
    pairQty : number;
    totalPairQty: number;
    isActive: string;
    status: string;
    createdBy: string; 
    artSizeRatio:number;
    sizeLabel:string;
    lastUpdatedBy:string;
    lastUpdateDate:Date;
    createdDate:Date;
}