/*
 * This class is replica of corresponding java enitiy class PurchaseOrderHeader. 
 * It is used for capturing purchase-order-header information from search-purchase-order Form.
 */
export class PurchaseOrderSearchForm {
    conceptCode:string;
    poNumber:string;
    deliveryFromWeek:string;
    deliveryToWeek:string;
    artNumber:string;
    artName:string;
    categoryNumber:string;
    categoryName:string;
    poType:string;
    deliveryFromMonth:string;
    suppCode:string;   
    poStatus:string;  
    suppName:string;
    orderPrefixConcept:string;    
    constructor(){}
}