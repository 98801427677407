import { Batches } from '@/batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteMasterService } from './route-master.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@/common/utils-service';
import { importsavevar } from '@/common/import-save-var';
import { searchreq } from './search-req-payload';
import { savereq } from './save-req-payload';

@Component({
  selector: 'app-route-master',
  templateUrl: './route-master.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', './route-master.component.css']
})
export class RouteMasterComponent implements OnInit {

  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  routeForm: FormGroup;
  routeResultForm: FormGroup;
  profileForm: FormGroup;
  isDisplayResult: boolean = false;
  responseMessage: any;
  statusMessage:string;
  concepts: any;
  downloadUrlList: Batches[] = [];
  downloadUrl: string;
  checkService = false;
  uploadFileName = 'No File Chosen';
  uploadKey = 'ROUTE_MASTER';
  searchData = new searchreq();
  saveData = new savereq();
  disable : boolean;
  routeDetails: any;
  currentPage : number = 1;
  itemPerPage:number = 20;
  // indexUpdate: number;
  dFlag : boolean = false ;
  enable = false;
  indexDeactivate: number;
  userName: string;
  ShopCodes: any[] = [];
  activeStatus: any[] = [];

  constructor(private fb: FormBuilder,private _router: Router,private routeMasterService: RouteMasterService, private httpClient: HttpClient,private _utilsService: UtilsService) { 
    this.routeForm = this.buildrouteForm();
    this.routeResultForm = this.buildrouteResultForm();
    this.profileForm = this.buildProfileForm();
   
  }
  
  onClickBack() { this._router.navigate(['']); }

  ngOnInit() {
    setTimeout(() => {
   
    // const sessUsername = sessionStorage.getItem('currentUser');
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    const conceptQueryMas = [{ 'name': 'email', 'value': this.userName }];
    
    this.profileForm.get('uploadedBy').setValue(this.userName);
    
    this.routeMasterService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        console.log(this.concepts); console.log('concepts');
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
    this.getShopCode();
  }, 100);
  }

  getShopCode(){
    let detail = [{
      "name":"shopAgencyCode",
      "value": '5',
          },{
            "name":"userCode",
            "value": this.userName
    }]
    this.routeMasterService.getShopCode(detail).subscribe(
      (res: any) => {
        this.ShopCodes = [];
        res.dataList.forEach(
          shopCode => {
            this.ShopCodes.push(shopCode);
          }
        );
      }
    );
  }

  buildrouteForm() {
    return this.fb.group({
      concept: [null],
      destShopCode: [null],
      sourceShopCode: [null],
      // route: [null],
      // subRoute: [null]
    });
  }

  buildrouteResultForm(): FormGroup {
    return this.fb.group({ route: this.fb.array([]) });
  }

  buildProfileForm(): FormGroup {
    return this.fb.group({
      name: [''],
      file: [''],
      uploadKey: [''],
      uploadedBy: ['']
    });
  }

  onSearch(formData:any){
    this.routeResultForm = this.buildrouteResultForm();
    this.searchData = formData;
    this.routeDetails=[];
    this.statusMessage='';
    this.responseMessage='';
    // let control = <FormArray>this.routeResultForm.controls.route;
    this.loaDer.nativeElement.style.display = 'block';
    if(this.searchData.concept != null || this.searchData.destShopCode != null || this.searchData.sourceShopCode != null){
    this.routeMasterService.getSearch(this.searchData).subscribe(
      (response : any) => {
        if(response!= ""){
        this.isDisplayResult = true;
        // this.responseMessage='';
        response.forEach(
          rms => {
           const e : searchreq = rms;
           this.routeDetails.push(e);
           this.disable = false;
          
          }
         
        );
        this.onUpdate(this.disable);
      }
        else{
          console.log('in else');
          this.statusMessage='error';
          this.responseMessage= 'No Data Found';
          this.isDisplayResult = false;
          this.loaDer.nativeElement.style.display = 'none';
        }
      },
      (error) => {
        console.error('some error occured! while fetching routes from server');
        console.log(error);
        this.statusMessage = 'error';
        this.responseMessage = error;
        this.routeResultForm = this.buildrouteResultForm();
        this.isDisplayResult = false;
        this.loaDer.nativeElement.style.display = 'none';
      }, () => {
        console.info('all disounts fetched. ');
        this.loaDer.nativeElement.style.display = 'none';}
    );}
    else{
      this.statusMessage = 'error';
      this.responseMessage = "Please select any of the search fields";
      this.loaDer.nativeElement.style.display = 'none';
    }
  }
  onUpdate(disable: any) {
    
    let control = <FormArray>this.routeResultForm.controls.route;
    // this.loaDer.nativeElement.style.display = 'block';
    console.log(this.routeDetails);
    this.routeDetails.forEach(
      rms =>{
    
            control.push(this.fb.group({
              destShopCode: [{ value: rms.destShopCode, disabled: true}],
              shopName: [{ value: rms.destShopName, disabled: true}],
              concept: [{ value: rms.concept, disabled: true}],
              route: [{ value: rms.route, disabled: true}],
              subRoute: [{ value: rms.subRoute, disabled: true}],
              // oracleSeque: [{ value: rms.oracleSequence, disabled: disable}],
              routeSeque: [{ value: rms.routeSequence, disabled: true}],
              sourceRDC: [{ value: rms.sourceShopCode, disabled: true}],
              customerCode: [{ value: rms.customerCode, disabled: true}],
              activeStatus: [{ value: rms.activeStatus, disabled: true}]
            }))
            this.activeStatus.push(rms.activeStatus);
          });
          console.log(control); console.log('control');
          console.log(this.activeStatus);
          
  }

  resetForm(){
    this.isDisplayResult = false;
    this.dFlag = false;
    this.enable = false;
    this.responseMessage='';
    this.statusMessage='';
    this.buildrouteResultForm();
    this.profileForm = this.buildProfileForm();
    this.uploadFileName = 'No File Chosen';
    this.myInputVariable.nativeElement.value = '';
  }

  public async downloadTemplate(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    // this.UploadMsg = '';
    this.routeMasterService.getDownloadUrl().subscribe(
      (res: BatchesData) => {
        console.log(res);
        this.downloadUrlList = res['configParamModels'];
        console.log(this.downloadUrlList);
        this.downloadUrlList.forEach(element => {
        if (element.paramName == 'ROUTE_MASTER') {
          console.log('RouteMaster');
          this.downloadUrl = element.paramValue;
          console.log(this.downloadUrl);
          this.getDownloadFile(this.downloadUrl);
          return this.checkService = true;
        }
      });
      }
    );
  }

  public async getDownloadFile(downloadUrl): Promise<void> {
    
      // const fullDownloadUrl: string = downloadUrl + '' + 'ROUTE_MASTER' + '.xls';
      // console.log(fullDownloadUrl);
      const file = await this.httpClient.get<Blob>(downloadUrl, { responseType: 'blob' as 'json' }).toPromise();
      const blob = file;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'ROUTE_MASTER' + '.xlsx';
      anchor.href = url;
      anchor.click();
      window.URL.revokeObjectURL(url);
      this.loaDer.nativeElement.style.display = 'none';

    
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
      this.profileForm.get('uploadKey').setValue(this.uploadKey);
      console.log(this.myInputVariable.nativeElement.value);
      console.log(this.myInputVariable.nativeElement.files);
    }
  }

  fileSubmit(){
  this.statusMessage = '';
  this.responseMessage = '';
  this.routeResultForm = this.buildrouteResultForm();
  this.routeForm = this.buildrouteForm();
  if (this.myInputVariable.nativeElement.files.length == 0) {
    this.responseMessage = 'Please select file';
    this.statusMessage = 'error';
    return false;
  } else {
    const newFileArray: any[] = this.profileForm.get('file').value.name.split('.');
    const newFileFormat = newFileArray[0].replace(/\s+/g, '') + '_' + this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
    const formData = new FormData();

    formData.append('name', this.profileForm.get('name').value);
    formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData.append('uploadedBy',this.userName);
    formData.append('uploadKey', this.profileForm.get('uploadKey').value);
    
    this.loaDer.nativeElement.style.display = 'block';

    this.routeMasterService.upload(formData).subscribe(
      (response: importsavevar) => {
        this.responseMessage = '';
        this.statusMessage = '';
        if (response.status == 'success') {
          this.responseMessage = 'File is uploaded successfully';
          this.statusMessage = 'success';
           this.enable = true;
        } else {
          this.responseMessage = response.message;
          // this.responseMessage.replace(/\\n/g, '<br/>');
          this.statusMessage = 'error';
           this.enable = false;
        }
       this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
        // this.responseMessage.replace(/\\n/g, '<br/>');
        this.statusMessage = 'error';
        this.loaDer.nativeElement.style.display = 'none';
        this.enable = false;
      }
    );
    this.uploadFileName = 'No File Chosen';
    this.profileForm = this.buildProfileForm();
    console.log(this.myInputVariable.nativeElement.value);
    this.myInputVariable.nativeElement.value = '';
    
  }
}

// onEdit(index:any){
//   this.indexUpdate = this.getAbsoluteIndex(index);
//   console.log(this.indexUpdate); console.log('indexUpdate');

//     this.routeResultForm.get(['route', this.indexUpdate]).get('route').enable();
//     this.routeResultForm.get(['route', this.indexUpdate]).get('subRoute').enable();
//     this.routeResultForm.get(['route', this.indexUpdate]).get('oracleSeque').enable();
//     this.routeResultForm.get(['route', this.indexUpdate]).get('routeSeque').enable();
  
// }

private getAbsoluteIndex(index: any) {
  let currentPageNumber = this.currentPage;
  let absoluteIndex = this.itemPerPage * (currentPageNumber - 1) + index;
  return absoluteIndex;
}

onSave(event:any,index:any){
  console.log(index); console.log('index');
  console.log(this.indexDeactivate); console.log('indexDeactivate');
  this.loaDer.nativeElement.style.display = 'block';
  this.statusMessage='';
  this.responseMessage='';
  if(this.dFlag != true){
  this.saveData = this.routeDetails[index];
  console.log(this.saveData); console.log('saveData'); 
  console.log(this.routeDetails[index]); console.log('routedetails with index');
  this.saveData.lastUpdatedBy = this.userName;
  this.saveData.route = this.routeResultForm.get(['route',index]).get('route').value;
  this.saveData.subRoute = this.routeResultForm.get(['route',index]).get('subRoute').value;
  // this.saveData.oracleSequence = this.routeResultForm.get(['route',index]).get('oracleSeque').value;
  this.saveData.routeSequence = this.routeResultForm.get(['route',index]).get('routeSeque').value;
  this.saveData.lastUpdatedDate = null;
  this.routeMasterService.getUpdate(this.saveData).subscribe(
    (response:any)=>{
     
      if(response.status == "Success"){
        this.statusMessage = 'success';
        this.responseMessage = 'Data has been updated successfully';
        setTimeout(() => {
          this.onSearch(this.searchData);
        }, 500);        
      }
      else{
        this.statusMessage = 'error';
        this.responseMessage = response.message;
      }
      this.loaDer.nativeElement.style.display = 'none';
    },(err)=>{
      this.statusMessage = 'error';
      this.responseMessage = err;
      this.loaDer.nativeElement.style.display = 'none';
    }
  );
  }
 
  else {
    this.saveData = this.routeDetails[this.indexDeactivate];
    console.log(this.saveData); console.log('saveData'); 
    console.log(this.routeDetails[this.indexDeactivate]); console.log('routedetails with indexDeactivate');
    this.saveData.lastUpdatedBy = this.userName;
    this.saveData.activeStatus = 'N';
    this.saveData.lastUpdatedDate = null;
    this.routeMasterService.getUpdate(this.saveData).subscribe(
      (response:any)=>{
       
        if(response.status == "Success"){
          this.statusMessage = 'success';
          this.responseMessage = 'Data has been deactivated successfully';
          setTimeout(() => {
            this.onSearch(this.searchData);
          }, 3000); 
        }
        else{
          this.statusMessage = 'error';
          this.responseMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },(err)=>{
        this.statusMessage = 'error';
        this.responseMessage = err;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }
  //  else{
  //   this.loaDer.nativeElement.style.display = 'none';
  //   alert('Nothing to update or invalidate');
  // }
}

onDeactivate(event:any, index:any){
  this.indexDeactivate = index;
  this.dFlag = false;
}

confirmInvalidate(event:any){
  this.dFlag = true;
  this.onSave(event,this.indexDeactivate);
}

onGenerate() {

  this.statusMessage = '';
  this.responseMessage = '';
  // this.UploadMsg = '';

  const uploadkeyValues: any = { 
     
      "uploadedBy": this.userName,
      "uploadKeyValues" : "ROUTE_MASTER"
    }
   
  this.loaDer.nativeElement.style.display = 'block';
  const importPo: any = this.routeMasterService.getGenerate(uploadkeyValues).subscribe(
    (response: any) => {
      if (response.status == 'Success') {
        this.responseMessage = "Route information generated in System";
        this.statusMessage = 'success';
      } else {
        this.responseMessage = response.message;
        // this.responseMessage.replace(/\\n/g, '<br/>');
        this.statusMessage = 'error';
      }
      this.loaDer.nativeElement.style.display = 'none';
    },
    (err) => {
      this.responseMessage = err;
      // this.responseMessage.replace(/\\n/g, '<br/>');
      this.statusMessage = 'error';
      this.loaDer.nativeElement.style.display = 'none';
    }
  );
  this.enable = false;
}

// checkFlag(){
//   if (this.RoleScreenData[absoluteIndex].activeFlag === 'A') {
//     this.RoleScreenData[absoluteIndex].activeFlag = 'I';
//   } else {
//     this.RoleScreenData[absoluteIndex].activeFlag = 'A';
//   }
// }

}
