import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { BilApiUrl } from "../../common/bil-api-url";
import { RdsBilApiUrl } from "../../common/rds-bil-api-url";

@Injectable({
  providedIn: "root",
})
export class CycleCountService {
  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  shop_location_url: string = RdsBilApiUrl.SEARCH_LOCATION_URL;
  cycle_count_details_url: string = RdsBilApiUrl.CYCLE_COUNT_DETAILS_URL;
  status_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;

  constructor(private _http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }

  getShopCode(details) {
    return this._http.post(this.shop_location_url, details);
  }

  getCycleCountDetails(details) {
    return this._http.post(this.cycle_count_details_url, details);
  }

  getStatus(details) {
    return this._http.post(this.status_url, details);
  }

  getSizeDetails(details) {
    return this._http.post(this.size_url, details);
  }
}
