import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PendingPoService } from './pending-po.service';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { SearchPoResponseModel } from '../common/search-po-response-model';
import { PurchaseOrderHeader } from '../search-po/po-header';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
import { PO_STATUS_PENDING } from '../common/field-name-list';
import { DeleteDetails } from '../common/delete-details';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { User } from '../models/user';
import { Role } from '../models/role';
import { AuthenticationService } from '../services/authentication.service';
import { PendingPurchaseOrderSearchForm } from './pending-po-search-form';


@Component({
  selector: 'app-pending-po',
  templateUrl: './pending-po.component.html',
  //styleUrls: ['./pending-po.component.css']
  styleUrls: ['../../assets/css/bootstrap.css',
    '../../assets/css/bil-style.css',
    '../../../node_modules/font-awesome/css/font-awesome.css', './pending-po.component.css'],
  providers: [PendingPoService]
})
export class PendingPoComponent implements OnInit,AfterViewInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  // @ViewChild('searchClick', { static: false }) searchClick: ElementRef;

  constructor(private _PendingPoService: PendingPoService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private authenticationService: AuthenticationService,
    private _utilsService: UtilsService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  pendingPurchaseOrderModel= new PendingPurchaseOrderSearchForm();
  status: string;
  pendingPurchaseOrders: PurchaseOrderHeader[] = [];
  requestParameterModel: RequestParameterModel[];
  remarks: string;
  responseMessage: string;
  poStatus: string;
  poHeaderId: number;
  userRole: string;
  currentDate: Date;
  currentUser: User;
  username: string;
  dateOfYear: string;

  ngOnInit() {
    this.currentDate = new Date();
    let myDate = new Date(this.currentDate);
    let year = this.currentDate.getFullYear();
    let mnth = ("0" + (myDate.getMonth() + 1)).slice(-2); let day = ("0" + myDate.getDate()).slice(-2);
    this.dateOfYear = [day, mnth, year].join("/");

    let sessUsername = sessionStorage.getItem('currentUser');
    this.userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    if(this._navigationLinkParameterService.pendingPoSearchData == undefined){
      console.log('New Search has to be made');  
      this.pendingPurchaseOrderModel.poNumber = null;
      this.pendingPurchaseOrderModel.categoryNumber = null;
    }
    else{
    console.log('Automatic Search will be performed');  
    this.pendingPurchaseOrderModel = this._navigationLinkParameterService.pendingPoSearchData.searchData;
    this.pendingPurchaseOrders = this._navigationLinkParameterService.pendingPoSearchData.resultData;
    this._navigationLinkParameterService.pendingPoSearchData = undefined;
    }



  }

  ngAfterViewInit(){
    this.loaDer.nativeElement.style.display = 'none';
  }


  submitPendingSearchPurchaseOrderForm() {
    this.pendingPurchaseOrders = [];
    this.responseMessage = '';
    this.loaDer.nativeElement.style.display = 'block';
    this._PendingPoService.getPendingPoList(this.pendingPurchaseOrderModel, this.username).subscribe(
      (response: SearchPoResponseModel) => {
        console.log(response);
        if (response.status !== 'False') {
          this.pendingPurchaseOrders = response.dataList;
        }
        else {
          this.responseMessage = response.message;
          this.pendingPurchaseOrders = [];
        }
        this.loaDer.nativeElement.style.display = 'none';
      },(err) => {
        this.responseMessage = err;
      },() => {
        this.loaDer.nativeElement.style.display = 'none';
        console.log("API call done");
      }
    );
  }

  onClickPendingPoNumber(po) {
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(po);
    this._navigationLinkParameterService.details = this.requestParameterModel;

    var found = this.pendingPurchaseOrders.find(function(element) {       
      return element.poNumber == po; 
    }); 
    //this._navigationLinkParameterService.id=this.searchedPurchaseOrders[absoluteIndex].poNumber;
    this._navigationLinkParameterService.id=po;
    this._navigationLinkParameterService.podetails=this.pendingPurchaseOrders[po];
    this._navigationLinkParameterService.podetails=found;
    // this._navigationLinkParameterService.navigateFlag = 'pendingPo';
    this._navigationLinkParameterService.pendingPoSearchData = this.searchPayload();

    this._router.navigate(['update-pending-po']);
  }

  searchPayload(){ 
    let searchNavigationHold = {
      searchData : this.pendingPurchaseOrderModel,
      resultData : this.pendingPurchaseOrders
    }
    return searchNavigationHold;
  }

  onClickBack() { this._router.navigate(['']); }

  resetResults() {
    this.pendingPurchaseOrders = [];
    this.responseMessage = '';
  }

  approvePurchaseOrder() {
    let poType: string = 'poHeader';
    let details = {
      "poStatus": this.poStatus,
      "approverRemarks": this.remarks,
      "action": "Approved",
      "poApproveBy": this.username,
      "lastUpdateBy": this.username,
      "lastUpdateDate": this.dateOfYear
    }
    let deleteDetails = new DeleteDetails();
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(details);

    deleteDetails.type = poType;
    deleteDetails.id = this.poHeaderId;
    deleteDetails.updatedDataList = this.requestParameterModel;
    console.log(JSON.stringify(deleteDetails));
    this._PendingPoService.getPoDeleted(deleteDetails).subscribe(
      (response: ResponseMessage) => {
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          console.log('Approved Successfully');
          this.remarks = '';
        }
        else {
          console.log('Could not delete');
          this.responseMessage = "Error Occured";
        }
        this.pendingPurchaseOrders = [];
        this.submitPendingSearchPurchaseOrderForm();
      })
  }

  rejectPurchaseOrder() {
    let poType: string = 'poHeader';
    let details = {
      "poStatus": this.poStatus,
      "approverRemarks": this.remarks,
      "action": "Reject",
      "poApproveBy": this.username,
      "cancelReason": "",
      "lastUpdateBy": this.username,
      "lastUpdateDate": this.dateOfYear
    }
    let deleteDetails = new DeleteDetails();
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(details);

    deleteDetails.type = poType;
    deleteDetails.id = this.poHeaderId;
    deleteDetails.updatedDataList = this.requestParameterModel;
    this._PendingPoService.getPoDeleted(deleteDetails).subscribe(
      (response: ResponseMessage) => {
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          console.log('Rejected successfully');
          this.remarks = '';
        }
        else {
          console.log('Could not delete');
          this.responseMessage = "Error Occured";
        }
        this.pendingPurchaseOrders = [];
        this.submitPendingSearchPurchaseOrderForm();
      }
    )
  }

  setDataApp(stat, id) {
    this.poStatus = stat;
    this.poHeaderId = id;
    this.remarks = '';
  }

  disableButtonApp(poStat) {
    let userRoleIndex = this.userRole.indexOf('HOD');
    if (((poStat == 'Pending Approval')||(poStat == 'Pending Recall')) && userRoleIndex >= 0) {
      return false;
    }
    else {
      return true;
    }
  }

}
