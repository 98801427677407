import { SearchPurchaseOrderService } from './search-po.service';
import { PurchaseOrderHeader } from './po-header';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchPoResponseModel } from '../common/search-po-response-model';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { Concept } from '../common/concept';
import { OrderType } from '../common/orderType';
import { PurchaseOrderSearchForm } from './po-search-form';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
import { OrderPipe } from 'ngx-order-pipe';
import { ITEMS_PER_PAGE, PO_NUMBER } from '../common/field-name-list';
import { DeleteDetails } from '../common/delete-details';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { GenerateReportService } from '@/generate-report/generate-report.service';
import { ReportGeneration } from '@/common/report-generation';

@Component({
  selector: 'app-search-po',
  templateUrl: './search-po.component.html',
  //styleUrls: ['./search-po.component.css']
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css',
    '../../../node_modules/font-awesome/css/font-awesome.css', './search-po.component.css'],
  providers: [SearchPurchaseOrderService]
})
export class SearchPoComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('searchClick', { static: false }) searchClick: ElementRef;
  searchedPurchaseOrders: PurchaseOrderHeader[] = [];
  concepts: any;
  statuses = ['New', 'Approved', 'Rejected', 'Closed', 'In-Transit', 'Cancelled', 'Pending Cancel', 'Pending Recall', 'Pending Approval'];
  deliveryFromMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  orderTypes: OrderType[];
  purchaseOrderModel = new PurchaseOrderSearchForm();
  public noResults = false;
  requestParameterModel: RequestParameterModel[];

  remarks: string;
  selectedIndex: number;
  responseMessage: string;
  enable: boolean = false;
  permission: boolean = false;

  order: string = '';
  reverse: boolean = false;
  sortedCollection: any[];
  currentPage: number = 1;

  poHeaderId: number;
  poStatus: string;

  userRole: string;
  username: string;
  coCode: any;
  isUserVendor: boolean = false;
  selectedPoNumbers: string[];
  isPoSelected: boolean = false;

  ngOnInit(): void {

    this.isPoSelected = false;

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);


    let sessUsername = sessionStorage.getItem('currentUser');
    /* Modified for testing start*/
    //if(typeof JSON.parse(sessUsername).groupAssignmentModels !== 'undefined'){
    this.userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');

    var conceptQueryMas = [{ "name": "email", "value": JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    if (this.userRole.indexOf('Vendor') >= 0) {
      this.isUserVendor = true;
    }
    //}
    // else{
    //   this.userRole=JSON.parse(sessUsername)[0].groupName.split(',');

    // var conceptQueryMas = [{"name":"email","value":JSON.parse(sessUsername)[0].userEmail}];
    // this.username =  JSON.parse(sessUsername)[0].userEmail;
    // this.coCode = JSON.parse(sessUsername)[0].concept.split(',');
    // }
    /* Modified for testing end*/
    this._SearchPurchaseOrderService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        //let initialSearchData = this._navigationLinkParameterService.searchPOHeaderData.searchData;
        if (this._navigationLinkParameterService.searchPOHeaderData == undefined) {
          console.log('New Search has to be made');
          this.purchaseOrderModel.conceptCode = null;
          this.purchaseOrderModel.orderPrefixConcept = null;
          this.purchaseOrderModel.poType = null;
          this.purchaseOrderModel.poStatus = null;
          this.purchaseOrderModel.deliveryFromMonth = null;
        }
        else {
          console.log('Automatic Search will be performed');
          this.purchaseOrderModel = this._navigationLinkParameterService.searchPOHeaderData.searchData;
          this.searchedPurchaseOrders = this._navigationLinkParameterService.searchPOHeaderData.resultData;
          this._navigationLinkParameterService.searchPOHeaderData = undefined;
        }
      },
      (err) => {
        this.responseMessage = err;
      }
    )
    this._SearchPurchaseOrderService.getOrderTypes().subscribe(
      (data: OrderType[]) => {
        this.orderTypes = data;
      }
    )
    // this.purchaseOrderModel.conceptCode = null;
    // this.purchaseOrderModel.poType = null;
    // this.purchaseOrderModel.poStatus = null;
    // this.purchaseOrderModel.deliveryFromMonth = null;
    this.selectedPoNumbers = [];
  }



  constructor(private orderPipe: OrderPipe, private _SearchPurchaseOrderService: SearchPurchaseOrderService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router,
    private _utilsService: UtilsService, private _generateReportService: GenerateReportService) {
    this.sortedCollection = orderPipe.transform(this.searchedPurchaseOrders, '');
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  /*
  The function is called on click of Submit button in Search PO page.
  */
  submitSearchPurchaseOrderForm() {
    this.loaDer.nativeElement.style.display = 'block';
    this.setPurchaseOrderBySearchCriteria(this.purchaseOrderModel);
  }

  /*
  The function is called on click of Reset button in Search PO page.
  */
  resetSearchPurchaseOrderResults() {
    this.searchedPurchaseOrders = [];
    this.noResults = false;
    this.responseMessage = '';
  }

  /*
  The function saves the results recieved from Search PO API service
  in an array of PO-Header class which is used in html page to show 
  the results.
  */
  setPurchaseOrderBySearchCriteria(purchaseOrderModel: PurchaseOrderSearchForm) {
    this.responseMessage = '';
    if (purchaseOrderModel.orderPrefixConcept != null && purchaseOrderModel.orderPrefixConcept != '' && purchaseOrderModel.orderPrefixConcept != undefined) {
      this.concepts.filter(concept => concept.orderPrefixInPoSystem == purchaseOrderModel.orderPrefixConcept).forEach(
        conc => {
          purchaseOrderModel.conceptCode = conc.concCode;
        }
      )
    }
    else {
      purchaseOrderModel.conceptCode = undefined;
    }
    console.log('Param passing to service : ' + JSON.stringify(purchaseOrderModel));
    this._SearchPurchaseOrderService.getPurchaseOrderBySearchCriteria(purchaseOrderModel, this.username).subscribe(
      (response: any) => {
        if (response.status !== 'False') {
          this.searchedPurchaseOrders = response.dataList;
          this.searchedPurchaseOrders.forEach(
            searchList => {
              var multiplier = Math.pow(10, 2);
              let amount = (Math.round(searchList.stdArtCost * multiplier) / multiplier);
              searchList.stdArtValue = this._utilsService.calculateDecimalValue(amount);
            }
          )
        }
        else {
          this.responseMessage = response.message;
          this.searchedPurchaseOrders = [];
        }
        //  this.searchedPurchaseOrders.forEach(
        //   res => {
        //     if (res.poStatus == 'APPROVED' && this.userRole == 'HOD') {

        //       this.enable = true;
        //     }
        //     else{
        //       this.enable=false;
        //     }

        //   }
        // );
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
      }
    );
    if (this.searchedPurchaseOrders != null && this.searchedPurchaseOrders.length == 0) {
      this.noResults = true;
    }
    else {
      this.noResults = false;
    }
  }

  disableButton(poStat, cancelFlag, poNum, conCode, isOtbProcessed, orderedQty, shippedQty) {
    let p = poNum.toString().substr(1, 1);
    var iof = this.coCode.indexOf(conCode);
    let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
    let userRoleIndexIT = this.userRole.indexOf('IT');
    if (isOtbProcessed == 'Y' || (parseInt(shippedQty) >= parseInt(orderedQty)) || cancelFlag == 'Y') {
      return true;
    }
    else {
      if (((poStat == 'Approved' || poStat == 'In-Transit') && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && p != '-' && iof >= 0 && (cancelFlag == 'N' || cancelFlag == null)) ||
        ((poStat == 'New' || poStat == 'Rejected') && (userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && p != '-' && iof >= 0 && (isOtbProcessed == 'N' || isOtbProcessed == null) && (cancelFlag == 'N' || cancelFlag == null))) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  cancelPurchaseOrder() {
    this.responseMessage = '';
    let poType: string = 'poHeader';
    let details = {
      "poStatus": this.poStatus,
      "cancelReason": this.remarks,
      "action": "Cancel"
    }
    let shippedQty: number;
    let recvQty: number;
    this.searchedPurchaseOrders.filter(cs => cs.poHeaderId == this.poHeaderId).forEach(
      searchList => {
        shippedQty = Number(searchList.shippedQuanitity);
        recvQty = Number(searchList.receivedQty);
      }
    )
    if (shippedQty >= recvQty) {
      let deleteDetails = new DeleteDetails();
      this.requestParameterModel = [];
      this.requestParameterModel = this._utilsService.createRequestParametersModelArray(details);
      deleteDetails.type = poType;
      deleteDetails.id = this.poHeaderId;
      deleteDetails.updatedDataList = this.requestParameterModel;
      this._SearchPurchaseOrderService.getPoDeleted(deleteDetails).subscribe(
        (response: ResponseMessage) => {

          if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
            //this.responseMessage = 'Purchase Order Updated successfully';
            this.remarks = '';
          }
          else {
            this.responseMessage = "Error Occured";
          }
          this.searchedPurchaseOrders = [];
          this.submitSearchPurchaseOrderForm();
        }
      )
    }
    else {
      this.responseMessage = "Received Quantity is greater than Shipped Quantity";
    }
  }

  onClickSearchpoNumber(index) {
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    var found = this.searchedPurchaseOrders.find(function (element) {
      return element.poNumber == index;
    });
    //this._navigationLinkParameterService.id=this.searchedPurchaseOrders[absoluteIndex].poNumber;
    this._navigationLinkParameterService.id = index;
    this._navigationLinkParameterService.podetails = this.searchedPurchaseOrders[index];
    this._navigationLinkParameterService.podetails = found;
    // this.requestParameterModel = [];
    // this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchedPurchaseOrders[index]);
    // this._navigationLinkParameterService.details = this.requestParameterModel;
    this._navigationLinkParameterService.navigateFlag = 'searchPo';
    this._navigationLinkParameterService.searchPOHeaderData = this.searchPayload();
    this._router.navigate(['update-po']);
  }

  searchPayload() {
    let searchNavigationHold = {
      searchData: this.purchaseOrderModel,
      resultData: this.searchedPurchaseOrders
    }
    return searchNavigationHold;
  }

  onClickBack() { this._router.navigate(['']); }

  // settingIndex(index) {
  //   console.log('selected index' + index);
  //   this.selectedIndex = index;
  //   this.responseMessage = '';
  //   this.remarks = '';
  // }

  settingIndex(headerId, poStat) {
    // this.selectedIndex = index;
    this.poHeaderId = headerId;
    this.poStatus = poStat;
    this.responseMessage = '';
    this.remarks = '';
  }

  viewLocationWiseQuantity(poNumber) {
    console.log('PoNumber for location level data' + poNumber);
    this._navigationLinkParameterService.searchPOHeaderData = this.searchPayload();
    this._navigationLinkParameterService.viewLocationPoNumber = poNumber;
    this._router.navigate(['view-location-quantity']);
  }


  updateTable() {
    this.responseMessage = '';
    console.log(this.poHeaderId);
    let details = {
      "type": "poHeader",
      "id": this.poHeaderId,
      "userCode":this.username
    }
    console.log(details);
    this._SearchPurchaseOrderService.getPoUpdated(details).subscribe(
      (response: ResponseMessage) => {
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = 'Purchase Order Updated Successfully';
        }
        else {
          this.responseMessage = response.message;
        }

        setTimeout(() => {
          this.searchedPurchaseOrders = [];
          this.submitSearchPurchaseOrderForm();
          this.loaDer.nativeElement.style.display = 'none';
        }, 5000);
      }
    )
  }

  vendorEnableDisable(poStatus, isOtbProcessed, accessCheck) {
    let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
    let userRoleIndexIT = this.userRole.indexOf('IT');
    let userRoleIndexVendor = this.userRole.indexOf('Vendor');

    if ((isOtbProcessed == 'Y' || (poStatus != 'New' && poStatus != 'Approved'))) {
      return true;
    } else if ((userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0) && accessCheck != 'Download') {
      return false;
    } else if (userRoleIndexVendor >= 0 && accessCheck == 'Download') {
      return false;
    } else {
      return true;
    }
  }

  async printPOPdf(poNumber): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    let details = new ReportGeneration();
    let reportParam = {
      "orderNoFrm": poNumber,
      "orderNoTo": poNumber,
      "userId": this.username
    }
    details.reportFormatType = "Pdf";
    details.reportInputRequestList = this._utilsService.createRequestParametersModelArray(reportParam);
    const blob = await this._generateReportService.getReportDetails(details, 'Purchase Order Printing');
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    let currentDate: string = this._utilsService.getCurrentFormatDate();
    let value = poNumber + '_' + poNumber + '_' + currentDate;
    anchor.download = value + '.' + 'pdf';
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none';
  }


  storePoNumber(selectedPoNumber, checkEvent) {
    if (checkEvent.target.checked === true) {
      console.log('checkedValue=' + checkEvent.target.checked);
      this.selectedPoNumbers.push(selectedPoNumber);
    } else {
      this.selectedPoNumbers.forEach((element, index) => {
        if (selectedPoNumber === element) {
          this.selectedPoNumbers.splice(index, 1);
          console.log('this.selectedPoNumbers='+this.selectedPoNumbers);
        };
      });
    }

    if(this.selectedPoNumbers.length > 0){
      this.isPoSelected = true;
    }else{
      this.isPoSelected = false;
    }
  }

  updateAllPo() {

    this.responseMessage = '';
    let details = {
      "poNumbers": this.selectedPoNumbers,
      "userCode":this.username
    };
    console.log(details);
    this._SearchPurchaseOrderService.updateAllPoPrice(details).subscribe(
      (response: ResponseMessage) => {
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = response.message;
        }
        else {
          this.responseMessage = response.message;
        }
        setTimeout(() => {
          this.searchedPurchaseOrders = [];
          this.submitSearchPurchaseOrderForm();
          this.loaDer.nativeElement.style.display = 'none';
        }, 5000);
      }
    );
    this.selectedPoNumbers = [];
  }

}

