import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { UserGroupModel } from '../../search-user-group/search-user-group-model';
import { CreateRoleScreenAccessService } from './create_role-screen-access.service';
import { Router, RouterModule } from '@angular/router';
import { UserConceptDetails } from '../../manage-user-concept-create/manage-user-details';
import { UserGroupSearchDetails } from '../../search-user-group/search-user-group-details';
import { GroupListDetails } from '../../search-user-group/user-group-list';


@Component({
  selector: 'app-create_role-screen-access',
  templateUrl: './create_role-screen-access.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css','../../../../node_modules/font-awesome/css/font-awesome.css','./create_role-screen-access.component.css']
})
export class CreateRoleScreenAccessComponent implements OnInit {

  @ViewChild('group', { static: false }) groupVariable: ElementRef;
  @ViewChild('screen', { static: false }) screenVariable: ElementRef;
  @ViewChild('access', { static: false }) accessVariable: ElementRef;
  
  createRoleScreenAccessForm: FormGroup;
  userGroupSearchParameter = new UserGroupModel();
  createUserGroup = new UserGroupSearchDetails();
  details: any;
  userDetailList: UserConceptDetails[] = [];
  selectedUser: number;
  selectedRow: number = 0;
  responseMessage: string;
  mt1: boolean[] = [];
  mt2: boolean[] = [];
  createUserGroupModel = new UserGroupSearchDetails();
  userGroupList: UserGroupSearchDetails[] = [];
  disableIndex: number = 0;
  userGroupPassingList: UserGroupSearchDetails[] = [];
  username: string;
  added = false; 
  noOfRow:number=0;
  groupListDetails = {
    groupName: ''
  };
  screenListDetails = [{
    "name":"screenModule",
		"value":"RDS"
  }];
  accessListDetails =[{
    "name":" ",
		"value":" "
  }];
  groupList: GroupListDetails[] = [];
  screenList:[]=[];
  accessList:[]=[];

  constructor(private _router: Router, private _CreateRoleScreenAccessService: CreateRoleScreenAccessService, private fb: FormBuilder) { 
    this.createRoleScreenAccessForm = this.fb.group({
      roleScreenAccess: this.fb.array([])
    });
    this.buildRoleScreenAccessForm();
  }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.createUserGroupModel = new UserGroupSearchDetails();
    this.userGroupList.push(this.createUserGroupModel);
    this.mt1[0] = false;
    this.mt2[0] = false;
    console.log(this.groupListDetails);
    this._CreateRoleScreenAccessService.getGroupList(this.groupListDetails).subscribe(
      (response:any) => {
        this.groupList = response;
      }
    );
    this._CreateRoleScreenAccessService.getScreenList(this.screenListDetails).subscribe(
      (response:any) => {
        console.log(response);
        this.screenList = response;
      }
    );
    this._CreateRoleScreenAccessService.getAccessList(this.accessListDetails).subscribe(
      (response:any) => {
        console.log(response.data);
        this.accessList = response.data;
      }
    );
    
  }

  buildRoleScreenAccessForm() {
    let control = this.roleScreenAccessFormArrayControl();
    return control.push(this.fb.group(
      {
        screen: [null, [Validators.required]],
        access: [null, [Validators.required]],
        group: [null, [Validators.required]]
      }
    ));
  }
  addRoleScreenAccessGroup(){
    console.log(this.noOfRow);
    if (this.createRoleScreenAccessForm.invalid) {
      this.added = true;
      return;
    } else{
      this.added = false;
      this.noOfRow++;
    }
    let control = this.roleScreenAccessFormArrayControl();
    return control.push(this.fb.group(
      {
        screen: [null, [Validators.required]],
        access: [null, [Validators.required]],
        group: [null, [Validators.required]]
      }
    ));
  }
  deleteConcept(access_index: any) {
    let control = <FormArray>this.createRoleScreenAccessForm.controls.roleScreenAccess;
    this.noOfRow--;
    control.removeAt(access_index);
  }
  roleScreenAccessFormArrayControl() {
    return <FormArray>this.createRoleScreenAccessForm.controls.roleScreenAccess;
  }

  submitRoleScreenAccess(formData: any) {
    console.log(formData);
    let dataSet: any = [];
    formData.roleScreenAccess.forEach(
      access => {
        let data: any = {
          "screenId": access.screen,
          "accessId": access.access,
          "groupId" : access.group,
          "activeFlag":"A",
          "lastUpdatedBy": this.username
        }
        dataSet.push(data);
      }
    );
    console.log(dataSet);
    this._CreateRoleScreenAccessService.saveBilGroupScreenDetails(dataSet).subscribe(
      (response: any) => {
        console.log(response);
        this.responseMessage =  response.message;
        
        if (response.status === 'Success') {
              this.responseMessage = 'User Role Created Successfully';
            }
            else{
              this.responseMessage = response.message;
            }
            this._router.navigate(['/create-role-screen-access']);
      }
    );
  }
  // onClickSearchUser() {
  //   if (this.userGroupSearchParameter.userName == undefined) {
  //     this.details = {
  //       "userName": ""
  //     }
  //   }
  //   else {
  //     this.details = {
  //       "userName": this.userGroupSearchParameter.userName
  //     }
  //   }
  //   console.log(JSON.stringify(this.details));
  //   this._CreateRoleScreenAccessService.getUserDetails(this.details).subscribe(
  //     (response: UserConceptDetails[]) => {
  //       console.log(response);
  //       this.userDetailList = response;
  //     }
  //   )
  // }

  // settingIndexforGroupLine(index) {
  //   this.responseMessage = '';
  //   this.selectedRow = index;
  // }

  // addUserGroup() {
  //   if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
  //     this.createUserGroup = new UserGroupSearchDetails();
  //     this.userGroupList.push(this.createUserGroup);
  //     this.selectedRow = null;
  //     this.selectedUser = null;
  //     this.userDetailList = [];
  //     this.userGroupSearchParameter = new UserGroupModel();
  //     this.disableIndex++;
  //     this.responseMessage = '';
  //   }
  // }

  // onClickSaveButton() {
  //   this.responseMessage = '';
  //   this.userGroupPassingList = [];
  //   if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
  //     this.userGroupList.forEach(
  //       conc => {
  //         let userConceptsPassing = new UserGroupSearchDetails();
  //         userConceptsPassing.groupId = conc.groupId;
  //         userConceptsPassing.userId = conc.userId;
  //         userConceptsPassing.createdBy = this.username;
  //         this.userGroupPassingList.push(userConceptsPassing);
  //       }
  //     );
  //     console.log(JSON.stringify(this.userGroupPassingList));
  //     this._CreateRoleScreenAccessService.saveUserDetails(this.userGroupPassingList).subscribe(
  //       (response: any) => {
  //         console.log(response);
  //         if (response.code != 'FAILED') {
  //           this.responseMessage = 'User Role Created Successfully';
  //         }
  //         else{
  //           this.responseMessage = response.message;
  //         }
  //       }
  //     )
  //   }
  //   else {
  //     this.responseMessage = 'Please fill Data First';
  //   }
  // }

  // setData(groupId, i) {
  //   this.responseMessage = '';
  //   this.userGroupList[i].groupId = groupId;
  //   this.mt2[this.disableIndex] = true;
  // }

  // onClickSubmit() {
  //   this.userGroupList[this.selectedRow].userName = this.userDetailList[this.selectedUser].userName;
  //   this.userGroupList[this.selectedRow].userId = this.userDetailList[this.selectedUser].userId;
  //   this.mt1[this.disableIndex] = true;

  // }

  // setIndexForUser(index) {
  //   this.responseMessage = '';
  //   this.selectedUser = index;
  // }       

  // onClickBack() {
  //   this._router.navigate(['']);
  // }

  // cancelLineDetails(index){
  //   if(this.disableIndex != 0){
  //   this.userGroupList.splice(index,1);
  //   this.disableIndex--;
  //   this.responseMessage = '';
  //   }
  // }
}

