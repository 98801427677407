export class Search {
    
    invoiceNumber:string;
    consignorCode:string;
    ginNumber:string;
    invoiceFromDate:Date;
    invoiceToDate:Date;
    fromYrWk:string;
    toYrWk:string;
   
    constructor(){}
}