
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';


@Injectable({
  providedIn: 'root'
})
export class MdmReportService {
  URL: string = '';
  reportsDetails: any = [
    { name: 'Purchase Order Printing', url:BilApiUrl.PURCHASE_ORDER_PRINTING_REPORT_URL},
    { name: 'Estimates Report', url:BilApiUrl.ESTIMATE_REPORT_URL},
    { name: 'Onorder Size for Distributor', url:BilApiUrl.ONORDER_SIZE_REPORT_URL},
    { name: 'Future Order Report', url:BilApiUrl.FUTUREORDER_REPORT_URL},
    { name: 'On Way Report', url:BilApiUrl.ONWAY_REPORT_URL},
    { name: 'Shop Master', url:BilApiUrl.SHOP_REPORT_URL},
    { name: 'Category Master', url:BilApiUrl.CATEGORY_REPORT_URL},
    { name: 'Article Master', url:BilApiUrl.ARTICLE_REPORT_URL},
    { name: 'State Master', url:BilApiUrl.STATE_REPORT_URL},
    { name: 'Week Master', url:BilApiUrl.WEEK_REPORT_URL},
    { name: 'Party Master', url:BilApiUrl.PARTY_REPORT_URL},
    { name: 'Packing Master', url:BilApiUrl.PACKING_REPORT_URL},
    { name: 'Factory Operations-Unit(By Order Week-Year)', url:BilApiUrl.FACOP_UNIT_REPORT_URL},
    { name: 'Factory Operations-OTB(By Delivery Week-Year)', url:BilApiUrl.FACOP_OTB_REPORT_URL},
    { name: 'Order Follow Invoice', url:BilApiUrl.ORDER_FOLLOW_INVOICE_REPORT_URL},
    { name: 'Supplier Performance Report', url:BilApiUrl.SUPPLIER_PERFM_REPORT_URL},
    { name: 'Order Master', url:BilApiUrl.ORDER_MASTER_REPORT_URL},
    { name: 'Order Status Report', url:BilApiUrl.ORDER_STATUS_REPORT_URL},
    { name: 'RDC Master', url:BilApiUrl.RDC_MASTER_REPORT_URL},
    { name: 'Supply Master', url:BilApiUrl.SUPPLY_MASTER_REPORT_URL},
    { name: 'Recap Order Vs RDC Receive', url:BilApiUrl.RECAP_ORDER_RDC_RECV_REPORT_URL},
    { name: 'Concept Master', url:BilApiUrl.CONCEPT_MASTER_REPORT_URL},
    { name: 'Supply RDC Receive Report', url:BilApiUrl.SUPPLY_RDC_DECV_REPORT_URL},
    { name: 'Outstanding for Distributor', url:BilApiUrl.OUTSTANDING_FOR_DIST_REPORT_URL},
    { name: 'Retail Order Onway', url:BilApiUrl.RETAIL_ORDER_ONWAY_REPORT_URL}
  ];
  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
   reports_url: string = BilApiUrl.REPORT_NAMES;
   offline_reports_url: string = BilApiUrl.OFFLINE_REPORT_URL;
   order_type_url = 'assets/static-data/orderTypes.json';
  // reports_url: string = 'http://01hw791336:8093/report/queryReportList';
  
  constructor(private _http: HttpClient) { }

  public async getReportDetails(details, reportName): Promise<Blob> {
    console.log('sending parameters'+JSON.stringify(details));
    this.reportsDetails.forEach(element => {
      if (reportName == element.name) {
        console.log('element name' + element.name + 'element url' + element.url);
        this.URL = element.url;
      }
    });
    const file = await this._http.post<Blob>(this.URL, details, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }

  getReports() {
    let reportParameter=[
      {
      "name":"reportModule",
      "value":"PO"
      }	
    ];
    return this._http.post(this.reports_url, reportParameter);
  }

  getOrderTypes() {
    return this._http.get(this.order_type_url);
  }

  offlineReportGeneration(reportParam){
    return this._http.post(this.offline_reports_url, reportParam);
  }

  getReportDetailsWithoutBlob(details, reportName){
    this.reportsDetails.forEach(element => {
      if (reportName == element.name) {
        console.log('element name' + element.name + 'element url' + element.url);
        this.URL = element.url;
      }
    });
    return this._http.post(this.URL, details);
  }
}
