import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ImportArticlePriceService } from './import-article-price.service';
import { RequestParameterModel } from '../common/request-parameter-model';
import { ImportPrice } from './import-article-price-maintenance';
import { importArticle } from './import_article';
import { ImportArticleprice } from './import-price';
import { importsave } from './import-article-save';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { isEmpty } from 'rxjs/operators';
import { Batches } from '../batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UtilsService } from '../common/utils-service';

@Component({
  selector: 'app-import-article-price',
  templateUrl: './import-article-price.component.html',
  //styleUrls: ['./import-article.component.css']
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css', './import-article-price.component.css']
})
export class ImportArticlePriceComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  requestParameterModel: RequestParameterModel[] = [];
  importPricearticle = new ImportPrice();
  importpricearticleLines: ImportPrice[];
  importPrice = new ImportArticleprice();
  importPricelines: ImportArticleprice[] = [];
  isArticleName: boolean = false;
  price: number;
  name: string;
  artNum: string;
  articleNum: string;
  currency1: string;
  responseMessage: string;
  fobId: string;
  profileForm: FormGroup;
  error: string;
  status: string;
  status1: string;
  price1: any;
  uploadFileName: string = 'No file Chosen';
  downloadUrlList: Batches[] = [];
  downloadUrl: string;
  downloadedFile: string;
  checkService: boolean = false;
  enable: boolean = false;
  fileUpload = { status: '', message: '', filePath: '' };
  statusMessage: string = '';
  userName: string;
  constructor(private fb: FormBuilder, private fileUploadService: ImportArticlePriceService, private httpClient: HttpClient,
    private _utilsService: UtilsService) {
    this.profileForm = this.fb.group({
      name: [''],
      file: [''],
      uploadKey: ['ARTICLE_PRICE'],
      uploadedBy: ['']
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.profileForm.get('uploadedBy').setValue(this.userName);
  }

  onSelectFile(event) {

    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
    }
  }

  onSubmit() {
    this.statusMessage = '';
    this.responseMessage = '';
    if (this.myInputVariable.nativeElement.files.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage='error';
      return false;
    }
    let newFileArray: any[] = this.uploadFileName.split('.');
    newFileArray[0] = newFileArray[0].replace(/ /g,"_");
    let newFileFormat = newFileArray[0] + '_' + this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
    const formData = new FormData();
    formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData.append('uploadKey', 'ARTICLE_PRICE');
    formData.append('uploadedBy', this.profileForm.get('uploadedBy').value);
    this.loaDer.nativeElement.style.display = 'block';
    this.fileUploadService.upload(formData).subscribe(
      (response: ResponseMessage) => {
        if (response.status == "success") {
          this.responseMessage = 'File Uploaded successfully';
          this.statusMessage = 'success';
          this.enable = true;
        } else {
          this.responseMessage = 'Something error happened';
          this.statusMessage = 'error';
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.enable = false;
        this.loaDer.nativeElement.style.display = 'none';
      }

    );
  }

  onKey() {
    this.responseMessage = '';
    if (this.importPrice.art_no.length == 7) {
      this.fileUploadService.getImportService1Criteria(this.importPrice.art_no).subscribe(
        (response: importArticle) => {
          if (response.artMasterResponseModels.length != 0) {
            response.artMasterResponseModels.forEach(
              searchPo => {
                if (this.importPrice.art_no == searchPo.art_no) {
                  this.importPrice.art_name == searchPo.art_name;
                  this.name = searchPo.art_name;
                  this.artNum = searchPo.art_no;
                  this.responseMessage = '';
                  this.isArticleName = true;
                  this.importPricearticle.currency = null;
                  this.price = null;
                  this.fobId = null;
                }
              }
            )
          }
          else {
            this.responseMessage = 'Please Enter Correct Article Number';
            this.isArticleName = false;
            this.name = null;
            this.importPricearticle.currency = null;
            this.price = null;
            this.fobId = null;
          }
        }
      )
    }
    else {
      this.name = null;
      this.importPricearticle.currency = null;
      this.price = null;
      this.fobId = null;
    }
  }

  onCurrencySelect() {
    this.responseMessage = '';
    this.price = null;
    this.fobId = null;
    let details = {
      "articleNumber": this.artNum,
      "currency": this.importPricearticle.currency
    }
    this.fileUploadService.getImportServiceCriteria(details).subscribe(
      (response: ImportPrice) => {
        response.dataList.forEach(
          searchPo => {
            let json_string_search = JSON.stringify(searchPo);
            let json_obj = JSON.parse(json_string_search);
            this.importPricearticle.price = json_obj.price;
            this.importPricearticle.currency = json_obj.currency;
            this.currency1 = this.importPricearticle.currency;
            this.price = json_obj.price;
            this.fobId = json_obj.fobId;
          }
        )
      }
    )
  }

  validateNumber(price) {
    if (price != undefined) {
      let prices = price;
      let decimal_length = 0;
      if (price.toString().split(".")[1] != undefined) {
        decimal_length = price.toString().split(".")[1].length;
        if (decimal_length > 1) {
          let decimal = (price.toString().split(".")[1].substr(0, 2));
          prices = price.toString().split(".")[0] + '.' + decimal;
        }
      }
      this.price = prices;
    }
  }

  onSaveNumber() {
    let details = {
      "articleNumber": this.artNum,
      "currency": this.importPricearticle.currency,
      "fobPrice": this.price,
      "fobId": this.fobId
    }
    this.fileUploadService.getImportServiceSaveCriteria(details).subscribe(
      (response: importsave) => {
        if (this.fobId == null) {
          this.responseMessage = 'Import Price Details saved successfully';
        }
        if (response.status == "Success" && this.fobId != null) {
          this.responseMessage = 'Import Price Details Updated successfully';
        }
      }
    )
  }

  public async downloadFile(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    this.fileUploadService.getDownloadFile().subscribe(
      (res: BatchesData) => {
        this.downloadUrlList = res['configParamModels'];
        // if (this.downloadUrlList[0].paramName == 'template_url') {
        //   console.log('inside if check');
        //   this.downloadUrl = this.downloadUrlList[0].paramValue;
        //   //console.log(this.downloadUrl);
        //   this.getDownloadedFile(this.downloadUrl);
        //   return this.checkService = true;
        // }
        this.downloadUrlList.filter(cs=> cs.paramName == 'template_url').forEach(
          each => {
            this.downloadUrl = each.paramValue;
          }
        )
        this.getDownloadFile(this.downloadUrl);
        return this.checkService = true;
      }
    );
  }

  public async getDownloadFile(downloadUrl): Promise<void> {
    let fullDownloadUrl: string = downloadUrl + "Import_FOB_Master_Template.xls";
    const file = await this.httpClient.get<Blob>(fullDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "Import_FOB_Master_Template.xls";
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none';
  }

  resetSearchPurchaseOrderResults() {
    this.importpricearticleLines = [];
    this.responseMessage = '';
    this.uploadFileName = '';
    this.loaDer.nativeElement.style.display = 'none';
    this.myInputVariable.nativeElement.value = "";
  }

  generateImportPo() {
    let uploadkeyValues: any = {
      "uploadKeyValues": "ARTICLE_PRICE",
      "uploadedBy": this.userName
    };
    this.loaDer.nativeElement.style.display = 'block';
    let importpo: any = this.fileUploadService.getGenerate(uploadkeyValues).subscribe(
      (response: importsave) => {
        if (response.status == "success") {
          this.statusMessage = 'success';
          this.responseMessage = response.message;
        }
        else {
          this.statusMessage = 'error';
          this.responseMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
    this.enable = false;
  }
}

