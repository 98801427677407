import { Injectable } from '@angular/core';
import {
  HttpClient,HttpHeaders
} from "@angular/common/http";
import { RdsBilApiUrl } from "../../common/rds-bil-api-url";
import { BilApiUrl } from "../../common/bil-api-url";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SearchCreditNoteService {
  debit_credit_transaction_details_url: string =
    RdsBilApiUrl.SEARCH_DEBIT_CREDIT_TRANSACTION;
  // status_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  // concept_division_url: string = RdsBilApiUrl.CONCEPT_DIVISION_URL;
  perform_shortage_adjustment_url: string =
    RdsBilApiUrl.PERFORM_SHORTAGE_ADJUSTMENT_URL;
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  pending_shortage_adjustment_approval_reject_url: string = RdsBilApiUrl.PERFORM_APPROVAL_OF_DEBIT_CREDIT_NOTE;
  print_url: string = RdsBilApiUrl.PRINT_CREDIT_NOTE;

  constructor(private _http: HttpClient) { }

  getStockAdjustmentDetails(details) {
    return this._http.post(this.debit_credit_transaction_details_url, details);
  }

  getSizeDetails(details) {
    return this._http.post(this.size_url, details);
  }

  pendingShortageApprovalReject(details) {
    return this._http.post(this.pending_shortage_adjustment_approval_reject_url, details);
  }

  printCreditNote(details) {
    return this._http.post(this.print_url, details , httpOptions);
  }

}
