import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReportHeader } from './report-header';
import { RdsReportGstService } from './rds-report-gst.service';
import { ReportGeneration } from '@/common/report-generation';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ReportSearch } from './report-header-search';
import { Concept } from '../../common/concept';
import { ReportNames } from '../../common/reports-names';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rds-report-gst',
  templateUrl: './rds-report-gst.component.html',
  styleUrls: ['./rds-report-gst.component.css']
})
export class RdsReportGSTComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myform', { static: false }) myForm: ElementRef;
  enable = false;
  shopEnable = true;
  regionEnable = true;
  distEnable = true;
  destinationEnable = false;
  dateEnable = false;
  weekEnable = false;
  invNoToEnable = true;
  invNoFromEnable = false;
  paraEnable = false;
  regions = [{ name: 'North (N)', value: 'N' },
  { name: 'South (S)', value: 'S' },
  { name: 'East (E)', value: 'E' },
  { name: 'West (W)', value: 'W' }];
  types = [{ name: 'GD', value: 'GD' },
  { name: 'GI', value: 'GI' },
  { name: 'CD', value: 'CD' },
  { name: 'CI', value: 'CI' },
  { name: 'NV', value: 'NV' }];
  reportModel = new ReportHeader();
  requestParameterModel: RequestParameterModel[];
  searchParameterModel = new ReportSearch();
  concepts: Concept[];
  conceptswithAll: any[] = [];
  conceptsForRetail: any[] = [];
  reportNames: ReportNames[];
  username: string;
  coCode: any;
  responseMessage: string;
  sucessMeg1: string;
  asyncSuccess: string = 'Failed';
  loginUserEmail: string;
  generatebutton: string = 'enable';
  sucessMeg: string;
  showReportList = {
    Reportname: false
  }
  fromWeek: string;
  errorMessage: string;
  payloadBataWeek = {};
  selectedValue = 'M';
  fromDate: string;
  arr = ['fromDate', 'toDate'];
  selectedVal: String;
  date: Date;
  toDate: string;
  ShopCodes: any[] = [];
  DistrictCodes: any[] = [];
  onlyPerfix: any[] = [];
  uniquerper: any[] = [];
  selectedList: any[] = [];
  reportPrefixOnly = {};
  yearMonthList: any[] = [];
  uniqueYear: any[] = [];
  uniqueMonth: any[] = [];
  mergedField : string;
  fromInvSequenceNo : string;
  toInvSequenceNo : string;
  invoiceType : string;
  firstDay: Date;
  lastDay: Date;
  minimumDate: Date;
  showDivDates = {
    SelectedDates: false
    }

  constructor(private _router: Router, private _generateReportService: RdsReportGstService,
    private _utilsService: UtilsService,private datePipe: DatePipe) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
    this.uniqueMonth = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    this.yearListdata();
    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.loginUserEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail;
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        this.reportNames = data;
        this.reportNames.forEach(element => {
          let reportPrefixOnly: {} = element.reportName.split("_");
          let n = element.reportName.indexOf('_');          
          var output = element.reportName.substring(n + 1);
          let rsp_prf = {
            "perfix": reportPrefixOnly[0],
            "reportName": output,
            "reportKey": element.reportKey
          };
          this.onlyPerfix.push(rsp_prf);
        });
        this.uniquerper = [...new Set(this.onlyPerfix.map(x => x.perfix))];
        this.onSelectPerfix("GST");
      }
    )

    this._generateReportService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        this.concepts.forEach(
          concept => {
            this.conceptswithAll.push(concept);
            if (concept.division == 'RETAIL' && concept.concCode != 'N') {
              this.conceptsForRetail.push(concept);
            }
          }
        );
        let conc = new Concept();
        conc.concCode = 'ALL';
        conc.concept = 'ALL';
        conc.description = 'ALL CONCEPT';
        this.conceptswithAll.unshift(conc);
        //this.searchParameterModel.concept = 'ALL';
      }
    )
  }

  onSelectConcept(value) {
    this.clearErrorMsg();
    this.loaDer.nativeElement.style.display = 'block';
    this.ShopCodes = [];
    this.DistrictCodes = [];
    let divisionCode: string;
    this.searchParameterModel.concept=undefined;
    this.concepts.filter(cs => cs.concCode === this.searchParameterModel.conceptCode).forEach(
      div => {
        if (value != 'ALL') { 
        this.searchParameterModel.concept = (div.concCode +'-'+ div.description);
        }
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
      }
    )
    let detail: any;
    if (value != 'ALL') {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': divisionCode,
        'userCode': this.username,
        'conceptCode': this.searchParameterModel.conceptCode
      }
    }
    else {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': '5',
        'userCode': this.username
      }
    }
    const requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.distEnable = false;
      this.regionEnable = false;
      this.paraEnable = false;
      this.invNoFromEnable = false;
      this._generateReportService.getLocations(requestParameterModel).subscribe(
        (res: any) => {
          this.ShopCodes = [];
          this.DistrictCodes = [];
          if (res.dataList != null) {
            res.dataList.forEach(
              data => {
                this.ShopCodes.push(data);
                this.DistrictCodes.push(data);
                this.searchParameterModel.regionCode = undefined;
                this.searchParameterModel.toRdcCode = undefined;
                this.searchParameterModel.rdcNo = undefined;
                this.searchParameterModel.businessCenter = undefined;
                this.searchParameterModel.RDCCode = undefined;
                this.searchParameterModel.RdcCode = undefined;
                this.searchParameterModel.rdcCode = undefined;
              }
            );
            // const details =
            // {
            //   "shopNumber": "ALL",
            //   "shopName": "All RDC CODE"
            // };
            // this.ShopCodes.unshift(details);
          }
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    else {
      this.shopEnable = true;
      this.distEnable = true;
      this.regionEnable = true;
      this.searchParameterModel.regionCode = undefined;
      this.searchParameterModel.toRdcCode = undefined;
      this.searchParameterModel.rdcNo = undefined;
      this.searchParameterModel.businessCenter = undefined;
      this.searchParameterModel.RDCCode = undefined;
      this.searchParameterModel.RdcCode = undefined;
      this.searchParameterModel.rdcCode = undefined;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  public async OnClickGenerate(myForm): Promise<void> {
    this.responseMessage = '';
    this.errorMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.loaDer.nativeElement.style.display = 'block';
    let switchFlag: any;
    let reportKey: any;
    let reportName: any;
    this.sucessMeg = 'Success';
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        switchFlag = report.switchingFlag;
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
   
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    let userDetails = {
      "name": "userId",
      "value": this.username
    }
    this.requestParameterModel.push(userDetails);
    console.log('requesting parameters inside updatedDatalist' + JSON.stringify(this.requestParameterModel));
    let details = new ReportGeneration();
    if (switchFlag == 'N') {
      console.log('Report will get generated online');
      if(this.searchParameterModel.conceptCode != null){
      details.reportFormatType = this.reportModel.reportFormatType;
      details.reportInputRequestList = this.requestParameterModel;
      this.callingServiceWithoutBlob(details);
      this.loaDer.nativeElement.style.display = 'none';
    } else{
      this.errorMessage = 'Concept Code is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
    }
  }
    else {
      console.log('Report will get generated offline');
      let detail = {
        "name": "reportKey",
        "value": reportKey
      }
      let nameDetail = {
        "name": "reportName",
        "value": reportName
      }
      this.requestParameterModel.push(detail);
      this.requestParameterModel.push(nameDetail);
      console.log(JSON.stringify(this.requestParameterModel));
      this._generateReportService.offlineReportGeneration(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          if (response.status == 'success') {
            // this.responseMessage = response.message;
            this.asyncSuccess = 'success';
            this.responseMessage = "Your report will be generated soon, you will be notified over mail. You can check the progress in View Report Status.";
          }
          else {
            this.asyncSuccess = 'Failed';
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.asyncSuccess = 'Failed';
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
  }

  callingServiceWithoutBlob(details) {
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    let reportDetails =
    {
      "name": "reportName",
      "value": reportName
    }
    this.requestParameterModel.push(reportDetails);
    let reportKeySelected =
    {
      "name": "reportKey",
      "value": reportKey
    }
    this.requestParameterModel.push(reportKeySelected);
    let userSelectedEmail =
    {
      "name": "userEmail",
      "value": this.loginUserEmail
    }
    this.requestParameterModel.push(userSelectedEmail);
    if (this.searchParameterModel.conceptCode === 'ALL') {
      let index = this.requestParameterModel.findIndex(cs => cs.name == 'conceptCode');
      this.requestParameterModel.splice(index, 1);
    }
    this.requestParameterModel.forEach((element,index)=>{
      if(element.name == 'bataYear') this.requestParameterModel.splice(index,1);
   });
    if (this.reportModel.reportKey === 'GSTR18_IT' || this.reportModel.reportKey === 'GSTR29_IHS' ||
    this.reportModel.reportKey === 'GSTR19_HWSO' || this.reportModel.reportKey === 'GSTR21' || this.reportModel.reportKey === 'GSTR21_IMPORT' ||
    this.reportModel.reportKey === 'GSTR_S' || this.reportModel.reportKey === 'GSTR11' || this.reportModel.reportKey === 'ICCNR' ||
    this.reportModel.reportKey === 'GSTR1_RETURN' || this.reportModel.reportKey === 'GSTR_SC' || this.reportModel.reportKey == 'GSTR_NV') {

      if (this.searchParameterModel.regionCode != null || this.searchParameterModel.toRdcCode != null || 
        this.searchParameterModel.businessCenter != null || this.searchParameterModel.RDCCode != null ||
        this.searchParameterModel.RdcCode != null || this.searchParameterModel.rdcCode != null) {

          if ((this.searchParameterModel.fromWeek != null && this.searchParameterModel.toWeek != null && this.searchParameterModel.fromWeek != '' && this.searchParameterModel.toWeek != '') 
          || (this.searchParameterModel.fromDate != null && this.searchParameterModel.toDate !=  null)) { 
            console.log(this.searchParameterModel.year);
            
            this.changeSelectedDate(this.searchParameterModel.toDate, this.searchParameterModel.fromDate);
            if (this.searchParameterModel.fromWeek != null && this.searchParameterModel.toWeek != null){
              let statusCheck: string;
              // statusCheck = this.datecheck(this.searchParameterModel.fromDate, this.searchParameterModel.toDate);
              // if (this.reportModel.reportKey === 'ICCNR' ) {
              //   statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.fromWeek, this.searchParameterModel.toWeek,'Year Week From','Year Week To');
              // }
              // else{
              statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.fromWeek, this.searchParameterModel.toWeek,'From BATA Week','To BATA Week');
              //} 
              if (statusCheck == 'success') {
                 this.callReportService(details);
               }
              //  else if(this.searchParameterModel.fromWeek != null || this.searchParameterModel.toWeek != null){
              //    this.errorMessage = 'Both From BATA Week and To BATA Week should be filled';
              //  }
               else{
                this.errorMessage = statusCheck;
   
               }
              }
            else{
              this.errorMessage = '';
              this.callReportService(details);

            }
            // details.reportInputRequestList = this.requestParameterModel;
            // console.log(JSON.stringify(details));
            // this.callReportService(details);    
      } 
      else {
        this.errorMessage = 'Either "From Week & To Week" / "Financial Year & Financial Month" is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
      }
      } else {
        this.errorMessage = 'Either "RDC Code" / "Region" is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
      }
    }
    else if (this.reportModel.reportKey === 'EWAY_BILL'  || this.reportModel.reportKey === 'GRRM_BC') {

      if (this.searchParameterModel.regionCode != null || this.searchParameterModel.rdcNo != null) {

          if (this.searchParameterModel.year != undefined && this.searchParameterModel.month != undefined) { 
            this.changeSelectedDate(this.searchParameterModel.toDate, this.searchParameterModel.fromDate);
            this.callReportService(details);
      } 
      else {
        this.errorMessage = '"Financial Year & Financial Month" is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
      }
      } 

      else if (this.searchParameterModel.rdcCode != undefined) {

        if ((this.fromInvSequenceNo != '' && this.fromInvSequenceNo != undefined && this.invoiceType != undefined 
        && this.searchParameterModel.bataYear != undefined) ||
          (this.searchParameterModel.year != undefined && this.searchParameterModel.month != undefined)) { 

            this.changeSelectedDate(this.searchParameterModel.toDate, this.searchParameterModel.fromDate);
              if (this.fromInvSequenceNo != '' && this.fromInvSequenceNo != undefined){
                //this.searchParameterModel.fromInvoiceNo = this.mergedField + this.fromInvSequenceNo;
                let userDetails  = {
                  "name": "fromInvoiceNo",
                  "value": this.mergedField + this.fromInvSequenceNo
                };
                this.requestParameterModel.push(userDetails);
              }
              if (this.toInvSequenceNo != '' && this.toInvSequenceNo != undefined) {
                // this.searchParameterModel.fromInvoiceNo = this.mergedField + this.fromInvSequenceNo;
                // this.searchParameterModel.toInvoiceNo = this.mergedField + this.toInvSequenceNo;
                let userDetails  = {
                  "name": "toInvoiceNo",
                  "value": this.mergedField + this.toInvSequenceNo
                };
                this.requestParameterModel.push(userDetails);
              }
             
              
             
          //this.changeSelectedDate(this.searchParameterModel.toDate, this.searchParameterModel.fromDate);
          this.callReportService(details);
    } else {
      this.errorMessage = 'Either "Invoice Type,Bata Year,From Invoice Seq No" / "Financial Year,Financial Month" is REQUIRED.';
      this.loaDer.nativeElement.style.display = 'none';
    }
    } 
    else {
      this.errorMessage = 'Either "RDC Code" / "Region" is REQUIRED.';
      this.loaDer.nativeElement.style.display = 'none';
    }
    }
    else {
      this.callReportService(details);
    }
  }

  callReportService(details) {
    this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.reportKey).subscribe(
      (response: any) => {
        if (response.status == 'Success') {
          this.asyncSuccess = 'success';
          this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          this.loaDer.nativeElement.style.display = 'none';
          this.generatebutton = 'disable';
          this.delay(5000).then(any => {
            this.generatebutton = 'enable';
          });
        }
        else {
          this.asyncSuccess = 'Failed';
          this.errorMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.asyncSuccess = 'Failed';
        this.errorMessage = err;
        this.loaDer.nativeElement.style.display = 'none';
      });
  }

  changingValue() {
    this.clearErrorMsg();
    this.mergedField = '';
    if( this.searchParameterModel.rdcCode != undefined && this.searchParameterModel.bataYear != undefined 
      && this.invoiceType != undefined){
        let yearhalf: string;
        yearhalf = this.searchParameterModel.bataYear.slice(-2);
        let n = this.searchParameterModel.rdcCode.indexOf('0');         
        var output = this.searchParameterModel.rdcCode.substring(n + 1);
       this.mergedField = this.invoiceType + output + yearhalf;
      }
  }

  changeSelectedDate(todate, fromdate) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    // let deliveryToDate: any[] = todate.split('/');
    // let etDate = new Date(deliveryToDate[2], deliveryToDate[1], deliveryToDate[0]);
    this.requestParameterModel.filter(cs => cs.name == 'fromDate' || cs.name == 'toDate').forEach(
      each => {
        let deliveryFrmDate: any[] = each.value.split('/');
        let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1, deliveryFrmDate[0]);
        each.value = this._utilsService.transformDateSearchchange(stDate);
      }
    )
  }

  onClickReset() {
    this.searchParameterModel = new ReportSearch();
    console.log('reset report search details');
    //this.searchParameterModel.concept = 'ALL';
    this.responseMessage = '';
    this.errorMessage = "";
    this.sucessMeg1 = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.regionEnable = true;
    this.enable = false;
    this.shopEnable = true;
    this.distEnable = true;
    this.dateEnable = false;
    this.weekEnable = false;
    this.invNoToEnable = true;
    this.invNoFromEnable = false;
    this.paraEnable = false;
    this.mergedField = '';
    this.fromInvSequenceNo = '';
    this.toInvSequenceNo = '';
    this.invoiceType = undefined;
    this.searchParameterModel.region = "";
    this.showReportList.Reportname = false;
    this.showDivDates.SelectedDates = false;
  }
  clearErrorMsg() {
    this.errorMessage = '';
    this.responseMessage = '';
  }


  setData() {
    console.log('removed');
    this.reportModel.reportFormatType = 'xlsx';
    this.responseMessage = '';
    this.sucessMeg1 = '';
    this.errorMessage = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    this.onClickReset();
    //this.searchParameterModel.concept = 'ALL';
    // if(this.reportModel.reportKey == 'GSTR_NV'){
    //   this.errorMessage = "This report has not been implemented yet.";
    // }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  regionChangeShopCode(regionVal) {
    this.clearErrorMsg();
    if (regionVal != '0: undefined') {
      this.invoiceType = undefined;
	    this.mergedField = undefined;
	    this.searchParameterModel.bataYear = undefined;
	    this.fromInvSequenceNo = undefined;
      this.toInvSequenceNo = undefined;
      this.invNoToEnable = true;
      this.shopEnable = true;
      this.distEnable = true;
      this.invNoFromEnable = true;
      this.paraEnable = true;
    }
    else {
      this.shopEnable = false;
      this.distEnable = false;
      this.invNoFromEnable = false;
      this.paraEnable = false;
      this.invNoToEnable = true;
    }
  }

  onBlurWeek(value) {
    this.clearErrorMsg();
    // this.dateEnable = false;
    if (value != '' && value != undefined ) {
      //this.dateEnable = true;
      this.invNoToEnable = false;
    }
    else {
      //this.dateEnable = false;
      this.toInvSequenceNo = undefined;
      this.invNoToEnable = true;
    }
    // if(this.searchParameterModel.fromDate != '' && this.searchParameterModel.toDate != ''){
    //   this.dateEnable = true;
    // }
    // else{
    //   this.dateEnable = false;
    // } 
  }

  changeDistrictCode(distValue) {
    this.clearErrorMsg();
    if (distValue != '0: undefined') {
      this.regionEnable = true;
      this.shopEnable = true;
    }
    else {
      this.regionEnable = false;
      this.shopEnable = false;
    }
  }

  onBlurCode(value) {
    this.clearErrorMsg();
    if (value != '0: undefined') {
      this.regionEnable = true;
      this.distEnable = true;
      this.changingValue();
    }
    else {
      this.regionEnable = false;
      this.distEnable = false;
      this.mergedField = '';
    }
  }

  onSelectPerfix(event) {
    this.clearErrorMsg();
    // if(event != undefined){
    //  this.showReportList.Reportname = true;
    // }
    console.log(event);
    this.selectedList = [];
    this.onlyPerfix.filter(cs => cs.perfix === event).forEach(
      reportkN => {
        this.selectedList.push(reportkN);
      }
    )
    console.log(this.selectedList);
  }

  currentDate() {
    this.clearErrorMsg();
    this.date = new Date();
    this.searchParameterModel.toDate = this._utilsService.transformDate1(this.date);
    this.searchParameterModel.fromDate = this._utilsService.transformDate1(this.date);
    console.log(this.toDate);
  }
  
   onSelectMonth(value){
    console.log(value);  
    this.clearErrorMsg(); 
    // this.showDivDates.SelectedDates = true;
    this.searchParameterModel.fromWeek = undefined;
    this.searchParameterModel.toWeek = undefined;
    this.searchParameterModel.fromDate = undefined;
    this.searchParameterModel.toDate = undefined;
    this.minimumDate = new Date();
    if (value != '0: undefined') {
      this.weekEnable = true;
      // this.invNoFromEnable = true;
      // this.invNoToEnable = true;
    }
    else {
      this.weekEnable = false;
      // this.invNoFromEnable = false;
      // this.invNoToEnable = false;
    }
    if(this.searchParameterModel.year != undefined && this.searchParameterModel.month != undefined){
      this.firstDay = new Date(parseInt(this.searchParameterModel.year), (parseInt(this.searchParameterModel.month)-1), 1);
    this.lastDay = new Date(parseInt(this.searchParameterModel.year),(parseInt(this.searchParameterModel.month)), 0);
    console.log(this.lastDay);console.log(this.firstDay); 
    this.searchParameterModel.fromDate =  this.datePipe.transform(this.firstDay,"dd/MM/yyyy").toString();
    this.searchParameterModel.toDate =  this.datePipe.transform(this.lastDay,"dd/MM/yyyy").toString(); 
    } 
    if(this.searchParameterModel.year != undefined || this.searchParameterModel.month != undefined){
      this.weekEnable = true;
    }
  } 
  
  yearListdata(){
    this.clearErrorMsg();
    this._generateReportService.getlistofbataWeeks().subscribe(
      (data: any) => {
        this.yearMonthList = data.dataList;
        this.uniqueYear = [...new Set(this.yearMonthList.map(x => x.bataYear))];
        this.uniqueYear.sort(function(a, b){ return b-a});
        // this.uniqueMonth = [...new Set(this.yearMonthList.map(x => x.bataMonth))];     
        // let d = new Date();
        // this.uniqueMonth = Array.apply(null, Array(12)).map((v, k) => d.setMonth(k));
        });
      }
      
  onBlurBataWeek(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    if(bataWeekVal.length == 6 ){
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    let bataNumber = bataWeekVal.substring(4, 6);
    let bataYear = bataWeekVal.substring(0, 4);
    console.log(bataNumber);
    console.log(bataYear);
    this.payloadBataWeek = {
      "bataYear": bataYear,
      "bataNumber": bataNumber,
      "searchFlag": searchFlag,
      "fromToFlag": fromToFlag
    }
    this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.message == "Success") {
          const fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
          this.searchParameterModel.fromDate = fromDateDaily1;
        }
      });
    }
  }

  onBlurBataWeekTo(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    if(bataWeekVal.length == 6){
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    let bataNumber = bataWeekVal.substring(4, 6);
    let bataYear = bataWeekVal.substring(0, 4);
    console.log(bataNumber);
    this.payloadBataWeek = {
      "bataYear": bataYear,
      "bataNumber": bataNumber,
      "searchFlag": searchFlag,
      "fromToFlag": fromToFlag
    }
    this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.message == "Success") {
          let fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
          this.searchParameterModel.toDate = fromDateDaily1;
        }

      });
    }

  }

}
