import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generate-export-po',
  templateUrl: './generate-export-po.component.html',
  //styleUrls: ['./generate-export-po.component.css']
  styleUrls: ['../../assets/css/bootstrap.css','../../assets/css/bil-style.css']
})
export class GenerateExportPoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
