import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, of, throwError, from } from 'rxjs';
import { gstVarList } from './gstVarList';

@Injectable({
  providedIn: 'root'
})
export class GstMasterService {

  // tslint:disable-next-line: variable-name
  gst_values: string = RdsBilApiUrl.GST_DATA;
  // insertGst = 'http://130.61.72.144:8011/invoice/insertGSTMaster';
  // updateGst = 'http://130.61.72.144:8011/invoice/updateGSTMaster';
   insertGst: string = RdsBilApiUrl.INSERT_GST;
   updateGst: string = RdsBilApiUrl.UPDATE_GST;
   GSTStatusUrl: string = RdsBilApiUrl.GST_STATUS_CHANGE_URL;
  // tslint:disable-next-line: variable-name
  constructor(private _http: HttpClient) {

  }

getGstListValues() {
  return this._http.post(this.gst_values, 0 );
}

saveData(data) {
    return this._http.post(this.updateGst, data);
}

getAddNewDetails(insertData) {
  // const reqHeader = new HttpHeaders().set('Content-Type','application/json').set('Accept','application/json');

  return this._http.post(this.insertGst, insertData);
}
/*-------------------------------- Handle Error  Service ---------------------------------*/
   private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
}

GSTStatusChange(details) {
  return this._http.post(this.GSTStatusUrl, details);
}

 /*-------------------------------- Get search Data Service ---------------------------------*/

}
