import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class SearchInventoryService {

  inventory_search_url: string = RdsBilApiUrl.INVENTORY_SEARCH;
  shop_location_url = RdsBilApiUrl.SEARCH_LOCATION_URL;
  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
  config_details_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  constructor(private http: HttpClient) { }

  getInventoryBySearchCriteria(data) {
    return this.http.post(this.inventory_search_url, data);
  }

  getConcepts(conceptQueryMas) {
    return this.http.post(this.concept_url, conceptQueryMas);
  }

  getLocations(requestParameterModel) {
    return this.http.post(this.shop_location_url, requestParameterModel);
  }

  getStatus() {
    const details: any = [{
      'name': 'paramName',
      'value': 'INVENTORY_STATUS'
  }]
    return this.http.post(this.config_details_url, details);
  }

  getArticleType() {
    const details: any = [{
      'name': 'paramName',
      'value': 'ARTICLE_TYPE'
  }]
    return this.http.post(this.config_details_url, details);
  }
  getSizeDetails(details) {
    return this.http.post(this.size_url, details);
  }
}
