import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../../common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class AsnUpdateService {

  SEARCH_ASN_URL: string = RdsBilApiUrl.RDC_SEARCH_LINES;
  UPDATE_STATUS: string = RdsBilApiUrl.UPDATE_TRANSPORT;

  // tslint:disable-next-line: variable-name
  constructor(private _http: HttpClient) { }

  getInvoiceLineDetails(challanInvoice) {
    const details =
    [{
      // tslint:disable-next-line: object-literal-key-quotes
      'name': 'challanInvoiceNumber',
      // tslint:disable-next-line: object-literal-key-quotes
      'value': challanInvoice
     }
     ];

    return this._http.post(this.SEARCH_ASN_URL, details);
  }

  addpost(GINNumber) {
    return this._http.post(this.UPDATE_STATUS , GINNumber);
  }

}
