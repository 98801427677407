export class SupplierAccessSearchDetails {
    activeFlag:string;
    partyNumber:string;
    lastUpdateDate:string;
    lastUpdatedBy:string;
    userCode:string;
    userId:string;
    userEmail:string;
    userSupplyId:string;
    userName:string;
    updatedBy:string;
    createdDate:string;
    createdBy:string;
    constructor(){}
}