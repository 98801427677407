import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormArray, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from "../../common/utils-service";
import { CycleCountService } from "./cycle-count.service";

@Component({
  selector: "app-cycle-count",
  templateUrl: "./cycle-count.component.html",
  styleUrls: [
    "../../../assets/css/bootstrap.css",
    "../../../assets/css/rds-bil-style.css",
    "../../../../node_modules/font-awesome/css/font-awesome.css",
    "./cycle-count.component.css",
  ],
})
export class CycleCountComponent implements OnInit {
  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  //@ViewChild("loaDer1", { static: false }) loaDer1: ElementRef;

  concepts: any[];
  division: string;
  userCode: string;
  ShopCodes: any[] = [];
  responseMessage: string;
  cycleCountList: any[];
  ShowSearchDisable: boolean = false;
  cycleCountForm: FormGroup;
  assortData: any = {};
  groupCode: string;
  artNumber: string;
  assortArray: any[];
  sizeList: any[];
  inventory: any[];
  articleTypes = [
    { id: "1", name: "FOOTWEAR", value: "FOOTWEAR" },
    { id: "2", name: "NON-FOOTWEAR", value: "NON FOOTWEAR" },
    { id: "3", name: "CONSUMABLES", value: "CONSUMABLES" },
  ];

  constructor(
    private fb: FormBuilder,
    private cycleCountService: CycleCountService,
    private _utilsService: UtilsService
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.buildForm();
  }

  buildForm(): void {
    //updated by debamala dated 28-7-20
    this.cycleCountForm = this.fb.group({
      conceptCode: [null],
      inventoryShopCode: [null],
      artType: [null],
      subCategoryCode: ["", [Validators.maxLength(4), Validators.minLength(4)]],
      categoryCode: ["", [Validators.maxLength(2), Validators.minLength(2)]],
      artNumber: ["", [Validators.maxLength(7), Validators.minLength(7)]],
      bataWeekFrom: ["",[Validators.maxLength(6), Validators.minLength(6)]],
      bataWeekTo: ["",[Validators.maxLength(6), Validators.minLength(6)]],
      // stockType: [null],
      userCode: [this.userCode],
      transactionType: ["STK_ADJ"],
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = "none";
      let conceptQueryMas = [
        {
          name: "email",
          value: this.userCode,
        },
      ];
      this.cycleCountService.getConcepts(conceptQueryMas).subscribe(
        (data: any) => {
          this.concepts = data.dataList.filter(function (list) {
            return list.division == "RETAIL";
          });
        },
        (err) => {
          this.responseMessage = err;
        }
      );
      var status_details = [
        {
          name: "paramName",
          value: "INVENTORY_STATUS",
        },
      ];
      this.cycleCountService.getStatus(status_details).subscribe(
        (data: any) => {
          this.inventory = data.configParamModels;
        },
        (err) => {
          this.responseMessage = err;
        }
      );
    }, 100);
  }

  showShopCode(conceptCode) {
    let details = [
      { name: "shopCloseYrWk1", value: "0" },
      { name: "shopAgencyCode", value: "5" },
      { name: "userCode", value: this.userCode },
      { name: "conceptCode", value: conceptCode },
    ];
    this.cycleCountService.getShopCode(details).subscribe(
      (data: any) => {
        this.ShopCodes = data.dataList;
      },
      (err) => {
        this.responseMessage = err;
      }
    );
  }

  characterCheck(event, name) {
    var regExpr = /[^a-zA-Z0-9-. ]/g;
    var userText = event.target.value;
    if (userText != "") {
      if (name == "subCategoryCode") {
        this.cycleCountForm.controls["subCategoryCode"].setValue(
          userText.replace(regExpr, "")
        );
        if (userText.length > 4) {
          var res = userText.substr(0, 4);
          this.cycleCountForm.controls["subCategoryCode"].setValue(res);
        } else {
          if (userText.length != 4) {
            this.ShowSearchDisable = true;
          } else {
            this.ShowSearchDisable = false;
          }
        }
      }
      if (name == "categoryCode") {
        this.cycleCountForm.controls["categoryCode"].setValue(
          userText.replace(regExpr, "")
        );
        if (userText.length > 2) {
          var res = userText.substr(0, 2);
          this.cycleCountForm.controls["categoryCode"].setValue(res);
        } else {
          if (userText.length != 2) {
            this.ShowSearchDisable = true;
          } else {
            this.ShowSearchDisable = false;
          }
        }
      }
      if (name == "artNumber") {
        this.cycleCountForm.controls["artNumber"].setValue(
          userText.replace(regExpr, "")
        );
        if (userText.length > 7) {
          var res = userText.substr(0, 7);
          this.cycleCountForm.controls["artNumber"].setValue(res);
        } else {
          if (userText.length != 7) {
            this.ShowSearchDisable = true;
          } else {
            this.ShowSearchDisable = false;
          }
        }
      }
      if (name == "bataWeekTo") {
        this.cycleCountForm.controls["bataWeekTo"].setValue(
          userText.replace(regExpr, "")
        );
        if (userText.length > 6) {
          var res = userText.substr(0, 5);
          this.cycleCountForm.controls["bataWeekTo"].setValue(res);
        } else {
          if (userText.length !== 6) {
            this.ShowSearchDisable = true;
          } else {
            this.ShowSearchDisable = false;
          }
        }
      }
      if (name == "bataWeekFrom") {
        this.cycleCountForm.controls["bataWeekFrom"].setValue(
          userText.replace(regExpr, "")
        );
        if (userText.length > 6) {
          var res = userText.substr(0, 5);
          this.cycleCountForm.controls["bataWeekFrom"].setValue(res);
        } else {
          if (userText.length !== 6) {
            this.ShowSearchDisable = true;
          } else {
            this.ShowSearchDisable = false;
          }
        }
      }
    } else {
      this.ShowSearchDisable = false;
    }
  }

  onReset() {
    this.ShopCodes=[];
    this.cycleCountForm.reset();
    setTimeout(() => {
      this.buildForm();
    }, 500);
    this.cycleCountList = [];
    this.responseMessage = "";
  }

  searchCycleCount() {
    if (this.cycleCountForm.controls["conceptCode"].value == null && this.cycleCountForm.controls["inventoryShopCode"].value == null && this.cycleCountForm.controls["artType"].value == null && this.cycleCountForm.controls["subCategoryCode"].value == "" && this.cycleCountForm.controls["artNumber"].value == "" && this.cycleCountForm.controls["categoryCode"].value == "" && this.cycleCountForm.controls["bataWeekFrom"].value == "" && this.cycleCountForm.controls["bataWeekTo"].value == "") {
      this.responseMessage = "Please select any search criteria.";
      return false;
    } else if(this.cycleCountForm.controls["bataWeekFrom"].value> this.cycleCountForm.controls["bataWeekTo"].value){
      this.responseMessage = '';
      this.responseMessage='BataWeekFrom should be less than BataWeekTo';
      this.loaDer.nativeElement.style.display = 'none';
      return false;
    }else {
      this.responseMessage = "";
      this.cycleCountList = [];
      this.loaDer.nativeElement.style.display = "block";
      this.cycleCountService
        .getCycleCountDetails(this.cycleCountForm.value)
        .subscribe(
          (response: any) => {
            this.loaDer.nativeElement.style.display = "none";
            if (response.status == "success") {
              this.cycleCountList = response.dataList;
            } else {
              this.responseMessage = "No Data Found";
            }
          },
          (err) => {
            this.responseMessage = "No Data Found";
            this.loaDer.nativeElement.style.display = "none";
          },
          () => {
            this.loaDer.nativeElement.style.display = "none";
          }
        );
    }
  }

  setCycleCountAssortmentDataPopup(transactionId, artNumber) {
    this.assortArray = [];
    let firstletter = artNumber.charAt(0);
    let index = this.cycleCountList.findIndex(
      (x) => x.transactionId == transactionId
    );
    if (index != -1) {
      this.assortData = this.cycleCountList[index];
      this.artNumber = artNumber;
      let details = [{ "name": "letterOfArticle", "value": firstletter }];
      this.cycleCountService
        .getSizeDetails(details)
        .subscribe(
          (response: any) => {
            if (response.status == "Success") {
              this.groupCode = response.dataList[0].groupCode;
              this.assortArray = [];
              response.dataList.forEach(data => {
                var assortmentArray = this.assortData.inventoryTransactionAssortment;
                var found = assortmentArray.findIndex(element => element.artSize == data.artSize);
                if (found != -1) {
                  var assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWiseQty: assortmentArray[found].pairQty };
                  this.assortArray.push(assortmentData);
                }
              });
            }
          },
          (err) => {
            this.assortArray = [];
          }
        );
    }
  }

}
