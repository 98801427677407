import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class CreateRdcMaintenanceService {

  rdcMaintenance_create_url: string = RdsBilApiUrl.CREATE_RDC_MAINTENANCE;

  constructor(private http: HttpClient) { }

  getCreateRDC(details :any){
    return this.http.post(this.rdcMaintenance_create_url, details);
  }
}
