import { Injectable } from '@angular/core';
import { BilApiUrl } from '@/common/bil-api-url';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ManageSubPartyMasterService {

   private _manage_sub_party_master_url: string = BilApiUrl.MANAGE_SUB_PARTY_MASTER;
   private country_url: string = BilApiUrl.COUNTRY_ALL_URL;
  constructor(private _http: HttpClient) { }

  getSubPartyMaster(manage_sub_party_master_param:any){
    return this._http.post(this._manage_sub_party_master_url,manage_sub_party_master_param);
  }
  getCountry() {
    return this._http.get(this.country_url);
  }
  
}
