import { Component, OnInit } from '@angular/core';
import { ManageSubPartyMasterService } from './manage-sub-party-master.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@/common/utils-service';
import {NO_RESULT_FOUND} from '../common/field-name-list';

@Component({
  selector: 'app-manage-sub-party-master',
  templateUrl: './manage-sub-party-master.component.html',
  styleUrls: ['./manage-sub-party-master.component.css']
})
export class ManageSubPartyMasterComponent implements OnInit {

  subPartyMasterForm: FormGroup;
  manageSubPartyMasterResponse: any;
  no_result_found :any = NO_RESULT_FOUND;
  responseMessage: string;
  countries:any;
  subparty_country:string;
  noSearchMessage:string;

  constructor(private _manageSubPartyMasterService: ManageSubPartyMasterService, private fb: FormBuilder, 
    private _utilsService: UtilsService) {
    this.subPartyMasterForm = this.createSubPartyMasterForm();
  }

  createSubPartyMasterForm() {
    let serachSubPartyMasterFields = {};
    serachSubPartyMasterFields['code'] = '';
    serachSubPartyMasterFields['fullName'] = '';
    serachSubPartyMasterFields['address1'] = '';
    serachSubPartyMasterFields['zipCode'] = '';
    serachSubPartyMasterFields['country'] = [null];
    return this.fb.group(serachSubPartyMasterFields);
  }

  submitSubPartyMasterForm(formData: any) {
    let manage_sub_party_master_param = [];
    this.noSearchMessage = '';
    manage_sub_party_master_param = this._utilsService.createRequestParametersModelArray(formData).filter(e=> e.value !=='' || e.value!==null);
    console.log(manage_sub_party_master_param);
    if(manage_sub_party_master_param != [] && manage_sub_party_master_param.length != 0){
    this._manageSubPartyMasterService.getSubPartyMaster(manage_sub_party_master_param)
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.dataList != null) {
            if(response.dataList.length > 0){
              this.manageSubPartyMasterResponse = [];
              this.responseMessage = '';
              this.manageSubPartyMasterResponse = response.dataList;

            }
             if(response.dataList.length == 0){
                this.manageSubPartyMasterResponse = [];
                this.responseMessage = this.no_result_found;
            }
          }
        }
      );
    }
    else{
      this.noSearchMessage = 'Please give a search criteria';
    }
  }

  resetForm(){
    this.manageSubPartyMasterResponse = [];
    this.responseMessage = '';
    this.noSearchMessage = '';
  }


  fetchCountry(){
    this.countries = [];
    this._manageSubPartyMasterService.getCountry().subscribe(
      (data: any) => {
        data.configParamModels.forEach(
           d => {
             if(d.paramName == 'subparty_country'){
            let {paramValue} = d;
            this.countries.push({paramValue});
             }
          }
        );
      }
    );
  }

  ngOnInit() {
    this.fetchCountry();
  }

}
