import { Injectable } from '@angular/core';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FinanceScratchingService {

  private _finance_search_result_url = RdsBilApiUrl.SEARCH_FINANCE_DETAILS;
  private _finance_discard_url= RdsBilApiUrl.DISCARD_FINANCE_DETAILS;
  private config_details_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;

  constructor(private _http: HttpClient) { }

  financeSearch(searchParam:any[]){
    return this._http.post(this._finance_search_result_url,searchParam);
  }
  discardFinanceInvalidate(discardDetails:any){
    return this._http.post(this._finance_discard_url,discardDetails);
  }
  getStatus() {
    const details: any = [{
      'name': 'paramName',
      'value': 'INVENTORY_STATUS'
  }]
    return this._http.post(this.config_details_url, details);
  }

}
