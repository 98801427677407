import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PendingShortageAdjustmentService } from "./pending-shortage-adjustment.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pending-shortage-adjustment',
  templateUrl: './pending-shortage-adjustment.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css',
    '../../../assets/css/rds-bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css',
    './pending-shortage-adjustment.component.css']
})
export class PendingShortageAdjustmentComponent implements OnInit {
  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  @ViewChild('closeBtn1', { static: false }) closeBtn1: ElementRef;

  userCode: string;
  userEmail: string;
  responseMessage: string;
  responseMessage1: string;
  shortageAdjustmentList: any[];
  listObj: any[];
  shortageAdjustmentDrpdwn: any[];
  stockAdjustmentForm: FormGroup;
  assortData: any = {};
  debitCreditNoteId: any;
  debitCreditTxnNumber: any;
  grnNumber: any;
  txnId: any;
  remarks: string;
  statuses: any[];
  groupCode: string;
  artNumber: string;
  assortArray: any[];
  selectedRow: any[];
  approveBtn:Boolean = false;
  isPrintEnabled: boolean = false;
  inventoryType:any;

  constructor(
    private fb: FormBuilder,
    private PendingShortageAdjustmentService: PendingShortageAdjustmentService,private httpClient: HttpClient
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.userEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail;
    //this.userCode = "user.test4";
    this.buildForm();
  }

  buildForm(): void {
    this.stockAdjustmentForm = this.fb.group({
      // articleNumber: [""],
      grnNumber: [""],
      shortAdjustmentDebitedTo: [null],
      approvalStatus: [null],
      bataWeekFrom: ["", [Validators.maxLength(6), Validators.minLength(6)]],
      bataWeekTo: ["", [Validators.maxLength(6), Validators.minLength(6)]],
      userCode: [this.userCode],
      txnType: ["DEBIT"],
    });
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.selectedRow == undefined) {
        this.selectedRow = [];
      }
      this.remarks = "";
      this.loaDer.nativeElement.style.display = "none";
      var details = [
        {
          name: "paramName",
          value: "SHORTAGE_ADJUSTED_TO",
        },
      ];
      this.PendingShortageAdjustmentService.getshortageAdjustmentDrpdwn(
        details
      ).subscribe(
        (data: any) => {
          this.shortageAdjustmentDrpdwn = data.configParamModels;
        },
        (err) => {
          this.responseMessage = err;
        }
      );
      // this.statuses = [{ "paramId": 1, "paramName": "APPROVED", "paramValue": "APPROVED", "remarks": "APPROVED" }, { "paramId": 2, "paramName": "REJECTED", "paramValue": "REJECTED", "remarks": "REJECTED" }, { "paramId": 3, "paramName": "PENDING_APPROVAL", "paramValue": "PENDING_APPROVAL", "remarks": "PENDING_APPROVAL" }];
      this.statuses = [{ "paramId": 1, "paramName": "APPROVED", "paramValue": "APPROVED", "remarks": "APPROVED" }, { "paramId": 3, "paramName": "PENDING_APPROVAL", "paramValue": "PENDING_APPROVAL", "remarks": "PENDING_APPROVAL" }];
    }, 100);
  }

  onReset() {
    this.stockAdjustmentForm.reset();
    setTimeout(() => {
      this.buildForm();
    }, 500);
    this.shortageAdjustmentList = [];
    this.listObj = [];
    this.responseMessage = "";
  }

  searchStockAdjustment() {
    setTimeout(() => {
      this.responseMessage = "";
    }, 3000);
    if (this.stockAdjustmentForm.controls["grnNumber"].value == '' && this.stockAdjustmentForm.controls["shortAdjustmentDebitedTo"].value == null && this.stockAdjustmentForm.controls["approvalStatus"].value == null && this.stockAdjustmentForm.controls["bataWeekFrom"].value == "" && this.stockAdjustmentForm.controls["bataWeekTo"].value == "") {
      this.responseMessage = "Please select any search criteria.";
      return false;
    } else {
      this.responseMessage = "";
      this.shortageAdjustmentList = [];
      this.listObj = [];
      this.selectedRow = [];
      this.loaDer.nativeElement.style.display = "block";
      this.PendingShortageAdjustmentService.getPendingStockAdjustmentDetails(
        this.stockAdjustmentForm.value
      ).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          if (response.status == "success") {
            this.shortageAdjustmentList = response.dataList; // datalist
            console.log(this.shortageAdjustmentList);

            
            if (response.dataList != null && response.dataList.length !== 0) {
              for(let x=0;x<response.dataList.length;x++) {

                let flag:any=null;
                flag=false;
                if(this.shortageAdjustmentList[x].shortAdjustmentDebitedTo == "VENDOR"){
                  flag = true;
                  console.log("success");
                  }
                if(this.shortageAdjustmentList[x].isGstinEmptyFlag == "Y"){
                  flag = true;
                  console.log("success - isGstinEmptyFlag");
                  }

                if ( this.listObj.findIndex(i=>i.debitCreditTxnNumber == response.dataList[x]['debitCreditTxnNumber']) == -1 ) {
                  this.listObj.push({
                    "txnId":response.dataList[x]['txnId'],
                    "debitCreditNoteId":response.dataList[x]['debitCreditNoteId'],
                    "debitCreditTxnNumber":response.dataList[x]['debitCreditTxnNumber'],
                    "grnNumber":response.dataList[x]['grnNumber'],
                    "shortAdjustmentDebitedTo":response.dataList[x]['shortAdjustmentDebitedTo'],
                    "debitCreditToCode":response.dataList[x]['debitCreditToCode'],
                    "debitCreditToDate":response.dataList[x]['debitCreditToDate'],
                    "debitCrditToName":response.dataList[x]['debitCrditToName'],
                    "approvalStatus":response.dataList[x]['approvalStatus'],
                    "approvalRemarks":response.dataList[x]['approvalRemarks'],
                    "isPrintEnabled":flag,
                    "inventoryType":response.dataList[x]['inventoryType'],
                    "children":[]
                  });
                }
                let index = this.listObj.findIndex(i=>i.debitCreditTxnNumber == response.dataList[x]['debitCreditTxnNumber']) ;
                this.listObj[index]['children'].push({
                  "debitCreditNoteId":response.dataList[x]['debitCreditNoteId'],
                  "articleNumber":response.dataList[x]['articleNumber'],
                  "adjustmentQty":response.dataList[x]['adjustmentQty'],
                  "stdCost":response.dataList[x]['stdCost'],
                  "cgst":response.dataList[x]['cgst'],
                  "sgst":response.dataList[x]['sgst'],
                  "igst":response.dataList[x]['igst'],
                  "claimedAmount":response.dataList[x]['claimedAmount'] == null?0:response.dataList[x]['claimedAmount'],
                  "cgstValue":response.dataList[x]['cgstValue'],
                  "sgstValue":response.dataList[x]['sgstValue'],
                  "igstValue":response.dataList[x]['igstValue'],
                  "taxableValue":response.dataList[x]['taxableValue'],
                  "mrp":response.dataList[x]['mrp']
                });
              }
              console.log(this.listObj);              
            } else {
              this.responseMessage = "No Data Found";
            }
          } else {
            this.responseMessage = "No Data Found";
          }
        },
        (err) => {
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.loaDer.nativeElement.style.display = "none";
        }
      );
    }
  }

  characterCheck(event, name) {
    var regExpr = /[^a-zA-Z0-9-. ]/g;
    var userText = event.target.value;
    if (userText != "") {
      // if (name == "articleNumber") {
      //   this.stockAdjustmentForm.controls["articleNumber"].setValue(
      //     userText.replace(regExpr, "")
      //   );
      //   if (userText.length > 7) {
      //     var res = userText.substr(0, 7);
      //     this.stockAdjustmentForm.controls["articleNumber"].setValue(res);
      //   }
      // }
      if (name == "grnNumber") {
        this.stockAdjustmentForm.controls["grnNumber"].setValue(
          userText.replace(regExpr, "")
        );
      }
    }
  }

  assortmentDataPopup(debitCreditNoteId, artNumber) {
    this.assortArray = [];
    let firstletter = artNumber.charAt(0);
    let index = this.shortageAdjustmentList.findIndex(
      (x) => x.debitCreditNoteId == debitCreditNoteId
    );
    if (index != -1) {
      this.assortData = this.shortageAdjustmentList[index];
      console.log(this.assortData);
      
      this.artNumber = artNumber;
      let details = [{ "name": "letterOfArticle", "value": firstletter }];
      this.PendingShortageAdjustmentService
        .getSizeDetails(details)
        .subscribe(
          (response: any) => {
            if (response.status == "Success") {
              this.groupCode = response.dataList[0].groupCode;
              this.assortArray = [];
              response.dataList.forEach(data => {
                console.log(this.assortArray);                
                var assortmentArray = this.assortData.debitCreditTxnAssortmentEntities;
                var found = assortmentArray.findIndex(element => element.artSize == data.artSize);
                var indx=assortmentArray.reduce(function(a, e, i) {
                  if (e.artSize === data.artSize)
                      a.push(i);
                  return a;
              }, []); 
                console.log(indx);
                let sizeWQty=0;
                indx.forEach(element => {
                   sizeWQty=sizeWQty+assortmentArray[element].pairQty;
                });
                if (found != -1) {
                  var assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWiseQty: sizeWQty };
                  
                  this.assortArray.push(assortmentData);
                }
              });
            }
          },
          (err) => {
            this.assortArray = [];
          }
        );
    }
  }

  showHideDiv(index) {
    let arr_index = this.selectedRow.indexOf(index);
    if (arr_index !== -1) {
      this.selectedRow.splice(arr_index, 1);
      document.getElementById('showHideDiv' + index).classList.remove("active");
    } else {
      this.selectedRow.push(index);
      document.getElementById('showHideDiv' + index).classList.add("active");
    }
  }


  approveOrReject(debitCreditTxnNumber,grnNumber,inventoryType) {
    // this.debitCreditNoteId = debitCreditNoteId;
    // this.txnId = txnId;
    this.grnNumber = grnNumber;
    this.debitCreditTxnNumber = debitCreditTxnNumber;
    this.inventoryType = inventoryType;
    this.remarks = '';
    console.log(this.inventoryType);
  }

  approveOrRejectStatusChange(status) {
    this.approveBtn=true;
    if (this.remarks == '') {
      this.approveBtn=false;
      this.responseMessage1 = "Please enter remarks.";
      setTimeout(() => {
        this.responseMessage1 = "";
      }, 3000);
    } else {
      this.loaDer.nativeElement.style.display = "block";
      var details = [
        {
          //"grnId": this.txnId,
          // "debitCreditTxnId": this.debitCreditNoteId,
          "grnNumber" : this.grnNumber,
          "debitCreditTxnNumber" : this.debitCreditTxnNumber,
          "txnType": "DEBIT",
          "approvalStatus": status,
          "approvedRejectedBy": this.userCode,
          "approvalRemarks": this.remarks
        }
      ];
      console.log(details);
      console.log(this.inventoryType);
      this.PendingShortageAdjustmentService.pendingShortageApprovalReject(details,this.inventoryType).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          if (response.status == "success") {
            this.approveBtn=false;
            setTimeout(() => {
              this.closeBtn.nativeElement.click();
              this.closeBtn1.nativeElement.click();
              this.searchStockAdjustment();
            }, 2000);
          } else {
            this.approveBtn=false;
            this.closeBtn.nativeElement.click();
            this.closeBtn1.nativeElement.click();
            this.responseMessage = "Something Error Happened.";
          }
        },
        (err) => {
          this.approveBtn=false;
          this.closeBtn.nativeElement.click();
          this.closeBtn1.nativeElement.click();
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.approveBtn=false;
          this.closeBtn.nativeElement.click();
          this.closeBtn1.nativeElement.click();
          this.loaDer.nativeElement.style.display = "none";
        }
      );
    }
  }

  print(debitCreditTxnNumber,shortAdjustmentDebitedTo) {
    this.loaDer.nativeElement.style.display = "block";
    var details = 
    {
      "reportInputRequestList": [      
        {      
          "name": "userId",      
          "value": this.userCode      
        },      
        {
          "name": "reportName",    
          "value": "Print Debit Note Report"    
        },    
        {    
          "name": "reportKey",    
          "value": "PDN_R"    
        },      
        {      
          "name": "userEmail",      
          "value": this.userEmail      
        },      
        {      
          "name": "debitCreditTxnNumber",      
          "value": debitCreditTxnNumber      
        },
        {
          "name":"shortAdjustmentDebitedTo",
          "value":shortAdjustmentDebitedTo
        }      
      ],      
      "reportFormatType": "excel"      
    };
    console.log(details);
    this.PendingShortageAdjustmentService.printDebitNote(details).subscribe(
      (response: any) => {
        this.loaDer.nativeElement.style.display = "none";
        if (response.status == "Success") {
          setTimeout(() => {              
            this.responseMessage = "Print done successfully.";
            this.getDownloadFile(response.message);
            // let x = document.createElement('a');
            // x.href = response.message;
            // x.click();
            // window.open(response.message, "_blank");
          }, 2000);
        } else {
          setTimeout(() => {              
            this.responseMessage = "Something Error Happened.";
          }, 2000);          
        }
      },
      (err) => {
        setTimeout(() => {              
          this.responseMessage = err;
        }, 2000);        
        this.loaDer.nativeElement.style.display = "none";
      },
      () => {
        this.loaDer.nativeElement.style.display = "none";
      }
    );
  }
  

  public async getDownloadFile(downloadUrl): Promise<void> {
    const file = await this.httpClient.get<Blob>(downloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    // anchor.download = this.selVal+'_'+ curent_date + '.xlsx';
    anchor.download = downloadUrl;
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none';
}

}
