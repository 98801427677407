import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ImportArticleUploadService {

  uploadfileURL: string = RdsBilApiUrl.UPLOAD_ASN_EXCEL_FILE;
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  generateAsn: string = RdsBilApiUrl.GENERATE;
  uploadGrnDamage: string = RdsBilApiUrl.GENERATE_GRN_DAMAGE;
  generateGRN: string = RdsBilApiUrl.GENERATE_GRN;

  constructor(private _http:HttpClient,) { }

   upload(formData) {
    return this._http.post(`${this.uploadfileURL}`, formData);

  }

 getDownloadUrl() {

  return this._http.get(this.urlForDownloadUrl);
  
}


public getGenerate(uploadKeyValues:any){
  console.log(uploadKeyValues);
  return this._http.post(this.generateAsn,uploadKeyValues, httpOptions);
}

public getGenerateDamage(uploadKeyValues:any){
  console.log(uploadKeyValues);
  if(uploadKeyValues[0]['value']==='IMPORT_GRN'){
    return this._http.post(this.generateGRN,uploadKeyValues, httpOptions);
  }else{
    return this._http.post(this.uploadGrnDamage,uploadKeyValues, httpOptions);
  }
}

}
