import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilsService } from '@/common/utils-service';
import { UploadDownloadCompService } from './upload-download-comp.service';
import { BatchesData } from '@/batches/batchesdata';
@Component({
  selector: 'app-upload-download-comp',
  templateUrl: './upload-download-comp.component.html',
  styleUrls: ['./upload-download-comp.component.css']
})
export class UploadDownloadCompComponent implements OnInit,AfterViewInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @Input('uploadFormDetails') uploadFormDetails:any;
  uploadForm:FormGroup;
  uploadFileName: string = "No Chosen File";
  isBatchSelected:boolean = false;
  uploadDone:boolean = false;
  downloadUrl:any;
  responseMessage: string;
  statusMessage: string;
  configList:any;

  constructor(private location:Location,private fb: FormBuilder,
    private _utilsService: UtilsService,private uploadDownloadCompService:UploadDownloadCompService) { 
      this.uploadForm = this.fb.group({
        name: [""],
        file: [""],
        uploadKey: [""],
        uploadedBy: [""],
        keys: [null],
      });
    }

  ngOnInit(): void {
    console.log(this.uploadFormDetails);
    let sessUsername = sessionStorage.getItem("currentUser");
    this.uploadForm.get("uploadedBy").setValue(JSON.parse(sessUsername).groupAssignmentModels[0].userCode);
  }

  ngAfterViewInit(): void {
      this.loaDer.nativeElement.style.display = 'none';
      this.downloadTemplateUrl();
  }

  onChange(value){
    console.log(value);
    this.statusMessage = '';
    this.responseMessage = '';
    this.downloadUrl = '';
    this.uploadDone = false;
    console.log(this.uploadForm.get('uploadKey').value);
    if(value != "null"){
      this.isBatchSelected = true;
      this.configList.filter(c => c.paramName == this.uploadForm.get('uploadKey').value)
                         .forEach(fileName => {this.downloadUrl = fileName.paramValue});
    } else{
      this.isBatchSelected = false;
    }
    console.log(this.downloadUrl);
  }

  onSelectFile(event){
    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  uploadFile() {
    this.statusMessage = '';
    this.responseMessage = '';
    this.uploadDone = false;
    if (this.uploadFileName.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage = 'error';
      return false;
    } else {
      let index =  this.uploadForm.get('file').value.name.lastIndexOf('.');
      let fileName = this.uploadForm.get('file').value.name.substr(0, index);
      let ext = this.uploadForm.get('file').value.name.substr(index+1, this.uploadForm.get('file').value.name.length);
      const newFileFormat = fileName.replace(/\s+/g, '') + '_' + this._utilsService.getCurrentFormatDate() + '.' + ext;
      const formData = new FormData();

      formData.append('name', this.uploadForm.get('name').value);
      formData.append('file', this.uploadForm.get('file').value, newFileFormat.toString());
      formData.append('uploadedBy',this.uploadForm.get('uploadedBy').value);
      formData.append('uploadKey', this.uploadForm.get('uploadKey').value);
      this.loaDer.nativeElement.style.display = 'block';

      this.uploadDownloadCompService.upload(formData).subscribe(
        (response: any) => {
          this.responseMessage = '';
          this.statusMessage = '';
          if (response.status.toLowerCase() === 'success') {
            this.responseMessage = "File is uploaded successfully, please click on Generate "+this.uploadFormDetails.batchName+" Button.";
            this.statusMessage = 'success';
            this.uploadDone = true;
          } else {
            this.responseMessage = response.message;
            this.statusMessage = 'error';
            this.uploadDone = false;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.responseMessage = err;
          this.statusMessage = 'error';
          this.uploadDone = false;
        }
      );
    }
  }

  onGenerate() {
    this.statusMessage = '';
    this.responseMessage = '';
    const uploadDetails: any = {
      uploadKeyValues: this.uploadForm.get('uploadKey').value,
      uploadedBy: this.uploadForm.get('uploadedBy').value
    };

    this.loaDer.nativeElement.style.display = 'block';
    this.uploadDownloadCompService.getGenerate(uploadDetails).subscribe(
      (response: any) => {
        if (response.status.toLowerCase() == 'success') {
          this.responseMessage = response.message;
          this.statusMessage = 'success';
        } else {
          this.responseMessage = response.message;
          this.statusMessage = 'error';
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  downloadTemplateUrl(){
    console.log("Download Template started::");
    this.loaDer.nativeElement.style.display = 'block';
    this.uploadDownloadCompService.getDownloadUrl().subscribe(
      (res: BatchesData) => {
        console.log(res);
        this.configList = res['configParamModels'];
      },(err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.loaDer.nativeElement.style.display = 'none';
      }, ()=> {
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  downloadTemplateFile(){
    console.log("Download file URL => ",this.downloadUrl);
    this.uploadDownloadCompService.getDownloadFile(this.downloadUrl,this.uploadFormDetails.bucketFileName);
  }

  onClickBack(){
    this.location.back();
  }

  resetDetails(){
    this.uploadFileName = "No Chosen File";
    this.uploadForm.reset({
      file: null,
      uploadKey: null,
      uploadedBy: this.uploadForm.get("uploadedBy").value,
      keys: null,
    });
    console.log(this.uploadForm.value);
  }

}