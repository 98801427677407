import { Injectable } from '@angular/core';
import { BilApiUrl } from '@/common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageCcListEditService {

  party_url: string = BilApiUrl.SUPPLIER_PARTY_NUMBER;
  concept_url: string = BilApiUrl.CONCEPT_DETAILS; 
  user_detail_url: string = BilApiUrl.USER_DETAIL_URl;
  saving_create_cc_url: string = BilApiUrl.CREATE_CC_LIST;
  config_param_url: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;

  constructor(private _http: HttpClient) { }

  getPartyNumber() {
    return this._http.get(this.party_url);
  }
  getUserConcept() {
    return this._http.post(this.concept_url, null);
  }
  getUserDetails(details) {
    console.log('passing data' + JSON.stringify(details));
    return this._http.post(this.user_detail_url, details);
  }

  saveCcDetails(ccListPassingList) {
    console.log('passing data' + JSON.stringify(ccListPassingList));
    return this._http.post(this.saving_create_cc_url, ccListPassingList);
  }
}
