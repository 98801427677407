import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BilApiUrl } from '@/common/bil-api-url';


@Injectable({
  providedIn: 'root'
})
export class DistributionDetailCompletedService {

  private _location_url = BilApiUrl.SEARCH_LOCATION_URL;
  private _assortment_url: string = BilApiUrl.ASSORTMENT_CODE_URL;
  private _add_requisition_distribution_url: string = BilApiUrl.ADD_REQUISITION_DISTRIBUTION;
  private requisition_size_info_url: string = BilApiUrl.REQUISITION_SIZE_INFO;
  private _assortment_type_url = 'assets/static-data/assortmentType.json';
 // private distribution_url:string = ' http://01HW588664:8058/requisition/searchRequisitionDistribution';
  private distribution_url:string = BilApiUrl.SEARCH_REQUISITION_DISTRIBUTION;

  constructor(private _http: HttpClient) { }
 /* getAssortmentTypes() {
    console.log(' Log File :  DistributionDetailService : Function : getAssortmentTypes')
    return this._http.get(this._assortment_type_url);
  }*/

  getLocations(location_param){
    return this._http.post(this._location_url,location_param);
  }
 
  getAssortmensts(assortment_code_param){
    return this._http.post(this._assortment_url,assortment_code_param);
  }

  saveDistribution(requisition_distribution_param){
    console.log( ' saveDistribution ');
    return this._http.post(this._add_requisition_distribution_url,requisition_distribution_param);
  }

  getDistributionDetails(reqLineId:any){
    console.log(reqLineId+'hello');
   return this._http.post(this.distribution_url,reqLineId);
  }
  getRequisitionSizeInfo(requisition_size_param){
    return this._http.post(this.requisition_size_info_url,requisition_size_param);
  }
}
