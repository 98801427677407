import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { Observable, of, throwError, from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BarcodeScanService {

  apiUrl = BilApiUrl.COMMON_UPLOAD_API_URL;
  // barcode_search_url: string = BilApiUrl.RDC_CREATE_GIN;
  gin_search_url: string = RdsBilApiUrl.GIN_SEARCH;
  barcode_search_url_1: string = RdsBilApiUrl.BARCODE_SCAN;
  validate_barcode_data: string = RdsBilApiUrl.VALIDATE_BARCODE_DATA;
  create_GRN: string = RdsBilApiUrl.CREATE_GRN_WITH_REMARKS;
  saveDataBarcode: string = RdsBilApiUrl.SAVE_DATA;
  sessUsername = sessionStorage.getItem('currentUser');

  constructor(private http: HttpClient) { }

  saveData(details) {
    return this.http.post(this.saveDataBarcode, details);
  }

  fakeValidateUserData(): any {
    return of("");
  }

  getBarcodeBySearchCriteria(data) {
    // let updatedUrl = this.barcode_search_url +'/'+ invNo;
    // return this.http.get(updatedUrl);
    return this.http.post(this.gin_search_url, data);
  }

  getBarcodeBySearchCriteria11(data) {
    return this.http.post(this.barcode_search_url_1, data);
  }

  validateBarcodeDetails(data) {
    let details = {
      "barcodeNumber": data,
      "shopCode": "103521",
      "loggedInUser": "admin"
    }
    return this.http.post(this.validate_barcode_data, details);
  }

  createGRNDetails(GRNdata) {
    return this.http.post(this.create_GRN, GRNdata);
  }

}
