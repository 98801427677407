export class invalidateDetails {

    finScratchId : number;
    sourceDistCode : string;
    sourceShopCode : string;
    sourceShopName : string;
    destDistCode : string;
    destShopName : string;
    destShopCode : string;
    transactionType : string;
    invoiceYearWeek : number;
    invoiceNo : string;
    invoiceDate : string;
    cnGrNo : string;
    cnGrDate : string;
    debitPairs : number;
    creditPairs : number;
    debitPcs : number;
    creditPcs: number;
    srNo : number;
    debitAmount : number;
    creditAmount :number;
    stockType : string;
    creationDate : string;
    closeDate : string;
    status: string;
    validFlag : string;
    createdBy : string;
    createdDate : string;
    lastUpdatedBy : string;
    lastUpdateDate : string;
    invalidatedBy : string;
    invalidatedDate : string;
    remarks : string;
    pairs : string;
    amount : string;
    pcs : string;
 
    constructor(){}
}