import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageSizeMasterService } from '@/manage-size-master/manage-size-master.service';
import { UtilsService } from '@/common/utils-service';
import { NO_RESULT_FOUND } from '@/common/field-name-list';
import { NEGATIVE } from '@/common/error-code-messages';
@Component({
  selector: 'app-manage-size-master',
  templateUrl: './manage-size-master.component.html',
  styleUrls: ['./manage-size-master.component.css']
})
export class ManageSizeMasterComponent implements OnInit {

  sizeMasterForm: FormGroup;
  manageSizeMasterResponse:any[];
  letterOfArticle = [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' }
  ];
  groupCode = [ 
    { id: 1, name: 'A' },
    { id: 2, name: 'B' },
    { id: 3, name: 'C' },
    { id: 4, name: 'D' },
    { id: 5, name: 'E' }
];
  no_result_found :any = NO_RESULT_FOUND;
  responseMessage: string;
  validationErrMsg:any;
  noSearchMessage:string;
  non_negative_char_regex_pattern = '[0-9]*$';
  constructor(private _manageSizeMasterService:ManageSizeMasterService ,private fb: FormBuilder, private _utilsService: UtilsService) { 
    this.sizeMasterForm = this.createManageSizetMasterForm();
  }
  createManageSizetMasterForm(){
    let serachSizeMasterFields = this.fb.group( 
                                                {
                                                  sizeBucket : ['',Validators.pattern(this._utilsService.non_negative_char_regex_pattern)],
                                                  letterOfArticle : [null],
                                                  groupCode : [null],
                                                  artSize : ['',Validators.pattern(this._utilsService.non_negative_char_regex_pattern)]
                                                 }
                                              ) ;
    return serachSizeMasterFields;
    // let serachSizeMasterFields = {};
    // serachSizeMasterFields['sizeBucket'] = ''; // Size Bucket // for calulation 
    // serachSizeMasterFields['letterOfArticle'] = [null]; 
    // serachSizeMasterFields['groupCode'] = [null];
    // serachSizeMasterFields['artSize'] = ''; //  Size Label// for display
    // return this.fb.group(serachSizeMasterFields);
  }

  ngOnInit() {
  }

  submitSizeMasterForm(formData:any){
    let manage_size_master_param = [];
    this.noSearchMessage = '';
   // manage_size_master_param = this._utilsService.createRequestParametersModelArray(formData);
    manage_size_master_param = this._utilsService.filterNullAndEmptyJsonObj(formData);
    console.log(manage_size_master_param);
    if(manage_size_master_param != [] && manage_size_master_param.length != 0){
    this._manageSizeMasterService.getSizeMaster(manage_size_master_param)
    .subscribe(
      (response:any) => {
        this.manageSizeMasterResponse = [];
        if(response!=null && response.dataList.length>0 && response.code !== 'E003'){
          this.manageSizeMasterResponse = response.dataList;
        }
        else{
          this.responseMessage = this.no_result_found;
        }
      }
    );
    }
    else{
      this.noSearchMessage = 'Please give a search criteria';
    }
  }

  checkNumberIfNegativeSizeBucket(){
      let  number = this.sizeMasterForm.get('sizeBucket').value;
      console.log(' sizeBucket '+number);
      if(this._utilsService.validateNumber(number) == -1){
        this.validationErrMsg = NEGATIVE;
      } else{
        this.validationErrMsg = '';
      }
  }

  checkNumberIfNegativeArtSize(){
    let  number = this.sizeMasterForm.get('artSize').value;
    console.log(' sizeBucket '+number);
    if(this._utilsService.validateNumber(number) == -1){
      this.validationErrMsg = NEGATIVE;
    } else{
      this.validationErrMsg = '';
    }
}

resetSizeMasterForm(){
  this.sizeMasterForm.reset();
  this.manageSizeMasterResponse=[];
  this.responseMessage='';
  this.noSearchMessage = '';
}
}
