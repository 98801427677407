import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ViewUploadStatusService } from './view-upload-status.service';
import { NO_RESULT_FOUND } from '../common/field-name-list';
import { UtilsService } from '@/common/utils-service';

@Component({
  selector: 'app-view-upload-status',
  templateUrl: './view-upload-status.component.html',
  styleUrls: ['./view-upload-status.component.css']
})
export class ViewUploadStatusComponent implements OnInit {
  viewUploadStatusForm: FormGroup;
  uploadKeyList: any[];
  uploadStatusDetails: any;
  responseMessage: string;
  no_result_found: any = NO_RESULT_FOUND;
  constructor(private fb: FormBuilder, private _viewUploadStatusService: ViewUploadStatusService,private _utilsService: UtilsService) {
    this.fetchUploadKeys();
    this.viewUploadStatusForm = this.buildForm();
  }

  ngOnInit() {

  }

  buildForm() {
    return this.fb.group(
      {
        uploadedBy: '',
        uploadKey: null
      }
    );
  }

  fetchUploadKeys() {
    this.uploadKeyList = [];
    this._viewUploadStatusService.getUploadKeys().subscribe(
      uploadKey => {
        if (uploadKey != null) {
          this.uploadKeyList.push(uploadKey);
        }

      },
      () => {
        console.log("Completed");
      }
    );
  }

  fetchUploadStatusOnSubmit(formData: any) {
    let view_upload_status_param = { uploadedBy: '', fileKey: '' };
    view_upload_status_param.uploadedBy = this.viewUploadStatusForm.controls.uploadedBy.value;
    view_upload_status_param.fileKey = this.viewUploadStatusForm.controls.uploadKey.value;
   
    for (var key in view_upload_status_param) {
      if (view_upload_status_param.hasOwnProperty(key)) {
        var val = view_upload_status_param[key];
        if(val == null || val == 'null'  || val == '' || val == undefined){
          delete view_upload_status_param[key];
        }
        console.log(val);
      }
    }
    console.log(JSON.stringify(view_upload_status_param));
    this._viewUploadStatusService.getUserUploadData(view_upload_status_param).subscribe(
      (uploadStatusResponseArray: any[]) => {
        this.uploadStatusDetails = [];

        if (uploadStatusResponseArray != null) {
          uploadStatusResponseArray.forEach(
            uploadStatusArrayElement => {
              let e: { uploadFileKey: any, uploadFileName: any, jobRunTime: any, invokedBy: any, jobStatus: any, failureReason: any } = uploadStatusArrayElement;
              this.uploadStatusDetails.push(e);
            }
          );

          if (uploadStatusResponseArray.length == 0) {
            this.uploadStatusDetails = [];
            this.responseMessage = this.no_result_found;
          }
        }
      },
      (error) => { console.error('Some error occured..') }
    );
  }

  resetForm() {
    this.viewUploadStatusForm.reset(
      { 'uploadedBy': '', 'uploadKey': null }
    );
    this.uploadStatusDetails = [];
    this.responseMessage = '';
  }


}
