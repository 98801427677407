import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { Observable, of, throwError, from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BarcodeScanDispatchService {

  apiUrl = BilApiUrl.COMMON_UPLOAD_API_URL;
  barcode_search_url: string = RdsBilApiUrl.RDC_CREATE_GIN;
  barcode_search_url_1: string = RdsBilApiUrl.BARCODE_SCAN;

  constructor(private http: HttpClient) { }

  upload(details: any) {

    return this.http.post(`${this.apiUrl}`, details);

  }

  fakeValidateUserData(): any {
    return of("");
  }


  getBarcodeBySearchCriteria11(invNum) {
    let details = {
      "invoiceNumber": invNum
    }

    return this.http.post(this.barcode_search_url_1, details);
  }
}