import { DatePipe } from '@angular/common';
import { ManageSupplierAccessCreateService } from './manage-supplier-access-create.service';
import { SupplierAccessSearchDetails } from '../manage-supplier-access/manage-supplier-access-details';
import { SupplierAccesstModel } from '../manage-supplier-access/manage-supplier-access-model';
import { SupplierAccessDetails } from './manage-supplier-detail';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../common/field-name-list';
import { Party } from '@/manage-supplier-access/manage-party-details-model';

@Component({
  selector: 'app-manage-supplier-access-create',
  templateUrl: './manage-supplier-access-create.component.html',
  styleUrls: ['./manage-supplier-access-create.component.css']
})
export class ManageSupplierAccessCreateComponent implements OnInit {

  parties: Party[];
  createSupplierAccessModel = new SupplierAccessSearchDetails();
  supplierAccessList: SupplierAccessSearchDetails[] = [];
  createSupplierAccess = new SupplierAccessSearchDetails();
  supplierAccessSearchParameter = new SupplierAccesstModel();
  supplierDetailList: SupplierAccessDetails[] = [];
  selectedUser: number;
  selectedRow: number = 0;
  responseMessage: string;
  mt1: boolean[] = [];
  mt2: boolean[] = [];
  disableIndex: number = 0;
  supplierAccessPassingList: SupplierAccessSearchDetails[] = [];
  details:any;
  username:string;
  successMsg: string;

  constructor(private _router: Router, private _createSupplierAccessService: ManageSupplierAccessCreateService, private datePipe: DatePipe) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.createSupplierAccessModel = new SupplierAccessSearchDetails();
    this.supplierAccessList.push(this.createSupplierAccessModel);
    this.mt1[0] = false;
    this.mt2[0] = false;
    this._createSupplierAccessService.getPartyNumber().subscribe(
      (data: any) => {
        console.log(data);
        this.parties = data.partyNumberList;
      }
    )
  }

  addUserConcept() {
    if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
      this.createSupplierAccess = new SupplierAccessSearchDetails();
      this.supplierAccessList.push(this.createSupplierAccess);
      this.selectedRow = null;
      this.selectedUser = null;
      this.supplierDetailList = [];
      this.supplierAccessSearchParameter = new SupplierAccesstModel();
      this.disableIndex++;
      this.responseMessage = '';
      this.successMsg = '';
    }
  }

  onClickSearchUser() {
    if(this.supplierAccessSearchParameter.userCode == undefined){
     this.details =  {
       "userCode": ""
      }
    }
    else{
      this.details =  {
        "userCode": this.supplierAccessSearchParameter.userCode
       }
    }
    console.log(JSON.stringify(this.details));
    this._createSupplierAccessService.getSupplierDetails(this.details).subscribe(
      (response: SupplierAccessDetails[]) => {
        console.log(response);
        this.supplierDetailList = response;
      }
    )
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  setIndexForUser(index) {
    this.responseMessage = '';
    this.successMsg = '';
    this.selectedUser = index;
  }

  onClickSubmit() {
    this.supplierAccessList[this.selectedRow].userCode = this.supplierDetailList[this.selectedUser].userCode;
    // this.supplierAccessList[this.selectedRow].userId = this.supplierDetailList[this.selectedUser].userId;
    this.supplierAccessList[this.selectedRow].userName = this.supplierDetailList[this.selectedUser].userName;

    this.mt1[this.disableIndex] = true;

  }

  settingIndexforConceptLine(index) {
    this.responseMessage = '';
    this.successMsg = '';
    this.selectedRow = index;
  }

  setData(partyCode, i) {
    this.responseMessage = '';
    this.successMsg = '';
    this.supplierAccessList[i].partyNumber = partyCode;
    this.mt2[this.disableIndex] = true;
   }

  onClickSaveButton() {
    this.responseMessage = '';
    this.successMsg = '';
    this.supplierAccessPassingList = [];
    let count:number=0;
    if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
      this.supplierAccessList.forEach(
        conc => {
          let supplierAccessPassing = new SupplierAccessSearchDetails();
          supplierAccessPassing.partyNumber = conc.partyNumber;
          supplierAccessPassing.userCode = conc.userCode;
          supplierAccessPassing.activeFlag = "Y";
          let currDate = new Date();
          supplierAccessPassing.createdDate = this.datePipe.transform(currDate,"yyyy-MM-dd");
          supplierAccessPassing.createdBy = this.username;
          supplierAccessPassing.lastUpdatedBy = this.username;
          this.supplierAccessPassingList.push(supplierAccessPassing);
        }
      );

      let filterArray:any = this.supplierAccessPassingList.reduce((accumalator, current) => {
        if (
          !accumalator.some(  
            (item) => item.userCode === current.userCode && item.partyNumber === current.partyNumber  
          ) 
        ) {
          accumalator.push(current); 
        }else{
          count++;
        } 
        return accumalator; 
      }, []);
      console.log(filterArray);  
      
      if(count == 0){
        console.log(JSON.stringify(this.supplierAccessPassingList));
        this._createSupplierAccessService.saveSupplierDetails(this.supplierAccessPassingList).subscribe(
          (response: any) => {
            console.log(response);
            if (response.status == 'Success') {
              this.successMsg='Success';
              this.responseMessage = 'Supplier Access Created Successfully';
            }
            else{
              this.responseMessage = response.message;
            }
          }
        )
      }else{
        console.log(JSON.stringify(this.supplierAccessPassingList));
        this.responseMessage = 'Duplicate Data Found';
      }
      
    }     
    else {
      this.responseMessage = 'Please fill Data First';
    }
  }

  cancelLineDetails(index){
    if(this.disableIndex != 0){
    this.supplierAccessList.splice(index,1);
    this.disableIndex--;
    this.responseMessage = '';
    this.successMsg = '';
    }
  }

}
