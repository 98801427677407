import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { BilApiUrl } from '@/common/bil-api-url';


@Injectable({
  providedIn: 'root'
})
export class PoListService {

  purchase_order_search_url: string = BilApiUrl.PO_HEADERS_URL;
  constructor(private _http: HttpClient) { }


  /*
    This class call the PO api service which return the matching 
    purchase orders for requisition.
    */
   getPoList(requestParameterModel:RequestParameterModel[]) {

    // this.requestParameterModel = [];
    // this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    // requestParameterModel.name = "reqId";
    // requestParameterModel.value = reqId;
    // purchase_order_search_parameters.push(requestParameterModel);
    return this._http.post(this.purchase_order_search_url, requestParameterModel);


  }
}
