import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { RequisitionDetailsService } from './requisition-details.service';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { RequisitionHeader } from '../requisition-header';
import { PurchaseOrderHeader } from '../../search-po/po-header';
import { RequisitionLines } from '../requisition-lines';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { SearchRequisitionLinesResponseModel } from './search-requistion-lines-response-model';
import { REQUISITION_LINES_DISTRIBUTION_PENDING, REQUISITION_LINES_DISTRIBUTION_COMPLETED, PO_CREATED, REQUISITION_LINES_STATUS_PENDING, REQUISITION_LINES_STATUS_OPEN, REQUISITION_LINES_STATUS_COMPLETED } from '@/common/field-name-list';
import { DISTRIBUTION_PAGE_DETAILS_TITLE, REQUISITION_DETAILS } from '../../common/field-name-list';
@Component({
  selector: 'app-requisition-details',
  templateUrl: './requisition-details.component.html',
  styleUrls: [
    '../../../assets/css/bootstrap.css'
    , '../../../assets/css/bil-style.css','./requisition-details.component.css'
  ],
  providers: [RequisitionDetailsService]
})
export class RequisitionDetailsComponent implements OnInit {
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;
 
 
  constructor(private _requisitionDetailsService: RequisitionDetailsService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router) { }
  reqNumber: string;
  private _requsetParameterModelArray: RequestParameterModel[];
  private _createPOPayload: any = { reqId: '', reqLinesId: [] , poCreatedBy: '' };
  searchedRequisitionDetails = new RequisitionHeader();
  searchedPoHeaders: PurchaseOrderHeader[] = [];
  openCount: string;
  completedCount: string;
  pendingCount: string;
  requisitionLinesDetails: RequisitionLines[];
  requisitionId: string;
  firstTabHeaderLabel = '';
  firstTabSelected:boolean;
  pendingDistributionDetails: RequisitionLines[];
  secondTabHeaderLabel = '';
  secondTabSelected:boolean;
  completedDistributionDetails: RequisitionLines[];
  createdPODetails: RequisitionLines[];
  thirdTabHeaderLabel = '';
  thirdTabSelected:boolean;
  createPOSuccessMessage: string;
  createPOErrorMessage:string;
  private _distributionPageTitle: string =DISTRIBUTION_PAGE_DETAILS_TITLE;
  distributionPageData: RequisitionLines ;
  completereqQuant: any;
  completeorderQuant: any;
  createreqQuant: any;
  createorderQuant: any;
  pair:string;
  value:string;
  cost:string;
  roleCheck:number = 0;
  userRole:string;
  userName:string;
  index: number = 0;
  isPOError: boolean = false;
  isSubmit: boolean = false;
  perPage: number = 3;
  createPofromReq:boolean = false;
  perfInvNumber:string;
  paymentTerms:string;

    ngOnInit() {

      let sessUsername = sessionStorage.getItem('currentUser');
      this.userRole=JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
      this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

      if (this._navigationLinkParameterService.details != null && this._navigationLinkParameterService.details.length > 0) {
  
        this._requsetParameterModelArray = [];
        this._requsetParameterModelArray = this._navigationLinkParameterService.details;
        this._requsetParameterModelArray.forEach(
          requsetParameter => {
            if ('reqId' === requsetParameter.name) {
              this.requisitionId = requsetParameter.value;
            }
            if ('reqNumber' === requsetParameter.name) {
              this.searchedRequisitionDetails.reqNumber = requsetParameter.value;
            }
  
            if ('reqStatus' === requsetParameter.name) {
              this.searchedRequisitionDetails.reqStatus = requsetParameter.value;
            }
  
            if ('conceptCode' === requsetParameter.name) {
              this.searchedRequisitionDetails.conceptCode = requsetParameter.value;
            }
  
            if ('reqCreateDate' === requsetParameter.name) {
              this.searchedRequisitionDetails.reqCreateDate = requsetParameter.value;
            }
  
            if ('reqCreatedby' === requsetParameter.name) {
              this.searchedRequisitionDetails.reqCreatedby = requsetParameter.value;
            }
  
            if ('supplierName' === requsetParameter.name) {
              this.searchedRequisitionDetails.supplierName = requsetParameter.value;
            }
  
            if ('prCreationDate' === requsetParameter.name) {
              this.searchedRequisitionDetails.prCreationDate = requsetParameter.value;
            }
  
            if ('paCreationDate' === requsetParameter.name) {
              this.searchedRequisitionDetails.paCreationDate = requsetParameter.value;
            }
  
            if ('productionYear' === requsetParameter.name) {
              this.searchedRequisitionDetails.productionYear = requsetParameter.value;
            }
  
            if ('productionMonth' == requsetParameter.name) {
              this.searchedRequisitionDetails.productionMonth = requsetParameter.value;
            }
  
            if ('totalPair' === requsetParameter.name) {
              this.searchedRequisitionDetails.totalPair = requsetParameter.value;
            }
  
            if ('totalValue' === requsetParameter.name) {
              this.searchedRequisitionDetails.totalValue = requsetParameter.value;
            }
  
            if ('totalCost' === requsetParameter.name) {
              this.searchedRequisitionDetails.totalCost = requsetParameter.value;
            }
  
  
            if ('division' === requsetParameter.name) {
              this.searchedRequisitionDetails.division = requsetParameter.value;
            }
          
            if ('conceptCode' === requsetParameter.name) {
              this.searchedRequisitionDetails.conceptCode = requsetParameter.value;
            }


            if ('perfInvNumber' === requsetParameter.name) {
              this.searchedRequisitionDetails.perfInvNumber = requsetParameter.value;
            }

            if ('paymentTerms' === requsetParameter.name) {
              this.searchedRequisitionDetails.paymentTerms = requsetParameter.value;
            }

            if ('poType' === requsetParameter.name) {
              this.searchedRequisitionDetails.poType = requsetParameter.value;
            }

            if ('subPartyCode' === requsetParameter.name) {
              this.searchedRequisitionDetails.subPartyCode = requsetParameter.value;
            }

          }
  
        );
  
        if (this.requisitionId != null) {
          this.fetchRequsitionLinesByRequisitionIdFromService(this.requisitionId);
        }

      }
      let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
      let userRoleIndexIT = this.userRole.indexOf('IT');
      if(userRoleIndexOrdering >= 0 || userRoleIndexIT >= 0){
        this.roleCheck = 1;
      }
      else{
        this.roleCheck = 0;
      }
      this.firstTabSelected = true;
      this.thirdTabSelected = false;
    }
  
    /* call service to populate requisition line table */
    fetchRequsitionLinesByRequisitionIdFromService(requisitionId: string) {
      let requestParameter: any = { "Req_ID": '' };;
      requestParameter = { "Req_ID": requisitionId }
      console.log('Before service call');
      this._requisitionDetailsService.findRequisitionLinesByRequisitionId(requestParameter).subscribe(
        (responseFromRequisitionLinesService: SearchRequisitionLinesResponseModel) => {
          console.log('after service call');
          console.log('response from service'+JSON.stringify(responseFromRequisitionLinesService));
          this.openCount = responseFromRequisitionLinesService.openCount;
          this.pendingCount = responseFromRequisitionLinesService.pendingCount;
          this.completedCount = responseFromRequisitionLinesService.completedCount;
          this.pair = responseFromRequisitionLinesService.totalQty;
          this.value = responseFromRequisitionLinesService.totalMrp;
          this.cost = responseFromRequisitionLinesService.totalStdCost;
          if (responseFromRequisitionLinesService.queryRequsitionLineResponse != null &&
            responseFromRequisitionLinesService.queryRequsitionLineResponse.length > 0) {
            let pendingDistributionCount = 0;
            let completedDistributionCount = 0;
            let createdPODistributionCount = 0;
            this.requisitionLinesDetails = [];
            this.pendingDistributionDetails = [];
            this.completedDistributionDetails = [];
            this.createdPODetails = [];
            this.requisitionLinesDetails = responseFromRequisitionLinesService.queryRequsitionLineResponse;
            this.requisitionLinesDetails.forEach(
  
              reqisitionline => {
                console.log(' Log File : RequisitionDetailsComponent : fetchRequsitionLinesByRequisitionIdFromService : Requisition Line Status :  ' + reqisitionline.reqLineStatus)
                if (REQUISITION_LINES_STATUS_OPEN === reqisitionline.reqLineStatus) {
                  ++pendingDistributionCount;
                  this.pendingDistributionDetails.push(reqisitionline);
                }
  
                if (REQUISITION_LINES_STATUS_PENDING === reqisitionline.reqLineStatus) {
                  ++completedDistributionCount;
                  this.completedDistributionDetails.push(reqisitionline);
                }
                if (REQUISITION_LINES_STATUS_COMPLETED === reqisitionline.reqLineStatus) {
                  ++createdPODistributionCount;
                  this.createdPODetails.push(reqisitionline);
                }
  
              }
  
            );
  
            this.completereqQuant = 0;
            this.completeorderQuant = 0;
            for(let i = 0; i < this.completedDistributionDetails.length; i++)
            {
              this.completereqQuant += this.completedDistributionDetails[i].reqQty;
              this.completeorderQuant += this.completedDistributionDetails[i].orderedQty;
            }
  
  
            this.createreqQuant = 0;
            this.createorderQuant = 0;
            for(let i = 0; i < this.createdPODetails.length; i++)
            {
              this.createreqQuant += this.createdPODetails[i].reqQty;
              this.createorderQuant += this.createdPODetails[i].orderedQty;
            }
  
            let pendingCountStr  =  '';
            pendingCountStr  = "(" + pendingDistributionCount + ")";
            let completeCountStr  =  '';
            completeCountStr  = "(" + completedDistributionCount + ")";
            let createCountStr  =  '';
            createCountStr  = "(" + createdPODistributionCount + ")";
            this.firstTabHeaderLabel = REQUISITION_LINES_DISTRIBUTION_PENDING;
            this.firstTabHeaderLabel = this.firstTabHeaderLabel + pendingCountStr;
            this.secondTabHeaderLabel = REQUISITION_LINES_DISTRIBUTION_COMPLETED;
            this.secondTabHeaderLabel = this.secondTabHeaderLabel + completeCountStr;
            this.thirdTabHeaderLabel  = PO_CREATED;
            this.thirdTabHeaderLabel = this.thirdTabHeaderLabel + createCountStr;
            if(completedDistributionCount > 0){
              this.isSubmit =  true;
            } else{
              this.isSubmit =  false;
            }
          }
  
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }

    createPO() {
      this.createPofromReq = true;
      let requisitionLineIdArray: string[];
      requisitionLineIdArray = [];
      this.completedDistributionDetails.forEach(
        completedDistribution => {
          if (completedDistribution != null) {
            requisitionLineIdArray.push(completedDistribution.reqLineId);
          }
        }
      );
  
      this._createPOPayload = { reqId: this.requisitionId, reqLinesId: requisitionLineIdArray, poCreatedBy: JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode };
      console.log(' _createPOPayload reqId ' + this._createPOPayload.reqId + " reqLinesId  " + this._createPOPayload.reqLinesId);
      this._requisitionDetailsService.createPOFromRequisitionDetails(this._createPOPayload).subscribe(
        (createPOServiceResponse:string) => {
          this.createPOSuccessMessage = '';
          console.log(' Log File : RequisitionDetailsComponent : createPO : response  :  ' + createPOServiceResponse);

          this.createPOSuccessMessage = createPOServiceResponse;
          this.createPofromReq = false;
          if(this.createPOSuccessMessage!=null && 
            this.createPOSuccessMessage!='' && this.createPOSuccessMessage!=undefined
            && (this.createPOSuccessMessage.indexOf('Error')  >=0)
            ){
              this.isPOError = true;
          }
        
        },
        error =>{
          this.createPOErrorMessage = '';
          console.log(' Log File : RequisitionDetailsComponent : createPO : response error :   ' + error);
          this.isPOError = true;
          this.createPOErrorMessage = error;
        },
        () =>{
          let requestParameter: any = { "Req_ID": '' };;
          requestParameter = { "Req_ID": this.requisitionId }
          console.log('Before service call');
          this._requisitionDetailsService.findRequisitionLinesByRequisitionId(requestParameter).subscribe(
            (responseFromRequisitionLinesService: SearchRequisitionLinesResponseModel) => {
              console.log('after service call');
              console.log('response from service'+JSON.stringify(responseFromRequisitionLinesService));
              this.openCount = responseFromRequisitionLinesService.openCount;
              this.pendingCount = responseFromRequisitionLinesService.pendingCount;
              this.completedCount = responseFromRequisitionLinesService.completedCount;
              this.pair = responseFromRequisitionLinesService.totalQty;
              this.value = responseFromRequisitionLinesService.totalMrp;
              this.cost = responseFromRequisitionLinesService.totalStdCost;
              if (responseFromRequisitionLinesService.queryRequsitionLineResponse != null &&
                responseFromRequisitionLinesService.queryRequsitionLineResponse.length > 0) {
                let pendingDistributionCount = 0;
                let completedDistributionCount = 0;
                let createdPODistributionCount = 0;
                this.requisitionLinesDetails = [];
                this.pendingDistributionDetails = [];
                this.completedDistributionDetails = [];
                this.createdPODetails = [];
                this.requisitionLinesDetails = responseFromRequisitionLinesService.queryRequsitionLineResponse;
                this.requisitionLinesDetails.forEach(
      
                  reqisitionline => {
                    console.log(' Log File : RequisitionDetailsComponent : fetchRequsitionLinesByRequisitionIdFromService : Requisition Line Status :  ' + reqisitionline.reqLineStatus)
                    if (REQUISITION_LINES_STATUS_OPEN === reqisitionline.reqLineStatus) {
                      ++pendingDistributionCount;
                      this.pendingDistributionDetails.push(reqisitionline);
                    }
      
                    if (REQUISITION_LINES_STATUS_PENDING === reqisitionline.reqLineStatus) {
                      ++completedDistributionCount;
                      this.completedDistributionDetails.push(reqisitionline);
                    }
                    if (REQUISITION_LINES_STATUS_COMPLETED === reqisitionline.reqLineStatus) {
                      ++createdPODistributionCount;
                      this.createdPODetails.push(reqisitionline);
                    }
      
                  }
      
                );
      
                this.completereqQuant = 0;
                this.completeorderQuant = 0;
                for(let i = 0; i < this.completedDistributionDetails.length; i++)
                {
                  this.completereqQuant += this.completedDistributionDetails[i].reqQty;
                  this.completeorderQuant += this.completedDistributionDetails[i].orderedQty;
                }
      
      
                this.createreqQuant = 0;
                this.createorderQuant = 0;
                for(let i = 0; i < this.createdPODetails.length; i++)
                {
                  this.createreqQuant += this.createdPODetails[i].reqQty;
                  this.createorderQuant += this.createdPODetails[i].orderedQty;
                }
      
                let pendingCountStr  =  '';
                pendingCountStr  = "(" + pendingDistributionCount + ")";
                let completeCountStr  =  '';
                completeCountStr  = "(" + completedDistributionCount + ")";
                if(completedDistributionCount > 0){
                  this.isSubmit =  true;
                } else{
                  this.isSubmit =  false;
                }
                let createCountStr  =  '';
                createCountStr  = "(" + createdPODistributionCount + ")";
                this.firstTabHeaderLabel = REQUISITION_LINES_DISTRIBUTION_PENDING;
                this.firstTabHeaderLabel = this.firstTabHeaderLabel + pendingCountStr;
                this.secondTabHeaderLabel = REQUISITION_LINES_DISTRIBUTION_COMPLETED;
                this.secondTabHeaderLabel = this.secondTabHeaderLabel + completeCountStr;
                this.thirdTabHeaderLabel  = PO_CREATED;
                this.thirdTabHeaderLabel = this.thirdTabHeaderLabel + createCountStr;
      
              }
      
              this.loaDer.nativeElement.style.display = 'none';
            },
            error =>{
              this.createPOErrorMessage = '';
              console.log(' Log File : RequisitionDetailsComponent : createPO : response error :   ' + error);
              this.isPOError = true;
              this.createPOErrorMessage = error;
            },
            () =>{
              this.index = 2;
              console.log('  if *** '+this.isPOError);
              if(this.isPOError){
                this.index = 1;
                console.log(' createPOErrorMessage..');
              }
            }
          );
          
        }
      );

    //  setTimeout(()=>{
    //    console.log(' set time out..')
    //   if(this.createPOErrorMessage!=''){
    //     this.index = 1;
    //   } else{
    //     this.index = 2;
    //   }
    //  },2000);

    }
    /* navigate to previous page on click of back button*/
    onClickBack() {
      this._router.navigate(['search-requisition']);
    }

    handleChange(e) {
      this.index = e.index;
      if (this.requisitionId != null &&  this.index == 1) {
      
      }
    }
  
  viewCompletedDistributionDetailsPage(index, distributionCount,currentPage=1){
    console.log(index);
    console.log(currentPage);
    let absoluteIndex = (this.perPage * (currentPage - 1)) + index;
    console.log(absoluteIndex);
    let distributionPageData : RequisitionLines = this.completedDistributionDetails[absoluteIndex];
    distributionPageData.division =  this.searchedRequisitionDetails.division;
    distributionPageData.conceptCode = this.searchedRequisitionDetails.conceptCode;
    let  distributionPageDataArray = new Array<RequisitionLines>();
    distributionPageDataArray.push(distributionPageData);
    let distributionDetails:any = {
                                      distributionPageTitle:this._distributionPageTitle,
                                      distributionPageDataArray:distributionPageDataArray,
                                      isPreviousPageRequisition:true
                                  }
    if(distributionCount == 0)
    {
      this._navigationLinkParameterService.distributionDetails = distributionDetails;
      this._navigationLinkParameterService.flag = 'Y';
      this._router.navigate(['/distribution-detail-multiple']);
    } else
    {
      this._navigationLinkParameterService.distributionDetails = distributionDetails;
      //this._router.navigate(['/distribution-detail']);
      this._navigationLinkParameterService.flag = 'Y';
      this._router.navigate(['/distribution-detail-completed']);
    }
  }
  
  viewPendingDistributionDetailsPage(index, distributionCount,currentPage=1){
    console.log(index);
    console.log(currentPage);
    let absoluteIndex = (this.perPage * (currentPage - 1)) + index;
    console.log(absoluteIndex);
    let distributionPageData : RequisitionLines = this.pendingDistributionDetails[absoluteIndex];
    distributionPageData.division =  this.searchedRequisitionDetails.division;
    distributionPageData.conceptCode = this.searchedRequisitionDetails.conceptCode;
    let  distributionPageDataArray = new Array<RequisitionLines>();
    distributionPageDataArray.push(distributionPageData);
    let distributionDetails:any = {distributionPageTitle:this._distributionPageTitle,distributionPageDataArray:distributionPageDataArray,isPreviousPageRequisition:true}
  
    if(distributionCount == 0)
    {
      this._navigationLinkParameterService.distributionDetails = distributionDetails;
      //this._router.navigate(['/distribution-detail']);
      this._navigationLinkParameterService.flag = 'Y';
      this._router.navigate(['/distribution-detail-multiple']);
    } else
    {
      this._navigationLinkParameterService.distributionDetails = distributionDetails;
      //this._router.navigate(['/distribution-detail']);
      this._navigationLinkParameterService.flag = 'Y';
      this._router.navigate(['/distribution-detail-completed']);
    }
  
  }
  
  viewPoList(lineId){
    this._navigationLinkParameterService.lineId =null;
    this.createdPODetails.filter(cs => cs.reqLineId == lineId).forEach(
      each => {
        this._navigationLinkParameterService.lineId = each.reqLineId;
      }
    )
    this._router.navigate(['po-list']);
  }

  distributionCompletedPageWithFlag(index, distributionCount,currentPage=1){
    console.log(index);
    console.log(currentPage);
    let absoluteIndex = (this.perPage * (currentPage - 1)) + index;
    console.log(absoluteIndex);
    let distributionPageData : RequisitionLines = this.createdPODetails[absoluteIndex];
    distributionPageData.division =  this.searchedRequisitionDetails.division;
    distributionPageData.conceptCode = this.searchedRequisitionDetails.conceptCode;
    let  distributionPageDataArray = new Array<RequisitionLines>();
    distributionPageDataArray.push(distributionPageData);
    let distributionDetails:any = {
                                    distributionPageTitle:this._distributionPageTitle,
                                    distributionPageDataArray:distributionPageDataArray,
                                    isPreviousPageRequisition:true
                                }
  if(distributionCount == 0)
  {
    this._navigationLinkParameterService.distributionDetails = distributionDetails;
    this._navigationLinkParameterService.flag = 'N';
    this._router.navigate(['/distribution-detail-multiple']);
  } else
  {
    this._navigationLinkParameterService.distributionDetails = distributionDetails;
    //this._router.navigate(['/distribution-detail']);
    this._navigationLinkParameterService.flag = 'N';
    this._router.navigate(['/distribution-detail-completed']);
  }
  
  }
 
}
