import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../../common/rds-bil-api-url';
import { BilApiUrl } from '../../../common/bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class ManualGrnService {
  
  SEARCH_ASN_URL: string = RdsBilApiUrl.RDC_SEARCH_LINES;
  FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL: any = BilApiUrl.FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL;
  SAVE_MANUAL_GRN_URL: any = RdsBilApiUrl.SAVE_MANUAL_GRN_URL;
  SAVE_MANUAL_GRN_CLAIMED_URL: any = RdsBilApiUrl.SAVE_MANUAL_GRN_CLAIM_URL;
  SAVE_MANUAL_GRN_DEFECTIVE_URL: any = RdsBilApiUrl.SAVE_MANUAL_GRN_DEFECTIVE_API_URL;
  constructor( private http: HttpClient) { }

  getInvoiceLineDetails(challanInvoice,userCode,asnNumber) {
    const details =
    [{ 
      "name": "asnNumber", 
      "value": asnNumber
    },
    { "name": "userCode", 
      "value": userCode
    },
    { "name": "searchType", 
      "value": "line"
    },
    { "name": "pageNumber", 
      "value": 1
    },
    { "name": "pageSize", 
      "value": 1000
    }
     ];

    return this.http.post(this.SEARCH_ASN_URL, details);
  }

  getSizeMaster(articleVal) {
    const details = [{ "name": "letterOfArticle", "value": articleVal }];

    return this.http.post(this.FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL,details );
  }

  saveManualGRNMultiple(saveList,inventoryType){
   let url:any;
   if(inventoryType == 'CLAIMED'){
     url = this.SAVE_MANUAL_GRN_CLAIMED_URL;
   } else if(inventoryType == 'DEFECTIVE'){
    url = this.SAVE_MANUAL_GRN_DEFECTIVE_URL;
   }else{
    url = this.SAVE_MANUAL_GRN_URL;
   }
   return this.http.post(url,saveList ); 
  }
}
