import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {FormBuilder, FormGroup } from '@angular/forms';
import {StationaryService} from './stationary.service';
import {ResponseMessage} from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import{Batches} from '../batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import{ importsave} from '../import-article-price/import-article-save';
import { Router, RouterModule } from '@angular/router';
import {UtilsService} from '../common/utils-service';

@Component({
  selector: 'app-stationary',
  templateUrl: './stationary.component.html',
  //styleUrls: ['./stationary.component.css']
  styleUrls: ['../../assets/css/bootstrap.css','../../assets/css/bil-style.css','./stationary.component.css']
})
export class StationaryComponent implements OnInit {
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  profileForm: FormGroup;
  error: string;
  responseMessage:string;
  statusMessage:string = '';
  uploadFileName:string='No Chosen file';
  downloadUrlList:Batches[] = [];
  downloadUrl:string;
  downloadedFile:string;
  checkService:boolean=false;
  enable:boolean=false;
  fileUpload = {status: '', message: '', filePath: ''};
  userName:string;
 

  constructor(private fb: FormBuilder,private _router: Router, private fileUploadService: StationaryService, private httpClient: HttpClient,
    private _utilsService: UtilsService ) {
      this.profileForm=this.fb.group({
      name:[''],
      file:[''],
      uploadKey:['PO_CONSUMABLE'],
      uploadedBy:['']
     });
   }

   ngOnInit() {
    setTimeout(()=>{
      this.loaDer.nativeElement.style.display = 'none';    
    },100);

    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.profileForm.get('uploadedBy').setValue(this.userName);
  }


// ---------------------- file upload ------------------------ //

  onSelectFile(event) {     
    if(event.target.files.length > 0 ) {
      this.statusMessage='';
      this.responseMessage = '';
      this.uploadFileName=event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
    }
  }

  onSubmit()  {
    this.statusMessage='';
    this.responseMessage='';
    if (this.myInputVariable.nativeElement.files.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage='error';
      return false;
    }
    let newFileArray:any[] = this.profileForm.get('file').value.name.split('.');
    newFileArray[0] = newFileArray[0].replace(/ /g,"_");
     let newFileFormat = newFileArray[0]+'_'+ this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
    //this.profileForm.get('file').setValue(newFileFormat);
    const formData = new FormData();
    //formData.append('name', this.profileForm.get('name').value);
    formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData.append('uploadKey', 'PO_CONSUMABLE');
    formData.append('uploadedBy', this.profileForm.get('uploadedBy').value);
    console.log(formData);
    this.loaDer.nativeElement.style.display = 'block';

    this.fileUploadService.upload(formData).subscribe(
     (response : importsave) => {
      this.responseMessage = '';
      this.statusMessage='';
      console.log(response);
      if( response.status == "success" ){
       this.responseMessage = response.message;
        this.statusMessage='success';
        this.enable=true;
       }
       else {
        this.responseMessage= response.message;
        this.statusMessage='error';
      }
      this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err)=>{
       this.responseMessage=err;
       this.statusMessage='error';
       this.enable=false;
       this.loaDer.nativeElement.style.display = 'none'; 
      }
    );
 }

// ---------------------- file upload ------------------------ //

  // ------------------- download template --------------------- //

  public async downloadFile(): Promise<void>{  
    this.loaDer.nativeElement.style.display = 'block'; 
    this.fileUploadService.getDownloadUrl().subscribe(
      (res:BatchesData) => {
        this.downloadUrlList = res['configParamModels'];
        // if (this.downloadUrlList[0].paramName == 'template_url') {
        //   console.log('inside if check');
        //   this.downloadUrl = this.downloadUrlList[0].paramValue;
        //   //console.log(this.downloadUrl);
        //   this.getDownloadedFile(this.downloadUrl);
        //   return this.checkService = true;
        // }
        this.downloadUrlList.filter(cs=> cs.paramName == 'template_url').forEach(
          each => {
            this.downloadUrl = each.paramValue;
          }
        )
        this.getDownloadFile(this.downloadUrl);
        return this.checkService = true;
      }
    );
  }
  
  public async getDownloadFile(downloadUrl): Promise<void>{
    let fullDownloadUrl:string=downloadUrl+"Consumable_PO_Upload_Master_Template.xls";
    const file = await this.httpClient.get<Blob>(fullDownloadUrl,{responseType: 'blob' as 'json'}).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "Consumable_PO_Upload_Master_Template.xls";
    anchor.href = url;
    anchor.click(); 
    window.URL.revokeObjectURL(url);  
    this.loaDer.nativeElement.style.display = 'none'; 
  }

  // ------------------- download template --------------------- //

  // -------------------- reset template ----------------------- //

  resetSearchPurchaseOrderResults(){
    this.uploadFileName='';
    this.responseMessage='';
    this.statusMessage='';
    this.enable=false;
    this.loaDer.nativeElement.style.display = 'none';
    this.myInputVariable.nativeElement.value = "";
  }

  // -------------------- reset template ----------------------- //

  generateImportPo() {
    this.responseMessage='';
    let uploadkeyValues:any= {
      "uploadKeyValues":"PO_CONSUMABLE",
      "uploadedBy": this.userName    
  }
    this.loaDer.nativeElement.style.display = 'block';
   let importpo:any=this.fileUploadService.getGenerate(uploadkeyValues).subscribe(
    (response: importsave) => {
     if (response.status=="success"){
        this.responseMessage=response.message;
        this.statusMessage='success';
      }else{
      this.responseMessage=response.message;
      this.statusMessage='error';
      }
      this.loaDer.nativeElement.style.display = 'none'; 
    },
    (err)=>{
      this.responseMessage=err;
      this.statusMessage='error';
      this.loaDer.nativeElement.style.display = 'none'; 
    }
   );
   this.enable=false;
  }

  onClickBack() {
    this._router.navigate(['search-po']);
  }

}
