import { RdsBilApiUrl } from '@/common/rds-bil-api-url';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateGatePassService {
  updateGatePassUrl:string = RdsBilApiUrl.UPDATE_GATE_PASS_DETAILS;
  constructor(private http:HttpClient) { }

  updateGateData(updateRequest){
    return this.http.post(this.updateGatePassUrl,updateRequest);
  }
}
