export class ReportSearch {
    estSession: string;
    conceptCode: string;
    mCat: string;
    rdcNo: string;
    delvFrmWk: string;
    delvToWk: string;
    suppCode: string;
    poType: string;
    year: string;
    session: string;
    ordyrWkFrm: string;
    ordyrWkTo: string;
    delyrWkFrm: string;
    delyrWkTo: string;
    concept: string;
    unitNo: string;
    type: string;
    orderNo: string;
    artNo: string;
    delWkYrFrm: string;
    delWkYrTo: string;
    supplyWkYrFrm: string;
    supplyWkYrTo: string;
    orderNoTo:string;
    orderNoFrm:string;
    ordNo:string;
    artNumber:string;
    vendorCd:string;
    reportType:string;
    assortmentType:string;
    catCode:string;
    shopCode:string;
    packCode:string;
    supyrwkTo:string;
    supyrwkFrom:string;
    receiveyrWkFrm:string;
    receiveyrWkTo:string;
    orderyrWkFrm:string;
    orderyrWkTo:string;
    groupcode:string;
    partyName:string;
    assosiateNumber:string;
    constructor() { }
}