
import { Component, OnInit, TemplateRef,ViewChild, ElementRef,ContentChild } from '@angular/core';  
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NavigationLinkParameterService } from '../../../common/navigation-link-parameters-service';
import { ManualGrnService } from "./manual-grn.service";
import { ASNDetailResponseModel } from '../../../common/asn-detail-model';
import { Router, RouterModule } from '@angular/router';
import { UtilsService } from '../../../common/utils-service';
// import { exists } from 'fs';
@Component({
  selector: 'app-manual-grn',
  templateUrl: './manual-grn.component.html',
  // styleUrls: ['./manual-grn.component.css']
  styleUrls: ['../../../../assets/css/bootstrap.css', '../../../../assets/css/rds-bil-style.css',
  '../../../../../node_modules/font-awesome/css/font-awesome.css','./manual-grn.component.css']
})
export class ManualGrnComponent implements OnInit {
  @ViewChild('template', { static: false }) template: ElementRef;
  @ViewChild('shortageTemplate', { static: false }) shortageTemplate: ElementRef;
  @ViewChild('loaDer', { static: true }) loaDer: ElementRef;
 // @ContentChild('modalRoot', { static: false }) modalRoot: ElementRef;

  conceptQueryMas: any;
  assortDetail: any[] = [];
  supplyVarList: any;
  articleFirstDigit: any;
  // articleMaster: any;
  // groupCode: any; 
  grantSum: any;
  allAsstData: any;
  grnWiseSum:any;
  asnWiseSum: any[] = [];
  grnWiseSum1: any[] = [];
  ValidateGRNGreaterMesssage: any;
  ValidateGRNSmallerMesssage: any;
  enable12 = true;
  userPairQty: any[] = [];
  show = false;
  //modalRef: BsModalRef; 
  assortAll: any[] = [];
  fullAssort: any[] = [];
  assortList: {};
  assortListFull:{};
  fullAssortvalue: any[] = [];
  asnIDCompare : any;
  assortcombine: any[] = [];
  assortCombineFull: any[] = [];
  sucessMsgGrn: any;
  artNumber : any[]=[];
  asnNumber : any[]=[];
  errorMsgGrn: any;
  groupCode : any[]=[];
  articleMaster: any[]=[];
  enabletext = true;
  ginDate:any;
  createGRNFlag:any;
  claimslipEntities:any;
  asnAssortmentClaimslips:any[]=[];
  asnAssortVal:any;
  selectedAssortmentForClaim:any;
  asnId:any;
  initialClaimSlipData:any[] = [];
  inventoryType:any;

  
  constructor( private navigationLinkParameterService: NavigationLinkParameterService,private utilsService: UtilsService, private manualGrnService: ManualGrnService,private router: Router ) { }
 
  ngOnInit() {
    console.log(this.navigationLinkParameterService);
    const sessUsername = sessionStorage.getItem('currentUser');
    this.conceptQueryMas = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
        this.supplyVarList = this.navigationLinkParameterService.supplyNo;
        this.createGRNFlag = this.navigationLinkParameterService.createGRNFlag;
        console.log(this.createGRNFlag);
        
        console.log(this.supplyVarList);
        this.loaDer.nativeElement.style.display = 'block';
        this.manualGrnService.getInvoiceLineDetails(this.supplyVarList.supplyNo,this.conceptQueryMas,this.supplyVarList.asnNumber).subscribe(
         (response: ASNDetailResponseModel) => {
            this.assortDetail = response.dataList;
            // console.log(this.assortDetail);
            
            let index = 0;
            this.assortDetail.forEach(element => {
               this.add(element.artNumber);
               this.userPairQty = [];
               this.fullAssort.push(element.shipmentAssortment);
               element.shipmentAssortment.forEach((asDifPairQty,index1) => {
                this.userPairQty.push(asDifPairQty.pairQty);
                this.assortDetail[index]['shipmentAssortment'][index1]['pairQtyASN'] = asDifPairQty.pairQty;
               
               });
               this.artNumber.push(element.artNumber);
               this.asnNumber.push(element.asnNumber);
               //this.assortDetail.push(this.userPairQty);
               
               this.articleFirstDigit = this.assortDetail[index].artNumber.toString().slice(0,1); 
               //console.log(this.articleFirstDigit);
               this.manualGrnService.getSizeMaster(this.articleFirstDigit).subscribe(
                (response: any) => {
                  // console.log(response);
                  // this.articleMaster.push(response.dataList);
                  this.groupCode.push(response.dataList[0].groupCode); 
                  // console.log(this.articleMaster);
                  ////console.log(this.groupCode);
                  
                }
                );
              index++;
             
            });
        console.log(this.articleMaster);

     if (this.navigationLinkParameterService.searchasnHeaderData === undefined) {
      //console.log('New Search has to be made');
      
      } else {
        //console.log('Automatic Search will be performed');
        
      }
      
      this.loaDer.nativeElement.style.display = 'none';
      //this.add(this.assortDetail);
  });
  // this.loaDer.nativeElement.style.display = 'none';
}


  grnwq(value, articleNo,asnId, sizebucket,grnindex) {
    console.log('calling');
    console.log(value);
    console.log(articleNo);
    console.log(sizebucket);
    console.log(grnindex);
    console.log(this.assortDetail);
    //this.arrayManualGrn[articleNo][sizebucket]=value
    ////console.log( this.arrayManualGrn[articleNo][sizebucket]);
    //this.assortDetail[index]['shipmentAssortment'][index1]['pairQty'] = value;
    this.ValidateGRNGreaterMesssage ="";
    this.ValidateGRNSmallerMesssage="";
    if (value != null || value != undefined || value != '') {
      console.log(this.assortDetail[grnindex]);
      this.assortDetail.filter(cs => cs.asnId == asnId).forEach(
        calgrnsum => {
          calgrnsum.shipmentAssortment.filter(cs => cs.sizeLabel == sizebucket).forEach(
            checksum => {
              checksum.pairQty = value;
              // this.calculateGrnSum(articleNo,grnindex);
              this.assortDetail.filter(cs => cs.asnId == asnId).forEach(
              // this.assortDetail.forEach(
                calgrnsum1 => {
                  calgrnsum1.grnWiseSum = 0;
                  calgrnsum1.shipmentAssortment.forEach(
                    checksum1 => {
                      if(checksum1.pairQty>checksum1.pairQtyASN){
                        this.ValidateGRNGreaterMesssage +='<div>'+' Size wise GRN quantity should not be greater than  Size wise quantity for ASN ' + calgrnsum.asnNumber + ' and Article '+ articleNo+'</div>' ;
                        this.enable12 = false;
                        console.log('call3');
                        // return false;
                      }
                      calgrnsum1.grnWiseSum += Number(checksum1.pairQty);
                     });
                  this.grnWiseSum1[grnindex]= calgrnsum1.grnWiseSum;
                })
            }
          )
        }
      )
      // this.assortDetail.filter(cs => cs.artNumber == articleNo).forEach(
      //   calgrnsum => {
      //     calgrnsum.shipmentAssortment.filter(cs => cs.sizeLabel == sizebucket).forEach(
      //       checksum => {
      //         checksum.pairQty = value;
      //         // this.calculateGrnSum(articleNo,grnindex);
      //         this.assortDetail.filter(cs => cs.artNumber == articleNo).forEach(
      //         // this.assortDetail.forEach(
      //           calgrnsum1 => {
      //             calgrnsum1.grnWiseSum = 0;
      //             calgrnsum1.shipmentAssortment.forEach(
      //               checksum1 => {
                     
      //                 calgrnsum1.grnWiseSum += Number(checksum1.pairQty);
      //                });
      //             this.grnWiseSum1[grnindex]= calgrnsum1.grnWiseSum;
      //           })
      //       }
      //     )
      //   }
      // )
      console.log(this.grnWiseSum1);console.log('grn');
      //console.log(this.asnWiseSum+'arn');
      //console.log(this.artNumber);
      //console.log(this.asnNumber);

      this.grnWiseSum1.forEach((val, point) => {
                console.log(point); // 0, 1, 2
                console.log(val); // 9, 2, 5
                console.log(this.asnWiseSum[point]);
              if(this.ValidateGRNGreaterMesssage === "" && this.ValidateGRNSmallerMesssage === "")
              {  
                if (val < this.asnWiseSum[point]) {
                  this.enable12 = true;
                  this.ValidateGRNSmallerMesssage += 'Sum of size wise GRN quantity is less than sum of size wise quantity for ASN ' + this.asnNumber[point] + ' and Article '+ this.artNumber[point]+',';
                  // this.ValidateGRNGreaterMesssage ="";
                  // this.shortageTemplate.nativeElement.click();               
                }
                else if(val >  this.asnWiseSum[point]){
                  this.ValidateGRNGreaterMesssage +='<div>'+'Sum of size wise GRN quantity should not be greater than Sum of Size wise quantity for ASN ' + this.asnNumber[point] + ' and Article '+ this.artNumber[point]+'</div>' ;
                  this.enable12 = false;
                  console.log('call');
                
                }
                else if(val =  this.asnWiseSum[point]){
                  this.enable12 = true;
                  console.log('call2');
                }
              }
            });
             
      // To be checked
      // if(this.ValidateGRNSmallerMesssage !== "" && this.ValidateGRNGreaterMesssage === ""){
      //   this.template.nativeElement.click();
      // }
    }
  }

  clickOkManualGRN(){
    //console.log('save GRN');
    // this.template.nativeElement.click();
    this.ValidateGRNSmallerMesssage="";
    this.enable12 = true;
  }

  calculateGrnSum(articleNo,grnindex) {

   // //console.log(articleNo);
    ////console.log(this.grnWiseSum1);
    this.assortDetail.filter(cs => cs.artNumber == articleNo).forEach(
      calgrnsum => {
        calgrnsum.grnWiseSum = 0;
        calgrnsum.shipmentAssortment.forEach(
          checksum => {
           
            calgrnsum.grnWiseSum += Number(checksum.pairQty);
           });
        this.grnWiseSum1[grnindex]= calgrnsum.grnWiseSum;
      })
  }

  add(articleNo){
    console.log("claim slip data::"+articleNo);
    this.assortDetail.filter(cs => cs.artNumber == articleNo).forEach(
    calgrnsumASN => {
      let asnSum = 0;
      this.inventoryType = calgrnsumASN.inventoryStatus;
      calgrnsumASN.shipmentAssortment.forEach(
        checksumASN => {
          asnSum += checksumASN.pairQty;
          if(checksumASN.asnAssortmentClaimSlipEntities.length > 0){
            checksumASN.asnAssortmentClaimSlipEntities.forEach(cs => {
              let obj = cs;
              this.initialClaimSlipData.push(obj);
            });  
          }     
          });
          this.asnWiseSum.push(asnSum); 
          this.grnWiseSum1.push(asnSum);
          //console.log(this.asnWiseSum);
        })
    }

  onClickBack() {
      this.router.navigate(['rds-asn-details']);
  }
  
  claimDetails(asnAssortmentClaimSlipEntities,assortData,asnId){
    console.log(asnAssortmentClaimSlipEntities);
    console.log(assortData);
    this.asnAssortVal="";
    this.claimslipEntities="";
    this.selectedAssortmentForClaim = assortData.asnAssortmentId;
    this.asnId = asnId;
    // this.asnAssortmentClaimslips.filter(rs => rs.assortmentClaimSlipId == asnAssortmentClaimSlipEntities.assortmentClaimSlipId).forEach(
    //     claimCheck => {
    //       this.claimslipEntities=asnAssortmentClaimSlipEntities;
    //     })
    this.claimslipEntities=asnAssortmentClaimSlipEntities;
  }

  checkPairs(clEnt,val){
    console.log(clEnt);
    console.log(val.key);
    console.log(this.initialClaimSlipData);
    this.asnAssortVal=val.key;
    clEnt.pairs = val.target.value;
    console.log(this.asnAssortmentClaimslips);
    console.log(this.initialClaimSlipData);
    // let index = this.asnAssortmentClaimslips.findIndex(c => c.assortmentClaimSlipId == clEnt.assortmentClaimSlipId);
    // if(index < 0){
    //   this.asnAssortmentClaimslips.push(clEnt.assortmentClaimSlipId);
    // } else{
    //   console.log(this.initialClaimSlipData);
    //   let oldObject = this.asnAssortmentClaimslips.find(c => c.assortmentClaimSlipId == clEnt.assortmentClaimSlipId);
    //   if(oldObject.pairs == clEnt.pairs){
    //     this.asnAssortmentClaimslips.splice(index,1);
    //   }
    // }
  }

  saveClaimslip(clEnt){
    let sumOfClaimSlipPairs:number = 0;
    console.log(clEnt);
    console.log(this.initialClaimSlipData);
    clEnt.forEach(
      each => {
        sumOfClaimSlipPairs += Number(each.pairs);
        let index = this.asnAssortmentClaimslips.findIndex(c => c.assortmentClaimSlipId == each.assortmentClaimSlipId);
        let oldObject = this.initialClaimSlipData.find(c => c.assortmentClaimSlipId == each.assortmentClaimSlipId);
          if(index < 0 && Number(each.pairs) == 0){
            console.log("Push the claim ID");
            this.asnAssortmentClaimslips.push(each.assortmentClaimSlipId);
          } else if(index >= 0 && Number(each.pairs) != 0){
            console.log("Splice the claim ID");
            this.asnAssortmentClaimslips.splice(index,1);
          } else {
            console.log("cannot be pushed");
          } 
    });
    let asnData = this.assortDetail.find(cs => cs.asnId == this.asnId);
    let assortmentData = asnData.shipmentAssortment.find(cs => cs.asnAssortmentId == this.selectedAssortmentForClaim);
    assortmentData.pairQty = Number(sumOfClaimSlipPairs);
    console.log(this.assortDetail);
    console.log(this.asnAssortmentClaimslips);
    this.grnwq(assortmentData.pairQty,asnData.artNumber,asnData.asnId,assortmentData.sizeLabel,this.assortDetail.findIndex(cs => cs.asnId == this.asnId));
  }

  saveManualGRN(asnNo,pairQty,createGRNFlag){
    console.log('saveManualGRN');
    this.errorMsgGrn="";
    let ginDate = this.utilsService.transformDateSearch(this.ginDate);
    if(this.ginDate == '' || this.ginDate == null){
      this.errorMsgGrn="Please select GIN date";
      return false;
    }
    this.enable12 = false;
    this.loaDer.nativeElement.style.display = 'block';
    let i = 0;
    console.log(this.assortDetail);
    console.log(this.ginDate); 
    
    ////console.log(this.fullAssort);
      //console.log(this.fullAssort);
      this.assortDetail.forEach(( list, indexvalue ) => {
      //console.log(list.shipmentAssortment);
      this.assortcombine =[];
        //this.fullAssort.forEach((singleAssorrt,asotId)  => {
          list.shipmentAssortment.forEach(element => {
            //console.log(element.pairQtyASN);
            let claimSlipList:any[] = [];
            element.asnAssortmentClaimSlipEntities.forEach(claims => 
              {
                if(this.asnAssortmentClaimslips.findIndex(c => c == claims.assortmentClaimSlipId) >= 0){
                  console.log("Found:::"+claims.assortmentClaimSlipId);
                  claimSlipList.push(claims);
                }
              });
              console.log(claimSlipList);
            if(element.pairQty != null && element.articleSize != null){
                this.assortListFull =   {
                "asnAssortmentId": String(element.asnAssortmentId),
                "asnId" : String(list.asnId), 
                "artSize" : String(element.articleSize), 
                "pairQuanity" : String(element.pairQty), 
                "lastUpdatedBy" : this.conceptQueryMas, 
                "sizeLabel" : element.sizeLabel,
                "asnAssortmentClaimslips":claimSlipList
              };
              this.assortcombine.push(this.assortListFull);
            }

          });
        
        //});

    this.assortList = 
    {
      "asnId": String(list.asnId), 
      "pairQuantity": this.grnWiseSum1[indexvalue],
      "asnAssortments": this.assortcombine
    }; 

    this.assortCombineFull.push(this.assortList);
  });

  ////console.log(this.assortCombineFull);
  
   let savePayload = 
    { 
      "asnNumber" : this.supplyVarList.asnNumber,
      "ginDate"   : ginDate,
      "userCode" : this.conceptQueryMas,
      "userRemarks" :"creating grn from asn",
      "asnRecords" : this.assortCombineFull,
      "manualAutoFlag":createGRNFlag
    };

    console.log(savePayload);
    // return false;
   
    this.manualGrnService.saveManualGRNMultiple(savePayload,this.inventoryType).subscribe(
      (response: any) => {
        this.loaDer.nativeElement.style.display = 'none';
        if (response.status === 'success'){
        this.sucessMsgGrn = response.message;
        this.enable12 = false;
        this.enabletext = false;
        }
        else{
          this.sucessMsgGrn = "";
          this.errorMsgGrn = "GRN is not created";
          this.enable12 = true;
          this.enabletext = true;
        }
      }
    )
   }

}
