import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class BatchesService {
  // submitCodeurl: string = BilApiUrl.PO_AUTOCANCELLATION;
  // orderTypeurl: string = BilApiUrl.FETCH_CONFIG_DETAILS;
  // conceptCodeurl: string = RdsBilApiUrl.CONCEPT_MASTER;
  rdsbatchlisturl: string = RdsBilApiUrl.RDS_BATCH_LIST;
  URL: string;
  batchesUrl = [
    { name: 'RM_40_OUTBOUND_JOB', url: RdsBilApiUrl.RM_40_OUTBOUND_JOB },
    { name: 'SHORTAGE_ADJUSTMENT_JOB', url: RdsBilApiUrl.SHORTAGE_ADJUSTMENT_JOB },
    { name: 'INTER_TRANSFER_RIMS_OUTBOUND', url: RdsBilApiUrl.INTER_TRANSFER_RIMS_OUTBOUND },
    { name: 'STOCK_VALUATION_OUTBOUND_JOB', url: RdsBilApiUrl.STOCK_VALUATION_OUTBOUND_JOB },
  ];
  constructor(private _http: HttpClient) { }

  // getOrderType() {
  //   return this._http.get(this.orderTypeurl);
  // }

  // getConceptCode(details: any) {
  //   return this._http.post(this.conceptCodeurl, details);
  // }

  // getSubmit(details: any) {
  //   return this._http.post(this.submitCodeurl, details);
  // }

  getUploadWithFilename(fileName: any, batchname) {
    this.URL = '';
    this.batchesUrl.forEach(
      holdingObj => {
        if (batchname === holdingObj.name) {
          this.URL = holdingObj.url;
        }
      }
    );
    console.log('filename' + JSON.stringify(fileName));
    console.log('URL' + this.URL);
    return this._http.post(this.URL, fileName);
  }

  getUploadWithoutFilename(batchname) {
    this.URL = '';
    this.batchesUrl.forEach(
      holdingObj => {
        if (batchname === holdingObj.name) {
          this.URL = holdingObj.url;
        }
      }
    );
    console.log('URL' + this.URL);
    return this._http.post(this.URL, null);
  }
  
  getRDSBatches(batchInput: any) {
    console.log(this.rdsbatchlisturl);
    console.log(batchInput);
    return this._http.post(this.rdsbatchlisturl, batchInput);
  }

}

