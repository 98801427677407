import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../../common/rds-bil-api-url';


@Injectable({
  providedIn: 'root'
})
export class UpdateInvoiceService {
  // invoice_search_url: string = RdsBilApiUrl.RDC_SEARCH_INVOICE;
  completeShipmentUrl: string = RdsBilApiUrl.COMPLETE_SHIPMENT;
  stopShipmentUrl: string = RdsBilApiUrl.STOP_SHIPMENT;
  search_freight_additional_info: string = RdsBilApiUrl.SEARCH_FREIGHT_ADDITIONAL_INFO;
  update_freight_additional_info: string = RdsBilApiUrl.FREIGHT_ADDITIONAL_INFO;

  constructor(private http: HttpClient) { }

  // getInvoiceLineDetails(challanInvoice) {
  //   let details =
  //     [{
  //       "name": "challanInvoiceNumber",
  //       "value": challanInvoice
  //     }
  //     ]

  //   return this.http.post(this.invoice_search_url, details);
  // }

  completeShipment(details: any) {
    console.log('updating parameter' + JSON.stringify(details));
    return this.http.post(this.completeShipmentUrl, details);
  }
  stopShipment(details: any) {
    console.log('updating parameter' + JSON.stringify(details));
    return this.http.post(this.stopShipmentUrl, details);
  }
  getInvoiceDetails(details) {
    return this.http.post(this.search_freight_additional_info, details);
  }
  updateAdditionalInfo(details:any){
    return this.http.put(this.update_freight_additional_info, details);
  }
}
