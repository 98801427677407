import { AssortmentDetails } from './assortment-details';
export class SearchPackMasterDetails {
    packingId:string;
    concept:string;
    iCode:string;
    catCode:string;
    shopCode:string;
    packCode:string;
    groupCode:string;
    heartSize:string;
    oldRDC:string;
    procTag:string;
    createdBy:string;
    createdDate:string;
    lastUpdatedBy:string;
    lastUpdateDate:string;
    assortmentType:string;
    assortmentCode:string;
    packingMasterAssortmentsDTO : AssortmentDetails[] = [];
    constructor(){}
}