import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { RdcMaintenanceService } from './rdc-maintenance.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rdc-maintenance',
  templateUrl: './rdc-maintenance.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css','./rdc-maintenance.component.css']
})
export class RdcMaintenanceComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  
  rdcForm: FormGroup;
  rdcResultForm: FormGroup;
  isDisplayResult: boolean = false;
  statusMessage: string;
  responseMessage : string;
  updateMessage : string;
  userName: any;
  concepts: any;
  keys : any[];
  faciFlag: boolean;
  currentPage : number = 1;
  itemPerPage:number = 5;

  constructor(private fb: FormBuilder, private rdcMaintenanceService: RdcMaintenanceService,private router: Router) {
    this.rdcForm = this.buildRDCForm();
    this.rdcResultForm = this.buildRDCResultForm();
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    // this.userName = "user.test4";
   }

  ngOnInit() {
    setTimeout(() => {
   
      // const sessUsername = sessionStorage.getItem('currentUser');
      const conceptQueryMas = [{ 'name': 'email', 'value': this.userName }];
      
      this.rdcMaintenanceService.getConcepts(conceptQueryMas).subscribe(
        (data: any) => {
          this.concepts = data.dataList;
          console.log(this.concepts); console.log('concepts');
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
      this.keys = [{
        "name" : "Yes",
        "value" : "Y"
      },
      {
        "name" : "No",
        "value" : "N"
      }]
    }, 100);
  }

  buildRDCForm() {
    return this.fb.group({
      shopNo: [null],
      concept: [null]
    });
  }

  buildRDCResultForm(): FormGroup {
    return this.fb.group({ rdcmain: this.fb.array([]) });
  }

  onSubmit(formData:any){
    let searchPayload = [{

      "configList": [
      {
      "name": "shopNumber",
      "value": this.rdcForm.get('shopNo').value
      },
      {
      "name": "conceptCode",
      "value": this.rdcForm.get('concept').value
      }
      ],
      
      "type":"RDC_MAINTENANCE_SCREEN"
      
      }]
      this.statusMessage = '';
      this.updateMessage = '';
      this.responseMessage = '';
      this.loaDer.nativeElement.style.display = 'block';
      this.rdcResultForm = this.buildRDCResultForm();
      if( this.rdcForm.get('shopNo').value != null || this.rdcForm.get('concept').value != null){
    let control = <FormArray>this.rdcResultForm.controls.rdcmain;
      this.rdcMaintenanceService.getSearchResponse(searchPayload).subscribe(
        (response : any)=>{
          if(response.transporformModels[0] != null){
          this.isDisplayResult = true;
          let arrayList = [];
          arrayList = response.transporformModels;
          arrayList.forEach(
            d =>{
              console.log(arrayList);
              let arrayOfObj = [];
              arrayOfObj = d;
              console.log(d);
              
              arrayOfObj.forEach(
                e =>{
                  control.push(this.fb.group({
                    shopNo: [{ value: e.shopNumber, disabled: true }],
                    facilityCode:[{value:e.facilityCode, disabled: false},Validators.required],
                    companyCode: [{ value: e.companyCode, disabled: false },Validators.required],
                    originInfo:[{value:e.originInfo, disabled: true}],
                    operationalInRDS: [{ value: e.operationalRdsDmsFlag , disabled: false }],
                    operationalInLogfire: [{value: e.operationalLogFireFlag, disabled: true}],
                    operationalInPO:[{ value: e.operationalPOFlag, disabled: false}],
                    parentRDCEnabled: [{value: e.parentShopNumber!=null ? true:false, disabled: false}],
                    parentShopNumber:[{value: e.parentShopNumber, disabled: e.parentShopNumber!=null ? false : true},Validators.required]
                
                  }));
                }
              )
            }
          )}
          else{
            this.responseMessage = 'No Data Found';
            this.isDisplayResult = false;
            this.loaDer.nativeElement.style.display = 'none';
          }
        },(error) => {
          console.error('some error occured! while fetching discounts from server');
          this.rdcResultForm = this.buildRDCResultForm();
          this.isDisplayResult = false;
          this.loaDer.nativeElement.style.display = 'none';
        }, () => {
          console.info('all disounts fetched. ');
          this.loaDer.nativeElement.style.display = 'none';}
      )}
      else{
        this.responseMessage = 'Please select any of the search fields';
        this.loaDer.nativeElement.style.display = 'none';
      }
  }

  onChangeoptRDS(event,index){
    console.log(index);
    if(this.rdcResultForm.get(['rdcmain',index]).get('operationalInRDS').value != null){
      this.rdcResultForm.get(['rdcmain',index]).get('operationalInLogfire').setValue(
        this.rdcResultForm.get(['rdcmain',index]).get('operationalInRDS').value=='Y' ? 'Y':'N');
      }
  }

  onChangeCompanyFacility(event,index,name){
    console.log(index);
    
    if(this.rdcResultForm.get(['rdcmain',index]).get('facilityCode').value == null){
      this.rdcResultForm.get(['rdcmain',index]).get('originInfo').setValue(this.rdcResultForm.get(['rdcmain',index]).get('companyCode').value.toUpperCase());
    }
    else if(this.rdcResultForm.get(['rdcmain',index]).get('companyCode').value == null){
      this.rdcResultForm.get(['rdcmain',index]).get('originInfo').setValue(this.rdcResultForm.get(['rdcmain',index]).get('facilityCode').value.toUpperCase());
    }else{
    this.rdcResultForm.get(['rdcmain',index]).get('originInfo').setValue(
      this.rdcResultForm.get(['rdcmain',index]).get('facilityCode').value.
        concat(this.rdcResultForm.get(['rdcmain',index]).get('companyCode').value).toUpperCase()
    );}
  }

  onChangeParentRDCEnabled($event,index){
    console.log(index);
    this.rdcResultForm.get(['rdcmain',index]).get('parentRDCEnabled').value == true ? 
    this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').enable() : 
    (this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').disable(),
    this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').setValue(null));
  }

  onSave(event:any,index:any){
    console.log(index);
    if(!this.rdcResultForm.get(['rdcmain',index]).invalid){
    let saveObj = [];
   saveObj = [{
    "shopNumber": this.rdcResultForm.get(['rdcmain',index]).get('shopNo').value ,
    "facilityCode": this.rdcResultForm.get(['rdcmain',index]).get('facilityCode').value.toUpperCase(),
    "companyCode": this.rdcResultForm.get(['rdcmain',index]).get('companyCode').value.toUpperCase(),
    "originInfo": this.rdcResultForm.get(['rdcmain',index]).get('originInfo').value,
    "operationalRdsDmsFlag": this.rdcResultForm.get(['rdcmain',index]).get('operationalInRDS').value,
    "operationalLogFireFlag" : this.rdcResultForm.get(['rdcmain',index]).get('operationalInLogfire').value,
    "operationalPOFlag": this.rdcResultForm.get(['rdcmain',index]).get('operationalInPO').value,
    "lastUpdatedBy": this.userName,
    "createdDate":"",
    
    "parentShopNumber": this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').value
  }]
    this.loaDer.nativeElement.style.display = 'block';
    this.statusMessage = '';
    this.updateMessage = '';
  this.rdcMaintenanceService.getSaveResponse(saveObj).subscribe(
    (response : any) =>
     { 
     
       if(response.status == "Success"){
          this.statusMessage = "Completed";
          this.updateMessage = response.message;
          // document.getElementById('search').click();
          this.loaDer.nativeElement.style.display = 'none';
      }
      else{
        this.statusMessage = "Error";
        this.updateMessage = response.message;
        this.loaDer.nativeElement.style.display = 'none';
      }
     },(err)=>{
       this.statusMessage = "Error";
       this.updateMessage = err;
       this.loaDer.nativeElement.style.display = 'none';
     }

  );
}
  else{
    console.log('in else');
    this.statusMessage = '';
    this.updateMessage = '';
    alert("Please fill all the mandatory fields");
  }
  }

  resetForm(){
    this.rdcForm = this.buildRDCForm();
    this.rdcResultForm = this.buildRDCResultForm();
    this.isDisplayResult = false;
    this.statusMessage = '';
    this.updateMessage = '';
    this.responseMessage = '';
  }

  private getAbsoluteIndex(index: any) {
    let currentPageNumber = this.currentPage;
    let absoluteIndex = this.itemPerPage * (currentPageNumber - 1) + index;
    return absoluteIndex;
  }
  onClickBack() { this.router.navigate(['']); }
}
