import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AsnUploadService } from './asn-upload.service';
import { importsavevar } from '../../common/import-save-var';
import { Router, RouterModule } from '@angular/router';
import { Batches } from '../../batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import { UtilsService } from '../../common/utils-service';
import { GenerateAsn } from './generate';
import { from } from 'rxjs';
import { isNull } from 'util';
// import { Console } from 'console';
// import { exists } from 'fs';

@Component({
  selector: 'app-asn-upload',
  templateUrl: './asn-upload.component.html',
  styleUrls: ['./asn-upload.component.css']
})
export class AsnUploadComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  profileForm: FormGroup;
  downloadForm: FormGroup;
  public noResults = false;
  uploadFileName = 'No File Chosen';
  statusMessage = '';
  searchText = '';
  responseMessage: string;
  enable = false;
  downloadUrlList: Batches[] = [];
  downloadUrl: string;
  downloadedFile: string;
  checkService = false;
  selVal: string;
  selValError: string;
  userName: string;
  userCode: string;
  UploadMsg: string;
  Selenable = false;

  keys = [
    { name: 'ASN_Vendor_WMS_RDC_Depot', uploadKey: 'SHIP_VENDOR' },
    { name: 'ASN_FactoryWMS680_RDC_Depot', uploadKey: 'SHIP_FACTORY' },
    { name: 'ASN_STORE_RDC_Fresh', uploadKey: 'SHIP_STORETORDC' },
    { name: 'ASN_STORE_RDC_Defective', uploadKey: 'SHIP_STORETORDC' },
    { name: 'ASN_STORE_RDC_Short', uploadKey: 'SHIP_STORETORDC' },
    { name: 'ASN_STORE_RDC_Claim', uploadKey: 'SHIP_STORETORDC' },
    { name: 'VENDOR_SUPPLY', uploadKey: 'SHIP_STORETORDC' },
    { name: 'LEGACY_RDC_DEPOT_SUPPLY', uploadKey: 'SHIP_STORETORDC' }

  ];

  // tslint:disable-next-line: max-line-length
  constructor(private fb: FormBuilder, private _asnUploadService: AsnUploadService, private _router: Router, private httpClient: HttpClient, private _utilsService: UtilsService) {
    this.uploadFileName;
    this.profileForm = this.fb.group({
      name: [''],
      file: [''],
      uploadKey: [''],
      uploadedBy: ['']
    });
  }


  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    const sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail;
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.profileForm.get('uploadedBy').setValue(this.userCode);
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.UploadMsg = '';
      let flag = true;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.keys.length; i++) {
        // const fileNameTrim: any[] = event.target.files[0].name.split('.');
        if (this.keys[i].name ) {
          flag = false;
          this.profileForm.get('uploadKey').setValue(this.keys[i].uploadKey);
        }
      }
      if (flag == false) {
        this.uploadFileName = event.target.files[0].name;
        const file = event.target.files[0];
        this.profileForm.get('file').setValue(file);
      } else {
        this.UploadMsg = 'Please choose correct file';
      }
    }
  }

  onSubmit() {

    this.statusMessage = '';
    this.responseMessage = '';
    if (this.myInputVariable.nativeElement.files.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage = 'error';
      return false;
    } else {
      const newFileArray: any[] = this.profileForm.get('file').value.name.split('.');
      const newFileFormat = newFileArray[0].replace(/\s+/g, '') + '_' + this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
      const formData = new FormData();

      formData.append('name', this.profileForm.get('name').value);
      formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
      formData.append('uploadedBy',this.profileForm.get('uploadedBy').value);
      formData.append('uploadKey', this.selVal);

      this.loaDer.nativeElement.style.display = 'block';

      this._asnUploadService.upload(formData).subscribe(
        (response: importsavevar) => {
          this.responseMessage = '';
          this.statusMessage = '';
          if (response.status == 'success') {
            this.responseMessage = 'File is uploaded successfully, please click on Generate ASN.';
            this.statusMessage = 'success';
            this.enable = true;
          } else {
            this.responseMessage = response.message;
            // this.responseMessage.replace(/\\n/g, '<br/>');
            this.statusMessage = 'error';
            this.enable = false;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.responseMessage = err;
          // this.responseMessage.replace(/\\n/g, '<br/>');
          this.statusMessage = 'error';
          this.enable = false;
        }
      );
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  resetSearchPurchaseOrderResults() {
    this.uploadFileName = 'No File Chosen';
    this.responseMessage = '';
    this.statusMessage = '';
    this.UploadMsg = '';
    this.enable = false;
    this.loaDer.nativeElement.style.display = 'none';
    this.myInputVariable.nativeElement.value = '';
  }

  onChange(selectedValue: any) {
    this.selVal = selectedValue;
    console.log(this.selVal);
    if ((this.selVal != 'null')) {
      this.Selenable = true;
    } else {
      this.Selenable = false;
    }
    this.keys.filter(cs => cs.name == selectedValue).forEach(
      obj => {
        this.profileForm.get('uploadKey').setValue(obj.uploadKey);
      }
    );
  }

  public async downloadTemplate(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    this.UploadMsg = '';
    this._asnUploadService.getDownloadUrl().subscribe(
      (res: BatchesData) => {
        this.downloadUrlList = res['configParamModels'];
        console.log(this.downloadUrlList);
        this.downloadUrlList.forEach(element => {
          if (element.paramName == this.selVal) {
            this.downloadUrl = element.paramValue;
            console.log(this.downloadUrl);
            this.getDownloadFile(this.downloadUrl);
            return this.checkService = true;
          } 
          
        });
      }
    );
  }

  public async getDownloadFile(downloadUrl): Promise<void> {
    if (this.selVal == 'null' || this.selVal == undefined) {

      this.selValError = 'Please Select';
    } else {
      this.selValError = '';

      const fullDownloadUrl: string = downloadUrl + '' + this.selVal + '.xls';
      const file = await this.httpClient.get<Blob>(downloadUrl, { responseType: 'blob' as 'json' }).toPromise();
      const blob = file;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = this.selVal + '.xls';
      anchor.href = url;
      anchor.click();
      window.URL.revokeObjectURL(url);
      this.loaDer.nativeElement.style.display = 'none';

    }
  }

  onGenerate() {
    console.log('generating start --------');
    this.loaDer.nativeElement.style.display = 'block';
    this.statusMessage = '';
    this.responseMessage = '';
    this.UploadMsg = '';

    const uploadkeyValues: any = {
      uploadKeyValues: this.selVal,
      uploadedBy: this.profileForm.get('uploadedBy').value
    };
   //console.log(uploadkeyValues);
    
   this._asnUploadService.getGenerate(uploadkeyValues).subscribe(
      (response: GenerateAsn) => {
        console.log(response);
        
        if (response.status == 'Success') {
          this.responseMessage = 'ASN generation successfully initiated';
         // console.log(response.message);
          
          this.statusMessage = 'Success';
          
        } else {
          this.responseMessage = response.message;
          // this.responseMessage.replace(/\\n/g, '<br/>');
          this.statusMessage = 'error';
         
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.responseMessage = err;
        // this.responseMessage.replace(/\\n/g, '<br/>');
        this.statusMessage = 'error';
      }
    );
    this.enable = false;
  }


}
