import { Injectable } from '@angular/core';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreateRoleScreenAccessService {
  // user_detail_url: string = BilApiUrl.USER_DETAIL_URl;
  // saving_create_user_url: string = BilApiUrl.CREATE_USER_GROUP;
  user_groupList_url : string = RdsBilApiUrl.USER_GROUP_LIST;
  screenList_url: string = RdsBilApiUrl.SCREEN_LIST;
  accessList_url: string = RdsBilApiUrl.ACCESS_LIST;
  add_roleScreenAccess_url: string=RdsBilApiUrl.SAVE_BILLGROUP_SCREEN_DETAILS;
  constructor(private _http: HttpClient) { }

  // getUserDetails(details) {
  //   console.log('passing data' + JSON.stringify(details));
  //   return this._http.post(this.user_detail_url, details);
  // }

  // saveUserDetails(userConceptsPassingList) {
  //   console.log('passing data' + JSON.stringify(userConceptsPassingList));
  //   return this._http.post(this.saving_create_user_url, userConceptsPassingList);
  // }

  getGroupList(groupList){
    console.log(groupList);
    return this._http.post(this.user_groupList_url, groupList);
  }
  getScreenList(screenList){
    console.log(screenList);
    return this._http.post(this.screenList_url, screenList);
  }
  getAccessList(accessList){
    console.log(accessList);
    return this._http.post(this.accessList_url, accessList);
  }
 
  saveBilGroupScreenDetails(add_roleScreenAccess_param){
    console.log(add_roleScreenAccess_param);
    // console.log(JSON.parse(add_roleScreenAccess_param));
    return this._http.post(this.add_roleScreenAccess_url,add_roleScreenAccess_param);
  }

  
}      
