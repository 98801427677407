import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-gstr21',
  templateUrl: './import-gstr21.component.html',
  styleUrls: ['./import-gstr21.component.css']
})
export class ImportGstr21Component implements OnInit {
  uploadFormDetails:any = {
    "screenName":"Import GSTR_2.1 Upload",
    "batchName":"Import GSTR_2.1",
    "bucketFileName":"Import_GSTR_Template.xls",
    "lovData":[
      {
        name:"Please Select", key: null , bucketFileName: null
      },
      {
        name:"Import GSTR 2.1", key: "Import_GSTR_Upload", bucketFileName: "Import_GSTR_Template.xls"
      }
    ]
  }
  constructor() { }

  ngOnInit(): void {
  }

}
