import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { SearchForm } from './search-form';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class SearchInvoiceService {

  invoice_search_url: string = RdsBilApiUrl.RDC_SEARCH_INVOICE;
  transporter_name: string = RdsBilApiUrl.TRANSPORTER_NAME_FETCH;
  config_details_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  shipmentCreationUrl:string = RdsBilApiUrl.SHIPMENT_CREATION_URL;
  searchURL: string = RdsBilApiUrl.SEARCH_FREIGHT_MASTER;
  shop_location_url: string = RdsBilApiUrl.SEARCH_LOCATION_URL;
  RouteFetchURL: string = RdsBilApiUrl.SEARCH_ROUTE_MASTER;

  constructor(private http: HttpClient) { }

  getInvoiceBySearchCriteria(invoiceNum) {
    console.log(invoiceNum);
    return this.http.post(this.invoice_search_url, invoiceNum);

  }
  getTransporterName() {
//     const details = [{
//     'name': 'isSecondary',
//     'value': 'Y'
// }]
// {
//       'name': 'isPrimary',
//       'value': 'Y'
//   },
const details =[{

  "configList": [
  // {
  // "name": "isPrimary",
  // "value": "Y"
  // },
  {
    "name": "isSecondary",
     "value": "Y"
 }]
  }
  
  ]
    return this.http.post(this.transporter_name, details);
  }


  getStatus() {
    const details: any = [{
      'name': 'paramName',
      'value': 'INVOICE_STATUS'
  }]
    return this.http.post(this.config_details_url, details);
  }
  asnCreation(details: any) {
    console.log('updating parameter' + JSON.stringify(details));
    return this.http.post(this.shipmentCreationUrl, details, {
      observe: 'response',
      responseType: 'text'
    });
  }
  getRatePerCarton(details:any){
    return this.http.post(this.searchURL,details);
  }
  getShopCode(details){
    return this.http.post(this.shop_location_url,details);
  }
  getRouteNumber(deatils:any){
    return this.http.post(this.RouteFetchURL,deatils);
  }
  
}
