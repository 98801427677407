import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { ResupplydetailModel } from './resupply-detailmodel';
// import { AsnLines } from '../asn-lines';
import { NavigationLinkParameterService } from '../../../common/navigation-link-parameters-service';
import { ResupplyDetailService } from './resupply-detail.service';
import { ASNDetailResponseModel } from '../../../common/asn-detail-model';
import { DatePipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
// import { GinNumber } from '../gin-model-model';
import { CreateGINModel } from './create-gin-model';
import { LoginComponent } from '@/login/login.component';
import { ResupplyService } from "../resupply.service";
import { ResupplyHeader } from "../resupply-header";
import { UpdateAssortment } from "../update-assortment";
type NewType = number;

@Component({
  selector: 'app-resupply-detail',
  templateUrl: './resupply-detail.component.html',
  styleUrls: [
    "../../../../assets/css/bootstrap.css",
    "../../../../assets/css/rds-bil-style.css",
    "../../../../../node_modules/font-awesome/css/font-awesome.css",
    "./resupply-detail.component.css",
  ],
})
export class ResupplyDetailComponent implements OnInit {
  @ViewChild('loaDer', { static: true }) loaDer: ElementRef;
  @ViewChild('closeBtn', {static: false}) closeBtn: ElementRef;
  searchedASNDetails: any;
  searchASNLineDetails: any[] = [];
  myDate: Date = new Date();
  // ginData: AsnLines[] = [];
  responsemessage: string;
  statusMessage=false;
  // ginNumberModel: GinNumber[] = [];
  ginCartonRecived = new CreateGINModel();
  eachObj = [];
  total = 0;
  shortageCartonQty: NewType = 0;
  disableBtn = true;
  totalInv: number;
  sumOfInvoice = 0;
  totalInvAmut: any = 0;
  dataRefresher: any;
  cartonDetail: any;
  challanId: any;
  artnumber: any;
  assortData: any = {};
  assortData1: any = {};
  conceptQueryMas: string;
  totalInvoiceValHeader: string;
  articleFirstDigit: any;
  groupCode: any;
  articleMaster: any[] = [];
  supNo: any;
  manualGrn: any;
  supplyNo: any;
  artNum: any;
  value: any;
  arrayManualGrn:any[]=[];
  grantSum: any;
  sumofArt : any;
  assortListFull:any = {};
  assortList: any = {};
  assortcombine: any[] = [];
  assortCombineFull: any[] =[];
  sucessMsgGrn: any;
  grnWiseSum1: any[] = [];
  savePayload: any;
  dispatchManualMode: any;

  isValid: boolean = false;
  sucessMsg: string;
  isFulfullQtyEnable: boolean[] = [];
  isEnabledButtonEnabled: boolean[] = [];
  isUpdateButtonEnable: boolean = false;
  assortmentDataList: any[] = [];
  articleNoOnly: ResupplyHeader[] = [];
  arr: any[];
  resupplyStat: string;
  count: number = 0;
  saveAssortment = new UpdateAssortment();
  saveAssortmentList: UpdateAssortment[] = [];
  erroeForFulfill: string;
  itnRsnId: any;
  assortArray: any[];
  filterResupplyParent: ResupplyHeader[] = [];
  headerData:any;

  searchType='line';
  currentPage:number=1;
  itemsPerPage:number=4999;
  totalItems:any;

  dispatchMode:boolean=false;
  cancelResupShow:boolean=false;
  invoiceQty:any[]= [];
  artInvoiceQty:any=[];
  assortmentInvoiceQty:any[]=[];
  artPos:any;
  disableBtnInvoice: Boolean= true;
  disableBtnCancel: Boolean = true;
  curresupplyStatus: Boolean = true;
  checkConsumable: Boolean = false;
  manualAssortmentInvoiceQty=[];
  // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: variable-name
  constructor( private navigationLinkParameterService: NavigationLinkParameterService,
               // tslint:disable-next-line: variable-name
               private ResupplyDetailService: ResupplyDetailService,
               private _resupplyService: ResupplyService,
               private datePipe: DatePipe,
               // tslint:disable-next-line: variable-name
               private router: Router) { }

  ngOnInit() {
    this.artPos="";
    const sessUsername = sessionStorage.getItem('currentUser');
    this.dispatchMode=false;
    this.conceptQueryMas = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
        this.searchedASNDetails = this.navigationLinkParameterService.challanResupDetails;
        console.log(this.searchedASNDetails);
        this.getResupplyData();
    //     let d={
    //       "itnRsnSerialNumber": this.searchedASNDetails.itnRsnSerialNumber,
    //       "searchType":this.searchType,
    //       "pageSize":this.itemsPerPage,
    //       "pageNumber":this.currentPage
    //     }
    //     this._resupplyService.getResupplySearchCriteria(d).subscribe(
    //      (response: any) => {
    //         this.searchASNLineDetails = response.dataList;
    //         this.totalItems=response.recordCount;
    //         //this.searchedASNDetails.asnStatus = this.searchASNLineDetails[0].asnStatus;
    //         // this.loaDer.nativeElement.style.display = 'none';

    //         for (const data of this.searchASNLineDetails) {
    //             this.total  += data.cartonNumber;
    //             // tslint:disable-next-line: no-string-literal
    //             this.totalInvAmut   += data['invoiceValue'];
    //             this.totalInvoiceValHeader=data['invoiceValue'];
    //        }
    //     }
    //  );

     
     this.navigationLinkParameterService.asndetails = '';

     if (this.navigationLinkParameterService.searchResupHeaderData === undefined) {
      console.log('New Search has to be made');
      //this.loaDer.nativeElement.style.display = 'none';
      } else {
        console.log('Automatic Search will be performed');
        this.headerData = this.navigationLinkParameterService.searchResupHeaderData;
        this.navigationLinkParameterService.searchResupHeaderData = undefined;
        // this.uniques = this.navigationLinkParameterService.searchasnHeaderData.resultData;
        //  this.navigationLinkParameterService.searchasnHeaderData = undefined;
        // console.log(this.searchASNCriteriaList);
        // this.searchAsnDataWithValues(this.searchASNCriteriaList);
        // this.searchPayload();
       
      }
  }

getResupplyData(){
  this.curresupplyStatus=true;
  let d={
    "itnRsnSerialNumber": this.searchedASNDetails.itnRsnSerialNumber,
    "sourceShopNumber": this.searchedASNDetails.sourceShopNumber,
    "destShopNumber": this.searchedASNDetails.destShopNumber,
    "searchType":this.searchType,
    "pageSize":this.itemsPerPage,
    "pageNumber":this.currentPage
  }
  this._resupplyService.getResupplySearchCriteria(d).subscribe(
   (response: any) => {
      this.searchASNLineDetails = response.dataList;
      // let dm=this.searchASNLineDetails[0].dispatchMode;
      
      // if(dm.charAt(0)==='C'){
      //   this.dispatchMode=true;
      // }else{
      //   this.dispatchMode=false;
      // }
     

      this.totalItems=response.recordCount;
      //this.searchedASNDetails.asnStatus = this.searchASNLineDetails[0].asnStatus;
      // this.loaDer.nativeElement.style.display = 'none';

      for (const data of this.searchASNLineDetails) {
        let dm=data.dispatchMode;
        
          if(dm.charAt(0)==='C' && this.checkConsumable == false){
            this.checkConsumable=true;
          }
        
          this.total  += data.cartonNumber;
          // tslint:disable-next-line: no-string-literal
          this.totalInvAmut   += data['invoiceValue'];
          this.totalInvoiceValHeader=data['invoiceValue'];
          
          this.invoiceQty.push(0);
          let bQty=this.Total(data['resupplyArtTotalQuantity'],data['resupplyArtFulfilledQuantity'],data['resupplyArtCancelQuantity']);
          if(bQty>0){
            this.disableBtnCancel=false;
          }
          if(data.resupplyStatus == 'OPEN' && this.curresupplyStatus == true){
            this.curresupplyStatus=true;
          }else{
            this.curresupplyStatus=false;
          }
     }
     if(this.checkConsumable == true){
      this.dispatchMode=true;
     }else{
       this.dispatchMode=false;
     }
      //Status open for all article line 
      // (dm.charAt(0)==='N' || dm.charAt(0)==='F')
      if(this.checkConsumable == false && this.searchASNLineDetails[0].stockType == 'FRESH' &&  this.curresupplyStatus == true){
        var dmLen=this.searchASNLineDetails[0].dispatchMode.length;
        if(this.searchASNLineDetails[0].resupplyType=='RESUP' && this.searchASNLineDetails[0].dispatchMode.charAt(dmLen-1)==='A'){
          this.dispatchManualMode=false;
        }else if(this.searchASNLineDetails[0].resupplyType=='RESUP' && this.searchASNLineDetails[0].dispatchMode.charAt(dmLen-1)==='M'){
          this.dispatchManualMode=true;
          this.cancelResupShow=true;
        }else{
          this.dispatchManualMode=true;
        }
        
      }else{
        this.dispatchManualMode=false;
      }
  }
);
}
// tslint:disable-next-line: member-ordering
private setting = {
  element: {
    dynamicDownload: null as HTMLElement
  }
};


downloadTextFile() {
  this.ResupplyDetailService.fakeValidateUserData().subscribe((res) => {
    this.dyanmicDownloadByHtmlTag({
      // tslint:disable-next-line: max-line-length
      fileName: 'INV_2D_' + this.searchedASNDetails.supplyNo + '_' + this.datePipe.transform(this.myDate, 'ddMMyyyHHmmss') + '.txt' ,
      text: ''
    });
  });

}

getPage(page){
  this.currentPage=page;
 //  this.setAsnByAsnSearchCriteria(this.searchASNCriteriaList);
 //console.log(this.searchASNCriteriaList);
  this.getResupplyData();

}

private dyanmicDownloadByHtmlTag(arg: {
  fileName: string,
  text: string
}) {
  if (!this.setting.element.dynamicDownload) {
    this.setting.element.dynamicDownload = document.createElement('a');
  }
  const element = this.setting.element.dynamicDownload;
  const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
  element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
  element.setAttribute('download', arg.fileName);

  const event = new MouseEvent('click');
  element.dispatchEvent(event);
}


// OnClickCreateGin() {
//   this.ginData = [];
//   this.shortageCartonQty = (this.total - this.ginCartonRecived.receivedCartonQty);
//   // this.searchASNLineDetails.forEach(
//   //  eachObj => {

//   const newObj: any = new CreateGINModel();
//   newObj.invoiceNumber = this.searchedASNDetails.supplyNo;
//   newObj.shopNumber = this.searchedASNDetails.shopNumber;
//   newObj.asnNumber = this.searchedASNDetails.asnNumber;
//   newObj.shippedCartonQty = this.total;
//   newObj.receivedCartonQty  = this.ginCartonRecived.receivedCartonQty;
//   newObj.shortageCartonQty = this.shortageCartonQty;
//   newObj.consignorCode = this.searchedASNDetails.consignorCode;
//   newObj.consignorName = this.searchedASNDetails.consignorName;
//   newObj.consigneeCode = this.searchedASNDetails.consigneeCode;
//   newObj.consigneeName = this.searchedASNDetails.consigneeName;
//   newObj.ewayBillNumber = this.searchedASNDetails.ewayBillNumber;
//   newObj.lastUpdatedBy =  this.searchedASNDetails.lastUpdatedBy;
//   newObj.invoiceValue =  this.searchedASNDetails.invoiceValue;
//   newObj.challanInvoiceDT =  this.searchedASNDetails.challanInvoiceDT;
//   this.ginData.push(newObj);

//   console.log(JSON.stringify(this.ginData));
//   this._asnDetailService.addpost(this.ginData).subscribe(
//     (res: any) => {
//       // tslint:disable-next-line: triple-equals
//       if (res.status == 'SUCCESS') {

//       this.responsemessage = res.message;
//       this.disableBtn = false;
//       this.refreshData();
//     } else {
//       this.responsemessage = 'Gin is already created!';
//     }

//       this._asnDetailService.getGinNo(this.searchedASNDetails.supplyNo, this.searchedASNDetails.consignorCode).subscribe(
//     // tslint:disable-next-line: no-shadowed-variable
//     (res: any) => {
//       this.ginNumberModel = res.dataList;
//     }

//   );
// });
// }


onClickBack() {
  this.navigationLinkParameterService.searchResupHeaderData=this.headerData;
  this.router.navigate(['rds-resupply-status']);
}


refreshData() {
  console.log('Page Refresh');

  this.dataRefresher =
   setTimeout(() => {
      this.ngOnInit();
      // Passing the false flag would prevent page reset to 1 and hinder user interaction
    }, 100);
}

setASNAssortmentDataPopup(artnumber) {
  this.artnumber = artnumber;
  const index = this.searchASNLineDetails.findIndex(
      (x) => x.artNumber == artnumber
    );
  if (index != -1) {
      this.assortData = this.searchASNLineDetails[index];
     
    }

  this.articleFirstDigit = this.artnumber.toString().slice(0,1); 
  // console.log(this.articleFirstDigit);

  this.ResupplyDetailService.getSizeMaster(this.articleFirstDigit).subscribe(
    (response: any) => {
      this.articleMaster = response.dataList;
      this.groupCode = response.dataList[index]; 
      console.log(this.articleMaster);
      console.log(this.groupCode);
      
    }
    );
}


manualGRNPage(SupplyNo) {
  console.log(SupplyNo);
  
  let selectInd: any;
  this.searchASNLineDetails.filter(cs => cs.supplyNo === SupplyNo).forEach(
    obj => {
      selectInd = obj;
     }
  );
  console.log(selectInd);
  
  this.navigationLinkParameterService.supplyNo = selectInd;
  // this.navigationLinkParameterService.searchasnHeaderData = this.searchPayload();
  this.router.navigate(['/manual-grn']);

  }

  searchPayload() {
    const searchNavigationHold = {
      //searchData: this.searchASNCriteriaList,
      resultData: this.searchASNLineDetails
    };
    return searchNavigationHold;
  }

  savePairQty(articleN){
    //this.enable12 = false;
    console.log(articleN);
    
    this.loaDer.nativeElement.style.display = 'block';
    let i = 0;
    console.log(this.searchASNLineDetails);
    this.searchASNLineDetails.filter(cs => cs.artNumber === articleN).forEach(( list, indexvalue ) => {
      
      this.assortcombine =[];
 
          list.shipmentAssortment.forEach(element => {
            
            if(element.pairQty != 0 && element.pairQty != null){
                this.assortListFull =   {
                "asnAssortmentId": String(element.asnAssortmentId),
                "asnId" : String(list.asnId), 
                "artSize" : element.articleSize, 
                "pairQuantity" : String(element.pairQty),
                "sizeLabel" : element.sizeLabel, 
                "lastUpdatedBy" : this.conceptQueryMas
                
              };
              this.assortcombine.push(this.assortListFull);
            }

          });

    
   this.savePayload = 
   { 
     "asnNumber" : this.searchedASNDetails.asnNumber,
     "userCode" : this.conceptQueryMas,
     "asnList" : [{
       "asnId": String(list.asnId), 
       "pairQuantity": this.grnWiseSum1,
       "asnAssortmentList": this.assortcombine
     }] 
 
   };
  });

  console.log(this.savePayload);
  
   

   
    this.ResupplyDetailService.saveAsnDetail(this.savePayload).subscribe(
      (response: any) => {
        this.loaDer.nativeElement.style.display = 'none';
        if (response.status === 'Success'){
        this.sucessMsgGrn = response.message;
        }
      }
    )
   }
   

   grnwq(value, articleNo, sizebucket,grnindex) {
    console.log(value);
    console.log(articleNo);
    console.log(sizebucket);
    if (value != null && value != undefined && value != '') {
      this.searchASNLineDetails.filter(cs => cs.artNumber == articleNo).forEach(
        calgrnsum => {
          
          calgrnsum.shipmentAssortment.filter(cs => cs.sizeLabel == sizebucket).forEach(
            checksum => {

              checksum.pairQty = value;
             
              // // this.calculateGrnSum(articleNo,grnindex);
              // this.searchASNLineDetails.filter(cs => cs.artNumber == articleNo).forEach(
              // // this.assortDetail.forEach(
              //   calgrnsum1 => {
             
                checksum.grnWiseSum = 0;
                calgrnsum.shipmentAssortment.forEach(
                    checksum1 => {
                     
                      checksum.grnWiseSum += Number(checksum1.pairQty);
                     });
                  this.grnWiseSum1= checksum.grnWiseSum;
                // })
              
            }
          )
          
          
        }
      )

      console.log(this.grnWiseSum1+'grn');
      //console.log(this.asnWiseSum+'arn');
    }
  }


  closePopup(){
    this.sucessMsgGrn = "";
    this.ngOnInit();
  }
  setResupplyAssortmentDataPopup(
    resupplySerialNo,
    resupplyartNo,
    resupplyStatus,
    itnRsnId,
    dispatchMode,artPos
  ) {
    this.isValid = false;
    this.sucessMsg = "";
    this.isEnabledButtonEnabled = [];
    this.isFulfullQtyEnable = [];
    this.isUpdateButtonEnable = false;
    this.assortmentDataList = [];
    this.articleNoOnly = [];
    this.arr = [];
    this.count = 0;
    this.erroeForFulfill = "";
    this.saveAssortment = new UpdateAssortment();
    this.saveAssortment.articleNumber = resupplyartNo;
    this.saveAssortment.reSupSerialNumber = resupplySerialNo;
    this.resupplyStat = resupplyStatus;
    this.itnRsnId = itnRsnId;
    console.log(dispatchMode);
    console.log(dispatchMode.charAt(0));
    if(dispatchMode.charAt(0)==='C'){
      this.dispatchMode=true;
    }else{
      this.dispatchMode=false;
    }
    this.artPos=artPos;
    // console.log(this.itnRsnId);
    if (resupplyStatus != "PROCESSED") {
      this.isEnabledButtonEnabled[i] = true;
      this.erroeForFulfill =
        "Update fulfill Quanity only for OPEN and PROCESSED";
    }
    //updated by debamala dated 31-7-20
    // this._resupplyService
    //   .getResupplyAssortmentPopup(resupplySerialNo, resupplyartNo)
    //   .subscribe((res: any) => {
    //     this.articleNoOnly = res.dataList;
    //     this.assortmentDataList = res.dataList[0].reSupplyItnAssormentModelList;
    //   });

    //updated by priti dated 01-10-20
    let firstletter = resupplyartNo.charAt(0);
    this.assortArray = [];
    this.artInvoiceQty=[];
    this.assortmentInvoiceQty[resupplyartNo]=[];
    //updated by priti dated 01-10-20
    let index = this.searchASNLineDetails.findIndex(
      (x) => x.itnRsnId == itnRsnId
    );
    if (index != -1) {
      this.assortData = this.searchASNLineDetails[index];
      //updated by priti dated 01-10-20
      let details = [{ "name": "letterOfArticle", "value": firstletter }];
      this._resupplyService.getSizeDetails(details).subscribe(
        (response: any) => {
          if (response.status == "Success") {
            this.groupCode = response.dataList[0].groupCode;
            this.assortArray = [];
            let i=0;
            response.dataList.forEach(data => {
              
              let assortmentArray =this.assortData.reSupplyItnAssormentModelList;
              let found = assortmentArray.findIndex(element => element.artSize == data.artSize);
              if (found !== -1) {
                let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWisePairQty: assortmentArray[found].pairQuantity, sizeWiseFulFillQty: assortmentArray[found].fulfilledQuantity, cancelledQuantity:assortmentArray[found].cancelledQuantity};
                this.assortArray.push(assortmentData);
                this.artInvoiceQty.push(0);
                let d={
                  'resupplyItnAssortmentId':assortmentArray[found].resupplyItnAssortmentId,
                  'size': assortmentArray[found].artSize,
                  'sizeBucket':data.sizeBucket,
                  // 'sizeWiseBalQty':this.Total(this.assortData.resupplyArtTotalQuantity,this.assortData.resupplyArtFulfilledQuantity,this.assortData.resupplyArtCancelQuantity),
                  'invoiceQty':0
                }
                this.assortmentInvoiceQty[resupplyartNo].push(d);
                i++;
                // console.log(this.assortArray);
              }
            });
            console.log(this.artInvoiceQty);
            console.log(this.assortmentInvoiceQty);
          }
        },
        (err) => {
          this.assortArray = [];
          this.artInvoiceQty=[];
        }
      );
      //updated by priti dated 01-10-20
    }
    //updated by debamala dated 31-7-20
   
    
    for (var i = 0; i < this.assortmentDataList.length; i++) {
      console.log(resupplyStatus);
      if (resupplyStatus == "PROCESSED" || resupplyStatus == "OPEN") {
        // console.log(this.isEnabledButtonEnabled[i]);
        this.isEnabledButtonEnabled[i] = false;
      }
    }
  }
  Total(resupplyArtTotalQuantity,resupplyArtFulfilledQuantity,resupplyArtCancelQuantity){
    var orderQty = parseInt((resupplyArtTotalQuantity!=null)?resupplyArtTotalQuantity:0)
    var dispatchQty = parseInt((resupplyArtFulfilledQuantity!=null)?resupplyArtFulfilledQuantity:0)
    var cancelQty = parseInt((resupplyArtCancelQuantity!=null)?resupplyArtCancelQuantity:0)
    var tot = (orderQty - (dispatchQty + cancelQty));
    return tot;
  }
  TotalAssortData(artNumber,pos,resupplyArtTotalQuantity,resupplyArtFulfilledQuantity,resupplyArtCancelQuantity){
    var orderQty = parseInt((resupplyArtTotalQuantity!=null)?resupplyArtTotalQuantity:0)
    var dispatchQty = parseInt((resupplyArtFulfilledQuantity!=null)?resupplyArtFulfilledQuantity:0)
    var cancelQty = parseInt((resupplyArtCancelQuantity!=null)?resupplyArtCancelQuantity:0)
    var tot = (orderQty - (dispatchQty + cancelQty));
    // if(this.dispatchManualMode == true){
    //   this.assortmentInvoiceQty[artNumber][pos]['invoiceQty']=tot;
    // }
    // console.log(this.assortmentInvoiceQty);
    return tot;
  }
  checkVal(event,artNumber,pos,resupplyArtTotalQuantity,resupplyArtFulfilledQuantity,resupplyArtCancelQuantity){
    console.log(pos);
    console.log(event.target.value);
    var orderQty = parseInt((resupplyArtTotalQuantity!=null)?resupplyArtTotalQuantity:0)
    var dispatchQty = parseInt((resupplyArtFulfilledQuantity!=null)?resupplyArtFulfilledQuantity:0)
    var cancelQty = parseInt((resupplyArtCancelQuantity!=null)?resupplyArtCancelQuantity:0)
    var tot = (orderQty - (dispatchQty + cancelQty));
    if(parseInt(event.target.value)>tot || event.target.value == ""){
      event.target.value=0;
    }
      this.artInvoiceQty[pos]=parseInt(event.target.value);
      this.assortmentInvoiceQty[artNumber][pos]['invoiceQty']=parseInt(event.target.value);
      console.log(this.artInvoiceQty);
      console.log(this.assortmentInvoiceQty);
  }
  resetInvoice(){
    this.assortmentInvoiceQty=[];
    this.disableBtnInvoice=true;
    this.searchASNLineDetails.forEach((data,index)=> {
      this.invoiceQty[index]=0;
    }
    )
  }
  cancelResupply(itnRsnSerialNumber){
    
    this.dispatchMode=false;
    this.loaDer.nativeElement.style.display = 'block';
    let details = { "userCode": this.conceptQueryMas,"itnRsnSerialNumber": itnRsnSerialNumber };
    console.log(details);
      this._resupplyService.cancelResupply(details).subscribe(
        (response: any) => {
          if(response.status !=='Success'){
            this.dispatchMode=true;
          }
          this.responsemessage = response.message;
          this.onClickBack();
          this.statusMessage=true;
        },
        (err) => {
          this.loaDer.nativeElement.style.display = 'none';
          this.responsemessage = err;
          this.statusMessage=false;
          
        }
      );

  }
  sumOfInvoiceQty(artPos){
    let sum=0;
    this.artInvoiceQty.forEach(
      val => {
        sum=sum+val;
      }
    );
    this.invoiceQty[artPos]=sum;
    this.invoiceQty.forEach(element => {
      if(element>0)
      {
        this.disableBtnInvoice=false;
        return;
      }
    });
    this.closeBtn.nativeElement.click();
  }
  manualDispatch(createManualDispatchFlag){
    this.disableBtnInvoice=true;
    this.dispatchManualMode=false;
    this.loaDer.nativeElement.style.display = 'block';
    console.log(this.searchedASNDetails.itnRsnSerialNumber);
    console.log(this.searchASNLineDetails);
    console.log(this.invoiceQty);
    console.log(this.assortmentInvoiceQty);
    let itnRsnList=[];
    // this.dispatchMode=false;
    
    this.searchASNLineDetails.forEach((val,index) => {
     if(createManualDispatchFlag == 'A') {
      if(this.invoiceQty[index]>0){
          let details ={
            "itnRsnId": val.itnRsnId,
            "itnRsnSerialNumber": val.itnRsnSerialNumber,
            "reSupplyItnAssormentModelList": this.assortmentInvoiceQty[val.artNumber]
          }
          itnRsnList.push(details);
        }
      }else{
        console.log(this.searchASNLineDetails[index].reSupplyItnAssormentModelList);
        this.manualAssortmentInvoiceQty=[];
        this.searchASNLineDetails[index].reSupplyItnAssormentModelList.forEach((value,indx) => {
          if(value.artSize != null && value.pairQuantity >0){
            let d1={
              'resupplyItnAssortmentId':value.resupplyItnAssortmentId,
              'size': value.artSize,
              'sizeBucket':value.sizeBucket,
              'invoiceQty':value.pairQuantity
            }
            console.log(d1);
            this.manualAssortmentInvoiceQty.push(d1);
          }
          
          console.log(this.manualAssortmentInvoiceQty);
        });
          let details ={
            "itnRsnId": val.itnRsnId,
            "itnRsnSerialNumber": val.itnRsnSerialNumber,
            "reSupplyItnAssormentModelList": this.manualAssortmentInvoiceQty
          }
          console.log(details);
          itnRsnList.push(details);
      }
    });
    let dataSet={
      "userCode":this.conceptQueryMas,
      "itnRsnList":itnRsnList,
      "manualAutoFlag":createManualDispatchFlag

    };
    console.log(dataSet);
  
    this._resupplyService.manualDispatch(dataSet).subscribe(
      (response: any) => {
        if(response.status =='Success'){
          this.dispatchMode=false;
          // this.getResupplyData();
          this.responsemessage = "Manual dispatch process is invoked, please check batch status to know the status.";
          this.statusMessage=false;
        }else{
          this.dispatchMode=false;
          this.loaDer.nativeElement.style.display = 'none';
          this.disableBtnInvoice=false;
          this.dispatchManualMode=true;
          this.responsemessage=response.message;
          this.statusMessage=true;
        }
        
        
        // this.onClickBack();
      },
      (err) => {
        console.log(err);
        this.dispatchMode=false;
        this.loaDer.nativeElement.style.display = 'none';
        this.responsemessage = err.message;
        this.disableBtnInvoice=false;
        this.dispatchManualMode=true;
        this.statusMessage=false;
      }
    );
  }
}

