import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { RequisitionLines } from '../search-requisition/requisition-lines';
import { DistributionDetails } from './DistributionDetails';
import { DistributionDetailMultipleService } from './distribution-detail-multiple.service';
import { Location } from './location';
import { PackingMaster } from './packing-master';
import { RETAIL_DIVISION, NON_RETAIL_DIVISION, LOCATION_DIVISION_PARAM, RETAIL_DIVISION_VALUE, NON_RETAIL_DIVISION_VALUE, EXPORT_DIVISION, SOLID, ASSORTED, WHOLE_SALE, ASSORTED_CODE, SOLID_CODE, WHOLE_SALE_CODE, USER_ROLE_ORDERING } from '../common/field-name-list';
import { ReuisitionDistributionDetails } from './requsition-distribution-details';
import { ReuisitionDistributionDetailsPanel } from './requisition-distribution-details-panel';
import { RequisitionAssortment } from './requisition-assortment';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { EditablePackingAssortments } from './ediatable-packing-assortments';
import { EditablePackingAssortmentsModel } from './editable-package-assortments-model';
import { DynamicPackingMaster } from './dynamic-packing-master';
import { DynamicPackingMasterList } from './dynamic-packing-master-data-list';
import { DynamicPackingMasterAssortment } from './dymanic-packing-master-assortment';
import { npost } from 'q';
import { PackingMasterResponse } from './packing-master-response';
import { Observable, Subject } from 'rxjs';
import { distinct, debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-distribution-detail-multiple',
  templateUrl: './distribution-detail-multiple.component.html',
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css','./distribution-detail-multiple.component.css']
})
export class DistributionDetailMultipleComponent implements OnInit {
  deleteDisable: boolean = true;

  distributionPageTitle: string;
  distributionPageData: any;
  isPreviousPageRequisition: boolean;
  searchDisrtibutionDetails: RequisitionLines;

  distributionDetailsModel: DistributionDetails;

  locations: Location[] = [];
  packingMasterList: PackingMaster[];
  displayDefaultValPackMaster: boolean = true;
  displayDefaultValPair: boolean = true;
  packingAssortmentDetailsDynamicList: PackingMaster[] = [];
  isOrderQuantityCalculated: boolean = false; // used in calculateOrderQuantity() method
  totalPackAssortments: number;
  totalParis: number;
  cartonQty: number;
  numberCarton: number;
  assortmentCode: string;
  distributionOrderQty: number;
  selectedCategoryCode: string;
  selectedAssortmentType: string;
  selectedAssortmentTypeId: string;
  selectedShopCode: string;
  selectedShopName: string;
  selectedLocation: any;
  totalArtQuantity: string;
  requisitionAssortments: RequisitionAssortment[] = [];
  selectedAssortmentIndex: any;
  responseMessage: string;
  saveDisabled: boolean = false;
  locationArray: Location[] = [];
  assortmentTypes: any[];
  assortment_index: any;
  editablePackageAssortmentsArray: number[];
  distributionDetailsForm: FormGroup;
  selectedPopupIndex: number = 0;
  displaySolidAssortments: boolean; // set in onAssortmentTypesValueChanges
  assortmentTypeVal: string;
  groupCodeArray: string[];
  dataList: DynamicPackingMasterList[];
  packingMasterAssortmentsDTO: DynamicPackingMasterAssortment[];
  sizepackingassortment: any;
  sizelabelArray: any;
  artSizeArray: any;
  selectedSizelabelPopup: any;
  fakeArray: any;
  packingMaster: any;
  sizeBucketCount: number;
  isPrime: boolean;
  isPairDivisible: boolean;
  isTotalPairDistributionOrderQtyEqual: boolean;
  isdistributionRequsitionQuantityGreaterRequisitionQuantity: boolean;
  primeErrMssg: string;
  sizeWisePairErrMssg: string;
  pairDistOrdErerrMssg: string;
  hasDuplicateDistibutionErrMsg: string;
  orderQuantityGreaterRequisitionQuantityErrMsg: string;
  distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg: string;
  distributionRequsitionQuantityEmptyErrMsg: string;
  distributionOrderQuantityEmptyErrMsg: string;
  pairPerCartonRatioEmptyErrMsg: string;
  flag: any;
  rdcCodeAssortmentTypeArray: any;
  distributionSaveButtonForm: FormGroup;
  sessUsername: string;
  orderedQuantity: number = 0;
  distReqQuantity: number = 0;// Balance logic change
  requisitionQuantity: number = 0;
  displayLoaderWhenAssortmentTypeWholeSale: boolean = false;
  loadingSpinnerMsg: string;
  hasDuplicate: boolean;
  distReqQtyGreaterThanOrderQty: boolean;
  dispilayCalcBtnManualSolid: boolean;
  ShowSave: number = 0;
  distibutionOrderQtyErrMsg: string;
  assormentMessage:string;
  sizeWiseArticleArray = [];
  requisitionSizeArr: any[];
  sizeWiseQtyArray = [];
  sizeWisePndgArray=[];
  
  constructor(private _distributionDetailService: DistributionDetailMultipleService, private _route: ActivatedRoute,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private fb: FormBuilder
  ) {
    this.distributionDetailsForm = this.fb.group({
      assortments: this.fb.array([])
    });
    this.distributionSaveButtonForm = this.buildDistributionSaveForm();
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    let control = <FormArray>this.distributionDetailsForm.controls.assortments;
    //let saveDistributionControl = 

    let distributionDetails: any = { distributionPageTitle: '', distributionPageDataArray: [], isPreviousPageRequisition: false }

    distributionDetails = this._navigationLinkParameterService.distributionDetails;
    this.distributionPageTitle = distributionDetails.distributionPageTitle;
    //searchDisrtibutionDetails is Searched Requisition Lines Data passed from RequisitionDetails page
    this.searchDisrtibutionDetails = distributionDetails.distributionPageDataArray[0];
    this.isPreviousPageRequisition = distributionDetails.isPreviousPageRequisition;
    this.selectedCategoryCode = this.searchDisrtibutionDetails.categoryNo;
    //Convert string reqQty to Number requisitionQuantity type 
    this.requisitionQuantity = +this.searchDisrtibutionDetails.reqQty;
    let artNumberStr = '';
    if (this.searchDisrtibutionDetails != null && this.searchDisrtibutionDetails.artNumber != null) {
      artNumberStr = this.searchDisrtibutionDetails.artNumber.toString();
    }
    let letterOfArticle: string = this.searchDisrtibutionDetails != null ? artNumberStr.charAt(0) : '';
    let editable_assortment_packages_param = { 'name': 'letterOfArticle', 'value': letterOfArticle };
    this.sizepackingassortment = [];
    this.artSizeArray = [];
    this.groupCodeArray = [];
    this.sizelabelArray = [];
    this._distributionDetailService.getEditableAssortmentPackagesForSolidAssortmentType(editable_assortment_packages_param)
    .subscribe(
      (response) => {
        this.sizepackingassortment.push(response['dataList']);
        this.fakeArray = new Array(this.sizepackingassortment[0].length);
        this.sizeBucketCount = this.sizepackingassortment[0].length;
        var blankDataSet = [];
        for (var j = 0; j < this.sizepackingassortment[0].length; j++) {
          this.groupCodeArray.push(this.sizepackingassortment[0][j].groupCode);
          blankDataSet.push({
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
          this.sizelabelArray.push(this.sizepackingassortment[0][j].artSize);
          this.artSizeArray.push(this.sizepackingassortment[0][j].sizeBucket);
        }
        control.push(this.fb.group({
          locationArray: [null],
          assortmentTypes: [null],
          assortmentCode: [''],
          numberOfCartons: { value: '', disabled: true },
          distributionRequisitionQuantity: [''],
          distributionOrderQuantity: { value: '', disabled: true },
          assortmentCheckBox: { value: false },
          packageAssortments: this.packageAssortments(blankDataSet),
          solid_packageAssortments: this.solid_packageAssortments(blankDataSet)
        }));      
        this.sizeWiseArticle();  
      });
      // for(let i=1; i<20; i++){
      //   this.sizeWiseArticleArray.push(i); 
      // }      
  }

  sizeWiseArticle() {
    if(this.sizepackingassortment[0] != null && this.sizepackingassortment[0].length >0){
      var resultArray = [];
      for(var j = 0; j < this.sizepackingassortment[0].length; j++)
      {
        resultArray.push({
            "ReqAssortmentId": 0,
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }
      this.sizeWiseArticleArray = [];
      this.sizeWiseQtyArray = [];
      this.sizeWisePndgArray=[];
      for (let i = 0; i < resultArray.length; i++) {
        this.sizeWiseArticleArray.push(resultArray[i].artSize); 
      }   
      let requisition_size_param = [{ 'name': 'reqLineId', 'value': this.searchDisrtibutionDetails.reqLineId }]; 
      this._distributionDetailService.getRequisitionSizeInfo(requisition_size_param).subscribe(
        (response:any) => {
          this.requisitionSizeArr = response.dataList;            
          if(this.requisitionSizeArr.length > 0){               
            for(let j=0; j<this.sizeWiseArticleArray.length; j++){  
              let index = this.requisitionSizeArr.findIndex(x => x.sizeLabel === this.sizeWiseArticleArray[j]);
              if(index == -1){ 
                this.sizeWiseQtyArray.push(0);
                this.sizeWisePndgArray.push(0);
              }else{
                this.sizeWiseQtyArray.push(this.requisitionSizeArr[index].pairQuantity);
                this.sizeWisePndgArray.push(this.requisitionSizeArr[index].pairQuantity);
              }
            }
            console.log(this.sizeWiseQtyArray);
          }else{
            for(let j=0; j<this.sizeWiseArticleArray.length; j++){  
              this.sizeWiseQtyArray.push(0);
              this.sizeWisePndgArray.push(0);
            }
          }
        },
        (err:any )=> {
          console.log(err);
          for(let j=0; j<this.sizeWiseArticleArray.length; j++){  
              this.sizeWiseQtyArray.push(0);
              this.sizeWisePndgArray.push(0);
          }

          
        }
      ); 
    }
  }


  buildDistributionSaveForm() {
    let saveButtons = {};
    saveButtons['saveDistributionButton'] = 'Save';
    saveButtons['calculateOrderQuantityButton'] = 'Calculate Order Quantity';
    return this.fb.group(saveButtons);
  }

  sizes(resultArray: { artSize: any; }[]) {
    this.sizeWiseArticleArray = [];
    var size = {};
    size['size0'] = 'Packing Assortment';

    for (let i = 0; i < resultArray.length; i++) {
      let j = i + 1;
      size['size' + j] = resultArray[i].artSize;
      this.sizeWiseArticleArray.push(resultArray[i].artSize); 
    }
    size['totalSize'] = 'Total';

    return this.fb.group(size)
  }

  packingAssortmentRow(resultArray: { pairQty: number; }[]) {
    var packingAssortment = {};
    var totpackingAssortment = 0;
    packingAssortment['packingAssortment0'] = 'Packing Assortment';
    for (let i = 0; i < resultArray.length; i++) {
      let j = i + 1;
      packingAssortment['packingAssortment' + j] = resultArray[i].pairQty;
      totpackingAssortment += resultArray[i].pairQty;
    }
    packingAssortment['totalPackingAssortment'] = totpackingAssortment;

    return this.fb.group(packingAssortment)
  }

  pairRow(resultArray: { totalPairQty: number; }[]) {
    var pair = {};
    var totpair = 0;
    pair['pair0'] = 'Pairs';
    for (let i = 0; i < resultArray.length; i++) {
      let j = i + 1;
      pair['pair' + j] = resultArray[i].totalPairQty;
      totpair += resultArray[i].totalPairQty;
    }
    pair['totalPairs'] = totpair;

    return this.fb.group(pair)
  }

  packageAssortments(blankDataSet: any[]) {
    return this.fb.group({
      sizes: this.sizes(blankDataSet),
      pairRow: this.pairRow(blankDataSet),
      packingAssortmentRow: this.packingAssortmentRow(blankDataSet)
    });
  }

  assortments() {
    return this.distributionDetailsForm.get('assortments') as FormArray;
  }

  ngOnInit() {
    console.log('ngOnInit')
    let conceptCode = this.searchDisrtibutionDetails.conceptCode;
    let division = this.searchDisrtibutionDetails.division;
    let division_value: string = "";
    if (RETAIL_DIVISION.toLowerCase() === division.toLowerCase())
      division_value = RETAIL_DIVISION_VALUE;

    if (NON_RETAIL_DIVISION.toLowerCase() === division.toLowerCase())
      division_value = NON_RETAIL_DIVISION_VALUE;

    this.sessUsername = sessionStorage.getItem('currentUser');
    let location_param: any = [
      { "name": "conceptCode", "value": conceptCode },
      { "name": "shopAgencyCode", "value": division_value },
      { "name": "shopCloseYrWk1", "value": LOCATION_DIVISION_PARAM },
      { "name": "userEmail", "value": JSON.parse(this.sessUsername).groupAssignmentModels[0].userCode }
    ];

    this.assortmentTypes = this.getAssortmentType(division, conceptCode);
    /*let user_conceptCodes = '';
    let groupName = JSON.parse(this.sessUsername)[0].groupName;
    user_conceptCodes = JSON.parse(this.sessUsername)[0].concept;
    if (USER_ROLE_ORDERING == groupName) {
      if (user_conceptCodes.includes(conceptCode)) {
        this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      } else {
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      }
    } else {
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    }*/

    let sessUsername = sessionStorage.getItem('currentUser');
    let userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    let coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    var iof = coCode.indexOf(conceptCode);
    let userRoleIndex = userRole.indexOf('Ordering') || userRole.indexOf('IT');
    let userRoleIndexOrdering = userRole.indexOf('Ordering');
    let userRoleIndexIT = userRole.indexOf('IT');
    if ((userRoleIndexOrdering>=0 || userRoleIndexIT>=0) && iof >= 0) {
      this.ShowSave = 1;
    }
    else {
      this.ShowSave = 0;
    }
    this.fetchLocation(location_param);
    this.dispilayCalcBtnManualSolid = true;
  }

  fetchLocation(location_param: any) {
    this._distributionDetailService.getLocations(location_param).subscribe(
      (response: any) => {
        this.locationArray = response.dataList;
        this.validateDistributionReqQty();
      }
    );
    return this.locationArray;
  }

  validateDistributionReqQty() {
    console.log('  validateDistributionReqQty ');//this.selectedPopupIndex 

    this.calculateBalance();
    let flag = this.checkRequisitionDistributionGreaterThanRequisitionOrder();
    console.log("flag>>>>" + flag);
    if(flag == false){
      this.calculateOrderQuantity() 
    }else{
      this.clearAssortmentsData()
    }
    // flag == false ? this.calculateOrderQuantity() : this.clearAssortmentsData();
    // let val = this.getAssortmentFormControl().get('distributionRequisitionQuantity')
    //   .valueChanges.pipe(debounceTime(1000)).subscribe(
    //     x => {
    //       console.log(x);
    //       this.calculateBalance();
    //       let flag = this.checkRequisitionDistributionGreaterThanRequisitionOrder();
    //       console.log("flag>>>>"+flag);
    //       flag == false ? this.calculateOrderQuantity() : this.clearAssortmentsData();
    //     }
    //   );
  }

  clearAssortmentsData() {
    let control = this.getAssortmentFormControl();
    let assortmentType = control.get('assortmentTypes').value;
    control.get('numberOfCartons').setValue('');
    control.get('distributionOrderQuantity').setValue('');
    if ((ASSORTED_CODE == assortmentType)) {
      this.clearPairsData();
      control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(0);
    }
    else if ((WHOLE_SALE_CODE == assortmentType || SOLID_CODE == assortmentType)) {
      this.clearPairsSolidData();
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
    }
  }

  getAssortmentType(division: string, conceptCode: string) {
    console.log('getAssortmentType')
    if (division != null && conceptCode != null) {
      this.assortmentTypes = [];
      if ('X' === conceptCode) {
        this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
        this.assortmentTypes.push({ id: WHOLE_SALE_CODE, name: WHOLE_SALE });
      }
      else if (RETAIL_DIVISION.toLowerCase() === division.toLowerCase()) {
        this.assortmentTypes.push({ id: ASSORTED_CODE, name: ASSORTED });
        this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
      }
      else if (NON_RETAIL_DIVISION.toLowerCase() === division.toLowerCase()) {
        this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
        this.assortmentTypes.push({ id: WHOLE_SALE_CODE, name: WHOLE_SALE });
      }
      else {
        this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID }); // when division is EXPORT
      }

    }
    return this.assortmentTypes;
  }

  solid_sizes(resultArray: { artSize: any; }[]) {
    var size = {};
    size['solid_size0'] = 'Size';
    for (let i = 0; i < resultArray.length; i++) {
      let j = i + 1;
      size['solid_size' + j] = resultArray[i].artSize;
    }
    size['solid_totalSize'] = 'Total';
    size['solid_action'] = 'Action';

    return this.fb.group(size);
  }

  solid_packingAssortmentRow(resultArray: { pairQty: number; }[]) {
    var packingAssortment = {};
    var totpackingAssortment = 0;
    packingAssortment['solid_packingAssortment0'] = 'Packing Assortment';
    for (let i = 0; i < resultArray.length; i++) {
      let j = i + 1;
      packingAssortment['solid_packingAssortment' + j] = resultArray[i].pairQty;
      totpackingAssortment += resultArray[i].pairQty;
    }
    packingAssortment['solid_totalPackingAssortment'] = totpackingAssortment;
    packingAssortment['solid_actionPackingAssortment'] = 'Enable';

    return this.fb.group(packingAssortment)
  }

  solid_pairRow(resultArray: { totalPairQty: number; }[]) {
    var pair = {};
    var totpair = 0;
    pair['solid_pair0'] = 'Pairs';
    for (let i = 0; i < resultArray.length; i++) {
      let j = i + 1;
      pair['solid_pair' + j] = resultArray[i].totalPairQty;
      totpair += resultArray[i].totalPairQty;
    }
    pair['solid_totalPairs'] = totpair;
    // pair['solid_actionPairs'] = 'Calculate';
    pair['solid_actionPairs'] = '';

    return this.fb.group(pair)
  }

  solid_packageAssortments(blankDataSet: any[]) {
    return this.fb.group({
      solid_sizes: this.solid_sizes(blankDataSet),
      solid_pairRow: this.solid_pairRow(blankDataSet),
      solid_packingAssortmentRow: this.solid_packingAssortmentRow(blankDataSet)
    });
  }

  getAssortmentFormControl() {
    let assortments_index: number = this.selectedPopupIndex;
    console.log(' getAssortmentFormControl assortments_index ' + assortments_index);
    let control = this.distributionDetailsForm.get(['assortments', assortments_index]);
    return control;
  }

  updateAssortmentIndex(targetLable: string, targetId: string) {
   return this.selectedPopupIndex = +(targetId.substring(targetLable.length, (targetId.length)));
  }

 onClickChangeAssortmentIndex(index: any) {
    this.errorMessageBlank();  
    this.selectedPopupIndex = index;
    let control = this.getAssortmentFormControl();
    let distReqQty: number = control.get('distributionRequisitionQuantity').value;
    if (WHOLE_SALE_CODE === control.get('assortmentTypes').value || SOLID_CODE === control.get('assortmentTypes').value) {
      console.log(' onClickChangeAssortmentIndex ' + control.get('assortmentTypes').value);
      console.log(control);
      
      this.validateDistributionReqQty();
      console.log(control);
      
      if (WHOLE_SALE_CODE === control.get('assortmentTypes').value) {
        if (( control.get('solid_packingAssortmentRow') !== null)&& ( control.get('solid_packingAssortmentRow').get('solid_pairRow') !== null) && ( control.get('solid_packingAssortmentRow').get('solid_pairRow').get('solid_totalPairs').value !== null)) {
          console.log("checking");
                //  this.calculateBalance();
                //  this.calculateOrderQuantity();        
        let sumOfPairs = control.get('solid_packingAssortmentRow').get('solid_pairRow').get('solid_totalPairs').value;
        if ((sumOfPairs <= (1.2 * distReqQty)) && (sumOfPairs >= (0.8 * distReqQty))) {
          this.distibutionOrderQtyErrMsg = '';
          this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        }
        else {
          this.distibutionOrderQtyErrMsg = 'Distribution Order Qunatity should not be 20% greater or less than Distibution Requsition Quantity.';//TODO Validation error show this
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          this.distributionDetailsForm.get(['assortments', index]).get('distributionOrderQuantity').setValue(0);
        }
      }else{
        console.log("Else Checking");
      
      }
  
      }
    }
    else {
      this.calculateBalance();
      this.calculateOrderQuantity();
      let sumOfPairs = control.get('packageAssortments').get('pairRow').get('totalPairs').value;
      if ((sumOfPairs <= (1.2 * distReqQty)) && (sumOfPairs >= (0.8 * distReqQty))) {
        this.distibutionOrderQtyErrMsg = '';
        this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
      }
      else {
        this.distibutionOrderQtyErrMsg = 'Distribution Order Qunatity should not be 20% greater or less than Distibution Requsition Quantity.';//TODO Validation error show this
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        this.distributionDetailsForm.get(['assortments', index]).get('distributionOrderQuantity').setValue(0);
      }
    }

    if (control.get('assortmentTypes').value === SOLID_CODE) {
      this.distributionDetailsForm.get(['assortments', index]).get('numberOfCartons').setValue('');
      this.distributionDetailsForm.get(['assortments', index]).get('distributionOrderQuantity').setValue(0);
      if((this.distributionDetailsForm.get(['assortments', index]).get('distributionRequisitionQuantity').value != this.distributionDetailsForm.get(['assortments', index]).get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').value) && (control.get('assortmentCode').value == ''|| control.get('assortmentCode').value == null)){
        this.responseMessage = 'Total Solid Pairs should be equal to distribution requisition qty for line ' + (index + 1);
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      }      
    }
  }

  changeAssortmentCode(assortment_index) {
  /*  var control = this.distributionDetailsForm.get(['assortments', assortment_index]);
    control.get('assortmentCode').enable();
    control.get('assortmentCode').setValue(''); */
     this.resetAssotmentTypeBasedTable(assortment_index);
  }

  onChangeAssortmentType(event: any) {
    let targetId = event.target.id;
    this.selectedAssortmentType = event.target.value;
    let targetLable = 'assortmentTypes';
    let index = this.updateAssortmentIndex(targetLable, targetId);
  /*  let control = this.getAssortmentFormControl();
    control.get('assortmentCode').enable();
    control.get('assortmentCode').setValue('');
    this.getAssortmentFormControl().get('assortmentCheckBox').disable();
    for (let i = 0; i < this.sizeBucketCount; i++) {
      let j = i + 1
      if (this.selectedAssortmentType == WHOLE_SALE_CODE) {
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).enable();
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Validate');
      } else {
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Calculate');
        control.get('assortmentCheckBox').enable();
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).disable();
        this.dispilayCalcBtnManualSolid = true;
      }
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).disable();
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').disable();
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
    } */
    this.resetAssotmentTypeBasedTable(index);
    this.distibutionOrderQtyErrMsg = '';
    return;
  }
  
  private resetAssotmentTypeBasedTable(index: any) {
    let control = this.distributionDetailsForm.get(['assortments', index]);
    let control1=this.distributionDetailsForm.get('assortments');
    control.get('assortmentCode').enable();
    control.get('assortmentCode').setValue('');
    control.get('numberOfCartons').setValue('');
    control.get('distributionRequisitionQuantity').setValue('');
    control.get('distributionOrderQuantity').setValue('');
    for (let i = 0; i < this.sizeBucketCount; i++) {
      let j = i + 1;
      if (this.selectedAssortmentType == WHOLE_SALE_CODE || this.selectedAssortmentType == SOLID_CODE) {
        let solidTablePACellName = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j);
        
        solidTablePACellName.setValue(0);
        
        if (this.selectedAssortmentType == WHOLE_SALE_CODE) {
          console.log(control1);
          
          // var control1=this.getAssortmentFormControl();
          console.log(control);
          if(control!=null){
            // this.getAssortmentFormControl().get('assortmentCheckBox').disable();
            // control.get('assortmentCheckBox').disable();
          }
          console.log(control);
          
          solidTablePACellName.enable();
          control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Validate');
        }
        else {
          control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Calculate');
          control.get('assortmentCheckBox').enable();
          solidTablePACellName.disable();
          this.dispilayCalcBtnManualSolid = true;
        }
        // let solidTablePairCellName = control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j);
        // solidTablePairCellName.disable();
        // solidTablePairCellName.setValue(0);
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').disable();
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(0);
      }
      else {
        let assortedTablePACellName = control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment' + j);
        assortedTablePACellName.setValue(0);
        control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(0);
        let assortedTablePairCellName = control.get('packageAssortments').get('pairRow').get('pair' + j);
        assortedTablePairCellName.setValue(0);
        control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(0);
      }
    }
  }

  fetchAssortmenDetails(event: any) {
    this.dataList = [];
    let control = this.getAssortmentFormControl();
    let location: string = control.get('locationArray').value;
    if (location != null) {
      let selectedShopName = location.split('~')[0];
      selectedShopName = selectedShopName.substr(0, selectedShopName.length - 1);
      let selectedShopCode = location.split('~')[1];
      selectedShopCode = selectedShopCode.substr(0, selectedShopCode.length);
      let selectedAssortmentTypeId: string = ASSORTED_CODE; // ASSORTMENT TYPE CODE WILL BE A ALWAYS.
      let targetId: string = event.target.id;
      let checkBoxLabel: string = "chkOpenPackPopup";
      this.selectedPopupIndex = +(targetId.substring(checkBoxLabel.length, (targetId.length)));
      // console.log(' gc '+this.groupCodeArray[0] +' cc ' + this.searchDisrtibutionDetails.conceptCode);
      let assortment_code_param = [
        {
          "name": "catCode",
          "value": this.selectedCategoryCode
        },
        {
          "name": "shopCode",
          "value": selectedShopCode
        },
        {
          "name": "assortmentType",
          "value": selectedAssortmentTypeId
        },
        {
          "name": "flag",
          "value": "N"
        },
        {
          "name": "groupCode",
          "value": this.groupCodeArray[0]
        },
        {
          "name": "concept",
          "value": this.searchDisrtibutionDetails.conceptCode
        }
      ];

      if ((SOLID_CODE === control.get('assortmentTypes').value) || (ASSORTED_CODE === control.get('assortmentTypes').value)) {
        this._distributionDetailService.getAssortmensts(assortment_code_param).subscribe(
          (response: DynamicPackingMaster) => {
            if (response != null) {
              if (response.dataList != null &&
                response.dataList.length > 0) {
                let dataListLength = response.dataList.length;
                this.dataList = [];
                this.packingMasterAssortmentsDTO = [];
                // this.selectedSizelabelPopup = [];
                for (let i = 0; i < dataListLength; i++) {
                  if (response.dataList[i] != null && response.dataList[i].packingMasterAssortmentsDTO != null
                    && response.dataList[i].packingMasterAssortmentsDTO.length > 0) {
                    let totalPairQty = 0;
                    for (let j = 0; j < response.dataList[i].packingMasterAssortmentsDTO.length; j++) {
                      this.packingMasterAssortmentsDTO.push(response.dataList[i].packingMasterAssortmentsDTO[j]);
                      response.dataList[i] != null && response.dataList[i].packingMasterAssortmentsDTO[j] != null ?
                        totalPairQty += response.dataList[i].packingMasterAssortmentsDTO[j].pairQty : 0;
                      response.dataList[i].totalPairQty = totalPairQty;
                      //  this.selectedSizelabelPopup.push(response.dataList[i].packingMasterAssortmentsDTO[j].sizeLable);
                    }
                  }
                  this.dataList.push(response.dataList[i]);
                  this.packingMaster = response.dataList;
                }
              }else {
                this.assormentMessage = 'No data found';
                setTimeout(()=>{
                  this.assormentMessage = '';
                }, 5000);
              }
            }else {
              this.assormentMessage = 'No data found';
              setTimeout(()=>{
                this.assormentMessage = '';
              }, 5000);
            }
          },
          (err)=>{
            this.assormentMessage = err;
            setTimeout(()=>{
              this.assormentMessage = '';
            }, 5000);
          });
        this.dispilayCalcBtnManualSolid = true;
      }
      this.validateDistributionReqQty();
    }
  }

  onClickBack() {
    this._router.navigate(['/requisition-details']);
  }

  selectedAssortment(index: string | number) {
    let selectedPackingAssortment = this.packingMaster[index];

    let control = this.getAssortmentFormControl();
    var fakeArr = [];
    var blankDataSet = [];
    for (var j = 0; j < this.sizeBucketCount; j++) {
      blankDataSet.push({
        "artSize": this.sizepackingassortment[0][j].artSize,
        "articleSize": this.sizepackingassortment[0][j].sizeBucket,
        "pairQty": 0,
        "totalPairQty": 0
      });
    }

    selectedPackingAssortment.packingMasterAssortmentsDTO.forEach((k: any) => {
      fakeArr.push(k);
    });

    var resultArray = [];
    resultArray = blankDataSet.reduce((arr, e) => {
      arr.push(Object.assign({}, e, fakeArr.find(a => e.articleSize == a.articleSize)))
      return arr;
    }, []);
    var totPackAssort = 0;
    if (this.selectedAssortmentType === ASSORTED_CODE) {
      if (resultArray.length > 0) {
        for (let i = 0; i < resultArray.length; i++) {
          let k = i + 1;
          control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment' + k).setValue(eval(`resultArray[${i}].pairQty`));
          totPackAssort += eval(`resultArray[${i}].pairQty`);
        }
        control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(totPackAssort);
      }
    } else {
      let resultArrayLength = resultArray.length;
      if (resultArrayLength > 0) {
        for (let i = 0; i < resultArrayLength; i++) {
          let k = i + 1;
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + k).setValue(eval(`resultArray[${i}].pairQty`));
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + k).disable();
          totPackAssort += eval(`resultArray[${i}].pairQty`);
        }

        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + resultArrayLength).disable();
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(totPackAssort);
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
      }
    }

    control.get('assortmentCode').setValue(selectedPackingAssortment.packCode);
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
    control.get('distributionOrderQuantity').setValue(0);
    control.get('numberOfCartons').disable();

  }
  /*
   1. add new Assortment form controls
   2. populate orderedQuantity
  */
  addAssortment() {
    var blankDataSet = [];
    for (var j = 0; j < this.sizepackingassortment[0].length; j++) {
      blankDataSet.push({
        "artSize": this.sizepackingassortment[0][j].artSize,
        "articleSize": this.sizepackingassortment[0][j].sizeBucket,
        "pairQty": 0,
        "totalPairQty": 0
      });
    }

    let control = <FormArray>this.distributionDetailsForm.controls.assortments;
    control.push(
      this.fb.group({
        assortmentCode: [''],
        numberOfCartons: { value: '', disabled: true },
        distributionRequisitionQuantity: [''],
        distributionOrderQuantity: { value: '', disabled: true },
        assortmentTypes: ['Please Select'],
        locationArray: [''],
        assortmentCheckBox: { value: false },
        packageAssortments: this.packageAssortments(blankDataSet),
        solid_packageAssortments: this.solid_packageAssortments(blankDataSet)
      }));
    this.deleteDisable = false;
    this.isOrderQuantityCalculated = false;
    //this.validateDistributionReqQty();
  }

  deleteAssortment(index: number) {
    let control = <FormArray>this.distributionDetailsForm.controls.assortments;
    let control1=this.getAssortmentFormControl();
    console.log("deleteAssortment");
    console.log(control);
    
    
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      var k='pair'+j;
      console.log(control.value[index].solid_packageAssortments.solid_packingAssortmentRow['solid_packingAssortment'+j]);
      console.log(control1);
      if(control1 !=null){
        
        let numOfCartoons=control1.get('numberOfCartons').value;
      }
      // if (WHOLE_SALE_CODE === control1.get('assortmentTypes').value) {
      //   this.sizeWisePndgArray[i]=parseInt(this.sizeWisePndgArray[i])+(parseInt( control.value[index].solid_packageAssortments.solid_packingAssortmentRow['solid_packingAssortment'+j]) * numOfCartoons);
      // }else
      //  if(SOLID_CODE === control1.get('assortmentTypes').value || WHOLE_SALE_CODE === control1.get('assortmentTypes').value){
      //   this.sizeWisePndgArray[i]=parseInt(this.sizeWisePndgArray[i])+(parseInt( control.value[index].solid_packageAssortments.solid_pairRow['solid_pair'+j]));
        
      // }
      // else{
      //   this.sizeWisePndgArray[i]=parseInt(this.sizeWisePndgArray[i])+parseInt(control.value[index].packageAssortments.pairRow['pair'+j]);
      // }
    }
    control.removeAt(index);
    this.selectedPopupIndex = index - 1;
    this.disableSave();
    this.calculateBalance();
    this.sumOfDistributionReqQuantity();
  }

  /*
    this method calculates : numberOfCartonsValue, totalParis, distributionOrderQuantity
    invoke on event fire
  */
  calculateOrderQuantity() {
    this.errorMessageBlank();  
    let control = this.getAssortmentFormControl();
    let distributionReqQty: number = control.get('distributionRequisitionQuantity').value;

    if (control != null && control.get('assortmentTypes').value == ASSORTED_CODE) {
      let totalPackingAssortment: number = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;

      if (totalPackingAssortment > 0) {
        this.displayDefaultValPair = false; //used in htlm
        let numberOfCartonsValue: number;
        let totalParis: number;
        //distributionReqQty = control.get('distributionRequisitionQuantity').value;
        if (distributionReqQty <= (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) {
          this.saveDisabled = true;
          numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
          totalParis = totalPackingAssortment * numberOfCartonsValue;
          this.calculatePairs(numberOfCartonsValue);
          control.get('numberOfCartons').setValue(numberOfCartonsValue);
          control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(totalParis);
          control.get('distributionOrderQuantity').setValue(totalParis);
          this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        }
        else {
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }
      }
    }

    if (WHOLE_SALE_CODE === control.get('assortmentTypes').value) {
      console.log("wholesale calc");
      let totalPackingAssortment: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
      if (totalPackingAssortment > 0) {
        this.displayDefaultValPair = false;
        let numberOfCartonsValue: number;
        let totalParis: number;
        distributionReqQty = control.get('distributionRequisitionQuantity').value;
        if (distributionReqQty <= (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) {
          this.saveDisabled = true;
          numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
          totalParis = totalPackingAssortment * numberOfCartonsValue;
          this.calculatePairsSolid(numberOfCartonsValue);
          control.get('numberOfCartons').setValue(numberOfCartonsValue);
          control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalParis);
          control.get('distributionOrderQuantity').setValue(totalParis);
          this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        }
        else {
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }
      }
    }

    if (control.get('assortmentTypes').value === SOLID_CODE) {
      control.get('numberOfCartons').setValue('');
      control.get('distributionOrderQuantity').setValue(0);
      console.log(control.get('distributionRequisitionQuantity').value);
      console.log(control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').value);
      if((control.get('distributionRequisitionQuantity').value != control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').value) && (control.get('assortmentCode').value == ''|| control.get('assortmentCode').value == null)){
        this.responseMessage = 'Total Solid Pairs should be equal to distribution requisition qty for line '+(this.selectedPopupIndex+1);
      } 
    }

    this.disableSave();
    if (this.isPrime)
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    else
      this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();

    this.calculateBalance();
    this.checkRequisitionDistributionGreaterThanRequisitionOrder();
  }

  private checkRequisitionDistributionGreaterThanRequisitionOrder() {
    let requisitionOrderQty = Number(this.searchDisrtibutionDetails.reqQty);
    console.log('distReqQuantity'+Number(this.distReqQuantity));
    console.log('requisitionOrderQty'+requisitionOrderQty);
    if (Number(this.distReqQuantity) > requisitionOrderQty) {
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Requisition Quantity is greater than Requisition Quantity.';
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      this.distReqQtyGreaterThanOrderQty = true;
    }
    else {
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      this.distReqQtyGreaterThanOrderQty = false;
    }
    return this.distReqQtyGreaterThanOrderQty;
  }

  calculateBalance() {
    let count: number = 0;
    let distributionReqQuantity: number = 0;
    this.distReqQuantity = 0;
    let control = this.distributionDetailsForm.get('assortments');
    console.log('calculateBalance');
    console.log(control);
    console.log(control.value);
    
    control.value.forEach((p: any) => {
      distributionReqQuantity = Number(this.distributionDetailsForm.get(['assortments', count]).get('distributionRequisitionQuantity').value);
      this.distReqQuantity += distributionReqQuantity;
      count++;
    });
    console.log(control.value);
    
  }

  calculatePairs(numberOfCartonsValue: number) {
    let control = this.distributionDetailsForm.get(['assortments', this.selectedPopupIndex]);

    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      control.get('packageAssortments').get('pairRow').get('pair' + j).setValue((control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment' + j).value) * numberOfCartonsValue);
      // this.sizeWisePndgArray[i]=this.sizeWisePndgArray[i]-((control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment' + j).value) * numberOfCartonsValue);
      
    }
  }

  clearPairsData() {
    let control = this.distributionDetailsForm.get(['assortments', this.selectedPopupIndex]);
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      control.get('packageAssortments').get('pairRow').get('pair' + j).setValue(0);
    }
  }

  calculatePairsSolid(numberOfCartonsValue: number) {
    let control = this.distributionDetailsForm.get(['assortments', this.selectedPopupIndex]);

    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).setValue((control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value) * numberOfCartonsValue);
      // this.sizeWisePndgArray[i]=this.sizeWisePndgArray[i]-((control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value) * numberOfCartonsValue);
    }
    console.log(control);
    
  }

  clearPairsSolidData() {
    let control = this.distributionDetailsForm.get(['assortments', this.selectedPopupIndex]);
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).setValue(0);
    }
  }

  sumOfDistributionReqQuantity() {
    this.errorMessageBlank();  
    console.log(this.ShowSave);
    let count: number = 0;
    let distributionOrderQuantity: number = 0;
    let distributionRequisitionQuantity: number = 0;
    this.orderedQuantity = 0;
    let control = this.distributionDetailsForm.get('assortments');
    let control1=this.getAssortmentFormControl();
    let rdcCodeAssortmentTypeArray = [];
    let allValidationPass: boolean;
    let totalOrderQuantity = this.searchDisrtibutionDetails.orderedQty;
    let requisitionQuantity = this.searchDisrtibutionDetails.reqQty;
    if (Number(this.distReqQuantity) > Number(requisitionQuantity)) {
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Requisition Quantity is greater than Requisition Quantity.';
      return;
    }
    console.log(control1);
    
    // control.value.forEach((k: any) => {
      console.log(control.value);
      console.log(control.value.length);
      console.log(this.sizeWiseQtyArray);
    this.sizeWisePndgArray=[];
    this.sizeWiseQtyArray.forEach(
      dta => {
        console.log(dta)
         this.sizeWisePndgArray.push(dta);
      });
    if(control.value.length>0){
    let selectAssortmentType = this.distributionDetailsForm.get(['assortments', count]).get('assortmentTypes').value;
    if (selectAssortmentType == ASSORTED_CODE) {
      // this.sizeWisePndgArray=[];
      control.value.forEach(
        data => {
          console.log(data);
          console.log(data.packageAssortments.pairRow['pair0']);
          
          for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
            var j = i + 1;
            // this.sizeWisePndgArray[i]=this.sizeWisePndgArray[i]-((data.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value));
            console.log(data.packageAssortments.pairRow['pair'+j]);
            console.log(this.sizeWisePndgArray);
            console.log(this.sizeWisePndgArray[i]);
            if(this.sizeWiseQtyArray[i]==0)
            {
              this.sizeWisePndgArray[i]=0;
            }else{
              this.sizeWisePndgArray[i]=parseInt((this.sizeWisePndgArray[i]!=undefined)?this.sizeWisePndgArray[i]:0)-parseInt(data.packageAssortments.pairRow['pair'+j]);
            }
            
          }
        });
    }else{
      // this.sizeWisePndgArray=[];
        control.value.forEach(
          data => {
            console.log(data);
            console.log(data.solid_packageAssortments.solid_pairRow['solid_pair0']);
            
            for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
              var j = i + 1;
              // this.sizeWisePndgArray[i]=this.sizeWisePndgArray[i]-((data.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value));
              console.log(data.solid_packageAssortments.solid_pairRow['solid_pair'+j]);
              console.log(this.sizeWisePndgArray);
              console.log(this.sizeWisePndgArray[i]);
              if(this.sizeWiseQtyArray[i]==0)
            {
              this.sizeWisePndgArray[i]=0;
            }else{
              this.sizeWisePndgArray[i]=parseInt((this.sizeWisePndgArray[i]!=undefined)?this.sizeWisePndgArray[i]:0)-parseInt(data.solid_packageAssortments.solid_pairRow['solid_pair'+j]);
            }
            }
          });
    }
  
    for(var i = 0; i< control.value.length;i++)
    {
      let locationValue = this.distributionDetailsForm.get(['assortments', count]).get('locationArray').value;
      distributionOrderQuantity = Number(this.distributionDetailsForm.get(['assortments', count]).get('distributionOrderQuantity').value);
      distributionRequisitionQuantity = Number(this.distributionDetailsForm.get(['assortments', count]).get('distributionRequisitionQuantity').value);
      let pairPerCartonRatio = this.distributionDetailsForm.get(['assortments', count]).get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value
      let assortmentTypes = this.distributionDetailsForm.get(['assortments', count]).get('assortmentTypes').value;
      console.log(' val pair per carton ratio ' + pairPerCartonRatio + ' assortmentTypes ' + assortmentTypes+ ' distributionOrderQty ' + distributionOrderQuantity);
      // Validation 
      console.log(locationValue);
      if (locationValue == null || locationValue == ""){
        console.log('location not selected');
        allValidationPass = false;
        this.distributionOrderQuantityEmptyErrMsg = 'Location is empty at  line ' + (count + 1);
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return;
      } else if (distributionOrderQuantity == 0) {
        this.distributionOrderQuantityEmptyErrMsg = 'Distribution req qty and Total pairs are different at line ' + (count + 1);
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        allValidationPass = false;
        console.log(' allValidationPass - distributionOrderQuantity == 0 ' + allValidationPass);
        return;
        this.distributionRequsitionQuantityEmptyErrMsg = '';
      } else if (distributionRequisitionQuantity == 0) {
        this.distributionRequsitionQuantityEmptyErrMsg = 'Distribution Requisition Quantity is empty at  line ' + (count + 1);
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        this.distributionOrderQuantityEmptyErrMsg = '';
        allValidationPass = false;
        console.log(' allValidationPass - distributionRequisitionQuantity == 0 ' + allValidationPass);
        return;
      } else if (pairPerCartonRatio == 0 && assortmentTypes == SOLID_CODE) {
        allValidationPass = false;
        this.pairPerCartonRatioEmptyErrMsg = 'Pair per carton is empty at  line ' + (count + 1);
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return;
      }
      else {
        console.log('else check');
        this.errorMessageBlank();  
      }

      // let selectAssortmentType = this.distributionDetailsForm.get(['assortments', count]).get('assortmentTypes').value;
      if (selectAssortmentType == ASSORTED_CODE || selectAssortmentType == SOLID_CODE) {
        let selectLocation: string = this.distributionDetailsForm.get(['assortments', count]).get('locationArray').value;
        let selectLocationCode = selectLocation.substring(selectLocation.lastIndexOf('~') + 1, selectLocation.length);
        rdcCodeAssortmentTypeArray.push(selectLocationCode);
      }

      this.orderedQuantity += Number(distributionOrderQuantity);
      count++;
    };
  }
    console.log('this.orderedQty'+ this.orderedQuantity);

    if (this.validateDuplicateRdcAndAssortmentTypeUsed(rdcCodeAssortmentTypeArray)) {
      allValidationPass = false;
      console.log(' allValidationPass - hasDuplicate ' + allValidationPass);
      //this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    } else{
      //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      allValidationPass = true;
    }

    console.log('allValidationPass'+allValidationPass);

    this.searchDisrtibutionDetails.orderedQty = this.orderedQuantity.toString();
    console.log((Number(this.searchDisrtibutionDetails.reqQty) * 1.2));
    // console.log(Number(this.searchDisrtibutionDetails.orderedQty));

    // let control = this.distributionDetailsForm.get(['assortments', this.selectedPopupIndex]);
    // let numOfCartoons=control1.get('numberOfCartons').value;
    
    
    
      
    
    if (((Number(Number(this.searchDisrtibutionDetails.reqQty) * 1.2) <= Number(this.searchDisrtibutionDetails.orderedQty)) || Number(this.searchDisrtibutionDetails.orderedQty) != 0) && allValidationPass) {
      console.log(' allValidationPass - final ' + allValidationPass);
      this.distributionSaveButtonForm.get('saveDistributionButton').enable();
    }
    else{
      console.log('validation failed');
    }
  }

  private validateDuplicateRdcAndAssortmentTypeUsed(rdcCodeAssortmentTypeArray: any[]) {
    this.hasDuplicate = false;
    let rdcCodeAssortmentTypeObservable: Observable<string> = Observable.create(observer => {
      rdcCodeAssortmentTypeArray.forEach(e => {
        observer.next(e);
      });
      observer.complete();
    });
    let distinctRdcCodeAssortmentTypeArray = [];
    rdcCodeAssortmentTypeObservable.pipe(distinct()).subscribe(x => {
      distinctRdcCodeAssortmentTypeArray.push(x);
    });
    console.log(' dist ' + distinctRdcCodeAssortmentTypeArray.length + ' all ' + rdcCodeAssortmentTypeArray.length);
    let rdcArraysLengthDiff = rdcCodeAssortmentTypeArray.length - distinctRdcCodeAssortmentTypeArray.length;
    if (rdcArraysLengthDiff > 0) {
      this.hasDuplicateDistibutionErrMsg = 'Please choose different location.';
      this.hasDuplicate = true;
    }
    else {
      this.hasDuplicateDistibutionErrMsg = '';
    }
    console.log(this.hasDuplicate);
    return this.hasDuplicate;
  }

  calculate(assortment_index: any) {
    this.errorMessageBlank();   
    //let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['assortments', assortment_index]);
    console.log("-----------------------");
    
    console.log(control);
    
    let assortmentCode: string = this.getAssortmentFormControl().get('assortmentCode').value;

    let distributionReqQty = control.get('distributionRequisitionQuantity').value;
    if ('W' === this.selectedAssortmentType) {

      this.displayLoaderWhenAssortmentTypeWholeSale = true;
      this.loadingSpinnerMsg = 'Please wait assortment code is populating.'
      control.get('assortmentCheckBox').disable();

      let assortmentType = control.get('assortmentTypes').value;
      let groupCode = this.groupCodeArray[0];
      let paTotal: number = 0;
      let pairs = {};
      let numberOfCartons: number = control.get('numberOfCartons').value;
      console.log(' numberOfCartons ' + numberOfCartons);
      for (var j = 0; j < this.sizepackingassortment[0].length; j++) {
        let k = j + 1;
        let paControlName = 'solid_packingAssortment' + k;
        let pairControlName = 'solid_pair' + k;
        let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
        console.log(paValue);
        
        //if(paValue!=0){
        control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(numberOfCartons * paValue);
        paTotal += Number(paValue);
        if (paValue != null && paValue != 0) {
          pairs['pair' + this.sizepackingassortment[0][j].sizeBucket] = paValue;
        }
        else {
          pairs['pair' + this.sizepackingassortment[0][j].sizeBucket] = 0;
        }
      }
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
      console.log(control);
      
      console.log("------------------------------");
      
      let isPrime = this.validateTotalPackingAssotmentIfPrime(assortment_index);
      if (isPrime) {
        console.log('assortment type : WA, pair per carton should not be prime ' + isPrime);
        this.displayLoaderWhenAssortmentTypeWholeSale = false;
        return;
      }
      let sessUsername = sessionStorage.getItem('currentUser');
      let userEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
      let assortment_code_param = {
        "groupCode": groupCode,
        "assortmentType": assortmentType,
        "pairs": pairs,
        "createdBy" : userEmail
      };
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
      this._distributionDetailService.getAssortmenstsValidate(assortment_code_param).subscribe(
        (response: DynamicPackingMaster) => {
          if (response != null) {
            let assortmentCode = response.dataList[0].assortmentCode;
            console.log('ac ' + assortmentCode);
            // const [assortmentCode] = response.dataList.map(item => item.assortmentCode); // always return one assortmentCode.
            // let assortmentCodeVal = [assortmentCode].toString();
            control.get('assortmentCode').setValue(assortmentCode);
            this.displayLoaderWhenAssortmentTypeWholeSale = false;
            this.loadingSpinnerMsg = '';
            let reqQnty: number = control.get('distributionRequisitionQuantity').value;
            console.log("reqQnty >>" + reqQnty);
            if (reqQnty != null && reqQnty != undefined && reqQnty > 0) {
              this.onClickChangeAssortmentIndex(assortment_index);
            }
             let sumOfPairs = control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').value;
              let distReqQty: number = control.get('distributionRequisitionQuantity').value;
              if((sumOfPairs <= (1.2 * distReqQty)) && (sumOfPairs >= (0.8 * distReqQty))){
                this.distibutionOrderQtyErrMsg = '';
                this.distributionSaveButtonForm.get('saveDistributionButton').enable();
                this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
              } 
              else{
                this.distibutionOrderQtyErrMsg = 'Distribution Order Qunatity should not be 20% greater or less than Distibution Requsition Quantity.';//TODO Validation error show this
                this.distributionSaveButtonForm.get('saveDistributionButton').disable();
                control.get('distributionOrderQuantity').setValue(0);
              }
            //   let paskingId:any = [];
            //   paskingId = response.dataList.map(item => item.packingMasterAssortmentsDTO.map(element => element.pckAssortmentId)); 
            //   paskingId.forEach(x=>console.log(x));
            //  ({status} = response);
            //  console.log(status); 
            // let { dataList:[ { assortmentCode: a , packingMasterAssortmentsDTO: [  {pckAssortmentId:pid}  ]}] } =  response;
            // console.log(a); 
            // console.log(pid); 
          }
        }
      );

      this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
      //Reset
      this.isTotalPairDistributionOrderQtyEqual = true; this.isPairDivisible = true; this.isPrime = false;
     
    }
    //  

    if ('S' === this.selectedAssortmentType) {
      let distributionReqQty: number = control.get('distributionRequisitionQuantity').value;
      let totalPackingAssortment: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
      let isDivisible = distributionReqQty != 0 && (distributionReqQty % totalPackingAssortment === 0) ? true : false;
      console.log(' distributionReqQty ' + distributionReqQty + ' | totalPackingAssortment ' + totalPackingAssortment
        + ' | ' + (distributionReqQty % totalPackingAssortment) + ' | isDivisible ' + isDivisible);
      if (assortmentCode != null && assortmentCode !== '') {
        
        this.calculateNumberOfCartons(assortment_index);
        this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        //Reset
        this.isTotalPairDistributionOrderQtyEqual = true; this.isPairDivisible = true; this.isPrime = false;
        // if (totalPackingAssortment > 0) {
        //   this.displayDefaultValPair = false; //used in htlm
        //   let numberOfCartonsValue: number;
        //   let totalParis: number;

        //   if (distributionReqQty <= (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) {
        //     this.saveDisabled = true;
        //     numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
        //     totalParis = totalPackingAssortment * numberOfCartonsValue;
        //     control.get('numberOfCartons').setValue(numberOfCartonsValue);
        //     this.calculatePairsSolid(numberOfCartonsValue);
        //     control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalParis);
        //     control.get('distributionOrderQuantity').setValue(totalParis);
        //     this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        //   }
        // }
        // this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        // this.isTotalPairDistributionOrderQtyEqual = true; this.isPairDivisible = true; this.isPrime = false;
      }
      // else {
      //   let numberOfCartons: number = control.get('numberOfCartons').value;
      //   if (numberOfCartons != null && numberOfCartons > 0) {
      //     let prTotal: number = 0;
      //     for (let j = 0; j < this.sizeBucketCount; j++) {
      //       let i = j + 1;
      //       let pairControlName = 'solid_pair' + i;
      //       let paControlName = 'solid_packingAssortment' + i;
      //       let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
      //       let pairValue = paValue * numberOfCartons;
      //       control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(pairValue);
      //       if (!isNaN(pairValue)) {
      //         prTotal += Number(pairValue);
      //       }
      //     }
      //     control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(prTotal);
      //     control.get('distributionOrderQuantity').setValue(prTotal);
      //     this.calculateTotalPackingAssortmentsSolid(assortment_index);
      //     this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      //     //Reset
      //     this.isTotalPairDistributionOrderQtyEqual = true; this.isPairDivisible = true; this.isPrime = false;
      //   }
      //   else {
      //     // get entered distribution requisition qty drq 
      //     numberOfCartons = this.calculateNumberOfCartons(assortment_index, numberOfCartons);
      //     this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      //     //Reset
      //     this.isTotalPairDistributionOrderQtyEqual = true; this.isPairDivisible = true; this.isPrime = false;
      //   }
      // }

      if (assortmentCode != null && assortmentCode !== '') {
        this.checkRequisitionDistributionGreaterThanRequisitionOrder();
      }
    }
  }

  private calculateNumberOfCartons(assortment_index: any) {
    let control = this.distributionDetailsForm.get(['assortments', assortment_index]);
    let distReqQty: number = control.get('distributionRequisitionQuantity').value;
    console.log(control);
    // calculate total ration packing assortment tpa
    let paTotal: number = 0;
	let assortmentSum: number = 0;
    for (let i = 0; i < this.sizeBucketCount; i++) {
      let sizeIndex = i + 1;
      let paControlName = 'solid_packingAssortment' + sizeIndex;
      let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
      if (!isNaN(paValue)) {
        paTotal += Number(paValue);
      }
    }
    control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
    let nearestPairVaule = 0;
    let sumOfPairs = 0;
	let a: number = 0;
    let b: number = 0; 
    for (let j = 0; j < this.sizeBucketCount; j++) {
      //pick each pa ratio
      let i = j + 1;
      let paControlName = 'solid_packingAssortment' + i;
      let pairControlName = 'solid_pair' + i;
      let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
      // get ratio              
      /*let x = (distReqQty / paTotal) * paValue;
      x = Math.ceil(x);
      let y = x / paTotal;
      let y_ceil = Math.ceil(y);
      let y_floor = Math.floor(y);
      let pairCeil = y_ceil * paTotal;
      let pairFloor = y_floor * paTotal;
      console.log('x ' + x);
      console.log('y ' + y);
      console.log('y_ceil ' + y_ceil);
      console.log('y_floor ' + y_floor);
      console.log('pairCeil ' + pairCeil);
      console.log('pairFloor ' + pairFloor);
      if (pairCeil - x >= x - pairFloor) {
        nearestPairVaule = pairFloor;
      }
      else if (pairCeil - x < x - pairFloor) {
        nearestPairVaule = pairCeil;
      }
      console.log('nearestPairVaule ' + nearestPairVaule);
      control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(nearestPairVaule);
      sumOfPairs += Number(nearestPairVaule);*/
	  
	  let mf = Math.round((distReqQty / paTotal))
						  let asst = mf * paValue;
			        assortmentSum = assortmentSum+asst;
				      let y = asst / paTotal;			  
              let y_ceil = Math.ceil(y);
              let y_floor = Math.floor(y);
              a = Number(paTotal * y_ceil);
              b = Number(paTotal * y_floor);
             
			  console.log(a+" ------ "+b);
        console.log(pairControlName);
					if((assortmentSum > (sumOfPairs+b)) && (assortmentSum > (sumOfPairs+a))){
						if((assortmentSum-(sumOfPairs+b)) > (assortmentSum-(sumOfPairs+a))){
							sumOfPairs += a;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
              console.log('if');console.log(control);
              
						}else{
              console.log('else');console.log(control);
							sumOfPairs += b;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
						}
						
					}else if((assortmentSum > (sumOfPairs+b)) && (assortmentSum < (sumOfPairs+a))){
            sumOfPairs += b;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              console.log('if');console.log(control);
					}else if(assortmentSum == (sumOfPairs+b)){
            sumOfPairs += b;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              console.log('Elseif');console.log(control);
					}else if(assortmentSum == (sumOfPairs+a)){
            sumOfPairs += a;
            console.log('Elseif1');console.log(control);
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
					}
    }
    console.log(control);
    console.log('sumOfPairs ' + sumOfPairs);
    // calculate carton 
	if((sumOfPairs <= (1.2 * distReqQty)) && (sumOfPairs >= (0.8 * distReqQty))){
   let numberOfCartons = sumOfPairs / paTotal;
    console.log('numberOfCartons ' + numberOfCartons);
    control.get('numberOfCartons').setValue(numberOfCartons);
    control.get('distributionOrderQuantity').setValue(sumOfPairs);
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(sumOfPairs);
	}else{
		this.distibutionOrderQtyErrMsg = 'Distribution Order Qunatity should not be 20% greater or less than Distibution Requsition Quantity.';//TODO Validation error show this
	    control.get('distributionOrderQuantity').setValue(0);
	}
  }

  calculateTotalPackingAssortmentsSolid(assortment_index: any) {
    //  let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['assortments', assortment_index]);
    let solid_totalPackAssortments = 0;
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      solid_totalPackAssortments += Number(control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value)
    }
    control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(solid_totalPackAssortments);
    return solid_totalPackAssortments;
  }

  onClickEnableButton(index: any) {
    this.errorMessageBlank();  
    let control = this.distributionDetailsForm.get(['assortments', index]);
    if (control.get('assortmentTypes').value == SOLID_CODE) {
      control.get('numberOfCartons').disable();
      let assortmentCode: string = this.getAssortmentFormControl().get('assortmentCode').value;
      let isAssortmentCodeEmpty = assortmentCode === null || assortmentCode === '' || assortmentCode === 'undifined' ? true : false;
      for (let i = 0; i < this.sizeBucketCount; i++) {
        var j = i + 1;
        let pairControlName = 'solid_pair' + j;
        control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).enable();
        let packingAssortmentControlName = 'solid_packingAssortment' + j;
        if (isAssortmentCodeEmpty) {
          this.dispilayCalcBtnManualSolid = false;
          console.log(' manula solid ');
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).setValue('');
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').enable();
        } else {
          this.dispilayCalcBtnManualSolid = true;
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
        }
      }

      if (isAssortmentCodeEmpty) {
        control.get('assortmentCode').setValue('');
        control.get('assortmentCode').disable();
      } else {
        let sumOfPairs = control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').value;
        let distReqQty: number = control.get('distributionRequisitionQuantity').value;
        if ((sumOfPairs <= (1.2 * distReqQty)) && (sumOfPairs >= (0.8 * distReqQty))) {
          this.distibutionOrderQtyErrMsg = '';
          this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        }
        else {
          this.distibutionOrderQtyErrMsg = 'Distribution Order Qunatity should not be 20% greater or less than Distibution Requsition Quantity.';//TODO Validation error show this
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }
      }
    }
  }

  submitRegDistributionPending(dataValue: any) {
    this.errorMessageBlank();  
    this.distributionSaveButtonForm.get('saveDistributionButton').disable(); 
    let totalOrderQuantity = this.searchDisrtibutionDetails.orderedQty;
    let requisitionQuantity = this.searchDisrtibutionDetails.reqQty;
    console.log('totalOrderQuantity ' + totalOrderQuantity + ' requisitionQuantity ' + requisitionQuantity);
    if (Number(totalOrderQuantity) > (parseInt(requisitionQuantity) * 1.2)) {
      this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Ordered Quantity is greater than 20% of Requisition Quantity, please correct it';
      return;
    } else if (Number(this.distReqQuantity) > Number(requisitionQuantity)) {
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Requisition Quantity is greater than Requisition Quantity.';
      return;
    }
    else {
      this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
      let dataset = [];
      let packingAssortmentControlName: string;
      let packingAssortment: number;
      let pairControlName: string;
      let pair: number;
      let hasDuplicate: boolean;
      let pairPerCarton: string;
      var j = 0;
      let inputboxValidation = 0;
      dataValue.assortments.forEach((k: { locationArray: { split: { (arg0: string): any[]; (arg0: string): any[]; }; }; distributionRequisitionQuantity: any; assortmentTypes: any; }) => {
        let sizeControlCount = this.sizepackingassortment[0].length;
        let requisitionAssortments = [];
        let control = this.distributionDetailsForm.get(['assortments', j]);
        let anyPairDivisible: boolean[] = [];
        let distributionRequisitionQuantity = control.get('distributionRequisitionQuantity').value;
        let distributionOrderQuantity = control.get('distributionOrderQuantity').value;
        let pairPerCartonRatio = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value; 
        let assortmentTypes =  control.get('assortmentTypes').value; 
        let assortmentCode =  control.get('assortmentCode').value; 
        
        let checkTotSolidPair = 0;
        for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
          let s = i + 1;
          checkTotSolidPair += Number(control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + s).value);
          let pair: number = control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + s).value;
          let divisible: boolean = pair % pairPerCartonRatio === 0 ? true : false;
          console.log('divisible  ' + divisible)
          anyPairDivisible.push(divisible);
        }

        let packCode = control.get('assortmentCode').value;
    
        let flag = 0;
        for (let i = 0; i < anyPairDivisible.length; i++) {
          if (anyPairDivisible[i]==false) {
            ++flag;
          }
        }
    
        if (distributionRequisitionQuantity == 0 || distributionRequisitionQuantity == '') {
          this.distributionRequsitionQuantityEmptyErrMsg = 'Distribution Requisition Quantity is empty at  line ' + (j + 1);
          inputboxValidation++;
          console.log(inputboxValidation);
          return;
        } else if (distributionOrderQuantity == 0 || distributionOrderQuantity == '') {
          this.distributionOrderQuantityEmptyErrMsg = 'Distribution req qty and Total pairs are different at line ' + (j + 1);
          inputboxValidation++;
          console.log(inputboxValidation);
          return;
        } else if( pairPerCartonRatio == 0 && assortmentTypes == SOLID_CODE){
            this.pairPerCartonRatioEmptyErrMsg = 'Pair per carton is empty at line ' + (j + 1);
            inputboxValidation++;
            console.log(inputboxValidation);
            return;
        } else if(checkTotSolidPair != distributionRequisitionQuantity  && assortmentTypes == SOLID_CODE && (assortmentCode == ''|| assortmentCode == null)){
            this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution requisition qty for  line ' + (j + 1);
            inputboxValidation++;
            console.log(inputboxValidation);
            return;
        } else if(assortmentTypes == SOLID_CODE && ((checkTotSolidPair > (1.2 * distributionRequisitionQuantity)) || (checkTotSolidPair < (0.8 * distributionRequisitionQuantity)))){
            this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (j + 1);
            inputboxValidation++;
            console.log(inputboxValidation);
            return;
        } else if(checkTotSolidPair != distributionOrderQuantity  && assortmentTypes == SOLID_CODE && assortmentCode != ''){
            this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Total Solid Pairs should be equal to distribution order qty for  line ' + (j + 1);
            inputboxValidation++;
            console.log(inputboxValidation);
            return;
          } else if (flag > 0 && packCode != null && packCode != '' && assortmentTypes == SOLID_CODE) {
            this.isPairDivisible = false;
            this.sizeWisePairErrMssg = 'Size wise pair must be divisible by pair per cartons for line ' + (j + 1);
            inputboxValidation++;
            console.log(inputboxValidation);
            return;
          } else {
            this.errorMessageBlank();
        }

        for (let size = 1; size <= sizeControlCount; size++) {
          let selectedAssortmentTypeId: string = control.get('assortmentTypes').value;
          if (selectedAssortmentTypeId != null && ASSORTED_CODE === selectedAssortmentTypeId) {
            packingAssortmentControlName = 'packingAssortment' + size;
            packingAssortment = control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).value;
            pairControlName = 'pair' + size;
            pair = control.get('packageAssortments').get('pairRow').get(pairControlName).value;
            pairPerCarton = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;
          }
          if (selectedAssortmentTypeId != null && (SOLID_CODE === selectedAssortmentTypeId || WHOLE_SALE_CODE === selectedAssortmentTypeId)) {
            packingAssortmentControlName = 'solid_packingAssortment' + size;
            packingAssortment = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).value;
            pairControlName = 'solid_pair' + size;
            pair = control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).value;
            pairPerCarton = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
          }

          if (packingAssortment !== 0 && pair != 0) {
            let requisitionAssortment = new RequisitionAssortment();
            requisitionAssortment.sizeLabel = this.sizepackingassortment[0][size - 1].artSize;
            requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket;
            requisitionAssortment.pairQty = packingAssortment;
            requisitionAssortment.totalPairQty = pair;
            requisitionAssortment.status = 'A';
            requisitionAssortment.createdBy = JSON.parse(this.sessUsername).groupAssignmentModels[0].userName;
            requisitionAssortments.push(requisitionAssortment);
          }
        }

        dataset.push(
          {

            assortmentCode: control.get('assortmentCode').value,
            rdcCode: k.locationArray.split('~')[1],
            rdcName: k.locationArray.split('~')[0],
            distributionReqQuantity: k.distributionRequisitionQuantity,
            totalArtQuantity: control.get('distributionOrderQuantity').value,
            assortmentType: k.assortmentTypes,
            cartonQty: control.get('numberOfCartons').value,
            status: 'A',
            createdBy: JSON.parse(this.sessUsername).groupAssignmentModels[0].userName,
            pairPerCarton: pairPerCarton,
            requisitionAssortments: requisitionAssortments
          }
        );

        j++;
      });

      var newdataset = {
        reqLineId: this.searchDisrtibutionDetails.reqLineId,
        orderedQuantity: this.searchDisrtibutionDetails.orderedQty,
        requisitionDistributions: dataset
      };
      let rdcCodeAssortmentTypeArray: any = [];
      for (let distributionLineIndex = 0; distributionLineIndex < dataset.length; distributionLineIndex++) {
        let distributionobj = dataset[distributionLineIndex];
        if (distributionobj.assortmentType == ASSORTED_CODE || distributionobj.assortmentType == SOLID_CODE) {
          rdcCodeAssortmentTypeArray.push(distributionobj.rdcCode);
        }
      }

      hasDuplicate = this.validateDuplicateRdcAndAssortmentTypeUsed(rdcCodeAssortmentTypeArray);
      if (!hasDuplicate && inputboxValidation == 0) {
        this.hasDuplicateDistibutionErrMsg = '';
        this._distributionDetailService.saveDistribution(newdataset)
          .subscribe(
            (response: ResponseMessage) => {
              if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
                this.responseMessage = 'Distribution Details saved successfully';
                this.distributionSaveButtonForm.get('saveDistributionButton').enable();
                this._router.navigate(['/requisition-details']);
              }
            }
          );
      }
    }
  }

  public openConfirmationDialog() {
    console.log('openConfirmationDialog');

  }

  resetDistributionLine(index: any) {
    this.errorMessageBlank();  
    this.deleteAssortment(index);
    this.addAssortment();
    this.isPrime = false;
    this.isTotalPairDistributionOrderQtyEqual = true;
    this.isPairDivisible = true;
    this.calculateBalance();
    // this.sumOfDistributionReqQuantity();
    // this.distributionSaveButtonForm.get('saveDistributionButton').disable();
  }

  onClickPairInput(event: any, assortment_index: any) {
    this.errorMessageBlank();  
    let control = this.distributionDetailsForm.get(['assortments', assortment_index]);
    let distReqQty = control.get('distributionRequisitionQuantity').value;
    console.log(' onClickPairInput : distReqQty ' + distReqQty);
    let pairPerCartons: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
    var checkTotSolidPair = 0;
    let anyPairDivisible: boolean[] = [];
    let assortmentCode = control.get('assortmentCode').value;
    console.log(' length ' + this.sizepackingassortment[0].length);
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      let j = i + 1;
      checkTotSolidPair += Number(control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value);
      let pair: number = control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value;
      let divisible: boolean = pair % pairPerCartons === 0 ? true : false;
      console.log('divisible  ' + divisible)
      anyPairDivisible.push(divisible);
      // this.sizeWisePndgArray[i]=this.sizeWisePndgArray[i]-((control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value) * pairPerCartons);
    }
    console.log('onClickPairInput ' + checkTotSolidPair);
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(checkTotSolidPair);


    let packCode = control.get('assortmentCode').value;

    let flag = 0;
    for (let i = 0; i < anyPairDivisible.length; i++) {
      if (!anyPairDivisible[i]) {
        ++flag;
      }
    }

    if (flag > 0 && packCode != null && packCode != '') {
      this.isPairDivisible = false;
      this.sizeWisePairErrMssg = 'Size wise pair must be divisible by pair per cartons for line '+(assortment_index+1);
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
    } else {
      this.isPairDivisible = true;
      this.sizeWisePairErrMssg = '';
      this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
    }

    if (packCode != null && packCode != '') {
      if ((checkTotSolidPair <= (1.2 * distReqQty)) && (checkTotSolidPair >= (0.8 * distReqQty))) {
        this.distibutionOrderQtyErrMsg = '';
        this.isTotalPairDistributionOrderQtyEqual = true;
        this.isPairDivisible == true ? control.get('distributionOrderQuantity').setValue(checkTotSolidPair) : '';
      }
      else {
        this.distibutionOrderQtyErrMsg = 'Distribution Order Qunatity should not be 20% greater or less than Distibution Requsition Quantity.';//TODO Validation error show this
        this.isTotalPairDistributionOrderQtyEqual = false;
        control.get('distributionOrderQuantity').setValue(0);
      }
      // if (checkTotSolidPair != control.get('distributionOrderQuantity').value) {
      //   this.isTotalPairDistributionOrderQtyEqual = false;
      //   this.pairDistOrdErerrMssg = 'Total solid pair must be equal to distributionorderquantity, please correct it.';
      // } else {
      //   // control.get('distributionOrderQuantity').setValue(control.get('distributionRequisitionQuantity').value);
      //   this.isTotalPairDistributionOrderQtyEqual = true;
      //   this.pairDistOrdErerrMssg = '';
      // }
    } else {
      // if (checkTotSolidPair != control.get('distributionRequisitionQuantity').value) {
      //   this.isTotalPairDistributionOrderQtyEqual = false;
      //   this.pairDistOrdErerrMssg = 'Total solid pair must be equal to distributionorderquantity.';
      // } else {
      control.get('distributionOrderQuantity').setValue(control.get('distributionRequisitionQuantity').value);
      this.isTotalPairDistributionOrderQtyEqual = true;
      this.pairDistOrdErerrMssg = '';
      let total_pair = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
      let distributionOrderQuantity = control.get('distributionOrderQuantity').value;
      let carton = total_pair != 0 ? Math.ceil(distributionOrderQuantity / total_pair) : '';
      control.get('numberOfCartons').setValue(carton);
      //}
    }
    this.checkRequisitionDistributionGreaterThanRequisitionOrder();
    console.log('isPairDivisible ' + this.isPairDivisible);
    console.log(' isTotalPairDistributionOrderQtyEqual ' + this.isTotalPairDistributionOrderQtyEqual);

    if (this.isTotalPairDistributionOrderQtyEqual == false || this.isPairDivisible == false
      || this.isdistributionRequsitionQuantityGreaterRequisitionQuantity == true || this.distReqQtyGreaterThanOrderQty == true) {
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    } else {
      this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
    }
  }

  disableSave() {
    let control = this.getAssortmentFormControl();
    console.log("---------disableSave--------------");
    
    console.log(control);
    
    let numberOfDistributionLines = (<FormArray>this.distributionDetailsForm.controls.assortments).length;
    if(control !=null){
        if (numberOfDistributionLines >= 1 && control.get('assortmentCode').value != null &&
        (ASSORTED_CODE == this.selectedAssortmentType || WHOLE_SALE_CODE == this.selectedAssortmentType || (SOLID_CODE == this.selectedAssortmentType))) {
        this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      }
      else {
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      }
    }
    
  }

  fetchEditableAssortmentPackages(editable_assortment_packages_param: any) {
    this.responseMessage = '';
    let editablePackingAssortments: EditablePackingAssortments[];
    let sizeBucketCount = 15;
    let groupCodeArray: string[];
    this._distributionDetailService.getEditableAssortmentPackagesForSolidAssortmentType(editable_assortment_packages_param)
      .subscribe(
        (response: EditablePackingAssortmentsModel) => {
          console.log(response);
          if (response != null && response.dataList != null && response.dataList.length > 0) {
            editablePackingAssortments = [];
            groupCodeArray = [];
            this.editablePackageAssortmentsArray = []
            editablePackingAssortments = response.dataList;
            editablePackingAssortments.forEach(
              e => {
                let sizeBucket = e.sizeBucket;

                let groupCode = e.groupCode;
                if (groupCode != null) {
                  groupCodeArray.push(groupCode);
                }
                for (let sizeBucketIndex = 1; sizeBucketIndex <= sizeBucketCount; sizeBucketIndex++) {
                  let sizeBucketIndexStr: string = sizeBucketIndex.toString();
                  if (sizeBucket != null && sizeBucket.includes(sizeBucketIndexStr)) {
                    let paEditable: number = +sizeBucket;
                    this.editablePackageAssortmentsArray.push(paEditable);
                  }
                }
                console.log(' sizeBucket ' + e.sizeBucket + ' | groupCode ' + groupCode);
              }
            );
          }
        }
      );


  }

  validateTotalPackingAssotmentIfPrime(assortment_index: any) {
    this.errorMessageBlank();  
    console.log('validateTotalPackingAssotmentIfPrime');
    let control = this.distributionDetailsForm.get(['assortments', assortment_index]);

    let paTotal = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
    console.log(' check prime ' + paTotal)
    if (paTotal != null && paTotal != 0) {
      //paTotal = Number(paTotal);
      this.isPrime = this.checkPrime(paTotal);
      console.log(' check prime ' + this.isPrime)
      if (this.isPrime) {
        this.primeErrMssg = 'Pair per carton is a PRIME number at line number ' + (assortment_index + 1) + ' , please correct it.';
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      } else {
        this.primeErrMssg = '';
        this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
      }
    }

    return this.isPrime;
  }

  checkPrime(n: any) {

    if (n == 1) {
      return false;
    }
    else if (n == 2) {
      return true;
    } else {
      for (var x = 2; x < n; x++) {
        if (n % x == 0) {
          return false;
        }
      }
      return true;
    }
  }

  validateAssortment(event: any, assortment_index) {
    console.log("----------------ValidateAssortment--------------");
    
    this.displayLoaderWhenAssortmentTypeWholeSale = true;
    var numbers = /^[0-9]+$/;
    let control = this.distributionDetailsForm.get(['assortments', assortment_index]);
    if (event.target.value.match(numbers)) {
      let location: string = control.get('locationArray').value;
      let selectedAssortmentTypeId: string = ASSORTED_CODE;

      if ((control.get('assortmentTypes').value != '' && control.get('assortmentTypes').value != null) && (location != '' && location != null)) {
        let selectedShopName = location.split('~')[0];
        selectedShopName = selectedShopName.substr(0, selectedShopName.length - 1);
        let selectedShopCode = location.split('~')[1];
        selectedShopCode = selectedShopCode.substr(0, selectedShopCode.length);
        let dataList1 = [];
        // let assortmentTypes: string = control.get('assortmentTypes').value; 
        let assortment_code_param = [
          { "name": "catCode", "value": this.selectedCategoryCode },
          { "name": "shopCode", "value": selectedShopCode },
          { "name": "assortmentType", "value": selectedAssortmentTypeId },
          { "name": "flag", "value": "N" },
          { "name": "groupCode", "value": this.groupCodeArray[0] },
          { "name": "concept", "value": this.searchDisrtibutionDetails.conceptCode }
        ];

        this._distributionDetailService.getAssortmensts(assortment_code_param).subscribe(
          (response: DynamicPackingMaster) => {
            if (response != null) {
              if (response.dataList != null && response.dataList.length > 0) {
                let dataListLength = response.dataList.length;
                this.dataList = [];
                this.packingMasterAssortmentsDTO = [];
                for (let i = 0; i < dataListLength; i++) {
                  if (response.dataList[i] != null && response.dataList[i].packingMasterAssortmentsDTO != null
                    && response.dataList[i].packingMasterAssortmentsDTO.length > 0) {
                    let totalPairQty = 0;
                    for (let j = 0; j < response.dataList[i].packingMasterAssortmentsDTO.length; j++) {
                      this.packingMasterAssortmentsDTO.push(response.dataList[i].packingMasterAssortmentsDTO[j]);
                      totalPairQty += response.dataList[i].packingMasterAssortmentsDTO[j].pairQty;
                      response.dataList[i].totalPairQty = totalPairQty;
                    }
                  }
                  this.dataList.push(response.dataList[i]);
                  dataList1.push(response.dataList[i]);
                  this.packingMaster = response.dataList;
                }

                let flag = 0;
                if (dataList1.length > 0) {
                  for (let k = 0; k < dataList1.length; k++) {
                    if (dataList1[k].packCode == event.target.value.toString()) {
                      flag++;
                      this.tabselectedAssortment(k, assortment_index);
                    }
                  }
                }
                if (flag == 0) {
                  this.assormentMessage = 'No matching assortment code found';
                  this.changeType(assortment_index);
                  setTimeout(() => {
                    this.assormentMessage = '';
                  }, 5000);
                }
                this.displayLoaderWhenAssortmentTypeWholeSale = false;
              }
              else {
                this.assormentMessage = response.message;
                this.changeType(assortment_index);
                setTimeout(() => {
                  this.assormentMessage = '';
                }, 5000);
                this.displayLoaderWhenAssortmentTypeWholeSale = false;
              }
            } else {
              this.assormentMessage = 'No data found';
              this.changeType(assortment_index);
              setTimeout(() => {
                this.assormentMessage = '';
              }, 5000);
              this.displayLoaderWhenAssortmentTypeWholeSale = false;
            }
          },
          (err) => {
            this.assormentMessage = err;
            this.changeType(assortment_index);
            setTimeout(() => {
              this.assormentMessage = '';
            }, 5000);
            this.displayLoaderWhenAssortmentTypeWholeSale = false;
          });
      } else {
        this.displayLoaderWhenAssortmentTypeWholeSale = false;
        this.responseMessage = 'Please choose location and assorment type.';
        this.changeType(assortment_index);
        setTimeout(() => {
          this.responseMessage = '';
        }, 5000);
      }
    } else {
      this.displayLoaderWhenAssortmentTypeWholeSale = false;
      this.responseMessage = 'Please enter numeric value only.';
      this.changeType(assortment_index);
      setTimeout(() => {
        this.responseMessage = '';
      }, 5000);
    }
  }

  tabselectedAssortment(index, assortment_index) {
    console.log(' tabselectedAssortment ');
    if (index != undefined) {
      let selectedPackingAssortment = this.packingMaster[index];
      let sizeBucketCount = this.sizepackingassortment[0].length;
      let control = this.distributionDetailsForm.get(['assortments', assortment_index]);
      var fakeArr = [];
      var blankDataSet = [];
      for (var j = 0; j < sizeBucketCount; j++) {
        blankDataSet.push({
          "artSize": this.sizepackingassortment[0][j].artSize,
          "articleSize": this.sizepackingassortment[0][j].sizeBucket,
          "pairQty": 0,
          "totalPairQty": 0
        });
      }
      selectedPackingAssortment.packingMasterAssortmentsDTO.forEach(k => {
        fakeArr.push(k);
      });

      var resultArray = [];
      resultArray = blankDataSet.reduce((arr, e) => {
        arr.push(Object.assign({}, e, fakeArr.find(a => e.articleSize == a.articleSize)))
        return arr;
      }, []);

      var totPackAssort = 0;
      if (control.get('assortmentTypes').value === ASSORTED_CODE) {
        if (resultArray.length > 0) {
          for (let i = 0; i < resultArray.length; i++) {
            let k = i + 1;
            control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment' + k).setValue(eval(`resultArray[${i}].pairQty`));
            totPackAssort += eval(`resultArray[${i}].pairQty`);
          }
          control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(totPackAssort);
        }
      } else {
        if (resultArray.length > 0) {
          for (let i = 0; i < resultArray.length; i++) {
            let k = i + 1;
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + k).setValue(eval(`resultArray[${i}].pairQty`));
            control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + k).setValue('0');
            control.get('numberOfCartons').setValue('');
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + k).disable();
            totPackAssort += eval(`resultArray[${i}].pairQty`);
          }
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + resultArray.length).disable();
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(totPackAssort);
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
        }
      }

      control.get('assortmentCode').setValue(selectedPackingAssortment.packCode);
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
      control.get('distributionOrderQuantity').setValue(0);
      control.get('distributionRequisitionQuantity').setValue(0);
      control.get('numberOfCartons').disable();

    }
  }

  changeType(assortment_index) {
    this.errorMessageBlank();  
    var control = this.distributionDetailsForm.get(['assortments', assortment_index]);
    control.get('assortmentCode').enable();
    control.get('assortmentCode').setValue('');
    control.get('numberOfCartons').setValue('');
    control.get('distributionRequisitionQuantity').setValue('');
    control.get('distributionOrderQuantity').setValue('');
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      let solidpairControlName = 'solid_pair' + j;
      let solidpackingAssortmentControlName = 'solid_packingAssortment' + j;
      let pairControlName = 'pair' + j;
      let packingAssortmentControlName = 'packingAssortment' + j;
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(solidpackingAssortmentControlName).setValue('');
      control.get('solid_packageAssortments').get('solid_pairRow').get(solidpairControlName).setValue('');
      control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).setValue('');
      control.get('packageAssortments').get('pairRow').get(pairControlName).setValue('');
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue('');
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue('');
      control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue('');
      control.get('packageAssortments').get('pairRow').get('totalPairs').setValue('');
    }
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
  }

  errorMessageBlank(){
    this.responseMessage = '';
    this.primeErrMssg = '';
    this.sizeWisePairErrMssg = '';
    this.pairDistOrdErerrMssg = '';
    this.hasDuplicateDistibutionErrMsg = '';
    this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
    this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
    this.distributionRequsitionQuantityEmptyErrMsg = '';
    this.distributionOrderQuantityEmptyErrMsg = '';
    this.distibutionOrderQtyErrMsg = '';
    this.pairPerCartonRatioEmptyErrMsg = '';
  }

}
