import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ShortageAdjustmentService } from "./shortage-adjustment.service"; 

@Component({
  selector: "app-shortage-adjustment",
  templateUrl: "./shortage-adjustment.component.html",
  styleUrls: [
    "../../../assets/css/bootstrap.css",
    "../../../assets/css/rds-bil-style.css",
    "../../../../node_modules/font-awesome/css/font-awesome.css",
    "./shortage-adjustment.component.css",
  ],
})
export class ShortageAdjustmentComponent implements OnInit {
  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  @ViewChild('closeBtn1', { static: false }) closeBtn1: ElementRef;
  @ViewChild('closeBtn2', { static: false }) closeBtn2: ElementRef;
  @ViewChild('shortageBtn', { static: false }) shortageBtn: ElementRef;

  userCode: string;
  responseMessage: string;
  responseMessage1: string;
  responseMessage2: string;
  shortageAdjustmentList: any[];
  shortageAdjustmentDrpdwn: any[];
  shortageAdjustmentFinalDrpdwn: any[];
  shortageAdjustmentFinalDrpdwnCheck:any;
  stockAdjustmentForm: FormGroup;
  selectedAdjustment: any[];
  selectedAdjustmentFinal: any[];
  performAdjustmentList: any = {};
  selectedAdjustmentDisplayList: any[];
  headerDisplayList: any[];
  performAdjustmentArray: any[];
  segment: string;
  shortageAdjustment: any[];
  remarks: string;
  isShowDiv = true;
  selectedRow: any[];
  artNumber: string;
  grnId: any;
  assortArray: any[];
  assortData: any = {};
  groupCode: string;
  parentId: number;
  childId: number;
  childrenId: number;
  arr = ['fromDate', 'toDate'];
  dateOfYear1 = '';
  dateTime = new Date();
  shortAdjDebitedToCodeDisplay: number;
  performRowIndex: any;
  listObj: any = [];
  consignorCode: string;
  consignorName: string;
  inputboxValidation: number;
  isSave: number = 0;
  display='none';
  display2='none';
  display3='none';
  display4='none';
  display5='none';
  traporterBoxShow :Boolean=true;
  grnType:any;
  inventoryType:any;
  grnIndex:number;

  constructor(
    private fb: FormBuilder,
    private ShortageAdjustmentService: ShortageAdjustmentService
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    //this.userCode = "user.test4";
    this.dateTime.setDate(this.dateTime.getDate());
    this.buildForm();
  }

  buildForm(): void {
    this.stockAdjustmentForm = this.fb.group({
      invoiceNumber: [""],
      grnNumber: [""],
      bataWeekFrom: ["", [Validators.maxLength(6), Validators.minLength(6)]],
      bataWeekTo: ["", [Validators.maxLength(6), Validators.minLength(6)]],
      invoiceFromDate: [""],
      invoiceToDate: [""],
      userCode: [this.userCode],
    });
  }

  ngOnInit() {
    this.assortArray = [];
    this.responseMessage2 = '';
    // this.selectedRow = [];
    // this.selectedAdjustment = [];
    // this.selectedAdjustmentDisplayList = [];
    // this.headerDisplayList = [];
    // this.listObj = [];
    setTimeout(() => {
      //this.maxDate = new Date();
      if (this.selectedRow == undefined) {
        this.selectedRow = [];
      }
      if (this.selectedAdjustment == undefined) {
        this.selectedAdjustment = [];
      }  
      if (this.selectedAdjustmentFinal == undefined) {
        this.selectedAdjustmentFinal = [];
      }    
      if (this.selectedAdjustmentDisplayList == undefined) {
        this.selectedAdjustmentDisplayList = [];
      }
      if (this.headerDisplayList == undefined) {
        this.headerDisplayList = [];
      }
      if (this.listObj == undefined) {
        this.listObj = [];
      }
      this.shortageAdjustment = null;
      this.remarks = "";
      this.loaDer.nativeElement.style.display = "none";
      var details = [
        {
          name: "paramName",
          value: "SHORTAGE_ADJUSTED_TO",
        },
      ];
      this.ShortageAdjustmentService.getshortageAdjustmentDrpdwn(
        details
      ).subscribe(
        (data: any) => {
          this.shortageAdjustmentDrpdwn = data.configParamModels;
          console.log(this.shortageAdjustmentDrpdwn);
        },
        (err) => {
          this.responseMessage = err;
        }
      );
    }, 100);
  }

  onReset() {
    this.stockAdjustmentForm.reset();
    setTimeout(() => {
      this.buildForm();
    }, 500);
    this.shortageAdjustmentList = [];
    this.selectedAdjustment = [];
    this.selectedAdjustmentDisplayList = [];
    this.responseMessage = "";
    this.responseMessage1 = "";
  }

  changeDate(value, key) {
    this.responseMessage = '';
    const myDate = new Date(value);
    const mnth = ('0' + (myDate.getMonth() + 1)).slice(-2);
    const day = ('0' + myDate.getDate()).slice(-2);
    this.dateOfYear1 = [day, mnth, myDate.getFullYear()].join('/');
    if (key === 'fromDate') {
      this.stockAdjustmentForm.controls["invoiceFromDate"].setValue(this.dateOfYear1);
    } else {
      this.stockAdjustmentForm.controls["invoiceToDate"].setValue(this.dateOfYear1);
    }
  }

  searchStockAdjustment() {
    setTimeout(() => {
      this.responseMessage = "";
    }, 3000);
    if (this.stockAdjustmentForm.controls["invoiceNumber"].value == '' && this.stockAdjustmentForm.controls["grnNumber"].value == '' && this.stockAdjustmentForm.controls["bataWeekFrom"].value == '' && this.stockAdjustmentForm.controls["bataWeekTo"].value == '' && this.stockAdjustmentForm.controls["invoiceFromDate"].value == '' && this.stockAdjustmentForm.controls["invoiceToDate"].value == '') {
      this.responseMessage = "Please select any search criteria.";
      return false;
    } else {
      const startDateFC = this.stockAdjustmentForm.controls["invoiceFromDate"].value;
      const endDateFC = this.stockAdjustmentForm.controls["invoiceToDate"].value;
      const FromDate = startDateFC.split('/');
      const startDate = new Date(FromDate[2], FromDate[1], FromDate[0]);
      const ToDate = endDateFC.split('/');
      const endDate = new Date(ToDate[2], ToDate[1], ToDate[0]);

      if (startDateFC != "" && endDateFC == "") {
        this.responseMessage = "Please select invoice To Date.";
        return false;
      } else if (startDateFC == "" && endDateFC != "") {
        this.responseMessage = "Please select invoice From Date.";
        return false;
      } else if (startDateFC != "" && endDateFC != "") {
        if (startDate.getTime() > endDate.getTime()) {
          this.responseMessage = "invoice From Date is greater than invoice To Date.";
          return false;
        }
      }
      this.assortArray = [];
      this.shortageAdjustmentFinalDrpdwn = [];
      this.shortageAdjustmentFinalDrpdwnCheck=[];
      this.selectedRow = [];
      this.shortageAdjustmentList = [];
      this.loaDer.nativeElement.style.display = "block";
      this.segment = "";
      this.listObj = [];
      this.selectedAdjustment = [];
      this.selectedAdjustmentDisplayList = [];
      this.ShortageAdjustmentService.getStockAdjustmentDetails(
        this.stockAdjustmentForm.value
      ).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          if (response.dataList != null && response.dataList.length !== 0) {
            for(let x=0;x<response.dataList.length;x++) {
              if ( this.listObj.findIndex(i=>i.grnNumber == response.dataList[x]['grnNumber']) == -1 ) {
                this.listObj.push({
                  "invoiceNumber":response.dataList[x]['invoiceNumber'],
                  "grnNumber":response.dataList[x]['grnNumber'],
                  "conceptCode":response.dataList[x]['conceptCode'],
                  "shopCode":response.dataList[x]['shopCode'],
                  "transporterCode":response.dataList[x]['transporterCode'],
                  "transporterName":response.dataList[x]['transporterName'],
                  "children":[]
                });
              }
              let index = this.listObj.findIndex(i=>i.grnNumber == response.dataList[x]['grnNumber']) ;
              this.listObj[index]['children'].push(response.dataList[x]);
            }
            console.log(this.listObj);
            if(this.listObj.length >0){
              for (let i = 0; i < this.listObj.length; i++) {
                this.performAdjustmentList[i] = {};
                this.performAdjustmentList[i][1] = {};
                this.performAdjustmentList[i][2] = {};                
                if(this.listObj[i].children.length >0){
                  for (let j = 0; j < this.listObj[i].children.length; j++) {
                    this.performAdjustmentList[i][1][j] = {};
                    this.performAdjustmentList[i][2][j] = {};
                    let artnumber = this.listObj[i].children[j].artNumber;
                    this.performAdjustmentList[i][1][artnumber] = {};
                    this.performAdjustmentList[i][2][artnumber] = {};
                    this.performAdjustmentList[i][1][j]['isSave'] = 0;
                    this.performAdjustmentList[i][2][j]['isSave'] = 0;
                    this.performAdjustmentList[i][1][j]['openPopup'] = 0;
                    // populating the value of claimed amount for claimed GRN else 0
                    this.listObj[i].children[j]['claimedAmount'] = 0;
                    this.listObj[i].children[j].goodsReceiptNoteAssortments.forEach(
                      each => {
                        if(each.grnAssortmentClaimSlipEntities.length > 0){
                          each.grnAssortmentClaimSlipEntities.forEach(claim => {
                            this.listObj[i].children[j]['claimedAmount'] += Number(claim.shortagePairs*claim.claimedAmount);
                          }) 
                        }
                      }
                    )

                  }   
                }
              }
            }
            this.shortageAdjustmentList = response.dataList;
            console.log(this.shortageAdjustmentList);
            for (let i = 0; i < this.shortageAdjustmentList.length; i++) {
              this.shortageAdjustmentFinalDrpdwn[i] = [];
              this.shortageAdjustmentFinalDrpdwnCheck.push(this.shortageAdjustmentList[i].grnId);
              for (let j = 0; j < this.shortageAdjustmentDrpdwn.length; j++) {
                if (this.shortageAdjustmentDrpdwn[j].paramValue == 'INSURANCE') {
                  this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                }
                if (this.shortageAdjustmentList[i].consignorType == 'SUPPLIER') {
                  if (this.shortageAdjustmentDrpdwn[j].paramValue == 'VENDOR') {
                    this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                  }
                }
                else if (this.shortageAdjustmentList[i].consignorType == 'FACTORY') {
                  if (this.shortageAdjustmentDrpdwn[j].paramValue == 'FACTORY') {
                    this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                  }
                }
                else if (this.shortageAdjustmentList[i].consignorType == 'RDC') {
                  if (this.shortageAdjustmentDrpdwn[j].paramValue == 'RDC') {
                    this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                  }
                }
                else if (this.shortageAdjustmentList[i].consignorType == 'DEPOT') {
                  if (this.shortageAdjustmentDrpdwn[j].paramValue == 'DEPOT') {
                    this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                  }
                }
                else if (this.shortageAdjustmentList[i].consignorType == 'STORE') {                  
                 
                  if(this.shortageAdjustmentList[i].grnType == "STORE_SHORTAGE"){
                    if (this.shortageAdjustmentDrpdwn[j].paramValue == 'VENDOR') {
                      this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                    }
                    if (this.shortageAdjustmentDrpdwn[j].paramValue == 'FACTORY') {
                      this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                    }
                  } else {
                    if (this.shortageAdjustmentDrpdwn[j].paramValue == 'STORE') {
                      this.shortageAdjustmentFinalDrpdwn[i].push(this.shortageAdjustmentDrpdwn[j]);
                    }
                  }
                }
              }
            }
            console.log(this.shortageAdjustmentFinalDrpdwn);

            console.log("shortageAdjustmentFinalDrpdwnCheck");
            console.log(this.shortageAdjustmentFinalDrpdwnCheck);
            
            let details = [
              {
                name: "concCode",
                value: this.shortageAdjustmentList[0].conceptCode,
              },
            ];
            this.ShortageAdjustmentService.getsegment(details).subscribe(
              (response1: any) => {
                console.log(response1[0].division);
                this.segment = response1[0].division;
              }
            );
          } else {
            this.responseMessage = "No Data Found";
          }
        },
        (err) => {
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.loaDer.nativeElement.style.display = "none";
        }
      );
    }
  }

  isAllSelected(parent, child, event: any,childrenArray,artNumber,grnId,grnType) {
    setTimeout(() => {
      this.responseMessage1 = '';
    }, 200);
    let children = this.listObj[parent].children.findIndex(i=>i.grnId == grnId);
    let id = event.target.id;
    let array_val = parent + '_' + child + '_' + children;
    const ele = document.getElementById(id) as HTMLInputElement;
    if (ele.checked == true) {
      if (this.selectedAdjustment.indexOf(array_val) == -1) {
        this.selectedAdjustment.push(array_val);
      }
    } else {
      if (this.selectedAdjustment.indexOf(array_val) !== -1) {
        this.selectedAdjustment.splice(this.selectedAdjustment.indexOf(array_val), 1);
        if(child == 1){
          this.performAdjustmentList[parent][child][children]['transporter_comment'] = "";
        }
        if(child == 2){
          this.performAdjustmentList[parent][child][children]['consignorCode'] = "";
          this.performAdjustmentList[parent][child][children]['consignorName'] = "";        
          this.performAdjustmentList[parent][child][children]['consignor_comment'] = "";
          this.performAdjustmentList[parent][child][children]['shortageAdjustment'] = "";
          this.performAdjustmentList[parent][child][children]['shortAdjDebitedToCode'] = "";
        }        
        this.performAdjustmentList[parent][child][children]['sumQty'] = 0; 
        this.performAdjustmentList[parent][child][artNumber]['sumQty'] = 0;
        this.performAdjustmentList[parent][child][children]['assort'] = [];
        this.assortmentDataPopup(grnId, artNumber, parent,grnType,this.inventoryType);
      }
    }
  }

  adjustmentSelected(parent, child, param: any,grnId){ 
    let children = this.listObj[parent].children.findIndex(i=>i.grnId == grnId);
    let array_val = parent + '_' + child + '_' + children;
    if(((this.shortageAdjustmentList[parent].consignorType == 'STORE' && param == 'VENDOR') || (this.shortageAdjustmentList[parent].consignorType == 'STORE' && param == 'FACTORY')) && this.shortageAdjustmentList[parent].rcvdPairQty == 0){
      if (this.selectedAdjustmentDisplayList.indexOf(array_val) == -1) {
        this.selectedAdjustmentDisplayList.push(array_val);
      }
    } else {
      this.selectedAdjustmentDisplayList.splice(this.selectedAdjustmentDisplayList.indexOf(array_val), 1);
      this.performAdjustmentList[parent][child][children]['shortAdjDebitedToCode'] = "";
    }
    if((param == 'STORE' && this.shortageAdjustmentList[parent].grnType != "STORE_SHORTAGE") || param == 'FACTORY' || param == 'RDC' || param == 'DEPOT'){
      this.performAdjustmentList[parent][child][children]['consignorCode'] = this.shortageAdjustmentList[parent].consignorCode;
      this.performAdjustmentList[parent][child][children]['consignorName'] = this.shortageAdjustmentList[parent].consignorName;
    } else if(param == 'VENDOR'){
      this.performAdjustmentList[parent][child][children]['consignorCode'] = this.shortageAdjustmentList[parent].partyNumber;
      this.performAdjustmentList[parent][child][children]['consignorName'] = "";
    } else {
      this.performAdjustmentList[parent][child][children]['consignorCode'] = "";
      this.performAdjustmentList[parent][child][children]['consignorName'] = "";
    }
  }

  characterCheck(event, name) {
    var regExpr = /[^a-zA-Z0-9-. ]/g;
    var userText = event.target.value;
    if (userText != "") {
      if (name == "invoiceNo") {
        this.stockAdjustmentForm.controls["invoiceNumber"].setValue(
          userText.replace(regExpr, "")
        );
      }
      if (name == "grnNumber") {
        this.stockAdjustmentForm.controls["grnNumber"].setValue(
          userText.replace(regExpr, "")
        );
      }
    }
  }

  showHideDiv(index) {
    let arr_index = this.selectedRow.indexOf(index);
    if (arr_index !== -1) {
      this.selectedRow.splice(arr_index, 1);
      document.getElementById('showHideDiv' + index).classList.remove("active");
    } else {
      this.selectedRow.push(index);
      document.getElementById('showHideDiv' + index).classList.add("active");
    }
  }

  assortmentDataPopup(grnId, artNumber, parent,grnType,inventoryType) {
      if(grnType == 'INTRA_RDC_SUPPLY'){
        this.traporterBoxShow=false;
      }else{
        this.traporterBoxShow=true;
      }  
      console.log(this.traporterBoxShow);
      this.display4='block';
      this.inventoryType = inventoryType;   
      console.log(this.selectedAdjustmentFinal);
      let children = this.listObj[parent].children.findIndex(i=>i.grnId == grnId);       
      this.isSave = 0;
      this.responseMessage1 = "";
      this.assortArray = [];
      for(let i = 1; i<=2; i++){       
        this.performAdjustmentList[parent][i][children]['openPopup'] = 1;         
        if(this.performAdjustmentList[parent][i][children]['assort'] == undefined || this.performAdjustmentList[parent][i][children]['assort'] == null){
          this.performAdjustmentList[parent][i][children]['assort'] = [];
        }
        if(this.performAdjustmentList[parent][i][children]['quantity'] == undefined || this.performAdjustmentList[parent][i][children]['quantity'] == null){
          this.performAdjustmentList[parent][i][children]['quantity'] = [];
        }
        if((this.performAdjustmentList[parent][i][children]['transporter_comment'] == undefined || this.performAdjustmentList[parent][i][children]['transporter_comment'] == null) && i == 1){
          this.performAdjustmentList[parent][i][children]['transporter_comment'] = [];
        }
        if((this.performAdjustmentList[parent][i][children]['consignor_comment'] == undefined || this.performAdjustmentList[parent][i][children]['consignor_comment'] == null) && i == 2){
          this.performAdjustmentList[parent][i][children]['consignor_comment'] = [];
        }
        if(this.performAdjustmentList[parent][i][children]['sumQty'] == undefined){
          this.performAdjustmentList[parent][i][children]['sumQty'] = 0;
        }
        if(this.performAdjustmentList[parent][i][artNumber]['sumQty'] == undefined){
          this.performAdjustmentList[parent][i][artNumber]['sumQty'] = 0;
        }      
        // let array_val = parent + '_' + i + '_' + children;      
        // if (this.selectedAdjustment.indexOf(array_val) == -1){
        //   let id = "checkbox"+array_val;
        //   const ele = document.getElementById(id) as HTMLInputElement;
        //   if(ele != null)
        //   ele.checked = false;     
        // } 
      }      
      this.parentId = parent;
      //this.childId = child;
      this.childrenId = children;
      this.grnIndex = this.shortageAdjustmentFinalDrpdwnCheck.indexOf(grnId);
      let firstletter = artNumber.charAt(0);
      let index = this.shortageAdjustmentList.findIndex(
        (x) => x.grnId == grnId
      );
      if (index != -1) {
        this.assortData = this.shortageAdjustmentList[index];
        this.artNumber = artNumber;
        this.grnId = grnId;
        this.grnType=grnType;
        let details = [{ "name": "letterOfArticle", "value": firstletter }];
        this.ShortageAdjustmentService
          .getSizeDetails(details)
          .subscribe(
            (response: any) => {
              if (response.status == "Success") {
                this.responseMessage1 = "";
                this.groupCode = response.dataList[0].groupCode;
                this.assortArray = [];  
                let index = 0;           
                          
                response.dataList.forEach(data => {
                  var assortmentArray = this.assortData.goodsReceiptNoteAssortments;
                  var found = assortmentArray.findIndex(element => element.articleSize == data.artSize);
                  if (found != -1) {
                    for(let i = 1; i<=2; i++){                  
                      //this.assortArray[i] = [];
                      if(this.performAdjustmentList[parent][i][children]['assort'][index] == undefined || this.performAdjustmentList[parent][i][children]['assort'][index] == null){
                        // this.performAdjustmentList[parent][i][children]['assort'][index] = [];
                       
                        if(this.inventoryType == 'CLAIMED'){
                          console.log("---------------------------------");
                          console.log("assortmentArray[found].grnAssortmentClaimSlipEntities");
                          console.log(assortmentArray[found].grnAssortmentClaimSlipEntities);
                          if(assortmentArray[found].grnAssortmentClaimSlipEntities.length > 0){
                            assortmentArray[found].grnAssortmentClaimSlipEntities.forEach(
                              claimData => {
                                this.performAdjustmentList[parent][i][children]['assort'].push({
                                  "pairQty": "", "artSize": assortmentArray[found].articleSize, "sizeLabel": data.sizeBucket, "claimSlipNum": claimData.claimSlipNo, "grnAssortmentClaimSlipId": claimData.assortmentClaimSlipId
                                  });
                                  // index++;
                              }
                            );
                            // index--;
                            if(i == 2 && assortmentArray[found].grnAssortmentClaimSlipEntities.length > 0){
                              index = this.performAdjustmentList[parent][i][children]['assort'].length - 1;
                            }
                          } else{
                            this.performAdjustmentList[parent][i][children]['assort'][index] = {
                              "pairQty": "", "artSize": assortmentArray[found].articleSize, "sizeLabel": data.sizeBucket, "claimSlipNum": null, "grnAssortmentClaimSlipId": null
                              }; 
                          }} else{
                            this.performAdjustmentList[parent][i][children]['assort'][index] = {
                              "pairQty": "", "artSize": assortmentArray[found].articleSize, "sizeLabel": data.sizeBucket
                          }
                        }; 
                     } 
                    }
                    if(this.inventoryType == 'CLAIMED'){
                        if(assortmentArray[found].grnAssortmentClaimSlipEntities.length > 0){
                          assortmentArray[found].grnAssortmentClaimSlipEntities.forEach(
                            claimData => {
                              let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].articleSize, sizeWiseQty: claimData.shortagePairs, claimSlipNum: claimData.claimSlipNo };
                              this.assortArray.push(assortmentData);
                            }
                          );
                        } else{
                          let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].articleSize, sizeWiseQty:assortmentArray[found].shortagePairQty, claimSlipNum: null };
                          this.assortArray.push(assortmentData);
                        }
                    } else{
                      let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].articleSize, sizeWiseQty: assortmentArray[found].shortagePairQty};
                      //this.assortArray[i].push(assortmentData);
                      this.assortArray.push(assortmentData);
                    }                
                    index++;
                  }
                });  
                console.log("performAdjustmentList")
                console.log(JSON.stringify(this.performAdjustmentList));
                console.log("==============================")
                console.log("assortArray")
                console.log(this.assortArray);     
              }
            },
            (err) => {
              this.responseMessage1 = "No record found";
              this.assortArray = [];
            }
          );          
      }
    //}
  }

  closeAssortment(parent,artNumber,grnId) {  
    let children = this.listObj[parent].children.findIndex(i=>i.grnId == grnId);
    //this.selectedAdjustment = [];
    //this.selectedAdjustmentDisplayList = [];
    console.log(this.selectedAdjustment);
    for(let i = 1; i<=2; i++){  
      console.log(this.performAdjustmentList[parent][i][children]['isSave']);
      this.performAdjustmentList[parent][i][children]['openPopup'] = 0;
      if(this.performAdjustmentList[parent][i][children]['isSave'] != 1){
        let array_val = parent + '_' + i + '_' + children;  
        if (this.selectedAdjustment.indexOf(array_val) !== -1) {
          this.selectedAdjustment.splice(this.selectedAdjustment.indexOf(array_val), 1);
        }    
        if (this.selectedAdjustmentDisplayList.indexOf(array_val) !== -1) {
          this.selectedAdjustmentDisplayList.splice(this.selectedAdjustmentDisplayList.indexOf(array_val), 1);
        }
        this.performAdjustmentList[parent][i][children]['assort'] = [];
        this.performAdjustmentList[parent][i][children]['quantity'] = [];
        this.performAdjustmentList[parent][i][children]['transporter_comment'] = [];
        this.performAdjustmentList[parent][i][children]['consignor_comment'] = [];
        this.performAdjustmentList[parent][i][children]['consignorCode'] = "";
        this.performAdjustmentList[parent][i][children]['consignorName'] = "";    
        this.performAdjustmentList[parent][i][children]['shortageAdjustment'] = "";
        this.performAdjustmentList[parent][i][children]['shortAdjDebitedToCode'] = "";
        this.performAdjustmentList[parent][i][children]['sumQty'] = 0;
        this.performAdjustmentList[parent][i][artNumber]['sumQty'] = 0;
      }
    } 
    this.isSave = 1;
    this.display3='none'; 
    // for (let child = 1; child <= 2; child++) {      
    //   let array_val = parent + '_' + child + '_' + children;      
    //   if (this.selectedAdjustment.indexOf(array_val) == -1){
    //     let id = "checkbox"+array_val;
    //     console.log(id);
    //     const ele = document.getElementById(id) as HTMLInputElement;
    //     ele.checked = false;     
    //   }  
    // }  
  }

  closeAssortment1(parent,artNumber,grnId) {  
    let children = this.listObj[parent].children.findIndex(i=>i.grnId == grnId);
    // this.selectedAdjustment = [];
    // this.selectedAdjustmentDisplayList = [];
    // this.performAdjustmentList[parent] = {};
    for(let i = 1; i<=2; i++){  
      this.performAdjustmentList[parent][i][children]['openPopup'] = 0;  
      // this.performAdjustmentList[parent][i] = {};
      // this.performAdjustmentList[parent][i][children] = {};
      // this.performAdjustmentList[parent][i][artNumber] = {};
      let array_val = parent + '_' + i + '_' + children;  
      if (this.selectedAdjustment.indexOf(array_val) !== -1) {
        this.selectedAdjustment.splice(this.selectedAdjustment.indexOf(array_val), 1);
      }    
      if (this.selectedAdjustmentDisplayList.indexOf(array_val) !== -1) {
        this.selectedAdjustmentDisplayList.splice(this.selectedAdjustmentDisplayList.indexOf(array_val), 1);
      }
      this.performAdjustmentList[parent][i][children]['assort'] = [];
      this.performAdjustmentList[parent][i][children]['quantity'] = [];
      this.performAdjustmentList[parent][i][children]['transporter_comment'] = [];
      this.performAdjustmentList[parent][i][children]['consignor_comment'] = [];
      this.performAdjustmentList[parent][i][children]['consignorCode'] = "";
      this.performAdjustmentList[parent][i][children]['consignorName'] = "";    
      this.performAdjustmentList[parent][i][children]['shortageAdjustment'] = "";
      this.performAdjustmentList[parent][i][children]['shortAdjDebitedToCode'] = "";
      this.performAdjustmentList[parent][i][children]['sumQty'] = 0;
      this.performAdjustmentList[parent][i][artNumber]['sumQty'] = 0;
    }
    
    this.display3='none'; 
  }

  saveAssortment(parent, children,artNumber,grnId) {
    let sum: number = 0;
    for (let child = 1; child <= 2; child++) {   
      this.performAdjustmentList[parent][child][children]['openPopup'] = 1;   
      let assorment = this.performAdjustmentList[parent][child][children]['assort'];
      var length = Object.keys(assorment).length;
      let qty: number = 0;
      for (let i = 0; i < length; i++) {
        let pairqty = parseInt(assorment[i]['pairQty']);
        if (isNaN(pairqty)) { pairqty = 0; }
        qty = qty + pairqty;
      }
      this.performAdjustmentList[parent][child][children]['quantity'] = qty;      
    }
    console.log(this.performAdjustmentList);
    this.performAdjustmentConfirm(parent,children,artNumber,grnId);
  }

  performAdjustmentConfirm(index,children,artNumber,grnId) {
    this.selectedAdjustmentFinal = [];
    this.performRowIndex = index;
    let selectedPerform_adjustment = this.performAdjustmentList[index]; 
    this.inputboxValidation = 0;
    this.responseMessage1 = "";
    if (this.selectedAdjustment.length > 0) {
      for (var j = 0; j < 2; j++) {       
        if (this.selectedAdjustment.indexOf(index + "_" + (j + 1) + "_" + children) !== -1) {
          if (selectedPerform_adjustment[j + 1][children].shortageAdjustment == undefined && j == 1) {
            this.responseMessage1 = "Please select shortage adjustment";
            this.inputboxValidation++;
            return false;
          } 
          else if (j == 1 && (selectedPerform_adjustment[j + 1][children].consignor_comment == undefined || selectedPerform_adjustment[j + 1][children].consignor_comment == '')){
            this.responseMessage1 = "Please write any remarks for  Consignor/Insurance";           
            this.inputboxValidation++;
            return false;
          }
          else if (j == 0 && (selectedPerform_adjustment[j + 1][children].transporter_comment == undefined || selectedPerform_adjustment[j + 1][children].transporter_comment == '')) {
            this.responseMessage1 = "Please write any remarks for Transporter";            
            this.inputboxValidation++;
            return false;
          }
          else if (this.selectedAdjustmentDisplayList.indexOf(index + "_" + (j + 1) + "_" + children) !== -1 && (selectedPerform_adjustment[j + 1][children].shortAdjDebitedToCode == "" || selectedPerform_adjustment[j + 1][children].shortAdjDebitedToCode == undefined)) {
            this.responseMessage1 = "Please enter  Consignor/Insurance debited to code";
            this.inputboxValidation++;
            return false;
          }
          else if(selectedPerform_adjustment[j+1][children]['sumQty'] == 0){
            if(j == 0){
              this.responseMessage1 = "Sum of Sizewise adjustment Quantity must be greater than zero for Transporter";
            } else {
              this.responseMessage1 = "Sum of Sizewise adjustment Quantity must be greater than zero for Consignor/Insurance";
            }            
            this.inputboxValidation++;
            return false;
          } else {            
            //if(j == 0){
              if (this.listObj[index].children.length > 0) {
                //for (var k = 0; k < this.listObj[index].children.length; k++) {
                  let shortageQty: number = parseInt(this.listObj[index].children[children].shortagePairQty);
                  let assortQty1 = parseInt(selectedPerform_adjustment[1][children]['quantity']);
                  if (isNaN(assortQty1)) { assortQty1 = 0; }
                  let assortQty2 = parseInt(selectedPerform_adjustment[2][children]['quantity']);
                  if (isNaN(assortQty2)) { assortQty2 = 0; }
                  if ((assortQty1 + assortQty2) != shortageQty) {
                    this.responseMessage1 = "Sum of Adjusted Quantity should be matched with Shortage Quantity";
                    this.inputboxValidation++;
                    return false;
                  }
                //}
              }
            //}
          }
        }
      }
    } else {
      this.responseMessage1 = "Please check Transporter or Shortage adjustment.";
      this.inputboxValidation++;
      return false;
    }
    if (this.inputboxValidation == 0) {
      this.isSave = 1;
      for (let child = 1; child <= 2; child++) {
        this.performAdjustmentList[index][child][children]['isSave'] = 1;
        let array_val = index + '_' + child + '_' + children;
        if (this.selectedAdjustmentFinal.indexOf(array_val) == -1) {
          this.selectedAdjustmentFinal.push(array_val);
        }
      }      
      setTimeout(() => {
        this.closeAssortment(index,artNumber,grnId)
        //this.closeBtn.nativeElement.click();
      }, 200);
    }
  }

  performButtonClick(){
    let index = this.performRowIndex;
    let selectedPerform_adjustment = this.performAdjustmentList[index];
    let shortPairQtyTotal:number = 0;
    let adjPairQtyTotal:number = 0;
    let inputboxValidation1:number = 0;
    this.responseMessage2 = '';

    if (this.listObj[index] && this.listObj[index].children.length > 0) {
      for(let q = 0; q < this.listObj[index].children.length; q++){
        shortPairQtyTotal = shortPairQtyTotal + this.listObj[index].children[q].shortagePairQty;
        let sumQty1 = parseInt(selectedPerform_adjustment[1][q]['sumQty']);
        if (isNaN(sumQty1)) { sumQty1 = 0; }
        let sumQty2 = parseInt(selectedPerform_adjustment[2][q]['sumQty']);
        if (isNaN(sumQty2)) { sumQty2 = 0; }
        adjPairQtyTotal = adjPairQtyTotal + (sumQty1 + sumQty2);

        let shortageQty: number = parseInt(this.listObj[index].children[q].shortagePairQty);
        let assortQty1 = parseInt(selectedPerform_adjustment[1][q]['quantity']);
        if (isNaN(assortQty1)) { assortQty1 = 0; }
        let assortQty2 = parseInt(selectedPerform_adjustment[2][q]['quantity']);
        if (isNaN(assortQty2)) { assortQty2 = 0; }
        if ((assortQty1 + assortQty2) != shortageQty) {
          this.responseMessage2 = "Adjusted Quantity should be matched with Shortage Quantity for line "+(index+1);            
          inputboxValidation1++; 
          this.display='none';
          this.display2='block';       
          return false;  
        }
      }
    }    
    this.performAdjustmentArray = [];
    if (shortPairQtyTotal != adjPairQtyTotal) {
        this.responseMessage2 = "Shortage Pair Quantity must be qual to Adjusted Quantity.";
        inputboxValidation1++;
        this.display='none';
        this.display2='block';        
        return false;   
    }
    if(this.inputboxValidation == 0 && inputboxValidation1 == 0){
      this.responseMessage2 = '';
      console.log('if');
      this.display2='none';
      this.display='block';
      // setTimeout(() => {
      //   this.closeBtn2.nativeElement.click();
      // }, 200);
    } else {
      this.responseMessage2 = "Please fill correct adjustment quantity and other details in assortment action popup.";
      this.display='none';
      this.display2='block';      
      return false; 
    }
  }

  performAdjustmentClose() {
    this.display='none';
  }

  performAdjustmentErrorClose() {
    this.display2='none';
  }

  performAdjustmentChangeOpen() {
    this.display3 ='block';
    this.display4 ='none';
  }

  performAdjustmentChangeClose() {
    this.display3='none';
    this.display4='none';
  }

  performAdjustment() {
    this.display='none';
    let index = this.performRowIndex;
    let selectedPerform_adjustment = this.performAdjustmentList[index];
    let shortPairQtyTotal:number = 0;
    let adjPairQtyTotal:number = 0;

    if (this.listObj[index] && this.listObj[index].children.length > 0) {
      for(let q = 0; q < this.listObj[index].children.length; q++){
        shortPairQtyTotal = shortPairQtyTotal + this.listObj[index].children[q].shortagePairQty;
        let sumQty1 = parseInt(selectedPerform_adjustment[1][q]['sumQty']);
        if (isNaN(sumQty1)) { sumQty1 = 0; }
        let sumQty2 = parseInt(selectedPerform_adjustment[2][q]['sumQty']);
        if (isNaN(sumQty2)) { sumQty2 = 0; }
        adjPairQtyTotal = adjPairQtyTotal + (sumQty1 + sumQty2);
      }
    }    
    this.responseMessage1 = "";
    let shortAdjDebitedTo = "";
    let shortAdjDebitedToCode = "";
    let remarks = "";
    this.performAdjustmentArray = [];
    this.loaDer.nativeElement.style.display = "block";
    for (var i = 0; i < Object.keys(selectedPerform_adjustment).length; i++) {
      if (Object.keys(selectedPerform_adjustment[i + 1]).length > 0) {           
        if (this.listObj[index].children.length > 0) {
          for (var k = 0; k < this.listObj[index].children.length; k++) {
            if (i == 0) {
              shortAdjDebitedTo = "TRANSPORTER";
              shortAdjDebitedToCode = "";
              remarks = selectedPerform_adjustment[i + 1][k].transporter_comment;
            } else {
              shortAdjDebitedTo = selectedPerform_adjustment[i + 1][k].shortageAdjustment;
              if(selectedPerform_adjustment[i + 1][k].shortAdjDebitedToCode == null || selectedPerform_adjustment[i + 1][k].shortAdjDebitedToCode == ""){
                shortAdjDebitedToCode = "";
              } else {
                shortAdjDebitedToCode = selectedPerform_adjustment[i + 1][k].shortAdjDebitedToCode;
              }
              remarks = selectedPerform_adjustment[i + 1][k].consignor_comment;
            }
            if(shortAdjDebitedToCode.length > 5){
              this.responseMessage1 = "Supplier Code/ Factory Code length of record " + (index + 1) + " cannot be more than 5";
              this.loaDer.nativeElement.style.display = "none";
              return false;
            }
            var assortment_array = selectedPerform_adjustment[i + 1][k]['assort'];
            if(selectedPerform_adjustment[i + 1][k]['assort']){
              let assortAdjstArray = assortment_array.filter(x => x.pairQty != "");
              console.log("assortAdjstArray");
              console.log(assortAdjstArray);
              assortAdjstArray.forEach(
                (each,index) => {
                  let indexFirst = assortAdjstArray.findIndex(cs => cs.artSize == each.artSize && cs.claimSlipNum == each.claimSlipNum);
                  if(assortAdjstArray[indexFirst]['shortageAdjustmentAssortmentClaimShipModels'] && assortAdjstArray[indexFirst]['shortageAdjustmentAssortmentClaimShipModels'].length > 0){
                    assortAdjstArray[indexFirst]['shortageAdjustmentAssortmentClaimShipModels'].push({
                      "claimShipId":each.grnAssortmentClaimSlipId,
                      "pairs":each.pairQty
                    });
                    if(assortAdjstArray[indexFirst]['shortageAdjustmentAssortmentClaimShipModels'].length > 1){
                      assortAdjstArray.splice(index,1);
                    }
                  } else{
                    each['shortageAdjustmentAssortmentClaimShipModels'] = [];
                    each['shortageAdjustmentAssortmentClaimShipModels'].push({
                      "claimShipId":each.grnAssortmentClaimSlipId,
                      "pairs":each.pairQty
                    })
                  }
                }
              );
              if (assortAdjstArray.length > 0) {
                var data = {
                  grnId: this.listObj[index].children[k].grnId,
                  grnNumber: this.listObj[index].grnNumber,
                  artNumber: this.listObj[index].children[k].artNumber,
                  quantity: selectedPerform_adjustment[i + 1][k].quantity,
                  shortAdjDebitedTo: shortAdjDebitedTo,
                  shortAdjDebitedToCode: shortAdjDebitedToCode,
                  remarks: remarks,
                  userCode: this.userCode,
                  debitCreditTxnAssortmentEntities: assortAdjstArray
                };
                this.performAdjustmentArray.push(data);
              }
            }
          }
        }        
      }
    }
    console.log(JSON.stringify(this.performAdjustmentArray));
    if (this.performAdjustmentArray.length > 0) {
      this.ShortageAdjustmentService.performShortageAdjustment(this.performAdjustmentArray,this.inventoryType).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          this.responseMessage = response.message;
          this.remarks = "";
          this.shortageAdjustment = null;
          this.searchStockAdjustment();
        },
        (err) => {
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.loaDer.nativeElement.style.display = "none";
        }
      );
      this.loaDer.nativeElement.style.display = "none";
    } else {
      this.loaDer.nativeElement.style.display = "none";
      this.responseMessage = "No data found";
    }
  }

  numberCheck(event, name) {
    var regExpr = /[^0-9]/g;
    var userText = event.target.value;
    if (userText != "") {
      if (name == "bataWeekFrom") {
        this.stockAdjustmentForm.controls["bataWeekFrom"].setValue(
          userText.replace(regExpr, '')
        );
      }
      if (name == "bataWeekTo") {
        this.stockAdjustmentForm.controls["bataWeekTo"].setValue(
          userText.replace(regExpr, '')
        );
      }
    }
  }

  shortageQtySum(parent, child, artNumber,grnId,sizeWiseQty,indx) {
    this.responseMessage1='';
    let children = this.listObj[parent].children.findIndex(i=>i.grnId == grnId);
    let assorment = this.performAdjustmentList[parent][child][children]['assort'];
    var length = Object.keys(assorment).length;
    let qty: number = 0;
    console.log("---------------------");
    
    console.log(sizeWiseQty);
    console.log(assorment);
    console.log(indx);
    console.log(this.performAdjustmentList[parent][1][children]['assort'][indx]['pairQty']);
    console.log(this.performAdjustmentList[parent][2][children]['assort'][indx]['pairQty']);
    
    console.log("---------------------");
    let pqtySum=((this.performAdjustmentList[parent][1][children]['assort'][indx]['pairQty']=='')?0:parseInt(this.performAdjustmentList[parent][1][children]['assort'][indx]['pairQty'])) + ((this.performAdjustmentList[parent][2][children]['assort'][indx]['pairQty']=='')?0:parseInt(this.performAdjustmentList[parent][2][children]['assort'][indx]['pairQty']));
    console.log(this.performAdjustmentList[parent][1][children]['assort'][indx]['pairQty'] +'----'+this.performAdjustmentList[parent][2][children]['assort'][indx]['pairQty']);
    
    console.log(pqtySum);
    
    if(this.performAdjustmentList[parent][child][children]['assort'][indx]['pairQty']<0 || pqtySum>sizeWiseQty){
      this.performAdjustmentList[parent][child][children]['assort'][indx]['pairQty']='';
      this.responseMessage1="Adjustment quantity should not more than sizeWise quantity or should not be negetive";
      return false;
    }else{
      for (let i = 0; i < length; i++) {
        let pairqty = parseInt(assorment[i]['pairQty']);
        if (isNaN(pairqty)) { pairqty = 0; }
        qty = qty + pairqty;
      }
      this.performAdjustmentList[parent][child][children]['sumQty'] = qty;
      this.performAdjustmentList[parent][child][artNumber]['sumQty'] = qty;
    }
  }

  sum(val1,val2) {
    let sumVal1 = parseInt(val1);
    if (isNaN(sumVal1)) { sumVal1 = 0; }
    let sumVal2 = parseInt(val2);
    if (isNaN(sumVal2)) { sumVal2 = 0; }
    return (sumVal1+sumVal2);
  }

}
