import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Concept } from '../common/concept';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { SearchPackingMasterService } from './search-packing-master.service';
import { SearchPackingMaster } from './search-pack-master';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
import { SearchPackMasterDetails } from './search-pack-master-details';
import { SearchPackingResponseModel } from '../common/search-pack-master';
import { Router, RouterModule } from '@angular/router';
import { AssortmentHeader } from './pack-master-assortment';
import { AssortmentDetails } from './assortment-details';
import { importArticle } from '../import-article-price/import_article';
import { SizePackingResponseModel } from '../common/size-pack-master';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../common/field-name-list';
@Component({
  selector: 'app-search-packing-master',
  templateUrl: './search-packing-master.component.html',
  styleUrls: ['./search-packing-master.component.css']
})
export class SearchPackingMasterComponent {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  shopCode = ['00017', '00018', '00019', '00020', '00021'];
  assortmentType = [{ name: "Assorted", value: "A" },
  { name: "Wholesale Assorted", value: "W" }];
  assortmentSizes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
  groupCodes = [{ id: "1", value: "A" }, { id: "2", value: "B" }, { id: "3", value: "C" },
  { id: "4", value: "D" }, { id: "5", value: "E" }]
  concepts: Concept[];
  searchPackingModel = new SearchPackingMaster();
  requestParameterModel: RequestParameterModel[];
  searchPackingDetails: SearchPackMasterDetails[] = [];
  searchPackingAssortmentDetails: AssortmentHeader[] = [];
  newAssortment = new AssortmentHeader();
  passingAssortmentDetails: AssortmentHeader[] = [];
  count: number = 0;
  availableSizes = new Array();
  errorMessage: string;
  absoluteIndex: number;
  enteredDetails = new AssortmentHeader();
  currentIndex: number;
  ind: number = 0;
  flag: number = 0;
  responseMessage:string;
  currentPage: number = 1;
  distributionDetailsForm: FormGroup;

  constructor(private _searchPackingMaster: SearchPackingMasterService,
    private _router: Router,
    private _utilsService: UtilsService
    , private fb: FormBuilder, private _navigationLinkParameterService: NavigationLinkParameterService) {
  }

  ngOnInit() {
    
    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{"name":"email","value":JSON.parse(sessUsername).groupAssignmentModels[0].userCode}];
    this._searchPackingMaster.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList; 
        const distinctConcept = this.concepts.filter(
          (thing, i, arr) => arr.findIndex(t => t.concCode === thing.concCode) === i
        ); 
        this.concepts = [];
        this.concepts = distinctConcept;
      }
    )

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
  }

  onClickSearch() {
    
    this.responseMessage = '';
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchPackingModel);
    console.log(JSON.stringify(this.requestParameterModel));
    if(this.requestParameterModel.length != 0){
    this.loaDer.nativeElement.style.display = 'block';
    let flagDetail = {
      "name": "flag",
      "value": "Y"
    }
    this.requestParameterModel.push(flagDetail);
    this.requestParameterModel.filter(cs => cs.name == 'packCode').forEach(
      checkPack => {
        if(this.searchPackingModel.assortmentType == 'W'){
          checkPack.name = 'assortmentCode';
        }
      }
    )
    this._searchPackingMaster.getPackMasterDetails(this.requestParameterModel).subscribe(
      (response: SearchPackingResponseModel) => {
        console.log('response from service' + JSON.stringify(response));
        if(response.dataList != null){
        this.searchPackingDetails = response.dataList;
          this.searchPackingDetails.forEach(
            result => {
              if(result.assortmentType == 'W'){
                result.packCode = result.assortmentCode;
              }
            }
          )
        }
        else{
          this.responseMessage = 'No data Found';
          this.searchPackingDetails= [];
        }
      this.loaDer.nativeElement.style.display = 'none';
      }
    )
    }
    else{
      this.responseMessage = 'Please give a search criteria';
    }
  }

  editPackingDetails(index) {
    console.log('edit option of packing master');
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    this._navigationLinkParameterService.assortmentDetails = this.searchPackingDetails[absoluteIndex].packingMasterAssortmentsDTO;
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchPackingDetails[absoluteIndex]);
    this._utilsService.pageNavigationWithParam('search-packing-master-edit', this.requestParameterModel);
  }

  onAssortTypeSelect(){
    if(this.searchPackingModel.assortmentType == 'W'){
      this.searchPackingModel.catCode = null;
      this.searchPackingModel.shopCode = null;
      this.searchPackingModel.concept = null;
    }
  }
}
