export class CcListSearchDetails {
    partyNumber:string;
    partyShortName:string;
    ccType:string;
    ccEmail:string;
    isActive:string;
    createdBy:string;
    lastUpdatedBy:string;
    lastUpdateDate:string;
    createdDate:string;
    partyMailSeq:string;
    conceptCode:string;
    constructor(){}
}