import { Injectable } from '@angular/core';
import { Requisition } from './requisition';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '../common/bil-api-url';
import { UtilsService } from '../common/utils-service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class SearchRequisitionService {
  // requisition_search_url: string ='http://devapigateway:8011/Requisition';
  _search_requisition_header_url: string = BilApiUrl.SEARCH_REQUISITION_HEADER_URL;

  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;

  apiUrl = BilApiUrl.COMMON_UPLOAD_API_URL;
  URL: string = BilApiUrl.COMMON_DOWNLOAD_URL;
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  generateImportPo:string=BilApiUrl.REQUISITION_DISTRIBUTION;
  constructor(private http: HttpClient, private _utilsService: UtilsService) { }



  upload(formData) {
  return this.http.post(`${this.apiUrl}`,formData);
  }

  // private getEventMessage(event: HttpEvent<any>, formData) {
  //   switch (event.type) {

  //     case HttpEventType.UploadProgress:
  //       return this.fileUploadProgress(event);

  //     case HttpEventType.Response:
  //       return this.apiResponse(event);

  //     default:
  //       return `File "${formData.get('profile').name}" surprising upload event : ${event.type}`;

  //   }
  // }
  // private fileUploadProgress(event) {
  //   const percentDone = Math.round(100 * event.loaded / event.total);
  //   return { status: 'progress', message: percentDone };
  // }

  // private apiResponse(event) {
  //   return event.body;
  // }

  getConcepts(conceptQueryMas) {
    console.log('Log File : SearchRequisitionService : Function : getConcepts');
    return this.http.post(this.concept_url, conceptQueryMas);
  }

  getRequisitionByRequisitionSearchCriteria(requisitionFormData: Requisition, username :string) {
    let requisition_search_parameters = new Array<RequestParameterModel>();

    //TODO: shift below code to seperate file.
    /* Prepare Request Parameter Start */
    let jsonRequisition = JSON.stringify(requisitionFormData);
    let jsonObject = JSON.parse(jsonRequisition);
    for (var i in jsonObject) {
      let requestParameterModel = new RequestParameterModel();

      if (jsonObject[i] !== '' && jsonObject[i] !== null && jsonObject[i] !== 'undefined') {
        requestParameterModel.name = i;
        if ('toPrDate'.includes(requestParameterModel.name)) {
          requestParameterModel.value = this._utilsService.transformDate(requisitionFormData.toPrDate);
        }
        else if ('fromPrDate'.includes(requestParameterModel.name)) {
          requestParameterModel.value = this._utilsService.transformDate(requisitionFormData.fromPrDate);
        }
        else if ('fromPaDate'.includes(requestParameterModel.name)) {
          requestParameterModel.value = this._utilsService.transformDate(requisitionFormData.fromPaDate);
        }
        else if ('toPaDate'.includes(requestParameterModel.name)) {
          requestParameterModel.value = this._utilsService.transformDate(requisitionFormData.toPaDate);
        }
        else {
          requestParameterModel.value = jsonObject[i];
        }
        requisition_search_parameters.push(requestParameterModel);
      }
    }
      let user = {
        "name":"userId",
        "value":username
      }
      requisition_search_parameters.push(user);
   
    console.log(' Log File : SearchRequisitionService  Function : getRequisitionByRequisitionSearchCriteria : Requisition Search Parameters :  '
      + JSON.stringify(requisition_search_parameters));
    /* Prepare Request Parameter End */

    return this.http.post(this._search_requisition_header_url, requisition_search_parameters);
  }
  public async getFile(fileName: string, downloadUrl: string): Promise<Blob> {
    // const file = await this.http.post<Blob>(this.URL,[{ "name":"downloadKey","value":"PO_ARTICLE"}],{responseType: 'blob' as 'json'}).toPromise();
    // return file;
    let fullDownloadUrl: string = downloadUrl + fileName;
    console.log(fullDownloadUrl);
    const file = await this.http.get<Blob>(fullDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  getDownloadFile() {
    return this.http.get(this.urlForDownloadUrl);
  }
  
  public  getGenerate(uploadKeyValues:any){
    return this.http.post(this.generateImportPo,uploadKeyValues);
  }
}

