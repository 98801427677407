import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
@Injectable({
  providedIn: 'root'
})
export class ReprocessJobService {

  itf_url: string = RdsBilApiUrl.ITF_DETAIL;
  asn_logfire_outbound_url: string= RdsBilApiUrl.ASN_LOGFIRE_OUTBOUD;
  resup_ito_logfire_outbound_url: string= RdsBilApiUrl.RESUP_ITO_LOGFIRE_OUTBOUD;
  grn_logfire_inbound_url: string= RdsBilApiUrl.GRN_LOGFIRE_INBOUND;
  dispatch_logfire_inbound_url: string= RdsBilApiUrl.DISPATCH_LOGFIRE_INBOUND;
  constructor(private _http: HttpClient) { }

  getBilItfDetails(param) {
    return this._http.post(this.itf_url, param);
  }

  invokeProcess(param){
    console.log(param); 
    if(param.itfKey=="ASN_Logfire_Outbound"){
      return this._http.post(this.asn_logfire_outbound_url, {"jobId":param.jobNumber});
    }else if(param.itfKey=="GRN_LOGFIRE_Batch"){
      return this._http.post(this.grn_logfire_inbound_url, {"fileName":param.jobNumber});
    } else if(param.itfKey=="DISPATCH_LOGFIRE_INBOUND"){
      return this._http.post(this.dispatch_logfire_inbound_url, null);
    } else{
      return this._http.post(this.resup_ito_logfire_outbound_url, {"jobId":param.jobNumber});
    }
  }

}