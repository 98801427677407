import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ManageSubPartyMasterCreateService } from './manage-sub-party-master-create.service';
import { RETAIL_DIVISION, NON_RETAIL_DIVISION, EXPORT_DIVISION } from '@/common/field-name-list';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-sub-party-master-create',
  templateUrl: './manage-sub-party-master-create.component.html',
  styleUrls: ['./manage-sub-party-master-create.component.css']
})
export class ManageSubPartyMasterCreateComponent implements OnInit {

  createSubPartyMasterForm: FormGroup;
  added = false;
  responseMessage:any;
  countries:any;

  constructor(private _manageSubPartyMasterCreateService: ManageSubPartyMasterCreateService, private fb: FormBuilder,private _router: Router) {
    this.createSubPartyMasterForm = this.fb.group({
      concepts: this.fb.array([])
    });
    this.buildSubPartyForm();
  }

  ngOnInit() {
    this.fetchCountry();
  }

  fetchCountry(){
    this.countries = [];
    this._manageSubPartyMasterCreateService.getCountry().subscribe(
      (data: any) => {
        data.configParamModels.forEach(
           d => {
             if(d.paramName == 'subparty_country'){
            let {paramValue} = d;
            this.countries.push({paramValue});
             }
          }
        );
      }
    );
  }

  buildSubPartyForm() {
    let control = this.conceptFormArrayControl();
    return control.push(this.fb.group(
      {
        code:     ['', [Validators.required]],
        fullName: ['', [Validators.required]],
        address1: ['', [Validators.required]],
        address2: [''],
        address3: [''],
        address4: [''],
        address5: [''],
        zipCode:  ['', [Validators.required]],
        country:  [null, [Validators.required]]       
      }
    ));
  }

  deleteSubParty(concept_index: any) {
    let control = <FormArray>this.createSubPartyMasterForm.controls.concepts;
    control.removeAt(concept_index);
  }

  conceptFormArrayControl() {
    return <FormArray>this.createSubPartyMasterForm.controls.concepts;
  }

  addSubParty() {
   
    if (this.createSubPartyMasterForm.invalid) {
      this.added = true;
      return;
    } else{
      this.added = false;
    }
    let control = this.conceptFormArrayControl();
    return control.push(this.fb.group(
      {
        code:     ['', [Validators.required]],
        fullName: ['', [Validators.required]],
        address1: ['', [Validators.required]],
        address2: [''],
        address3: [''],
        address4: [''],
        address5: [''],
        zipCode:  ['', [Validators.required]],
        country:  [null, [Validators.required]]       
      }
    ));
  }

  submitSubParty(formData: any) {
    console.log(formData);
    let dataSet: any = [];
    formData.concepts.forEach(
      concept => {
        let data: any = {
          code: concept.code,
          fullName: concept.fullName,
          address1: concept.address1,
          address2: concept.address2,
          address3: concept.address3,
          address4: concept.address4,
          address5: concept.address5,
          zipCode: concept.zipCode,
          country: concept.country
        }
        dataSet.push(data);
      }
    );
    this._manageSubPartyMasterCreateService.addSubPartyMaster(dataSet).subscribe(
      (response: any) => {
        console.log(response);
        if(response.status!='ERROR'){
          // this._router.navigate(['']);
         this.responseMessage = "Details Successfully Saved";
        }
        else{
          this.responseMessage= response.message;
        }

      }
    );
  }


}
