import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SearchRoleScreenAccessService } from './search_role-screen-access.service';
import { UserConceptModel, GroupModel, RoleScreenData } from './search_role-screen-access-model';
import { Concept } from '../../common/concept';
import { UserConceptSearchDetails } from './search_role-screen-access-details';
import { Router, RouterModule } from '@angular/router';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../../common/field-name-list';
import { UserGroupModel } from '@/search-user-group/search-user-group-model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-search_role-screen-access',
  templateUrl: './search_role-screen-access.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css',
  '../../../../node_modules/font-awesome/css/font-awesome.css','./search_role-screen-access.component.css']
})
export class SearchRoleScreenAccessComponent implements OnInit {
 
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  @ViewChild('myForm', { static: false }) ngForm: NgForm;

  @ViewChild('group', { static: false }) groupVariable: ElementRef;

  userConceptParameter = new UserConceptModel();
  groupParameter = new GroupModel();

  username: string;
  concepts: Concept[];
  userConcepts: UserConceptSearchDetails[] = [];
  responseMessage: string;
  currentPage = 1;
  RoleScreenData: RoleScreenData[] = [];
  groupListDetails = {
    groupName: ''
  };
  groupParamForSearch = [{
    name: 'groupId',
    value: ''
  }];

  groupList: [] = [];
  selectedValue: string;
 

  constructor(private _SearchRoleScreenAccessService: SearchRoleScreenAccessService, private _router: Router) { }

  ngOnInit() {

    this.groupParameter.groupId = null;
    console.log(this.groupParameter.groupId);
    const sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    // this.username='user.test4';

    this._SearchRoleScreenAccessService.getGroupList(this.groupListDetails).subscribe(
      (response: any) => {
        this.groupList = response;
        console.log(this.groupList);
      }
    );
  }

  onClickSearch() {
    this.responseMessage = '';
    console.log(this.groupParameter);
    console.log(this.groupParameter.groupId);
    if (this.groupParameter.groupId !== undefined && this.groupParameter.groupId !== null) {
      // if (this.groupParameter.groupId == null) {
      //   this.groupParameter.groupId = undefined;
      // }else{
        this.groupParamForSearch[0]['value'] = this.groupParameter.groupId;
      // }
      // if (this.groupParameter.groupname == null) {
      //   this.groupParameter.groupname = undefined;
      // }
        console.log( this.groupParamForSearch );
        this._SearchRoleScreenAccessService.getBilGroupScreenDetailsByGroupId(this.groupParamForSearch).subscribe(
          (response: any) => {
            console.log(JSON.stringify(response));
            this.RoleScreenData = response.data;
            console.log(this.RoleScreenData);
            if (this.RoleScreenData.length === 0) {
              console.log('No Data');
              this.responseMessage = 'Data Not Found';
            }
          }
        );
    } else {
      this.responseMessage = 'Please select a group';
    }
  }

  resetSearch() {
    this.responseMessage = '';
    this.RoleScreenData = [];
    // this.userConceptParameter = new UserConceptModel();
  }

  checkFlag(flag) {
    if (flag !== 'I') {
      return true;
    } else {
      return false;
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  changeFlagStatus(index) {
    const dataSet: any = [];
    this.responseMessage = '';
    const absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log('index ---> ' + absoluteIndex);
    if (this.RoleScreenData[absoluteIndex].activeFlag === 'A') {
      this.RoleScreenData[absoluteIndex].activeFlag = 'I';
    } else {
      this.RoleScreenData[absoluteIndex].activeFlag = 'A';
    }
    const details = {
      groupScreenAccessId: this.RoleScreenData[absoluteIndex].groupScreenAccessId,
      groupId: this.RoleScreenData[absoluteIndex].groupId,
      screenId: this.RoleScreenData[absoluteIndex].screenId,
      accessId: this.RoleScreenData[absoluteIndex].accessId,
      activeFlag: this.RoleScreenData[absoluteIndex].activeFlag,
      lastUpdatedBy: this.username
    };
    dataSet.push(details);
    console.log(details);
    this._SearchRoleScreenAccessService.updateRoleFlagStatus(dataSet).subscribe(
      (response: any) => {
        console.log(JSON.stringify(response));
        if (response.status === 200) {
          this.responseMessage = 'Role Screen Flag Status Updated';
        } else {
          this.responseMessage = 'Something went wrong';
        }
      }
    );
  }
}
