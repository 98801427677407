import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ManageUserConceptCreateService } from './manage-user-concept-create.service';
import { Concept } from '../common/concept';
import { UserConceptSearchDetails } from '../manage-user-concept/manage-user-concept-details';
import { UserConceptModel } from '../manage-user-concept/manage-user-concept-model';
import { UserConceptDetails } from './manage-user-details';

@Component({
  selector: 'app-manage-user-concept-create',
  templateUrl: './manage-user-concept-create.component.html',
  styleUrls: ['./manage-user-concept-create.component.css']
})

export class ManageUserConceptCreateComponent implements OnInit {
  concepts: Concept[];
  createUserConceptModel = new UserConceptSearchDetails();
  userConceptsList: UserConceptSearchDetails[] = [];
  createUserConcept = new UserConceptSearchDetails();
  userConceptSearchParameter = new UserConceptModel();
  userDetailList: UserConceptDetails[] = [];
  selectedUser: number;
  selectedRow: number = 0;
  responseMessage: string;
  mt1: boolean[] = [];
  mt2: boolean[] = [];
  disableIndex: number = 0;
  userConceptsPassingList: UserConceptSearchDetails[] = [];
  details:any;
  username:string;

  constructor(private _router: Router, private _createUserConceptService: ManageUserConceptCreateService) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.createUserConceptModel = new UserConceptSearchDetails();
    this.userConceptsList.push(this.createUserConceptModel);
    this.mt1[0] = false;
    this.mt2[0] = false;
    this._createUserConceptService.getUserConcept().subscribe(
      (data: any) => {
        console.log(data);
        this.concepts = data;
      }
    )
  }

  addUserConcept() {
    if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
      this.createUserConcept = new UserConceptSearchDetails();
      this.userConceptsList.push(this.createUserConcept);
      this.selectedRow = null;
      this.selectedUser = null;
      this.userDetailList = [];
      this.userConceptSearchParameter = new UserConceptModel();
      this.disableIndex++;
      this.responseMessage = '';
    }
  }

  onClickSearchUser() {
    if(this.userConceptSearchParameter.userName == undefined){
     this.details =  {
       "userName": ""
      }
    }
    else{
      this.details =  {
        "userName": this.userConceptSearchParameter.userName
       }
    }
    console.log(JSON.stringify(this.details));
    this._createUserConceptService.getUserDetails(this.details).subscribe(
      (response: UserConceptDetails[]) => {
        console.log(response);
        this.userDetailList = response;
      }
    )
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  setIndexForUser(index) {
    this.responseMessage = '';
    this.selectedUser = index;
  }

  onClickSubmit() {
    this.userConceptsList[this.selectedRow].userName = this.userDetailList[this.selectedUser].userName;
    this.userConceptsList[this.selectedRow].userId = this.userDetailList[this.selectedUser].userId;
    this.mt1[this.disableIndex] = true;

  }

  settingIndexforConceptLine(index) {
    this.responseMessage = '';
    this.selectedRow = index;
  }

  setData(conceptId, i) {
    this.responseMessage = '';
    this.userConceptsList[i].conceptCode = conceptId;
    this.mt2[this.disableIndex] = true;
  }

  onClickSaveButton() {
    this.responseMessage = '';
    this.userConceptsPassingList = [];
    if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
      this.userConceptsList.forEach(
        conc => {
          let userConceptsPassing = new UserConceptSearchDetails();
          userConceptsPassing.conceptCode = conc.conceptCode;
          userConceptsPassing.userId = conc.userId;
          userConceptsPassing.updatedBy = this.username;
          this.userConceptsPassingList.push(userConceptsPassing);
        }
      );
      console.log(JSON.stringify(this.userConceptsPassingList));
      this._createUserConceptService.saveUserDetails(this.userConceptsPassingList).subscribe(
        (response: any) => {
          console.log(response);
          if (response.code != 'FAILED') {
            this.responseMessage = 'User Concept Created Successfully';
          }
          else{
            this.responseMessage = response.message;
          }
        }
      )
    }     
    else {
      this.responseMessage = 'Please fill Data First';
    }
  }

  cancelLineDetails(index){
    if(this.disableIndex != 0){
    this.userConceptsList.splice(index,1);
    this.disableIndex--;
    this.responseMessage = '';
    }
  }
}
