import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { RequisitionLines } from '../search-requisition/requisition-lines';
import { DistributionDetailMultipleService } from '../distribution-detail-multiple/distribution-detail-multiple.service';
import { DistributionDetailCompletedService } from '../distribution-detail-completed/distribution-detail-completed.service';
import { Location } from '../distribution-detail-multiple/location';
import { PackingMaster } from '../distribution-detail-multiple/packing-master';
import { RETAIL_DIVISION, NON_RETAIL_DIVISION, LOCATION_DIVISION_PARAM, RETAIL_DIVISION_VALUE, NON_RETAIL_DIVISION_VALUE, EXPORT_DIVISION, SOLID, ASSORTED, WHOLE_SALE, ASSORTED_CODE, SOLID_CODE, WHOLE_SALE_CODE,USER_ROLE_ORDERING, PENDING_CANCEL, PENDING_APPROVAL, PENDING_RECALL,CANCELLED,CLOSED,REJECTED} from '../common/field-name-list';
import { RequisitionAssortment } from './requisition-assortment';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import {DynamicPackingMaster} from './dynamic-packing-master';
import { DynamicPackingMasterList } from './dynamic-packing-master-data-list';
import { DynamicPackingMasterAssortment } from './dynamic-packing-master-assortment';
import { Observable, Subject } from 'rxjs';
import { distinct, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-distribution-detail-po',
  templateUrl: './distribution-detail-po.component.html',
  styleUrls: ['../../assets/css/bootstrap.css','../../assets/css/bil-style.css','./distribution-detail-po.component.css']
})
export class DistributionDetailPoComponent implements OnInit {
  @ViewChild('ref', {static: false}) ref: ElementRef;
  @ViewChild('closeBtn', {static: false}) closeBtn: ElementRef;
  @ViewChild('closeBtn1', {static: false}) closeBtn1: ElementRef;
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef; 

  myForm: any;
  totresps: any; 
  searchDisrtibutionDetails: RequisitionLines;
  distributionPageTitle: string;
  isPreviousPageRequisition: boolean;
  distributionDetailsForm: FormGroup;
  assortmentTypes = [];
  selectedItem: any;
  locationArray: Location[] = [];
  fakeArray = [];
  selectedPopupIndex: number = 0;
  selectedCategoryCode: string;
  selectedShopCode: string;
  selectedAssortmentTypeId: string;
  packingMasterList: PackingMaster[];
  assortment_index: any;
  selectedAssortmentIndex: any;
  displayDefaultValPackMaster: boolean = true;
  packingAssortmentDetails: PackingMaster = new PackingMaster();
  packingAssortmentDetailsDynamicList: PackingMaster[] = [];
  assortmentCode: string;
  packCode: string;
  totalPackAssortments: number;
  totalParis: number;
  results: any;
  data1: any;
  displayDefaultValPair: boolean = true;
  distributionReqQty: number;
  saveDisabled: boolean = false;
  responseMessage: string;
  assormentMessage: string;
  selectedAssortmentType: string;
  groupCodeArray: string[];
  editablePackageAssortmentsArray: number[];
  addDistributionTypeSolidPackCode:boolean=false;
  addDistributionTypeSolidPackCodeEnable: boolean = false;
  deleteDisable: boolean = true;
  isOrderQuantityCalculated: boolean = false; 
  sizepackingassortment: any;
  packingMaster: any;
  dataList: DynamicPackingMasterList[];
  packingMasterAssortmentsDTO: DynamicPackingMasterAssortment[];
  distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg: string;
  isPairDivisible: boolean;
  primeErrMssg: string;
  sizeWisePairErrMssg: string;
  pairDistOrdErerrMssg: string;
  flag: any;
  isPrime: boolean;
  distributionSaveButtonForm: FormGroup;
  isTotalPairDistributionOrderQtyEqual: boolean;
  searchDisrtibutionDetails1: any;
  poHeaderIdbck: any;
  hasDuplicateDistibutionErrMsg: string;
  isdistributionRequsitionQuantityGreaterRequisitionQuantity: boolean;
  displayLoaderWhenAssortmentTypeWholeSale:boolean=false;
  loadingSpinnerMsg: string;
  orderQuantityGreaterRequisitionQuantityErrMsg: string;
  divisionval: any;
  // lined: any;
  orderedQuantity: number = 0;
  distReqQuantity: number = 0;// Balance logic change
  bq: number = 0;
  poDistributionArray = [];
  ShowSave:number = 0;
  ShowCancel:number = 0;
  isReadOnly: boolean = false;
  assortCheckValue: boolean = false;
  distributionOrderQuantityEmptyErrMsg: string;
  distributionRequsitionQuantityEmptyErrMsg: string;
  pairPerCartonRatioEmptyErrMsg: string;
  potype: any;
  isCalculateButtonShow = 1;
  ratioControl: any;
  isDeleteDisplay:number = 0;
  reqOrdQty:number = 0;
  selectedPOlinearray: any;
  pairFlag = [];
  pairFlag1 = [];
  cancelArray = [];
  cancelIndex: any;
  cancelQty:number = 0;
  disableCalculate:number = 0;
  isRoleVendor:boolean = false;
  
  constructor(private _distributionDetailService: DistributionDetailMultipleService, private _distributionDetailCompletedService: DistributionDetailCompletedService, private _route: ActivatedRoute,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private fb: FormBuilder) {
    this.distributionDetailsForm = this.fb.group({
      companies: this.fb.array([])
    });
    this.distributionSaveButtonForm = this.buildDistributionSaveForm()
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    this.divisionval = this._navigationLinkParameterService.distributionDetails.division;
    this.selectedPOlinearray = this._navigationLinkParameterService.distributionDetails.letpolines.find(x => x.id === this._navigationLinkParameterService.distributionDetails.lineId);
    this.selectedCategoryCode = this.selectedPOlinearray.categoryNumber;
    this.setCompanies();    
  }

  buildDistributionSaveForm() {
    let saveButtons = {};
    saveButtons['saveDistributionButton'] = 'Save';
    saveButtons['calculateOrderQuantityButton'] = 'Calculate Order Quantity';
    return this.fb.group(saveButtons);
  }

  sizes(resultArray) {
    var size = {};
        size['size0'] = 'Size';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        size['size'+j] = resultArray[i].artSize; 
      }
        size['totalSize'] = 'Total';

    return this.fb.group(size)
  }
 
  packingAssortmentRow(resultArray) {
    
      var packingAssortment = {};
      var totpackingAssortment = 0;
          packingAssortment['packingAssortment0'] = 'Packing Assortment';
        for(let i = 0; i < resultArray.length; i++)
        {
          let j = i + 1;
          packingAssortment['packingAssortment'+j] = resultArray[i].artSizeRatio;
          if(resultArray[i].artSizeRatio != undefined){
          totpackingAssortment += resultArray[i].artSizeRatio;
          }
        }
          packingAssortment['totalPackingAssortment'] = totpackingAssortment;

    return this.fb.group(packingAssortment)
  }
 
  pairRow(resultArray) {
      var pair = {};
      var totpair = 0;
          pair['pair0'] = 'Pairs';
        for(let i = 0; i < resultArray.length; i++)
        {
          let j = i + 1;
          pair['pair'+j] = resultArray[i].totalPairQty;
          totpair += resultArray[i].totalPairQty;
        }
          pair['totalPairs'] = totpair;

    return this.fb.group(pair)
  }

  reqassortmtid(resultArray) {
    var reqassort = {};
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        reqassort['reqAssortmentId'+j] = resultArray[i].id;
      }

    return this.fb.group(reqassort)
}
 
  packageAssortments(resultArray) {
    return this.fb.group({
      sizes: this.sizes(resultArray),
      reqassortmtid: this.reqassortmtid(resultArray),
      pairRow: this.pairRow(resultArray),
      packingAssortmentRow: this.packingAssortmentRow(resultArray)
    });
  }

  setCompanies() {
    //this.disableSave();
    let control = <FormArray>this.distributionDetailsForm.controls.companies;
    this.poHeaderIdbck = this._navigationLinkParameterService.distributionDetails.poHeaderId;
    this.searchDisrtibutionDetails1 = this._navigationLinkParameterService.distributionDetails.letpolines;
    var lined: number = this._navigationLinkParameterService.distributionDetails.lineId;
    var isPresent = this.searchDisrtibutionDetails1.filter(item => item.id == lined);
    this.totresps = isPresent; 
    this.potype = this._navigationLinkParameterService.distributionDetails.poType;
    let letterOfArticle: string = this.totresps != null ? this.totresps[0].artNumber.toString().charAt(0) : '';
    let editable_assortment_packages_param = { 'name': 'letterOfArticle', 'value': letterOfArticle };
    this.sizepackingassortment = [];
    this._distributionDetailService.getEditableAssortmentPackagesForSolidAssortmentType(editable_assortment_packages_param)
      .subscribe(
        (response) => {
          this.sizepackingassortment.push(response['dataList']);
        }
      );       
      this.groupCodeArray = [];
      setTimeout(()=>{
        console.log(this.sizepackingassortment[0]);
        if(this.sizepackingassortment[0]){
          let orderedQuantity: number = 0;
          this.totresps[0].poLineDistributions.forEach(x => {
            let cancel:number = 0;
            if(x.cancelFlag == 'Y'){
              cancel = 1;
            }
            var blankDataSet = [];          
              for(var j = 0; j < this.sizepackingassortment[0].length; j++)
              {
                this.groupCodeArray.push(this.sizepackingassortment[0][j].groupCode);
                blankDataSet.push({
                    "ReqAssortmentId": 0,
                    "artSize": this.sizepackingassortment[0][j].artSize,
                    "articleSize": this.sizepackingassortment[0][j].sizeBucket,
                    "pairQty": 0,
                    "totalPairQty": 0
                  });
              }        
            this.fakeArray = new Array(this.sizepackingassortment[0].length);
            var resultArray = blankDataSet.reduce((arr, e) => {
              arr.push(Object.assign({}, e, x.poLineAssortments.find(a => a.articleSize == e.articleSize)))
              return arr;
            }, []);
            if(x.assortmentCode == null || x.assortmentCode == "null") {
              x.assortmentCode = '';
            }
            if(cancel == 1){
              this.isReadOnly = true;
              this.cancelQty += Number(x.cancelledQty);
            }else{
              this.isReadOnly = false;
            }
            if(x.assortmentCode != ''){
              this.assortCheckValue = true;
            } else {
              this.assortCheckValue = false;
            }
            if(x.requestedOrderQuantity == null || x.requestedOrderQuantity == ''){
              if(x.distributionReqQuantity == null || x.distributionReqQuantity == ''){
                this.reqOrdQty = x.totalArtQty;
              } else {
                this.reqOrdQty = x.distributionReqQuantity;
              }
            } else {
              this.reqOrdQty = x.requestedOrderQuantity;
            }
            // if(k.cancelStatus == 1){
            //   rdcCode = x.rdcName+'~'+x.rdcCode;
            //   rdcName = k.rdcName;
            // } else {
            //   rdcCode = k.locationArray.split('~')[1];
            //   rdcName = k.locationArray.split('~')[0];
            // }
            control.push(this.fb.group({ 
              assortmentCode: { value: x.assortmentCode, disabled: this.isReadOnly },
              numberOfCartons: x.cartonQty,
              // requestedOrderQuantity: { value: (x.requestedOrderQuantity == null || x.requestedOrderQuantity == '') ? x.distributionReqQuantity  : x.requestedOrderQuantity, disabled: this.isReadOnly } ,
              // distributionRequisitionQuantity: x.distributionReqQuantity,
              requestedOrderQuantity: { value: this.reqOrdQty , disabled: this.isReadOnly } ,
              rdcCode: x.rdcCode,
              rdcName: x.rdcName,
              distributionOrderQuantity: x.totalArtQty,
              assortmentTypes: { value: x.assortmentType, disabled: this.isReadOnly },
              locationArray: { value: x.rdcName+'~'+x.rdcCode, disabled: this.isReadOnly },
              reqDistributionStatus: x.isActive,
              reqDistributionStat: 'block',
              reqDistributionId: x.poDistributionId,
              packageAssortments: this.packageAssortments(resultArray),
              solid_packageAssortments: this.solid_packageAssortments(resultArray, x.pairPCarton),
              assortmentCheckBox: { value: this.assortCheckValue, disabled: this.isReadOnly },
              shippedQty: x.shippedQty,
              receivedQty: x.receivedQty,
              cancelledQty: x.cancelledQty,
              distributionStatus: x.distributionStatus,
              cancelStatus:cancel,
              assortmentTypesCancel: x.assortmentType
            }));

            orderedQuantity += (x.requestedOrderQuantity == null || x.requestedOrderQuantity == '') ? x.totalArtQty  : x.requestedOrderQuantity;

            this.loaDer.nativeElement.style.display = 'none';
          });
          if(Number(this.totresps[0].distributionReqQuantitySum) == 0){
            this.totresps[0].distributionReqQuantitySum = orderedQuantity;
          }
        } else {
          this.responseMessage = 'Network problem. Please try again.'
        }
       }, 3000);
       this.showButton();
       this.calculateCancelBalance();
  }

  showButton(){
    let sessUsername = sessionStorage.getItem('currentUser');
    let userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    let coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    let isOtbProcessed = this._navigationLinkParameterService.podetails.isOtbProcessed;
    let status = this._navigationLinkParameterService.podetails.poStatus;
    var iof=coCode.indexOf(this._navigationLinkParameterService.distributionDetails.conceptCode);
    let userRoleIndex = userRole.indexOf('Ordering') || userRole.indexOf('IT');
    let userRoleIndexOrdering = userRole.indexOf('Ordering');
    let userRoleIndexIT = userRole.indexOf('IT');
    let poLineStatus = this.selectedPOlinearray.poLineStatus;
    let isPrinted:string =  this._navigationLinkParameterService.distributionDetails.isPrinted;
    if(userRole.indexOf('Ordering') >=0 || userRole.indexOf('IT') >= 0){	
      this.isRoleVendor = false;	
    } else{	
      this.isRoleVendor = true;	
    }
    isPrinted =  isPrinted!=null ? isPrinted.toUpperCase() : null;
    if ((userRoleIndexOrdering>=0 || userRoleIndexIT>=0) && iof>=0 && (status=='Approved' || (status=='New' && isOtbProcessed != 'Y') || status=='In-Transit'  || status == REJECTED)){
      if(isPrinted == 'Y' || (poLineStatus == PENDING_CANCEL || poLineStatus == PENDING_APPROVAL || poLineStatus == PENDING_RECALL || poLineStatus == CANCELLED || poLineStatus == CLOSED || poLineStatus == REJECTED) || (status == PENDING_CANCEL || status == PENDING_APPROVAL || status == PENDING_RECALL || status == CANCELLED || status == CLOSED)){
        this.ShowSave = 0;
      }else{
        this.ShowSave = 1;
      }
    }
    else {
      this.ShowSave = 0;
    }
    if ((userRoleIndexOrdering>=0 || userRoleIndexIT>=0) && iof>=0 && (status=='Approved' || (status=='New' && isOtbProcessed != 'Y') || status=='In-Transit' || status == REJECTED)){
      if((poLineStatus == PENDING_CANCEL || poLineStatus == PENDING_APPROVAL || poLineStatus == PENDING_RECALL || poLineStatus == CANCELLED || poLineStatus == CLOSED || poLineStatus == REJECTED) || (status == PENDING_CANCEL || status == PENDING_APPROVAL || status == PENDING_RECALL || status == CANCELLED || status == CLOSED)){
        this.ShowCancel = 0;
      }else{
        this.ShowCancel = 1;
      }
    }
    else {
      this.ShowCancel = 0;
    }
    
  }

  addAssortment()
  {
    var blankDataSet = [];
      for(var j = 0; j < this.sizepackingassortment[0].length; j++)
      {
        blankDataSet.push({
            "ReqAssortmentId": 0,
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }
      let control = <FormArray>this.distributionDetailsForm.controls.companies;
      
      control.push(
        this.fb.group({
          assortmentCode: [''],
          numberOfCartons: [''],
          // distributionRequisitionQuantity: [''],
          rdcCode: [''],
          rdcName: [''],
          distributionOrderQuantity: [''],
          requestedOrderQuantity: [''],
          assortmentTypes: [''],
          assortmentTypesCancel: [''],
          locationArray: [''],
          reqDistributionStatus: 'Y',
          reqDistributionStat: [''],
          reqDistributionId: [''],
          assortmentCheckBox: { value: false },
          solid_packageAssortments: this.solid_packageAssortments(blankDataSet, '0'),
          packageAssortments: this.packageAssortments(blankDataSet),
          shippedQty: 0,
          receivedQty: 0,
          cancelledQty: 0,
          distributionStatus: 'New',
          cancelStatus:2
      }));
      this.deleteDisable = false;
      this.isOrderQuantityCalculated = false;
  }

  ngOnInit() {
    let division_value: string = "";
 
    if (RETAIL_DIVISION.toLowerCase() === this.divisionval.toLowerCase())
      division_value = RETAIL_DIVISION_VALUE;
 
    if (NON_RETAIL_DIVISION.toLowerCase() === this.divisionval.toLowerCase())
      division_value = NON_RETAIL_DIVISION_VALUE;

    let sessUsername = sessionStorage.getItem('currentUser');

    let user_conceptCodes = '';
    let groupName = JSON.parse(sessUsername).groupAssignmentModels[0].groupName;
    user_conceptCodes = JSON.parse(sessUsername).groupAssignmentModels[0].concept;
    let location_param: any = [
      {"name": "shopAgencyCode", "value": division_value},
      {"name": "shopCloseYrWk1", "value": LOCATION_DIVISION_PARAM},
      {"name": "userEmail", "value": JSON.parse(sessUsername).groupAssignmentModels[0].userCode},
      {"name": "conceptCode", "value": this._navigationLinkParameterService.distributionDetails.conceptCode}
    ]; 
    this.fetchLocation(location_param);
    if (USER_ROLE_ORDERING == groupName) {
      if (user_conceptCodes.includes(this._navigationLinkParameterService.distributionDetails.conceptCode)) {
        this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      } else {
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      }
    } else {
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    }
    this.assortmentTypes = this.getAssortmentType(this.divisionval, this._navigationLinkParameterService.distributionDetails.conceptCode);
  
    if( this._navigationLinkParameterService.distributionDetails!=null && this._navigationLinkParameterService.distributionDetails.letpolines!=null && this._navigationLinkParameterService.distributionDetails.podetails!=null){      
      let userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
      let coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');  
      let isOtbProcessed = this._navigationLinkParameterService.podetails.isOtbProcessed;
      let status = this._navigationLinkParameterService.podetails.poStatus;
      var iof=coCode.indexOf(this._navigationLinkParameterService.distributionDetails.conceptCode);
      let userRoleIndex = userRole.indexOf('Ordering') || userRole.indexOf('IT');
      let userRoleIndexOrdering = userRole.indexOf('Ordering');
      let userRoleIndexIT = userRole.indexOf('IT');
      let poLineStatus = this._navigationLinkParameterService.distributionDetails.letpolines[0].poLineStatus;
      let isPrinted:string =  this._navigationLinkParameterService.distributionDetails.isPrinted;
      isPrinted =  isPrinted!=null ? isPrinted.toUpperCase() : null;
      if ((userRoleIndexOrdering>=0 || userRoleIndexIT>=0) && iof>=0 && (status=='Approved' || (status=='New' && isOtbProcessed != 'Y'))){
        if(isPrinted == 'Y' || (poLineStatus == PENDING_CANCEL || poLineStatus == PENDING_APPROVAL || poLineStatus == PENDING_RECALL || poLineStatus == CANCELLED || poLineStatus == CLOSED || poLineStatus == REJECTED) || (status == PENDING_CANCEL || status == PENDING_APPROVAL || status == PENDING_RECALL || status == CANCELLED || status == CLOSED || status == REJECTED)){
          this.isDeleteDisplay = 0;
        }else{
          this.isDeleteDisplay = 1;
        }
      }
      else {
        this.isDeleteDisplay = 0;
      }       
    }
     
  }

  getAssortmentType(division: string, conceptCode: string) {
    if (division != null && conceptCode != null) {
      this.assortmentTypes = [];
      if(this._navigationLinkParameterService.podetails.orderType == 'C'){
        this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
      } else {
        if ('X' === conceptCode) {
          this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
          this.assortmentTypes.push({ id: WHOLE_SALE_CODE, name: WHOLE_SALE });
        }
        else if (RETAIL_DIVISION.toLowerCase() === division.toLowerCase()) {
          this.assortmentTypes.push({ id: ASSORTED_CODE, name: ASSORTED });
          this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
        }
        else if (NON_RETAIL_DIVISION.toLowerCase() === division.toLowerCase()) {
          this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
          this.assortmentTypes.push({ id: WHOLE_SALE_CODE, name: WHOLE_SALE });
        }
        else {
          this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID }); // when division is EXPORT
        }
      }
    }
    return this.assortmentTypes; 
  }
  
  fetchLocation(location_param: any) {
    this._distributionDetailService.getLocations(location_param).subscribe(
      (response: any) => {
        this.locationArray = response.dataList;
       // this.validateDistributionReqQty();
      }
    ); 
    return this.locationArray;
  }
 
  getAssortmentFormControl() {    
    let assortments_index: number = this.selectedPopupIndex;
    let control = this.distributionDetailsForm.get(['companies', assortments_index]);
    return control;
  }
 
  fetchAssortmenDetails(event: any, assortment_index) {
    this.dataList=[];
    this.loaDer.nativeElement.style.display = 'block';
    // let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let location: string = control.get('locationArray').value;
    let selectedAssortmentTypeId: string = ASSORTED_CODE;
    if(selectedAssortmentTypeId != '' && location != ''){ 
      let selectedShopName = location.split('~')[0];
      selectedShopName = selectedShopName.substr(0, selectedShopName.length - 1);
      let selectedShopCode = location.split('~')[1];
      selectedShopCode = selectedShopCode.substr(0, selectedShopCode.length);
      // let assortmentTypes: string = control.get('assortmentTypes').value; 
      let targetId: string = event.target.id;
      let checkBoxLabel: string = "chkOpenPackPopup";
      this.selectedPopupIndex = +(targetId.substring(checkBoxLabel.length, (targetId.length)));
      
      let assortment_code_param = [
        {"name": "catCode", "value": this.selectedCategoryCode},
        {"name": "shopCode", "value": selectedShopCode},
        {"name": "assortmentType", "value": selectedAssortmentTypeId},
        {"name": "flag", "value": "N"},
        {"name": "groupCode", "value": this.groupCodeArray[0]},
        {"name": "concept", "value": this._navigationLinkParameterService.distributionDetails.conceptCode}
      ]; 

      this._distributionDetailService.getAssortmensts(assortment_code_param).subscribe(
      (response: DynamicPackingMaster) => {
        console.log(response);
        if (response != null) {
          if(response.dataList!=null && response.dataList.length >0 ){              
            let dataListLength = response.dataList.length;
            this.dataList=[];
            this.packingMasterAssortmentsDTO=[];
            for(let i=0; i<dataListLength;i++){
              if(response.dataList[i]!=null && response.dataList[i].packingMasterAssortmentsDTO!=null 
                && response.dataList[i].packingMasterAssortmentsDTO.length>0){
                  let totalPairQty =0;
                  for(let j=0;j<response.dataList[i].packingMasterAssortmentsDTO.length;j++){
                      this.packingMasterAssortmentsDTO.push(response.dataList[i].packingMasterAssortmentsDTO[j]);
                      totalPairQty += response.dataList[i].packingMasterAssortmentsDTO[j].pairQty;
                      response.dataList[i].totalPairQty = totalPairQty;
                }
              }
              this.dataList.push(response.dataList[i]);
              this.packingMaster = response.dataList; 
            }
            this.loaDer.nativeElement.style.display = 'none'; 
          }else {
            this.assormentMessage = 'No data found';
            this.dataList=[];
            setTimeout(()=>{
              this.assormentMessage = '';
            }, 5000);
            this.loaDer.nativeElement.style.display = 'none'; 
          }
        }else {
          this.assormentMessage = 'No data found';
          this.dataList=[];
          setTimeout(()=>{
            this.assormentMessage = '';
          }, 5000);
          this.loaDer.nativeElement.style.display = 'none'; 
        }
      },
      (err)=>{
        this.assormentMessage = err;
        this.dataList=[];
        setTimeout(()=>{
          this.assormentMessage = '';
        }, 5000);
        this.loaDer.nativeElement.style.display = 'none'; 
      });
    } else {
      this.loaDer.nativeElement.style.display = 'none'; 
      this.responseMessage = 'Please choose location and assortment type.';
      setTimeout(()=>{
        this.responseMessage = '';
      }, 3000);      
    }
    
  }
 
  get assortments() {
    return this.distributionDetailsForm.get('companies') as FormArray;
  }
 
  getAssortmentsIndex() {
    let assortments_index: number = this.assortments.length - 1;
    return assortments_index;
  }
 
  selectedAssortment(index) {
    
    if(index != undefined){ 
      let selectedPackingAssortment = this.packingMaster[index];
      let sizeBucketCount = this.sizepackingassortment[0].length;
      let control = this.getAssortmentFormControl();
      var fakeArr = [];
      var blankDataSet = [];
      for(var j = 0; j < sizeBucketCount; j++)
      {
        blankDataSet.push({
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }

      selectedPackingAssortment.packingMasterAssortmentsDTO.forEach(k => {
          fakeArr.push(k);
      });

      var resultArray = [];
      resultArray = blankDataSet.reduce((arr, e) => {
        arr.push(Object.assign({}, e, fakeArr.find(a => e.articleSize == a.articleSize)))
        return arr;
      }, []);
      var totPackAssort = 0;
      if (this.selectedAssortmentType === ASSORTED_CODE) {
          if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;              
              control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment'+k).setValue(eval(`resultArray[${i}].pairQty`));
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(totPackAssort);
          }
      } else
      {        
        if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+k).setValue(eval(`resultArray[${i}].pairQty`));
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+k).disable();
              control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+k).setValue('0');
              control.get('numberOfCartons').setValue('');
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + resultArray.length).disable();
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(totPackAssort);
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
          }
      }

      control.get('assortmentCode').setValue(selectedPackingAssortment.packCode);
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
      control.get('distributionOrderQuantity').setValue(0);
      control.get('numberOfCartons').disable();
    }
  }

  tabselectedAssortment(index,assortment_index) {
    
    if(index != undefined){ 
      let selectedPackingAssortment = this.packingMaster[index];
      let sizeBucketCount = this.sizepackingassortment[0].length;
      let control = this.distributionDetailsForm.get(['companies', assortment_index]);
      var fakeArr = [];
      var blankDataSet = [];
      for(var j = 0; j < sizeBucketCount; j++)
      {
        blankDataSet.push({
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }

      selectedPackingAssortment.packingMasterAssortmentsDTO.forEach(k => {
          fakeArr.push(k);
      });

      var resultArray = [];
      resultArray = blankDataSet.reduce((arr, e) => {
        arr.push(Object.assign({}, e, fakeArr.find(a => e.articleSize == a.articleSize)))
        return arr;
      }, []);
      var totPackAssort = 0;
      if (control.get('assortmentTypes').value === ASSORTED_CODE) {
          if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;              
              control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment'+k).setValue(eval(`resultArray[${i}].pairQty`));
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(totPackAssort);
          }
      } else
      {        
        if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+k).setValue(eval(`resultArray[${i}].pairQty`));
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+k).disable();
              control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+k).setValue('0');
              control.get('numberOfCartons').setValue('');
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + resultArray.length).disable();
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(totPackAssort);
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
          }
      }

      control.get('assortmentCode').setValue(selectedPackingAssortment.packCode);
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
      control.get('distributionOrderQuantity').setValue(0);
      control.get('requestedOrderQuantity').setValue(0);
      control.get('numberOfCartons').disable();
    }
  }

  validateAssortment(event: any, assortment_index) {
    this.loaDer.nativeElement.style.display = 'block';
    var numbers = /^[0-9]+$/;
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    if(event.target.value.match(numbers))
    { 
      let location: string = control.get('locationArray').value;
      let selectedAssortmentTypeId: string = ASSORTED_CODE;
      if((control.get('assortmentTypes').value != '' && control.get('assortmentTypes').value != null) && (location != '' && location != null)){ 
        let selectedShopName = location.split('~')[0];
        selectedShopName = selectedShopName.substr(0, selectedShopName.length - 1);
        let selectedShopCode = location.split('~')[1];
        selectedShopCode = selectedShopCode.substr(0, selectedShopCode.length);
        let dataList1 = [];
        // let assortmentTypes: string = control.get('assortmentTypes').value; 
        let assortment_code_param = [
          {"name": "catCode", "value": this.selectedCategoryCode},
          {"name": "shopCode", "value": selectedShopCode},
          {"name": "assortmentType", "value": selectedAssortmentTypeId},
          {"name": "flag", "value": "N"},
          {"name": "groupCode", "value": this.groupCodeArray[0]},
          {"name": "concept", "value": this._navigationLinkParameterService.distributionDetails.conceptCode}
        ];

        this._distributionDetailService.getAssortmensts(assortment_code_param).subscribe(
        (response: DynamicPackingMaster) => {          
          if (response != null) {
            if(response.dataList!=null && response.dataList.length >0 ){              
              let dataListLength = response.dataList.length;
              this.dataList=[];
              this.packingMasterAssortmentsDTO=[];
              for(let i=0; i<dataListLength;i++){
                if(response.dataList[i]!=null && response.dataList[i].packingMasterAssortmentsDTO!=null 
                  && response.dataList[i].packingMasterAssortmentsDTO.length>0){
                    let totalPairQty =0;
                    for(let j=0;j<response.dataList[i].packingMasterAssortmentsDTO.length;j++){
                        this.packingMasterAssortmentsDTO.push(response.dataList[i].packingMasterAssortmentsDTO[j]);
                        totalPairQty += response.dataList[i].packingMasterAssortmentsDTO[j].pairQty;
                        response.dataList[i].totalPairQty = totalPairQty;
                  }
                }
                this.dataList.push(response.dataList[i]);
                dataList1.push(response.dataList[i]);
                this.packingMaster = response.dataList; 
              }
            
              let flag = 0;
              if(dataList1.length > 0){
                for(let k=0; k<dataList1.length; k++){
                  if(dataList1[k].packCode == event.target.value.toString()){
                    flag ++ ;
                    this.tabselectedAssortment(k,assortment_index); 
                  }                 
                }
              }
              if(flag == 0){
                this.assormentMessage = 'No matching assortment code found';
                this.changeType(assortment_index);
                setTimeout(()=>{
                  this.assormentMessage = '';
                }, 5000);
              }
              this.loaDer.nativeElement.style.display = 'none'; 
            } else {
              this.assormentMessage = response.message;
              this.changeType(assortment_index);
              setTimeout(()=>{
                this.assormentMessage = '';
              }, 5000);
              this.loaDer.nativeElement.style.display = 'none'; 
            }
          } else {
            this.assormentMessage = 'No data found';
            this.changeType(assortment_index);
            setTimeout(()=>{
              this.assormentMessage = '';
            }, 5000);
            this.loaDer.nativeElement.style.display = 'none'; 
          }
        },
        (err)=>{
          this.assormentMessage = err;
          this.changeType(assortment_index);
          setTimeout(()=>{
            this.assormentMessage = '';
          }, 5000);
          this.loaDer.nativeElement.style.display = 'none'; 
        });
        
      } else {
        this.loaDer.nativeElement.style.display = 'none'; 
        this.responseMessage = 'Please choose location and assortment type.';
        this.changeType(assortment_index);
        setTimeout(()=>{
          this.responseMessage = '';
        }, 5000);      
      }  
    } else {
      this.loaDer.nativeElement.style.display = 'none'; 
      this.responseMessage = 'Please enter numeric value only.';
      this.changeType(assortment_index);
      setTimeout(()=>{
        this.responseMessage = '';
      }, 5000); 
    } 
  }
  
  calculatePairs(numberOfCartonsValue: number, assortment_index: any) {
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    for(var k = 1; k <= this.sizepackingassortment[0].length; k++)
    {
      control.get('packageAssortments').get('pairRow').get('pair'+k).setValue(control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment'+k).value * numberOfCartonsValue);
    } 
  }
 
  calculateOrderQuantity(assortment_index: any){  
    this.errorMessageBlank();  
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let distributionReqQty: number;    
    //let poArtQty = parseInt(this.totresps[0].poArtQty);
    let poArtQty = 0;
    let distributionReqQuantitySum = parseInt(this.totresps[0].distributionReqQuantitySum);
    if(poArtQty == 0){
      poArtQty = distributionReqQuantitySum;
    }
    
    if (control.get('assortmentTypes').value == ASSORTED_CODE) {
      let totalPackingAssortment: number = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;      
      if (totalPackingAssortment > 0) {
        this.displayDefaultValPair = false; //used in htlm
        let numberOfCartonsValue: number;
        let totalParis: number;        
        distributionReqQty = control.get('requestedOrderQuantity').value;
        // if (distributionReqQty <= (poArtQty * 1.2)) {
        //   this.saveDisabled = true;
        //   numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
        //   totalParis = totalPackingAssortment * numberOfCartonsValue;
        //   this.calculatePairs(numberOfCartonsValue, assortment_index);
        //   control.get('numberOfCartons').setValue(numberOfCartonsValue);
        //   control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(totalParis);
        //   control.get('distributionOrderQuantity').setValue(totalParis);
        //   //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        // }
        // else {
        //   this.responseMessage = 'Ordered Quantity is greater than 20% of Requisition Quantity, please correct it';
        //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        // }

        /// Distribution location line level validation - distribution order quantity should be with in +-20% of distribution req quantity(for Req)/ requested order quantity(for PO) otherwise throw validation error
        numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
        totalParis = totalPackingAssortment * numberOfCartonsValue;
        this.calculatePairs(numberOfCartonsValue, assortment_index);
        control.get('numberOfCartons').setValue(numberOfCartonsValue);
        control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(totalParis);
        control.get('distributionOrderQuantity').setValue(totalParis);

        if((totalParis <= (1.2 * distributionReqQty)) && (totalParis >= (0.8 * distributionReqQty))){
          this.saveDisabled = true;   
          this.responseMessage = '';          
        } else {
          this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }
      }
    }
    if (control.get('assortmentTypes').value === WHOLE_SALE_CODE) {
      let totalPackingAssortment: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;      
      if (totalPackingAssortment > 0) {
        this.displayDefaultValPair = false;
        let numberOfCartonsValue: number;
        let totalParis: number;
        distributionReqQty = control.get('requestedOrderQuantity').value;
        // if (distributionReqQty <= (poArtQty * 1.2)) {
        //   this.saveDisabled = true;
        //   numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
        //   totalParis = totalPackingAssortment * numberOfCartonsValue;
        //   this.calculatePairsSolid(numberOfCartonsValue, assortment_index);
        //   control.get('numberOfCartons').setValue(numberOfCartonsValue);
        //   control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalParis);
        //   control.get('distributionOrderQuantity').setValue(totalParis);
        //   //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        // }
        // else {
        //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        // }

        /// Distribution location line level validation - distribution order quantity should be with in +-20% of distribution req quantity(for Req)/ requested order quantity(for PO) otherwise throw validation error
        numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
        totalParis = totalPackingAssortment * numberOfCartonsValue;
        this.calculatePairsSolid(numberOfCartonsValue, assortment_index);
        control.get('numberOfCartons').setValue(numberOfCartonsValue);
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalParis);
        control.get('distributionOrderQuantity').setValue(totalParis);

        if((totalParis <= (1.2 * distributionReqQty)) && (totalParis >= (0.8 * distributionReqQty))){
          this.saveDisabled = true;   
          this.responseMessage = '';       
        } else {
          this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }
      }
    }
    if (control.get('assortmentTypes').value === SOLID_CODE) {
      control.get('numberOfCartons').setValue('');
      control.get('distributionOrderQuantity').setValue(0);
      if((control.get('requestedOrderQuantity').value != control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').value) && (control.get('assortmentCode').value == ''|| control.get('assortmentCode').value == null)){
        this.responseMessage = 'Total Solid Pairs should be equal to Distribution Req Quantity for line '+ (assortment_index + 1);
      }      
    }
    //this.disableSave();
    // if (distributionReqQty > (parseInt(this.totresps[0].poArtQty) * 1.2)) {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Requisition Quantity is greater than 20% of Requisition Quantity, please correct it';
    //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    // }
    // else {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
    //   this.distributionSaveButtonForm.get('saveDistributionButton').enable();
    // }

    this.calculateBalance();
    this.checkRequisitionDistributionGreaterThanRequisitionOrder();
  }

  calculateBalance(){
    let count: number = 0;
    let distributionReqQuantity: number = 0;
    this.distReqQuantity = 0;
    let control = this.distributionDetailsForm.get('companies');
    control.value.forEach((k: any) => {
      distributionReqQuantity = Number(this.distributionDetailsForm.get(['companies', count]).get('requestedOrderQuantity').value);
      this.distReqQuantity += distributionReqQuantity;
      count++;
    });
  }

  private checkRequisitionDistributionGreaterThanRequisitionOrder() {
    let flag:boolean;
    this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
    let requisitionOrderQty = Number(this.totresps[0].distributionReqQuantitySum);
    if(requisitionOrderQty != 0){
      if (Number(this.distReqQuantity > requisitionOrderQty)) {
        this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Req Quantity is greater than Requisition Quantity of PO Line.';
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
        flag = true;
      }
      else {
        this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        if(this.disableCalculate == 0){
          this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        } 
        //this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        flag = false;
      }
    }else{
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      if(this.disableCalculate == 0){
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
      } 
      //this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
      flag = false;
    }    
    return flag;
  }
 
  deletePopAssortment(index)
  {
    this.ref.nativeElement.value = index;
  }

  deleteAssortment(index) {    
    let control1 = <FormArray>this.distributionDetailsForm.controls.companies;
    let control = this.distributionDetailsForm.get(['companies', index]);
    var reqDistributionStat = control.get('reqDistributionStat').value;
    var dataset:any = [];
    if(control.get('reqDistributionId').value != ''){ 
      //if(reqDistributionStat == 'block'){
      //  control.get('reqDistributionStat').setValue('none');
      control.get('reqDistributionStatus').setValue('I');
      //}   
      let sizeControlCount = this.sizepackingassortment[0].length;
      let requisitionAssortments= []; 
      let packingAssortmentControlName: string;
      let assortments_index: number = this.selectedPopupIndex;
      let packingAssortment: number;
      let pairControlName: string;
      let reqControlName: string;
      let pair: number;
      let reqpair: number;
      let hasDuplicate: boolean;
      let pairPerCarton: string;
      let sessUsername = sessionStorage.getItem('currentUser');
      var sizelabelcontrolname: string;
      var sizeLabel:string;
      let currentDate = new Date();
 
      for (let size = 1; size <= sizeControlCount; size++) {
        let selectedAssortmentTypeId: string = control.get('assortmentTypes').value;
        reqControlName = 'reqAssortmentId'+size;
        reqpair = control.get('packageAssortments').get('reqassortmtid').get(reqControlName).value;

        if (selectedAssortmentTypeId != null && ASSORTED_CODE === selectedAssortmentTypeId) {
          packingAssortmentControlName = 'packingAssortment' + size;
          packingAssortment = control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).value;
          pairControlName = 'pair' + size;
          pair = control.get('packageAssortments').get('pairRow').get(pairControlName).value;
          pairPerCarton = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;
          sizelabelcontrolname = 'size'+size;
          sizeLabel = control.get('packageAssortments').get('sizes').get(sizelabelcontrolname).value;
        }
        if (selectedAssortmentTypeId != null && (SOLID_CODE === selectedAssortmentTypeId || WHOLE_SALE_CODE === selectedAssortmentTypeId)) {
          packingAssortmentControlName = 'solid_packingAssortment' + size;
          packingAssortment = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).value;
          pairControlName = 'solid_pair' + size;
          pair = control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).value;
          pairPerCarton = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
          sizelabelcontrolname = 'solid_size'+size;
          sizeLabel = control.get('solid_packageAssortments').get('solid_sizes').get(sizelabelcontrolname).value;
        }

        if (packingAssortment !== 0 && pair != 0) {
          let requisitionAssortment = new RequisitionAssortment();
          requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket;
          requisitionAssortment.sizeLabel = sizeLabel;
          requisitionAssortment.pairQty = packingAssortment;
          requisitionAssortment.artSizeRatio = packingAssortment;
          requisitionAssortment.totalPairQty = pair;
          requisitionAssortment.isActive = 'I';
          requisitionAssortment.lastUpdatedBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
          requisitionAssortment.lastUpdateDate = currentDate;

          if (reqpair != null) {
            requisitionAssortment.poAssortmentId = reqpair;
          }
          requisitionAssortments.push(requisitionAssortment);
        }    
      }

      if(control.get('reqDistributionId').value != '')
      {
          var newarrexitSet = [];
          this.totresps[0].poLineDistributions[index].poLineAssortments.forEach(s => {
              let afterStatusAddition = {
                poAssortmentId: s.id,
                articleSize: s.articleSize,
                artSizeRatio: s.pairQty,
                pairQty: s.pairQty,
                totalPairQty: s.totalPairQty,
                isActive: 'I',
                createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName
              }

              newarrexitSet.push(afterStatusAddition);

          });

          var resultArray = newarrexitSet.map(
            s => requisitionAssortments.find(
              t => t.articleSize == s.articleSize) || s
          ).concat( 
            requisitionAssortments.filter(
              s => !newarrexitSet.find(t => t.articleSize == s.articleSize)
            ) 
          );
      } else
      {
        var resultArray = requisitionAssortments;
      }

      // var neworderQty:number = this.orderQty.nativeElement.value;
      // var distorderqty:number = control.get('distributionOrderQuantity').value;

      dataset.push({ 
        poDistributionId: control.get('reqDistributionId').value,
        assortmentCode: control.get('assortmentCode').value,
        rdcCode: control.get('locationArray').value.split('~')[1],
        rdcName: control.get('locationArray').value.split('~')[0],
        totalArtQty: control.get('distributionOrderQuantity').value,
        assortmentType: control.get('assortmentTypes').value,
        cartonQty: control.get('numberOfCartons').value,
        pairPCarton: pairPerCarton, 
        isActive: 'I',
        createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
        pairPerCarton: pairPerCarton,
        lastUpdateDate : currentDate,
        lastUpdatedBy : JSON.parse(sessUsername).groupAssignmentModels[0].userName,
        poLineAssortments: resultArray
      });

      this.poDistributionArray.push({ 
        poDistributionId: control.get('reqDistributionId').value,
        assortmentCode: control.get('assortmentCode').value,
        rdcCode: control.get('locationArray').value.split('~')[1],
        rdcName: control.get('locationArray').value.split('~')[0],
        totalArtQty: control.get('distributionOrderQuantity').value,
        assortmentType: control.get('assortmentTypes').value,
        cartonQty: control.get('numberOfCartons').value,
        pairPCarton: pairPerCarton, 
        isActive: 'I',
        lastUpdateDate : currentDate,
        lastUpdatedBy : JSON.parse(sessUsername).groupAssignmentModels[0].userName,
        createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
        pairPerCarton: pairPerCarton,
        poLineAssortments: resultArray
      });
            
      control1.removeAt(index);
      this.selectedPopupIndex = index - 1;
      // var newdatset = {
      //   poHeaderId: this.poHeaderIdbck,
      //   poLines : [{
      //     poArtQty: this.totresps[0].poArtQty,
      //     poLineId: this.totresps[0].id,
      //     poLineDistributions: dataset
      //   }]
      // };

      // console.log(newdatset);
      // this._distributionDetailService.savePoDistribution(newdatset)
      //   .subscribe(
      //     (response: ResponseMessage) => {
      //       if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
      //         this.responseMessage = 'Distribution Deleted successfully';
      //       }
      //     }
      //   );

        // this.distributionDetailsForm = this.fb.group({
        //   companies: this.fb.array([])
        // });

        // this.setCompanies();        
    } 
    else 
    {
        control1.removeAt(index);
        this.selectedPopupIndex = index - 1;
    } 
    this.sumOfDistributionReqQuantity();
    //this.disableSave();
    this.calculateBalance();
    this.checkRequisitionDistributionGreaterThanRequisitionOrder();
    this.closeBtn.nativeElement.click();
  }

  cancelPopAssortment(index){
    this.cancelIndex = index;
  }

  onConfirmCancel(index){
    this.responseMessage = '';
    let control = this.distributionDetailsForm.get(['companies', index]);
    let shippedQuantity:number = Number(control.get('shippedQty').value);
    let orderedQty = (control.get('requestedOrderQuantity').value);
    let recvQuantity:number = Number(control.get('receivedQty').value);
    let distributionStatus = control.get('distributionStatus').value;
    if((distributionStatus != 'In-Transit') || (recvQuantity <= shippedQuantity)){
      this.cancelAssortment(index);
    }
    else{
      this.responseMessage = 'Received Quantity is greater than Shipped/Ordered Quantity';
      this.closeBtn1.nativeElement.click();
    }
  }

  cancelAssortment(index){
    let control = this.distributionDetailsForm.get(['companies', index]);

    let calculatedCancelled:number = 0;
    let shippedQuantity:number = Number(control.get('shippedQty').value);
    let orderedQty = (control.get('distributionOrderQuantity').value);
    let recvQuantity:number = Number(control.get('receivedQty').value);
    let distributionStatus = control.get('distributionStatus').value;
    let updateddistributionStatus:any;
    if ((shippedQuantity == 0 && recvQuantity == 0) && (distributionStatus == 'New' || distributionStatus == 'Approved')){
      calculatedCancelled = orderedQty;
      updateddistributionStatus = 'Cancelled';
    }else if ((shippedQuantity > recvQuantity) && distributionStatus == 'In-Transit'){
      calculatedCancelled = orderedQty - shippedQuantity;
      if(recvQuantity == 0){
        updateddistributionStatus = 'In-Transit';
      }
      else{
        updateddistributionStatus = 'Closed';
      }
    }else if ((shippedQuantity == recvQuantity) && (shippedQuantity != 0 && recvQuantity != 0) && distributionStatus == 'In-Transit'){
      calculatedCancelled = orderedQty - shippedQuantity;
      updateddistributionStatus = 'Closed';
    }else if ((recvQuantity > shippedQuantity) && distributionStatus == 'In-Transit'){
      calculatedCancelled = orderedQty - recvQuantity;
    }
    this.cancelArray.push(control.get('reqDistributionId').value);
    var dataset:any = [];
    if(control.get('reqDistributionId').value != ''){   
      let sizeControlCount = this.sizepackingassortment[0].length;
      let requisitionAssortments= []; 
      let packingAssortmentControlName: string;
      let packingAssortment: number;
      let pairControlName: string;
      let reqControlName: string;
      let pair: number;
      let reqpair: number;
      let pairPerCarton: string;
      let sessUsername = sessionStorage.getItem('currentUser');
      var sizelabelcontrolname: string;
      var sizeLabel:string;
      let currentDate = new Date();
 
      for (let size = 1; size <= sizeControlCount; size++) {
        let selectedAssortmentTypeId: string = control.get('assortmentTypes').value;
        reqControlName = 'reqAssortmentId'+size;
        reqpair = control.get('packageAssortments').get('reqassortmtid').get(reqControlName).value;

        if (selectedAssortmentTypeId != null && ASSORTED_CODE === selectedAssortmentTypeId) {
          packingAssortmentControlName = 'packingAssortment' + size;
          packingAssortment = control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).value;
          pairControlName = 'pair' + size;
          pair = control.get('packageAssortments').get('pairRow').get(pairControlName).value;
          pairPerCarton = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;
          sizelabelcontrolname = 'size'+size;
          sizeLabel = control.get('packageAssortments').get('sizes').get(sizelabelcontrolname).value;
        }
        if (selectedAssortmentTypeId != null && (SOLID_CODE === selectedAssortmentTypeId || WHOLE_SALE_CODE === selectedAssortmentTypeId)) {
          packingAssortmentControlName = 'solid_packingAssortment' + size;
          packingAssortment = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).value;
          pairControlName = 'solid_pair' + size;
          pair = control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).value;
          pairPerCarton = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
          sizelabelcontrolname = 'solid_size'+size;
          sizeLabel = control.get('solid_packageAssortments').get('solid_sizes').get(sizelabelcontrolname).value;
        }
        if (packingAssortment !== 0 && pair != 0) {
          let requisitionAssortment = new RequisitionAssortment();
          requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket;
          requisitionAssortment.sizeLabel = sizeLabel;
          requisitionAssortment.pairQty = packingAssortment;
          requisitionAssortment.artSizeRatio = packingAssortment;
          requisitionAssortment.totalPairQty = pair;
          requisitionAssortment.isActive = 'Y';
          requisitionAssortment.lastUpdatedBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
          requisitionAssortment.lastUpdateDate = currentDate;

          if (reqpair != null) {
            requisitionAssortment.poAssortmentId = reqpair;
          }
          requisitionAssortments.push(requisitionAssortment);
        } 
      }
 
      console.log('requisitionAssortments');
      console.log(JSON.stringify(requisitionAssortments));

      var newarrexitSet = [];
      this.totresps[0].poLineDistributions[index].poLineAssortments.forEach(s => {
          let afterStatusAddition = {
            poAssortmentId: s.poAssortmentId,
            articleSize: s.articleSize,
            artSizeRatio: s.pairQty,
            pairQty: s.pairQty,
            totalPairQty: s.totalPairQty,
            isActive: 'Y',
            createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName
          }
          newarrexitSet.push(afterStatusAddition);              
      });

      console.log('newarrexitSet');
      console.log(JSON.stringify(newarrexitSet));

      var resultArray = newarrexitSet.map(
        s => requisitionAssortments.find(
          t => t.articleSize == s.articleSize) || s
      ).concat( 
        requisitionAssortments.filter(
          s => !newarrexitSet.find(t => t.articleSize == s.articleSize)
        ) 
      );

      console.log('resultArray');
      console.log(JSON.stringify(resultArray));
 
      dataset.push(
        {
          poDistributionId: control.get('reqDistributionId').value,
          assortmentCode: control.get('assortmentCode').value,
          rdcCode: control.get('locationArray').value.split('~')[1],
          rdcName: control.get('locationArray').value.split('~')[0],
          totalArtQty: control.get('distributionOrderQuantity').value,
          requestedOrderQuantity: Number(control.get('requestedOrderQuantity').value),
          assortmentType: control.get('assortmentTypes').value,
          cartonQty: control.get('numberOfCartons').value,
          pairPCarton: pairPerCarton, 
          isActive: 'Y',
          createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
          cancelledQty: calculatedCancelled,
          receivedQty: control.get('receivedQty').value,
          shippedQty: control.get('shippedQty').value,
          cancelFlag: 'Y',
          lastUpdateDate : currentDate,
          lastUpdatedBy : JSON.parse(sessUsername).groupAssignmentModels[0].userName,
          distributionStatus: 'Cancelled',
          poLineAssortments: resultArray
        }
      );
 
      var newdatset = {
        poHeaderId: this.poHeaderIdbck,
        cancelFlag: 'Y',
        poLines : [{
          poArtQty: this.totresps[0].poArtQty,
          poLineId: this.totresps[0].id,
          poLineDistributions: dataset
        }]
      };
      console.log(newdatset);

      this._distributionDetailService.savePoDistribution(newdatset)
      .subscribe(
        (response: ResponseMessage) => {
          if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
            this.responseMessage = 'Distribution PO cancelled successfully';
            control.get('cancelStatus').setValue(1);
            control.get('cancelledQty').setValue(calculatedCancelled);
            control.get('distributionStatus').setValue(updateddistributionStatus);
            control.get('requestedOrderQuantity').disable();
            control.get('locationArray').disable();
            control.get('assortmentCode').disable();
            control.get('assortmentCheckBox').disable();
            control.get('assortmentTypes').disable();
            this.calculateCancelBalance();
          } else {
            this.responseMessage = response.message;
          }
        },
        (err) => {
          this.responseMessage = err;
        }
      );
    }
    this.closeBtn1.nativeElement.click();
  }

  calculateCancelBalance(){
    let count: number = 0;
    let cancelQuantity: number = 0;
    this.cancelQty = 0;
    let control = this.distributionDetailsForm.get('companies');
    control.value.forEach((k: any) => {
      cancelQuantity = Number(this.distributionDetailsForm.get(['companies', count]).get('cancelledQty').value);
      this.cancelQty += cancelQuantity;
      count++;
    });
  }
 
  onClickBack() {
    this._router.navigate(['/update-po']);
  }

  changeType(assortment_index){
    this.errorMessageBlank();  
    var control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let location = control.get('locationArray').value;
    control.get('rdcCode').setValue(location.split('~')[1]);
    control.get('rdcName').setValue(location.split('~')[0]);
    control.get('assortmentCode').enable();
    control.get('assortmentCode').setValue('');
    control.get('numberOfCartons').setValue('');
    control.get('requestedOrderQuantity').setValue('');
    control.get('distributionOrderQuantity').setValue('');
    if(control.get('assortmentTypes').value == WHOLE_SALE_CODE){
      control.get('assortmentCheckBox').disable();
    }

    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      let solidpairControlName = 'solid_pair' + j;
      let solidpackingAssortmentControlName = 'solid_packingAssortment' + j;
      let pairControlName = 'pair' + j;
      let packingAssortmentControlName = 'packingAssortment' + j;
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(solidpackingAssortmentControlName).setValue('');
      control.get('solid_packageAssortments').get('solid_pairRow').get(solidpairControlName).setValue('');
      control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).setValue('');
      control.get('packageAssortments').get('pairRow').get(pairControlName).setValue('');
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue('');
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue('');
      control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue('');
      control.get('packageAssortments').get('pairRow').get('totalPairs').setValue('');
    }
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
  }

  onChangeAssortmentType(event: any, assortment_index) {
    this.errorMessageBlank();  
    //this.resetDistributionLine(assortment_index);
    let targetId = 'assortmentTypes'+assortment_index;
    var control = this.distributionDetailsForm.get(['companies', assortment_index]);
    this.selectedAssortmentType = event;
    let targetLable = 'assortmentTypes';
    this.updateAssortmentIndex(targetLable, targetId);
    if(this.sizepackingassortment[0]){
      for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
        let j = i + 1;
        if (this.selectedAssortmentType == WHOLE_SALE_CODE) {
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).enable();
          control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Validate');
          control.get('assortmentCheckBox').disable();
        } else {
          control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Calculate');
          control.get('assortmentCheckBox').enable();
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).disable();
        }
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).disable();
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').disable();
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
      }
    }
  }

  submitRegDistributionCompleted(dataValue:any)
  {   
    let val = this.sumOfDistributionReqQuantity();
    if(val == 1){
      this.errorMessageBlank();  
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      var dataset = [];
      let packingAssortmentControlName: string;
      let assortments_index: number = this.selectedPopupIndex;
      let packingAssortment: number;
      let pairControlName: string;
      let reqControlName: string;
      let pair: number;
      let reqpair: number;
      let hasDuplicate: boolean;
      let hasDuplicate1: boolean;
      var pairPerCarton: string;
      let sessUsername = sessionStorage.getItem('currentUser');
      var j = 0; 
      var assortmentForCancel: string;
      var rdcCode: string;
      var rdcName: string;
      var sizelabelcontrolname: string;
      var sizeLabel:string;
      let currentDate = new Date();
     
      dataValue.companies.forEach(k => {  
        let sizeControlCount = this.sizepackingassortment[0].length;
        let requisitionAssortments= [];
        let control = this.distributionDetailsForm.get(['companies', j]);
        
        for (let size = 1; size <= sizeControlCount; size++) {
            let selectedAssortmentTypeId: string = control.get('assortmentTypes').value;
            let selectedAssortmentCode: string = control.get('assortmentCode').value;
            reqControlName = 'reqAssortmentId'+size;
            reqpair = control.get('packageAssortments').get('reqassortmtid').get(reqControlName).value;

            if (selectedAssortmentTypeId != null && ASSORTED_CODE === selectedAssortmentTypeId) {
              packingAssortmentControlName = 'packingAssortment' + size;
              packingAssortment = control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).value;
              pairControlName = 'pair' + size;
              pair = control.get('packageAssortments').get('pairRow').get(pairControlName).value;
              pairPerCarton = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;
              sizelabelcontrolname = 'size'+size;
              sizeLabel = control.get('packageAssortments').get('sizes').get(sizelabelcontrolname).value;
            }
            if (selectedAssortmentTypeId != null && (SOLID_CODE === selectedAssortmentTypeId || WHOLE_SALE_CODE === selectedAssortmentTypeId)) {
              packingAssortmentControlName = 'solid_packingAssortment' + size;
              packingAssortment = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).value;
              pairControlName = 'solid_pair' + size;
              pair = control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).value;
              pairPerCarton = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
              sizelabelcontrolname = 'solid_size'+size;
              sizeLabel = control.get('solid_packageAssortments').get('solid_sizes').get(sizelabelcontrolname).value;
            }
            if (Number(packingAssortment) != 0 && Number(pair) != 0 && selectedAssortmentCode != '') {
              let requisitionAssortment = new RequisitionAssortment();
              requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket;
              requisitionAssortment.sizeLabel = sizeLabel;
              requisitionAssortment.pairQty = packingAssortment;
              requisitionAssortment.artSizeRatio = packingAssortment;
              requisitionAssortment.totalPairQty = pair;
              requisitionAssortment.isActive = 'Y';

              if (Number(reqpair) != 0) {
                requisitionAssortment.poAssortmentId = reqpair;
              }
              else{
                requisitionAssortment.createdBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
                requisitionAssortment.createdDate =  currentDate;
                requisitionAssortment.lastUpdatedBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
                requisitionAssortment.lastUpdateDate =  currentDate;
              }
              requisitionAssortments.push(requisitionAssortment);

            }  
            if (Number(packingAssortment) == 0 && Number(pair) != 0 && selectedAssortmentCode == '') {
              let requisitionAssortment = new RequisitionAssortment();
              requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket;
              requisitionAssortment.sizeLabel = sizeLabel;
              requisitionAssortment.pairQty = packingAssortment;
              requisitionAssortment.artSizeRatio = packingAssortment;
              requisitionAssortment.totalPairQty = pair;
              requisitionAssortment.isActive = 'Y';

              if (Number(reqpair) != 0) {
                requisitionAssortment.poAssortmentId = reqpair;
              }
              else{
                requisitionAssortment.createdBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
                requisitionAssortment.createdDate =  currentDate;
                requisitionAssortment.lastUpdatedBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
                requisitionAssortment.lastUpdateDate =  currentDate;
              }
              requisitionAssortments.push(requisitionAssortment);
            } 
        }
        console.log(requisitionAssortments);
        
        if(control.get('reqDistributionId').value != '')
        {
            var newarrexitSet = [];
            console.log('poLineAssortments');
            console.log(this.totresps[0].poLineDistributions[j].poLineAssortments);
            
            this.totresps[0].poLineDistributions[j].poLineAssortments.forEach(s => {
                let afterStatusAddition = {
                  poAssortmentId: s.id,
                  articleSize: s.articleSize,
                  artSizeRatio: s.pairQty,
                  pairQty: s.pairQty,
                  totalPairQty: s.totalPairQty,
                  isActive: 'I',
                  createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName
                }
                newarrexitSet.push(afterStatusAddition);              
            });
            console.log('newarrexitSet');
            console.log(newarrexitSet);
            var resultArray = newarrexitSet.map(
              s => requisitionAssortments.find(
                t => t.articleSize == s.articleSize) || s
            ).concat( 
              requisitionAssortments.filter(
                s => !newarrexitSet.find(t => t.articleSize == s.articleSize)
              ) 
            );
            console.log('if');
            console.log('reqDistributionId '+control.get('reqDistributionId').value);
            console.log(resultArray); 
        } else
        {
          var resultArray = requisitionAssortments;
          console.log('else');
          console.log('reqDistributionId '+control.get('reqDistributionId').value);
          console.log(resultArray);
        }        

        if(k.cancelStatus == 1){
          assortmentForCancel = k.assortmentTypesCancel;
        } else {
          assortmentForCancel = k.assortmentTypes;
        }
        if(k.rdcCode != ''){
          rdcCode = k.rdcCode;
        } else {
          rdcCode = k.locationArray.split('~')[1];
        }
        if(k.rdcName != ''){
          rdcName = k.rdcName;
        } else {
          rdcName = k.locationArray.split('~')[0];
        }
        if(k.reqDistributionId != ''){          
            dataset.push(
                {
                    poDistributionId: k.reqDistributionId,
                    assortmentCode: control.get('assortmentCode').value,
                    rdcCode: rdcCode,
                    rdcName: rdcName,
                    totalArtQty: control.get('distributionOrderQuantity').value,
                    // distributionRequisitionQuantity: control.get('distributionRequisitionQuantity').value,
                    requestedOrderQuantity: Number(control.get('requestedOrderQuantity').value),
                    assortmentType: assortmentForCancel,
                    cartonQty: control.get('numberOfCartons').value,
                    pairPCarton: pairPerCarton, 
                    isActive: 'Y',
                    createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
                    pairPerCarton: pairPerCarton,
                    poLineAssortments: resultArray
                  }
                );
        } else {
          dataset.push( 
            {
                assortmentCode: control.get('assortmentCode').value,
                rdcCode: rdcCode,
                rdcName: rdcName,
                totalArtQty: control.get('distributionOrderQuantity').value,
                // distributionRequisitionQuantity: control.get('distributionRequisitionQuantity').value,
                requestedOrderQuantity: Number(control.get('requestedOrderQuantity').value),
                assortmentType: k.assortmentTypes,
                cartonQty: control.get('numberOfCartons').value,
                pairPCarton: pairPerCarton, 
                isActive: 'Y',
                createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
                createdDate: currentDate,
                lastUpdatedBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
                lastUpdateDate: currentDate,
                pairPerCarton: pairPerCarton,
                distributionStatus: 'New',
                poLineAssortments: resultArray
              }
            );
        }
        j++;
      });
      let rdcCodeAssortmentTypeArray1: any = [];
      for (let distributionLineIndex = 0; distributionLineIndex < dataset.length; distributionLineIndex++) {
        let distributionobj = dataset[distributionLineIndex];
        rdcCodeAssortmentTypeArray1.push(distributionobj.rdcCode);
      }
      // rdcCodeAssortmentTypeArray1.filter(
      //   (item: any, index: any) => {
      //     hasDuplicate1 = rdcCodeAssortmentTypeArray1.indexOf(item) === index;
      //   }
      // );
      if (new Set(rdcCodeAssortmentTypeArray1).size !== rdcCodeAssortmentTypeArray1.length) {
        hasDuplicate1 = true;
      }
      else {
        hasDuplicate1 = false;
      }
      
      if(this.poDistributionArray.length > 0){
        for (let podel = 0; podel < this.poDistributionArray.length; podel++) {
          dataset.push(this.poDistributionArray[podel]);
        }
      }
  
      var newdatset = {
        poHeaderId: this.poHeaderIdbck,
        cancelFlag: 'N',
        poLines : [{
          poArtQty: this.totresps[0].poArtQty,
          poLineId: this.totresps[0].id,
          poLineDistributions: dataset
        }]
      };
      
      // let rdcCodeAssortmentTypeArray: any = [];
      // for (let distributionLineIndex = 0; distributionLineIndex < dataset.length; distributionLineIndex++) {
      //   let distributionobj = dataset[distributionLineIndex];
      //   rdcCodeAssortmentTypeArray.push(distributionobj.rdcCode);
      // }
  
      // rdcCodeAssortmentTypeArray.filter(
      //   (item: any, index: any) => {
      //     hasDuplicate = rdcCodeAssortmentTypeArray.indexOf(item) === index;
      //   }
      // );
      
      if (hasDuplicate1 && rdcCodeAssortmentTypeArray1.length>0) {
        this.hasDuplicateDistibutionErrMsg = 'Please choose different location.'
      }
      else {
        this.hasDuplicateDistibutionErrMsg = '';
        console.log(newdatset);
        if(confirm("Are you sure you want to save ? If success it will redirect to Purchase Order Details.")) {
          this._distributionDetailService.savePoDistribution(newdatset)
          .subscribe(
            (response: ResponseMessage) => {
              if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
                this.responseMessage = 'Distribution PO Details saved successfully';
                this.closeBtn1.nativeElement.click();
                this._router.navigate(['/update-po']);
              } else {
                this.responseMessage = response.message;
              }
            },
            (err)=>{
              this.responseMessage = err;
            }
          );
        } 
      }
    }
  }

  sumOfDistributionReqQuantity() {    
    //this.pairDistOrdErerrMssg = '';
    this.errorMessageBlank();  
    let calSuccess = 0;
    let inputboxValidation = 0;
    let count: number = 0;
    let count1: number = 0;
    let distributionOrderQuantity: number = 0;
    let requestedOrderQuantity: number = 0;
    let orderedQuantity: number = 0;
    let requestedQuantitySum: number = 0;
    let control = this.distributionDetailsForm.get('companies');
    let control1 = this.distributionDetailsForm.get('companies');
    control.value.forEach(k => {
      distributionOrderQuantity = this.distributionDetailsForm.get(['companies', count]).get('distributionOrderQuantity').value;      
      orderedQuantity += distributionOrderQuantity; 
      requestedQuantitySum += Number(this.distributionDetailsForm.get(['companies', count]).get('requestedOrderQuantity').value);
      count++;
    });
  
    control1.value.forEach(k => {
      distributionOrderQuantity = this.distributionDetailsForm.get(['companies', count1]).get('distributionOrderQuantity').value; 
      requestedOrderQuantity =  this.distributionDetailsForm.get(['companies', count1]).get('requestedOrderQuantity').value;  
      let pairPerCartonRatio = Number(this.distributionDetailsForm.get(['companies', count1]).get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value); 
      let assortmentTypes =  this.distributionDetailsForm.get(['companies', count1]).get('assortmentTypes').value;    
      let assortmentCode =  this.distributionDetailsForm.get(['companies', count1]).get('assortmentCode').value;
      let locationValue =  this.distributionDetailsForm.get(['companies', count1]).get('locationArray').value;
      
      let control2 = this.distributionDetailsForm.get(['companies', count1]);
      let anyPairDivisible: boolean[] = [];
      let checkTotSolidPair = 0;
      for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
        let j = i + 1;
        checkTotSolidPair += Number(control2.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value);
        let pair: number = control2.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value;
        let divisible: boolean = pair % pairPerCartonRatio === 0 ? true : false;
        anyPairDivisible.push(divisible);
      }
 
      let flag = 0;
      for (let i = 0; i < anyPairDivisible.length; i++) {
        if (anyPairDivisible[i]==false) {
          ++flag;
        }
      }

      if (distributionOrderQuantity == 0) {
        this.distributionOrderQuantityEmptyErrMsg = 'Distribution req qty and Total pairs are different at  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if (requestedOrderQuantity == 0) {
        this.distributionRequsitionQuantityEmptyErrMsg = 'Distribution Req Quantity is empty at  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if (locationValue == null || locationValue == "") {
        this.distributionRequsitionQuantityEmptyErrMsg = 'Location is empty at  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if( pairPerCartonRatio == 0 && assortmentTypes == SOLID_CODE){
        this.pairPerCartonRatioEmptyErrMsg = 'Pair per carton is empty at  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      }else if(checkTotSolidPair != requestedOrderQuantity  && assortmentTypes == SOLID_CODE && (assortmentCode == ''|| assortmentCode == null)){
        this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution req Quantity for  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      }else if(assortmentTypes == SOLID_CODE && ((checkTotSolidPair > (1.2 * requestedOrderQuantity)) || (checkTotSolidPair < (0.8 * requestedOrderQuantity)))){
        this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      }else if(checkTotSolidPair != distributionOrderQuantity  && assortmentTypes == SOLID_CODE && assortmentCode != ''){
        this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Total Solid Pairs should be equal to distribution order qty for  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if (flag > 0 && assortmentCode != null && assortmentCode != '' && assortmentTypes == SOLID_CODE) {
        this.sizeWisePairErrMssg = 'Size wise pair must be divisible by pair per cartons for line ' + (count1 + 1);
        inputboxValidation++;
        return false;
      } else {
        //  this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        this.errorMessageBlank();  
      }
      count1++;
    });

    console.log('After validation');
    
    //if(requisitionOrderQty != 0){
      this.totresps[0].poArtQty = orderedQuantity;
      let artQty = Number(this.totresps[0].distributionReqQuantitySum);
      let upperlimit = Number(artQty * 1.2);
      let lowerlimit = artQty-(upperlimit-artQty); 
      // if(requestedQuantitySum == artQty && (this.pairFlag.length == 0 && this.pairFlag1.length == 0)) {
      // }
      if(requestedQuantitySum == artQty && inputboxValidation == 0) {
        this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
        this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
        this.responseMessage = '';
        this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
        this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
        this.pairDistOrdErerrMssg = '';
        calSuccess = 1;
      }
      // if (Number(this.totresps[0].poArtQty) > upperlimit) {
      //   this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Order Quantity is greater than 20% of Distribution Req Quantity, please correct it';
      //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      //   this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
      //   calSuccess = 0;
      // }
      // else if (Number(this.totresps[0].poArtQty) < lowerlimit) {
      //   this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Order Quantity is lower than 20% of Distribution Req Quantity, please correct it';
      //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      //   this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
      //   calSuccess = 0;
      // }
      // else if ((Number(this.totresps[0].poArtQty) != 0 && (Number(this.totresps[0].poArtQty) >= lowerlimit && Number(this.totresps[0].poArtQty) <= upperlimit)) && inputboxValidation == 0){
      //   this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
      //   this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      //   this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
      //   calSuccess = 1;
      // }   
      else{
        if(requestedQuantitySum != artQty){
          if(requestedQuantitySum > artQty){
            this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Req Quantity is greater than Requisition Quantity of PO Line.'; 
          }
          if(requestedQuantitySum < artQty){
            this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Req Quantity is less than Requisition Quantity of PO Line.';
          }
        }   
        // if(this.pairFlag.length > 0){
        //   for(let i = 0; i < this.pairFlag.length; i++){
        //     this.responseMessage += '<br> Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity, please correct it at  line ' + (this.pairFlag[i]);
        //   }
        // }     
        // if(this.pairFlag1.length > 0){
        //   for(let j = 0; j < this.pairFlag1.length; j++){
        //     this.pairDistOrdErerrMssg += '<br> Total Solid Pairs should be equal to distribution requisition qty, please correct it at  line ' + (this.pairFlag1[j]);
        //   }
        // }  
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
        calSuccess = 0;
      }
    //} 
    // else {
    //   if(inputboxValidation == 0 && Number(this.totresps[0].poArtQty) != 0) {
    //     this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
    //     this.distributionSaveButtonForm.get('saveDistributionButton').enable();
    //     this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
    //     calSuccess = 1;
    //   }else{
    //     this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Something Error Happened.';
    //     this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    //     this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
    //     calSuccess = 0;
    //   }
    // }
    return calSuccess;
  }

  updateAssortmentIndex(targetLable: string, targetId: string) {
    this.selectedPopupIndex = +(targetId.substring(targetLable.length, (targetId.length)));
  }

  onClickEnableButton(assortment_index: any) {
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    if (control.get('assortmentTypes').value == SOLID_CODE) {
      control.get('numberOfCartons').disable();
      let assortmentCode = control.get('assortmentCode').value;
      let isAssortmentCodeEmpty = (assortmentCode == null || assortmentCode == '' || assortmentCode === undefined)?true:false;
      if (isAssortmentCodeEmpty) {
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').enable();
        this.isCalculateButtonShow = 0;     
        control.get('assortmentCode').setValue('');
        control.get('assortmentCode').disable();
      } else {
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
        this.isCalculateButtonShow = 1;  
      }
      for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
        var j = i + 1;
        let pairControlName = 'solid_pair' + j;
        control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).enable();
        let packingAssortmentControlName = 'solid_packingAssortment' + j;
        if (isAssortmentCodeEmpty) {
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).setValue('');
        }
      }
 
      if (isAssortmentCodeEmpty) {
        //control.get('distributionRequisitionQuantity').setValue(0) //
        //control.get('distributionOrderQuantity').setValue(0)
      }
    }
  }

  calculatePairsSolid(numberOfCartonsValue: number, assortment_index: any) {

    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    var totalPairs = 0;
    for(let j = 1; j <= this.sizepackingassortment[0].length; j++)
    {
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+j).setValue((control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+j).value) * numberOfCartonsValue);
      totalPairs += Number( control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+j).value)
    }
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalPairs);
    control.get('distributionOrderQuantity').setValue(totalPairs);
  }

  calculateTotalPackingAssortmentsSolid(assortment_index: any) {
    // let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    var solid_totalPackAssortments = 0;
      for(let j = 1; j < this.sizepackingassortment[0].length; j++)
      {
        solid_totalPackAssortments += Number(control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+j).value)
      }
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(solid_totalPackAssortments);

      return solid_totalPackAssortments;
  }  

  calculate(assortment_index: any) {    
    this.errorMessageBlank();  
    this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
    // let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let assortmentCode: string = control.get('assortmentCode').value;    
    let assortmentType: string = control.get('assortmentTypes').value;
    let distributionReqQty = control.get('requestedOrderQuantity').value;
    if(assortmentType != 'W' && (assortmentCode == ''|| assortmentCode == null )){
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Cannot calculate without Assortment Code';
      setTimeout(()=>{
        this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
      }, 3000);
      return false;      
    }else{  
      if ('W' == assortmentType) {
        this.displayLoaderWhenAssortmentTypeWholeSale=true;
        this.loaDer.nativeElement.style.display = 'block';
        this.loadingSpinnerMsg='Please wait assortment code is populating.'
        control.get('assortmentCheckBox').disable();  
        let assortmentType = control.get('assortmentTypes').value;
        let groupCode = this.groupCodeArray[0];
        let paTotal: number = 0;
        let pairs = {};
        let numberOfCartons: number = control.get('numberOfCartons').value;
  
        for (var j = 0; j < this.sizepackingassortment[0].length; j++) {
          let k = j + 1;
          let paControlName = 'solid_packingAssortment' + k;
          let pairControlName = 'solid_pair' + k;
          let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;          
          //if(paValue!=0){
          control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(numberOfCartons * paValue);
          paTotal += Number(paValue);
          //if (paValue != null && paValue != 0) {
          paValue = Number(paValue);
          pairs['pair' + this.sizepackingassortment[0][j].sizeBucket] = paValue;
          //}
        }
        if (paTotal != null && paTotal!=0) {
          //paTotal = Number(paTotal);
          this.isPrime =  this.checkPrime(paTotal);
          if (this.isPrime) {
            this.primeErrMssg = 'Pair per cartoon should not be a PRIME number for line '+(assortment_index + 1 );
            this.loaDer.nativeElement.style.display = 'none';
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          } else {
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
            let sessUsername = sessionStorage.getItem('currentUser');
            let userEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
            let assortment_code_param = {
              "groupCode": groupCode,
              "assortmentType": assortmentType,
              "pairs": pairs,
              "createdBy" : userEmail
            };
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
            this._distributionDetailService.getAssortmenstsValidate(assortment_code_param).subscribe(
              (response: DynamicPackingMaster) => {
                if (response != null) {
                  let assortmentCode = response.dataList[0].assortmentCode;
                  control.get('assortmentCode').setValue(assortmentCode);
				          this.calculateOrderQuantity(assortment_index);
                  this.loaDer.nativeElement.style.display = 'none';
                  this.displayLoaderWhenAssortmentTypeWholeSale=false;
                  this.loadingSpinnerMsg='';
                }
              }
            );  
            this.primeErrMssg = '';
            //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
            this.isTotalPairDistributionOrderQtyEqual = true; 
            this.isPairDivisible = true; 
            this.isPrime = false;
          }
        } else {
          this.primeErrMssg = 'Pair per cartoon should not be 0 for line '+(assortment_index + 1 );
          this.loaDer.nativeElement.style.display = 'none';
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }
      }   
      if ('S' === assortmentType) {
        let distributionReqQty: number = control.get('requestedOrderQuantity').value;
        let totalPackingAssortment: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
        let isDivisible = distributionReqQty != 0 && (distributionReqQty % totalPackingAssortment === 0) ? true : false;
        if (assortmentCode != null && assortmentCode !== '') {
            let paTotal: number = 0;
            //Distribution Req Quantity
            let distReqQty: number = control.get('requestedOrderQuantity').value;
            let orderReqQty: number = 0;
            let numberOfCartons: number = 0;
			      let assortmentSum: number = 0;//newly added TEST
            for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
              let sizeIndex = i + 1;
              let paControlName = 'solid_packingAssortment' + sizeIndex;
              let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
              if (!isNaN(paValue)) {
                paTotal += Number(paValue);
              }
            }
            //Total
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
            let a: number = 0;
            let b: number = 0;  
            for (let j = 0; j < this.sizepackingassortment[0].length; j++) {
              let sizeIndex = j + 1;
              let paControlName = 'solid_packingAssortment' + sizeIndex;
              let pairControlName = 'solid_pair' + sizeIndex;
              let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
			  
			      ////start newly added TEST
			        let mf = Math.round((distReqQty / paTotal));
			        let asst = mf * paValue;
			        assortmentSum = assortmentSum+asst;
			        let y = asst / paTotal;			  
              let y_ceil = Math.ceil(y);
              let y_floor = Math.floor(y);
              a = Number(paTotal * y_ceil);
              b = Number(paTotal * y_floor);
              if((assortmentSum > (orderReqQty+b)) && (assortmentSum > (orderReqQty+a))){
                if((assortmentSum-(orderReqQty+b)) > (assortmentSum-(orderReqQty+a))){
                  orderReqQty += a;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
                }else{
                  orderReqQty += b;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
                }
                
              }else if((assortmentSum > (orderReqQty+b)) && (assortmentSum < (orderReqQty+a))){
                  orderReqQty += b;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              }else if(assortmentSum == (orderReqQty+b)){
                  orderReqQty += b;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              }else if(assortmentSum == (orderReqQty+a)){
                  orderReqQty += a;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
              }
            }

            numberOfCartons = orderReqQty / paTotal; 
            control.get('numberOfCartons').setValue(numberOfCartons);
            control.get('distributionOrderQuantity').setValue(orderReqQty);
            control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(orderReqQty);

            if((orderReqQty <= (1.2 * distReqQty)) && (orderReqQty >= (0.8 * distReqQty))){
              this.responseMessage = '';   
            } else {
              this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
              this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            }            
          this.isTotalPairDistributionOrderQtyEqual = true; 
          this.isPairDivisible = true; 
          this.isPrime = false;
        }
        this.calculateBalance();
        this.checkRequisitionDistributionGreaterThanRequisitionOrder();
      }
    }
  }

  solid_sizes(resultArray) {
    var size = {};
        size['solid_size0'] = 'Size';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        size['solid_size'+j] = resultArray[i].artSize;
      }
        size['solid_totalSize'] = 'Total';
        size['solid_action'] = 'Action';

    return this.fb.group(size);
  }
 
  solid_packingAssortmentRow(resultArray, pairPerCarton) {
    var packingAssortment = {};
    var totpackingAssortment = 0;
        packingAssortment['solid_packingAssortment0'] = 'Packing Assortment';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        packingAssortment['solid_packingAssortment'+j] = resultArray[i].artSizeRatio;
        if(resultArray[i].artSizeRatio != undefined){
          totpackingAssortment += resultArray[i].artSizeRatio;
        }
      }
        packingAssortment['solid_totalPackingAssortment'] = pairPerCarton;
        packingAssortment['solid_actionPackingAssortment'] = 'Enable';

     return this.fb.group(packingAssortment)
  }
 
  solid_pairRow(resultArray) {
    var pair = {};
    var totpair = 0;
        pair['solid_pair0'] = 'Pairs';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        pair['solid_pair'+j] = resultArray[i].totalPairQty;
        totpair += resultArray[i].totalPairQty;
      }
        pair['solid_totalPairs'] = totpair;
        pair['solid_actionPairs'] = 'Calculate';

      return this.fb.group(pair)
  }  

  solid_packageAssortments(resultArray, pairPerCarton) {
    return this.fb.group({
      solid_sizes: this.solid_sizes(resultArray),
      solid_pairRow: this.solid_pairRow(resultArray),
      solid_packingAssortmentRow: this.solid_packingAssortmentRow(resultArray, pairPerCarton)
    });
  }

  resetDistributionLine(index) {
    this.errorMessageBlank();  
    let control1 = this.distributionDetailsForm.get(['companies', index]);
    var reqDistributionStat = control1.get('reqDistributionStat').value;

    if(control1.get('reqDistributionId').value != ''){ 
        if(reqDistributionStat == 'block'){
          control1.get('reqDistributionStat').setValue('none');
        }
    } else 
    {
        let control = <FormArray>this.distributionDetailsForm.controls.companies;
        control.removeAt(index);
        this.selectedPopupIndex = index - 1;
    } 
 
    this.addAssortment();
    this.isTotalPairDistributionOrderQtyEqual = false;
    this.isPrime = true;
    this.isPairDivisible = false;
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    this.calculateBalance();
  }

  onClickPairInput(assortment_index: any) {
    this.errorMessageBlank();    
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let numberOfCartons: number = control.get('numberOfCartons').value;
    let pairPerCartons: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
    let assortmentCode = control.get('assortmentCode').value;
    let onPairDivisible: boolean[] = [];
 
    var checkTotSolidPair = 0;
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      let j = i + 1;
      checkTotSolidPair += Number(control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value);
      let pair: number = control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value;
      let divisible: boolean = pair % pairPerCartons === 0 ? true : false;
      onPairDivisible.push(divisible);
    }
 
    let flag = 0;
    for (let i = 0; i < onPairDivisible.length; i++) {
      if (onPairDivisible[i]==false) {
        ++flag;
      }
    }
 
    //if(assortmentCode != '' || assortmentCode != 'null')
    //{
      // if(numberOfCartons != 0)
      //  {

      //Distribution order quantity can be changed after size wise pair update (which is divisible by Pair per cartoon) only for Enable of Solid WITH assortment code
        let reqOrdQty = control.get('requestedOrderQuantity').value;
        if((checkTotSolidPair <= (1.2 * reqOrdQty)) && (checkTotSolidPair >= (0.8 * reqOrdQty))){
          let index = this.pairFlag.indexOf(assortment_index + 1);
          if (index > -1) {
            this.pairFlag.splice(index, 1);
          }
          this.responseMessage = '';
          control.get('distributionOrderQuantity').setValue(checkTotSolidPair);
        } else {
          if(this.pairFlag.indexOf(assortment_index + 1) < 0){
            this.pairFlag.push(assortment_index + 1);
          }
          this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
        }

        if (flag > 0) {
          if(assortmentCode != '' && assortmentCode != null){
            if(numberOfCartons != 0)
            {
              this.disableCalculate = 1;
              this.sizeWisePairErrMssg = 'Size wise pair must be divisible by pair per cartons for line '+(assortment_index + 1 );
              this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
            } else 
            {
              this.disableCalculate = 0;
              this.sizeWisePairErrMssg = '';
              this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
            }
          }

          // if((checkTotSolidPair != control.get('distributionOrderQuantity').value)  && assortmentCode != '')
          // {
          //   this.isTotalPairDistributionOrderQtyEqual = false;
          //   this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution order qty for line ' + (assortment_index + 1);
          //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          //   if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
          //     this.pairFlag1.push(assortment_index + 1);
          //   }
          // }
           if((checkTotSolidPair != control.get('requestedOrderQuantity').value)  && (assortmentCode == ''|| assortmentCode == null)) {
            this.isTotalPairDistributionOrderQtyEqual = false;
            this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution requisition qty for line ' + (assortment_index + 1);
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
              this.pairFlag1.push(assortment_index + 1);
            }
          } else {
            this.isTotalPairDistributionOrderQtyEqual = true;
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            let index = this.pairFlag1.indexOf(assortment_index + 1);
            if (index > -1) {
              this.pairFlag1.splice(index, 1);
            }
            this.pairDistOrdErerrMssg = '';
          }
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();  
        } else {
          // if(checkTotSolidPair != control.get('distributionOrderQuantity').value && assortmentCode != '')
          // {
          //   this.isTotalPairDistributionOrderQtyEqual = false;
          //   this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution order qty for line ' + (assortment_index + 1);
          //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          //   if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
          //     this.pairFlag1.push(assortment_index + 1);
          //   }
          // }
          if((checkTotSolidPair != control.get('requestedOrderQuantity').value)  && (assortmentCode == ''|| assortmentCode == null))
          {
            this.isTotalPairDistributionOrderQtyEqual = false;
            this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution requisition qty for line ' + (assortment_index + 1);
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
              this.pairFlag1.push(assortment_index + 1);
            }
          }          
          else
          {
            this.isTotalPairDistributionOrderQtyEqual = true;
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            let index = this.pairFlag1.indexOf(assortment_index + 1);
            if (index > -1) {
              this.pairFlag1.splice(index, 1);
            }
            this.pairDistOrdErerrMssg = '';
          } 
          this.sizeWisePairErrMssg = '';
          this.disableCalculate = 0;
        }
      // } 
    //}    
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(checkTotSolidPair);
 
    let distributionReqQty: number = control.get('requestedOrderQuantity').value;
    this.checkRequisitionDistributionGreaterThanRequisitionOrder();
    // this.isdistributionRequsitionQuantityGreaterRequisitionQuantity = (distributionReqQty > (parseInt(this.totresps[0].poArtQty) * 1.2)) ? true : false;
    // if (this.isdistributionRequsitionQuantityGreaterRequisitionQuantity) {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Requisition Quantity is greater than 20% of Requisition Quantity, please correct it.';
    // }
    // else {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
    // }
    let total_pair:number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
    if(total_pair > 0){
      if(assortmentCode == '' || assortmentCode == null){
        let cartoon: number = Math.ceil(checkTotSolidPair/total_pair);
        control.get('numberOfCartons').setValue(cartoon);
      }
      //this.sizeWisePairErrMssg = '';
    } else {
      let cartoon: number = 0;
      control.get('numberOfCartons').setValue(cartoon);
      this.sizeWisePairErrMssg = 'Total pair should not be blank';
    }
    
    if (checkTotSolidPair == control.get('requestedOrderQuantity').value) {
      control.get('distributionOrderQuantity').setValue(checkTotSolidPair);
    }
 
  }

  disableSave() {
    let control = this.getAssortmentFormControl();
    let numberOfDistributionLines = (<FormArray>this.distributionDetailsForm.controls.companies).length;
    if (numberOfDistributionLines == 1 && this.selectedAssortmentType == ASSORTED_CODE && control.get('assortmentCode').value != null ) {
      this.distributionSaveButtonForm.get('saveDistributionButton').enable();
    }
    else {
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    }
  }

  validateTotalPackingAssotmentIfPrime(assortment_index: any) {
    this.errorMessageBlank();  
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let total = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
    if(total == 0 || total == null){
      this.changeType(assortment_index);
    }
    if (SOLID_CODE == control.get('assortmentTypes').value) {
      let paTotal = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
      // if (paTotal != null) {
      //   for (let k = 2; k <= Number(paTotal / 2); k++) {
      //     if (paTotal % k == 0) {
      //       this.primeErrMssg = '';
      //       this.isPrime = false;
      //       this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      //     } else {
      //       this.primeErrMssg = 'Pair per cartoon should not be a PRIME number, please correct it.';
      //       this.isPrime = true;
      //       this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      //     }
      //   }
      // } 
      if (paTotal != null && paTotal!=0) {
        //paTotal = Number(paTotal);
        this.isPrime =  this.checkPrime(paTotal);
        if (this.isPrime) {
          this.primeErrMssg = 'Pair per cartoon should not be a PRIME number for line '+(assortment_index + 1 );
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        } else {
          this.onClickPairInput(assortment_index);
          this.primeErrMssg = '';
          //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        }
      }
 
    }
  }

  checkPrime(n: any) {
    if (n == 1) {
      return false;
    }
    else if (n == 2) {
      return true;
    } else {
      for (var x = 2; x < n; x++) {
        if (n % x == 0) {
          return false;
        }
      }
      return true;
    }
  }

  errorMessageBlank(){
    this.responseMessage = '';
    this.primeErrMssg = '';
    this.sizeWisePairErrMssg = '';
    this.pairDistOrdErerrMssg = '';
    this.hasDuplicateDistibutionErrMsg = '';
    this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
    this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
    this.distributionRequsitionQuantityEmptyErrMsg = '';
    this.distributionOrderQuantityEmptyErrMsg = '';
    this.pairPerCartonRatioEmptyErrMsg = '';
  }

}
