import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { RequisitionLines } from '../search-requisition/requisition-lines';
import { DistributionDetailMultipleService } from '../distribution-detail-multiple/distribution-detail-multiple.service';
import { DistributionDetailCompletedService } from '../distribution-detail-completed/distribution-detail-completed.service';
import { Location } from '../distribution-detail-multiple/location';
import { PackingMaster } from '../distribution-detail-multiple/packing-master';
import { RETAIL_DIVISION, NON_RETAIL_DIVISION, LOCATION_DIVISION_PARAM, RETAIL_DIVISION_VALUE, NON_RETAIL_DIVISION_VALUE, EXPORT_DIVISION, SOLID, ASSORTED, WHOLE_SALE, ASSORTED_CODE, SOLID_CODE, WHOLE_SALE_CODE } from '../common/field-name-list';
import { RequisitionAssortment } from './requisition-assortment';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import {DynamicPackingMaster} from './dynamic-packing-master';
import { DynamicPackingMasterList } from './dynamic-packing-master-data-list';
import { DynamicPackingMasterAssortment } from './dynamic-packing-master-assortment';
import { ValueTransformer } from '@angular/compiler/src/util';
 
@Component({
  selector: 'app-distribution-detail-completed',
  templateUrl: './distribution-detail-completed.component.html',
  styleUrls: ['./distribution-detail-completed.component.css']
})
export class DistributionDetailCompletedComponent implements OnInit {
  @ViewChild('ref', {static: false}) ref: ElementRef;
  @ViewChild('closeBtn', {static: false}) closeBtn: ElementRef;
  @ViewChild('orderQty', {static: false}) orderQty: ElementRef;
  @ViewChild('reqorderQty', {static: false}) reqorderQty: ElementRef;
  @ViewChild('balanceorderQty', {static: false}) balanceorderQty: ElementRef;
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;
  @ViewChild('blurcss', {static: false}) blurcss: ElementRef;
 
  myForm: any;
  totresps: any; 
  searchDisrtibutionDetails: RequisitionLines;
  distributionPageTitle: string;
  isPreviousPageRequisition: boolean;
  distributionDetailsForm: FormGroup;
  assortmentTypes = [];
  selectedItem: any;
  locationArray: Location[] = [];
  fakeArray = [];
  sizeWiseArticleArray = [];
  sizeWiseQtyArray = [];
  selectedPopupIndex: number = 0;
  selectedCategoryCode: string;
  selectedShopCode: string;
  selectedAssortmentTypeId: string;
  packingMasterList: PackingMaster[];
  assortment_index: any;
  selectedAssortmentIndex: any;
  displayDefaultValPackMaster: boolean = true;
  packingAssortmentDetails: PackingMaster = new PackingMaster();
  packingAssortmentDetailsDynamicList: PackingMaster[] = [];
  assortmentCode: string;
  packCode: string;
  totalPackAssortments: number;
  totalParis: number;
  results: any;
  data1: any;
  displayDefaultValPair: boolean = true;
  distributionReqQty: number;
  saveDisabled: boolean = false;
  responseMessage: string;
  selectedAssortmentType: string;
  groupCodeArray: string[];
  editablePackageAssortmentsArray: number[];
  addDistributionTypeSolidPackCode:boolean=false;
  addDistributionTypeSolidPackCodeEnable: boolean = false;
  deleteDisable: boolean = true;
  isOrderQuantityCalculated: boolean = false; 
  sizepackingassortment: any;
  packingMaster: any;
  dataList: DynamicPackingMasterList[];
  packingMasterAssortmentsDTO: DynamicPackingMasterAssortment[];
 
  isPairDivisible: boolean;
  primeErrMssg: string;
  sizeWisePairErrMssg: string;
  pairDistOrdErerrMssg: string;
  flag: any;
  isPrime: boolean;
  distributionSaveButtonForm: FormGroup;
  isTotalPairDistributionOrderQtyEqual: boolean;
  hasDuplicateDistibutionErrMsg: string;
  distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg: string;
  orderQuantityGreaterRequisitionQuantityErrMsg: string;
  displayLoaderWhenAssortmentTypeWholeSale:boolean=false;
  loadingSpinnerMsg:string;
  isdistributionRequsitionQuantityGreaterRequisitionQuantity: boolean;
  hideDistribution: boolean = true;
  hideIndex: any;
  hideStat = 'visible';
  orderedQuantity: number = 0;
  distReqQuantity: number = 0;// Balance logic change
  bq: number = 0;
  requisitionQuantity: number = 0;
  ShowSave:number = 0;
  distributionOrderQuantityEmptyErrMsg: string;
  distributionRequsitionQuantityEmptyErrMsg: string;
  pairPerCartonRatioEmptyErrMsg: string;
  groupcode: string;
  enableSave:string;
  showCalculate:number = 0;
  pairFlag = [];
  pairFlag1 = [];
  disableCalculate: number = 0;
  assormentMessage:string;  
  requisitionSizeArr: any[];
  sizeWisePndgArray:any[];
  arrayForPndgCal:any[];
 
  constructor(private _distributionDetailService: DistributionDetailMultipleService, private _distributionDetailCompletedService: DistributionDetailCompletedService, private _route: ActivatedRoute,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private fb: FormBuilder
  ) {
    this.arrayForPndgCal=[];
    this.distributionDetailsForm = this.fb.group({
      companies: this.fb.array([])
    });
    this.enableSave = this._navigationLinkParameterService.flag;
    this.distributionSaveButtonForm = this.buildDistributionSaveForm()
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    this.setCompanies();    
  }
 
  buildDistributionSaveForm() {
    let saveButtons = {};
    saveButtons['saveDistributionButton'] = 'Save';
    saveButtons['calculateOrderQuantityButton'] = 'Calculate Order Quantity';
    return this.fb.group(saveButtons);
  }

  sizeWiseArticle() {
    console.log(this.arrayForPndgCal);
    let control = <FormArray>this.distributionDetailsForm.controls.companies;   
    console.log(control);
    if(this.sizepackingassortment[0] != null && this.sizepackingassortment[0].length >0){
      var resultArray = [];
      for(var j = 0; j < this.sizepackingassortment[0].length; j++)
      {
        resultArray.push({
            "ReqAssortmentId": 0,
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }
      this.sizeWiseArticleArray = [];
      this.sizeWiseQtyArray = [];
      this.sizeWisePndgArray=[];
      for (let i = 0; i < resultArray.length; i++) {
        this.sizeWiseArticleArray.push(resultArray[i].artSize); 
      } 
      console.log(resultArray);
      
      console.log(this.sizeWiseArticleArray);
        
      let requisition_size_param = [{ 'name': 'reqLineId', 'value': this.searchDisrtibutionDetails.reqLineId }]; 
      this._distributionDetailService.getRequisitionSizeInfo(requisition_size_param).subscribe(
        (response:any) => {
          this.requisitionSizeArr = response.dataList;     
          console.log(this.requisitionSizeArr);
          console.log(this.arrayForPndgCal);
                 
          if(this.requisitionSizeArr.length > 0){               
            for(let j=0; j<this.sizeWiseArticleArray.length; j++){  
              let index = this.requisitionSizeArr.findIndex(x => x.sizeLabel === this.sizeWiseArticleArray[j]);
              if(index == -1){ 
                this.sizeWiseQtyArray.push(0);
                this.sizeWisePndgArray.push(0);
              }else{
                this.sizeWiseQtyArray.push(this.requisitionSizeArr[index].pairQuantity);
                this.sizeWisePndgArray.push(this.requisitionSizeArr[index].pairQuantity);
              }
            }
            console.log(this.sizeWiseQtyArray);
          }else{
            for(let j=0; j<this.sizeWiseArticleArray.length; j++){  
              this.sizeWiseQtyArray.push(0);
              this.sizeWisePndgArray.push(0);
            }
          }
          console.log(this.arrayForPndgCal);
          
          if(this.arrayForPndgCal.length>0){
            this.arrayForPndgCal.forEach(reqRow => {
              reqRow.forEach((element, index)=>{
                console.log(element.totalPairQty);
                console.log(this.sizeWisePndgArray[index]);
                console.log(this.sizeWisePndgArray[index] - element.totalPairQty);
                
                this.sizeWisePndgArray[index]=(this.sizeWisePndgArray[index] - element.totalPairQty)<0?0:(this.sizeWisePndgArray[index] - element.totalPairQty);
              });
            });

          }
        }
      ); 
    }    
  }
 
  sizes(resultArray) {
    this.sizeWiseArticleArray = [];
    var size = {};
        size['size0'] = 'Size';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        size['size'+j] = resultArray[i].artSize; 
        this.sizeWiseArticleArray.push(resultArray[i].artSize); 
      }
        size['totalSize'] = 'Total';
 
    return this.fb.group(size)
  }
 
  packingAssortmentRow(resultArray) {
      var packingAssortment = {};
      var totpackingAssortment = 0;
          packingAssortment['packingAssortment0'] = 'Packing Assortment';
        for(let i = 0; i < resultArray.length; i++)
        {
          let j = i + 1;
          packingAssortment['packingAssortment'+j] = resultArray[i].pairQty;
          totpackingAssortment += resultArray[i].pairQty;
        }
          packingAssortment['totalPackingAssortment'] = totpackingAssortment;
 
    return this.fb.group(packingAssortment)
  }
 
  pairRow(resultArray) {
      var pair = {};
      var totpair = 0;
          pair['pair0'] = 'Pairs';
        for(let i = 0; i < resultArray.length; i++)
        {
          let j = i + 1;
          pair['pair'+j] = resultArray[i].totalPairQty;
          totpair += resultArray[i].totalPairQty;
        }
          pair['totalPairs'] = totpair;
 
    return this.fb.group(pair)
  }
 
  reqassortmtid(resultArray) {
    var reqassort = {};
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        reqassort['reqAssortmentId'+j] = resultArray[i].ReqAssortmentId;
      }
 
    return this.fb.group(reqassort)
  }
 
  packageAssortments(resultArray) {
    return this.fb.group({
      sizes: this.sizes(resultArray),
      reqassortmtid: this.reqassortmtid(resultArray),
      pairRow: this.pairRow(resultArray),
      packingAssortmentRow: this.packingAssortmentRow(resultArray)
    });
  }
 
  setCompanies() {
    let control = <FormArray>this.distributionDetailsForm.controls.companies;    
    //this.loaDer.nativeElement.value == 'block';

    let distributionDetails: any = { distributionPageTitle: '', distributionPageDataArray: [], isPreviousPageRequisition: false }
    distributionDetails = this._navigationLinkParameterService.distributionDetails;
    this.distributionPageTitle = distributionDetails.distributionPageTitle;
    this.searchDisrtibutionDetails = distributionDetails.distributionPageDataArray[0];
    this.isPreviousPageRequisition = distributionDetails.isPreviousPageRequisition;
    let req_id = {
      ReqLineID: this.searchDisrtibutionDetails.reqLineId
    }
    this.requisitionQuantity = +this.searchDisrtibutionDetails.reqQty;
    //this.distReqQuantity = +this.searchDisrtibutionDetails.orderedQty;    
    let letterOfArticle: string = this.searchDisrtibutionDetails != null ? this.searchDisrtibutionDetails.artNumber.toString().charAt(0) : '';
    let editable_assortment_packages_param = { 'name': 'letterOfArticle', 'value': letterOfArticle };
    this.sizepackingassortment = [];
    this._distributionDetailService.getEditableAssortmentPackagesForSolidAssortmentType(editable_assortment_packages_param)
      .subscribe(
        (response) => {
          this.sizepackingassortment.push(response['dataList']);
        }
      );    
    this.groupCodeArray = [];  
    this.distReqQuantity = 0;
    this.orderedQuantity = 0;
    setTimeout(()=>{
      this._distributionDetailCompletedService.getDistributionDetails(req_id).subscribe(
        (response) => {
          this.totresps = response;
         let k=0;
          this.totresps.forEach(x => {
            var blankDataSet = [];
            for(var j = 0; j < this.sizepackingassortment[0].length; j++)
            {
              this.groupCodeArray.push(this.sizepackingassortment[0][j].groupCode);
              blankDataSet.push({
                  "ReqAssortmentId": 0,
                  "artSize": this.sizepackingassortment[0][j].artSize,
                  "articleSize": this.sizepackingassortment[0][j].sizeBucket,
                  "pairQty": 0,
                  "totalPairQty": 0
                });
            }
            this.distReqQuantity += Number(x.distributionReqQuantity);
            var resultArray = [];
            resultArray = blankDataSet.reduce((arr, e) => {
              arr.push(Object.assign({}, e, x.requisitionAssortment.find(a => e.articleSize == a.articleSize)))
              return arr;
            }, []); 
            this.fakeArray = new Array(this.sizepackingassortment[0].length);
            if(x.assortmentCode == null || x.assortmentCode == "null") {
              x.assortmentCode = '';
            }
            this.arrayForPndgCal.push(resultArray);
            console.log(resultArray);
            console.log(blankDataSet);
            console.log(this.arrayForPndgCal);
            
            
            control.push(this.fb.group({ 
              locationArray: x.rdcName+'~'+x.rdcCode,
              assortmentTypes: x.assortmentType,
              reqDistributionId: x.reqDistributionId,
              reqDistributionStatus: x.status,
              reqDistributionStat: 'block',
              assortmentCode: x.assortmentCode,
              numberOfCartons: { value: x.cartonQty, disabled: true },
              distributionRequisitionQuantity: x.distributionReqQuantity,
              distributionOrderQuantity: { value: x.totalArtQty, disabled: true },
              assortmentCheckBox: { value: false },
              reqcartonDivisible: '',
              packageAssortments: this.packageAssortments(resultArray),
              solid_packageAssortments: this.solid_packageAssortments(resultArray, x.pairPerCarton)
            }));
            this.orderedQuantity += x.totalArtQty;
            this.loaDer.nativeElement.style.display = 'none';  
            k++;
          });          
          this.searchDisrtibutionDetails.orderedQty = this.orderedQuantity.toString();
          console.log(this.arrayForPndgCal);
          this.sizeWiseArticle();  
        },
        (error)=>{
          console.log(error);
          console.log(this.arrayForPndgCal);
          this.sizeWiseArticle();
          
        }
      );
      
      // this.sizeWiseArticle();  
    }, 3000);
    
    this.showButton();
    this.showCalculateButton();
  }
 
  getAssortmentType(division: string, conceptCode: string) {
    if (division != null) {
      this.assortmentTypes = [];
      if (RETAIL_DIVISION.toLowerCase() === division.toLowerCase()) {
        this.assortmentTypes.push({ id: ASSORTED_CODE, name: ASSORTED });
        this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
      }
      if (NON_RETAIL_DIVISION.toLowerCase() === division.toLowerCase()) {
          this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
          this.assortmentTypes.push({ id: WHOLE_SALE_CODE, name: WHOLE_SALE });          
      }
      if (EXPORT_DIVISION.toLowerCase() === division.toLowerCase()) {
        this.assortmentTypes.push({ id: SOLID_CODE, name: SOLID });
      }
    } 
    return this.assortmentTypes; 
  }
 
  addAssortment()
  {
    var blankDataSet = [];
      for(var j = 0; j < this.sizepackingassortment[0].length; j++)
      {
        blankDataSet.push({
            "ReqAssortmentId": 0,
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }
 
      let control = <FormArray>this.distributionDetailsForm.controls.companies;
      control.push(
        this.fb.group({
          assortmentCode: [''],
          numberOfCartons: [''],
          distributionRequisitionQuantity: [''],
          distributionOrderQuantity: [''],
          assortmentTypes: [''],
          locationArray: [''],
          reqDistributionId: [''], 
          reqDistributionStatus: 'A',
          reqDistributionStat: [''],
          reqcartonDivisible: '',
          assortmentCheckBox: { value: false },
          solid_packageAssortments: this.solid_packageAssortments(blankDataSet, '0'),
          packageAssortments: this.packageAssortments(blankDataSet),
        }));
        this.deleteDisable = false;
        this.isOrderQuantityCalculated = false;
  } 
 
  ngOnInit() { 
    let distributionDetails: any = { distributionPageTitle: '', distributionPageDataArray: [], isPreviousPageRequisition: false }
    distributionDetails = this._navigationLinkParameterService.distributionDetails;
    this.distributionPageTitle = distributionDetails.distributionPageTitle;
    this.searchDisrtibutionDetails = distributionDetails.distributionPageDataArray[0];
    this.isPreviousPageRequisition = distributionDetails.isPreviousPageRequisition;
    this.selectedCategoryCode = this.searchDisrtibutionDetails.categoryNo;
    let division = this.searchDisrtibutionDetails.division;
    let division_value: string = "";
 
    if (RETAIL_DIVISION.toLowerCase() === division.toLowerCase())
      division_value = RETAIL_DIVISION_VALUE;
 
    if (NON_RETAIL_DIVISION.toLowerCase() === division.toLowerCase())
      division_value = NON_RETAIL_DIVISION_VALUE;
 
    let sessUsername = sessionStorage.getItem('currentUser');
      let location_param: any = [
        {"name": "shopAgencyCode", "value": division_value},
        {"name": "shopCloseYrWk1", "value": LOCATION_DIVISION_PARAM},
        {"name": "userEmail", "value": JSON.parse(sessUsername).groupAssignmentModels[0].userCode},
        {"name": "conceptCode", "value": this.searchDisrtibutionDetails.conceptCode}
      ];
 
    this.fetchLocation(location_param);
    this.assortmentTypes = this.getAssortmentType(division, this.searchDisrtibutionDetails.conceptCode);

  } 
 
  fetchLocation(location_param: any) {
    this._distributionDetailService.getLocations(location_param).subscribe(
      (response: any) => {
        this.locationArray = response.dataList;
      }
    );
    return this.locationArray;
  }
 
  getAssortmentFormControl() {
    let assortments_index: number = this.selectedPopupIndex;
    let control = this.distributionDetailsForm.get(['companies', assortments_index]);
    return control;
  }
 
  fetchAssortmenDetails(event: any, assortment_index) {
    console.log('Call onInit');
    
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let location: string = control.get('locationArray').value;
    let selectedShopName = location.split('~')[0];
    selectedShopName = selectedShopName.substr(0, selectedShopName.length - 1);
    let selectedShopCode = location.split('~')[1];
    selectedShopCode = selectedShopCode.substr(0, selectedShopCode.length);
    // let assortmentTypes: string = control.get('assortmentTypes').value;
    let selectedAssortmentTypeId: string = ASSORTED_CODE;
 
    let targetId: string = event.target.id;
    let checkBoxLabel: string = "chkOpenPackPopup";
    this.selectedPopupIndex = +(targetId.substring(checkBoxLabel.length, (targetId.length)));
 
    let assortment_code_param = [
      {"name": "catCode", "value": this.selectedCategoryCode},
      {"name": "shopCode", "value": selectedShopCode},
      {"name": "assortmentType", "value": selectedAssortmentTypeId},
      {"name": "flag", "value": "N"},
      {"name": "groupCode", "value": this.groupCodeArray[0]},
      {"name": "concept", "value": this._navigationLinkParameterService.distributionDetails.distributionPageDataArray[0].conceptCode}
    ];
 
    this._distributionDetailService.getAssortmensts(assortment_code_param).subscribe(
      (response: DynamicPackingMaster) => {
        if (response != null) {
          if(response.dataList!=null && 
            response.dataList.length >0 ){
 
              let dataListLength = response.dataList.length;
              this.dataList=[];
              this.packingMasterAssortmentsDTO=[];
              for(let i=0; i<dataListLength;i++){
                if(response.dataList[i]!=null && response.dataList[i].packingMasterAssortmentsDTO!=null 
                  && response.dataList[i].packingMasterAssortmentsDTO.length>0){
                    let totalPairQty =0;
                    for(let j=0;j<response.dataList[i].packingMasterAssortmentsDTO.length;j++){
                        this.packingMasterAssortmentsDTO.push(response.dataList[i].packingMasterAssortmentsDTO[j]);
                        totalPairQty += response.dataList[i].packingMasterAssortmentsDTO[j].pairQty;
                        response.dataList[i].totalPairQty = totalPairQty;
                  }
                }
                this.dataList.push(response.dataList[i]);
                this.packingMaster = response.dataList; 
              }
          }else {
            this.assormentMessage = 'No data found';
            setTimeout(()=>{
              this.assormentMessage = '';
            }, 5000);
          }
        }else {
          this.assormentMessage = 'No data found';
          setTimeout(()=>{
            this.assormentMessage = '';
          }, 5000);
        }
      },
      (err)=>{
        this.assormentMessage = err;
        setTimeout(()=>{
          this.assormentMessage = '';
        }, 5000);
      });
  } 
 
  get assortments() {
    return this.distributionDetailsForm.get('companies') as FormArray;
  }
 
  getAssortmentsIndex() {
    let assortments_index: number = this.assortments.length - 1;
    return assortments_index;
  }
 
  selectedAssortment(index) {
    let selectedPackingAssortment = this.packingMaster[index];
    let sizeBucketCount = this.sizepackingassortment[0].length;
    let control = this.getAssortmentFormControl();
    var fakeArr = [];
    var blankDataSet = [];
      for(var j = 0; j < sizeBucketCount; j++)
      {
        blankDataSet.push({
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }
 
      selectedPackingAssortment.packingMasterAssortmentsDTO.forEach(k => {
          fakeArr.push(k);
      });
 
      var resultArray = [];
      resultArray = blankDataSet.reduce((arr, e) => {
        arr.push(Object.assign({}, e, fakeArr.find(a => e.articleSize == a.articleSize)))
        return arr;
      }, []);
 
      var totPackAssort = 0;
      if (this.selectedAssortmentType === ASSORTED_CODE) {
          if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;
              control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment'+k).setValue(eval(`resultArray[${i}].pairQty`));
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(totPackAssort);
          }
      } else
      {
        if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+k).setValue(eval(`resultArray[${i}].pairQty`));
              control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+k).setValue('0');
              control.get('numberOfCartons').setValue('');
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+k).disable();
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + resultArray.length).disable();
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(totPackAssort);
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
          }
      }
 
      control.get('assortmentCode').setValue(selectedPackingAssortment.packCode);
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
      control.get('distributionOrderQuantity').setValue(0);
      control.get('numberOfCartons').disable();
  }

  validateAssortment(event: any, assortment_index) { 
    this.loaDer.nativeElement.style.display = 'block';
    var numbers = /^[0-9]+$/;
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    console.log(control.value);
    if(event.target.value.match(numbers))
    { 
      // let control = this.getAssortmentFormControl();        
      let location: string = control.get('locationArray').value; 
      // let assortmentTypes: string = control.get('assortmentTypes').value;
      let selectedAssortmentTypeId: string = ASSORTED_CODE;
      if((control.get('assortmentTypes').value != '' && control.get('assortmentTypes').value != null) && (location != '' && location != null)){ 
        let selectedShopName = location.split('~')[0];
        selectedShopName = selectedShopName.substr(0, selectedShopName.length - 1);
        let selectedShopCode = location.split('~')[1];
        selectedShopCode = selectedShopCode.substr(0, selectedShopCode.length);
        let dataList1 = [];
        // let assortmentTypes: string = control.get('assortmentTypes').value; 
        let assortment_code_param = [
          {"name": "catCode", "value": this.selectedCategoryCode},
          {"name": "shopCode", "value": selectedShopCode},
          {"name": "assortmentType", "value": selectedAssortmentTypeId},
          {"name": "flag", "value": "N"},
          {"name": "groupCode", "value": this.groupCodeArray[0]},
          {"name": "concept", "value": this._navigationLinkParameterService.distributionDetails.distributionPageDataArray[0].conceptCode}
        ];

        this._distributionDetailService.getAssortmensts(assortment_code_param).subscribe(
        (response: DynamicPackingMaster) => {            
          if (response != null) {
            if(response.dataList!=null && response.dataList.length >0 ){              
              let dataListLength = response.dataList.length;
              this.dataList=[];
              this.packingMasterAssortmentsDTO=[];
              for(let i=0; i<dataListLength;i++){
                if(response.dataList[i]!=null && response.dataList[i].packingMasterAssortmentsDTO!=null 
                  && response.dataList[i].packingMasterAssortmentsDTO.length>0){
                    let totalPairQty =0;
                    for(let j=0;j<response.dataList[i].packingMasterAssortmentsDTO.length;j++){
                        this.packingMasterAssortmentsDTO.push(response.dataList[i].packingMasterAssortmentsDTO[j]);
                        totalPairQty += response.dataList[i].packingMasterAssortmentsDTO[j].pairQty;
                        response.dataList[i].totalPairQty = totalPairQty;
                  }
                }
                this.dataList.push(response.dataList[i]);
                dataList1.push(response.dataList[i]);
                this.packingMaster = response.dataList; 
              }
            
              let flag = 0;
              if(dataList1.length > 0){
                for(let k=0; k<dataList1.length; k++){
                  if(dataList1[k].packCode == event.target.value.toString()){
                    flag ++ ;
                    this.tabselectedAssortment(k,assortment_index); 
                  }                 
                }
              }
              if(flag == 0){
                this.assormentMessage = 'No matching assortment code found';
                this.changeType(assortment_index);
                setTimeout(()=>{
                  this.assormentMessage = '';
                }, 5000);
              }
              this.loaDer.nativeElement.style.display = 'none'; 
            }
            else {
              this.assormentMessage = response.message;
              this.changeType(assortment_index);
              setTimeout(()=>{
                this.assormentMessage = '';
              }, 5000);
              this.loaDer.nativeElement.style.display = 'none'; 
            }
          } else {
            this.assormentMessage = 'No data found';
            this.changeType(assortment_index);
            setTimeout(()=>{
              this.assormentMessage = '';
            }, 5000);
            this.loaDer.nativeElement.style.display = 'none'; 
          }
        },
        (err)=>{
          this.assormentMessage = err;
          this.changeType(assortment_index);
          setTimeout(()=>{
            this.assormentMessage = '';
          }, 5000);
          this.loaDer.nativeElement.style.display = 'none'; 
        });
        
      } else {
        this.loaDer.nativeElement.style.display = 'none'; 
        this.responseMessage = 'Please choose location and assorment type.';
        this.changeType(assortment_index);
        setTimeout(()=>{
          this.responseMessage = '';
        }, 5000);      
      }  
    } else {
      this.loaDer.nativeElement.style.display = 'none'; 
      this.responseMessage = 'Please enter numeric value only.';
      this.changeType(assortment_index);
      setTimeout(()=>{
        this.responseMessage = '';
      }, 5000); 
    } 
    console.log(control.value);
  }

  tabselectedAssortment(index,assortment_index) {
    
    if(index != undefined){ 
      let selectedPackingAssortment = this.packingMaster[index];
      let sizeBucketCount = this.sizepackingassortment[0].length;
      let control = this.distributionDetailsForm.get(['companies', assortment_index]);
      var fakeArr = [];
      var blankDataSet = [];
      for(var j = 0; j < sizeBucketCount; j++)
      {
        blankDataSet.push({
            "artSize": this.sizepackingassortment[0][j].artSize,
            "articleSize": this.sizepackingassortment[0][j].sizeBucket,
            "pairQty": 0,
            "totalPairQty": 0
          });
      }
 
      selectedPackingAssortment.packingMasterAssortmentsDTO.forEach(k => {
          fakeArr.push(k);
      });
 
      var resultArray = [];
      resultArray = blankDataSet.reduce((arr, e) => {
        arr.push(Object.assign({}, e, fakeArr.find(a => e.articleSize == a.articleSize)))
        return arr;
      }, []);
      var totPackAssort = 0;
      if (control.get('assortmentTypes').value === ASSORTED_CODE) {
          if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;
              control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment'+k).setValue(eval(`resultArray[${i}].pairQty`));
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(totPackAssort);
          }
      } else
      {
        if(resultArray.length > 0)
          {
            for(let i = 0; i < resultArray.length; i++)
            {
              let k= i + 1;
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+ k).setValue(eval(`resultArray[${i}].pairQty`));
              control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+ k).setValue('0');
              control.get('numberOfCartons').setValue('');
              control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+ k).disable();
              totPackAssort += eval(`resultArray[${i}].pairQty`);
            }
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + resultArray.length).disable();
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(totPackAssort);
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
          }
      }
 
      control.get('assortmentCode').setValue(selectedPackingAssortment.packCode);
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
      control.get('distributionOrderQuantity').setValue(0);
      control.get('distributionRequisitionQuantity').setValue(0);
      control.get('numberOfCartons').disable();
    }
  }

  changeType(assortment_index){
    this.errorMessageBlank();  
    var control = this.distributionDetailsForm.get(['companies', assortment_index]);
    control.get('assortmentCode').enable();
    control.get('assortmentCode').setValue('');
    control.get('numberOfCartons').setValue('');
    control.get('distributionRequisitionQuantity').setValue('');
    control.get('distributionOrderQuantity').setValue('');
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      let solidpairControlName = 'solid_pair' + j;
      let solidpackingAssortmentControlName = 'solid_packingAssortment' + j;
      let pairControlName = 'pair' + j;
      let packingAssortmentControlName = 'packingAssortment' + j;
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(solidpackingAssortmentControlName).setValue('');
      control.get('solid_packageAssortments').get('solid_pairRow').get(solidpairControlName).setValue('');
      control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).setValue('');
      control.get('packageAssortments').get('pairRow').get(pairControlName).setValue('');
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue('');
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue('');
      control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue('');
      control.get('packageAssortments').get('pairRow').get('totalPairs').setValue('');
    }
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    console.log(this.distributionDetailsForm.controls.companies.value);
  }

  changeAssortmentCode(assortment_index){
    this.errorMessageBlank(); 
    var control = this.distributionDetailsForm.get(['companies', assortment_index]);
    console.log(this.distributionDetailsForm.controls.companies.value);
    control.get('assortmentCode').enable();
    control.get('assortmentCode').setValue('');
    this.hasDuplicateDistibutionErrMsg = '';
    control.get('numberOfCartons').setValue('');
    control.get('distributionRequisitionQuantity').setValue('');
    control.get('distributionOrderQuantity').setValue('');
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      var j = i + 1;
      let solidpairControlName = 'solid_pair' + j;
      let solidpackingAssortmentControlName = 'solid_packingAssortment' + j;
      let pairControlName = 'pair' + j;
      let packingAssortmentControlName = 'packingAssortment' + j;
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(solidpackingAssortmentControlName).setValue(0);
      control.get('solid_packageAssortments').get('solid_pairRow').get(solidpairControlName).setValue(0);
      control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).setValue(0);
      control.get('packageAssortments').get('pairRow').get(pairControlName).setValue(0);
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(0);
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(0);
      control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').setValue(0);
      control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(0);
    }
    console.log(this.distributionDetailsForm.controls.companies.value);
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
  }
 
  calculatePairs(numberOfCartonsValue: number, assortment_index: any) {
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    for(var k = 1; k <= this.sizepackingassortment[0].length; k++)
    {
      control.get('packageAssortments').get('pairRow').get('pair'+k).setValue(control.get('packageAssortments').get('packingAssortmentRow').get('packingAssortment'+k).value * numberOfCartonsValue);
    } 
    console.log(control.value);
  }  
 
  calculateOrderQuantity(assortment_index: any){
    this.errorMessageBlank();  
    // let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let distributionReqQty: number = control.get('distributionRequisitionQuantity').value;
    if (control.get('assortmentTypes').value == ASSORTED_CODE) {
        let totalPackingAssortment: number = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;
        if (totalPackingAssortment > 0) {
          this.displayDefaultValPair = false; //used in htlm
          let numberOfCartonsValue: number;
          let totalParis: number;
          
           /// Distribution location line level validation - distribution order quantity should be with in +-20% of distribution req quantity(for Req)/ requested order quantity(for PO) otherwise throw validation error
          numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
          totalParis = totalPackingAssortment * numberOfCartonsValue;
          this.calculatePairs(numberOfCartonsValue, assortment_index);
          control.get('numberOfCartons').setValue(numberOfCartonsValue);
          control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(totalParis);
          control.get('distributionOrderQuantity').setValue(totalParis);

          if((totalParis <= (1.2 * distributionReqQty)) && (totalParis >= (0.8 * distributionReqQty))){
            this.saveDisabled = true;   
            this.responseMessage = '';          
          } else {
            this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          }
          //distributionReqQty = control.get('distributionRequisitionQuantity').value;
          // if (distributionReqQty <= (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) {
          //   this.saveDisabled = true;
          //   numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
          //   totalParis = totalPackingAssortment * numberOfCartonsValue;
          //   this.calculatePairs(numberOfCartonsValue, assortment_index);
          //   control.get('numberOfCartons').setValue(numberOfCartonsValue);
          //   control.get('packageAssortments').get('pairRow').get('totalPairs').setValue(totalParis);
          //   control.get('distributionOrderQuantity').setValue(totalParis);
          //   //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          // }
          // else {
          //   this.responseMessage = 'Ordered Quantity is greater than 20% of Requisition Quantity, please correct it';
          //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          // }
        }
    }
 
    if (WHOLE_SALE_CODE === control.get('assortmentTypes').value) {
      let totalPackingAssortment: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
      if (totalPackingAssortment > 0) {
        this.displayDefaultValPair = false;
        let numberOfCartonsValue: number;
        let totalParis: number;
        distributionReqQty = control.get('distributionRequisitionQuantity').value;

        /// Distribution location line level validation - distribution order quantity should be with in +-20% of distribution req quantity(for Req)/ requested order quantity(for PO) otherwise throw validation error
          numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
        totalParis = totalPackingAssortment * numberOfCartonsValue;
        this.calculatePairsSolid(numberOfCartonsValue, assortment_index);
        control.get('numberOfCartons').setValue(numberOfCartonsValue);
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalParis);
        control.get('distributionOrderQuantity').setValue(totalParis);

        if((totalParis <= (1.2 * distributionReqQty)) && (totalParis >= (0.8 * distributionReqQty))){
          this.saveDisabled = true;   
          this.responseMessage = '';       
        } else {
          this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }
        // if (distributionReqQty <= (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) {
        //   this.saveDisabled = true;
        //   numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
        //   totalParis = totalPackingAssortment * numberOfCartonsValue;
        //   this.calculatePairsSolid(numberOfCartonsValue, assortment_index);
        //   control.get('numberOfCartons').setValue(numberOfCartonsValue);
        //   control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalParis);
        //   control.get('distributionOrderQuantity').setValue(totalParis);
        //   //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        // }
        // else {
        //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        // }
      }
    }

    if (control.get('assortmentTypes').value === SOLID_CODE) {
      control.get('numberOfCartons').setValue('');
      control.get('distributionOrderQuantity').setValue(0);
      if((control.get('distributionRequisitionQuantity').value != control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').value) && (control.get('assortmentCode').value == ''|| control.get('assortmentCode').value == null)){
        this.responseMessage = 'Total Solid Pairs should be equal to Distribution Req Quantity for line '+ (assortment_index + 1);
      }      
    }
 
    this.disableSave();
    // if (distributionReqQty > (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Requisition Quantity is greater than 20% of Requisition Quantity, please correct it';
    //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    // }
    // else {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
    //   this.distributionSaveButtonForm.get('saveDistributionButton').enable();
    // }
    this.calculateBalance();
    this.checkRequisitionDistributionGreaterThanRequisitionOrder();
  }

  calculateBalance(){
    let count: number = 0;
    let distributionReqQuantity: number = 0;
    this.distReqQuantity = 0;
    let control = this.distributionDetailsForm.get('companies');
    control.value.forEach((k: any) => {
      distributionReqQuantity = Number(this.distributionDetailsForm.get(['companies', count]).get('distributionRequisitionQuantity').value);
      this.distReqQuantity += distributionReqQuantity;
      count++;
    });
  }

  private checkRequisitionDistributionGreaterThanRequisitionOrder() {
    let requisitionOrderQty = Number(this.searchDisrtibutionDetails.reqQty);
    
    if (Number(this.distReqQuantity > requisitionOrderQty)) {
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Requisition Quantity is greater than Requisition Quantity.';
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
    }
    else {
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      console.log('checkRequisitionDistributionGreaterThanRequisitionOrder');
      if(this.disableCalculate == 0){
        this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
      }     
    }
    
  }
 
  deletePopAssortment(index)
  {
      this.ref.nativeElement.value = index;
  }
 
  deleteAssortment(index) { 
    let control1 = <FormArray>this.distributionDetailsForm.controls.companies;
    let control = this.distributionDetailsForm.get(['companies', index]);
    var reqDistributionStat = control.get('reqDistributionStat').value;
    this.loaDer.nativeElement.style.display = 'block'; 
    this.blurcss.nativeElement.style.filter = 'blur(20px)';
    var dataset:any = [];
    if(control.get('reqDistributionId').value != ''){ 
        control.get('reqDistributionStatus').setValue('I');
   
        let sizeControlCount = this.sizepackingassortment[0].length;
        let requisitionAssortments= [];
 
            let packingAssortmentControlName: string;
            let assortments_index: number = this.selectedPopupIndex;
            let packingAssortment: number;
            let pairControlName: string;
            let reqControlName: string;
            let pair: number;
            let reqpair: number;
            let hasDuplicate: boolean;
            let pairPerCarton: string;
            let sessUsername = sessionStorage.getItem('currentUser');
 
        for (let size = 1; size <= sizeControlCount; size++) {
            let selectedAssortmentTypeId: string = control.get('assortmentTypes').value;
            reqControlName = 'reqAssortmentId'+size;
            reqpair = control.get('packageAssortments').get('reqassortmtid').get(reqControlName).value;
 
            if (selectedAssortmentTypeId != null && ASSORTED_CODE === selectedAssortmentTypeId) {
              packingAssortmentControlName = 'packingAssortment' + size;
              packingAssortment = control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).value;
              pairControlName = 'pair' + size;
              pair = control.get('packageAssortments').get('pairRow').get(pairControlName).value;
              pairPerCarton = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;
              console.log(this.distributionDetailsForm.controls.companies.value);
            }
            if (selectedAssortmentTypeId != null && (SOLID_CODE === selectedAssortmentTypeId || WHOLE_SALE_CODE === selectedAssortmentTypeId)) {
              packingAssortmentControlName = 'solid_packingAssortment' + size;
              packingAssortment = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).value;
              pairControlName = 'solid_pair' + size;
              pair = control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).value;
              pairPerCarton = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
            }
 
          if (packingAssortment !== 0) {
            let requisitionAssortment = new RequisitionAssortment();
            requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket
            requisitionAssortment.pairQty = packingAssortment;
            requisitionAssortment.totalPairQty = pair;
            requisitionAssortment.status = 'I';
            requisitionAssortment.createdBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
            if (reqpair !== 0) {
              requisitionAssortment.ReqAssortmentId = reqpair;
            }
            requisitionAssortments.push(requisitionAssortment);
          }   
        }
 
        if(control.get('reqDistributionId').value != '')
        {
            var newarrexitSet = [];
            this.totresps[index].requisitionAssortment.forEach(s => {
                let afterStatusAddition = {
                  ReqAssortmentId: s.ReqAssortmentId,
                  articleSize: s.articleSize,
                  pairQty: s.pairQty,
                  totalPairQty: s.totalPairQty,
                  status: 'I',
                  createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName
                }

                newarrexitSet.push(afterStatusAddition);

            });

            var resultArray = newarrexitSet.map(
              s => requisitionAssortments.find(
                t => t.articleSize == s.articleSize) || s
            ).concat( 
              requisitionAssortments.filter(
                s => !newarrexitSet.find(t => t.articleSize == s.articleSize)
              ) 
            );
          } else
          {
            var resultArray = requisitionAssortments;
          }
 
            var neworderQty:number = this.orderQty.nativeElement.value;
            var distorderqty:number = control.get('distributionOrderQuantity').value;
 
             dataset.push({ 
                reqDistributionId: control.get('reqDistributionId').value,
                assortmentCode: control.get('assortmentCode').value,
                rdcCode: control.get('locationArray').value.split('~')[1],
                rdcName: control.get('locationArray').value.split('~')[0],
                distributionReqQuantity: control.get('distributionRequisitionQuantity').value,
                totalArtQuantity: control.get('distributionOrderQuantity').value,
                assortmentType: control.get('assortmentTypes').value,
                cartonQty: control.get('numberOfCartons').value,
                status: control.get('reqDistributionStatus').value,
                createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
                pairPerCarton: pairPerCarton,
                requisitionAssortments: resultArray
              });
 
              var newdatset = {
                reqLineId: this.searchDisrtibutionDetails.reqLineId,
                orderedQuantity: neworderQty - distorderqty,
                requisitionDistributions: dataset
              };
 
              this._distributionDetailService.saveDistribution(newdatset)
                .subscribe(
                  (response: ResponseMessage) => {
                    if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
                      this.responseMessage = 'Distribution Deleted successfully';
                    }
                  }
                );
 
          this.orderQty.nativeElement.value =  neworderQty - distorderqty;
          this.balanceorderQty.nativeElement.value =  this.reqorderQty.nativeElement.value - this.orderQty.nativeElement.value;
          this.distributionDetailsForm = this.fb.group({
            companies: this.fb.array([])
          });

          this.setCompanies();    
          this.blurcss.nativeElement.style.filter = '';  
          this.loaDer.nativeElement.style.display = 'none'; 
    } else 
    {
        control1.removeAt(index);
        this.selectedPopupIndex = index - 1;
        this.blurcss.nativeElement.style.filter = '';
        this.loaDer.nativeElement.style.display = 'none'; 
    }     
    this.sumOfDistributionReqQuantity();
    this.closeBtn.nativeElement.click();
  }
 
  onClickBack() {
    this._router.navigate(['/requisition-details']);
  }
 
  onChangeAssortmentType(event: any, assortment_index) {
    this.errorMessageBlank();  
    let targetId = 'assortmentTypes'+assortment_index;
    this.selectedAssortmentType = event;
    let targetLable = 'assortmentTypes';
    this.updateAssortmentIndex(targetLable, targetId);
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    control.get('assortmentCheckBox').disable();
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      let j = i + 1
      if (this.selectedAssortmentType == WHOLE_SALE_CODE) {
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).enable();
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Validate');
      } else {
        control.get('solid_packageAssortments').get('solid_pairRow').get('solid_actionPairs').setValue('Calculate');
        control.get('assortmentCheckBox').enable();
        control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).disable();
      }
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).disable();
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').disable();
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
    }
  } 

  showButton(){
    let sessUsername = sessionStorage.getItem('currentUser');
    let userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    let coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    var iof=coCode.indexOf(this.searchDisrtibutionDetails.conceptCode);
    let userRoleIndex = userRole.indexOf('Ordering') || userRole.indexOf('IT');
    let userRoleIndexOrdering = userRole.indexOf('Ordering');
    let userRoleIndexIT = userRole.indexOf('IT');
    if ((userRoleIndexOrdering>=0 || userRoleIndexIT>=0) && iof>=0 && this.enableSave == 'Y') {        
      this.ShowSave = 1;
    }
    else{
      this.ShowSave = 0;
    }
  }

  showCalculateButton(){
    if(this.enableSave == "Y"){
      this.showCalculate = 1;
    }
    else{
      this.showCalculate = 0;
    }
  }
 
  submitRegDistributionCompleted(dataValue:any)
  {
    let val = this.sumOfDistributionReqQuantity();
    if(val == 1){
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      this.errorMessageBlank();  
      let totalOrderQuantity = this.searchDisrtibutionDetails.orderedQty;
      let requisitionQuantity = this.searchDisrtibutionDetails.reqQty;
      this.loaDer.nativeElement.style.display = 'block';

      if (Number(totalOrderQuantity) > (parseInt(requisitionQuantity) * 1.2)) {
        //this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Ordered Quantity is greater than 20% of Requisition Quantity, please correct it';
        this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Ordered Quantity is greater than 20% of Requisition Quantity, please correct it';
        return;
      }
      // else if (Number(this.distReqQuantity) > Number(requisitionQuantity)) {
      //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Sum of Distribution Requisition Quantity is greater than Requisition Quantity.';
      //   return;
      // }
      else {
      this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
      var dataset = [];
      let packingAssortmentControlName: string;
      let assortments_index: number = this.selectedPopupIndex;
      let packingAssortment: number;
      let pairControlName: string;
      let reqControlName: string;
      let pair: number;
      let reqpair: number;
      let hasDuplicate: boolean;
      let pairPerCarton: string;
      let sessUsername = sessionStorage.getItem('currentUser');
  
      var j = 0; 
      dataValue.companies.forEach(k => {  
        let sizeControlCount = this.sizepackingassortment[0].length;
        let requisitionAssortments= [];
        let control = this.distributionDetailsForm.get(['companies', j]);
  
        for (let size = 1; size <= sizeControlCount; size++) {
            let selectedAssortmentTypeId: string = control.get('assortmentTypes').value;
            let selectedAssortmentCode: string = control.get('assortmentCode').value;
            reqControlName = 'reqAssortmentId'+size;
            reqpair = control.get('packageAssortments').get('reqassortmtid').get(reqControlName).value; 
            if (selectedAssortmentTypeId != null && ASSORTED_CODE === selectedAssortmentTypeId) {
              packingAssortmentControlName = 'packingAssortment' + size;
              packingAssortment = control.get('packageAssortments').get('packingAssortmentRow').get(packingAssortmentControlName).value;
              pairControlName = 'pair' + size;
              pair = control.get('packageAssortments').get('pairRow').get(pairControlName).value;
              console.log(control.value);
              pairPerCarton = control.get('packageAssortments').get('packingAssortmentRow').get('totalPackingAssortment').value;
            }
            if (selectedAssortmentTypeId != null && (SOLID_CODE === selectedAssortmentTypeId || WHOLE_SALE_CODE === selectedAssortmentTypeId)) {
              packingAssortmentControlName = 'solid_packingAssortment' + size;
              packingAssortment = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).value;
              pairControlName = 'solid_pair' + size;
              pair = control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).value;
              pairPerCarton = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
            } 
            if (Number(packingAssortment) != 0 && Number(pair) != 0 && selectedAssortmentCode != '') {
              let requisitionAssortment = new RequisitionAssortment();
              requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket;
              requisitionAssortment.pairQty = packingAssortment;
              requisitionAssortment.totalPairQty = pair;
              requisitionAssortment.status = 'A';
              requisitionAssortment.createdBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
              if (reqpair !== 0) {
                requisitionAssortment.ReqAssortmentId = reqpair;
              }
              requisitionAssortments.push(requisitionAssortment);
            }  
            if (Number(packingAssortment) == 0 && Number(pair) != 0 && selectedAssortmentCode == '') {
              let requisitionAssortment = new RequisitionAssortment();
              requisitionAssortment.articleSize = this.sizepackingassortment[0][size - 1].sizeBucket;
              requisitionAssortment.pairQty = packingAssortment;
              requisitionAssortment.totalPairQty = pair;
              requisitionAssortment.status = 'A';
              requisitionAssortment.createdBy = JSON.parse(sessUsername).groupAssignmentModels[0].userName;
              if (reqpair !== 0) {
                requisitionAssortment.ReqAssortmentId = reqpair;
              }
              requisitionAssortments.push(requisitionAssortment);
            }   
        }

        if(control.get('reqDistributionId').value != '')
        {
            var newarrexitSet = [];
            this.totresps[j].requisitionAssortment.forEach(s => {
                let afterStatusAddition = {
                  ReqAssortmentId: s.ReqAssortmentId,
                  articleSize: s.articleSize,
                  pairQty: s.pairQty,
                  totalPairQty: s.totalPairQty,
                  status: 'I',
                  createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName
                }  
                newarrexitSet.push(afterStatusAddition);
  
            });
  
            var resultArray = newarrexitSet.map(
              s => requisitionAssortments.find(
                t => t.articleSize == s.articleSize) || s
            ).concat( 
              requisitionAssortments.filter(
                s => !newarrexitSet.find(t => t.articleSize == s.articleSize)
              ) 
            );
          } else
          {
            var resultArray = requisitionAssortments;
          }
  
          if(k.reqDistributionId != ''){
              dataset.push(
                  {
                      reqDistributionId: k.reqDistributionId,
                      assortmentCode: control.get('assortmentCode').value,
                      rdcCode: k.locationArray.split('~')[1],
                      rdcName: k.locationArray.split('~')[0],
                      distributionReqQuantity: k.distributionRequisitionQuantity,
                      totalArtQuantity: control.get('distributionOrderQuantity').value,
                      assortmentType: k.assortmentTypes,
                      cartonQty: control.get('numberOfCartons').value,
                      status: control.get('reqDistributionStatus').value,
                      createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
                      pairPerCarton: pairPerCarton,
                      requisitionAssortments: resultArray
                    }
                  );
          } else
          {
            dataset.push(
              {
                  assortmentCode: control.get('assortmentCode').value,
                  rdcCode: k.locationArray.split('~')[1],
                  rdcName: k.locationArray.split('~')[0],
                  distributionReqQuantity: k.distributionRequisitionQuantity,
                  totalArtQuantity: control.get('distributionOrderQuantity').value,
                  assortmentType: k.assortmentTypes,
                  cartonQty: control.get('numberOfCartons').value,
                  status: control.get('reqDistributionStatus').value,
                  createdBy: JSON.parse(sessUsername).groupAssignmentModels[0].userName,
                  pairPerCarton: pairPerCarton,
                  requisitionAssortments: resultArray
                }
              );
          }
            j++;
        });
  
      var newdatset = {
        reqLineId: this.searchDisrtibutionDetails.reqLineId,
        orderedQuantity: this.searchDisrtibutionDetails.orderedQty,
        requisitionDistributions: dataset
      };
  
      let rdcCodeAssortmentTypeArray: any = [];
      for (let distributionLineIndex = 0; distributionLineIndex < dataset.length; distributionLineIndex++) {
        let distributionobj = dataset[distributionLineIndex];
        if(distributionobj.assortmentType != 'W'){
          rdcCodeAssortmentTypeArray.push(distributionobj.rdcCode);
        }
      }
      // rdcCodeAssortmentTypeArray.filter(
      //   (item: any, index: any) => {
      //     hasDuplicate = rdcCodeAssortmentTypeArray.indexOf(item) === index;
      //   }
      // ); 
      if (new Set(rdcCodeAssortmentTypeArray).size !== rdcCodeAssortmentTypeArray.length) {
        hasDuplicate = true;
      } else {
        hasDuplicate = false;
      }

      if (hasDuplicate && rdcCodeAssortmentTypeArray.length>0) {
        this.hasDuplicateDistibutionErrMsg = 'Please choose different location.'
      }
      else {
        this.hasDuplicateDistibutionErrMsg = '';
        this._distributionDetailService.saveDistribution(newdatset)
          .subscribe(
            (response: ResponseMessage) => {
              if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
                this.responseMessage = 'Distribution Details saved successfully';
                this._router.navigate(['/requisition-details']);
              }
            }
          );
        } 
      }

      this.loaDer.nativeElement.style.display = 'none';
    }
  } 
 
  sumOfDistributionReqQuantity() {  
    this.errorMessageBlank();  
    let calSuccess = 0;
    let inputboxValidation = 0;
    let count: number = 0;
    let count1: number = 0;
    let distributionOrderQuantity: number = 0;
    let requestedOrderQuantity: number = 0;
    this.orderedQuantity = 0;
    let control = this.distributionDetailsForm.get('companies');
    let control1 = this.distributionDetailsForm.get('companies');
    
    console.log(control1);
      console.log(control.value);
      console.log(control.value.length);
    this.sizeWisePndgArray=[];
    this.sizeWiseQtyArray.forEach(
      dta => {
        console.log(dta)
         this.sizeWisePndgArray.push(dta);
      });
      if(control.value.length>0){
      let selectAssortmentType = this.distributionDetailsForm.get(['companies', count]).get('assortmentTypes').value;
    if (selectAssortmentType == ASSORTED_CODE) {
      // this.sizeWisePndgArray=[];
      control.value.forEach(
        data => {
          console.log(data);
          console.log(data.packageAssortments.pairRow['pair0']);
          
          for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
            var j = i + 1;
            // this.sizeWisePndgArray[i]=this.sizeWisePndgArray[i]-((data.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value));
            console.log(data.packageAssortments.pairRow['pair'+j]);
            console.log(this.sizeWisePndgArray);
            console.log(this.sizeWisePndgArray[i]);
            if(this.sizeWiseQtyArray[i]==0)
            {
              this.sizeWisePndgArray[i]=0;
            }else{
              this.sizeWisePndgArray[i]=parseInt((this.sizeWisePndgArray[i]!=undefined)?this.sizeWisePndgArray[i]:0)-parseInt(data.packageAssortments.pairRow['pair'+j]);
            }
            
          }
        });
    }else{
      // this.sizeWisePndgArray=[];
        control.value.forEach(
          data => {
            console.log(data);
            console.log(data.solid_packageAssortments.solid_pairRow['solid_pair0']);
            
            for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
              var j = i + 1;
              // this.sizeWisePndgArray[i]=this.sizeWisePndgArray[i]-((data.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment' + j).value));
              console.log(data.solid_packageAssortments.solid_pairRow['solid_pair'+j]);
              console.log(this.sizeWisePndgArray);
              console.log(this.sizeWisePndgArray[i]);
              if(this.sizeWiseQtyArray[i]==0)
            {
              this.sizeWisePndgArray[i]=0;
            }else{
              this.sizeWisePndgArray[i]=parseInt((this.sizeWisePndgArray[i]!=undefined)?this.sizeWisePndgArray[i]:0)-parseInt(data.solid_packageAssortments.solid_pairRow['solid_pair'+j]);
            }
            }
          });
    }
  }
    control.value.forEach((k: any) => {
      distributionOrderQuantity = Number(this.distributionDetailsForm.get(['companies', count]).get('distributionOrderQuantity').value);
      this.orderedQuantity += distributionOrderQuantity;      
      count++;
    });
    this.searchDisrtibutionDetails.orderedQty = this.orderedQuantity.toString();
    control1.value.forEach(k => {
      distributionOrderQuantity = this.distributionDetailsForm.get(['companies', count1]).get('distributionOrderQuantity').value; 
      requestedOrderQuantity =  this.distributionDetailsForm.get(['companies', count1]).get('distributionRequisitionQuantity').value;  
      let pairPerCartonRatio = this.distributionDetailsForm.get(['companies', count1]).get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value; 
      let assortmentTypes =  this.distributionDetailsForm.get(['companies', count1]).get('assortmentTypes').value; 
      let assortmentCode =  this.distributionDetailsForm.get(['companies', count1]).get('assortmentCode').value;
      let locationValue =  this.distributionDetailsForm.get(['companies', count1]).get('locationArray').value;

      let control2 = this.distributionDetailsForm.get(['companies', count1]);
      let anyPairDivisible: boolean[] = [];
      let checkTotSolidPair = 0;
      for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
        let j = i + 1;
        checkTotSolidPair += Number(control2.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value);
        let pair: number = control2.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value;
        let divisible: boolean = pair % pairPerCartonRatio === 0 ? true : false;
        anyPairDivisible.push(divisible);
      }
      
      let flag = 0;
      for (let i = 0; i < anyPairDivisible.length; i++) {
        if (anyPairDivisible[i]==false) {
          ++flag;
        }
      }

      if (distributionOrderQuantity == 0) {
        this.distributionOrderQuantityEmptyErrMsg = 'Distribution req qty and Total pairs are different at line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if (requestedOrderQuantity == 0) {
        this.distributionRequsitionQuantityEmptyErrMsg = 'Requested Order Quantity is empty at  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if (locationValue == null || locationValue == "") {
        this.distributionRequsitionQuantityEmptyErrMsg = 'Location is empty at  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if( pairPerCartonRatio == 0 && assortmentTypes == SOLID_CODE){
        this.pairPerCartonRatioEmptyErrMsg = 'Pair per carton is empty at line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      }else if(checkTotSolidPair != requestedOrderQuantity  && assortmentTypes == SOLID_CODE && (assortmentCode == ''|| assortmentCode == null)){
        this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution requisition qty for  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      }else if(assortmentTypes == SOLID_CODE && ((checkTotSolidPair > (1.2 * requestedOrderQuantity)) || (checkTotSolidPair < (0.8 * requestedOrderQuantity)))){
        this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
       }else if(checkTotSolidPair != distributionOrderQuantity  && assortmentTypes == SOLID_CODE && assortmentCode != ''){
        this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Total Solid Pairs should be equal to distribution order qty for  line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else if (flag > 0 && assortmentCode != null && assortmentCode != '' && assortmentTypes == SOLID_CODE) {
        this.sizeWisePairErrMssg = 'Size wise pair must be divisible by pair per cartons for line ' + (count1 + 1);
        inputboxValidation++;
        this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        return false;
      } else {
        //  this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        this.errorMessageBlank();  
      }
      count1++;
    }); 

    console.log('After validation');
    // if (((Number(Number(this.searchDisrtibutionDetails.reqQty) * 1.2) <= Number(this.searchDisrtibutionDetails.orderedQty)) || Number(this.searchDisrtibutionDetails.orderedQty) != 0) && inputboxValidation == 0  && (this.pairFlag.length == 0 && this.pairFlag1.length == 0)){
    //}
    if (((Number(Number(this.searchDisrtibutionDetails.reqQty) * 1.2) <= Number(this.searchDisrtibutionDetails.orderedQty)) || Number(this.searchDisrtibutionDetails.orderedQty) != 0) && inputboxValidation == 0){
      this.distributionSaveButtonForm.get('saveDistributionButton').enable(); 
      this.responseMessage = '';
      this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
      this.pairDistOrdErerrMssg = '';
      calSuccess = 1;
    }else{
      // if(this.pairFlag.length > 0){
      //   for(let i = 0; i < this.pairFlag.length; i++){
      //     this.responseMessage += '<br> Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity, please correct it at  line ' + (this.pairFlag[i]);
      //   }
      // }     
      // if(this.pairFlag1.length > 0){
      //   for(let j = 0; j < this.pairFlag1.length; j++){
      //     this.pairDistOrdErerrMssg += '<br> Total Solid Pairs should be equal to distribution requisition qty, please correct it at  line ' + (this.pairFlag1[j]);
      //   }
      // }  
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      //this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
      calSuccess = 0;
    }
    return calSuccess;
  }
 
  updateAssortmentIndex(targetLable: string, targetId: string) {
    this.selectedPopupIndex = +(targetId.substring(targetLable.length, (targetId.length)));
  }
 
  onClickEnableButton(assortment_index: any) { ////////
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    console.log(control.value);
    if (control.get('assortmentTypes').value == SOLID_CODE) {
      control.get('numberOfCartons').disable();
      let assortmentCode = control.get('assortmentCode').value;
      let isAssortmentCodeEmpty = (assortmentCode == 'null' || assortmentCode == '' || assortmentCode === undefined)?true:false; 
      console.log(control.value);
      for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
        var j = i + 1;
        let pairControlName = 'solid_pair' + j;
        console.log(control.value);
        control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).enable();
        // control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).disable();
        let packingAssortmentControlName = 'solid_packingAssortment' + j;
        console.log(control.value);
        if (isAssortmentCodeEmpty) {
          console.log(control.value);
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(packingAssortmentControlName).setValue('');
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').enable();          
        } else {
          console.log(control.value);
          control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').disable();
        }
      }
      console.log(control.value);
      if (isAssortmentCodeEmpty) {
        control.get('assortmentCode').setValue('');
        control.get('assortmentCode').disable();
        console.log(control.value);
      }
    }
 
  } 
 
  calculatePairsSolid(numberOfCartonsValue: number, assortment_index: any) {
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    var totalPairs = 0;
    for(let j = 1; j <= this.sizepackingassortment[0].length; j++)
    {
      control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+j).setValue((control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+j).value) * numberOfCartonsValue);
      totalPairs += Number( control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair'+j).value)
    }
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalPairs);
    control.get('distributionOrderQuantity').setValue(totalPairs);
  } 
 
  calculateTotalPackingAssortmentsSolid(assortment_index: any) {
    // let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    var solid_totalPackAssortments = 0;
      for(let j = 1; j < this.sizepackingassortment[0].length; j++)
      {
        solid_totalPackAssortments += Number(control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_packingAssortment'+j).value)
      }
      control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(solid_totalPackAssortments);
 
      return solid_totalPackAssortments;
  }
 
  calculate(assortment_index: any) {   
    this.errorMessageBlank();   
    this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
    // let control = this.getAssortmentFormControl();
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    console.log(control.value);
    let assortmentCode: string = control.get('assortmentCode').value; 
    let assortmentType: string = control.get('assortmentTypes').value;
    let distributionReqQty = control.get('distributionRequisitionQuantity').value;
    if(assortmentType != 'W' && (assortmentCode == ''|| assortmentCode == null )){
      this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Cannot calculate without Assortment Code';      
      setTimeout(()=>{
        this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
      }, 3000);
      return false;      
    }else{ 
      if ('W' === assortmentType) {
        this.loaDer.nativeElement.style.display = 'block';
        this.loadingSpinnerMsg='Please wait assortment code is populating.'
        control.get('assortmentCheckBox').disable();
  
        let assortmentType = control.get('assortmentTypes').value;
        let groupCode = this.groupCodeArray[0];
        let paTotal: number = 0;
        let pairs = {};
        let numberOfCartons: number = control.get('numberOfCartons').value;
  
        for (var j = 0; j < this.sizepackingassortment[0].length; j++) {
          let k = j + 1;
          let paControlName = 'solid_packingAssortment' + k;
          let pairControlName = 'solid_pair' + k;
          control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).enable();
          let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
          //if(paValue!=0){
          control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(numberOfCartons * paValue);
          paTotal += Number(paValue);
          // if (paValue != null && paValue != 0) {
          paValue = Number(paValue);
          pairs['pair' + this.sizepackingassortment[0][j].sizeBucket] = paValue;
          // }else{
          // pairs['pair' + this.sizepackingassortment[0][j].sizeBucket] = 0;

          // }
        }
        if (paTotal != null && paTotal!=0) {
          //paTotal = Number(paTotal);
          this.isPrime =  this.checkPrime(paTotal);
          if (this.isPrime) {
            this.loaDer.nativeElement.style.display = 'none';
            this.primeErrMssg = 'Pair per cartoon should not be a PRIME number for line ' + (assortment_index + 1);
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          } else {
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
            let sessUsername = sessionStorage.getItem('currentUser');
            let userEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
            let assortment_code_param = {
              "groupCode": groupCode,
              "assortmentType": assortmentType,
              "pairs": pairs,
              "createdBy" : userEmail
            };
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
            this._distributionDetailService.getAssortmenstsValidate(assortment_code_param).subscribe(
              (response: DynamicPackingMaster) => {
                if (response != null) {
                  let assortmentCode = response.dataList[0].assortmentCode;
                  control.get('assortmentCode').setValue(assortmentCode);
                  this.calculateOrderQuantity(assortment_index);
                  this.loaDer.nativeElement.style.display = 'none';
                  this.loadingSpinnerMsg='';
                }
              }
            );
            this.primeErrMssg = '';
            //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
            this.isTotalPairDistributionOrderQtyEqual = true; 
            this.isPairDivisible = true; 
            this.isPrime = false;
          }
        } else {
          this.loaDer.nativeElement.style.display = 'none';
          this.primeErrMssg = 'Pair per cartoon should not be 0 for line ' + (assortment_index + 1);
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        }  
      }   
      if ('S' === assortmentType) {
        console.log(control.value);
        let distributionReqQty: number = control.get('distributionRequisitionQuantity').value;
        let totalPackingAssortment: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
        let isDivisible = distributionReqQty != 0 && (distributionReqQty % totalPackingAssortment === 0) ? true : false;  
        if (assortmentCode != 'null' && assortmentCode !== '') {
          // this.calculateNumberOfCartons(assortment_index);
          // this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          // this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
          // //Reset
          // this.isTotalPairDistributionOrderQtyEqual = true; this.isPairDivisible = true; this.isPrime = false;
          // if(isDivisible){
          //   if (totalPackingAssortment > 0) {
          //     this.displayDefaultValPair = false; //used in htlm
          //     let numberOfCartonsValue: number;
          //     let totalParis: number;
    
          //     if (distributionReqQty <= (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) {
          //       this.saveDisabled = true;
          //       numberOfCartonsValue = Math.round(distributionReqQty / totalPackingAssortment);
          //       totalParis = totalPackingAssortment * numberOfCartonsValue;
          //       control.get('numberOfCartons').setValue(numberOfCartonsValue);
          //       this.calculatePairsSolid(numberOfCartonsValue, assortment_index);
          //       control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(totalParis);
          //       control.get('distributionOrderQuantity').setValue(totalParis);
          //       //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          //     }
          //   }
          // } else {            
            let paTotal = 0;
            let distReqQty: number = control.get('distributionRequisitionQuantity').value;
            let orderReqQty: number = 0;
            let numberOfCartons: number = 0;
            let assortmentSum: number = 0;//newly added TEST
            for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
              let sizeIndex = i + 1;
              let paControlName = 'solid_packingAssortment' + sizeIndex;
              let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
              console.log(paValue);
              if (!isNaN(paValue)) {
                paTotal += Number(paValue);
              }
            }
            control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
            let a: number = 0;
            let b: number = 0;
            
            for (let j = 0; j < this.sizepackingassortment[0].length; j++) {
              let sizeIndex = j + 1;
              let paControlName = 'solid_packingAssortment' + sizeIndex;
              let pairControlName = 'solid_pair' + sizeIndex;
              control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).enable();
              let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
              // let x = (distReqQty / paTotal) * paValue;
              // x = Math.ceil(x);       
              // let y = x / paTotal; 
              // let y_ceil = Math.ceil(y);
              // let y_floor = Math.floor(y);
              // a = Number(paTotal * y_ceil);
              // b = Number(paTotal * y_floor);
              // if (a-x >= x-b){  
              // orderReqQty += b;
              // control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              // }
              // else if (a-x < x-b){ 
              // orderReqQty += a;
              // control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
              // }
              ////start newly added TEST
			        let mf = Math.round((distReqQty / paTotal));
			        let asst = mf * paValue;
			        assortmentSum = assortmentSum+asst;
			        let y = asst / paTotal;			  
              let y_ceil = Math.ceil(y);
              let y_floor = Math.floor(y);
              a = Number(paTotal * y_ceil);
              b = Number(paTotal * y_floor);
              console.log('mf'+mf);
              console.log('asst'+asst);
              console.log('assortmentSum'+assortmentSum);
              console.log('a'+a);
              console.log('b'+b);
              if((assortmentSum > (orderReqQty+b)) && (assortmentSum > (orderReqQty+a))){
                console.log("inside 1st loop");
                if((assortmentSum-(orderReqQty+b)) > (assortmentSum-(orderReqQty+a))){
                  orderReqQty += a;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
                }else{
                  orderReqQty += b;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
                }                
              }else if((assortmentSum > (orderReqQty+b)) && (assortmentSum < (orderReqQty+a))){
                  orderReqQty += b;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              }else if(assortmentSum == (orderReqQty+b)){
                  orderReqQty += b;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              }else if(assortmentSum == (orderReqQty+a)){
                  orderReqQty += a;
                  control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
              }

              let solidPairsRow = control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).value;
              console.log("solid_pairRow"+j+"-->"+solidPairsRow);
              console.log('orderReqQty'+orderReqQty);
              // control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(solidPairsRow);
              // control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).disable();
              console.log(control.value);

            }
            numberOfCartons = orderReqQty / paTotal; 
            control.get('numberOfCartons').setValue(numberOfCartons);
            control.get('distributionOrderQuantity').setValue(orderReqQty);
            control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(orderReqQty);  
            if((orderReqQty <= (1.2 * distReqQty)) && (orderReqQty >= (0.8 * distReqQty))){
              this.responseMessage = '';   
            } else {
              this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
              this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            }           
          //}          
          //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
          this.isTotalPairDistributionOrderQtyEqual = true; 
          this.isPairDivisible = true; 
          this.isPrime = false;
          if (assortmentCode != null && assortmentCode !== '') {
            this.checkRequisitionDistributionGreaterThanRequisitionOrder();
          }
        }        
        // this.checkRequisitionDistributionGreaterThanRequisitionOrder();
      }
    }    
  }

  private calculateNumberOfCartons(assortment_index: any) {
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let distReqQty: number = control.get('distributionRequisitionQuantity').value;
    console.log(control);
    // calculate total ration packing assortment tpa
    let paTotal: number = 0;
	let assortmentSum: number = 0;
    for (let i = 0; i <  this.sizepackingassortment[0].length; i++) {
      let sizeIndex = i + 1;
      let paControlName = 'solid_packingAssortment' + sizeIndex;
      let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
          // control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).enable();
      if (!isNaN(paValue)) {
        paTotal += Number(paValue);
      }
    }
    control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').setValue(paTotal);
    let nearestPairVaule = 0;
    let sumOfPairs = 0;
	let a: number = 0;
    let b: number = 0; 
    for (let j = 0; j <  this.sizepackingassortment[0].length; j++) {
      //pick each pa ratio
      let i = j + 1;
      let paControlName = 'solid_packingAssortment' + i;
      let pairControlName = 'solid_pair' + i;
      let paValue: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get(paControlName).value;
      // get ratio              
      /*let x = (distReqQty / paTotal) * paValue;
      x = Math.ceil(x);
      let y = x / paTotal;
      let y_ceil = Math.ceil(y);
      let y_floor = Math.floor(y);
      let pairCeil = y_ceil * paTotal;
      let pairFloor = y_floor * paTotal;
      console.log('x ' + x);
      console.log('y ' + y);
      console.log('y_ceil ' + y_ceil);
      console.log('y_floor ' + y_floor);
      console.log('pairCeil ' + pairCeil);
      console.log('pairFloor ' + pairFloor);
      if (pairCeil - x >= x - pairFloor) {
        nearestPairVaule = pairFloor;
      }
      else if (pairCeil - x < x - pairFloor) {
        nearestPairVaule = pairCeil;
      }
      console.log('nearestPairVaule ' + nearestPairVaule);
      control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(nearestPairVaule);
      sumOfPairs += Number(nearestPairVaule);*/
	  
	  let mf = Math.round((distReqQty / paTotal))
						  let asst = mf * paValue;
			        assortmentSum = assortmentSum+asst;
				      let y = asst / paTotal;			  
              let y_ceil = Math.ceil(y);
              let y_floor = Math.floor(y);
              a = Number(paTotal * y_ceil);
              b = Number(paTotal * y_floor);
             
			  console.log(a+" ------ "+b);
        console.log(pairControlName);
					if((assortmentSum > (sumOfPairs+b)) && (assortmentSum > (sumOfPairs+a))){
						if((assortmentSum-(sumOfPairs+b)) > (assortmentSum-(sumOfPairs+a))){
							sumOfPairs += a;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
              console.log('if');console.log(control);
              
						}else{
              console.log('else');console.log(control);
							sumOfPairs += b;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
						}
						
					}else if((assortmentSum > (sumOfPairs+b)) && (assortmentSum < (sumOfPairs+a))){
            sumOfPairs += b;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              console.log('if');console.log(control);
					}else if(assortmentSum == (sumOfPairs+b)){
            sumOfPairs += b;
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(b);
              console.log('Elseif');console.log(control);
					}else if(assortmentSum == (sumOfPairs+a)){
            sumOfPairs += a;
            console.log('Elseif1');console.log(control);
							control.get('solid_packageAssortments').get('solid_pairRow').get(pairControlName).setValue(a);
					}
    }
    console.log(control);
    console.log('sumOfPairs ' + sumOfPairs);
    // calculate carton 
	if((sumOfPairs <= (1.2 * distReqQty)) && (sumOfPairs >= (0.8 * distReqQty))){
   let numberOfCartons = sumOfPairs / paTotal;
    console.log('numberOfCartons ' + numberOfCartons);
    control.get('numberOfCartons').setValue(numberOfCartons);
    control.get('distributionOrderQuantity').setValue(sumOfPairs);
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(sumOfPairs);
	}else{
		this.orderQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Order Qunatity should not be 20% greater or less than Distibution Requsition Quantity.';//TODO Validation error show this
	    control.get('distributionOrderQuantity').setValue(0);
	}
  }
  solid_sizes(resultArray) {
    var size = {};
        size['solid_size0'] = 'Size';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        size['solid_size'+j] = resultArray[i].artSize;
      }
        size['solid_totalSize'] = 'Total';
        size['solid_action'] = 'Action';
 
    return this.fb.group(size);
  }
 
  solid_packingAssortmentRow(resultArray, pairPerCarton) {
    var packingAssortment = {};
    var totpackingAssortment = 0;
        packingAssortment['solid_packingAssortment0'] = 'Packing Assortment';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        packingAssortment['solid_packingAssortment'+j] = resultArray[i].pairQty;
        totpackingAssortment += resultArray[i].pairQty;
      }
        packingAssortment['solid_totalPackingAssortment'] = pairPerCarton;
        packingAssortment['solid_actionPackingAssortment'] = 'Enable';
 
     return this.fb.group(packingAssortment)
  }
 
  solid_pairRow(resultArray) {
    var pair = {};
    var totpair = 0;
        pair['solid_pair0'] = 'Pairs';
      for(let i = 0; i < resultArray.length; i++)
      {
        let j = i + 1;
        pair['solid_pair'+j] = resultArray[i].totalPairQty;
        totpair += resultArray[i].totalPairQty;
      }
        pair['solid_totalPairs'] = totpair;
        pair['solid_actionPairs'] = 'Calculate';
 
      return this.fb.group(pair)
  }  
 
  solid_packageAssortments(resultArray, pairPerCarton) {
    return this.fb.group({
      solid_sizes: this.solid_sizes(resultArray),
      solid_pairRow: this.solid_pairRow(resultArray),
      solid_packingAssortmentRow: this.solid_packingAssortmentRow(resultArray, pairPerCarton)
    });
  }
 
  resetDistributionLine(index) {
    let control1 = this.distributionDetailsForm.get(['companies', index]);
    var reqDistributionStat = control1.get('reqDistributionStat').value;
    if(control1.get('reqDistributionId').value != ''){ 
        if(reqDistributionStat == 'block'){
          control1.get('reqDistributionStat').setValue('none');
        }
    } else 
    {
        let control = <FormArray>this.distributionDetailsForm.controls.companies;
        control.removeAt(index);
        this.selectedPopupIndex = index - 1;
    } 
 
    this.addAssortment(); 
    this.errorMessageBlank();  
    this.isTotalPairDistributionOrderQtyEqual = false;    
    this.isPrime = true;
    this.isPairDivisible = false;
    this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    this.calculateBalance();
  }
 
  onClickPairInput(assortment_index:any) {
    this.errorMessageBlank();  
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    let numberOfCartons: number = control.get('numberOfCartons').value;
    let pairPerCartons: number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
    let assortmentCode = control.get('assortmentCode').value;
    let onPairDivisible: boolean[] = [];
 
    var checkTotSolidPair = 0;
    for (let i = 0; i < this.sizepackingassortment[0].length; i++) {
      let j = i + 1;
      checkTotSolidPair += Number(control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value);
      let pair: number = control.get('solid_packageAssortments').get('solid_pairRow').get('solid_pair' + j).value;
      let divisible: boolean = pair % pairPerCartons === 0 ? true : false;
      onPairDivisible.push(divisible);
    }
 
    let flag = 0;
    for (let i = 0; i < onPairDivisible.length; i++) {
      if (onPairDivisible[i]==false) {
        ++flag;
      }
    }
    //if(assortmentCode != '' || assortmentCode != 'null')
    //{
      // if(numberOfCartons != 0)
      //  {
        //Distribution order quantity can be changed after size wise pair update (which is divisible by Pair per cartoon) only for Enable of Solid WITH assortment code
        let reqOrdQty = control.get('distributionRequisitionQuantity').value;
        if((checkTotSolidPair <= (1.2 * reqOrdQty)) && (checkTotSolidPair >= (0.8 * reqOrdQty))){
          let index = this.pairFlag.indexOf(assortment_index + 1);
          if (index > -1) {
            this.pairFlag.splice(index, 1);
          }
          this.responseMessage = '';
          control.get('distributionOrderQuantity').setValue(checkTotSolidPair);
        } else {
          if(this.pairFlag.indexOf(assortment_index + 1) < 0){
            this.pairFlag.push(assortment_index + 1);
          }
          this.responseMessage = 'Distribution Order Quantity is greater/less than 20% of Distribution Req Quantity for line ' + (assortment_index + 1);
        }

        if (flag > 0) {
            if(assortmentCode != '' && assortmentCode != null){
              if(numberOfCartons != 0)
              {
                this.sizeWisePairErrMssg = 'Size wise pair must be divisible by pair per cartons.';
                console.log('disable --->'+this.showCalculate);
                this.disableCalculate = 1;
                this.distributionSaveButtonForm.get('calculateOrderQuantityButton').disable();
              } else 
              {
                this.sizeWisePairErrMssg = '';
                this.disableCalculate = 0;
                this.distributionSaveButtonForm.get('calculateOrderQuantityButton').enable();
              }
            }
            // if((checkTotSolidPair != control.get('distributionOrderQuantity').value)  && assortmentCode != '')
            // {
            //   this.isTotalPairDistributionOrderQtyEqual = false;
            //   this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution order qty for line ' + (assortment_index + 1);
            //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            //   if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
            //     this.pairFlag1.push(assortment_index + 1);
            //   }
            // } 
            if((checkTotSolidPair != control.get('distributionRequisitionQuantity').value)  && (assortmentCode == ''|| assortmentCode == null)) {
              this.isTotalPairDistributionOrderQtyEqual = false;
              this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution requisition qty for line ' + (assortment_index + 1);
              this.distributionSaveButtonForm.get('saveDistributionButton').disable();
              if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
                this.pairFlag1.push(assortment_index + 1);
              }
            } else
            {
              this.isTotalPairDistributionOrderQtyEqual = true;
              this.pairDistOrdErerrMssg = '';
              this.distributionSaveButtonForm.get('saveDistributionButton').disable();
              let index = this.pairFlag1.indexOf(assortment_index + 1);
              if (index > -1) {
                this.pairFlag1.splice(index, 1);
              }
            }
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();  
        } else {
          // if((checkTotSolidPair != control.get('distributionOrderQuantity').value)  && assortmentCode != '')
          // {
          //   this.isTotalPairDistributionOrderQtyEqual = false;
          //   this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution order qty for line ' + (assortment_index + 1);
          //   this.distributionSaveButtonForm.get('saveDistributionButton').disable();
          //   if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
          //     this.pairFlag1.push(assortment_index + 1);
          //   }
          // } 
          if((checkTotSolidPair != control.get('distributionRequisitionQuantity').value)  && (assortmentCode == ''|| assortmentCode == null))
          {
            this.isTotalPairDistributionOrderQtyEqual = false;
            this.pairDistOrdErerrMssg = 'Total Solid Pairs should be equal to distribution requisition qty for line ' + (assortment_index + 1);
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            if(this.pairFlag1.indexOf(assortment_index + 1) < 0){
              this.pairFlag1.push(assortment_index + 1);
            }
          }
          else
          {
            this.isTotalPairDistributionOrderQtyEqual = true;
            this.pairDistOrdErerrMssg = '';
            this.distributionSaveButtonForm.get('saveDistributionButton').disable();
            let index = this.pairFlag1.indexOf(assortment_index + 1);
            if (index > -1) {
              this.pairFlag1.splice(index, 1);
            }
            this.pairDistOrdErerrMssg = '';
          } 
          this.sizeWisePairErrMssg = '';
          this.disableCalculate = 0;
        }
      // } 
    //}
 
    control.get('solid_packageAssortments').get('solid_pairRow').get('solid_totalPairs').setValue(checkTotSolidPair);
 
    let distributionReqQty: number = control.get('distributionRequisitionQuantity').value;
    this.checkRequisitionDistributionGreaterThanRequisitionOrder();
    // this.isdistributionRequsitionQuantityGreaterRequisitionQuantity = (distributionReqQty > (parseInt(this.searchDisrtibutionDetails.reqQty) * 1.2)) ? true : false;
    // if (this.isdistributionRequsitionQuantityGreaterRequisitionQuantity) {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = 'Distribution Requisition Quantity is greater than 20% of Requisition Quantity, please correct it.';
    // }
    // else {
    //   this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
    // }

    let total_pair:number = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
    if(total_pair > 0){
      if(assortmentCode == '' || assortmentCode == null){
        let cartoon = Math.ceil(checkTotSolidPair/total_pair);
        control.get('numberOfCartons').setValue(cartoon);
      }
      //this.sizeWisePairErrMssg = '';
    } else {
      this.sizeWisePairErrMssg = 'Total pair should not be blank';
    }
 
    if (checkTotSolidPair == control.get('distributionRequisitionQuantity').value) {
      control.get('distributionOrderQuantity').setValue(checkTotSolidPair);
    }
 
  } 
 
  disableSave() {
    let control = this.getAssortmentFormControl();
    let numberOfDistributionLines = (<FormArray>this.distributionDetailsForm.controls.companies).length;
    if (numberOfDistributionLines == 1 && this.selectedAssortmentType == ASSORTED_CODE && control.get('assortmentCode').value != null ) {
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    }
    else {
      this.distributionSaveButtonForm.get('saveDistributionButton').disable();
    }
  } 
 
  validateTotalPackingAssotmentIfPrime(assortment_index: any) {
    this.errorMessageBlank();  
    let control = this.distributionDetailsForm.get(['companies', assortment_index]);
    if (SOLID_CODE == control.get('assortmentTypes').value) {
      let paTotal = control.get('solid_packageAssortments').get('solid_packingAssortmentRow').get('solid_totalPackingAssortment').value;
      // if (paTotal != null) {
      //   for (let k = 2; k <= Number(paTotal / 2); k++) {
      //     if (paTotal % k == 0) {
      //       this.primeErrMssg = '';
      //       this.isPrime = false;
      //       this.distributionSaveButtonForm.get('saveDistributionButton').enable();
      //     } else {
      //       this.primeErrMssg = 'Pair per cartoon should not be a PRIME number, please correct it.';
      //       this.isPrime = true;
      //       this.distributionSaveButtonForm.get('saveDistributionButton').disable();
      //     }
      //   }
      // } 
      if (paTotal != null && paTotal!=0) {
        //paTotal = Number(paTotal);
        this.isPrime =  this.checkPrime(paTotal);
        if (this.isPrime) {
          this.primeErrMssg = 'Pair per cartoon should not be a PRIME number for line ' + (assortment_index + 1);
          this.distributionSaveButtonForm.get('saveDistributionButton').disable();
        } else {
          this.onClickPairInput(assortment_index);
          this.primeErrMssg = '';
          //this.distributionSaveButtonForm.get('saveDistributionButton').enable();
        }
      }
 
    }
  }

  checkPrime(n: any) {
    if (n == 1) {
      return false;
    }
    else if (n == 2) {
      return true;
    } else {
      for (var x = 2; x < n; x++) {
        if (n % x == 0) {
          return false;
        }
      }
      return true;
    }
  }

  errorMessageBlank(){
    this.responseMessage = '';
    this.primeErrMssg = '';
    this.sizeWisePairErrMssg = '';
    this.pairDistOrdErerrMssg = '';
    this.hasDuplicateDistibutionErrMsg = '';
    this.orderQuantityGreaterRequisitionQuantityErrMsg = '';
    this.distributionRequsitionQuantityGreaterRequisitionQuantityErrMsg = '';
    this.distributionRequsitionQuantityEmptyErrMsg = '';
    this.distributionOrderQuantityEmptyErrMsg = '';
    this.pairPerCartonRatioEmptyErrMsg = '';
  }

 
}
