import { Injectable } from '@angular/core';
import { BilApiUrl } from '../common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ManageSupplierAccessCreateService {
  party_url: string = BilApiUrl.SUPPLIER_PARTY_NUMBER;
  supplier_detail_url: string = BilApiUrl.USER_DETAIL_URl;
  saving_create_supplier_url: string = BilApiUrl.CREATE_SUPPLIER_ACCESS;

  constructor(private _http: HttpClient) { }

  getPartyNumber() {
    return this._http.get(this.party_url);
  }

  getSupplierDetails(details) {
    console.log('passing data' + JSON.stringify(details));
    return this._http.post(this.supplier_detail_url, details);
  }

  saveSupplierDetails(userConceptsPassingList) {
    console.log('passing data' + JSON.stringify(userConceptsPassingList));
    return this._http.post(this.saving_create_supplier_url, userConceptsPassingList);
  }

}
