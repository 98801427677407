export class ImportArticleprice{
    art_no:string;
    art_name: string;
    art_type: string;
    art_mcatg_key: string;
    art_scatg_key: string;
    art_wsl_price_code: string;
    art_wsl_price_mrp: string;
    art_price_mrp: string;
    art_price_wsl: string;
    art_price_factory: string;
    art_price_rtl:string;
    art_price_prev: string;
    art_sequence_no: string;
    art_state_code: string;
    art_tax_code:string;
    art_prod_unit: string;
    art_prod_unit_d: string;
    art_stnd_cost: string;
    art_stnd_cost_d: string;
    art_brand_code: string;
    art_material_code: string;
    art_bil_last_del_yr_wk: string;
    art_rtl_wsl_type:string;
    art_stnd_cost_1:string;
    art_stnd_cost_2:string;
    art_stnd_cost_3: string;
    art_discount_percent: string;
    art_discount_amount:string;
    art_uom:string;
    art_intro_yr_wk: string;
    art_lead_time:string;
    art_mutation_master: string;
    art_sale_out_time: string;
    art_remark_1_d: string;
    gst_code: string;
    gst_pcnt: string;
    cgst_pcnt: string;
    sgst_pcnt: string;
    igst_pcnt: string;
    uom_sh: string;
    gst_code_desc:string;



 constructor(){}
}
