export class SearchForm {
    challanInvoiceNumber: string;
    invoiceStatus: any;

    frominvoiceCreateDate: string;
    toinvoiceCreateDate: string;

    itnRsnSerialNumber: string;
    destShopCode: string;
    partyNumber: string;
    itoStnNumber: string;

    userCode: string;
    bataWeekFrom:string;
    bataWeekTo:string;
    routeNumber:string;
    sourceShopCode:string;
    vehicleNumber:string;
    searchType:string;
    pageNumber:Number;
    pageSize:Number;
    recordCount:any;

    shipmentFromDate: string;
    shipmentToDate: string;

    constructor() { }
}
