export class PackingMaster {
    iCode: string;
    concept: string;
    catCode: string;
    shopCode: string;
    packCode: string;
    groupCode: string;
    heartSize: string;
    packingAssortment1: number;
    packingAssortment2: number;
    packingAssortment3: number;
    packingAssortment4: number;
    packingAssortment5: number;
    packingAssortment6: number;
    packingAssortment7: number;
    packingAssortment8: number;
    packingAssortment9: number;
    packingAssortment10: number;
    packingAssortment11: number;
    packingAssortment12: number;
    packingAssortment13: number;
    packingAssortment14: number;
    packingAssortment15: number;
    pair1: number;
    pair2: number;
    pair3: number;
    pair4: number;
    pair5: number;
    pair6: number;
    pair7: number;
    pair8: number;
    pair9: number;
    pair10: number;
    pair11: number;
    pair12: number;
    pair13: number;
    pair14: number;
    pair15: number;
    oldRDC: string;
    procTag: string;
    cartonQty: string;
    assortmentCode: string;
    assortmentType: string;
    createdDate: string;
    createdBy: string;
    totalPackAssortments:number;
    // set on Distribution Requisition Quantity: value change calculateOrderQuantity() method - distribution..component.ts
    totalPairs: number;
}
