/*
 * This class is replica of corresponding java enitiy class RequisitionHeader. 
 * It is used for capturing requisition-header information from search-requisition Form.
 */
import {RequisitionLines} from './requisition-lines';
export class RequisitionHeader {
   /*  reqNumber:string: string ;
     division:string: string ;
     category:string: string ;
     conceptCode:string: string ;
     reqStatus:string: string ;
     reqCreatedby:string: string ;
     reqCreateDate:string: string ;
     lastUpdatedBy:string: string ;
     lastUpdatedDate:string: string ;
     suppCode:string: string ;   
     suppName:string: string ;   
     requisitionLinesList: RequisitionLines[]: string ;*/
     reqId: string ;
	
     reqNumber: string ;
     
     conceptCode: string ;
     
     suppCode: string ;
     
     supplierName: string ;
     
     division: string ;
     
     reqStatus: string ;
     
     reqCreatedby: string ;
     
     reqCreateDate: string ;
     
     lastUpdatedBy: string ;
 
     lastUpdatedDate: string ;
     
     prCreationDate: string ;
     
     paCreationDate: string ;
     
     productionMonth: string ;
     
     productionYear: string ;
     
     buyerName: string ;
     
     plannerName: string ;

     totalPair: string ;
     
     totalValue: string ;
     
     totalCost: string ;
     
     createdDate:string;

     poCount:string;

     perfInvNumber:string;

     paymentTerms:string;

     poType:string;

     poTypeWithName:string;

     subPartyCode:string;

     constructor(){}
}
