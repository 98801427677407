import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BilApiUrl } from '../common/bil-api-url';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ViewUploadStatusService {
 
  private _view_uplaod_status_url: string = BilApiUrl.VIEW_UPLOAD_STATUS;
  constructor(private _http: HttpClient) { }

  getUploadKeys(){
    //let  {Observable}  = require('rxjs/Observable');
    let keyList = [
      { id: 'ARTICLE_COMPONENT', name: 'Import Article Component'},
      { id: 'ARTICLE_DISTRIBUTION', name: 'Import Article Distribution' },
      { id: 'ARTICLE_PRICE', name: 'Import FOB' },
      { id: 'ESTIMATE_UPLOAD', name: 'Import Estimate	Upload' },
      { id: 'PACK_ASSORTED', name: 'Import Pack Master for Assorted' },
      { id: 'PACK_WSL_ASSORTED', name: 'Import Pack Master for Wholesale Assorted' },
      { id: 'PO_ARTICLE', name: 'Import Po For Imported Article' },
      { id: 'PO_CONSUMABLE', name: 'Import Po For Consumable Article' },
      { id: 'PO_EXPORT', name: 'Upload Export PO' },
      { id: 'PO_RECALL', name: 'Import Bulk Recall' }
    ];
    let uploadKeys:Observable<string> =  Observable.create(
        observer => {
          keyList.forEach(
            key => {
              observer.next(key);
            }
          );
       // observer.complete();
      });
    return uploadKeys;
  }

  getUserUploadData(view_upload_status_param:any){
    return this._http.post(this._view_uplaod_status_url,view_upload_status_param);
  }

}
