import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserGroupModel } from './search-user-group-model';
import { SearchUserGroupService } from './search-user-group.service';
import { UserGroupSearchDetails } from './search-user-group-details';
import { GroupListDetails } from './user-group-list';
import { UserGroupResponseModel } from './user-group-response';

@Component({
  selector: 'app-search-user-group',
  templateUrl: './search-user-group.component.html',
  styleUrls: ['./search-user-group.component.css']
})
export class SearchUserGroupComponent implements OnInit {
  userGroupParameter = new UserGroupModel();
  responseMessage: string;
  userGroups: UserGroupSearchDetails[] = [];
  groupListDetails = {
    "groupName": ""
}
groupList:GroupListDetails[] = [];
username:string;

  constructor(private _manageUserGroupService: SearchUserGroupService,
    private _router: Router) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this._manageUserGroupService.getGroupList(this.groupListDetails).subscribe(
      (response:any) => {
        this.groupList = response;
      }
    )
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  onClickSearch() {
    this.responseMessage = '';
    this.userGroups = [];
    if (this.userGroupParameter.userName != undefined || this.userGroupParameter.groupId != undefined) {
      if (this.userGroupParameter.groupId == null) {
        this.userGroupParameter.groupId = undefined;
      }
      if (this.userGroupParameter.userName == null) {
        this.userGroupParameter.userName = undefined;
      }
      this._manageUserGroupService.getUserDetailBySearch(this.userGroupParameter).subscribe(
        (response: UserGroupResponseModel) => {
          console.log(JSON.stringify(response));
          if(response.code == "SUCCESS"){
          this.userGroups = response.dataList;
          }       
          else{
            this.responseMessage = 'Data Not Found';
          }
        },
        (err)=>{
          this.responseMessage = err;
         }
      )
    }
    else{
      this.responseMessage = 'Please give a search criteria';
    }
  }

  resetSearch() {
    this.responseMessage = '';
    this.userGroups = [];
    this.userGroupParameter = new UserGroupModel();
  }

  changeFlagStatus(index){
    this.responseMessage = '';
    if (this.userGroups[index].activeFlag == 'A') {
      this.userGroups[index].activeFlag = 'I';
    }
    else {
      this.userGroups[index].activeFlag = 'A';
    }
    let details = {
      "groupId": this.userGroups[index].groupId,
      "userId": this.userGroups[index].userId,
      "statusFlag": this.userGroups[index].activeFlag,
      "updatedBy": this.username
    }
    this._manageUserGroupService.updateUserFlagStatus(details).subscribe(
      (response: any) => {
        console.log(JSON.stringify(response));
        if (response.status == 200) {
          this.responseMessage = 'User Role Flag Status Updated';
        }
        else {
          this.responseMessage = 'Something went wrong';
        }
      }
    )
  }

  checkFlag(flag) {
    if (flag != 'I') {
      return true;
    }
    else {
      return false;
    }
  }

}
