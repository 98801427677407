export class savereq {
routeMasterId: number;
sourceShopCode: string;
routeSequence: string;
oracleSequence: number;
subRoute: string;
route: string;
concept: string;
conceptCode: string;
destShopCode: string;
destShopName: string;
activeStatus: string;
createdBy: string;
createdDate: string;
lastUpdatedBy: string;
lastUpdatedDate: string
constructor() {}
}