import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BatchModel } from './search_batch';
import { SearchBatchStatusService } from './search-batch-status.service';
import { SearchBatchStatus } from './search_batch_response';
import { UtilsService } from '../../common/utils-service';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { Router, RouterModule } from '@angular/router';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-search-batch-status',
  templateUrl: './search-batch-status.component.html',
  styleUrls: ['./search-batch-status.component.css']
})
export class SearchBatchStatusComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  searchBatch: SearchBatchStatus[] = [];
  batchModel = new BatchModel();
  RunDateStr: string;
  EndDateStr: string;
  responseMessage: string;
  requestParameterModel: RequestParameterModel[];
  currentPage: number = 1;
  batchName:string;
  batches:any;
  batchModel1:any;

  // batches = [
  //   { id: 'm', name: 'JDE To PO Interface', jobName: 'PO_Factory_Batch' },
  //   { id: 'i', name: 'Article Master Upload', jobName: 'MDM_ArticleMaster_Upload_Batch' },
  //   { id: 'i', name: 'Category Master Upload', jobName: 'MDM_Category_Upload_Batch' },
  //   { id: 'i', name: 'Party Master Upload', jobName: 'MDM_Party_Upload_Batch' },
  //   { id: 'i', name: 'Shop Master Upload', jobName: 'MDM_Shop_Upload_Batch' },
  //   { id: 'i', name: 'Week Master Upload', jobName: 'MDM_Week_Upload_Batch' },
  //   { id: 'i', name: 'State Master Upload', jobName: 'MDM_State_Upload_Batch' },
  //   { id: 'm', name: 'OTB To PO Interface', jobName: 'PO_OTB_Batch' },
  //   { id: 'm', name: 'RDS To PO Interface', jobName: 'PO_RECEIPT_RDS_Batch' },
  //   { id: 'm', name: 'DMS To PO Interface', jobName: 'PO_RECEIPT_DMS_Batch' },
  //   { id: 'm', name: 'Vendor Supply To PO Interface', jobName: 'PO_SUPPLY_VENDOR_Batch' },
  //   { id: 'm', name: 'Unit Supply To PO Interface', jobName: 'PO_SUPPLY_UNIT_Batch' },
  //   { id: 'i', name: 'BARCODE To PO Interface', jobName: 'BARCODE_TO_PO_Batch' },
  //   { id: 'o', name: 'PO To RIMS Onway Interface', jobName: 'PO_RIMS_ONWAY_Batch' },
  //   { id: 'o', name: 'PO To RIMS Onorder Interface', jobName: 'PO_RIMS_ONORDTRN_Batch' },
  //   { id: 'o', name: 'PO To OTB Interface', jobName: 'PO_OTB_Batch' },
  //   { id: 'o', name: 'PO To RDS Interface', jobName: 'PO_RDC_Batch' },
  //   { id: 'o', name: 'PO To DMS Interface', jobName: 'PO_DMS_Batch' },
  //   { id: 'o', name: 'PO To BARCODE Order Master Interface', jobName: 'PO_Barcode_ORDMAST_FSC_Batch' },
  //   { id: 'o', name: 'PO To BARCODE Order Interface', jobName: 'PO_Barcode_ORDER_FSC_Batch' },
  //   { id: 'o', name: 'PO To PROMPS Interface', jobName: 'PO_PROMS_Batch' },
  //   { id: 'o', name: 'PO To WMS 680 Interface', jobName: 'PO_WMS680_Batch' },
  // ];

  constructor(private searchBatchService: SearchBatchStatusService,
    private _utilsService: UtilsService, private _router: Router,private httpClient: HttpClient) { }

  ngOnInit() {
    let batchInput=[{
      "name":"itfModule",
      "value":"RDS"
  }]
  this.searchBatchService.getRDSBatches(batchInput).subscribe(
    (res: any) => {
      console.log(res);
      this.batches=res.data;
      console.log(this.batches);
    });
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
    this.batchModel.jobName = null;
  }

  onSearch() {
    console.log(this.batchModel);
    this.responseMessage = '';
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.batchModel);
    console.log(JSON.stringify(this.requestParameterModel));
    if (this.requestParameterModel.length !== 0) {
      this.loaDer.nativeElement.style.display = 'block';
      this.searchBatchService.getBatches(this.requestParameterModel).subscribe(
        (response: SearchBatchStatus[]) => {
          console.log(response);
          console.log('batchlist');
          if (response != null) {
            this.searchBatch = [];
            // this.batches.filter(cs => cs.jobName === this.batchModel.jobName).forEach(
            //   each => {
            //     this.batchName = each.name;
            //   }  
            //   );
            this.searchBatch = response;
            this.searchBatch = this.searchBatch.filter(function( obj){
              return obj.jobName !== "RESUPPLY_BATCH_UPLOAD" || obj.failureReason !== "ReSupply Batch Failed - No file was found in SFTP for the executed batch"; //the second condition is only executed when the first condition false.
            });
            console.log(this.searchBatch);
            this.searchBatch.sort((a, b) => {
              return <any>new Date(b.jobRunTime) - <any>new Date(a.jobRunTime);
            });
            let RunDate: Date;
            let EndDate: Date;
            this.searchBatch.forEach(
              batch => {
                if (batch.failureReason != null) {
                  batch.startingUrl = batch.failureReason.substr(0,8);
                  }
                RunDate = new Date(batch.jobRunTime);
                this.RunDateStr = this._utilsService.getCurrentDateTimeFormat(RunDate);
                batch.jobRunTime = this.RunDateStr;
                if (batch.jobEndTime != null || batch.jobEndTime != undefined) {
                  EndDate = new Date(batch.jobEndTime);
                  this.EndDateStr = this._utilsService.getCurrentDateTimeFormat(EndDate);
                  batch.jobEndTime = this.EndDateStr;
                }
              }
            );
            this.currentPage = 1;
            this.loaDer.nativeElement.style.display = 'none';
          }
          else {
            this.responseMessage = 'No Data Found';
            this.searchBatch = [];
            this.loaDer.nativeElement.style.display = 'none';
          }
        },
        (err) => {
          this.searchBatch = [];
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
    else {
      this.responseMessage = 'Please give Search Criteria';
      this.searchBatch = [];
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  resetSearch(){
    this.batchModel = new BatchModel();
    this.batchModel.jobName = null;
    this.responseMessage = '';
    this.searchBatch = [];
    this.loaDer.nativeElement.style.display = 'none';
  }

  public async downloadReason(failureDownloadUrl,jobDownloadName): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    const file = await this.httpClient.get<Blob>(failureDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = jobDownloadName + '.' + 'csv';
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none';
  }
}
