import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchInvoiceHeader } from '../search-header';
import { SearchInvoiceLine } from '../../search-invoice/search-line';
import { SearchAssortment } from '../../search-invoice/search-assortment';
import { Router, RouterModule } from '@angular/router';
import { ViewInvoiceService } from './view-invoice.service';
import { NavigationLinkParameterService } from '../../../common/navigation-link-parameters-service';
import { SearchInvoiceResponseModel } from '../../../common/search-invoice-response-model';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['../../../../assets/css/bootstrap.css', '../../../../assets/css/rds-bil-style.css', './view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {

  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  @ViewChild('closeBtn1', { static: false }) closeBtn1: ElementRef;

  searchInvoiceDetails: any;
  searchInvoiceLineDetails: any[] = [];
  searchInvoiceAssortment: SearchAssortment[] = [];
  cmpshpBtn=false;
  stpshpBtn=false;
  artnumber: any;
  assortData: any = {};
  confMsg:string;
  note:string;
  btnName:string;
  remarks:string='';
  responseMessage1:string;
  responseMessage:string;
  cls:string;
  userCode:string;
  assortArray: any[];
  groupCode: string;
  totalPairs:any;
  aprvrejbtn:boolean=false;
  stopShipmentProcess:boolean=false;

  itemsPerPage:number=10;
  totalItems:any;
  currentPage = 1;
  errormsg: string;
  invoiceNumber:string;
  invStatus:string;
  constructor(private viewInvoiceService: ViewInvoiceService, private router: Router,
    private navigationLinkParameterService: NavigationLinkParameterService) { }

  ngOnInit() {
    const sessUsername = sessionStorage.getItem('currentUser');
    this.userCode=JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.invoiceNumber = this.navigationLinkParameterService.invoiceNumber;
    this.invStatus = this.navigationLinkParameterService.invStatus;
    this.searchInvoiceDetails = this.navigationLinkParameterService.invoiceDetails;
    console.log(this.searchInvoiceDetails);
    // this.viewInvoiceService.getInvoiceLineDetails(this.searchInvoiceDetails.challanInvoiceNumber).subscribe(
    //   (response: SearchInvoiceResponseModel) => {
    //     this.searchInvoiceLineDetails = response.dataList[0].invoiceLinesList;
    //     this.loaDer.nativeElement.style.display = 'none';
    //   }
    // );
    let totalCout=0;
    // this.searchInvoiceLineDetails = this.navigationLinkParameterService.invoiceDetails.invoiceLinesList;
    let d=[
      {"name":"challanInvoiceNumber","value":this.invoiceNumber},
      // {"name":"pageSize","value":this.itemsPerPage},
      // {"name":"pageNumber","value":this.currentPage},
      {"name":"searchType","value":"line"},
      {"name":"userCode","value":this.userCode}
    ]
      this.viewInvoiceService.getInvoiceBySearchCriteria(d).subscribe(
        (response: any) => {
          if (response.dataList !== null) {
            this.searchInvoiceDetails=response.dataList[0];      
            console.log(this.searchInvoiceDetails);
            console.log(response.dataList[0].invoiceLinesList);
            this.searchInvoiceLineDetails = response.dataList[0].invoiceLinesList;
            this.searchInvoiceLineDetails.sort((a, b) => b['invoiceLineId'].localeCompare(a['invoiceLineId']));
            console.log(this.searchInvoiceLineDetails);
            

            this.totalItems=response.recordCount;
            this.searchInvoiceLineDetails.forEach(
              assort => {
                console.log(assort.artQty);
                totalCout=totalCout+((assort.artQty!=null)?parseInt(assort.artQty):0);
                if(this.searchInvoiceDetails.stockType == 'CLAIMED'){
                  assort['claimAmount'] = 0;
                  assort.invoiceLineAssortments.forEach(
                    assortData => {
                      if(assortData.invoiceAssortmentClaimSlipModels.length > 0){
                        assortData.invoiceAssortmentClaimSlipModels.forEach(
                          claims => {
                            assort['claimAmount'] += Number(claims.claimedAmount * claims.pairs);
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
            this.totalPairs=totalCout;
          } else {
            // console.log(response.message);
            this.responseMessage = response.message;
            this.searchInvoiceLineDetails = [];
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (error) => {
          this.errormsg = error;
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
      var numCheck = this.searchInvoiceDetails.challanInvoiceNumber.substring(0,2);
    //console.log(this.searchInvoiceDetails.invStatus);
    //console.log(this.searchInvoiceLineDetails);
    if(this.invStatus === 'INVOICED' || this.invStatus === 'SHIPPED'){
      this.cmpshpBtn=true;
    }
    // if(this.searchInvoiceDetails.invStatus === 'SHIPMENT-INITIATED' && this.searchInvoiceDetails.invoiceType !== 'DISPATCH_IMPORT'){
    //   //console.log(this.searchInvoiceDetails.invoiceType);
    //   this.stpshpBtn=true;
    // }
    // if((this.invStatus === 'SHIPMENT-INITIATED' || this.invStatus === 'DISPATCHED') && this.searchInvoiceDetails.customerCode == null){
    if((this.invStatus === 'SHIPPED' || this.invStatus === 'INVOICED') && this.searchInvoiceDetails.customerCode == null && numCheck !=='NV'){
      //console.log(this.searchInvoiceDetails.invoiceType);
      this.stpshpBtn=true;
    }
  }
  invoiceBySearchCriteria(invoiceModel) {
    invoiceModel.pageSize=this.itemsPerPage;
    invoiceModel.pageNumber=this.currentPage;
      this.viewInvoiceService.getInvoiceBySearchCriteria(invoiceModel).subscribe(
        (response: any) => {
          if (response.dataList !== null) {
            this.searchInvoiceLineDetails = response.dataList;
            this.totalItems=response.recordCount;
            // for(let x=0;x<response.dataList.length;x++) {
            //   const FromDate: any[] = response.dataList[x]['invoiceCreateDate'].split('/');
            //   const stDate = new Date(FromDate[2], FromDate[1]-1, FromDate[0]);
            //   this.dateArray[x] = {};
            //   this.dateArray[x] = stDate;
            // }    
            // this.createForm();
          } else {
            // console.log(response.message);
            this.responseMessage = response.message;
            this.searchInvoiceLineDetails = [];
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (error) => {
          this.errormsg = error;
          this.loaDer.nativeElement.style.display = 'none';
        }
      );

  }
  onClickBack() {
    this.router.navigate(['/rds-search-invoice']);
  }
  getPage(page){
    this.currentPage=page;
    // this.searchAsnDataWithValues(this.searchASNCriteriaList);

}
  printInvoice() {
    this.navigationLinkParameterService.invoiceDetails=this.searchInvoiceDetails;
    this.router.navigate(['/rds-invoice']);
  }

  checkStatus(status){
    //console.log(status)
  }

  // onClickView(index) {
  //   this.searchInvoiceAssortment = [];
  //   this.searchInvoiceLineDetails[index].invoiceLineAssortments.forEach(
  //     assort => {
  //       let newObj = new SearchAssortment();
  //       newObj.artSize = assort.artSize;
  //       newObj.pairQty = assort.pairQty;
  //       this.searchInvoiceAssortment.push(newObj);
  //     }
  //   );
  // }
  shipmentOperation(operation){
    //console.log(operation);
    this.aprvrejbtn=false;
    this.responseMessage1 ='';
      if(operation === 'STOP'){
        //console.log('if');
        this.btnName="Stop";
        this.note="cancellation reason";
        this.confMsg="Are you sure you want to stop the shipment?";
        this.cls="fa fa-times";
      }else{
        //console.log('else');
        this.btnName="Complete";
        this.note="Completeion note";
        this.confMsg="Are you sure you want to complete the shipment?";
        this.cls="fa fa-check";
      }
  }
  setASNAssortmentDataPopup(artnumber) {
    //console.log(artnumber);
      this.artnumber = artnumber;
      this.assortArray = [];
      let firstletter = artnumber.charAt(0);
      //console.log(this.searchInvoiceLineDetails);
      const index = this.searchInvoiceLineDetails.findIndex((x) => x.artNumber === artnumber);
      //console.log(index);
      if (index != -1) {
          this.assortData = this.searchInvoiceLineDetails[index];
          //console.log(this.assortData);
          let details = [{ "name": "letterOfArticle", "value": firstletter }];
          this.viewInvoiceService
          .getSizeDetails(details)
          .subscribe(
            (response: any) => {
              if (response.status == "Success") {
                this.groupCode = response.dataList[0].groupCode;
                this.assortArray = [];
                response.dataList.forEach(data => {
                  let assortmentArray = this.assortData.invoiceLineAssortments;
                  let found = assortmentArray.findIndex(element => element.artSize == data.artSize);
                  if (found !== -1) {
                    let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWiseQty: assortmentArray[found].pairQty };
                    this.assortArray.push(assortmentData);
                  }
                });
              }
            },
            (err) => {
              this.assortArray = [];
            }
          );
        }
    }

    approveOrRejectStatusChange(status) {
      //console.log(status);
      
      if (this.remarks === '') {
        if(status !== 'Complete'){
          this.responseMessage1 = "Please enter cancel reason.";
        }else{
          this.responseMessage1 = "Please enter note.";
        }
        
        
      } else {
        this.loaDer.nativeElement.style.display = "block";
        this.aprvrejbtn=true;
        if(status === 'Complete'){
          this.closeBtn1.nativeElement.click();
          let details = [
            {
              "userId": this.userCode,
              "invoiceIds": this.searchInvoiceDetails.invoiceId,
              "completionNote": this.remarks
            }
          ];
          this.viewInvoiceService.completeShipment(details).subscribe(
            (response: any) => {
              this.loaDer.nativeElement.style.display = "none";
              if (response.status === "SUCCESS") {
                setTimeout(() => {
                  
                  this.closeBtn1.nativeElement.click();
                  // this.navigationLinkParameterService.searchInvoiceHeaderData.searchData='';
                  // this.navigationLinkParameterService.searchInvoiceHeaderData.resultData='';
                  this.onClickBack();
                }, 200);
              } else {
                
                this.closeBtn1.nativeElement.click();
                this.responseMessage = "Something Error Happened.";
              }
            },
            (err) => {
              
              this.closeBtn1.nativeElement.click();
              this.responseMessage = err;
              this.loaDer.nativeElement.style.display = "none";
            },
            () => {
              
              this.closeBtn1.nativeElement.click();
              this.loaDer.nativeElement.style.display = "none";
            }
          );
        }else{
          this.stopShipmentProcess=true;
          let details1 = [
            {"name":"invoiceId", "value":this.searchInvoiceDetails.invoiceId},
            {"name":"userCode","value":this.userCode},
            {"name":"challanInvoiceNumber","value":this.searchInvoiceDetails.challanInvoiceNumber },
            {"name":"cancelledReason", "value":this.remarks }
          ];
          // setTimeout(() => {                  
          //   this.closeBtn1.nativeElement.click();
          //   this.navigationLinkParameterService.searchInvoiceHeaderData.searchData='';
            this.navigationLinkParameterService.searchInvoiceHeaderData.resultData='';
          //   this.onClickBack();
          // }, 200);
          this.responseMessage = "Please wait! Stop Shipment Processing.";
          this.viewInvoiceService.stopShipment(details1).subscribe(
            (response: any) => {
              //console.log(response);
              this.loaDer.nativeElement.style.display = "none";
              if (response.status === "SUCCESS") {
                setTimeout(() => {
                  
                  this.closeBtn1.nativeElement.click();
                  // this.navigationLinkParameterService.searchInvoiceHeaderData.searchData='';
                  this.navigationLinkParameterService.searchInvoiceHeaderData.resultData='';
                  this.loaDer.nativeElement.style.display = "none";
                  this.onClickBack();
                }, 500);
              } else {
                this.stopShipmentProcess=false;
                this.closeBtn1.nativeElement.click();
                this.responseMessage = "Something Error Happened.";
              }
            },
            (err) => {
              
              this.closeBtn1.nativeElement.click();
              this.responseMessage = err;
              this.loaDer.nativeElement.style.display = "none";
            },
            () => {
              
              this.closeBtn1.nativeElement.click();
              this.loaDer.nativeElement.style.display = "none";
            }
          );
        }
      }
    }

}
