export class ResupplyHeader {
  itnRsnSerialNumber: string;
  fromresupplydate: Date;
  toresupplydate: Date;
  resupplyStatus: string;
  destShopNumber: string;
  conceptCode: string;
  sourceRDC: string;
  itoStnNumber: string;
  itnRsnId: string;
  //  updated by priti dated 01-10-20 
  resupplyType: string;
  bataWeekFrom: string;
  bataWeekTo: string;
  resupplyYear:string;
  reSupplyWeek:string;
  sourceShopNumber:string;
  resupplyArtTotalQuantity:string;
  odrQty:Number;
  resupplyArtFulfilledQuantity:string;
  resupplyArtCancelQuantity:string;

  //  updated by priti dated 01-10-20
  constructor() {}
}
