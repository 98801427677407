import { Injectable } from '@angular/core';
import { BilApiUrl } from '@/common/bil-api-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PoDateChangeService {


  apiUrl=BilApiUrl.COMMON_UPLOAD_API_URL;
  URL :string=BilApiUrl.COMMON_DOWNLOAD_URL;
  urlForDownloadUrl:string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  generateImportPo:string=BilApiUrl.PO_DATE_CHANGE;

  constructor(private http:HttpClient) { }

  upload(formData) {
    return this.http.post(`${this.apiUrl}`, formData);
  }
  
  getDownloadUrl(){
    return this.http.get(this.urlForDownloadUrl);
  }

  public getGenerate(uploadKeyValues:any){
    return this.http.post(this.generateImportPo,uploadKeyValues);
  }
}
