import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ViewLocatonQuantityService } from './view-locaton-quantity.service';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';

@Component({
  selector: 'app-view-location-quantity',
  templateUrl: './view-location-quantity.component.html',
  styleUrls: ['./view-location-quantity.component.css']
})
export class ViewLocationQuantityComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  constructor(private _router: Router, private _viewShipmentService: ViewLocatonQuantityService,
  private _utilsService: UtilsService,private _navigationLinkParameterService: NavigationLinkParameterService) { }
  poNumber: string;
  requestParameterModel: RequestParameterModel[];
  viewLocationLines: any[] = [];
  responseMessage: string;

  ngOnInit() {
    this.poNumber = this._navigationLinkParameterService.viewLocationPoNumber;
    this.responseMessage = '';
    let searchDetails = {
      "poNumber": this.poNumber,
      "userId": JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode
    }
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(searchDetails);
    this._viewShipmentService.getLocationServiceCriteria(this.requestParameterModel).subscribe(
      (response: any) => {
        console.log(JSON.stringify(response));
        if(response.status == 'success'){
          this.viewLocationLines = [];
          this.viewLocationLines = response.poRDCWiseModel;
          this.viewLocationLines.forEach(
            locationObj => {
              let outstandingQuant = locationObj.orderedQty - locationObj.supplyQty - locationObj.cancelledQty;
              let onwayQuant = locationObj.supplyQty - locationObj.receivedQty;
              if (outstandingQuant < 0) {
                locationObj.outstandingQty = '0';
              }
              else {
                locationObj.outstandingQty = (outstandingQuant).toString();
              }
              if (onwayQuant < 0) {
                locationObj.onwayQty = '0';
              }
              else {
                locationObj.onwayQty = (onwayQuant).toString();
              }
            }
          );
        }
        else{
          this.viewLocationLines = [];
          this.responseMessage = response.msg;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (error) => {
        this.responseMessage = error;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
    
  }

  onClickBack() {
    this._router.navigate(['search-po']);
  }
}
