import { Party } from './manage-party-details-model';
import { Component, OnInit } from '@angular/core';
import { ManageSupplierAccessService } from './manage-supplier-access.service';
import { SupplierAccesstModel } from './manage-supplier-access-model';
import { Concept } from '../common/concept';
import { SupplierAccessSearchDetails } from './manage-supplier-access-details';
import { Router, RouterModule } from '@angular/router';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../common/field-name-list';
import { UtilsService } from '../common/utils-service';


@Component({
  selector: 'app-manage-supplier-access',
  templateUrl: './manage-supplier-access.component.html',
  styleUrls: ['./manage-supplier-access.component.css']
})
export class ManageSupplierAccessComponent implements OnInit {
  supplierAccessParameter = new SupplierAccesstModel();
  username: string;
  parties: Party[];
  supplierAccess: SupplierAccessSearchDetails[] = [];
  responseMessage: string;
  currentPage: number = 1;

  constructor(private _utilsService: UtilsService, private _manageSupplierAccessService: ManageSupplierAccessService,
    private _router: Router) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this._manageSupplierAccessService.getPartyNumber().subscribe(
      (data: any) => {
        console.log(data);
        this.parties = data.partyNumberList;
      }
    )
  }

  onClickSearch() {
    this.responseMessage = '';
    this.supplierAccess = [];
    console.log(this.supplierAccessParameter.userCode);
    console.log(this.supplierAccessParameter.partyNumber);

    if(this._utilsService.createRequestParametersModelArray(this.supplierAccessParameter).length > 0){
      if(this.supplierAccessParameter.partyNumber == null){
        this.supplierAccessParameter.partyNumber = undefined;
      }
      if(this.supplierAccessParameter.userCode == null){
        this.supplierAccessParameter.userCode = undefined;
      }
      this._manageSupplierAccessService.getSupplierDetailBySearch(this.supplierAccessParameter).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          this.supplierAccess = response.dataList;
          if (response.status != 'Success') {
            console.log('No Data');
            this.responseMessage = response.message;
          }
        }
      )

    }
    else{
      this.responseMessage = 'Please give a search criteria';
    }


    // if ((this.supplierAccessParameter.userCode == undefined || this.supplierAccessParameter.userCode ==  "" ) && this.supplierAccessParameter.partyNumber == undefined) {
    //   this.responseMessage = 'Please give a search criteria';
    // }else{

    //   console.log(this.supplierAccessParameter.userCode);
    //   console.log(this.supplierAccessParameter.partyNumber);

    //   this._manageSupplierAccessService.getSupplierDetailBySearch(this.supplierAccessParameter).subscribe(
    //     (response: any) => {
    //       console.log(JSON.stringify(response));
    //       this.supplierAccess = response.dataList;
    //       if (response.status != 'Success') {
    //         console.log('No Data');
    //         this.responseMessage = response.message;
    //       }
    //     }
    //   )
    // }

  }

  resetSearch() {
    this.responseMessage = '';
    this.supplierAccess = [];
    this.supplierAccessParameter = new SupplierAccesstModel();
  }

  checkFlag(flag) {
    if (flag != 'N') {
      return true;
    }
    else {
      return false;
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  changeFlagStatus(index) {
    this.responseMessage = '';
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log('index ---> '+absoluteIndex);
    if (this.supplierAccess[absoluteIndex].activeFlag == 'Y') {
      this.supplierAccess[absoluteIndex].activeFlag = 'N';
    }
    else {
      this.supplierAccess[absoluteIndex].activeFlag = 'Y';
    }
    let details = {
      "userSupplyId": this.supplierAccess[absoluteIndex].userSupplyId,
      "partyNumber": this.supplierAccess[absoluteIndex].partyNumber,
      "activeFlag": this.supplierAccess[absoluteIndex].activeFlag,
      "updatedBy": this.username
    }
    this._manageSupplierAccessService.updateSupplierFlagStatus(details).subscribe(   
      (response: any) => {
        console.log(JSON.stringify(response));
        if (response.status == 200) {
          this.responseMessage = 'Status Updated Successfully.';
            setTimeout(()=>{
              this.responseMessage = '';
            },3000);
        }
        else {
          this.responseMessage = 'Status Update Failed.';
        }
      }
    )
  }
}
