import { Injectable } from '@angular/core';
import { BilApiUrl } from '@/common/bil-api-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageSizeMasterService {

  constructor(private _http: HttpClient) { }
  private _manage_size_master_url: string = BilApiUrl.MANAGE_SIZE_MASTER; 
  
  getSizeMaster(manage_size_master_param:any){
    return this._http.post(this._manage_size_master_url,manage_size_master_param);
  }
 

}
