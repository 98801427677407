import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { BilApiUrl } from '../common/bil-api-url';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    user_auth_url1: string = BilApiUrl.USER_AUTH_URL;
    user_auth_url: string = BilApiUrl.USER_AUTH_LOGIN_URL;
    get_refresh_token: string = BilApiUrl.GET_REFRESH_TOKEN;
    @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
    @Output() getLoggedInRole: EventEmitter<any> = new EventEmitter();
    @Output() getLoggedInConcept: EventEmitter<any> = new EventEmitter();
    @Output() getLoggedInModule: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient,private authService: MsalService) {
        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string) {
        //if(username == 'User Test1' || username == 'User Test2' || username == 'User Test3'){
        console.log("userName " + username + "qwerty" + btoa(username));
        let user_details = [{ "name": "userName", "value": btoa(username) }];
        return this.http.post<any>(this.user_auth_url, user_details)
            .pipe(map(user => {
                if (user.status == 'success' && user.groupAssignmentModels !=null && user.groupAssignmentModels.length>0 ) {
                    sessionStorage.setItem('currentUser', JSON.stringify(user));
                    //console.log(user);
                    this.getLoggedInName.emit(user.groupAssignmentModels[0].userName);
                    this.getLoggedInRole.emit(user.groupAssignmentModels[0].groupName);
                    this.getLoggedInConcept.emit(user.groupAssignmentModels[0].concept);
                    this.getLoggedInModule.emit(user.groupAssignmentModels[0].userModule);
                    this.currentUserSubject.next(user);
                } else {
                    //console.log('hello');
                    this.currentUserSubject.next(null);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        //localStorage.removeItem('currentUser');
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.authService.logout();
    }

    refreshToken() {
        // var userDetails = JSON.parse(sessionStorage.getItem('currentUser'));
        // let user_details = [{ "name": "userName", "value": userDetails.groupAssignmentModels[0].userCode }, { 'name': 'bilToken', "value": userDetails.bilToken }];
        // //console.log('RefreshToken called');
        // localStorage.setItem('refresfToken', "true");
        // return this.http.post<any>(this.get_refresh_token, user_details)
        //     .pipe(map(user => {
        //         if (user) {

        //             // console.log(user);
        //             // console.log('if');
        //             userDetails['bilToken'] = user.tokenCode;
        //             //console.log(JSON.stringify(userDetails));
        //             sessionStorage.setItem('currentUser', JSON.stringify(userDetails));
        //         } else {
        //             // console.log('else');
        //             // console.log(user);
        //             this.currentUserSubject.next(null);
        //         }

        //         return user.status;
        //     }));
        const requestObj={
            scopes:["user.read"]
        };
        this.authService.acquireTokenSilent(requestObj).subscribe(tokenResponse => {
            console.log(tokenResponse.accessToken);
            console.log;("tokenResponse");
            return tokenResponse.accessToken;
          },
          error => {
             console.log("Error");
              console.log(error);
              return error;
          });
    }
}
