import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { UtilsService } from '../../common/utils-service';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { ResponseMessage } from '../../common/response-message';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BarcodeScanService } from './barcode-scan.service';
import { BarcodeScanHeader } from './barcode-header';
import { BarcodeScanResponseModel } from '../../common/barcode-scan-response-model';
import { Search } from './search';
import { DatePipe } from '@angular/common';
import { BarcodeScanLine } from './barcode-line';
import { BarcodeScanAssort } from './barcode-assort';
import { ValidateGRN } from './validate-grn';
import { ShortageAssort } from './shortage-assortment';
import { from } from 'rxjs';
import { GRNLine } from '../search-grn/grn-line';

@Component({
  selector: 'app-barcode-scan',
  templateUrl: './barcode-scan.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', './barcode-scan.component.css'],
  providers: [DatePipe]
})
export class BarcodeScanComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;

  requestParameterModel: RequestParameterModel[];
  barcodeModel = new Search();
  searchBarcode: BarcodeScanHeader[] = [];
  searchBarcodeObj = new BarcodeScanHeader();
  searchBarcodeObj1D = new BarcodeScanAssort();
  searchBarcodeLineArr: BarcodeScanLine[] = [];
  searchBarcodeLine = new BarcodeScanLine();
  uploadFileName: string = 'No file Chosen';
  responseMessage: string;
  uploadMessage: string;
  errormsg: string;
  profileForm: FormGroup;
  statusMessage: string = '';
  show: boolean = false;
  remarks: string;
  myDate: Date = new Date();
  username: string;
  check: number = 0;
  enable: boolean = true;
  arr = ['2D', '1D'];
  ginDateStr: string;
  manDateStr: string;
  shortage: boolean = false;
  barcodeNo: string;
  responseValidatedData: ValidateGRN[] = [];
  transpoterList: ValidateGRN[] = [];
  responseShortageData: ShortageAssort[] = [];
  combineData: any;
  listOfShortage: ShortageAssort[] = [];
  responseValidateMessage: string;
  firstTabHeaderLabel: string = '1(D)';
  secondTabHeaderLabel: string = '2(D)';
  oneDimenResult: BarcodeScanAssort[] = [];
  resultArray: any = [];
  resultArray1D: any = [];
  assormentArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15','16','17','18','19'];

  constructor(private fb: FormBuilder, private barcodeScanService: BarcodeScanService, private httpClient: HttpClient, private _utilsService: UtilsService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private datePipe: DatePipe) {
    this.profileForm = this.fb.group({
      name: [''],
      file: [''],
      uploadedBy: [''],
      uploadKey: ['BARCODE_SCAN']
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername)[0].userEmail;
  }

  submitSearchForm() {
    this.loaDer.nativeElement.style.display = 'block';
    this.barcodeBySearchCriteria();
  }


  barcodeBySearchCriteria() {
    this.check = 0;
    this.enable = true;
    this.responseMessage = '';
    this.oneDimenResult = [];
    this.resultArray = [];

    if (this.barcodeModel.invoiceNumber != null || this.barcodeModel.ginNumber != null) {
      this.barcodeScanService.getBarcodeBySearchCriteria(this.barcodeModel).subscribe(
        (response: BarcodeScanResponseModel) => {
          console.log(response);
          if (response.status == 'SUCCESS') {
            this.check = 1;
            this.enable = false;
            this.show = true;
            this.searchBarcodeObj = response.dataList[0];
            let ginDate: Date = this.searchBarcodeObj.createDate;
            this.ginDateStr = this._utilsService.transformDate1(ginDate);
          }
          else {
            this.responseMessage = response.message;
            this.searchBarcodeObj = null;
          }
        },
        (error) => {
          this.responseMessage = error;
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
      this.barcodeScanService.getBarcodeBySearchCriteria11(this.barcodeModel).subscribe(
        (response: any) => {
          console.log(response);
          if (response.dataList.length != 0) {
            this.searchBarcodeLineArr = response.dataList;
            this.searchBarcodeLine = response.dataList[0];
            this.oneDimenResult = [];
            this.oneDimenResult = response.dataList[0].oneDScans;

            this.oneDimenResult.forEach(
              dates => {
                dates.manDateStr = this._utilsService.transformDate1(dates.manufacturingDate);
              }
            );
            for (var i = 0; i < this.searchBarcodeLineArr.length; i++) {

              this.resultArray[i] = [];
              // this.resultArray1D[i] = [];

              this.resultArray[i].planNumber = this.searchBarcodeLineArr[i].planNumber;
              this.resultArray[i].productionUnit = this.searchBarcodeLineArr[i].productionUnit;
              this.resultArray[i].artNumber = this.searchBarcodeLineArr[i].artNumber;
              this.resultArray[i].poNumber = this.searchBarcodeLineArr[i].poNumber;

              this.resultArray[i].sizes = [];
              // this.resultArray1D[i].sizes1D = [];

              for (var j = 0; j < this.assormentArray.length; j++) {

                if (this.searchBarcodeLineArr[i].twoDScanAssortments.length !== 0) {
                  let index = this.searchBarcodeLineArr[i].twoDScanAssortments.findIndex((x) => x.sizeBucket == this.assormentArray[j]);
                  let obj = {};
                  if (index > -1) {
                    obj["pairQty"] = this.searchBarcodeLineArr[i].twoDScanAssortments[index].pairQty;
                  } else {
                    obj["pairQty"] = '';
                  }
                  this.resultArray[i].sizes.push(obj);
                } else {
                  let obj = {};
                  obj["pairQty"] = '';
                  this.resultArray[i].sizes.push(obj);
                }
                // if (this.searchBarcodeLineArr[i].oneDScans.length != 0) {
                //   let index1D = this.searchBarcodeLineArr[i].oneDScans.findIndex((x) => x.sizeBucket == this.assormentArray[j]);
                //   let obj1D = {};
                //   if (index1D > -1) {
                //     obj1D["pairQty"] = '1';
                //   } else {
                //     obj1D["pairQty"] = '';
                //   }
                //   this.resultArray1D[i].sizes1D.push(obj1D);
                // }
                // else{
                //   let obj1D = {};
                //   obj1D["pairQty"] = '';
                //   this.resultArray1D[i].sizes1D.push(obj1D);
                // }
              }
            }
            console.log(this.resultArray);
          }
          else {
            this.responseMessage = 'No Data Found for 2D/1D.';
            this.searchBarcodeLineArr = [];
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (error) => {
          this.responseMessage = error;
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    else {
      this.errormsg = 'Please fill any field first';
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  validateData(barcodeNo) {
    this.responseValidatedData = [];
    this.responseShortageData = [];

    this.barcodeScanService.validateBarcodeDetails(barcodeNo).subscribe(
      (response: any) => {

        if (response.status == 'SUCCESS') {
          this.responseValidatedData = response.dataList;
          console.log(this.responseValidatedData);
          for (var i = 0; i < this.responseValidatedData.length; i++) {
            this.responseShortageData = this.responseValidatedData[i].goodsReceiptNoteAssortments;
            if (this.responseValidatedData[i].shortage == true) {
              this.shortage = true;
              this.responseValidateMessage = "Validation Done!Click on shortage button for viewing size wise shortage";
            }
            else {
              this.shortage = false;
              this.responseValidateMessage = "Validation Done!No shortage";
            }
            for (var j = 0; j < this.responseShortageData.length; j++) {
              let obj = {};
              obj['sizeLabel'] = this.responseShortageData[j].sizeLabel;
              obj['shortagePairQty'] = this.responseShortageData[j].shortagePairQty;
              this.combineData = [obj['sizeLabel'], obj['shortagePairQty']];
              this.listOfShortage.push(this.combineData);
            }
          }
          console.log(this.listOfShortage);
        }
      }
    );
    // this.barcodeScanService.validateBarcodeDetails(this.barcodeModel).subscribe(
    //   (response: any) => {
    //     console.log(response);
    //     if (response.status == 'SUCCESS') {

    //       this.show = true;
    //       this.searchBarcodeObj = response.dataList[0];

    //       let ginDate: Date = this.searchBarcodeObj.createDate;
    //       this.ginDateStr = this._utilsService.transformDate1(ginDate);
    //     }
    //     else {
    //       this.responseMessage = response.message;
    //       this.searchBarcodeObj = null;
    //     }
    //   },
    //   (error) => {
    //     this.responseMessage = error;
    //     this.loaDer.nativeElement.style.display = 'none';
    //   }
    // );
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      this.check++;
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
    }
  }

  onSubmit(value) {
    this.statusMessage = '';
    this.responseMessage = '';
    this.uploadMessage = '';
    if (this.myInputVariable.nativeElement.files.length == 0) {
      this.uploadMessage = 'Please select file';
      this.statusMessage = 'error';
      return false;
    }
    else {
      const formData = new FormData();
      formData.append('file', this.profileForm.get('file').value);
      formData.append('txnType', "receive");
      formData.append('createdBy', this.username);
      formData.append('scanType', value);
      formData.append('ginNumber', this.searchBarcodeObj.ginNumber);
      formData.append('consignorCode', this.searchBarcodeObj.consignorCode);
      formData.append('shopNumber', "4029");

      this.loaDer.nativeElement.style.display = 'block';
      this.barcodeScanService.saveData(formData).subscribe(
        (response: ResponseMessage) => {
          if (response.status == 'SUCCESS') {
            this.responseMessage = response.message;
            this.submitSearchForm();
          }
          else {
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.uploadMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
  }
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }

  downloadTextFile() {
    this.barcodeScanService.fakeValidateUserData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: this.searchBarcodeObj.invoiceNumber + '.txt',
        text: ""
      });
    });
  }

  // downloadTextFile2D() {
  //   this.barcodeScanService.fakeValidateUserData().subscribe((res) => {
  //     this.dyanmicDownloadByHtmlTag({
  //       fileName: '2DScan' + "_" + this.barcodeModel.ginNumber + "_" + this.datePipe.transform(this.myDate, 'ddMMyyyHHmmss') + '.txt',
  //       text: ""
  //     });
  //   });
  // }

  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);
    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  resetResults() {
    this.check = 0;
    this.enable = true;
    this.show = false;
    this.searchBarcode = [];
    this.searchBarcodeLineArr = [];
    this.oneDimenResult = [];
    this.resultArray = [];
    this.resultArray1D = [];
    this.responseMessage = '';
    this.errormsg = '';
    this.uploadFileName = '';
    this.remarks = '';
    this.barcodeModel = new Search();
    this.loaDer.nativeElement.style.display = 'none';
    this.myInputVariable.nativeElement.value = "";
    this.uploadFileName = 'No File Chosen';
    this.uploadMessage = '';
    this.shortage = false;
  }

  createGRNwithRemarks(remarks) {
    for (let i = 0; i < this.responseValidatedData.length; i++) {
      this.responseValidatedData[i].remarks = remarks;
    }
    this.barcodeScanService.createGRNDetails(this.responseValidatedData).subscribe(
    );
  }

}
