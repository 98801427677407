import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Requisition } from './requisition';
import { SearchRequisitionService } from './search-requisition.service';
import { NgForm, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SearchRequisitionResultModel } from './search-requisition-response-model';
import { RequisitionHeader } from './requisition-header';
import { RequisitionLines } from './requisition-lines';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { Concept } from '../common/concept';
import { UtilsService } from '../common/utils-service';
import { RequestParameterModel } from '../common/request-parameter-model';

import { OrderPipe } from 'ngx-order-pipe';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../common/field-name-list';
import { Batches } from '../batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import { importsave } from '../import-article-price/import-article-save';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { OrderType } from '@/common/orderType';


@Component({
  selector: 'app-search-requisition',
  templateUrl: './search-requisition.component.html',
  providers: [SearchRequisitionService],
  //styleUrls: ['./search-requisition.component.css']
  styleUrls: ['../../assets/css/bootstrap.css',
    '../../assets/css/bil-style.css',
    '../../../node_modules/font-awesome/css/font-awesome.css', './search-requisition.component.css'],
})




export class SearchRequisitionComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  @ViewChild('searchClick', { static: false }) searchClick: ElementRef;
  requisitionForm: NgForm;
  dataSaved = false;
  requistion_detail: SearchRequisitionResultModel;
  requisition_header_list: RequisitionHeader[];
  requisition_lines_list: RequisitionLines[];
  searchedRequisitions: RequisitionHeader[] = [];
  requestParameterModel: RequestParameterModel[];
  errorMessage: string;
  status: string;
  message: string;
  totalPair: string;
  totalValue: string;
  totalCost: string;
  nosRecords: string;
  uploadFileName: string = 'No Choose File';
  username: string;
  private _error: any = { isError: false, errorMessage: '' };


  profileForm: FormGroup;
  error: string;
  responseMessage: string;
  statusMessage: string = '';
  fileUpload = { status: '', message: '', filePath: '' };

  order: string = '';
  reverse: boolean = false;
  sortedCollection: any[];
  poType: any[];
  currentPage: number = 1;

  downloadUrlList: Batches[] = [];
  downloadUrl: string;
  downloadedFile: string;
  checkService: boolean = false;
  enable: boolean = false;
  coCode: any;
  userRole: string;
  req:string;
  userCode:any;
  order_type_url = 'assets/static-data/orderTypes.json';
  orderTypes: OrderType[];

  constructor(private orderPipe: OrderPipe, private fb: FormBuilder, private _SearchRequisitionService: SearchRequisitionService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private _utilsService: UtilsService, private httpClient: HttpClient) {
    this.sortedCollection = orderPipe.transform(this.searchedRequisitions, '');
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  ngOnInit() {
    this.httpClient.get(this.order_type_url).subscribe((data: OrderType[]) => {
      this.orderTypes = data;
      console.log( this.orderTypes);
      this.poType = data.filter(function (list) {
        return (list.code == "I" || list.code == "D");
      });
    });

    this.profileForm = this.fb.group({
      name: [''],
      file: [''],
      uploadKey: ['ARTICLE_DISTRIBUTION'],
      uploadedBy:['']
    });

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    let sessUsername = sessionStorage.getItem('currentUser');
    // console.log();
    var conceptQueryMas = [{ "name": "email", "value": JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.profileForm.get('uploadedBy').setValue(this.userCode);
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.userRole=JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    this._SearchRequisitionService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        let initialSearchData = this._navigationLinkParameterService.searchHeaderData;
        if(initialSearchData == undefined){
        console.log('New Search has to be made');  
        this.requisitionModel.conceptCode = null;
        this.requisitionModel.productionMonth = null;
        this.requisitionModel.productionYear = null;
        this.requisitionModel.reqStatus = null;
        this.requisitionModel.conceptJDECode = null;
        this.requisitionModel.poType = null;
        }
        else{
        console.log('Automatic Search will be performed');  
        //this.searchClick.nativeElement = null;
        this.requisitionModel = this._navigationLinkParameterService.searchHeaderData.searchData;
        this.searchedRequisitions = this._navigationLinkParameterService.searchHeaderData.resultData;
        this.totalCost = null;
        this.totalPair = null;
        this.totalValue = null;
        this.totalCost = this._navigationLinkParameterService.searchHeaderData.cost;
        this.totalPair = this._navigationLinkParameterService.searchHeaderData.pair;
        this.totalValue = this._navigationLinkParameterService.searchHeaderData.value;
        this._navigationLinkParameterService.searchHeaderData = undefined;
        }
      }
    )
    // this.requisitionModel.conceptCode = null;
    // this.requisitionModel.productionMonth = null;
    // this.requisitionModel.productionYear = null;
    // this.requisitionModel.reqStatus = null;
  }

  concepts: any;
  statuses = ['All', 'PO', 'PA', 'PP', 'PC'];
  months = [
    { id: 1, name: 'Jan' },
    { id: 2, name: 'Feb' },
    { id: 3, name: 'Mar' },
    { id: 4, name: 'Apr' },
    { id: 5, name: 'May' },
    { id: 6, name: 'Jun' },
    { id: 7, name: 'Jul' },
    { id: 8, name: 'Aug' },
    { id: 9, name: 'Sep' },
    { id: 10, name: 'Oct' },
    { id: 11, name: 'Nov' },
    { id: 12, name: 'Dec' }
  ];

  years = ['2021', '2022'];

  isValidDate: boolean;
  public noResults: boolean = false;


  requisitionModel = new Requisition();


  onSelectFile(event) {
    console.log('filename: ' + event);

    if (event.target.files.length > 0) {
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
    }
  }

  onSubmit() {
    this.responseMessage='';
    if (this.myInputVariable.nativeElement.files.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage='error';
      return false;
    }
    let newFileArray:any[] = this.uploadFileName.split('.');
    newFileArray[0] = newFileArray[0].replace(/ /g,"_");
     let newFileFormat = newFileArray[0]+'_'+ this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
     //this.profileForm.get('file').setValue(newFileFormat);
     const formData = new FormData();
     //formData.append('name', this.profileForm.get('name').value);
     formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData.append('uploadKey', this.profileForm.get('uploadKey').value);
    formData.append('uploadedBy', this.profileForm.get('uploadedBy').value);
    this.loaDer.nativeElement.style.display = 'block';

    this._SearchRequisitionService.upload(formData).subscribe(
      (response: importsave) => {
        this.responseMessage = '';
        this.statusMessage = '';
        console.log(response);
        if (response.status == "success") {
          this.responseMessage = response.message;
          this.statusMessage = 'success';
          this.enable = true;
        }
        else {
          this.responseMessage = response.message;
          this.statusMessage = 'error';
          this.enable = false;
        }
        this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.enable = false;
        this.loaDer.nativeElement.style.display = 'none'; 
      }

    );
  }

  checkViewPoDisable(reqStatus, poCount) {
    if (reqStatus != 'PC' && poCount > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  checkReqUploadDisable(reqStatus){
    let userRoleIndexOrdering = this.userRole.indexOf('Ordering');
    let userRoleIndexIT = this.userRole.indexOf('IT');
    if ((reqStatus == 'PA' || reqStatus == 'PP') && (userRoleIndexOrdering>=0 ||userRoleIndexIT>=0) ){
      return false;
    }
    else{
      return true;
    }
  }


  disableButton(reqStat, conCode) {
    var iof = this.coCode.indexOf(conCode);
    if (iof >= 0 && reqStat != 'PC') {
      return false;
    }
    else {
      return true;
    }
  }


  searchRequisitionSubmitForm(formvalue) {
    this.loaDer.nativeElement.style.display = 'block';
    this.responseMessage = '';
    this.isValidDate = true;
    let fromDate: Date = this.requisitionModel.fromPrDate;
    let toDate: Date = this.requisitionModel.toPrDate
    let fromDateStr: string;
    let toDateStr: string;
    //Validate ToPRDate and FromPRDate
    if (fromDate != null || toDate != null) {
      fromDateStr = this._utilsService.transformDate(fromDate);
      toDateStr = this._utilsService.transformDate(toDate);
      this.isValidDate = this._utilsService.validateDates(fromDateStr, toDateStr);
    }
    //Validate ToPADate and FromPADate
    fromDate = this.requisitionModel.fromPaDate;
    toDate = this.requisitionModel.toPaDate;
    if (fromDate != null || toDate != null) {
      fromDateStr = this._utilsService.transformDate(fromDate);
      toDateStr = this._utilsService.transformDate(toDate);
      this.isValidDate = this._utilsService.validateDates(fromDateStr, toDateStr);
    }

    if (this.isValidDate) {
      this.setRequisitionByRequisitionSearchCriteria(this.requisitionModel);
    }
    else {
      this._error = this._utilsService.fetchErrorMessage();
      this.errorMessage = this._error.errorMessage;
    }

  }


  setRequisitionByRequisitionSearchCriteria(requisitionModel: Requisition) {
    console.log(' Log File : SearchRequisitionComponent  Function : setRequisitionByRequisitionSearchCriteria : Requisition Line Status :  ' + requisitionModel);
    if(requisitionModel.conceptJDECode != null && requisitionModel.conceptJDECode != '' && requisitionModel.conceptJDECode != undefined){
      this.concepts.filter(concept=> concept.concInJdeE1 == requisitionModel.conceptJDECode).forEach(
        conc=> {
          requisitionModel.conceptCode = conc.concCode;
        }
      )
    }
    else{
      requisitionModel.conceptCode = undefined;
    }
    this._SearchRequisitionService.getRequisitionByRequisitionSearchCriteria(requisitionModel, this.username).subscribe(
      (res: SearchRequisitionResultModel) => {
        if (res.status != 'Failed') {
          this.searchedRequisitions = res.data;// Requisitions
          this.nosRecords = res.nosRecords;
          this.totalCost = this.calculateDecimalValue(res.totalValue) + ' ' + 'INR';
          this.totalPair = this.calculateDecimalValue(res.totalPair);
          this.totalValue = this.calculateDecimalValue(res.totalCost) + ' ' + 'INR';
          this.status = res.status;
          this.message = res.message;
          if (this.searchedRequisitions != null) {
            this.searchedRequisitions.forEach(
              requsition => {
                console.log(requsition);
                this.orderTypes.forEach(data => {
                  if (data.code === requsition.poType) {
                    requsition.poType = requsition.poType + " - " + data.description;
                  }
                })
              }
            )
          }
        }
        if (res.status === 'Failed') {
          this.responseMessage = res.message;
          this.searchedRequisitions = [];
          this.totalCost = null;
          this.totalPair = null;
          this.totalValue = null;
          this._error = { isError: false, errorMessage: '' };
          this.isValidDate = true;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err)=>{
       this.responseMessage=err;
      }
    );
    console.log(' Log File : SearchRequisitionComponent  Function : setRequisitionByRequisitionSearchCriteria : Requisition Search Result :  ' + this.searchedRequisitions);
    /*
        if (this.searchedRequisitions != null || this.searchedRequisitions.length == 0) {
          this.noResults = true;
        }
        else{
          this.noResults = false;
        }*/
  }

  onClickRequisitionNumber(index) {
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log(' absoluteIndex ' + absoluteIndex)
    this._navigationLinkParameterService.id = this.searchedRequisitions[absoluteIndex].reqNumber;
    this._router.navigate(['requisition-details']);
  }

  resetSearchRequisitionResults() {
    this.searchedRequisitions = [];
    this.noResults = false;
    this._error = { isError: false, errorMessage: '' };
    this.isValidDate = true;
    this.responseMessage = '';
    this.totalCost = null;
    this.totalPair = null;
    this.totalValue = null;
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  viewPoList(index) {
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log(' absoluteIndex ' + absoluteIndex)
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchedRequisitions[absoluteIndex]);
    this._navigationLinkParameterService.lineId = null;
    this._navigationLinkParameterService.searchHeaderData = this.searchpayload();
    this._utilsService.pageNavigationWithParam('po-list', this.requestParameterModel);
  }

  viewRequisitionDetails(index) {
    this.requestParameterModel = [];
    let requsetParameters = new Array<RequestParameterModel>();
    let jsonRequisition = JSON.stringify(this.searchedRequisitions);
    console.log(jsonRequisition);
    let jsonObject = JSON.parse(jsonRequisition);
    for (var i in jsonObject) {
      // console.log(jsonObject[i]['reqNumber']);
      let requestParameterModel = new RequestParameterModel();
      if (jsonObject[i] !== '' && jsonObject[i] !== null && jsonObject[i] !== 'undefined' && jsonObject[i]['reqNumber'] === index) {
        jsonObject[i].totalCost = this.totalCost;
        jsonObject[i].totalValue = this.totalValue;
        jsonObject[i].totalPair = this.totalPair;
        console.log(jsonObject[i]);
        this.requestParameterModel = this._utilsService.createRequestParametersModelArray(jsonObject[i]);
      }
    }
    console.log(this.requestParameterModel);
    this._navigationLinkParameterService.searchHeaderData = this.searchpayload();
    this._utilsService.pageNavigationWithParam('requisition-details', this.requestParameterModel);
  }

  searchpayload(){
    let searchNavigationHold = {
      searchData : this.requisitionModel,
      resultData : this.searchedRequisitions,
      cost : this.totalCost,
      value : this.totalValue,
      pair : this.totalPair
    }
    return searchNavigationHold;
  }

  public async downloadFile(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    this._SearchRequisitionService.getDownloadFile().subscribe(
      (res: BatchesData) => {
        this.downloadUrlList = res['configParamModels'];
        // if (this.downloadUrlList[0].paramName == 'template_url') {
        //   console.log('inside if check');
        //   this.downloadUrl = this.downloadUrlList[0].paramValue;
        //   //console.log(this.downloadUrl);
        //   this.getDownloadedFile(this.downloadUrl);
        //   return this.checkService = true;
        // }
        this.downloadUrlList.filter(cs=> cs.paramName == 'template_url').forEach(
          each => {
            this.downloadUrl = each.paramValue;
          }
        )
        this.getDownloadFile(this.downloadUrl);
        return this.checkService = true;
      }
    );
  }
  public async getDownloadFile(downloadUrl): Promise<void> {

    let fullDownloadUrl: string = downloadUrl + 'Upload_Distribution_Master_Template.xls';
    const file = await this.httpClient.get<Blob>(fullDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    console.log('downloaded:: ' + blob);
    const url = window.URL.createObjectURL(blob);
    //console.log('URL ::'+url);
    var anchor = document.createElement("a");
    anchor.download = "Upload_Distribution_Master_Template.xls";
    anchor.href = url;
    anchor.click();
    /*var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }*/
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none'; 

  }

  resetSearchPurchaseOrderResults() {
    this.uploadFileName = '';
    this.responseMessage = '';
    this.loaDer.nativeElement.style.display = 'none';
    this.myInputVariable.nativeElement.value = "";
  }

  generateImportPo() {
    this.responseMessage = '';
    console.log(this.req);
    let uploadkeyValues: any =
    {
      "uploadKeyValues": "ARTICLE_DISTRIBUTION",
      "reqNumber": this.req,
      "userId":this.userCode
    };
    this.loaDer.nativeElement.style.display = 'block';
    let importpo: any = this._SearchRequisitionService.getGenerate(uploadkeyValues).subscribe(
      (response: importsave) => {
        console.log(JSON.stringify(response));
        if (response.status == "success") {
          this.responseMessage = response.message;
          this.statusMessage = 'success';
        }
        else {
          this.responseMessage = response.message;
          this.statusMessage = 'error';
        }
        this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err) => {
        this.responseMessage = err;
        this.loaDer.nativeElement.style.display = 'none'; 
      }
    );
    console.log('generate');
    console.log(importpo);
    this.enable = false;
  }

  calculateDecimalValue(value): string {
    let val = value.toString();
    let index = val.indexOf('.');
    let afterDecimal;
    if (index >= 0) {
      let valueList = val.split('.');
      if(valueList[1].length >= 2){
      afterDecimal = valueList[1].substr(0, 2);
      }
      else{
      afterDecimal = valueList[1] + '0';  
      }
      let resultValue = valueList[0] + '.' + afterDecimal;
      console.log('result' + resultValue);
      return resultValue;
    }
    else {
      return val;
    }
  }

  reqNumberButton(value) {
    this.uploadFileName = null;
    this.req = null;
    console.log(value);
    this.req = value;
    this.myInputVariable.nativeElement.value = "";
  }
}
