import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../common/bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class ViewReportStatusService {
  report_status_url: string = BilApiUrl.REPORT_STATUS;

  constructor(private _http: HttpClient) { }

  getReportData(param) {
    return this._http.post(this.report_status_url, param);
  }

}
