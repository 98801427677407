import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { NavigationLinkParameterService } from '@/common/navigation-link-parameters-service';
import { Router } from '@angular/router';
import { ViewGrnService } from './view-grn.service';

@Component({
  selector: 'app-view-grn',
  templateUrl: './view-grn.component.html',
  styleUrls: ['./view-grn.component.css','../../../../assets/css/bootstrap.css','../../../../assets/css/rds-bil-style.css']
})
export class ViewGrnComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  viewGRNPoList: any;
  // viewGRNPoList: any[] = [];
  responseShortageData: any[] = [];
  // singlePOEntry: any;
  grnassormentArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17','18','19'];
  artnumber: any;
  assortArray: any[];
  groupCode: string;
  userName: string;
  viewGRNLineDetails : any[]=[];
  headerData : any;
  uniqueKeyArray:any[];
  totalCartonQty:any;
  searchType='line';
  pageNumber=1;
  itemsPerPage=10;
  totalItems:any;
  currentPage : number = 1;

  constructor(private navigationLinkParameterService: NavigationLinkParameterService,private router: Router,private viewGrnService: ViewGrnService) { }

  ngOnInit() {
    const sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.viewGRNPoList = this.navigationLinkParameterService.grnNOdetails;
    // console.log(this.viewGRNPoList);
    let lineData = { 
      
      "grnNumber": this.viewGRNPoList.grnNumber,
      "userCode" :  this.userName,
      "searchType":this.searchType,
      "pageNumber":this.pageNumber,
      "pageSize":this.itemsPerPage
    }
    let cartonQty=0;
        this.uniqueKeyArray=[];
        this.totalCartonQty=0;
    this.viewGrnService.getGrnLineDetails(lineData).subscribe(
      (response : any)=>
      {
        this.viewGRNLineDetails = response.dataList;
        this.totalItems=response.recordCount;
        this.viewGRNLineDetails.forEach(
          grnData => {
            let key=grnData.grnNumber;
            cartonQty=((grnData.cartonQty!=null)?parseInt(grnData.cartonQty):0);
            // console.log(cartonQty);
            if(grnData.consignorType === 'SUPPLIER' || grnData.consignorType === 'FACTORY'){
              this.totalCartonQty=this.totalCartonQty+cartonQty;
            }else{
              this.totalCartonQty=cartonQty;   
            }            
          }
        );
        // console.log(this.totalCartonQty);
      });
      this.navigationLinkParameterService.grndetails='';
      if (this.navigationLinkParameterService.searchGRNHeaderData === undefined) {
        // console.log('New Search has to be made');
      } else {
        // console.log('Automatic Search will be performed');
        this.headerData = this.navigationLinkParameterService.searchGRNHeaderData;
        this.navigationLinkParameterService.searchGRNHeaderData = undefined;
      }
    // this.viewGRNPoList = this.navigationLinkParameterService.grnIDdetails.goodsReceiptNoteAssortments;
  }

  onClickBack() {
    this.navigationLinkParameterService.searchGRNHeaderData = this.headerData;
    this.router.navigate(['/rds-search-grn']);
  }

  getPage(page){
    this.loaDer.nativeElement.style.display = 'block';
       this.currentPage=page;

       let lineData = { 
      
        "grnNumber": this.viewGRNPoList.grnNumber,
        "userCode" :  this.userName,
        "searchType":this.searchType,
        "pageNumber":page,
        "pageSize":this.itemsPerPage
      }
      let cartonQty=0;
          this.uniqueKeyArray=[];
          this.totalCartonQty=0;
      this.viewGrnService.getGrnLineDetails(lineData).subscribe(
        (response : any)=>
        {
          this.viewGRNLineDetails = response.dataList;
          this.viewGRNLineDetails.forEach(
            grnData => {
              let key=grnData.grnNumber;
              cartonQty=((grnData.cartonQty!=null)?parseInt(grnData.cartonQty):0);
              // console.log(cartonQty);
              if(grnData.consignorType === 'SUPPLIER' || grnData.consignorType === 'FACTORY'){
                this.totalCartonQty=this.totalCartonQty+cartonQty;
              }else{
                this.totalCartonQty=cartonQty;   
              }            
            }
          );
          // console.log(this.totalCartonQty);
        });
  
  }

  getShortageAssotList(artnumber,index) {
    // console.log('in get shortage');
    // this.responseShortageData = [];
    // // this.singlePOEntry = this.viewGRNPoList[index];
    // // console.log(this.singlePOEntry);
    // this.responseShortageData = this.viewGRNPoList.goodsReceiptNoteAssortments;

    // console.log(artnumber);
    this.artnumber = artnumber;
    this.assortArray = [];
    let firstletter = artnumber.charAt(0);
    // console.log(this.viewGRNPoList);
    // console.log(this.viewGRNLineDetails);
    // console.log(this.viewGRNLineDetails[index]);
    // const index = this.viewGRNPoList.findIndex((x) => x.artNumber === artnumber);
    // console.log(index);
    // if (index != -1) {
      // this.assortData = this.viewGRNPoList[index];
      // console.log(this.assortData);
      let details = [{ "name": "letterOfArticle", "value": firstletter }];
      this.viewGrnService
      .getSizeDetails(details)
      .subscribe(
        (response: any) => {
          if (response.status == "Success") {
            this.groupCode = response.dataList[0].groupCode;
            this.assortArray = [];
            response.dataList.forEach(data => {
              let assortmentArray = this.viewGRNLineDetails[index].goodsReceiptNoteAssortments;
              // console.log(assortmentArray); console.log('assortmentArray');
              let found = assortmentArray.findIndex(element => element.articleSize == data.artSize);
              if (found !== -1) {
                let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].articleSize, sizeWiseRcvQty: assortmentArray[found].pairQty, sizeWiseSrtQty: assortmentArray[found].shortagePairQty };
                this.assortArray.push(assortmentData);
              }
            });
          }
        },
        (err) => {
          this.assortArray = [];
        }
      );
    // }
  }

}
