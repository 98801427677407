import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../../common/rds-bil-api-url';
import { BilApiUrl } from "../../../common/bil-api-url";

@Injectable({
  providedIn: 'root'
})
export class ViewInvoiceService {
  // invoice_search_url: string = RdsBilApiUrl.RDC_SEARCH_INVOICE;
  completeShipmentUrl: string = RdsBilApiUrl.COMPLETE_SHIPMENT;
  stopShipmentUrl: string = RdsBilApiUrl.STOP_SHIPMENT;
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  invoice_search_url: string = RdsBilApiUrl.RDC_SEARCH_INVOICE;
  constructor(private http: HttpClient) { }

  // getInvoiceLineDetails(challanInvoice) {
  //   let details =
  //     [{
  //       "name": "challanInvoiceNumber",
  //       "value": challanInvoice
  //     }
  //     ]

  //   return this.http.post(this.invoice_search_url, details);
  // }

  completeShipment(details: any) {
    console.log('updating parameter' + JSON.stringify(details));
    return this.http.post(this.completeShipmentUrl, details);
  }
  stopShipment(details: any) {
    console.log('updating parameter' + JSON.stringify(details));
    return this.http.post(this.stopShipmentUrl, details);
  }
  getSizeDetails(details) {
    return this.http.post(this.size_url, details);
  }
  getInvoiceBySearchCriteria(invoiceNum) {
    return this.http.post(this.invoice_search_url, invoiceNum);

  }
}
