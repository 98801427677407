import { Component, OnInit } from '@angular/core';
import { ManageCcListService } from './manage-cc-list.service';
import { CcListModel } from './manage-cc-list-model';
import { CC_list } from './CC_list';
import { Concept } from '../common/concept';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { CcListSearchDetails } from './manage-cc-list-detail';
import { Router, RouterModule } from '@angular/router';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../common/field-name-list';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '@/common/utils-service';

@Component({
  selector: 'app-manage-cc-list',
  templateUrl: './manage-cc-list.component.html',
  styleUrls: ['./manage-cc-list.component.css']
})
export class ManageCcListComponent implements OnInit {
  ccListParameter = new CcListModel();
  requestParameterModel: RequestParameterModel[];
  username: string;
  concepts: Concept[];
  conficCcType: CC_list[] = [];
  ccTypeList: CC_list[] = [];
  // ccTypeList: any = [{ name:'Merchandising', value: 'M'},
  //                { name:'Replenishment', value: 'R'},
  //                { name:'Sourcing', value: 'S'}];
  ccResponseList: CcListSearchDetails[] = [];
  responseMessage: string;
  currentPage: number = 1;
  itemsPerPage=10;
  pageNumber=0;
  totalItems: any;

  constructor(private _manageCcListService: ManageCcListService,private utilsService: UtilsService,private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this._manageCcListService.getUserConcept().subscribe(
      (data: any) => {
        console.log(data);
        this.concepts = data;
        if (this._navigationLinkParameterService.ccSearchData == undefined) {
          console.log('New Search has to be made');
          this.ccListParameter.conceptCode = null;
          this.ccListParameter.ccType = null;
         
        }
        else {
          console.log('Automatic Search will be performed');
          this.ccListParameter = this._navigationLinkParameterService.ccSearchData.searchData;
          this.ccResponseList = this._navigationLinkParameterService.ccSearchData.resultData;
          this._navigationLinkParameterService.ccSearchData = undefined;
        }
      },
      (err) => {
        this.responseMessage = err;
      }
    )
    this.ccResponseList = [];

    // this._manageCcListService.getPartyNumber().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.parties = data.partyNumberList;
    //   }
    // )

    this._manageCcListService.getCcType().subscribe(
      (response: CC_list) => {
        this.conficCcType.push(response);
        this.conficCcType[0].configParamModels.forEach(
          element => {
            if (element.paramName == 'PARTY_MAIL_CC_TYPE') {
              console.log(element);
              this.ccTypeList.push(element);
            }
          });
          console.log(this.ccTypeList);
      }
    )

  }

  onClickSearch(value,flag) {
    this.responseMessage = '';
    
    if(flag == 'FALSE'){
      this.pageNumber=0;
      this.currentPage=1;
      if (this.ccListParameter.ccType == null) {
        this.ccListParameter.ccType = undefined;
      }
      if (this.ccListParameter.conceptCode == null) {
        this.ccListParameter.conceptCode = undefined;
      }
    }
    else{
      this.ccListParameter=value;
    }
    
      this.ccListParameter.pageSize=this.itemsPerPage;
      this.ccListParameter.pageNumber=this.pageNumber; 
      this.currentPage=this.currentPage;
      
      console.log(this.ccListParameter);


      this.requestParameterModel = this.utilsService.createRequestParametersModelArray(this.ccListParameter);
      console.log(this.requestParameterModel.length);
      if (this.requestParameterModel.length > 2) {
      this._manageCcListService.getCcListBySearch(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          this.ccResponseList = response.data;
          // this.currentPage = this.ccListParameter.pageNumber;
          this.totalItems=response.count;
          console.log(this.ccResponseList);
          if (this.ccResponseList == null) {
            console.log('No Data');
            this.responseMessage = 'Data Not Found';
          }
        }
      )
      }else{
        this.responseMessage = 'Please give a search criteria';
      }
  }


  edit(index){
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    if (this.ccResponseList[absoluteIndex].isActive == 'Y') {
       
      console.log('edit option of cc list');
      this.requestParameterModel = [];
      this.requestParameterModel = this.utilsService.createRequestParametersModelArray(this.ccResponseList[absoluteIndex]);
      this._navigationLinkParameterService.ccSearchData = this.searchPayload();
      this.utilsService.pageNavigationWithParam('manage-cc-list-edit', this.requestParameterModel);



    }
  }

  searchPayload(){
    let searchNavigationHold = {
      searchData: this.ccListParameter,
      resultData: this.ccResponseList
    }
    return searchNavigationHold;
  }

  resetSearch() {
    this.responseMessage = '';
    this.ccResponseList = [];
    this.ccListParameter = new CcListModel();
  }

  checkFlag(flag) {
    if (flag != 'N') {
      return true;
    }
    else {
      return false;
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  getPage(page){
    this.pageNumber=parseInt(page)-1;
    this.currentPage=page;
    this.onClickSearch(this.ccListParameter,'TRUE');

}

  changeFlagStatus(index) {
    this.responseMessage = '';
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log('index ---> '+absoluteIndex);
    if (this.ccResponseList[absoluteIndex].isActive == 'Y') {
      this.ccResponseList[absoluteIndex].isActive = 'N';
    }
    else {
      this.ccResponseList[absoluteIndex].isActive = 'Y';
    }
    let details = [{
      "partyMailSeq":this.ccResponseList[absoluteIndex].partyMailSeq,
      "conceptCode":this.ccResponseList[absoluteIndex].conceptCode,
      "ccEmail":this.ccResponseList[absoluteIndex].ccEmail,
      "ccType": this.ccResponseList[absoluteIndex].ccType,
      "isActive": this.ccResponseList[absoluteIndex].isActive,
      "createdBy":this.ccResponseList[absoluteIndex].createdBy,
      "lastUpdatedBy":this.ccResponseList[absoluteIndex].lastUpdatedBy,
    }];
    this._manageCcListService.updateCcFlagStatus(details).subscribe( //////Active flag
      (response: any) => {
        console.log(JSON.stringify(response));
        if (response.status == 200) {
          this.responseMessage = 'CC Flag Status Updated';
        }
        else {
          this.responseMessage = 'Something went wrong';
        }
      }
    )
  }
}
