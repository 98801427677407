import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
@Injectable({
  providedIn: 'root'
})
export class SearchBatchStatusService {
  urlBatch: string = BilApiUrl.BATCH_STATUS;
  rdsbatchlisturl: string = RdsBilApiUrl.RDS_BATCH_LIST;
  constructor(private _http: HttpClient) { }

  getBatches(value: any) {
    return this._http.post(this.urlBatch, value);
  }
  getRDSBatches(batchInput: any) {
    console.log(this.rdsbatchlisturl);
    console.log(batchInput);
    return this._http.post(this.rdsbatchlisturl, batchInput);
  }
}