import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SearchCreditNoteService } from "./search-credit-note.service";

@Component({
  selector: 'app-search-credit-note',
  templateUrl: './search-credit-note.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css',
    '../../../assets/css/rds-bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css',
    './search-credit-note.component.css']
})
export class SearchCreditNoteComponent implements OnInit {
  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  @ViewChild('closeBtn1', { static: false }) closeBtn1: ElementRef;

  userCode: string;
  userEmail: string;
  responseMessage: string;
  responseMessage1: string;
  shortageAdjustmentList: any[];
  stockAdjustmentForm: FormGroup;
  assortData: any = {};
  CreditedTo: any[];
  status: any[];
  debitCreditNoteId: any;
  invoiceNumber: any;
  txnId: any;
  remarks: string;
  selectedRow: any[];
  artNumber: string;
  assortArray: any[];
  groupCode: string;
  listObj: any = [];
  approveBtn:Boolean = false;

  constructor(
    private fb: FormBuilder,
    private SearchCreditNoteService: SearchCreditNoteService
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.userEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail;
    //this.userCode = "user.test4";
    this.status = [
      {
      name: "APPROVED",
      value: "APPROVED"
      },
      {
        name: "PENDING_APPROVAL",
        value: "PENDING_APPROVAL"
      }
    ];
    this.CreditedTo = [
      {
      name: "RDC",
      value: "RDC"
      },
      {
      name: "DEPOT",
      value: "DEPOT"
      },
      {
        name: "SUPPLIER",
        value: "SUPPLIER"
      },
      {
        name: "FACTORY",
        value: "FACTORY"
      },
      {
        name: "STORE",
        value: "STORE"
      }
    ];
    this.buildForm();
  }

  buildForm(): void {
    this.stockAdjustmentForm = this.fb.group({
      articleNumber: [""],
      invoiceNumber: [""],
      bataWeekFrom: ["", [Validators.maxLength(6), Validators.minLength(6)]],
      bataWeekTo: ["", [Validators.maxLength(6), Validators.minLength(6)]],
      approvalStatus: [null],
      creditedTo: [null],
      userCode: [this.userCode],
      txnType: ["CREDIT"],
    });
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.selectedRow == undefined) {
        this.selectedRow = [];
      }
      this.loaDer.nativeElement.style.display = "none";
    }, 100);
  }

  onReset() {
    this.stockAdjustmentForm.reset();
    setTimeout(() => {
      this.buildForm();
    }, 500);
    this.shortageAdjustmentList = [];
    this.listObj = [];
    this.responseMessage = "";
  }

  searchStockAdjustment() {
    setTimeout(() => {
      this.responseMessage = "";
    }, 3000);
    console.log(this.stockAdjustmentForm);
    if (this.stockAdjustmentForm.controls["articleNumber"].value == '' && this.stockAdjustmentForm.controls["invoiceNumber"].value == '' && this.stockAdjustmentForm.controls["bataWeekFrom"].value == '' && this.stockAdjustmentForm.controls["bataWeekTo"].value == '' && this.stockAdjustmentForm.controls["approvalStatus"].value == null && this.stockAdjustmentForm.controls["creditedTo"].value == null) {
      this.responseMessage = "Please select any search criteria.";
      return false;
    } else {
      this.responseMessage = "";
      this.shortageAdjustmentList = [];
	    this.selectedRow = [];
      this.listObj = [];
      this.loaDer.nativeElement.style.display = "block";
      this.SearchCreditNoteService.getStockAdjustmentDetails(
        this.stockAdjustmentForm.value
      ).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          if (response.dataList != null && response.dataList.length !== 0) {
            this.shortageAdjustmentList = response.dataList;    
            for(let x=0;x<response.dataList.length;x++) {

              if ( this.listObj.findIndex(i=>i.invoiceNumber == response.dataList[x]['invoiceNumber']) == -1 ) {
                this.listObj.push({
                  "invoiceNumber":response.dataList[x]['invoiceNumber'],
                  "approvalStatus":response.dataList[x]['approvalStatus'],
                  "debitCreditToDate":response.dataList[x]['debitCreditToDate'],
                  "grnNumber":response.dataList[x]['grnNumber'],
                  "creditedTo":response.dataList[x]['creditedTo'],
                  "debitCreditToCode":response.dataList[x]['debitCreditToCode'],
                  "debitCrditToName":response.dataList[x]['debitCrditToName'],
                  "debitCreditTxnNumber":response.dataList[x]['debitCreditTxnNumber'],
                  "children":[]
                });
              }
              let index = this.listObj.findIndex(i=>i.invoiceNumber == response.dataList[x]['invoiceNumber']) ;
              // console.log("index", index);
              this.listObj[index]['children'].push(response.dataList[x]);
            }
            console.log(this.listObj);

          } else {
            this.responseMessage = "No Data Found";
          }
        },
        (err) => {
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.loaDer.nativeElement.style.display = "none";
        }
      );
    }
  }

  characterCheck(event, name) {
    var regExpr = /[^a-zA-Z0-9-. ]/g;
    var userText = event.target.value;
    if (userText != "") {
      if (name == "articleNumber") {
        this.stockAdjustmentForm.controls["articleNumber"].setValue(
          userText.replace(regExpr, "")
        );
        if (userText.length > 7) {
          var res = userText.substr(0, 7);
          this.stockAdjustmentForm.controls["articleNumber"].setValue(res);
        }
      }
      if (name == "invoiceNumber") {
        this.stockAdjustmentForm.controls["invoiceNumber"].setValue(
          userText.replace(regExpr, "")
        );
      }
    }
  }

  showHideDiv(index) {
    let arr_index = this.selectedRow.indexOf(index);
    if (arr_index !== -1) {
      this.selectedRow.splice(arr_index, 1);
      document.getElementById('showHideDiv' + index).classList.remove("active");
    } else {
      this.selectedRow.push(index);
      document.getElementById('showHideDiv' + index).classList.add("active");
    }
  }

  assortmentDataPopup(debitCreditNoteId, artNumber) {
    this.assortArray = [];
    let firstletter = artNumber.charAt(0);
    let index = this.shortageAdjustmentList.findIndex(
      (x) => x.debitCreditNoteId == debitCreditNoteId
    );
    if (index != -1) {
      this.assortData = this.shortageAdjustmentList[index];
      this.artNumber = artNumber;
      let details = [{ "name": "letterOfArticle", "value": firstletter }];
      this.SearchCreditNoteService
        .getSizeDetails(details)
        .subscribe(
          (response: any) => {
            if (response.status == "Success") {
              this.groupCode = response.dataList[0].groupCode;
              this.assortArray = [];
              response.dataList.forEach(data => {
                var assortmentArray = this.assortData.debitCreditTxnAssortmentEntities;
                var found = assortmentArray.findIndex(element => element.artSize == data.artSize);
                if (found != -1) {
                  var assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWiseQty: assortmentArray[found].pairQty };
                  this.assortArray.push(assortmentData);
                }
              });
            }
          },
          (err) => {
            this.assortArray = [];
          }
        );
    }
  }

  approve(invoiceNumber, txnId) {
    this.invoiceNumber = invoiceNumber;
    this.txnId = txnId;
    this.remarks = '';
  }

  approvesStatusChange(status) {
    this.approveBtn=true;
    if (this.remarks == '') {
      this.approveBtn=false;
      this.responseMessage1 = "Please enter remarks.";
      setTimeout(() => {
        this.responseMessage1 = "";
      }, 3000);
    } else {
      this.loaDer.nativeElement.style.display = "block";
      var details = [
        {
          "invoiceNumber": this.invoiceNumber,
          "txnType": "CREDIT",
          "approvedRejectedBy": this.userCode,
          "approvalRemarks": this.remarks
        }
      ];
      console.log(details);
      this.SearchCreditNoteService.pendingShortageApprovalReject(details).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          if (response.status == "success") {
            this.responseMessage = "Status approved successfully.";
            this.approveBtn=false;
            this.closeBtn.nativeElement.click();
            //setTimeout(() => {              
              this.searchStockAdjustment();
              //this.onReset();
            //}, 2000);
            
          } else {
            this.approveBtn=false;
            this.closeBtn.nativeElement.click();
            this.responseMessage = "Something Error Happened.";
          }
        },
        (err) => {
          this.approveBtn=false;
          this.closeBtn.nativeElement.click();
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.approveBtn=false;
          this.closeBtn.nativeElement.click();
          this.loaDer.nativeElement.style.display = "none";
        }
      );
    }
  }

  print(debitCreditTxnNumber) {
    this.loaDer.nativeElement.style.display = "block";
    var details = 
    {
      "reportInputRequestList": [      
        {      
          "name": "userId",      
          "value": this.userCode      
        },      
        {      
          "name": "reportName",      
          "value": "Print Credit Note"      
        },      
        {      
          "name": "reportKey",      
          "value": "PCN"      
        },      
        {      
          "name": "userEmail",      
          "value": this.userEmail      
        },      
        {      
          "name": "debitCreditTxnNumber",      
          "value": debitCreditTxnNumber      
        }      
      ],      
      "reportFormatType": "excel"      
    };
    console.log(details);
    this.SearchCreditNoteService.printCreditNote(details).subscribe(
      (response: any) => {
        this.loaDer.nativeElement.style.display = "none";
        if (response.status == "Success") {
          setTimeout(() => {              
            this.responseMessage = "Print done successfully.";
            let x = document.createElement('a');
            x.href = response.message;
            x.click();
          }, 2000);
        } else {
          setTimeout(() => {              
            this.responseMessage = "Something Error Happened.";
          }, 2000);          
        }
      },
      (err) => {
        setTimeout(() => {              
          this.responseMessage = err;
        }, 2000);        
        this.loaDer.nativeElement.style.display = "none";
      },
      () => {
        this.loaDer.nativeElement.style.display = "none";
      }
    );
  }

}
