import { BilApiUrl } from '@/common/bil-api-url';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class ViewGrnService {
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  grnSearchUrl = RdsBilApiUrl.SEARCH_GRN;
  
  constructor(private http: HttpClient) { }

  getSizeDetails(details) {
    return this.http.post(this.size_url, details);
  }
  
  getGrnLineDetails(data) {
    return this.http.post(this.grnSearchUrl, data);

  }
}
