import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InvoiceService } from './invoice.service';
// import { uselessPipe } from '../invoice/pipe';
// declare var require: any
// const FileSaver = require('file-saver');

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', './invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  userCode: string;
  userEmail: string;
  searchInvoiceDetails: any;
  searchInvoiceLineDetails: any[] = [];

  resultArray: any = [];

  itnRsnSerialNumber: string;
  itoStnNumber: string;
  transValue: number= 0.0;
  shopDetails: any;
  shopDetailsParty: any;
  from = '';
  to = '';
  concatAddress = '';
  totalArtQty=0;
  sumtotalMrpValue=0.0;
  totalTransValue=0.0;
  totalAmnt=0.0;
  responseMessage: string;
  responseMessage1: string;
  currentPage:number=1;
  itemsPerPage:number=10;
  isPrintDisabled: boolean = false;
  key:any;
  report:any;
  printInvoiceMessage:string;
  flag:number=0;

  assormentArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17','18','19'];

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private navigationLinkParameterService: NavigationLinkParameterService, private invoiceService: InvoiceService, private httpClient: HttpClient) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.userEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail;
   }

  ngOnInit() {
    this.itnRsnSerialNumber = this.navigationLinkParameterService.invoiceDetails.itnRsnSerialNumber;
    this.itoStnNumber = this.navigationLinkParameterService.invoiceDetails.itoStnNumber;
    // this.transValue = (this.navigationLinkParameterService.invoiceDetails.invoiceLinesList[0].artCost) *
    // (this.navigationLinkParameterService.invoiceDetails.invoiceLinesList[0].artQty);

    this.searchInvoiceDetails = this.navigationLinkParameterService.invoiceDetails;
    this.searchInvoiceLineDetails = this.navigationLinkParameterService.invoiceDetails.invoiceLinesList;

    if((this.searchInvoiceDetails.challanInvoiceNumber.startsWith('NV') || this.searchInvoiceDetails.challanInvoiceNumber.startsWith('CD') || this.searchInvoiceDetails.challanInvoiceNumber.startsWith('CI') || this.searchInvoiceDetails.challanInvoiceNumber.startsWith('DD') || this.searchInvoiceDetails.challanInvoiceNumber.startsWith('DI')) && this.searchInvoiceDetails.stockType == 'CLAIMED'){
      this.key = "NIL_STOCK";
      this.report = "NilStock";
      this.flag = 1;
    }else{
      this.key = 'PRINT_INVOICE';
      this.report = "PrintInvoice";
      this.flag = 0;
    }
    if(this.key == 'PRINT_INVOICE' ){
      if((this.searchInvoiceDetails.destGstin == this.searchInvoiceDetails.sourceGstin) && (this.searchInvoiceDetails.sourceState == this.searchInvoiceDetails.destState)){
      this.isPrintDisabled = false;
    }else{
      this.isPrintDisabled = true;
    }
  }else{
    this.isPrintDisabled = false;
  }
  if (this.searchInvoiceDetails.invoiceType === 'RETURN_SUPPLIER' || this.searchInvoiceDetails.invoiceType === 'SHORTAGE_SUPPLIER' || (this.searchInvoiceDetails.partyNumber !='' && this.searchInvoiceDetails.partyNumber !='000')) {
    this.getPartyMasterData(this.searchInvoiceDetails.partyNumber);
   } else {
      this.getShopDetails(this.searchInvoiceDetails.destShopCode, 'to');
    }
    this.getShopDetails(this.searchInvoiceDetails.sourceShopCode, 'from');
    console.log(this.searchInvoiceDetails);
    if(this.searchInvoiceLineDetails !== null){
    for (let i = 0; i < this.searchInvoiceLineDetails.length; i++) {
      this.resultArray[i] = [];
      this.resultArray[i].sizes = [];
      for (let j = 0; j < this.assormentArray.length; j++) {
        // console.log(this.searchInvoiceLineDetails[i].invoiceLineAssortments);
        if (this.searchInvoiceLineDetails[i].invoiceLineAssortments.length !== 0) {
          let index = this.searchInvoiceLineDetails[i].invoiceLineAssortments.findIndex((x) => x.sizeLabel === this.assormentArray[j]);
          console.log(index);
          let obj = {};
          if (index > -1) {
            obj['pairQty'] = this.searchInvoiceLineDetails[i].invoiceLineAssortments[index].pairQty;
          } else {
            obj['pairQty'] = '';
          }
          this.resultArray[i].sizes.push(obj);
        } else {
          let obj = {};
          obj["pairQty"] = '';
          this.resultArray[i].sizes.push(obj);
        }
      }
      this.searchInvoiceLineDetails[i].transValue = 0;
      if(this.searchInvoiceDetails.stockType == 'CLAIMED'){
        this.searchInvoiceLineDetails[i].invoiceLineAssortments.forEach(
          assortData => {
            if(assortData.invoiceAssortmentClaimSlipModels.length > 0){
              assortData.invoiceAssortmentClaimSlipModels.forEach(
                claim => {
                  this.searchInvoiceLineDetails[i].transValue = Number(this.searchInvoiceLineDetails[i].transValue) + Number(claim.pairs * claim.txnPrice);
                }
              );
            }
          }
        );
      } else{
        this.searchInvoiceLineDetails[i].transValue=this.searchInvoiceLineDetails[i].artQty * this.searchInvoiceLineDetails[i].artCost;
      }
      console.log("transe valur for :->"+this.searchInvoiceLineDetails[i].transValue);
      this.totalTransValue=this.totalTransValue+parseFloat(this.searchInvoiceLineDetails[i].transValue);
      this.totalArtQty=this.totalArtQty+parseInt(this.searchInvoiceLineDetails[i].artQty);
      this.sumtotalMrpValue=this.sumtotalMrpValue+parseFloat(this.searchInvoiceLineDetails[i].totalMrpValue);
      this.totalAmnt=this.totalAmnt+parseFloat(this.searchInvoiceLineDetails[i].totalAmnt);
    }
  }
    this.totalAmnt=parseFloat(this.totalAmnt.toFixed(2));

    console.log(this.sumtotalMrpValue);
    console.log(this.searchInvoiceDetails);
    console.log(this.resultArray);
  }

  ConvertToInt(val) {
    return parseFloat(val);
    }

  onClickBack() {
    this.router.navigate(['/rds-search-invoice']);
  }

  getShopDetails(shopcode, callfor) {
    const searchData = [{name: 'shopNo', value: shopcode}];
    this.concatAddress = '';
    this.from = '';
    this.to = '';
    console.log('concatAddress'+this.concatAddress);
    this.invoiceService.getShopDetails(searchData).subscribe(
      (data: any) => {
        if(data.dataList != null && data.dataList.length !== 0){
          this.shopDetails = data.dataList[0];
          if(this.shopDetails.shopName !== '' && this.shopDetails.shopName !== null){
            this.concatAddress = this.concatAddress + ' ' + this.shopDetails.shopName;
          }
          if(this.shopDetails.shopAddress1 !== '' && this.shopDetails.shopAddress1 !== null){
            this.concatAddress = this.concatAddress + ' ' + this.shopDetails.shopAddress1;
          }
          if(this.shopDetails.shopAddress2 !== '' && this.shopDetails.shopAddress2 !== null){
            this.concatAddress = this.concatAddress + ' ' + this.shopDetails.shopAddress2;
          }
          if(this.shopDetails.shopAddress3 !== '' && this.shopDetails.shopAddress3 !== null){
            this.concatAddress = this.concatAddress + ' ' + this.shopDetails.shopAddress3;
          }
          if(this.shopDetails.shopAddress4 !== '' && this.shopDetails.shopAddress4 !== null){
            this.concatAddress = this.concatAddress + ' ' + this.shopDetails.shopAddress4;
          }
          if(this.shopDetails.shopAddress5 !== '' && this.shopDetails.shopAddress5 !== null){
            this.concatAddress = this.concatAddress + ' ' + this.shopDetails.shopAddress5;
          }
          if(this.shopDetails.shopAddress6 !== '' && this.shopDetails.shopAddress6 !== null){
            this.concatAddress = this.concatAddress + ' ' + this.shopDetails.shopAddress6;
          }
          if(callfor === 'from'){
            this.from = this.concatAddress;
            this.concatAddress='';
            console.log(this.from);
          }else{
            this.to = this.concatAddress;
            this.concatAddress='';
            console.log(this.to);
          }
        }else{
          if(callfor === 'from'){
            this.from = data.message;
            console.log(this.from);
          }else{
            this.to=data.message;
          }
        }
        console.log(this.shopDetails);
    });
  }

  getPartyMasterData(shopcode) {
    const searchData1 = [{name: 'partyNo', value: shopcode}];
    this.to = '';
    this.invoiceService.getPartyMasterData(searchData1).subscribe(
      (data: any) => {
        console.log(data);
        if(data.dataList != null && data.dataList.length !== 0){
          this.shopDetailsParty = data.dataList[0];
          if(this.shopDetailsParty.partyFullName1 !== '' && this.shopDetailsParty.partyFullName1 !== null){
            this.to = this.to + ' ' + this.shopDetailsParty.partyFullName1;
          }
          if(this.shopDetailsParty.partyAddress1 !== '' && this.shopDetailsParty.partyAddress1 !== null){
            this.to = this.to + ' ' + this.shopDetailsParty.partyAddress1;
          }
          if(this.shopDetailsParty.partyAddress2 !== '' && this.shopDetailsParty.partyAddress2 !== null){
            this.to = this.to + ' ' + this.shopDetailsParty.partyAddress2;
          }
          if(this.shopDetailsParty.partyAddress3 !== '' && this.shopDetailsParty.partyAddress3 !== null){
            this.to = this.to + ' ' + this.shopDetailsParty.partyAddress3;
          }
          if(this.shopDetailsParty.partyAddress4 !== '' && this.shopDetailsParty.partyAddress4 !== null){
            this.to = this.to + ' ' + this.shopDetailsParty.partyAddress4;
          }
          if(this.shopDetailsParty.partyAddress5 !== '' && this.shopDetailsParty.partyAddress5 !== null){
            this.to = this.to + ' ' + this.shopDetailsParty.partyAddress5;
          }
        }else{
          this.to = data.message;
        }
        console.log(this.shopDetailsParty);
    });
  }

  printInvoice(challanInvoiceNumber){
    this.printInvoiceMessage = '';
    if(this.searchInvoiceDetails.einvoiceProcessed == 'N' || this.searchInvoiceDetails.einvoiceProcessed == 'Y'){
    let printRequestData={
      "reportFormatType": "pdf",  
      "reportInputRequestList": [  
      {"name": "userId", "value": this.userCode},  
      {"name": "reportName", "value": this.flag == 1?'NilStock':'PrintInvoice'},
      {"name": "reportKey", "value": this.flag == 1?'NIL_STOCK':'PRINT_INVOICE'}, 
      {"name": "userEmail", "value": this.userEmail},
      {"name": "invoiceNo", "value": challanInvoiceNumber}
      ]}

    this.invoiceService.printInvoice(printRequestData,this.key).subscribe(
      (response: any) => {
        console.log(response);
        this.loaDer.nativeElement.style.display = "none";
        if (response.status == "Success") {           
            this.responseMessage = "Print done successfully.";
            this.getDownloadFile(response.message); 
        } else {         
            this.responseMessage = "Something Error Happened.";         
        }
      },
      (err) => {
        setTimeout(() => {              
          this.responseMessage = err;
        }, 2000);        
        this.loaDer.nativeElement.style.display = "none";
      },
      () => {
        this.loaDer.nativeElement.style.display = "none";
      }
    ); 
    }else{
      this.printInvoiceMessage = 'Please wait.. Invoice generation is still in progress. Please search & navigate after some time';
    }   
  }
  
  public async getDownloadFile(downloadUrl): Promise<void> {
      const file = await this.httpClient.get<Blob>(downloadUrl, { responseType: 'blob' as 'json' }).toPromise();
      const blob = file;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      // anchor.download = this.selVal+'_'+ curent_date + '.xlsx';
      anchor.download = downloadUrl;
      anchor.href = url;
      anchor.click();
      window.URL.revokeObjectURL(url);
      this.loaDer.nativeElement.style.display = 'none';
  }
  

}
