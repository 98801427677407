import { Injectable } from '@angular/core';
import { BilApiUrl } from '../common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageUserConceptCreateService {
  concept_url: string = BilApiUrl.CONCEPT_DETAILS;
  user_detail_url: string = BilApiUrl.USER_DETAIL_URl;
  saving_create_user_url: string = BilApiUrl.CREATE_USER_CONCEPT;

  constructor(private _http: HttpClient) { }

  getUserConcept() {
    return this._http.post(this.concept_url, null);
  }

  getUserDetails(details) {
    console.log('passing data' + JSON.stringify(details));
    return this._http.post(this.user_detail_url, details);
  }

  saveUserDetails(userConceptsPassingList) {
    console.log('passing data' + JSON.stringify(userConceptsPassingList));
    return this._http.post(this.saving_create_user_url, userConceptsPassingList);
  }
}
     