import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { UtilsService } from '../../common/utils-service';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { ResponseMessage } from '../../common/response-message';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BarcodeScanDispatchService } from './barcode-scan-dispatch.service';
import { BarcodeScanHeader } from '../barcode-scan/barcode-header';
import { BarcodeScanResponseModel } from '../../common/barcode-scan-response-model';
import { Search } from '../barcode-scan/search';
import { DatePipe } from '@angular/common';
import { BarcodeScanLine } from '../barcode-scan/barcode-line';

@Component({
  selector: 'app-barcode-scan-dispatch',
  templateUrl: './barcode-scan-dispatch.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', './barcode-scan-dispatch.component.css'],
  providers: [DatePipe]
})
export class BarcodeScanDispatchComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;


  requestParameterModel: RequestParameterModel[];
  barcodeModel = new Search();
  searchBarcode: BarcodeScanHeader[] = [];
  searchBarcodeObj = new BarcodeScanHeader();

  searchBarcodeLineArr: BarcodeScanLine[] = [];
  searchBarcodeLine = new BarcodeScanLine();

  uploadFileName: string = 'No file Chosen';
  responseMessage: string;
  profileForm: FormGroup;
  fileUpload = { status: '', message: '', filePath: '' };
  statusMessage: string = '';
  enable: boolean = false;
  myDate: Date = new Date();

  firstTabHeaderLabel: string = "1(D)";
  secondTabHeaderLabel: string = "2(D)";

  resultArray: any = [];
  resultArray1D: any = [];
  assormentArray = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19'];

  constructor(private fb: FormBuilder, private barcodeScanService: BarcodeScanDispatchService, private httpClient: HttpClient, private _utilsService: UtilsService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private datePipe: DatePipe) { 
      this.profileForm = this.fb.group({
        name: [''],
        file: [''],
        uploadKey: ['BARCODE_SCAN']
      });
    }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);


  }

  submitSearchForm() {
    this.loaDer.nativeElement.style.display = 'block';
    this.barcodeBySearchCriteria();
  }


  barcodeBySearchCriteria() {
    this.responseMessage = '';

    this.barcodeScanService.getBarcodeBySearchCriteria11(this.barcodeModel.invoiceNumber).subscribe(
      (response: any) => {
        console.log(response);
        if (response.status == 'SUCCESS') {
          this.searchBarcodeLineArr = response.dataList;
          for (var i = 0; i < this.searchBarcodeLineArr.length; i++) {

            this.resultArray[i] = [];
            this.resultArray1D[i] = [];

            this.resultArray[i].planNumber = this.searchBarcodeLineArr[i].planNumber;
            this.resultArray[i].productionUnit = this.searchBarcodeLineArr[i].productionUnit;
            this.resultArray[i].artNumber = this.searchBarcodeLineArr[i].artNumber;
            this.resultArray[i].poNumber = this.searchBarcodeLineArr[i].poNumber;

            this.resultArray1D[i].planNumber = this.searchBarcodeLineArr[i].planNumber;
            this.resultArray1D[i].productionUnit = this.searchBarcodeLineArr[i].productionUnit;
            this.resultArray1D[i].artNumber = this.searchBarcodeLineArr[i].artNumber;
            this.resultArray1D[i].manufacturingDate = this.searchBarcodeLineArr[i].manufacturingDate;

            this.resultArray[i].sizes = [];
            this.resultArray1D[i].sizes1D = [];

            for (var j = 0; j < this.assormentArray.length; j++) {
              if (this.searchBarcodeLineArr[i].twoDScanAssortments.length != 0) {
                let index = this.searchBarcodeLineArr[i].twoDScanAssortments.findIndex((x) => x.sizeBucket == this.assormentArray[j]);
                let obj = {};
                if (index > -1) {
                  obj["pairQty"] = this.searchBarcodeLineArr[i].twoDScanAssortments[index].pairQty;
                } else {
                  obj["pairQty"] = '';
                }
                this.resultArray[i].sizes.push(obj);
              }
              else{
                let obj = {};
                obj["pairQty"] = '';
                this.resultArray[i].sizes.push(obj);
              }


              if (this.searchBarcodeLineArr[i].oneDScans.length != 0) {
                let index1D = this.searchBarcodeLineArr[i].oneDScans.findIndex((x) => x.sizeBucket == this.assormentArray[j]);
                let obj1D = {};
                if (index1D > -1) {
                  obj1D["pairQty"] = '1';
                } else {
                  obj1D["pairQty"] = '';
                }
                this.resultArray1D[i].sizes1D.push(obj1D);
              }
              else{
                let obj1D = {};
                obj1D["pairQty"] = '';
                this.resultArray1D[i].sizes1D.push(obj1D);
              }
            }
          }
          console.log(this.resultArray);
        }
        else {
          this.responseMessage = response.message;
          this.searchBarcodeLineArr = [];
        }
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  onSelectFile(event) {

    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
    }
  }

  onSubmit() {
    this.statusMessage = '';
    this.responseMessage = '';
    const formData = new FormData();
    formData.append('file', this.profileForm.get('file').value);
    formData.append('uploadKey', 'BARCODE_SCAN');
    this.barcodeScanService.upload(formData).subscribe(
      (response: ResponseMessage) => {
        console.log(response);
        if (response.status == "success") {
          this.responseMessage = 'File Uploaded successfully';
          this.statusMessage = 'success';
          this.enable = true;
        } else {
          this.responseMessage = 'Something error happened';
          this.statusMessage = 'error';
        }
      },
      (err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.enable = false;
      }

    );
  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }

  downloadTextFile() {
    this.barcodeScanService.fakeValidateUserData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: '2DScan' + "_" + this.searchBarcodeObj.invoiceNumber + "_" + this.datePipe.transform(this.myDate, 'ddMMyyyHHmmss') + '.txt',
        text: ""
      });
    });
  }

  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);
    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
   
  resetResults() {
    this.searchBarcode = [];
    this.searchBarcodeLineArr = [];
    this.responseMessage = '';
    this.uploadFileName = '';
  }

}
