import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { BilApiUrl } from '../../common/bil-api-url';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class FinanceScratchingUploadService {
  uploadfileURL: string = RdsBilApiUrl.UPLOAD_ASN_EXCEL_FILE;
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  generateFinanceScrapping: string = RdsBilApiUrl.GENERATE_FINANCE_SCRAPPING;


  constructor(private _http: HttpClient) { }

  upload(formData) {
    return this._http.post(`${this.uploadfileURL}`, formData);

  }

  getDownloadUrl() {

    return this._http.get(this.urlForDownloadUrl);

  }

  public getGenerate(uploadKeyValues: any) {
    return this._http.post(this.generateFinanceScrapping, uploadKeyValues, httpOptions);
  }


}
