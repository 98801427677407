export class Concept{
    conceptId: string;
    division: string;
    concept:string;
    conceptCode:string;
    /* concCode is added for the sending code of corresponding concept insted of concept name */// Done By Sushant Gupta
    concCode:string;
    description:string;
    orderPrefixPo:string;
    conceptInJde:string;
    orderPrefixJde:string;
    usedOnPo:string;
    usedOnSo:string;
    orderPrefixInPoSystem:string;
    }