export class PendingExportHeader {
    componentCode:string;
    componentName:string;
    articleNumber:string;
    articleName:number;
    customerArticleNumber:string;
    color:string;
    standardCost:string;
    customer:string;
    uom:string;
    componentId:string;
    componentStatus:string;
    endDate:string;
    createdBy:string;
    createdDate:string;
    lastUpdatedBy:string;
    lastUpdatedDate:string;
    country:string;

   constructor(){}
}


