export class PendingPurchaseOrderSearchForm {
    // conceptCode:string;
    poNumber:string;
    // deliveryFromWeek:string;
    // deliveryToWeek:string;
    // artNumber:string;
    // artName:string;
    categoryNumber:string;
    // categoryName:string;
    // poType:string;
    // deliveryFromMonth:string;
    // suppCode:string;   
    // poStatus:string;  
    // suppName:string;
    // orderPrefixConcept:string;    
    constructor(){}
}