import { UtilsService } from '@/common/utils-service';
import { Component, OnInit } from '@angular/core';
import { UpdateGatePassService } from './update-gate-pass.service';

@Component({
  selector: 'app-update-gate-pass',
  templateUrl: './update-gate-pass.component.html',
  styleUrls: ['./update-gate-pass.component.css']
})
export class UpdateGatePassComponent implements OnInit {
  searchModel:any = {
    "gatePassNo":null,
    "actionType":null,
    "gatePassDate":null,
    "vehicleNumber":null,
  }
  responseMessage: any;
  constructor(private updateGatePassService:UpdateGatePassService,
    private utilsService:UtilsService) { }

  ngOnInit(): void {
  }

  updateGatePassData(action){
    console.log("start the search/update");
    this.responseMessage = '';
    this.searchModel.actionType = action;
    let requestList:any = this.utilsService.createRequestParametersModelArray(this.searchModel);
    this.updateGatePassService.updateGateData(requestList).subscribe(
      (response:any) => {
        if(response.status.toLowerCase() == "success"){
          if(action == "search"){
            this.searchModel.gatePassDate = response.dataList[0].gatePassDate;
            this.searchModel.vehicleNumber = response.dataList[0].vehicleNumber;
          } else{
            this.responseMessage = response.message;
          }
        } else{
          this.responseMessage = response.message;
        }
      },
      (err) => {
        this.responseMessage = err;
      }
    )
  }

  changeDate(value){
    const myDate = new Date(value);
    const mnth = ('0' + (myDate.getMonth() + 1)).slice(-2);
    const day = ('0' + myDate.getDate()).slice(-2);
    this.searchModel.gatePassDate = [day, mnth, myDate.getFullYear()].join('/');
  }

  resetResults(){
    console.log("reset the search/update");
    this.responseMessage = '';
    this.searchModel = {
      "gatePassNo":null,
      "actionType":null,
      "gatePassDate":null,
      "vehicleNumber":null,
    }
  }

}
