import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../common/bil-api-url';
import { RequestParameterModel } from '../common/request-parameter-model';

@Injectable({
  providedIn: 'root'
})
export class SearchPackingMasterService {
  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
  pack_master_url: string = BilApiUrl.SEARCH_PACK_MASTER;
  article_no_url: string = BilApiUrl.IMPORT_ARTICLE_PRICE_MASTER;
  size_master_url: string = BilApiUrl.FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL;
  // concept_url: string = 'http://01HW976679:8095/mdm/queryconceptmasterall';
  // pack_master_url: string = 'http://01HW976679:8095/mdm/queryPackingMasterNew';
  // article_no_url: string = 'http://01HW976679:8095/mdm/queryArticleMaster';
  // size_master_url: string = 'http://01HW976679:8095/mdm/querySizeMaster';
  constructor(private _http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }

  getPackMasterDetails(requestParameterModel: RequestParameterModel[]) {
    return this._http.post(this.pack_master_url, requestParameterModel);
  }

  getArticleNumber(requestParameterModels:RequestParameterModel[]){
    console.log('paramters for getting article number'+JSON.stringify(requestParameterModels));
    return this._http.post(this.article_no_url, requestParameterModels);
  }

  getSizeBucketDetails(requestParameterData:RequestParameterModel[]){
    console.log('paramters for getting article number'+JSON.stringify(requestParameterData));
    return this._http.post(this.size_master_url, requestParameterData);
  }


}
