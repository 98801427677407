import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { CalendarModule } from 'primeng/calendar';

import { SearchAsnListComponent } from './asn-search/search-asn.component';
import { SearchGinComponent } from './search-gin/search-gin.component';
import { SearchGrnComponent } from './search-grn/search-grn.component';
// import { GenerateGinComponent } from './generate-gin/generate-gin.component';
import { ResupplyComponent } from './re-supply-status/re-supply-status.component';
// import { GinDetailsComponent } from './generate-gin/gin-details/gin-details.component';
import { SearchInvoiceComponent } from './search-invoice/search-invoice.component';
import { ViewInvoiceComponent } from './search-invoice/view-invoice/view-invoice.component';
import { AsnUploadComponent } from './asn-upload/asn-upload.component';
import { ResupplyUploadComponent } from './resupply-upload/resupply-upload.component';
import { AsnDetailComponent } from './asn-search/asn-detail/asn-detail.component';
import { BarcodeScanComponent } from './barcode-scan/barcode-scan.component';
import { BarcodeScanDispatchComponent } from './barcode-scan-dispatch/barcode-scan-dispatch.component';
import { AsnUpdateComponent } from './asn-search/asn-update/asn-update.component';
import { SearchInventoryComponent } from './search-inventory/search-inventory.component';
import { StockAdjustmentComponent } from './stock-adjustment/stock-adjustment.component';
import { ShortageAdjustmentComponent } from './shortage-adjustment/shortage-adjustment.component';
import { BlockArticleComponent } from './block-article/block-article.component';
import { UnblockArticleComponent } from './unblock-article/unblock-article.component';
import { GstMasterComponent } from './gst-master/gst-master.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { CycleCountComponent } from './cycle-count/cycle-count.component';
import { ImportArticleUploadComponent } from './import-article-upload/import-article-upload.component';
// import { RdsReportComponent } from './rds-report/rds-report.component';
import { ViewRdsStatusComponent } from './view-rds-status/view-rds-status.component';
import {RDSBatchesComponent} from './batches/batches.component';
import {SearchBatchStatusComponent} from './rds-batch-status/search-batch-status.component';
import {CreateRoleScreenAccessComponent} from './create_role-screen-access/create_role-screen-access.component'; 
import {SearchRoleScreenAccessComponent} from './search_role-screen-access/search_role-screen-access.component';
import { AcknowledgementUploadComponent } from './acknowledgement-upload/acknowledgement-upload.component';
import { ViewGrnComponent } from './search-grn/view-grn/view-grn.component';
import { NilStockUploadComponent } from './nil-stock-upload/nil-stock-upload.component';
import { FinanceScratchingUploadComponent } from './finance-scratching-upload/finance-scratching-upload.component';
import { PendingNilStockComponent } from './pending-nil-stock/pending-nil-stock.component';
import { from } from 'rxjs';
import { FinanceScratchingViewComponent } from './finance-scratching-view/finance-scratching-view.component';
import { PendingShortageAdjustmentComponent } from './pending-shortage-adjustment/pending-shortage-adjustment.component';
import { SearchCreditNoteComponent } from './search-credit-note/search-credit-note.component';
import { StockAdjustmentUploadComponent } from './stock-adjustment-upload/stock-adjustment-upload.component';
import { RouteMasterComponent } from './route-master/route-master.component';
import { ManualGrnComponent } from './asn-search/manual-grn/manual-grn.component';
import { FreightMasterComponent } from './freight-master/freight-master.component';
import { RdcMaintenanceComponent } from './rdc-maintenance/rdc-maintenance.component';
import { CreateRdcMaintenanceComponent } from './create-rdc-maintenance/create-rdc-maintenance.component';
import { SearchInventoryBifurcationComponent } from './search-inventory-bifurcation/search-inventory-bifurcation.component';
import { CreateInventoryBifurcationComponent } from './create-inventory-bifurcation/create-inventory-bifurcation.component';
import { FreightDetailComponent } from './freight-master/freight-detail/freight-detail.component';
import { ClaimSlipsComponent } from './claim-slips/claim-slips.component';
import { ReprocessJobComponent } from './reprocess-job/reprocess-job.component';
import { FreightAdditionalUploadComponent } from './freight-report-additional-info/freight-report-additional-info.component';
import { RdsReportASNComponent } from './rds-report-asn/rds-report-asn.component';
import { RdsReportGRNComponent } from './rds-report-grn/rds-report-grn.component';
import { RdsReportINVOICEComponent } from './rds-report-invoice/rds-report-invoice.component';
import { RdsReportGSTComponent } from './rds-report-gst/rds-report-gst.component';
import { RdsReportACCOUNTINGComponent } from './rds-report-accounting/rds-report-accounting.component';
import { RdsReportSTOCKComponent } from './rds-report-stock/rds-report-stock.component';
import { RdsReportFREIGHTComponent } from './rds-report-freight/rds-report-freight.component';
import { RdsReportNILSTOCKComponent } from './rds-report-nilstock/rds-report-nilstock.component';
import { RdsReportSTOCKADJComponent } from './rds-report-stockadj/rds-report-stockadj.component';
import { RdsReportSHORTAGEComponent } from './rds-report-shortage/rds-report-shortage.component';
import { RdsReportRESUPPLYComponent } from './rds-report-resupply/rds-report-resupply.component';
import { RdsReportAGEANALYSISComponent } from './rds-report-ageanalysis/rds-report-ageanalysis.component';
import { RdsReportOthersComponent } from './rds-report-others/rds-report-others.component';
import { UpdateInvoiceComponent } from './search-invoice/update-invoice/update-invoice.component';
import { ResupplyDetailComponent } from './re-supply-status/resupply-detail/resupply-detail.component';
import {MdmReportComponent} from './mdm-report/mdm-report.component';
import {ViewMdmStatusComponent} from './view-mdm-status/view-mdm-status.component';
import {EwayAdditionalUploadComponent} from './e-way-additional-upload/e-way-additional-upload.component';
import { ImportGstr21Component } from './import-gstr21/import-gstr21.component';
import { UpdateGatePassComponent } from './update-gate-pass/update-gate-pass.component';
import { DefectiveArticlesComponent } from './defective-articles/defective-articles.component';

const routes: Routes = [
    {
        path: 'rds-asn-search',
        component: SearchAsnListComponent,
    },
    {
        path: 'rds-asn-status-update',
        component: AsnUpdateComponent,
    },
    // {
    //     path: 'rds-generate-gin',
    //     component: GenerateGinComponent,
    // },
    {
        path: 'nil-stock-upload',
        component: NilStockUploadComponent,
    },
    {
        path: 'finance-scratching-upload',
        component: FinanceScratchingUploadComponent,
    },
    {
        path: 'rds-search-gin',
        component: SearchGinComponent,
    },
    {
        path: 'rds-barcode-scan',
        component: BarcodeScanComponent,
    },
    {
        path: 'rds-barcode-scan-dispatch',
        component: BarcodeScanDispatchComponent,
    },
    {
        path: 'rds-search-invoice',
        component: SearchInvoiceComponent,
    },
    {
        path: 'pending-nil-stock',
        component: PendingNilStockComponent,
    },
    {
        path: 'rds-view-invoice',
        component: ViewInvoiceComponent,
    },
    {
        path: 'rds-update-invoice',
        component: UpdateInvoiceComponent,
    },
    {
        path: 'manual-grn',
        component: ManualGrnComponent,
    },
    {
        path: 'rds-invoice',
        component: InvoiceComponent,
    },
    {
        path: 'rds-shortage-adjustment',
        component: ShortageAdjustmentComponent,
    },
    {
        path: 'rds-stock-adjustment',
        component: StockAdjustmentComponent,
    },
    {
        path: 'rds-search-inventory',
        component: SearchInventoryComponent,
    },
    {
        path: 'rds-search-grn',
        component: SearchGrnComponent,
    },
    {
        path: 'rds-view-grn',
        component: ViewGrnComponent,
    },
    {
        path: 'rds-resupply-status',
        component: ResupplyComponent,
    },
    {
        path: 'rds-cycle-count',
        component: CycleCountComponent,
    },
    {
        path: 'rds-asn-upload',
        component: AsnUploadComponent,
    },
    {
        path: 'ito-stn-upload',
        component: ResupplyUploadComponent,
    },
    {
        path: 'rds-asn-details',
        component: AsnDetailComponent,
    },
    {
        path: 'rds-block-article',
        component: BlockArticleComponent,
    },
    {
        path: 'acknowledgement-upload',
        component: AcknowledgementUploadComponent,
    },
    {
        path: 'rds-unblock-article',
        component: UnblockArticleComponent,
    },
    {
        path: 'rds-gst',
        component: GstMasterComponent,
    },
    {
        path: 'grn-download-upload',
        component: ImportArticleUploadComponent,
    },
    // {
    //     path: 'rds-report',
    //     component: RdsReportComponent,
    // },
    {
        path: 'view-rds-status',
        component: ViewRdsStatusComponent,
    },
    {
        path: 'view-mdm-status',
        component: ViewMdmStatusComponent,
    },
    {
        path: 'mdm-report',
        component: MdmReportComponent,
    },
    {
        path: 'rds-batch-programms',
        component: RDSBatchesComponent,
    },
    {
        path: 'rds-batch-status',
        component: SearchBatchStatusComponent,
    },
    {
        path: 'create-role-screen-access',
        component: CreateRoleScreenAccessComponent,
    },
    {
        path: 'search-role-screen-access',
        component: SearchRoleScreenAccessComponent,
    },
    {
        path: 'finance-scratching-view',
        component: FinanceScratchingViewComponent,
    },
    {
        path: 'pending-shortage-adjustment',
        component: PendingShortageAdjustmentComponent,
    },
    {
        path: 'search-credit-note',
        component: SearchCreditNoteComponent,
    },
    {
        path: 'stock-adjustment-upload',
        component: StockAdjustmentUploadComponent,
    },
    {
        path: 'route-master',
        component: RouteMasterComponent,
    },
    {
        path: 'freight-master',
        component: FreightMasterComponent,
    },
    {
        path: 'rdc-maintenance',
        component: RdcMaintenanceComponent,
    },
    {
        path: 'create-rdc-maintenance',
        component: CreateRdcMaintenanceComponent,
    },
    {
        path: 'search-inventory-bifurcation',
        component: SearchInventoryBifurcationComponent,
    },
    {
        path: 'create-inventory-bifurcation',
        component: CreateInventoryBifurcationComponent,
    },
    {
        path: 'freight-details',
        component: FreightDetailComponent,
    },
    {
        path: 'claim-slips',
        component: ClaimSlipsComponent,
    },
    {
        path: 'defective-articles',
        component: DefectiveArticlesComponent,
    },
    {
        path: 'import-gstr',
        component: ImportGstr21Component,
    },
    {
        path: 'update-gate-pass',
        component: UpdateGatePassComponent,
    },
    {
        path: 'reprocess-job',
        component: ReprocessJobComponent,
    },
    {
        path:'freight-additional-info',
        component:FreightAdditionalUploadComponent,
    },
    {
        path: 'rds-report-asn',
        component: RdsReportASNComponent,
    },
    {
        path: 'rds-report-grn',
        component: RdsReportGRNComponent,
    },
    {
        path: 'rds-report-invoice',
        component: RdsReportINVOICEComponent,
    },
    {
        path: 'rds-report-gst',
        component: RdsReportGSTComponent,
    },
    {
        path: 'rds-report-accounting',
        component: RdsReportACCOUNTINGComponent,
    },
    {
        path: 'rds-report-stock',
        component: RdsReportSTOCKComponent,
    },
    {
        path: 'rds-report-freight',
        component: RdsReportFREIGHTComponent,
    },
    {
        path: 'rds-report-nilstock',
        component: RdsReportNILSTOCKComponent,
    },
    {
        path: 'rds-report-stockadj',
        component: RdsReportSTOCKADJComponent,
    },
    {
        path: 'rds-report-shortage',
        component: RdsReportSHORTAGEComponent,
    },
    {
        path: 'rds-report-resupply',
        component: RdsReportRESUPPLYComponent,
    },
    {
        path: 'rds-report-ageanalysis',
        component: RdsReportAGEANALYSISComponent,
    },
    {
        path: 'rds-report-others',
        component: RdsReportOthersComponent,
    },
    {
        path:'rds-resupply-details',
        component: ResupplyDetailComponent,

    },
    {
        path: 'e-way-additional-upload',
        component: EwayAdditionalUploadComponent,
    },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }

];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RdsRoutingModule { }
