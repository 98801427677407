import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ɵConsole,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ResupplyService } from "./resupply.service";
import { ResupplyHeader } from "./resupply-header";
import { RequestParameterModel } from "../../common/request-parameter-model";
import { SearchResupplResultModel } from "./search-resupply-result-model";
import { ListOfBataWeek } from "../asn-search/list-of-bata-week";
import { UtilsService } from "../../common/utils-service";
import { importsavevar } from "./import-save-var";
import { ResupplyLine } from "./resupply-lines";
import { AssortmentDetailModel } from "./assortment-detail-model";
import { AssortmentDetails } from "@/search-packing-master/assortment-details";
import { UpdateAssortment } from "../re-supply-status/update-assortment";
import { DatePipe } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { NavigationLinkParameterService } from "../../common/navigation-link-parameters-service";

@Component({
  selector: "app-re-supply-status",
  templateUrl: "./re-supply-status.html",
  styleUrls: [
    "../../../assets/css/bootstrap.css",
    "../../../assets/css/rds-bil-style.css",
    "../../../../node_modules/font-awesome/css/font-awesome.css",
    "./re-supply-status.component.css",
  ],
  providers: [DatePipe],
})
export class ResupplyComponent implements OnInit {
  /*----------------------- List of all declared Variables ---------------------------*/

  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  form: FormGroup;
  resupForm: FormGroup;
  profileForm: FormGroup;
  userform: FormGroup;
  errorMessage: string;
  status: string;
  message: string;
  filterResupplyParent: ResupplyHeader[] = [];
  uniques: ResupplyHeader[] = [];
  _SearchResultModel = new ResupplyHeader();
  errormsg: string;
  isValidDate: boolean;
  isValid: boolean = false;
  dateValue: any[];
  responseMessage: string;
  frombataWeekzero: string;
  frombataWeekfinal: any;
  statusMessage: string = "";
  searchText: string = "";
  enable: boolean = false;
  public noResults: boolean = false;
  uploadFileName: string = "No Choose File";
  bataList: ListOfBataWeek[] = [];
  tobataWeekzero: string;
  tobataWeekfinal: string;
  searchResupplyData: RequestParameterModel[];
  assortmentDataList: any[] = [];
  articleNoOnly: ResupplyHeader[] = [];
  isShow = false;
  updatefilful: ResupplyLine[] = [];
  private _error: any = { isError: false, errorMessage: "" };
  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter();
  @Output() groupFilters: EventEmitter<any> = new EventEmitter<any>();
  enableEdit = false;
  enableEditIndex = null;
  isEditEnable: boolean = true; // to show and hide the edit button
  assortmentDetailList: AssortmentDetailModel[] = [];
  isFulfullQtyEnable: boolean[] = [];
  isEnabledButtonEnabled: boolean[] = [];
  isUpdateButtonEnable: boolean = false;
  saveAssortment = new UpdateAssortment();
  saveAssortmentList: UpdateAssortment[] = [];
  erroeForFulfill: string;
  arr: any[];
  resupplyStat: string;
  count: number = 0;
  myDate: Date = new Date();
  sucessMsg: string;
  concepts: any[];
  statuses: any[];
  types: any[];
  usercode: any;
  ShowSearchDisable: boolean = false;
  itnRsnId: any;
  assortData: any = {};
  groupCode: any;
  assortArray: any[];
  destFlag:boolean= false; 
  sourceFlag:boolean= false; 
  weekfromFlag:boolean= false;
  weektoFlag:boolean= false;
  serialFlag:boolean= false;
  partyFlag:boolean = false;
  routeDetails:any[];
  totalOrderQty: any[];
  totalDispatchQty:any[];
  totalCancelQty:any[];
  totalBalanceQty:any[];

  searchType='header';
  currentPage:number=1;
  itemsPerPage:number=10;
  callForm:boolean=false;
  totalItems:any;

  uniqueKeyArray:any[];
  

  /*----------------------- List of all declared Variables ---------------------------*/

  /*-------------------------Constructor declartion ------------------------------------*/

  // tslint:disable-next-line: max-line-length
  constructor(
    private fb: FormBuilder,
    private _resupplyService: ResupplyService,
    private _utilsService: UtilsService,
    private datePipe: DatePipe,
    private _router: Router,
    private _navigationLinkParameterService: NavigationLinkParameterService
  ) {
    //updated by debamala dated 28-7-20
    let sessUsername = sessionStorage.getItem("currentUser");
    this.usercode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    //this.usercode = "user.test4";
    this.buildForm();
    this.userForm();
    //updated by debamala dated 28-7-20
  }

  /*-------------------------Constructor declartion ------------------------------------*/

  ngOnInit(): void {
    // console.log("RESUPPLY CALL");
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = "none";
      //added by debamala dated 28-7-20
      var conceptQueryMas = [
        {
          name: "email",
          value: this.usercode,
        },
      ];
      this._resupplyService.getConcepts(conceptQueryMas).subscribe(
        (data: any) => {
          //this.concepts = data.dataList;
          this.concepts = data.dataList.filter(function (list) {
            return list.division == "RETAIL";
          });
        },
        (err) => {
          this.responseMessage = err;
        }
      );
      var status_details = [
        {
          name: "paramName",
          value: "RESUPPLY_STATUS",
        },
      ];
      this._resupplyService.getStatus(status_details).subscribe(
        (data: any) => {
          this.statuses = data.configParamModels;
        },
        (err) => {
          this.responseMessage = err;
        }
      );
      //added by debamala dated 28-7-20

      var routeNumberFetch ={
          "activeStatus" : "Y"  
        };
      this._resupplyService.getRouteNumber(routeNumberFetch).subscribe(
        (data: any) => {
          // console.log(data);
          var flags = [], output = [], l = data.length, i;
          for( i=0; i<l; i++) {
              if( flags[data[i].routeSequence]) continue;
              flags[data[i].routeSequence] = true;
              output.push(data[i]);
          }
          // console.log(output);
           this.routeDetails = output;
        },
        (err) => {
          this.responseMessage = err;
        }
      );

      //updated by priti dated 01-10-20
      this.types = [{
        name: "ReSupply Number",
        value: "RESUP"
      },{
        name: "ITO/STN Number",
        value: "ITO"
      }]
      //updated by priti dated 01-10-20
    }, 100);

    if (
      this._navigationLinkParameterService.searchResupHeaderData !== "" &&
      this._navigationLinkParameterService.searchResupHeaderData !== undefined
    ) {
      this._SearchResultModel = this._navigationLinkParameterService.searchResupHeaderData;
      console.log(this._SearchResultModel);
      
      this.resupForm.controls["itnRsnSerialNumber"].setValue(this._navigationLinkParameterService.searchResupHeaderData.itnRsnSerialNumber);
      this.resupForm.controls["resupplyType"].setValue(this._navigationLinkParameterService.searchResupHeaderData.resupplyType);
      this.resupForm.controls["fromresupplydate"].setValue(this._navigationLinkParameterService.searchResupHeaderData.fromresupplydate);
      this.resupForm.controls["toresupplydate"].setValue(this._navigationLinkParameterService.searchResupHeaderData.toresupplydate);
      this.resupForm.controls["resupplyStatus"].setValue(this._navigationLinkParameterService.searchResupHeaderData.resupplyStatus);
      this.resupForm.controls["concept"].setValue(this._navigationLinkParameterService.searchResupHeaderData.concept);
      this.resupForm.controls["destShopNumber"].setValue(this._navigationLinkParameterService.searchResupHeaderData.destShopNumber);
      this.resupForm.controls["sourceShopNumber"].setValue(this._navigationLinkParameterService.searchResupHeaderData.sourceShopNumber);
      this.resupForm.controls["routeNumber"].setValue(this._navigationLinkParameterService.searchResupHeaderData.routeNumber);
      this.resupForm.controls["partyNumber"].setValue(this._navigationLinkParameterService.searchResupHeaderData.partyNumber);
      this.resupForm.controls["bataWeekFrom"].setValue(this._navigationLinkParameterService.searchResupHeaderData.bataWeekFrom);
      this.resupForm.controls["bataWeekTo"].setValue(this._navigationLinkParameterService.searchResupHeaderData.bataWeekTo);
      this.callForm=true;
      this.currentPage=1;
      this.setResupplySearchCriteria(this._SearchResultModel);
    } else {
      this.resupForm.controls["itnRsnSerialNumber"].setValue("");
      // this.resupForm.controls["resupplyType"].setValue("");
      // this.resupForm.controls["fromresupplydate"].setValue("");
      // this.resupForm.controls["toresupplydate"].setValue("");
      // this.resupForm.controls["resupplyStatus"].setValue("");
      // this.resupForm.controls["concept"].setValue("");
      // this.resupForm.controls["destShopNumber"].setValue("");
      // this.resupForm.controls["sourceShopNumber"].setValue("");
      // this.resupForm.controls["routeNumber"].setValue("");
      // this.resupForm.controls["partyNumber"].setValue("");
      // this.resupForm.controls["bataWeekFrom"].setValue("");
      // this.resupForm.controls["bataWeekTo"].setValue("");

    }
    this._navigationLinkParameterService.searchResupHeaderData = "";
  }

  /* -------------------------------- Form Builder header ----------------------------*/

  buildForm(): void {
    //updated by debamala dated 28-7-20
    this.resupForm = this.fb.group({
      itnRsnSerialNumber: [
        "",
        [Validators.maxLength(20), Validators.minLength(3)],
      ],
      resupplyStatus: [null],
      //updated by priti dated 01-10-20
      resupplyType: [null],
      //updated by priti dated 01-10-20
      concept: [null],
      fromresupplydate: [""],
      toresupplydate: [""],
      destShopNumber: ["", [Validators.maxLength(5), Validators.minLength(5)]],
      sourceShopNumber: ["", [Validators.maxLength(5), Validators.minLength(5)]],
      partyNumber: ["", [Validators.maxLength(3), Validators.minLength(3)]],
      // itoStnNumber: [""],
      userCode: [this.usercode],
      //updated by priti dated 01-10-20
      routeNumber:[null],
      bataWeekFrom: ["",[Validators.maxLength(6), Validators.minLength(6)]],
      bataWeekTo: ["",[Validators.maxLength(6), Validators.minLength(6)]],
      searchType:[""],
      pageNumber:[""],
      pageSize:[""]
      //updated by priti dated 01-10-20
      //updated by debamala dated 28-7-20
    });
  }

  userForm(): void {
    this.userform = this.fb.group({
      fulfilledQuantity: new FormControl(),
      artNumber: new FormControl(""),
      itnRsnId: new FormControl(""),
      artSize: new FormControl(""),
      resupplyStatus: new FormControl(""),
    });
  }

  /* -------------------------------- Form Builder header ----------------------------*/

  /* -------------------------------- On Search button click ----------------------------*/

  searchResupplyDataWithValues(filters: any) {
    this.currentPage=1;
    //updated by priti dated 01-10-20
    //&& this.resupForm.controls["itoStnNumber"].value == ""
    if (this.resupForm.controls["itnRsnSerialNumber"].value == "" && this.resupForm.controls["resupplyType"].value == null && this.resupForm.controls["resupplyStatus"].value == null && this.resupForm.controls["concept"].value == null && this.resupForm.controls["fromresupplydate"].value == "" && this.resupForm.controls["toresupplydate"].value == "" && this.resupForm.controls["destShopNumber"].value == ""  && this.resupForm.controls["routeNumber"].value == null && this.resupForm.controls["bataWeekFrom"].value == "" && this.resupForm.controls["bataWeekTo"].value == "" && this.resupForm.controls["partyNumber"].value == "" && this.resupForm.controls["sourceShopNumber"].value == "") {
      // console.log('in if'); 
      this.errormsg = "Please select any search criteria.";
      //updated by priti dated 01-10-20
    }
    else {
      
      // console.log('in else');
      // console.log(filters.resupplyType);
      this.errormsg = "";
      this.loaDer.nativeElement.style.display = "block";
      // Object.keys(filters).forEach(key => filters[key] === '' ? delete filters[key] : key);
      this.groupFilters.emit(filters);
      this.isValidDate = true;
      // console.log(this.resupForm.value);
      let fromDate: Date = this.resupForm.value.fromresupplydate;
      let toDate: Date = this.resupForm.value.toresupplydate;
      let invoiceNO: string = this.resupForm.value.itnRsnSerialNumber;
      let stats: string = this.resupForm.value.resupplyStatus;
      let concept: string = this.resupForm.value.concept;
      let destRDC: string = this.resupForm.value.destShopNumber;
      let sourceRDC: string = this.resupForm.value.sourceShopNumber;
      
      let partyNum: string = this.resupForm.value.partyNumber;
      //updated by priti dated 01-10-20
      // let ITONumber: string = this.resupForm.value.itoStnNumber;

      let fromDateStr: string;
      let toDateStr: string;

      // if (
      //   fromDate != null ||
      //   toDate != null ||
      //   invoiceNO != "" ||
      //   stats != null ||
      //   concept != null ||
      //   sourceRDC != "" ||
      //   ITONumber != ""
      // ) {
      //   this.errormsg = "";
      // } else {
      //   this.errormsg = "Please select search criteria first";
      // }

      // if(filters.resupplyType== null && filters.itnRsnSerialNumber!="" || filters.itnRsnSerialNumber == "" && filters.resupplyType!= null){
      //   // console.log('in elseif');
      //   this.errormsg = "Please select both ReSupply/ITO STN Number & ReSupply Type";
      //   this.loaDer.nativeElement.style.display = "none";
      //   return false;
      // }
      
      if(filters.bataWeekFrom!="" && filters.bataWeekTo=="" || filters.bataWeekFrom=="" && filters.bataWeekTo!=""){
        // console.log('in elseif');
        this.errormsg='Please provide both values BataWeekFrom & BataWeekTo';
        this.loaDer.nativeElement.style.display = "none";
        return false;
      }
  
      if(filters.bataWeekFrom > filters.bataWeekTo){
        // console.log('in elseif');
        // console.log(filters.bataWeekFrom > filters.bataWeekTo); console.log('filters.bataWeekFrom > filters.bataWeekTo');
        this.errormsg='BataWeekFrom should be less than BataWeekTo';
        this.loaDer.nativeElement.style.display = "none";
        return false;
      }
      if(filters.fromresupplydate > filters.toresupplydate){
        // console.log('in elseif');
        // console.log(filters.fromresupplydate > filters.toresupplydate); console.log('filters.fromresupplydate > filters.toresupplydate');
        this.errormsg='Date From should be less than Date To';
        this.loaDer.nativeElement.style.display = "none";
        return false;
      }
      //updated by priti dated 01-10-20
      // Validate ToPRDate and FromPRDate
      if (fromDate != null || toDate != null) {
        fromDateStr = this._utilsService.transformDateSearch(fromDate);
        toDateStr = this._utilsService.transformDateSearch(toDate);
        this.isValidDate = this._utilsService.validateDatesSearch(
          fromDateStr,toDateStr
          
        );
      }
      

     if (this.isValidDate) {
        this.setResupplySearchCriteria(this._SearchResultModel);
      } else {
        this._error = this._utilsService.fetchErrorMessage();
        this.errorMessage = this._error.errorMessage;
        this.loaDer.nativeElement.style.display = "none";
      }
    }
  }

  /* -------------------------------- On Search button click ----------------------------*/

  /* ---------------------------- Rest all the fields ------------------------*/

  resetSearchResupplyResults() {
    this.resupForm.reset();
    setTimeout(() => {
      //this.resupForm.controls["userCode"].setValue(this.usercode);
      this.buildForm();
    }, 500);
    this.filterResupplyParent = [];
    this.uniques=[];
    this.noResults = false;
    this._error = { isError: false, errorMessage: "" };
    this.isValidDate = true;
    this.responseMessage = "";
    this.errormsg = "";
    this.ShowSearchDisable = false;
    this.destFlag= false;
    this.sourceFlag = false;
    this.weektoFlag= false;
    this.weekfromFlag= false;
    this.serialFlag= false;
    this.partyFlag = false;
    this.currentPage=1;
  }

  /* ---------------------------- Rest all the fields ------------------------*/

  /*  ----------------Search Resupply Data from service - Search----------- */

  setResupplySearchCriteria(requisitionModel: ResupplyHeader) {
    //updated error message by debamala dated 28-7-20
    this.uniques=[];
    this.totalOrderQty=[];
    this.totalDispatchQty=[];
    this.totalCancelQty=[];
    this.totalBalanceQty=[];
    this.filterResupplyParent=[];
    this.uniqueKeyArray=[];
    let orderQty=0;
    let dispatchQty=0;
    let cancelQty=0;
    if(this.callForm){
      // this.loaDer.nativeElement.style.display = 'block';
      this.callForm=false;
    }
    // console.log(" Log File : ReSupplyComponent  Function : setResupplySearchCriteria : Resupply Line Status :  " + requisitionModel);
    console.log(this.resupForm.value); 
    console.log(this.searchType);
    this.resupForm.controls["searchType"].setValue(this.searchType);
    this.resupForm.controls["pageSize"].setValue(this.itemsPerPage);
    this.resupForm.controls["pageNumber"].setValue(this.currentPage);
    this._resupplyService
      .getResupplySearchCriteria(this.resupForm.value)
      .subscribe(
        (res: SearchResupplResultModel) => {
          if (res.dataList.length>0) {
            this.filterResupplyParent = res.dataList;
            this.status = res.status;
            this.message = res.message;
            // if (this.filterResupplyParent != null) {
            //   this.filterResupplyParent.forEach((requsition) =>
            //     console.log(requsition)
            //   );
            // }

            if ( this.filterResupplyParent != null) {
              this.totalItems=res.recordCount;

              this.filterResupplyParent.forEach(
                resupData => {
                  let key=resupData.itnRsnSerialNumber + '|'+ resupData.resupplyYear + '|' + resupData.reSupplyWeek + '|' + resupData.sourceShopNumber + '|' + resupData.destShopNumber;
                  orderQty=orderQty+((resupData.resupplyArtTotalQuantity!=null)?parseInt(resupData.resupplyArtTotalQuantity):0);
                  dispatchQty=dispatchQty+((resupData.resupplyArtFulfilledQuantity!=null)?parseInt(resupData.resupplyArtFulfilledQuantity):0);
                  cancelQty=cancelQty+((resupData.resupplyArtCancelQuantity!=null)?parseInt(resupData.resupplyArtCancelQuantity):0);
                  console.log(key);
                  var indexofKey=this.uniqueKeyArray.indexOf(key);
                  if (indexofKey === -1) {
                    // console.log('check');
                    // console.log(orderQty);
                  
                    this.uniqueKeyArray.push(key);
                    this.totalOrderQty.push(orderQty);
                    this.totalDispatchQty.push(dispatchQty);
                    this.totalCancelQty.push(cancelQty);
                    this.totalBalanceQty.push(orderQty-(cancelQty+dispatchQty));
                   
                }else{
                  this.totalOrderQty[indexofKey]=this.totalOrderQty[indexofKey]+orderQty;
                  this.totalDispatchQty[indexofKey]=this.totalDispatchQty[indexofKey]+dispatchQty;
                  this.totalCancelQty[indexofKey]=this.totalCancelQty[indexofKey]+cancelQty;
                  this.totalBalanceQty[indexofKey]=this.totalBalanceQty[indexofKey]+(orderQty-(cancelQty+dispatchQty));
                }
                  orderQty=0;
                  dispatchQty=0;
                  cancelQty=0;
                  
                }
              );
                //  console.log(this.totalOrderQty);
              this.uniques = this.filterResupplyParent.filter(function(a) {
                     // const key = a.supplyNo + '|' + a.consignorCode;
                     const key=a.itnRsnSerialNumber + '|'+ a.resupplyYear + '|' + a.reSupplyWeek + '|' + a.sourceShopNumber + '|' + a.destShopNumber;
                     //const key=a.itnRsnSerialNumber + '|' + a.sourceShopNumber + '|' + a.destShopNumber;
                     //const key=a.itnRsnSerialNumber;
                      // console.log(key);
                      // orderQty=orderQty+((a.resupplyArtTotalQuantity!=null)?parseInt(a.resupplyArtTotalQuantity):0);
                      
                     if (!this[key]) {
                          // console.log('check');
                          // console.log(orderQty);
                          
                         this[key] = true;
                        //  this.totalOrderQty.push(orderQty);
                        //  orderQty=0;
                         return true;
                     }
                 }, Object.create(null));
                
            } else {
             this.uniques = [];
             this.errormsg = res.message;
           }
          } else {
            this.filterResupplyParent = [];
            this.uniques=[];
            this.errormsg = res.message;
          }
          this.loaDer.nativeElement.style.display = "none";
        },
        (err) => {
          this.filterResupplyParent = [];
          this.uniques=[];
          this.loaDer.nativeElement.style.display = "none";
          this.errormsg = err;
        }
      );
    // tslint:disable-next-line: max-line-length
    // console.log(" Log File : ReSupplyComponent  Function : setResupplySearchCriteria : Resupply Search Result :  " + this.filterResupplyParent);
  }

  /*  ----------------Search Resupply Data from service - Search----------- */

  /*  ----------------Search Resupply Data from service - Search----------- */

  setResupplyAssortmentDataPopup(
    resupplySerialNo,
    resupplyartNo,
    resupplyStatus,
    itnRsnId
  ) {
    this.isValid = false;
    this.sucessMsg = "";
    this.isEnabledButtonEnabled = [];
    this.isFulfullQtyEnable = [];
    this.isUpdateButtonEnable = false;
    this.assortmentDataList = [];
    this.articleNoOnly = [];
    this.arr = [];
    this.count = 0;
    this.erroeForFulfill = "";
    this.saveAssortment = new UpdateAssortment();
    this.saveAssortment.articleNumber = resupplyartNo;
    this.saveAssortment.reSupSerialNumber = resupplySerialNo;
    this.resupplyStat = resupplyStatus;
    this.itnRsnId = itnRsnId;
    // console.log(this.itnRsnId);
    if (resupplyStatus != "PROCESSED") {
      this.isEnabledButtonEnabled[i] = true;
      this.erroeForFulfill =
        "Update fulfill Quanity only for OPEN and PROCESSED";
    }
    //updated by debamala dated 31-7-20
    // this._resupplyService
    //   .getResupplyAssortmentPopup(resupplySerialNo, resupplyartNo)
    //   .subscribe((res: any) => {
    //     this.articleNoOnly = res.dataList;
    //     this.assortmentDataList = res.dataList[0].reSupplyItnAssormentModelList;
    //   });

    //updated by priti dated 01-10-20
    let firstletter = resupplyartNo.charAt(0);
    this.assortArray = [];
    //updated by priti dated 01-10-20
    let index = this.filterResupplyParent.findIndex(
      (x) => x.itnRsnId == itnRsnId
    );
    if (index != -1) {
      this.assortData = this.filterResupplyParent[index];
      // console.log(this.assortData);
      //updated by priti dated 01-10-20
      let details = [{ "name": "letterOfArticle", "value": firstletter }];
      this._resupplyService.getSizeDetails(details).subscribe(
        (response: any) => {
          if (response.status == "Success") {
            this.groupCode = response.dataList[0].groupCode;
            this.assortArray = [];
            response.dataList.forEach(data => {
              
              let assortmentArray =this.assortData.reSupplyItnAssormentModelList;
              // console.log(assortmentArray); console.log('assortmentArray');
              let found = assortmentArray.findIndex(element => element.artSize == data.artSize);
              if (found !== -1) {
                let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWisePairQty: assortmentArray[found].pairQuantity, sizeWiseFulFillQty: assortmentArray[found].fulfilledQuantity};
                this.assortArray.push(assortmentData);
              }
            });
          }
        },
        (err) => {
          this.assortArray = [];
        }
      );
      //updated by priti dated 01-10-20
    }
    //updated by debamala dated 31-7-20
   
    
    for (var i = 0; i < this.assortmentDataList.length; i++) {
      console.log(resupplyStatus);
      if (resupplyStatus == "PROCESSED" || resupplyStatus == "OPEN") {
        // console.log(this.isEnabledButtonEnabled[i]);
        this.isEnabledButtonEnabled[i] = false;
      }
    }
  }

  setDataForEditing(index) {
    this.erroeForFulfill = "";
    // console.log("set data for row no. :-" + index);
    this.arr = [];
    for (var i = 0; i < this.assortmentDataList.length; i++) {
      this.arr[i] = this.assortmentDataList[i].fulfilledQuantity;
    }
    this.isEnabledButtonEnabled[index] = true;
    this.isFulfullQtyEnable[index] = true;
    this.count = this.count + 1;
    // this.isEnabledButtonEnabled.forEach(
    //   check => {
    //     if(check == true){
    //       this.isUpdateButtonEnable = true;
    //     }
    //     else{
    //       this.isUpdateButtonEnable = false;
    //     }
    //   }
    // )
  }

  onClickCancel(index) {
    this.erroeForFulfill = "";
    // console.log("cancel data for row no. :-" + index);
    this.isEnabledButtonEnabled[index] = false;
    this.isFulfullQtyEnable[index] = false;
    this.assortmentDataList[index].fulfilledQuantity = this.arr[index];
    this.count = this.count - 1;
    // this.isEnabledButtonEnabled.forEach(
    //   check => {
    //     if(check == true){
    //       this.isUpdateButtonEnable = true;
    //     }
    //     else{
    //       this.isUpdateButtonEnable = false;
    //     }
    //   }
    // )
  }

  setFulfillQty(e, i) {
    this.erroeForFulfill = "";
    let value = Object(e.target.value);
    this.assortmentDataList[i].fulfilledQuantity = value;
  }
  getPage(page){
       this.currentPage=page;
      //  this.setAsnByAsnSearchCriteria(this.searchASNCriteriaList);
      //console.log(this.searchASNCriteriaList);
       this.setResupplySearchCriteria(this.resupForm.value);
  
  }

  /*  ----------------Search Resupply Data from service - Search----------- */

  /*  ---------------- Close popup disable text box ----------- */
  // IsmodelShow = true;
  closePopupFuncion(e, i) {
    this.cancel(e, i);
    // this.IsmodelShow=false;
  }
  /*----------------------- Enable Update Button --------------------*/

  saveFulfillQuantity() {
    // // this.groupFilters.emit(filters);
    // // this.isValidDate = true;
    // // let fulfillQty: string = this.userform.value.fulfilledQuantity;
    // // let arSize: string = this.userform.value.artSize;
    // // let artNumber: string = this.userform.value.artNumber;
    // // let itnRsnId: string = this.userform.value.itnRsnId;
    // // let newObj: any = new ResupplyLine();

    // // newObj.reSupSerialNumber = itnRsnId;
    // // newObj.articleNumber = artNumber;
    // // newObj.reSupUpdateDetails = fulfillQty;
    // // newObj.reSupUpdateDetails = arSize;

    // // this.assortmentDataList.push(newObj);
    // // console.log(newObj);
    // // this.enableEditIndex = null;
    // // let updatefilful = [];

    // //console.log(this.updatefilful);
    // /* this.updatefilful.updateFuilfilQuantity(artNumber,fulfillQty,arSize,itnRsnId).subscribe(
    //    (res : any) =>  {
    //      console.log(res.message);
    //        //this. = res.message;
    //      }
    //  ) */
    this.erroeForFulfill = "";
    this.saveAssortmentList = [];
    this.assortmentDetailList = [];
    let count: number = 0;
    // console.log("lets save the data as per payload who all rows are enabled");
    for (var i = 0; i < this.isEnabledButtonEnabled.length; i++) {
      if (this.isEnabledButtonEnabled[i] == true) {
        if (
          this.assortmentDataList[i].pairQuantity >=
          this.assortmentDataList[i].fulfilledQuantity
        ) {
          let newObj = new AssortmentDetailModel();
          newObj.artSize = this.assortmentDataList[i].artSize;
          newObj.fulFillQty = this.assortmentDataList[i].fulfilledQuantity;
          this.assortmentDetailList.push(newObj);
        } else {
          count = count + 1;
          // this.erroeForFulfill = 'Please Enter the FulfillQty less than pairQty for enabled rows';
        }
      }
    }
    if (count == 0) {
      this.saveAssortment.reSupUpdateDetails = this.assortmentDetailList;
      this.saveAssortmentList.push(this.saveAssortment);
      // console.log("Saving Data" + JSON.stringify(this.saveAssortmentList));
      this._resupplyService
        .updateFuilfilQuantity(this.saveAssortmentList)
        .subscribe((res: any) => {
          // console.log(res.status + res.message);
          if (res.status == "SUCCESS") {
            this.setResupplyAssortmentDataPopup(
              this.saveAssortment.reSupSerialNumber,
              this.saveAssortment.articleNumber,
              this.resupplyStat,
              this.itnRsnId
            );
            this.sucessMsg = res.message;
            this.isValid = true;
          }
        });
    } else {
      this.erroeForFulfill =
        "Please Enter the FulfillQty less than pairQty for enabled rows";
      this.isValid = false;
    }
  }

  /*----------------------- Enable Update Button --------------------*/

  onEdit(itnNo) {
    // console.log(itnNo);
    this.isEditEnable = !this.isEditEnable;
  }

  enableEditMethod(e, i) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    // console.log(i, e);
  }

  cancel(e, i) {
    // console.log("cancel");
    this.enableEditIndex = i;
  }
  saveSegment() {
    this.enableEditIndex = null;
  }
  /*----------------------- Enable Update Button --------------------*/

  /* --------------------- download template ------------------------ */

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement,
    },
  };

  downloadTextFile() {
    this._resupplyService.fakeValidateUserData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName:
          "RDS_ReSup_" +
          this.userform.value.itnRsnId +
          "_" +
          this.datePipe.transform(this.myDate, "ddMMyyyHHmmss") +
          ".txt",
        text: "",
      });
    });
  }

  private dyanmicDownloadByHtmlTag(arg: { fileName: string; text: string }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement("a");
    }
    const element = this.setting.element.dynamicDownload;
    const fileType =
      arg.fileName.indexOf(".json") > -1 ? "text/json" : "text/plain";
    element.setAttribute(
      "href",
      `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`
    );
    element.setAttribute("download", arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  /* --------------------- download template ------------------------ */

  /*  ------------------------Back button method----------------------------- */
  onClickBack() {
    this._router.navigate([""]);
  }
  searchPayload() {
    const searchNavigationHold = {
      searchData: '',
      resultData: this.uniques
    };
    return searchNavigationHold;
  }
  viewResupplyDetails(itnRsnId) {
    let selectInd: any;
    // console.log(this.filterResupplyParent);
    // console.log(asnID);
    this.filterResupplyParent.filter(cs => cs.itnRsnId === itnRsnId).forEach(
      obj => {
        selectInd = obj;
      }
    );
     console.log(selectInd);
    
    this._navigationLinkParameterService.challanResupDetails = selectInd;
    this._navigationLinkParameterService.searchResupHeaderData = this.resupForm.value;
    this._router.navigate(['/rds-resupply-details']);
  
    }
  /*  ------------------------Back button method----------------------------- */
  //added by debamala dated 28-7-20
  characterCheck(event, name) {
     //modified by priti dated 12-10-20
    // var regExpr = /[^a-zA-Z0-9-. ]/g;
    var regExpr = /[^a-zA-Z0-9]/g;
    var regExpr2 = /[^0-9]/g;
    var userText = event.target.value;
    if (userText != "") {
      if (name == "destShopNumber") {
        this.resupForm.controls["destShopNumber"].setValue(
          userText.replace(regExpr2, "")
        );
        userText = this.resupForm.value.destShopNumber;
         if (this.resupForm.value.destShopNumber != "") {
          // console.log('userText'); console.log(userText); console.log(userText.length);
        if (userText.length > 5) {
          var res = userText.substr(0, 5);
          this.resupForm.controls["destShopNumber"].setValue(res);
          // console.log(this.resupForm.value.destShopNumber);
        } else {
        //  console.log(this.resupForm.value.destShopNumber.length); console.log('destShopNumber.length');
          if (userText.length != 5 || this.resupForm.value.destShopNumber.length != 5) {
            this.destFlag = true;
          } else {
            this.destFlag = false;
          }
        }
       }
      }
      if (name == "sourceShopNumber") {
        this.resupForm.controls["sourceShopNumber"].setValue(
          userText.replace(regExpr2, "")
        );
        userText = this.resupForm.value.sourceShopNumber;
         if (this.resupForm.value.sourceShopNumber != "") {
          // console.log('userText'); console.log(userText); console.log(userText.length);
        if (userText.length > 5) {
          var res = userText.substr(0, 5);
          this.resupForm.controls["sourceShopNumber"].setValue(res);
          // console.log(this.resupForm.value.sourceShopNumber);
        } else {
        //  console.log(this.resupForm.value.sourceShopNumber.length); console.log('sourceShopNumber.length');
          if (userText.length != 5 || this.resupForm.value.sourceShopNumber.length != 5) {
            this.sourceFlag = true;
          } else {
            this.sourceFlag = false;
          }
        }
       }
      }
      if (name == "partyNumber") {
        this.resupForm.controls["partyNumber"].setValue(
          userText.replace(regExpr2, "")
        );
        userText = this.resupForm.value.partyNumber;
         if (this.resupForm.value.partyNumber != "") {
          // console.log('userText'); console.log(userText); console.log(userText.length);
        if (userText.length > 3) {
          var res = userText.substr(0, 3);
          this.resupForm.controls["partyNumber"].setValue(res);
          // console.log(this.resupForm.value.partyNumber);
        } else {
        //  console.log(this.resupForm.value.partyNumber.length); console.log('partyNumber.length');
          if (userText.length != 3 || this.resupForm.value.partyNumber.length != 3) {
            this.partyFlag = true;
          } else {
            this.partyFlag = false;
          }
        }
       }
      }
      if (name == "resupplySerialNo") {
        this.resupForm.controls["itnRsnSerialNumber"].setValue(
          userText.replace(regExpr, "")
        );
       
        if (userText.length > 20) {
          var res = userText.substr(0, 20);
          this.resupForm.controls["itnRsnSerialNumber"].setValue(res);
        } else {
          if (userText.length < 3) {
            this.serialFlag = true;
          } else {
            this.serialFlag = false;
          }
        }
     
      }
      // if (name == "itoStnNumber") {
      //   this.resupForm.controls["itoStnNumber"].setValue(
      //     userText.replace(regExpr, "")
      //   );
      // }
      if (name == "bataWeekFrom") {
        this.resupForm.controls["bataWeekFrom"].setValue(
          userText.replace(regExpr2, "")
        );
        userText = this.resupForm.value.bataWeekFrom;
        if (this.resupForm.value.bataWeekFrom != "") {
        if (userText.length > 6) {
          var res = userText.substr(0, 6);
          this.resupForm.controls["bataWeekFrom"].setValue(res);
        } else {
          if (userText.length != 6 || this.resupForm.value.bataWeekFrom.length != 6) {
            this.weekfromFlag = true;
          } else {
            this.weekfromFlag = false;
          }
        }
      }
      }
      if (name == "bataWeekTo") {
        this.resupForm.controls["bataWeekTo"].setValue(
          userText.replace(regExpr2, "")
        );
        userText = this.resupForm.value.bataWeekTo;
        if (this.resupForm.value.bataWeekTo != "") {
        if (userText.length > 6) {
          var res = userText.substr(0, 6);
          this.resupForm.controls["bataWeekTo"].setValue(res);
        } else {
          if (userText.length != 6 || this.resupForm.value.bataWeekTo.length != 6) {
            this.weektoFlag = true;
          } else {
            this.weektoFlag = false;
          }
        }
      }
      }
      if(this.destFlag == false && this.partyFlag == false && this.weekfromFlag == false && this.weektoFlag == false && this.serialFlag == false){
        this.ShowSearchDisable = false;
      } else { this.ShowSearchDisable = true; }
    } else {
      // this.ShowSearchDisable = false;
      if(this.destFlag == false && this.partyFlag == false && this.weekfromFlag == false && this.weektoFlag == false && this.serialFlag == false){
        this.ShowSearchDisable = false;
      } else { 
        this.ShowSearchDisable = true;
        if (name == "destShopNumber") {
          this.destFlag = false;
        }
        if(name == "sourceShopNumber"){
          this.sourceFlag = false;
        }
        if (name == "bataWeekFrom") {
          this.weekfromFlag = false;
        }
        if (name == "bataWeekTo") {
          this.weektoFlag = false;
        }
        if (name == "resupplySerialNo") {
          this.serialFlag = false;
        }
        if(name == 'partyNumber'){
          this.partyFlag = false;
        }
       }
    }
  }
   //modified by priti dated 12-10-20
  //added by debamala dated 28-7-20
}
