export class GeneratePo {
    componentCode:string;
    componentName:string;
    articleNumber:string;
    articleName:string;
    customerArticleNumber:string;
    color:string;
    standardCost:string;
    customer:string;
    uom:string;
    componentId:string;
    componentStatus:string;
    endDate:string;
    createdBy:string;
    createdDate:string;
    lastUpdatedBy:string;
    lastUpdatedDate:string;
    deliveryFromWeek: string;
    deliveryToWeek: string;
    country:string;
    partyNo:string;
    partyShortName:string;
    cmpData:any;

   constructor(){}
}
