import { RdsBilApiUrl } from '@/common/rds-bil-api-url';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DefectiveArticlesService {
  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  search_defective_url: string = RdsBilApiUrl.SEARCH_DEFECTIVE_ARTICLES;
  downloadUrl : string = RdsBilApiUrl.DEFECTIVE_ARTICLES_TXN_TEMPLATE;
  rdcListLovUrl : string = RdsBilApiUrl.SEARCH_LOCATION_URL;
  factoryUrl : string = RdsBilApiUrl.SHOP_MASTER_URL;
  constructor(private http:HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this.http.post(this.concept_url, conceptQueryMas);
  }

  searchDefectiveData(searchParam){
    return this.http.post(this.search_defective_url, searchParam);
  }

  getRDCdata(details){
    return this.http.post(this.rdcListLovUrl,details);
  }

  getFactoryCode(details : any){
    return this.http.post(this.factoryUrl,details);
  }

  public async getDownloadTemplate(details : any): Promise<void> {
  const file = await this.http.post<Blob>(this.downloadUrl,details, { responseType: "blob" as "json" }).toPromise();
  const blob = file;
  const url = window.URL.createObjectURL(blob);
  var anchor = document.createElement("a");
  anchor.download = 'Defective_Articles_Template' + ".xlsx";
  anchor.href = url;
  anchor.click();
  window.URL.revokeObjectURL(url);
 }
}
