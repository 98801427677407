export class BilApiUrl{

     public static BASE_URL:string = 'https://biscs.bata.com/';
     public static SEARCH_REQUISITION_HEADER_URL:string = BilApiUrl.BASE_URL +'requisition/searchrequisitionheader';
     public static SEARCH_REQUISITION_LINES_URL:string = BilApiUrl.BASE_URL +'requisition/searchrequisitionline';
     public static MULTIPLE_ARTICLE_DISTRIBUTION:string = BilApiUrl.BASE_URL +'requisition/importMultipleRequisition';
      public static CONCEPT_LIST_ALL_URL: string = BilApiUrl.BASE_URL +'mdm/queryconceptmasterall';

     public static CONCEPT_LIST_URL: string = BilApiUrl.BASE_URL +'common/queryConceptMasterByUser';
     public static PO_HEADERS_URL:string = BilApiUrl.BASE_URL +'po/querypolist';
     public static PO_DETAILS_URL:string = BilApiUrl.BASE_URL +'po/querypodetail';
     public static PENDING_PO_DETAILS_URL:string = BilApiUrl.BASE_URL +'po/pendingPoDetails';
     public static CREATE_PO_FROM_REQUISITION_DETAILS_URL:string = BilApiUrl.BASE_URL +'requisition/createPoFromRequisition';
     public static  SEARCH_PO_LIST_URL:string = BilApiUrl.BASE_URL +'po/getPoNumberByReqId';
     // public static  SEARCH_LOCATION_URL:string = BilApiUrl.BASE_URL +'mdm/queryShopMaster';
     public static  SEARCH_LOCATION_URL:string = BilApiUrl.BASE_URL +'common/queryShopMasterByUser';
     public static  ASSORTMENT_CODE_VALIDDATE_URL:string = BilApiUrl.BASE_URL +'mdm/validateCreateWaPackingMaster';
     public static  ASSORTMENT_CODE_URL:string = BilApiUrl.BASE_URL +'mdm/queryPackingMasterNew';
     public static  ADD_REQUISITION_DISTRIBUTION:string = BilApiUrl.BASE_URL +'requisition/addRequisitionDistribution';
     public static  SEARCH_REQUISITION_DISTRIBUTION:string = BilApiUrl.BASE_URL +'requisition/searchRequisitionDistribution';
     public static REQ_ASSORTMENT_SIZE_INFO_DETAILS:string = BilApiUrl.BASE_URL +'requisition/searchReqAssortmentSizeInfo';
     public static  FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL:string = BilApiUrl.BASE_URL +'mdm/querySizeMaster';
     public static  FETCH_ASSORTMENT_BY_GROUPCODE_URL:string = BilApiUrl.BASE_URL +'mdm/querySizeMasterByGroupCode';
     public static VIEW_SHIPMENT_DETAILS:string = BilApiUrl.BASE_URL + 'po/viewShipmentDetails';
     public static PO_LOCATION_DETAILS_URL:string = BilApiUrl.BASE_URL +'po/queryPODetailRDCWise';
     public static  IMPORT_ARTICLE_MASTER:string=BilApiUrl.BASE_URL+'mdm/queryimportarticle';
     public static IMPORT_ARTICLE_MASTER_PRICE_SAVE:string=BilApiUrl.BASE_URL+'mdm/addimportarticle';
     public static  IMPORT_ARTICLE_PRICE_MASTER:string=BilApiUrl.BASE_URL+'mdm/queryArticleMaster';
     public static REPORT_NAMES:string=BilApiUrl.BASE_URL+'common/queryReportList';
     public static USER_AUTH_URL: string = BilApiUrl.BASE_URL +'common/userGroupAssignmentList';
	  public static SEARCH_PACK_MASTER: string = BilApiUrl.BASE_URL +'mdm/queryPackingMasterForSearch';
     public static ADD_PACK_MASTER: string = BilApiUrl.BASE_URL +'mdm/addPackingMasterNew';
     public static IMPORT_PO_UPDATE: string = BilApiUrl.BASE_URL +'mdm/getFobDetailsByArtNos';
     public static OTHER_PO_UPDATE: string = BilApiUrl.BASE_URL +'mdm/queryArticleByArtNumbers';
     public static  DELETE_DETAILS:string=BilApiUrl.BASE_URL+'po/updatePoStatus';
     public static  UPDATE_DETAILS:string=BilApiUrl.BASE_URL+'po/updateStdPriceOrFobPrice';
     public static PENDING_URL:string=BilApiUrl.BASE_URL+'po/pendingPoStatus';
     public static  ADD_PO_DISTRIBUTION:string = BilApiUrl.BASE_URL +'po/updatePoDetails';
	 public static DELETE_PACK_MASTER:string=BilApiUrl.BASE_URL+'mdm/deletePackingData';
     public static  GENERATE_IMPORT_PO:string = BilApiUrl.BASE_URL +'po/importPoCreation';
     public static  GENERATE_FOB_PRICE_URL:string = BilApiUrl.BASE_URL +'mdm/addimportarticleInterface';
     public static CATEGORY_CODE_LIST_URL: string = BilApiUrl.BASE_URL +'mdm/queryCategoryMasterNew';
     public static SHOP_CODE_LIST_URL: string = BilApiUrl.BASE_URL +'mdm/queryShopMasterNew';
     public static  DATE_CHANGE:string=BilApiUrl.BASE_URL+'mdm/queryWeekMasterNew';
     public static USER_AUTH_LOGIN_URL:string = BilApiUrl.BASE_URL +'common/bilUserLogin';
     public static GET_REFRESH_TOKEN:string = BilApiUrl.BASE_URL +'common/bilUserTokenRefresh';
     public static PO_DATE_CHANGE:string = BilApiUrl.BASE_URL +'po/dateShiftOrExtension';
     public static PO_BULK_CANCELLATION:string = BilApiUrl.BASE_URL +'po/poBulkCancellation';

     public static  BATCH_SERVICES_URL:string=BilApiUrl.BASE_URL+'po/poAutoCancellation';
     public static  BATCH_SERVICES_BARCODE_URL:string=BilApiUrl.BASE_URL+'po/barcodePOInt';
     public static  BATCH_SERVICES_ORDER_TYP_URL:string=BilApiUrl.BASE_URL+'mdm/fetchConfigDetails';
     public static  BATCH_SERVICES_CON_CODE_URL:string=BilApiUrl.BASE_URL+'common/queryConceptMasterByUser';
     public static  BATCH_SERVICES_SUBMIT_CODE_URL:string=BilApiUrl.BASE_URL+'po/poAutoCancellation';
     public static  BATCH_SERVICES_UPLOAD_URL:string=BilApiUrl.BASE_URL+'po/barcodePOInt';

     public static  COMMON_UPLOAD_API_URL:string=BilApiUrl.BASE_URL+'utility/uploadToStorage';
     public static  COMMON_DOWNLOAD_URL:string=BilApiUrl.BASE_URL+'utility/download';
     public static  COMMON_CONFIG_FETCH_URL:string=BilApiUrl.BASE_URL+'mdm/fetchConfigDetails';

     public static  GENERATE_PO_URL:string=BilApiUrl.BASE_URL+'mdm/queryarticlecomponent';
     public static  GENERATE_PO_OTHER_URL:string=BilApiUrl.BASE_URL+'mdm/querypartymaster';
     public static  CREATE_PO_COMP_URL:string=BilApiUrl.BASE_URL+'mdm/createarticlecomponent';

     public static  PURCHASE_ORDER_PRINTING_REPORT_URL:string=BilApiUrl.BASE_URL+'report/poReport';
     public static  ESTIMATE_REPORT_URL:string=BilApiUrl.BASE_URL+'report/estimateMasterReport';
     public static  ONORDER_SIZE_REPORT_URL:string=BilApiUrl.BASE_URL+'report/orderSizeReport';
     public static  FUTUREORDER_REPORT_URL:string=BilApiUrl.BASE_URL+'report/futureOrderReport';
     public static  ONWAY_REPORT_URL:string=BilApiUrl.BASE_URL+'report/onWayReport';
     public static  SHOP_REPORT_URL:string=BilApiUrl.BASE_URL+'report/shopMasterReport';
     public static  CATEGORY_REPORT_URL:string=BilApiUrl.BASE_URL+'report/categoryMasterReport';
     public static  ARTICLE_REPORT_URL:string=BilApiUrl.BASE_URL+'report/articleMasterReport';
     public static  STATE_REPORT_URL:string=BilApiUrl.BASE_URL+'report/stateMasterReport';
     public static  WEEK_REPORT_URL:string=BilApiUrl.BASE_URL+'report/weekMasterReport';
     public static  PARTY_REPORT_URL:string=BilApiUrl.BASE_URL+'report/partyMasterReport';
     public static  PACKING_REPORT_URL:string=BilApiUrl.BASE_URL+'report/packMasterReport';
     public static  FACOP_UNIT_REPORT_URL:string=BilApiUrl.BASE_URL+'report/factoryOperationsUnitReport';
     public static  FACOP_OTB_REPORT_URL:string=BilApiUrl.BASE_URL+'report/factoryOperationsOTBReport';
     public static  ORDER_MASTER_REPORT_URL:string=BilApiUrl.BASE_URL+'report/orderMasterReport';
     public static  ORDER_FOLLOW_INVOICE_REPORT_URL:string=BilApiUrl.BASE_URL+'report/orderFollowInvoiceReport';
     public static  SUPPLIER_PERFM_REPORT_URL:string=BilApiUrl.BASE_URL+'report/supplierPerfmReport';
     public static  ORDER_STATUS_REPORT_URL:string=BilApiUrl.BASE_URL+'report/orderStatusReport';
     public static  RDC_MASTER_REPORT_URL:string=BilApiUrl.BASE_URL+'report/rdcMasterReport';
     public static  SUPPLY_MASTER_REPORT_URL:string=BilApiUrl.BASE_URL+'report/supplyMasterReport';
     public static  RECAP_ORDER_RDC_RECV_REPORT_URL:string=BilApiUrl.BASE_URL+'report/recapVsRdcRcvReport';
     public static  CONCEPT_MASTER_REPORT_URL:string=BilApiUrl.BASE_URL+'report/conceptMasterReport';
     public static  SUPPLY_RDC_DECV_REPORT_URL:string=BilApiUrl.BASE_URL+'report/supplyRdcRecvReport';
     public static  RETAIL_ORDER_ONWAY_REPORT_URL:string=BilApiUrl.BASE_URL+'report/rtlOrderOnwayReport';
     public static  OUTSTANDING_FOR_DIST_REPORT_URL:string=BilApiUrl.BASE_URL+'report/outstandingForDistReport';
     public static  OFFLINE_REPORT_URL:string=BilApiUrl.BASE_URL+'report/asyncReportGen';
     public static  PO_AUTOCANCELLATION:string=BilApiUrl.BASE_URL+'po/poAutoCancellation';



     public static FETCH_CONFIG_DETAILS: string = BilApiUrl.BASE_URL + 'mdm/fetchConfigDetails';
     public static CONCEPT_MASTER: string = BilApiUrl.BASE_URL + 'common/queryConceptMasterByUser';
     public static JDE_PO_UPLOAD: string = BilApiUrl.BASE_URL + 'po/createfactorypo';
     public static ADD_ARTICLE_MASTER: string = BilApiUrl.BASE_URL + 'mdm/addArticleMaster';
     public static ADD_CATEGORY_MASTER: string = BilApiUrl.BASE_URL + 'mdm/addCategoryMaster';
     public static ADD_STATE_MASTER: string = BilApiUrl.BASE_URL + 'mdm/addStateMaster';
     public static ADD_WEEK_MASTER: string = BilApiUrl.BASE_URL + 'mdm/addWeekMaster';
     public static ADD_SHOP_MASTER: string = BilApiUrl.BASE_URL + 'mdm/addShopMaster';
     public static PARTY_MASTER_UPLOAD: string = BilApiUrl.BASE_URL + 'mdm/invokePartyMasterProc';
     public static OTB_PO_INTERFACE: string = BilApiUrl.BASE_URL + 'po/updateHeaderFromOTB';
     public static RDS_PO_INTERFACE: string = BilApiUrl.BASE_URL + 'po/createPOReceipt';
     public static DMS_PO_INTERFACE:string=BilApiUrl.BASE_URL+'po/createPOReceipt';
     public static SUPPLIER_PO_INTERFACE: string = BilApiUrl.BASE_URL + 'shipment/handleShipmentBatch';
     public static BARCODE_PO_INTERFACE: string = BilApiUrl.BASE_URL + 'po/barcodePOInt';
     public static PO_RIMS_ONWAY_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokeRimsOnWayJob';
     public static PO_RIMS_ONORDER_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokeRimsJob';
     public static PO_TO_OTB_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokejob';
     public static PO_TO_RDS_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokeRdcJob';
     public static PO_TO_DMS_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokeDmsJob';
     public static PO_TO_BARCODE_ORDER_MASTER_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokePoToBarcodeJob';
     public static PO_TO_BARCODE_ORDER_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokePoBarcodeOrderJob';
     public static PO_TO_PROMPS_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokePoPromsJob';
     public static PO_TO_WMS_INTERFACE: string = BilApiUrl.BASE_URL + 'po/invokeWmsJob';
     public static CONSUMABLE_PO_CREATION:string=BilApiUrl.BASE_URL+ 'po/consumablePoCreation';
     public static EXPORT_PO_COMPONENT:string=BilApiUrl.BASE_URL+'mdm/importarticlecomponent';
     public static MANAGE_CONCEPT_MASTER:string=BilApiUrl.BASE_URL+'mdm/queryconceptmaster';
     public static ADD_CONCEPT_MASTER:string=BilApiUrl.BASE_URL+'mdm/addConceptMaster';
     public static MANAGE_SIZE_MASTER:string=BilApiUrl.BASE_URL+'mdm/querySizeMaster';

     public static MANAGE_SUB_PARTY_MASTER:string=BilApiUrl.BASE_URL+'mdm/querysubpartymaster';
     public static ADD_SUB_PARTY_MASTER:string=BilApiUrl.BASE_URL+'mdm/addSubPartyMaster';
     public static COUNTRY_ALL_URL: string = BilApiUrl.BASE_URL +'mdm/fetchConfigDetails';
    // public static MANAGE_CONCEPT_MASTER:string=BilApiUrl.BASE_URL+'mdm/queryconceptmaster';
     //mdm/queryconceptmaster
     public static ESTIMATE_UPLOAD=BilApiUrl.BASE_URL+'mdm/addEstimateMaster';
     public static EXPORT_PO:string=BilApiUrl.BASE_URL+'po/exportPoCreation';
     public static BULK_RECALL:string=BilApiUrl.BASE_URL+'po/poBulkRecallProcess';
     public static PACK_MASTER:string=BilApiUrl.BASE_URL+'mdm/importPackingMasterAssorted';
     public static PACK_WHOLESALE:string=BilApiUrl.BASE_URL+'mdm/importPackingMasterWholesaleAssorted';
     public static REQUISITION_DISTRIBUTION:string=BilApiUrl.BASE_URL+'requisition/importrequisition';
     public static USER_CONCEPT_DETAILS:string=BilApiUrl.BASE_URL+'common/getUserConcepts';
     public static UPDATE_USER_CONCEPT_DETAILS:string=BilApiUrl.BASE_URL+'common/updateUserConceptStatus';
     public static CONCEPT_DETAILS: string = BilApiUrl.BASE_URL +'mdm/queryconceptmasterall';
     public static SUPPLIER_PARTY_NUMBER: string = BilApiUrl.BASE_URL +'mdm/fetchPartyNumberAndName';
     public static SUPPLIER_ACCESS_DETAILS:string=BilApiUrl.BASE_URL+'common/getSupplierDetails';
     public static UPDATE_SUPPLIER_ACCESS_DETAILS:string=BilApiUrl.BASE_URL+'common/updateUserSupplierActiveFlag';
     public static USER_DETAIL_URl: string = BilApiUrl.BASE_URL +'common/searchUserMaster';
     public static CREATE_USER_CONCEPT: string = BilApiUrl.BASE_URL +'common/createUserConcepts';
     public static CREATE_CC_LIST: string = BilApiUrl.BASE_URL +'mdm/addPartyMailMaster';
     public static FETCH_CC_DETAILS:string=BilApiUrl.BASE_URL+'mdm/fetchPartyMailMaster';
     public static CREATE_SUPPLIER_ACCESS: string = BilApiUrl.BASE_URL +'common/addUserSupplierNumberDetails';
     public static ADD_SIZE_MASTER: string = BilApiUrl.BASE_URL + 'mdm/addSizeMaster';
	 public static VIEW_UPLOAD_STATUS: string = BilApiUrl.BASE_URL + 'utility/getUserUploadData';
     public static BATCH_STATUS:string=BilApiUrl.BASE_URL+'utility/getBatchStatus';
     public static USER_GROUP_DETAILS:string=BilApiUrl.BASE_URL+'common/getUserRoles';
     public static CREATE_USER_GROUP:string=BilApiUrl.BASE_URL+'common/addUserRoles';
     public static USER_GROUP_LIST:string=BilApiUrl.BASE_URL+'common/searchGroupMaster';
     public static UPDATE_USER_GROUP_DETAILS:string=BilApiUrl.BASE_URL+'common/updateUserRoleStatus';
     public static REPORT_STATUS:string=BilApiUrl.BASE_URL+'common/queryBilReportAuditTrial';


     public static versionCheckURL:string= BilApiUrl.BASE_URL+'version.json';
     public static  OUTWARD_GATE_PASS_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/outwardGatePassReport';
     public static  OUTWARD_GATE_PASS_SHOP_NAME:string=BilApiUrl.BASE_URL+'mdm/queryShopMasterNew';
     public static  DAILY_DISPATCH_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/dailyDispatchReport';
     public static  NIL_VALUE_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/nilValueReport';
     public static  CLAIM_DISPATCH_TO_FACTORY_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/claimDispatchToFactoryReport';
     public static  RECEIPT_SUMMARY_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/receiptSummaryReportSNo15';
     public static  WEEK_RECEIPT_SUMMARY:string=BilApiUrl.BASE_URL+'mdm/weekmasterdate';
     public static  WEEKLY_RECEIPT_FROM_VENDOR_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/weeklyReceiptFromVendorReportSNo27';

     public static GOW_08B:string=BilApiUrl.BASE_URL+'report/rdc/rdcGOW08BReport';
     public static GOW_08BI:string=BilApiUrl.BASE_URL+'report/rdc/rdcGOW08BIReport';
     public static GOW_02:string=BilApiUrl.BASE_URL+'report/rdc/rdcGOW02Report';
     public static MATERIAL_08BI:string=BilApiUrl.BASE_URL+'report/rdc/rdcMaterialInTransitStoreToRdcReport';
     public static MATERIAL_08B:string=BilApiUrl.BASE_URL+'report/rdc/rdcMaterialInTransit08BReport';
     public static MATERIAL_02:string=BilApiUrl.BASE_URL+'report/rdc/rdcMaterialInTransit02RdcToStoreReport';
     public static RECAP_08B:string=BilApiUrl.BASE_URL+'report/rdc/gow1308BReCapRdcReport';
     public static RECAP_02:string=BilApiUrl.BASE_URL+'report/rdc/gow1302ReCapRdcReport';
     public static MATERIAL_RECEIPT_FROM_RDC_FACTORY:string=BilApiUrl.BASE_URL+'report/rdc/rdcMaterialReceiptFromRDCFactoryVendorStoreDepotReport';
     public static ONE_RDC_TO_ANOTHER:string=BilApiUrl.BASE_URL+'report/rdc/rdcDispatchReportOneRDCToAnotherRDC';

     public static resupply_Pending_Status:string=BilApiUrl.BASE_URL+'report/rdc/resupplyPendingStatusReport';
     public static RDS_DISPATCH_SUMMARY:string=BilApiUrl.BASE_URL+'report/rdc/rdcDispatchSummarySNO14Report';
     public static DISPATCH_ARTICLE_WISE:string=BilApiUrl.BASE_URL+'report/rdc/dispatchAritcleWiseReport';
     public static RECEIPT_ARTICLE_WISE:string=BilApiUrl.BASE_URL+'report/rdc/rdcReceiptArticleWiseReport';
     public static RDC_REPORT_STOCK_TYPE_CONCEPT:string=BilApiUrl.BASE_URL+'report/rdc/rdcReportStockTypeConceptRdcWiseReport';
     public static PHYSICAL_INV_MISMATCH:string=BilApiUrl.BASE_URL+'report/rdc/rdcPhysicalInventoryArticleMismatchReport';
     public static RDC_RECEIPT_SUMMARY_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/goodsReceiptAndIssueTransitionSummaryReport';
     public static RDC_STOCK_ADJUSTMENT_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/rdcStockAdjustmentReport';
     public static GSTR_1_8:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR_1_8Report';
     public static GSTR_1_9_HSN_WISE:string=BilApiUrl.BASE_URL+'report/rdc/rdcHSNWiseSummaryOutwardReport';
     public static GSTR_2_9_INWARD_HSN:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR2_9InwardHSNSummaryReport';
     public static SHORTAGE_FAC_VEN:string=BilApiUrl.BASE_URL+'report/rdc/rdcShortageExcessReport';
     public static GSTR_2_1:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR_2_1Report';
     public static GSTR_2_1_IMPORT:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR_2_1_ImportReport';
     public static GSTR_STOCK_CORRECTION_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTRStockCorrectionReport';
     public static INVOICE_CANCELLATION_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/invoiceCancellationReport';
     public static GSTR_SHORTAGE:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR_ShortageReport';
     public static INVOICE_PRINT_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/printInvoiceReport';
     public static FINANCE_SCRATCHING:string=BilApiUrl.BASE_URL+'report/rdc/rdcFinanceScratching';
     public static AGED_STOCK:string=BilApiUrl.BASE_URL+'report/rdc/rdcAgedStockSNo28Report';
     public static GST_RECONCILIATION_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/gstReconciliationReport';
     public static GSTR_1_1:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR_1_1Report';
     public static GSTR_RETURN_MONTHLY_WISE:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR_1_ReturnMonthlySummary';
     public static GSTR_E_WAY_BILL:string=BilApiUrl.BASE_URL+'report/rdc/rdcE_WayBillReport';
     public static GSTR_NIL_VALUE:string=BilApiUrl.BASE_URL+'report/rdc/rdcGSTR_NilValueReport';
     public static CLOSING_STOCK:string=BilApiUrl.BASE_URL+'report/rdc/closingStkSNo11Report';
     public static PRICE_VARIANCE_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/rdcInventoryPriceVarianceReport';
     public static RDC_ACCOUNTING_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/rdcStockAccountingReport';
     public static DEFECTIVE_ACCOUNTING_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/rdcDefectiveAccountingReport';
     public static BATA_YEAR_WEEK:string=BilApiUrl.BASE_URL+'mdm/queryWeekMasterNew';
     public static RDC_Freight_REGISTER:string=BilApiUrl.BASE_URL+'report/rdc/rdcFreightRegister';
     public static RDC_STOCK_STATEMENT:string=BilApiUrl.BASE_URL+'report/rdc/rdcStockStatementReport';
     public static CLAIM_ACCOUNTING_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/rdcClaimAccountingReport';
     public static CONTINUOUS_INVOICE_PRINT_REPORT:string=BilApiUrl.BASE_URL+'report/rdc/continuousPrintInvoiceReport';

     public static REQUISITION_UPLOAD_BATCH:string=BilApiUrl.BASE_URL+'requisition/invokeRequisitionBatch';
     public static REQUISITION_SIZE_INFO:string=BilApiUrl.BASE_URL+'requisition/getRequisitionSizeInfo';
     public static APPROVE_PO_BATCH:string=BilApiUrl.BASE_URL+'po/approvePo';
     public static  UPDATE_ALL_PO_PRICE_DETAILS:string=BilApiUrl.BASE_URL+'po/syncAllPoData';
     public static PO_PDF_BATCH:string=BilApiUrl.BASE_URL+'report/generatePrpaPdf';
  public static AUTO_PO_INVOKE:string=BilApiUrl.BASE_URL+'requisition/autoRequisitionPoInvoke';
 }

