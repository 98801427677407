import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import { throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '../common/bil-api-url';
import { EditPackMasterDetails } from '../search-packing-master-edit/edit-pack-master';
import { SearchPackMasterDetails } from '../search-packing-master/search-pack-master-details';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class CreatePackingMasterService {
  apiUrl=BilApiUrl.COMMON_UPLOAD_API_URL;
  URL :string=BilApiUrl.COMMON_DOWNLOAD_URL;
  pack_master_url: string = BilApiUrl.ADD_PACK_MASTER;
  article_no_url: string = BilApiUrl.IMPORT_ARTICLE_PRICE_MASTER;
  size_master_url: string = BilApiUrl.FETCH_ASSORTMENT_BY_GROUPCODE_URL;
  editedDataList: EditPackMasterDetails[] = [];
  urlForDownloadUrl:string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  //editedDataList: EditPackMasterDetails[] = [];
  EntiredDownloadedUrl:string;
  wholeDownloadedUrl:string;
  generateImportPo:string=BilApiUrl.PACK_MASTER
  generatedImportPo:string=BilApiUrl.PACK_WHOLESALE;
  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
  category_code_url: string = BilApiUrl.CATEGORY_CODE_LIST_URL;
  shop_code_url: string = BilApiUrl.SEARCH_LOCATION_URL;
  arr:any[] = [];
  constructor(private _http:HttpClient) { }

  upload(formData) {
    return this._http.post(`${this.apiUrl}`, formData);
  }

  upload1(formData1) {
 return this._http.post(`${this.apiUrl}`, formData1);
  }
  getArticleNumber(requestParameterModels: RequestParameterModel[]) {
    console.log('paramters for getting article number' + JSON.stringify(requestParameterModels));
    return this._http.post(this.article_no_url, requestParameterModels);
  }

  getSizeDetails(groupCode){
    console.log('****group Code**** ' +JSON.stringify(groupCode));
    return this._http.post(this.size_master_url, groupCode);
  }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }

  getCategoryCode(){
    return this._http.post(this.category_code_url, this.arr);
  }

  getShopCodeByUser(requestParameterModel){
    return this._http.post(this.shop_code_url, requestParameterModel);
  }


  saveEditPackingDetails(editPackMasterDetails: SearchPackMasterDetails[]) {
    //this.editedDataList.push(editPackMasterDetails);
    //console.log('Data that is getting edited' + JSON.stringify(this.editedDataList));
    return this._http.post(this.pack_master_url, editPackMasterDetails);
  }

  getDownloadFile(){
    return this._http.get(this.urlForDownloadUrl);
  }
  public  getGenerate(uploadKeyValues:any){
    //return this._http.post(this.generateImportPo,uploadKeyValues);
    return this._http.post(this.generateImportPo,uploadKeyValues, httpOptions);
   // return this.http.post(this.generateImportPo,uploadKeyValues);
  }
  public getGenerated(uploadKeyValues:any){
    return this._http.post(this.generatedImportPo,uploadKeyValues, httpOptions);
  }


}
