import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '@/common/bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class GeneratePoService {

  constructor(private _http: HttpClient) { }

  url = BilApiUrl.GENERATE_PO_URL;
  urll= BilApiUrl.GENERATE_PO_OTHER_URL;
  

  getImportServiceCriteria(articleNumber:string){
    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = 'articleNumber';
    requestParameterModel.value = articleNumber;
    purchase_order_search_parameters.push(requestParameterModel);
    console.log('purchase_order_search_parameters'+purchase_order_search_parameters);
    return this._http.post(this.url, purchase_order_search_parameters);

  }
   
  getImportServiceCriteria11(componentCode:string){
    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = 'componentCode';
    requestParameterModel.value = componentCode;
    purchase_order_search_parameters.push(requestParameterModel);
    console.log('purchase_order_search_parameters'+purchase_order_search_parameters);
    return this._http.post(this.url, purchase_order_search_parameters);

  }

  getImportService(partyNo:string){
    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = 'partyNo';
    requestParameterModel.value = partyNo;
    purchase_order_search_parameters.push(requestParameterModel);
    console.log('purchase_order_search_parameters'+purchase_order_search_parameters);
    return this._http.post(this.urll, purchase_order_search_parameters);

  }

}
