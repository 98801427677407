import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GRNHeader } from './grn-header';
import { GRNLine } from './grn-line';
import { SearchGrnService } from './search-grn.service';
import { UtilsService } from '../../common/utils-service';
// import { ShortageAssort } from './shortage-assortment';
import { NgForm } from '@angular/forms';
// import { from } from 'rxjs';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-grn',
  templateUrl: './search-grn.component.html',
  // styleUrls: ['./search-grn.component.css']
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css', './search-grn.component.css']
})
export class SearchGrnComponent implements OnInit {

  // @ViewChild('v', { static: false }) myInputVariable: NgForm;
  @ViewChild('GRNForm', { static: false }) myInputVariable: NgForm;
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  searchGRNModel = new GRNHeader();
  // searchGRNObj = new GRNLine();
  // searchGRNPoList = new GRNLine();
  searchGRNPoList: GRNLine[] = [];
  uniquesGRNList:  GRNLine[] = [];
  // singlePOEntry = new GRNLine();
  responseMessage: string;
  errormsg: string;
  show: boolean = false;
  // responseShortageData: ShortageAssort[] = [];
  // grnassormentArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'];
  currentPage : number = 1;
  duplicateFlag: boolean = false;

  searchType='header';
  pageNumber=1;
  itemsPerPage=10;
  totalItems:any;
  getpage:boolean = true;

  constructor(private searchGrnService: SearchGrnService, private utilsService: UtilsService,
              private _navigationLinkParameterService: NavigationLinkParameterService, private router: Router) { }

  ngOnInit() {
    let sessUsername = sessionStorage.getItem('currentUser');
    this.searchGRNModel.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
    this.pageNumber=1;
      this.currentPage=1;
    //console.log(this._navigationLinkParameterService.grndetails);
    if(this._navigationLinkParameterService.grndetails!='' && this._navigationLinkParameterService.grndetails!=undefined)
    {
      console.log('CHECK');
      this.searchGRNModel=this._navigationLinkParameterService.grndetails;
      this.searchGRNModel.searchType=this.searchType;
      this.searchGRNModel.pageNumber=1;
      this.currentPage=1;
      this.searchGRNModel.pageSize=this.itemsPerPage;
      this.grnBySearchCriteria(this.searchGRNModel);
    }
    
    this._navigationLinkParameterService.grndetails='';

    if (this._navigationLinkParameterService.searchGRNHeaderData === undefined) {
      //console.log('New Search has to be made');
      //console.log(this._navigationLinkParameterService.searchGRNHeaderData);
    } else {
      console.log('Automatic Search will be performed');
      //console.log(this._navigationLinkParameterService.searchGRNHeaderData);
      this.searchGRNModel.searchType=this.searchType;
      this.searchGRNModel.pageNumber=1;
      this.currentPage=1;
      this.searchGRNModel.pageSize=this.itemsPerPage;
      this.searchGRNModel = this._navigationLinkParameterService.searchGRNHeaderData.searchData;
      // this.searchGRNPoList = this._navigationLinkParameterService.searchGRNHeaderData.resultData;
      this.uniquesGRNList = this._navigationLinkParameterService.searchGRNHeaderData.resultData;
      //console.log( this.uniquesGRNList);
      this.show=true;
      this._navigationLinkParameterService.searchGRNHeaderData = undefined;
      if(this.searchGRNModel.fromGrnCreateDate!= null && this.searchGRNModel.fromGrnCreateDate!= null){
        this.getpage=false;
      }
      console.log(this.searchGRNModel);
      this.grnBySearchCriteria(this.searchGRNModel);
    }
    
  }

  searchPayload() {
    const searchNavigationHold = {
      searchData: this.searchGRNModel,
      resultData: this.uniquesGRNList
    };
    return searchNavigationHold;
  }

  submitSearchGRNForm(form: NgForm) {
    this.loaDer.nativeElement.style.display = 'block';
    this.responseMessage = '';
    this.errormsg = '';
    if(form.invalid){
      this.responseMessage ="Invalid Data could not be proceesed";
      this.loaDer.nativeElement.style.display = 'none';
      return false;
    }
    this.searchGRNModel.searchType=this.searchType;
    this.searchGRNModel.pageNumber=1;
    this.pageNumber=1;
    this.currentPage=1;
    this.searchGRNModel.pageSize=this.itemsPerPage;
    
    this.grnBySearchCriteria(this.searchGRNModel);

  }

  grnBySearchCriteria(searchGRNModel: GRNHeader) {
    this.responseMessage = '';
    this.show = false;
    let fromDate= new Date();
    let toDate= new Date();
    console.log(fromDate);
    console.log(toDate);

    console.log(JSON.stringify(this.searchGRNModel));
    if(this.searchGRNModel.fromGrnCreateDate !=null && this.searchGRNModel.toGrnCreateDate !=null){
    fromDate = new Date(this.searchGRNModel.fromGrnCreateDate);
    toDate= new Date(this.searchGRNModel.toGrnCreateDate);
      if(this.searchGRNModel.fromGrnCreateDate.toString().includes('/') && this.searchGRNModel.toGrnCreateDate.toString().includes('/')){
        let fromDateArray:any[] = this.searchGRNModel.fromGrnCreateDate.split('/');
        let toDateArray:any[] = this.searchGRNModel.toGrnCreateDate.split('/');
        fromDate = new Date(fromDateArray[2],fromDateArray[1] - 1,fromDateArray[0]);
        toDate = new Date(toDateArray[2],toDateArray[1] - 1,toDateArray[0]);
      }
      if(this.searchGRNModel.fromGrnCreateDate.toString().includes('/') && this.searchGRNModel.toGrnCreateDate.toString().includes('/')){
        let fromDateArray:any[] = this.searchGRNModel.fromGrnCreateDate.split('/');
        let toDateArray:any[] = this.searchGRNModel.toGrnCreateDate.split('/');
        fromDate = new Date(fromDateArray[2],fromDateArray[1] - 1,fromDateArray[0]);
        toDate = new Date(toDateArray[2],toDateArray[1] - 1,toDateArray[0]);
      }
      let fromDateStr = this.utilsService.transformDateSearch(fromDate);
      let toDateStr = this.utilsService.transformDateSearch(toDate);
      this.searchGRNModel.fromGrnCreateDate=fromDateStr;
      this.searchGRNModel.toGrnCreateDate=toDateStr;
      
      console.log(fromDateStr);
    }
    if ((this.searchGRNModel.invoiceNumber != null && this.searchGRNModel.invoiceNumber != "")|| 
      (this.searchGRNModel.consignorCode != null && this.searchGRNModel.consignorCode != "")||
      (this.searchGRNModel.grnNumber != null && this.searchGRNModel.grnNumber != "")||
      (this.searchGRNModel.asnNumber != null && this.searchGRNModel.asnNumber != "")||
      (this.searchGRNModel.partyNumber != null && this.searchGRNModel.partyNumber != "")||
      ((this.searchGRNModel.bataWeekFrom != null && this.searchGRNModel.bataWeekTo != null)&&
       (this.searchGRNModel.bataWeekFrom != "" && this.searchGRNModel.bataWeekTo != "")) || (this.searchGRNModel.fromGrnCreateDate != null && this.searchGRNModel.toGrnCreateDate != null)) {

        if(this.searchGRNModel.bataWeekFrom > this.searchGRNModel.bataWeekTo){
          this.responseMessage = '';
          this.errormsg='BataWeekFrom should be less than BataWeekTo';
          this.loaDer.nativeElement.style.display = 'none';
          return false;
        }
        console.log(fromDate.getTime());
        console.log(toDate.getTime());
        if(fromDate.getTime() > toDate.getTime()){
          this.responseMessage = '';
          this.errormsg='GRN From Date should be less than GRN To Date';
          this.loaDer.nativeElement.style.display = 'none';
          return false;
        }
      this.searchGrnService.getGrnBySearchCriteria(searchGRNModel).subscribe(
        (response: any) => {

          if (response.dataList != '') {
            this.show = true;
            this.searchGRNPoList = response.dataList;
            this.totalItems=response.recordCount;
            this.uniquesGRNList = this.searchGRNPoList.filter(function(a) {
               const key=a.grnNumber;
              if (!this[key]) {
                  this[key] = true;
                  return true;
              }
          }, Object.create(null));
          //console.log('unique list'); //console.log(this.uniquesGRNList);
            this.searchGRNPoList.forEach(
              dates => {
                if(dates.grnCreateDate != null){
                  dates.grnCreateDateAfterConvert = this.utilsService.transformDate1(dates.grnCreateDate);
                //console.log( dates.grnCreateDateAfterConvert);
              }}
            );
        //  for(let i=0;i < this.searchGRNPoList.length; i++){
        //    this.duplicateFlag = false;
        //    for(let k = i-1;k>=0;k--){
        //     if(this.searchGRNPoList[i].grnNumber == this.searchGRNPoList[k].grnNumber){
        //        this.duplicateFlag = true;
              
        //     }
        //    }
        //    if(this.duplicateFlag == false){
        //    this.searchGRNPoList[i].sumLineRcvdPairQty = this.searchGRNPoList[i].rcvdPairQty;
        //    this.searchGRNPoList[i].sumLineShortagePairQty = this.searchGRNPoList[i].shortagePairQty;
        //    this.searchGRNPoList[i].grnValue = this.searchGRNPoList[i].mrp *(this.searchGRNPoList[i].rcvdPairQty + this.searchGRNPoList[i].shortagePairQty);
        //    this.searchGRNPoList[i].sumLineCartonQty = this.searchGRNPoList[i].cartonQty;
        //    this.searchGRNPoList[i].sumLineShortageCartonQty = this.searchGRNPoList[i].shortageCartonQty;
         
        //    for(let j=i+1; j <this.searchGRNPoList.length;j++){
        //     if(this.searchGRNPoList[i].grnNumber == this.searchGRNPoList[j].grnNumber){
        //       //console.log('same grn no.'); 
        //       this.searchGRNPoList[i].sumLineRcvdPairQty += this.searchGRNPoList[j].rcvdPairQty;
        //       this.searchGRNPoList[i].sumLineShortagePairQty += this.searchGRNPoList[j].shortagePairQty;
        //       this.searchGRNPoList[i].grnValue += (this.searchGRNPoList[j].mrp * (this.searchGRNPoList[j].rcvdPairQty + this.searchGRNPoList[j].shortagePairQty));
        //       this.searchGRNPoList[i].sumLineCartonQty += this.searchGRNPoList[j].cartonQty;
        //       this.searchGRNPoList[i].sumLineShortageCartonQty += this.searchGRNPoList[j].shortageCartonQty;
        //       //console.log(this.searchGRNPoList[i].sumLineRcvdPairQty);
             
        //     }
        //   }
        //   this.uniquesGRNList.filter(a=> a.grnNumber == this.searchGRNPoList[i].grnNumber).forEach(
        //     obj =>{
        //       //console.log(this.searchGRNPoList[i].sumLineRcvdPairQty);
        //         obj.sumLineRcvdPairQty = this.searchGRNPoList[i].sumLineRcvdPairQty;
        //         obj.sumLineShortagePairQty = this.searchGRNPoList[i].sumLineShortagePairQty;
        //         obj.grnValue = this.searchGRNPoList[i].grnValue;
        //         obj.sumLineCartonQty = this.searchGRNPoList[i].sumLineCartonQty;
        //         obj.sumLineShortageCartonQty = this.searchGRNPoList[i].sumLineShortageCartonQty;
        //         //console.log(this.searchGRNPoList[i].grnValue);
        //     }
        //   );}
        // }
   
            // this.searchGRNObj = response.dataList[0];
          } else {
            this.responseMessage = 'No Data Found';
            this.show = false;
            this.uniquesGRNList = [];
            // this.searchGRNDataList = [];
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (error) => {
          this.responseMessage = error;
          this.show = false;
          this.errormsg = '';
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    } else if( ( (this.searchGRNModel.bataWeekFrom != null && this.searchGRNModel.bataWeekFrom != "") && (this.searchGRNModel.bataWeekTo == null || this.searchGRNModel.bataWeekTo == "")) ||
    ( (this.searchGRNModel.bataWeekFrom == null || this.searchGRNModel.bataWeekFrom == "") && (this.searchGRNModel.bataWeekTo != null && this.searchGRNModel.bataWeekTo != "") ) ) {
      this.responseMessage = '';
      this.errormsg = 'Please fill  the fields BataWeekFrom & BataWeekTo';
      this.loaDer.nativeElement.style.display = 'none';
    } else if( ( (this.searchGRNModel.fromGrnCreateDate != null) && (this.searchGRNModel.toGrnCreateDate == null)) ||
    ( (this.searchGRNModel.fromGrnCreateDate == null ) && (this.searchGRNModel.toGrnCreateDate != null) ) ) {
      this.responseMessage = '';
      this.errormsg = 'Please fill  the fields GRN From Date & GRN To Date';
      this.loaDer.nativeElement.style.display = 'none';
    }
    else {
     
      this.responseMessage = '';
      this.errormsg = 'Please fill any search criteria';
      // this.errormsg = 'Please fill Invoice number OR Consignor Code OR GRN Number OR ASN Number OR (BataWeekFrom & BataWeekTo)';
      this.loaDer.nativeElement.style.display = 'none';
    } }

  resetResults() {
    // this.searchGRNDataList = [];
    this.responseMessage = '';
    this.errormsg = '';
    this.loaDer.nativeElement.style.display = 'none';
    this.show = false;
    this.uniquesGRNList = [];
    this.searchGRNPoList = [];
    this.searchGRNModel.pageNumber=1;
  }
  getPage(page){
    ////console.log("getPage");
    ////console.log(page);
    this.loaDer.nativeElement.style.display = 'block';
       this.searchGRNModel.pageNumber=page;
       this.currentPage=page;
       this.getpage=false
      //  this.setAsnByAsnSearchCriteria(this.searchASNCriteriaList);
      ////console.log(this.searchASNCriteriaList);
       this.grnBySearchCriteria(this.searchGRNModel);
  
  }

  // getShortageAssotList(index) {

  //   this.responseShortageData = [];
  //   this.singlePOEntry = this.searchGRNPoList[index];

  //   this.responseShortageData = this.singlePOEntry.goodsReceiptNoteAssortments;

 // }

 viewGRNPage(grnNumber) {
  let selectGRNno: any;
  this.uniquesGRNList.filter(cs => cs.grnNumber === grnNumber).forEach(
    obj => {
      selectGRNno = obj;
    }
  );
  this.searchGRNModel.pageNumber=1;
  this._navigationLinkParameterService.grnNOdetails = selectGRNno;
  this._navigationLinkParameterService.searchGRNHeaderData = this.searchPayload();
  this.router.navigate(['/rds-view-grn']);
}
onClickBack() { this.router.navigate(['']); }

}
