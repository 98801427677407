/*
 * This class is replica of corresponding java enitiy class PurchaseOrderHeader. 
 * It is used for capturing purchase-order-header information from search-purchase-order Form.
 */
import { PurchaseOrderLines } from './po-lines';

export class PurchaseOrderHeaderWithLines {
     id:string;
     poNumber:string;
     reqNumber:string;
     conceptCode:string;
     graceperiod:number;
     poType:string;
     poAddlType:string;
     poCategory:string;
     prnTagCode:string;
     fob:string;
     partyShortName:string;   
     partyNumber:string;  
     poCreateWK:string;
     poCreateDate:string;
     poCancelYRWK:string;
     poCancelDate:string;
     poApprovalDate:string;
     poStatus:string;
     perfInvNumber:string;
     factoryOrderNo:string;
     lastUpdatedBy:string;
     lastUpdateDate:string;
     createdBy:string;
     customerId:string;
     location:string;
     poVersion:number;
     division:string;
     isPrinted:string;
     isOtbProcessed:string;
     orderedQty:string;
     poLines: PurchaseOrderLines[] = [];
     constructor(){}
}