import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
// import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchGrnService {

  grnSearchUrl = RdsBilApiUrl.SEARCH_GRN;

  constructor(private http: HttpClient) { }
  getGrnBySearchCriteria(data) {
    return this.http.post(this.grnSearchUrl, data);

  }
}
