import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { FinanceScratchingService } from './finance-scratching.service';
import { invalidateDetails } from './search-discard-response-payload';
// import { UtilsService } from '@/common/utils-service';


@Component({
  selector: 'app-finance-scratching-view',
  templateUrl: './finance-scratching-view.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css',
  '../../../../node_modules/font-awesome/css/font-awesome.css','./finance-scratching-view.component.css']
})
export class FinanceScratchingViewComponent implements OnInit {
  
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  
  finScratchForm: FormGroup;
  finScratchResultForm: FormGroup;
  isDisplayResult: boolean = false;
  responseMessage: any;
  statusMessage:string;
  modalMessage: string;
  discardMessage: string;
  discardObj= new invalidateDetails();
  searchResponseObj:  invalidateDetails[]=[];
  discardIndex : any;
  remarks: any;
  flag: boolean = false;
  validationFlag:boolean;
  stocks:any;
  userName:any;
  searchRequestObj : any[] = [];

  constructor(private fb: FormBuilder,private _router: Router,
              private _financeScratchingService : FinanceScratchingService) {
                
    this.finScratchForm = this.buildfinScratchForm();
    this.finScratchResultForm = this.buildfinScratchResultForm();
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    // this.userName = "user.test4";
   }

   onClickBack() { this._router.navigate(['']); }

  ngOnInit() {
   
    this.validationFlag=false;
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    
    this._financeScratchingService.getStatus().subscribe(
      (data: any) => {
        this.stocks = data.configParamModels;
        }
      );
    }, 100);
  }

  buildfinScratchForm() {
    return this.fb.group({
      sourceShopNo: [null],
      destinationShopNo: [null],
      invoiceNumber: [null],
      invoiceFromWeek: [null],
      invoiceToWeek: [null],
      bataWeekFrom: [null],
      bataWeekTo: [null],
      stockType: [null]
    });
  }

  buildfinScratchResultForm(): FormGroup {
    return this.fb.group({ finance: this.fb.array([]) });
  }

  onSubmit(formData: any) {
    this.loaDer.nativeElement.style.display = 'block';
console.log(formData);console.log('formdata');
   let searchObj =[
      {
        "name": "invoiceNo",
        "value": formData.invoiceNumber!=null ? formData.invoiceNumber : ''
      },
      {
        "name": "sourceShopCode",
        "value": formData.sourceShopNo!=null ? formData.sourceShopNo : ''
      },
      {
        "name": "destShopCode",
        "value":  formData.destinationShopNo!=null ? formData.destinationShopNo : ''
      },
      {
        "name": "invoiceFromWeek",
        "value": formData.invoiceFromWeek!=null ? formData.invoiceFromWeek : ''
      },
      {
        "name": "invoiceToWeek",
        "value":  formData.invoiceToWeek!=null ? formData.invoiceToWeek : ''
      },
      {
        "name": "bataWeekFrom",
        "value":  formData.bataWeekFrom!=null ? formData.bataWeekFrom : ''
      },
      {
        "name": "bataWeekTo",
        "value":  formData.bataWeekTo!=null ? formData.bataWeekTo : ''
      },
      {
        "name": "stockType",
        "value": formData.stockType!=null ? formData.stockType : ''
      }
     
    ]
    console.log(searchObj); console.log('searchObj');
    let addObj = [ 
      {
      "name": "validFlag",
      "value": "Y"
      },
      {
      "name": "userCode",
      "value": this.userName
      }
    ];
    // this.discardMessage='';
   
    this.searchRequestObj = [];
    searchObj.filter(cs => cs.value != "").forEach(
      obj => {
        this.flag = true;
       
        this.searchRequestObj.push(obj);
        console.log( this.searchRequestObj); console.log( 'searchRequestObj');
      }
    );
   
     if(this.flag == false){
      this.statusMessage='Error';
      this.responseMessage='Please select any of the search fields';
      this.loaDer.nativeElement.style.display = 'none';
      return false;
    }
    // <--------------------------invoiceWeek validation-------------------------------------->
    if( formData.invoiceFromWeek!=null && formData.invoiceToWeek==null || formData.invoiceFromWeek==null && formData.invoiceToWeek!=null){
      this.statusMessage='Error';
      this.responseMessage='Please provide both values InvoiceFromWeek & InvoiceToWeek';
      this.loaDer.nativeElement.style.display = 'none';
      return false;
    }
    
    if(formData.invoiceFromWeek > formData.invoiceToWeek){
      console.log(formData.invoiceFromWeek > formData.invoiceToWeek); console.log('formData.invoiceFromWeek > formData.invoiceToWeek');
      this.statusMessage='Error';
      this.responseMessage='InvoiceFromWeek should be less than InvoiceToWeek';
      this.isDisplayResult = false;
      this.buildfinScratchResultForm();
      this.loaDer.nativeElement.style.display = 'none';
      return false;
    }
  // <-------------------------- bataWeek validation-------------------------------------->
    if( formData.bataWeekFrom!=null && formData.bataWeekTo==null || formData.bataWeekFrom==null && formData.bataWeekTo!=null){
      this.statusMessage='Error';
      this.responseMessage='Please provide both values BataWeekFrom & BataWeekTo';
      this.loaDer.nativeElement.style.display = 'none';
      return false;
    }
    
    if(formData.bataWeekFrom > formData.bataWeekTo){
      console.log(formData.bataWeekFrom > formData.bataWeekTo); console.log('formData.bataWeekFrom > formData.bataWeekTo');
      this.statusMessage='Error';
      this.responseMessage='BataWeekFrom should be less than BataWeekTo';
      this.isDisplayResult = false;
      this.buildfinScratchResultForm();
      this.loaDer.nativeElement.style.display = 'none';
      return false;
    }
    // <------------------------------------------------------------------------------->
    this.searchRequestObj = this.searchRequestObj.concat(addObj);
    console.log(this.searchRequestObj); console.log('searchRequestObj with addObj');

    this.finScratchResultForm = this.buildfinScratchResultForm();
    let control = <FormArray>this.finScratchResultForm.controls.finance;
    this._financeScratchingService.financeSearch(this.searchRequestObj).subscribe(
      (response: any) => {
        if(response.count!=0 && response.count!=null){
        let details = [];
        details = response.dataList;
        this.searchResponseObj = response.dataList;
        this.isDisplayResult = true;
        this.responseMessage='';
        details.forEach(
          d=>{
            console.log(' search finance response ' + JSON.stringify(d));
            control.push(this.fb.group({
              sourceShopNo: [{ value: d.sourceShopCode, disabled: true }],
              sourceShopName: [{ value: d.sourceShopName, disabled: true }],
              destinationShopNo:[{value:d.destShopCode, disabled: true}],
              destinationShopName:[{value:d.destShopName, disabled: true}],
              invoiceNumber: [{ value: d.invoiceNo, disabled: true }],
              invoiceDate: [{value: d.invoiceDate, disabled: true}],
              consignmentNumber:[{ value: d.cnGrNo, disabled: true}],
              consignmentDate: [{value: d.cnGrDate, disabled: true}],
              debitPairs:[{value: d.debitPairs, disabled: true}],
              creditPairs: [{value: d.creditPairs, disabled: true}],
              debitPieces: [{value: d.debitPcs, disabled: true}],
              creditPieces: [{value: d.creditPcs, disabled: true}],
              debitAmount: [{value: d.debitAmount, disabled: true}],
              creditAmount:[{value: d.creditAmount, disabled: true}],
              stockType:[{value: d.stockType, disabled: true}],
              validFlag: [{value:d.validFlag}]
            }));
          }
        );}
        else{
          this.statusMessage='Error';
          this.responseMessage= 'No Data Found';
          this.isDisplayResult = false;
          this.loaDer.nativeElement.style.display = 'none';
        }
        console.log('control');console.log(control);
      }, (error) => {
        console.error('some error occured! while fetching discounts from server');
        this.finScratchResultForm = this.buildfinScratchResultForm();
        this.isDisplayResult = false;
        this.loaDer.nativeElement.style.display = 'none';
      }, () => {
        console.info('all disounts fetched. ');
        this.loaDer.nativeElement.style.display = 'none';}
    );
  }

  invalidate(event: any, index: any){
     let control = <FormArray>this.finScratchResultForm.controls.finance;
    console.log(this.finScratchResultForm.get(['finance',index]).get('validFlag').value.value);
    this.validationFlag=false;
    if(this.finScratchResultForm.get(['finance',index]).get('validFlag').value.value=='N'){
      alert('Selected finance is already invalidated');
      return;
    }
    else{
      this.discardIndex = index;
      console.log('discard index'+this.discardIndex);
      this.modalMessage = '';
      this.discardMessage='';
      this.validationFlag=true;
    }
  
  }

  confirmInvalidate(event: any){
    this.loaDer.nativeElement.style.display = 'block';
    if(this.remarks){
      console.log('in if');
    this.discardObj=this.searchResponseObj[this.discardIndex];
    console.log(this.discardObj); console.log("discardObj");
    this.discardObj.validFlag = 'N';
    this.discardObj.invalidatedBy = this.userName;
    this.discardObj.remarks = this.remarks;
    this.remarks='';
   
    console.log(this.discardObj); console.log("discardObj");
    this.modalMessage='';
    document.getElementById('close').click();
    this._financeScratchingService.discardFinanceInvalidate(this.discardObj).subscribe(
      (response: any) => {
        console.log('respopnse'+response);
        this.discardMessage='';
        this.statusMessage='';
        if(response.status=="success"){
  
          this.discardMessage=response.message;
          this.statusMessage='Completed';
          document.getElementById('submit').click();
          this.timeout('Completed');
        }
        else{
          this.discardMessage=response.message;
          this.statusMessage='Error';
          this.timeout('Error');
        }
      },
      (err)=>{
        console.log('in error');
        this.discardMessage=err;
        this.statusMessage='Error';
        this.timeout('Error');
        this.loaDer.nativeElement.style.display = 'none';
       }, () => { console.info('all disount details fetched. ') ; 
       this.loaDer.nativeElement.style.display = 'none';
     }
    );
    }
    else{
      console.log('in else');
      this.modalMessage = 'Please fill remarks';
      this.loaDer.nativeElement.style.display = 'none';
      
    }
    
  }

  resetForm(){
    this.isDisplayResult = false;
    this.responseMessage='';
    this.modalMessage='';
    this.statusMessage='';
    this.discardMessage='';
    this.flag=false;
    this.validationFlag=false;
    this.searchRequestObj = [];
    this.buildfinScratchResultForm();
  
  }

  timeout(msg) {
    setTimeout(() => {
      this.discardMessage="";
      this.statusMessage=msg;
    }, 10000);
  } 

}
