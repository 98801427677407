export class BarcodeScanHeader {
    ginNumber:string;
    invoiceNumber:string;
    poNumber:string;
    artNumber:number;
    shopNumber:number;
    ginId:string;
    asnNumber:string;
    invoiceDate:Date;
    invoiceValue:string
    igstAmount:string;
    cgstAmount:string;
    sgstAmount:string;   
    netAmount:string;  
    shippedCartonQty:string;
    receivedCartonQty:string;
    shortageCartonQty:string;
    excessCartonQty:string;
    consignorCode:string;
    consignorName:string;
    consigneeCode:string;
    consigneeName:string;
    transporterCode:string;
    transporterName:string;
    ewayBillNumber:string;
    lrGrNumber:string;
    lrGrDate:string;
    vehicleNumber:string;
    createDate:Date;
    lastUpdatedBy:string;
    lastUpdated:string;
    bataWeek:string;
    onwayValue:string;
    receivedPairQty:string;
    shortagePairQty:string;
    totalPairs:string;
    excessPairQty:string;
    grnStatus:string;
   
   constructor(){}
}