import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpHeaders, HttpErrorResponse,HttpResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { LoginComponent } from '../login/login.component';

import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,private router: Router,
         private alertService: AlertService ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err instanceof HttpResponse) {
                //console.log(err);
            }

            if (err instanceof HttpErrorResponse) {
                //console.log(err);
               //  //console.log(err.error.status);
                //console.log(err.status);

                if (err.status === 932 || err.status === 401) {
                   // auto logout if 401 response returned from api
                   this.authenticationService.logout();
                   // this.alertService.error("Session Timed Out! Please Login");
                   localStorage.setItem('errMessage','Session Timed Out! Please Login');
                   this.router.navigate(['/login']);
                   //this.login.showSessionMessage();
                   // location.reload(true);
               }
               if ([401, 403].indexOf(err.status) !== -1) {
                   // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                   // this.authenticationService.logout();
                   // location.reload(true);
               }
    
           }
            
           if(err.error == null){
           // const error = err.error.message || err.statusText;
            return throwError(err.statusText);
           }
           else{
               const error = err.error.message;
            return throwError(error);
           }

            // const error = err.error.message || err.statusText;
            // return throwError(error);
        }))
    }

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //     return next.handle(request).pipe(catchError(err => {
    //         if (err instanceof HttpResponse) {
    //             //console.log(err);
    //         }
    //         if (err instanceof HttpErrorResponse) {
    //              //console.log(err);
    //             //  //console.log(err.error.status);
    //              //console.log(err.status);

    //              if (err.status === 932) {
    //                 // auto logout if 401 response returned from api
    //                 this.authenticationService.logout();
    //                 // this.alertService.error("Session Timed Out! Please Login");
    //                 localStorage.setItem('errMessage','Session Timed Out! Please Login');
    //                 this.router.navigate(['/login']);
    //                 //this.login.showSessionMessage();
    //                 // location.reload(true);
    //             }
    //             if ([401, 403].indexOf(err.status) !== -1) {
    //                 // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    //                 this.authenticationService.logout();
    //                 location.reload(true);
    //             }
    
    //         }
            
    //         if(err.error == null){
    //         // const error = err.error.message || err.statusText;
    //          return throwError(err.statusText);
    //         }
    //         else{
    //             const error = err.error.message;
    //          return throwError(error);
    //         }
    //     }))
    // }
}