import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { BilApiUrl } from "../../common/bil-api-url";
import { RdsBilApiUrl } from "../../common/rds-bil-api-url";
import { RequestParameterModel } from '../../common/request-parameter-model';

@Injectable({
  providedIn: 'root'
})
export class CreateInventoryBifurcationService {

  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  shop_location_url: string = RdsBilApiUrl.SEARCH_PARENT_LOCATION_URL;
  inventory_bifurcation_url: string = RdsBilApiUrl.SEARCH_INVENTORY_BIFURCATION_URL;
  add_inventory_bifurcation_url: string = RdsBilApiUrl.ADD_INVENTORY_BIFURCATION_URL;

  constructor(private _http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }

  getShopCode() {
    let headers={
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
    }
    return this._http.get(this.shop_location_url,headers);
  }

  getinventoryBifurcationDetails(details) {
    return this._http.post(this.inventory_bifurcation_url, details);
  }

  createRequestParametersModelArray(requestParameterModel: any): RequestParameterModel[]{
    let requsetParameters = new Array<RequestParameterModel>();
    let jsonRequisition = JSON.stringify(requestParameterModel);
    console.log(jsonRequisition);
    let jsonObject = JSON.parse(jsonRequisition);
    for (var i in jsonObject) {
          let requestParameterModel = new RequestParameterModel();
          if (jsonObject[i] !== '' && jsonObject[i] !== null && jsonObject[i] !== 'undefined') {
                requestParameterModel.name = i;
                requestParameterModel.value = jsonObject[i];
                requsetParameters.push(requestParameterModel);
          }
    }
    requsetParameters.forEach(
          params => console.log(' requisition header params name '+params.name + ' value '+params.value)
    );
    return requsetParameters;
  } 

  addinventoryBifurcationDetails(details) {
    return this._http.post(this.add_inventory_bifurcation_url, details);
  }
}
