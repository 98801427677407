import { Injectable } from '@angular/core';
import { BilApiUrl } from '../common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageSupplierAccessService {
  party_url: string = BilApiUrl.SUPPLIER_PARTY_NUMBER;
  supplier_access_url: string = BilApiUrl.SUPPLIER_ACCESS_DETAILS;
  supplier_access_update_url: string = BilApiUrl.UPDATE_SUPPLIER_ACCESS_DETAILS;
  constructor(private _http: HttpClient) { }
   
  getPartyNumber() {
    return this._http.get(this.party_url);
  }

  getSupplierDetailBySearch(userConceptParameter) {
    console.log('searching parameter' + JSON.stringify(userConceptParameter));
    return this._http.post(this.supplier_access_url, userConceptParameter);
  }

  updateSupplierFlagStatus(details) {
    console.log('updating parameter' + JSON.stringify(details));
    return this._http.post(this.supplier_access_update_url, details, {
      observe: 'response',
      responseType: 'text'
    });
  }
}
