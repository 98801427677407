import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { BilApiUrl } from '../../common/bil-api-url';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ResupplyUploadService {

  uploadfileURL: string = RdsBilApiUrl.UPLOAD_ASN_EXCEL_FILE;
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  generateAsn: string = RdsBilApiUrl.GENERATE;
  generateITOSTN: string = RdsBilApiUrl.GENERATE_ITO_STN;
  urlForRFUCUploadUrl:string=RdsBilApiUrl.UPLOAD_URL_RFUC;
  nilStockDefectiveUpload:string=RdsBilApiUrl.NIL_STOCK_DEFECTIVE_UPLOAD;

 constructor(private _http: HttpClient) { }

    /*---------------------------------------- Upload Service ---------------------------------*/

    upload(formData) {
      console.log(formData);
      return this._http.post(`${this.uploadfileURL}`, formData);
      // return this._http.post(`${this.urlForRFUCUploadUrl}`, formData);
    }

      /*-------------------------------- Upload Service ---------------------------------*/

  getDownloadUrl() {

    return this._http.get(this.urlForDownloadUrl);

  }

  public getGenerate(uploadKeyValues: any) {
    console.log(uploadKeyValues[0]['value']);
     if(uploadKeyValues[0]['value']=='ITO_STN_RTN_TO_FACTORY_CLAIMED'){
      return this._http.post(this.urlForRFUCUploadUrl, uploadKeyValues, httpOptions);
     } else if(uploadKeyValues[0]['value'] == 'ITO_STN_DEFECTIVE_NV' || uploadKeyValues[0]['value'] == 'ITO_STN_RTN_TO_FACTORY_DEFECTIVE'){
      return this._http.post(this.nilStockDefectiveUpload, uploadKeyValues, httpOptions);
     }else{
      return this._http.post(this.generateITOSTN, uploadKeyValues, httpOptions);
     }

  }


}
