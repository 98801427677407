import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '../common/bil-api-url';



@Injectable({
  providedIn: 'root'
})
export class ViewShipmentReceiptDetailsService {
  constructor(private _http: HttpClient,private httpService:HttpClient) { }
  //private url:string="../assets/static-data/viewshipment-details.json";
private distribution_url:string=BilApiUrl.VIEW_SHIPMENT_DETAILS;
  
  getViewShipmentServiceCriteria(poLineId:string){
    let view_shipment_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = 'poLineID';
    requestParameterModel.value = poLineId;
    view_shipment_search_parameters.push(requestParameterModel);
    console.log(' getPurchaseOrderBySearchCriteria : purchase_order_search_parameters : ' + JSON.stringify(view_shipment_search_parameters));
    return this._http.post(this.distribution_url, view_shipment_search_parameters);
  }
}