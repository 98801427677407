export class AssortmentDetailModel {
        resupplyItnAssortmentId: string;
        itnRsnId: string;
        artSize: string;
        pairQuantity: string;
        fulFillQty: string;
        lastUpdatedBy: string;
        lastUpdateDate: Date;
        constructor() {}

}
