//TODO: hard code payload to be remmoved.
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { DistributionDetailService } from './distribution-detail.service';
import { FormBuilder, FormGroup, FormArray, FormControl,NG_VALUE_ACCESSOR } from '@angular/forms';
import {ResponseMessage} from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { DeleteDetails } from '../common/delete-details';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
@Component({
  selector: 'app-distribution-detail',
  templateUrl: './distribution-detail.component.html',
  styleUrls: ['./distribution-detail.component.css'],
   
})
export class DistributionDetailComponent implements OnInit {
  @ViewChild('ref', {static: false}) ref: ElementRef;
  @ViewChild('ref1', {static: false}) ref1: ElementRef;
  distributionForm: FormGroup;
  assortments: FormArray;
  responseMessage: string;
  saveDisabled:boolean=false;
  alldetails: any[] = [];
  distributionTotalDetails:any;
  totpackingassort: any;
  remarks:string;
  currentDate:Date;
  dateOfYear:string;
  currentIndex:number;
  cancelQty: number = 0;
  private _requestParameterModelArray: RequestParameterModel[];

  constructor(private _distributionDetailService: DistributionDetailService, private _route: ActivatedRoute,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private _formBuilder: FormBuilder,private _utilsService: UtilsService
  ) { 
    this.distributionTotalDetails = this._navigationLinkParameterService.tot_details;
  }

  ngOnInit() {
    this.pageloadingFunc();
  }

  pageloadingFunc(){

    this.alldetails = [];
    this.currentDate = new Date();
    let myDate = new Date(this.currentDate);
    let year = this.currentDate.getFullYear();
    let mnth = ("0" + (myDate.getMonth() + 1)).slice(-2);    let day = ("0" + myDate.getDate()).slice(-2);
    let orderedQuantity: number = 0;
    this.dateOfYear = [day, mnth, year].join("/");
    console.log('distributionTotalDetails');
    console.log(this.distributionTotalDetails);
    if(this.distributionTotalDetails.poLineDistributions != null){
    this.distributionTotalDetails.poLineDistributions.forEach(
      distDetails => {
        if(distDetails.requestedOrderQuantity == null || distDetails.requestedOrderQuantity == ''){
          if(distDetails.distributionReqQuantity == null || distDetails.distributionReqQuantity == ''){
            distDetails.requestedOrderQuantity = distDetails.totalArtQty;
          } else {
            distDetails.requestedOrderQuantity = distDetails.distributionReqQuantity;
          }
        } else {
          distDetails.requestedOrderQuantity = distDetails.requestedOrderQuantity;
        }
        orderedQuantity += (distDetails.requestedOrderQuantity == null || distDetails.requestedOrderQuantity == '') ? distDetails.totalArtQty  : distDetails.requestedOrderQuantity;
        this.cancelQty += Number(distDetails.cancelledQty);
        if(distDetails.distributionStatus == 'Pending Approval' || distDetails.distributionStatus == 'Pending Recall'){
          this.alldetails.push(distDetails);
        }
      }
     );   
     if(Number(this.distributionTotalDetails.distributionReqQuantitySum) == 0){
      this.distributionTotalDetails.distributionReqQuantitySum = orderedQuantity;
    }
    }
     console.log('alldetails');
     console.log(JSON.stringify(this.alldetails));
     if(this.alldetails.length == 0 || this.distributionTotalDetails.poLineDistributions == null){
       this.responseMessage = 'No Data Found';
     }
  }

  setDataApp(id,index){   
    this.remarks = '';
    this.ref.nativeElement.value = id;
    this.currentIndex = index;
  }

  setDataRej(id,index){
    this.remarks = '';
    this.ref1.nativeElement.value = id;
    this.currentIndex = index;
  }

  approve(id){
    this.responseMessage = '';
    console.log(id);
    let distributionStatus:string;
    let distributionShippedQty:number;
    let distributionCancelQty:number;
    this.distributionTotalDetails.poLineDistributions.filter(cs => cs.poDistributionId == id).forEach(
    distribution => {
      distributionStatus = distribution.distributionStatus;
      distributionShippedQty = distribution.shippedQty;
      distributionCancelQty = distribution.cancelledQty;
    }
    )
    let poType: string = 'poDistribution';
    let details = {
        "headerId": this._navigationLinkParameterService.poHeaderId,
        "distributionStatus": distributionStatus,
        "approverRemarks": this.remarks,
        "action": "Approved",
        "cancelledQty": distributionCancelQty,
        "shippedQty": distributionShippedQty,
        "approveRejectedBy":JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode,
        "lastUpdateBy":JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode,
        "lastUpdateDate":this.dateOfYear
      }
      this.approvalRejectionService(id,poType,details);
  }

  reject(id){
    console.log(id);
    this.responseMessage = '';
    let distributionStatus:string;
    let distributionShippedQty:number;
    this.distributionTotalDetails.poLineDistributions.filter(cs => cs.poDistributionId == id).forEach(
    distribution => {
      distributionStatus = distribution.distributionStatus;
      distributionShippedQty = distribution.shippedQty;
    }
    )
    let poType: string = 'poDistribution';
    let details = {
        "headerId": this._navigationLinkParameterService.poHeaderId,
        "distributionStatus": distributionStatus,
        "approverRemarks": this.remarks,
        "action": "Reject",
        "approveRejectedBy":JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode,
        "lastUpdateBy":JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode,
        "lastUpdateDate":this.dateOfYear
      }
      this.approvalRejectionService(id,poType,details);
  }

  approvalRejectionService(id,poType,details){
    console.log(this.distributionTotalDetails.poLineDistributions.indexOf(id));
    let deleteDetails = new DeleteDetails();
    this._requestParameterModelArray = [];
    this._requestParameterModelArray = this._utilsService.createRequestParametersModelArray(details);
    deleteDetails.type = poType;
    deleteDetails.id = id;
    deleteDetails.updatedDataList = this._requestParameterModelArray;
    console.log(JSON.stringify(deleteDetails));

    this._distributionDetailService.getPoDeleted(deleteDetails).subscribe(
      (response: ResponseMessage) => {
        console.log(response);
        if (response != null && details.action == 'Approved' && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = "Approved Successfully";
          this.remarks = '';
          this.distributionTotalDetails.poLineDistributions.splice(this.currentIndex, 1);
          this.pageloadingFunc();
        }else if (response != null && details.action == 'Reject' && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = "Rejected Successfully";
          this.remarks = '';
          this.distributionTotalDetails.poLineDistributions.splice(this.currentIndex, 1);
          this.pageloadingFunc();
        }
        else {
          this.responseMessage = response.message;
        }
      },
      (err) => {
        this.responseMessage = err;
      }
    );
  }

  onClickBack() {
    this._router.navigate(['/update-pending-po']);
  }

}
