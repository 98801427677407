import {BarcodeScanAssort} from './barcode-assort';
export class BarcodeScanLine {
    planNumber:string;
    productionUnit:string;
    artNumber:string;
    poNumber:number;
    itnRsnId:string;
    manufacturingDate:string;
    barcodeNumber:string;

    twoDScanAssortments: BarcodeScanAssort[]=[];
    oneDScans: BarcodeScanAssort[]=[];
   constructor(){}
}