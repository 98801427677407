export class UserGroupSearchDetails {
    userId:string;
    userName:string;
    userCode:string;
    userEmail:string;
    activeFlag:string;
    groupName:string;
    concept:string;
    groupId:string;
    screenId:string;
    reportId:string;
    createdBy:string;
    constructor(){}
}