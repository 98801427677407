import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { PoListService } from './po-list.service';
import { RequisitionHeader } from '../requisition-header';
import { PurchaseOrderHeader } from '../../search-po/po-header';
import { SearchPoResponseModel } from '../../common/search-po-response-model';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ITEMS_PER_PAGE } from '../../common/field-name-list';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css','./po-list.component.css'],
  providers: [PoListService]
})
export class PoListComponent implements OnInit {
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;


  constructor(private _PoListService: PoListService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router,private _utilsService: UtilsService
  ) {}

  reqNumber: string;
  private _requsetParameterModelArray: RequestParameterModel[];
  searchedRequisitionDetails = new RequisitionHeader();
  searchedPoHeaders: PurchaseOrderHeader[] = [];
  reqId: string;
  reqLindId:string;
  requestParameterModel: RequestParameterModel[];
  details:any;
  currentPage:number=1;


  ngOnInit() {
    this.reqLindId = this._navigationLinkParameterService.lineId;
    if (this._navigationLinkParameterService.details != null && this._navigationLinkParameterService.details.length > 0) {
      this._requsetParameterModelArray = [];
      this._requsetParameterModelArray = this._navigationLinkParameterService.details;
      this._requsetParameterModelArray.forEach(
        requsetParameter => {
         console.log(' inside ngOnInit'+ 'name '+requsetParameter.name + ' value '+requsetParameter.value)
          if ('reqNumber' === requsetParameter.name) {
          
             console.log('  name '+requsetParameter.name + ' value '+requsetParameter.value)
             this.searchedRequisitionDetails.reqNumber = requsetParameter.value;
             this.reqNumber = requsetParameter.value;
          }
          if ('reqId' === requsetParameter.name) {
          
             console.log('  name '+requsetParameter.name + ' value '+requsetParameter.value)
             this.searchedRequisitionDetails.reqNumber = requsetParameter.value;
             this.reqId = requsetParameter.value;
          }

          if ('reqStatus' === requsetParameter.name) {
            this.searchedRequisitionDetails.reqStatus = requsetParameter.value;
          }

          if ('conceptCode' === requsetParameter.name) {
            this.searchedRequisitionDetails.conceptCode = requsetParameter.value;
          }

          if ('reqCreateDate' === requsetParameter.name) {
            this.searchedRequisitionDetails.reqCreateDate = requsetParameter.value;
          }

          if ('reqCreatedby' === requsetParameter.name) {
            this.searchedRequisitionDetails.reqCreatedby = requsetParameter.value;
          }

          if ('suppName' === requsetParameter.name) {
            this.searchedRequisitionDetails.supplierName = requsetParameter.value;
          }
        }

      );
    }

    if(this.reqLindId == null && this.reqLindId == undefined){
      console.log('header');
       this.details = {
        "reqId":this.reqId,
      }
    }

    if(this.reqLindId != null && this.reqLindId != undefined){
      console.log('line');
    this.details = {
      "reqId":this.reqId,
      "reqLineId":this.reqLindId
    }
  }
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.details);
    console.log('request parameter from po list'+JSON.stringify(this.requestParameterModel));
    this._PoListService.getPoList(this.requestParameterModel).subscribe(
      (response: SearchPoResponseModel) => {
        this.searchedPoHeaders = response.dataList;
        this.searchedPoHeaders.forEach(
          searchList => {
            var multiplier = Math.pow(10, 2);
            let amount = (Math.round(searchList.stdArtCost * multiplier) / multiplier);
            searchList.stdArtValue = this._utilsService.calculateDecimalValue(amount);
          }
        )
        this.loaDer.nativeElement.style.display = 'none';
      }

    );
this.reqId = null;
this.reqLindId = null;
this._navigationLinkParameterService.id = null;
console.log('reqid'+this.reqId+'reqlineid'+this.reqLindId);

  }

  onClickPoNumber(index){
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log(' absoluteIndex '+absoluteIndex)
    this._navigationLinkParameterService.id=this.searchedPoHeaders[absoluteIndex].poNumber;
    this._navigationLinkParameterService.podetails=this.searchedPoHeaders[absoluteIndex];
    // this.requestParameterModel = [];
    // this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchedPurchaseOrders[index]);
    // this._navigationLinkParameterService.details = this.requestParameterModel;
    this._navigationLinkParameterService.navigateFlag = 'poList';
    this._router.navigate(['update-po']);
  }

  onClickBack() {
    this._router.navigate(['search-requisition']);
  }

}
