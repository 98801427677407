import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { Observable, of, throwError, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  shopMasterUrl: string = RdsBilApiUrl.SHOP_MASTER_URL;
  partyMasterUrl: string = RdsBilApiUrl.PARTY_MASTER_URL;
  printInvoiceReportURL: string = RdsBilApiUrl.PRINT_INVOICE_REPORT_URL;
  nilstockReportURL: string = RdsBilApiUrl.NIL_STOCK_REPORT_URL;
  constructor(private _http: HttpClient) { }


getShopDetails(data) {
  return this._http.post(this.shopMasterUrl, data).pipe(

    retry(1),

    catchError(this.handleError)

  );;
}
getPartyMasterData(data) {
  return this._http.post(this.partyMasterUrl, data).pipe(

    retry(1),

    catchError(this.handleError)

  );
}
printInvoice(requestData,key){
    console.log(key);
    let reportKeyObj = requestData.reportInputRequestList.find(cs => cs.name == 'reportKey');
    let url = '';
    console.log(JSON.stringify(reportKeyObj));
    if(key == 'NIL_STOCK'){
      url = this.nilstockReportURL;
    } else{
      url = this.printInvoiceReportURL;
    }

  return this._http.post(url, requestData).pipe(
    retry(1),
    catchError(this.handleError)

  );
}

/*-------------------------------- Handle Error  Service ---------------------------------*/
private handleError(err) {
  // in a real world app, we may send the server to some remote logging infrastructure
  // instead of just logging it to the console
  let errorMessage: string;
  if (err.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    errorMessage = `An error occurred: ${err.error.message}`;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
  }
  console.error(err);
  return throwError(errorMessage);
}


/*-------------------------------- Get search Data Service ---------------------------------*/

}
