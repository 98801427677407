import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { ManageCcListEditService } from './manage-cc-list-edit.service';
import { Concept } from '../common/concept';
import { CcListSearchDetails } from '../manage-cc-list/manage-cc-list-detail';
import { CcListModel } from '../manage-cc-list/manage-cc-list-model';
// import { CcListDetails } from './manage-cc-details';
import { Party } from '@/manage-supplier-access/manage-party-details-model';
import { RequestParameterModel } from '@/common/request-parameter-model';


@Component({
  selector: 'app-manage-cc-list-edit',
  templateUrl: './manage-cc-list-edit.component.html',
  styleUrls: ['./manage-cc-list-edit.component.css']
})

export class ManageCcListEditComponent implements OnInit {
  concepts: Concept[];
  // parties: any = [];
  ccTypeList: any = [{ name:'Merchandising', value: 'M'},
                 { name:'Replenishment', value: 'R'},
                 { name:'Sourcing Import', value: 'SI'},
                 { name:'Sourcing Domestic', value: 'SD'},
                 { name:'Sourcing Consumable', value: 'SC'}];
  createCcListModel = new CcListSearchDetails();
  ccList: CcListSearchDetails[] = [];
  createCcList = new CcListSearchDetails();
  ccListSearchParameter = new CcListModel();
  // ccDetailList: CcListDetails[] = [];
  selectedUser: number;
  selectedRow: number = 0;
  responseMessage: string;
  mt1: boolean[] = [];
  mt2: boolean[] = [];
  disableIndex: number = 0;
  ccPassingList: CcListSearchDetails[] = [];
  details:any;
  username:string;
  err:any;
  partyName:any = [];


  private _requsetParameterModelArray: RequestParameterModel[];
  holdingPackingData = new CcListSearchDetails();
  editCcListDetails = new CcListSearchDetails();
  requestParameterModel: RequestParameterModel[];
  errorMessage: string;





  constructor(private _router: Router, private _editCcListService: ManageCcListEditService, private _navigationLinkParameterService: NavigationLinkParameterService ) { }

  ngOnInit() {
  



    // this.editCcListDetails.packingMasterAssortmentsDTO = this._navigationLinkParameterService.assortmentDetails;
    console.log(this._navigationLinkParameterService.details);
    if (this._navigationLinkParameterService.details != null && this._navigationLinkParameterService.details.length > 0) {
      this._requsetParameterModelArray = [];
      this._requsetParameterModelArray = this._navigationLinkParameterService.details;
      console.log('packing edit data' + this._requsetParameterModelArray);
      this._requsetParameterModelArray.forEach(
        
        requsetParameter => {
          if ('partyMailSeq' === requsetParameter.name) {
            this.editCcListDetails.partyMailSeq = requsetParameter.value;
          }
          if ('conceptCode' === requsetParameter.name) {
            this.editCcListDetails.conceptCode = requsetParameter.value;
          }
          if ('ccType' === requsetParameter.name) {
            this.editCcListDetails.ccType = requsetParameter.value;
          }

          if ('ccEmail' === requsetParameter.name) {
            this.editCcListDetails.ccEmail = requsetParameter.value;
          }

          if ('partyNumber' === requsetParameter.name) {
            this.editCcListDetails.partyNumber = requsetParameter.value;
          }

          if ('partyShortName' === requsetParameter.name) {
            this.editCcListDetails.partyShortName = requsetParameter.value;
          }

          if ('isActive' === requsetParameter.name) {
            this.editCcListDetails.isActive = requsetParameter.value;
          }

          if ('createdBy' === requsetParameter.name) {
            this.editCcListDetails.createdBy = requsetParameter.value;
          }
          if ('createdDate' === requsetParameter.name) {
            this.editCcListDetails.createdDate = requsetParameter.value;
          }
          if ('lastUpdatedBy' === requsetParameter.name) {
            this.editCcListDetails.lastUpdatedBy = requsetParameter.value;
          }
          if ('lastUpdateDate' === requsetParameter.name) {
            this.editCcListDetails.lastUpdateDate = requsetParameter.value;
          }
         
        }
      );


      }

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.createCcListModel = new CcListSearchDetails();
    this.ccList.push(this.createCcListModel);
    this.mt1[0] = false;
    this.mt2[0] = false;
    
    this._editCcListService.getUserConcept().subscribe(
      (data: any) => {
        console.log(data);
        this.concepts = data;
      }
    )
    console.log(this.editCcListDetails.conceptCode);
    console.log(this.editCcListDetails.ccType);
    // this._editCcListService.getPartyNumber().subscribe(
    //     (data: any) => {
    //       console.log(data);
    //       this.parties = data.partyNumberList;
    //     }
    //   )
  }

  
  onClickBack() {
    this._router.navigate(['manage-cc-list']);
  }

  setIndexForUser(index) {
    this.responseMessage = '';
    this.selectedUser = index;
  }


  settingIndexforConceptLine(partyNo,index) {
    this.responseMessage = '';
    this.selectedRow = index;
  }

  setData(ccValue, i) {
    this.responseMessage = '';
    console.log(ccValue);
    this.editCcListDetails.ccType = ccValue;
    this.mt2[this.disableIndex] = true;
  }

  setData1(concCode, i) {
    this.responseMessage = '';
    console.log(concCode);
    this.editCcListDetails.conceptCode = concCode;
    this.mt1[this.disableIndex] = true;
  }


  onClickSave(){
    this.responseMessage = '';
    this.ccPassingList = [];
    console.log(this.mt1[this.disableIndex]);
    console.log(this.mt2[this.disableIndex]);
    console.log(this.editCcListDetails);
    console.log(this.editCcListDetails.conceptCode);
    console.log(this.editCcListDetails.ccType);

    if((this.editCcListDetails.conceptCode == '' || this.editCcListDetails.conceptCode == undefined || this.editCcListDetails.conceptCode == null) || (this.editCcListDetails.ccType == '' || this.editCcListDetails.ccType == undefined || this.editCcListDetails.ccType == null) || (this.editCcListDetails.ccEmail == '' || this.editCcListDetails.ccEmail == undefined || this.editCcListDetails.ccEmail == null)){
      this.responseMessage = 'Please fill Data First';
    }else{
    console.log(JSON.stringify(this.editCcListDetails));

      // this.ccList.forEach(
      //   (conc,index) => {
      //     let ccPassing = new CcListSearchDetails();
      //     ccPassing.ccType = conc.ccType;
      //     ccPassing.ccEmail = conc.ccEmail;
      //     ccPassing.partyMailSeq= conc.partyMailSeq;
      //     // ccPassing.partyNumber = conc.partyNumber;
      //     // ccPassing.partyShortName = conc.partyShortName;
      //     ccPassing.conceptCode = conc.conceptCode;
      //     ccPassing.isActive = "Y";
      //     ccPassing.createdBy = this.username;
      //     ccPassing.lastUpdatedBy = this.username;
      //     this.ccPassingList.push(ccPassing);
      //   }
      // );
      this.ccPassingList.push(this.editCcListDetails);

      console.log(this.editCcListDetails);
      console.log(JSON.stringify(this.ccPassingList));
      console.log((this.ccPassingList));
        console.log('calling service....');

        this._editCcListService.saveCcDetails(this.ccPassingList).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == 'Success') {
            this.responseMessage = 'CC Data Created Successfully';
          }
          else{
            this.responseMessage = response.message;
          }
        }
      )
    
    }      
  }

// onKey(partyNo,index){
//  let partyLen = this.parties.length;
//     for(let i=0; i<partyLen;i++){
//       console.log(this.parties[i].partyShortName);
//       if (partyNo == this.parties[i].partyNo) {
//         this.ccList[index].partyShortName = this.parties[i].partyShortName;
//         break;
//       }
//       console.log(index);
//       console.log(this.ccList);
//       console.log(this.ccList[index].partyShortName);
//     }
  
// }

  cancelLineDetails(index){
    if(this.disableIndex != 0){
    this.ccList.splice(index,1);
    this.disableIndex--;
    this.responseMessage = '';
    }
  }
}
