import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BatchesService } from './batches.service';
import { ResponseMessage } from '../common/response-message';
import { Batches } from './batches';
import { BatchesHeader } from './batchesheader';
import { SUCCESS_MSG } from '../common/field-name-list';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { BatchModel } from './batch-model';
import { SearchBatchModel } from './search-batches';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css','batches.component.css']
})

export class BatchesComponent implements OnInit {
  batchOrderLine: Batches[] = [];
  val: boolean = false;
  conceptList: any;
  responseMessage: string;
  batchOrderList: Batches[] = [];
  createdUser: string;
  batchModel = new BatchModel();
  searchbatchModel = new SearchBatchModel();
  requestParameterModel: RequestParameterModel[];
  enable:boolean=false;
  invokeButton:string= 'enable';
  batches = [
    { id: 'm', name: 'JDE To PO Interface', filename: 'JDE_PO_Interface' },
    { id: 'i', name: 'Article Master Upload', filename: 'ART.BAC' },
    { id: 'i', name: 'Category Master Upload', filename: 'CTGM.TXT' },
    { id: 'p', name: 'Party Master Upload', filename: 'PARTYO.TXT' },
    { id: 'i', name: 'Shop Master Upload', filename: 'SA002.TXT' },
    { id: 'i', name: 'Week Master Upload', filename: 'WEEKM.TXT' },
    { id: 'i', name: 'State Master Upload', filename: 'STATMAST.TXT' },
    // { id: 'm', name: 'OTB To PO Interface', filename: 'OTB' },
    { id: 'r', name: 'RDS To PO Interface', filename: 'ORDFL' },
    { id: 'r', name: 'DMS To PO Interface', filename: 'DMS' },
    { id: 'r', name: 'Vendor Supply To PO Interface', filename: 'SHIP_VENDOR' },
    // { id: 'm', name: 'Unit and SVS Supply to PO interface', filename: 'SHIP_FACTORY' },
    { id: 'i', name: 'BARCODE To PO Interface', filename: 'BARCODE_TO_PO_TXT.txt' },
    { id: 'a', name: 'PO To RIMS Onway Interface', filename: '' },
    { id: 'a', name: 'PO To RIMS Onorder Interface', filename: '' },
    // { id: 'a', name: 'PO To OTB Interface', filename: '' },
    { id: 'a', name: 'PO To RDS Interface', filename: '' },
    { id: 'a', name: 'PO To DMS Interface', filename: '' },
    { id: 'a', name: 'PO To BARCODE Order Master Interface', filename: '' },
    { id: 'a', name: 'PO To Tableau Interface', filename: '' },
    { id: 'a', name: 'PO To PROMPS Interface', filename: '' },
    { id: 'a', name: 'PO To WMS 680 Interface', filename: '' },
    { id: 'o', name: 'Cancellation Batch', filename: '' },
    { id: 'l', name: 'Requisition Upload Batch', filename: '' },
    { id: 'l', name: 'Approve Po Batch', filename: '' },
    { id: 'p', name: 'Po Pdf Batch', filename: '' },
    { id: 'pr', name: 'Auto PO Invoke', filename: '' } 
  ];

  constructor(private _router: Router, private batchServices: BatchesService,
    private fb: FormBuilder, private authentic: AuthenticationService,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    let sessUsername = sessionStorage.getItem('currentUser');
    this.createdUser = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    console.log(this.createdUser);
    let detail = [{
      "name": "userEmail",
      "value": this.createdUser
    }]

    this.batchServices.getConceptCode(detail).subscribe(
      (response1: BatchesHeader) => {
        console.log(response1);
        this.conceptList = response1.dataList;
        const distinctConcept = this.conceptList.filter(
          (thing, i, arr) => arr.findIndex(t => t.concCode === thing.concCode) === i
        ); 
        this.conceptList = [];
        this.conceptList = distinctConcept;
      });

    this.batchServices.getOrderType().subscribe(
      (response: Batches) => {
        this.batchOrderLine.push(response);
        this.batchOrderLine[0].configParamModels.forEach(
          element => {
            if (element.paramName == 'order_type') {
              console.log(element);
              this.batchOrderList.push(element);
            }
          });
      }
    )
  }

  onInvoke() {
    this.responseMessage = '';
    this.invokeButton = 'enable';
    console.log('Batch Invoking Starts here');
    if (this.batchModel.name != 'Cancellation Batch') {
      this.batches.forEach(
        batch => {
          if (this.batchModel.name == batch.name && batch.id == 'i') {
            let fileNames: any = {
              "fileName": batch.filename
            };
            this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
            this.responseMessage = 'Batch Invoked Successfully';
          }
          else if (this.batchModel.name == batch.name && batch.id == 'o') {
            this.batchServices.getUploadWithoutFilename(batch.name).subscribe();
            this.responseMessage = 'Batch Invoked Successfully';
          }
          else if (this.batchModel.name == batch.name && batch.id == 'a') {
            let fileNames: any = {
              "uploadedBy":this.createdUser
            };
            this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
            this.responseMessage = 'Batch Invoked Successfully';
          }
          else if (this.batchModel.name == batch.name && batch.id == 'p') {
            let fileNames: any = {
              "name": "userId",
              "value": this.createdUser
          };
            this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
            this.responseMessage = 'Batch Invoked Successfully';
          }
          else if (this.batchModel.name == batch.name && batch.id == 'l') {
            let fileNames: any = [{
              "name": "userId",
              "value": this.createdUser
          }];
            this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
            this.responseMessage = 'Batch Invoked Successfully';
          }
          else if (this.batchModel.name == batch.name && batch.id == 'r') {
            let fileNames: any = {
              "uploadKeyValues": batch.filename,
              "uploadedBy": this.createdUser
            };
            this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe(
              (response: any) => {
                if (response.status == 'Success') {
                  this.responseMessage = response.message;
                }
                else {
                  this.responseMessage = response.message;
                }
              },
              (err) => {
                this.responseMessage = err;
              }
            );
          }
          else if (this.batchModel.name == batch.name && batch.id == 'pr') {
            let fileNames: any = {
              "uploadKeyValues": "MULTIPLE_ARTICLE_DISTRIBUTION",
              "reqNumber": null,
              "userId":this.createdUser
            };
            this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
            this.responseMessage = 'Batch Invoked Successfully';
          }
          else {
            if (this.batchModel.name == batch.name && batch.id == 'm') {
              let fileNames: any = {
                "uploadKeyValues": batch.filename,
                "uploadedBy":this.createdUser
              };
              this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
              this.responseMessage = 'Batch Invoked Successfully';
            }
          }
        }
      );
    }
    else {
      this.val = true;
    }
    this.invokeButton = 'disable';
    console.log('Batch Invoking Ends here');
  }

  onSubmit() {
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchbatchModel);
    console.log('sending data' + this.requestParameterModel);
    this.batchServices.getSubmit(this.requestParameterModel).subscribe(
      (response: ResponseMessage) => {
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = 'Data saved successfully';
        }
	 this.searchbatchModel.conceptCode='';
         this.searchbatchModel.orderType='';
         this.enable=false;
      }
    )
  }

  onChange() {
    this.responseMessage = '';
    this.val = false;
    this.invokeButton = 'enable';
  }

  onClickBack() {
    this._router.navigate(['']);
  }
 checkValue(){
    if(this.searchbatchModel.conceptCode != '' && this.searchbatchModel.conceptCode != null && this.searchbatchModel.conceptCode != undefined){
      if(this.searchbatchModel.orderType != '' && this.searchbatchModel.orderType != null && this.searchbatchModel.orderType != undefined){
        this.enable = true;
      }
    } 
    this.searchbatchModel
  }

}
