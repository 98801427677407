import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { PendingExportComponentService } from './pending-export-component.service';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { SearchPoResponseModel } from '../common/search-po-response-model';
import { PendingExportHeader } from './pending-header';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
import { PO_STATUS_PENDING } from '../common/field-name-list';
import { PendingExportResponseModel } from '../common/pending-export-response-model';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { PO_COMPONENT_STATUS_PENDING } from '../common/field-name-list';



@Component({
  selector: 'app-pending-export-component',
  templateUrl: './pending-export-component.component.html',
  //styleUrls: ['./pending-po.component.css']
  styleUrls: ['../../assets/css/bootstrap.css',
    '../../assets/css/bil-style.css',
    '../../../node_modules/font-awesome/css/font-awesome.css','./pending-export-component.component.css'],
  providers: [PendingExportComponentService]
})
export class PendingExportComponentComponent implements OnInit {
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;

  constructor(private _PendingPoService: PendingExportComponentService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router,
    private _utilsService: UtilsService
  ) { }

  pendingStatus: string = PO_COMPONENT_STATUS_PENDING;

  pendingPurchaseOrders: PendingExportHeader[] = [];
  public noResults = false;
  requestParameterModel: RequestParameterModel[];
  pendingOrders = new PendingExportHeader();
  componentStatus:string='Approved';
  responseMessage:string;
  ngOnInit() {


    this._PendingPoService.getPendingPoList(this.pendingStatus).subscribe(
      (response: PendingExportResponseModel) => {
        response.data.forEach(
          searchPo => {
            //if (this.articleNumber === searchPo.articleNumber) {
              this.pendingPurchaseOrders.push(searchPo);
              console.log("sdfhhf" + JSON.stringify(searchPo));
            //}

          }
        );
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
    if (this.pendingPurchaseOrders != null && this.pendingPurchaseOrders.length == 0) {
      this.noResults = true;
    }
    else {
      this.noResults = false;
    }

  }
  onApproved(index) {
    console.log('Approved action button clicked');
    this.pendingPurchaseOrders.splice(index,1);
    console.log('sending parameters'+JSON.stringify(this.pendingPurchaseOrders[index]));
    this.pendingPurchaseOrders[index].componentStatus = "Approved";
    console.log('sending parameters component status'+JSON.stringify(this.pendingPurchaseOrders[index].componentStatus));
    let details : PendingExportHeader[] = [];
    details.push(this.pendingPurchaseOrders[index]);
      this._PendingPoService.getServiceApprovedCriteria(details).subscribe(
        (response : ResponseMessage) => {
          console.log('response'+response);
          if(response!=null && response.status !=null && SUCCESS_MSG === response.status.toLowerCase()){
            this.responseMessage = 'Approved Successfully';
          }
         }
    )
  }


 onClickBack() { this._router.navigate(['search-po']); }

}
