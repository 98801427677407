export class ImportPrice{
    fobId:string;
    artNo:number;
    artName:string;
    currency:string;
    price:string; 
   
    dataList:ImportPrice[];
   
 constructor(){}
}
