import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UpdatePendingPoService } from './update-pending-po.service'
import { PurchaseOrderHeaderWithLines } from '../../search-po/po-header-with-lines';
import { PurchaseOrderLines } from '../../search-po/po-lines';
import { UpdatePoResponseModel } from '../../common/update-po-response-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { DeleteDetails } from '../../common/delete-details';
import { ResponseMessage } from '../../common/response-message';
import { SUCCESS_MSG } from '../../common/field-name-list';
import { User } from '../../models/user';
import { Role } from '../../models/role';
import { AuthenticationService } from '../../services/authentication.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-update-pending-po',
  templateUrl: './update-pending-po.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css', './update-pending-po.component.css'],
  providers: [UpdatePendingPoService]
})
export class UpdatePendingPoComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;


  constructor(private _updatePendingPoService: UpdatePendingPoService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private route: ActivatedRoute,
    private _router: Router,
    private _utilsService: UtilsService, private authenticationService: AuthenticationService, private datePipe: DatePipe) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  poNumber: string;
  searchedPendingPoDetails = new PurchaseOrderHeaderWithLines();
  searchedPoLines: PurchaseOrderLines[] = [];
  private _requestParameterModelArray: RequestParameterModel[];
  remarks: string;
  selectedIndex: number;
  responseMessage: string;
  poLineStatus: string;
  poArtQty: number;
  shippedQty: number;
  cancelledQty: number;
  deliveryOldStartDate: string;
  deliveryOldEndDate: string;
  deliveryFromWeek: string;
  deliveryToWeek: string;
  id: number;
  userRole: string;
  currentUser: User;
  username: string;
  currentDate: Date;
  dateOfYear: string;
  arr = ['fromDate', 'toDate'];

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }


  myFunc() {

    this.currentDate = new Date();
    let myDate = new Date(this.currentDate);
    let year = this.currentDate.getFullYear();
    let mnth = ("0" + (myDate.getMonth() + 1)).slice(-2); let day = ("0" + myDate.getDate()).slice(-2);
    this.dateOfYear = [day, mnth, year].join("/");

    let sessUsername = sessionStorage.getItem('currentUser');
    this.userRole = JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');

    var conceptQueryMas = [{ "name": "email", "value": JSON.parse(sessUsername).groupAssignmentModels[0].userName }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.poNumber = this._navigationLinkParameterService.id;
    // let searchedPoNumber = this.searchedPendingPoDetails.poNumber;
    if (this._navigationLinkParameterService.details != null && this._navigationLinkParameterService.details.length > 0) {
      this._requestParameterModelArray = [];
      this._requestParameterModelArray = this._navigationLinkParameterService.details;
      this._requestParameterModelArray.forEach(
        requestParameter => {
          if ('poNumber' == requestParameter.name) {
            this.searchedPendingPoDetails.poNumber = requestParameter.value;
          }

          if ('poStatus' == requestParameter.name) {
            this.searchedPendingPoDetails.poStatus = requestParameter.value;
          }

          if ('poCreateDate' == requestParameter.name) {
            this.searchedPendingPoDetails.poCreateDate = requestParameter.value;
          }

          if ('createdBy' == requestParameter.name) {
            this.searchedPendingPoDetails.createdBy = requestParameter.value;
          }
          if ('reqNumber' == requestParameter.name) {
            this.searchedPendingPoDetails.reqNumber = requestParameter.value;
          }
          if ('conceptCode' == requestParameter.name) {
            this.searchedPendingPoDetails.conceptCode = requestParameter.value;
          }

          if ('poType' == requestParameter.name) {
            this.searchedPendingPoDetails.poType = requestParameter.value;
          }
          if ('poAddlType' == requestParameter.name) {
            this.searchedPendingPoDetails.poAddlType = requestParameter.value;
          }
          if ('partyNumber' == requestParameter.name) {
            this.searchedPendingPoDetails.partyNumber = requestParameter.value;
          }
          if ('partyShortName' == requestParameter.name) {
            this.searchedPendingPoDetails.partyShortName = requestParameter.value;
          }

        }

      );

      this._updatePendingPoService.getPurchaseOrderBySearchCriteria(this.searchedPendingPoDetails.poNumber).subscribe(
        (response: UpdatePoResponseModel) => {
          console.log(JSON.stringify(response));
          response.dataList.forEach(
            searchPo => {
              if (this.searchedPendingPoDetails.poNumber === searchPo.poNumber) {
                this.searchedPendingPoDetails = searchPo;
                this.searchedPoLines = searchPo.poLines;
                if (this.searchedPendingPoDetails.poType != 'I') {
                  this.searchedPoLines.forEach(
                    poType => {
                      poType.currencyCode = 'INR';
                    }
                  );
                }
                this.searchedPoLines.forEach(
                  poLine => {
                    //   if(poLine.deliveryOldStartDate != "" && poLine.deliveryOldStartDate != null){
                    //   let dS = poLine.deliveryOldStartDate.split('/');
                    //   let dS1 = dS[2] + '/' + dS[1] + '/' + dS[0];
                    //   let myDate1 = new Date(dS1);
                    //   poLine.oldStartWeek = this.getWeekNumber(myDate1);
                    //   }
                    //   if(poLine.deliveryOldEndDate != "" && poLine.deliveryOldEndDate != null){
                    //   let dE = poLine.deliveryOldEndDate.split('/');
                    //   let dE1 = dE[2] + '/' + dE[1] + '/' + dE[0];
                    //   let myDate2 = new Date(dE1);
                    //   poLine.oldEndWeek = this.getWeekNumber(myDate2);
                    // }
                    let outstandingQuant = poLine.poArtQty - poLine.shippedQty - poLine.cancelledQty;
                    if (outstandingQuant < 0) {
                      poLine.outstandingQty = '0';
                    }
                    else {
                      poLine.outstandingQty = (outstandingQuant).toString();
                    }
                  }
                );
              }
              console.log('saerched po libne' + JSON.stringify(this.searchedPoLines));
            }
          );
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
  }

  ngOnInit() {

    this.myFunc();

  }

  cancelPurchaseOrderLine(index) {
    this.searchedPoLines.splice(index, 1);
  }

  onClickBack() {
    this._router.navigate(['pending-po']);
  }

  onClickEditDetails(index) {
    // this._requestParameterModelArray = [];
    // this._requestParameterModelArray = this._utilsService.createRequestParametersModelArray(this.searchedPoLines[index]);
    // this._navigationLinkParameterService.details = this._requestParameterModelArray;
    // this._router.navigate(['distribution-detail']);
    this._navigationLinkParameterService.poHeaderId = this.searchedPendingPoDetails.id;
    this._navigationLinkParameterService.tot_details = this.searchedPoLines[index];
    this._router.navigate(['distribution-detail']);

  }


  approvePurchaseOrder() {

    if (this.poLineStatus == "Pending Cancel") {
      let poType: string = 'poLine';
      let details = {
        "poLineStatus": this.poLineStatus,
        "approverRemarks": this.remarks,
        "action": "Approved",
        "cancelledQty": this.poArtQty - this.shippedQty - this.cancelledQty,
        "approveRejectedBy": this.username,
        "lastUpdateBy": this.username,
        "lastUpdateDate": this.dateOfYear
      }
      this.sendingAppRejData(poType, details);

    }
    else if (this.poLineStatus == "Pending Approval") {
      let poType: string = 'poLine';
      let details = {
        "poLineStatus": this.poLineStatus,
        "approverRemarks": this.remarks,
        "action": "Approved",
        "shippedQty": this.shippedQty,
        "approveRejectedBy": this.username,
        "lastUpdateBy": this.username,
        "lastUpdateDate": this.dateOfYear
      }
      this.sendingAppRejData(poType, details);

    }
    else {
      let poType: string = 'poLine';
      let details = {
        "poLineStatus": this.poLineStatus,
        "approverRemarks": this.remarks,
        "action": "Approved",
        "cancelledQty": "0",
        "shippedQty": this.shippedQty,
        "approveRejectedBy": this.username,
        "lastUpdateBy": this.username,
        "lastUpdateDate": this.dateOfYear

      }
      this.sendingAppRejData(poType, details);
    }
  }

  rejectPurchaseOrder() {

    if (this.poLineStatus == "Pending Cancel") {
      let poType: string = 'poLine';
      let details = {
        "poLineStatus": this.poLineStatus,
        "approverRemarks": this.remarks,
        "action": "Reject",
        "shippedQty": this.shippedQty,
        "approveRejectedBy": this.username,
        "cancelReason": "",
        "lastUpdateBy": this.username,
        "lastUpdateDate": this.dateOfYear
      }

      this.sendingAppRejData(poType, details);

    }
    else if (this.poLineStatus == "Pending Approval") {
      let poType: string = 'poLine';
      let details = {
        "poLineStatus": this.poLineStatus,
        "approverRemarks": this.remarks,
        "action": "Reject",
        "shippedQty": this.shippedQty,
        "deliveryStartDateFinal": this.deliveryOldStartDate,
        "deliveryEndDateFinal": this.deliveryOldEndDate,
        "deliveryFromWeekFinal": this.deliveryFromWeek,
        "deliveryToWeekFinal": this.deliveryToWeek,
        "approveRejectedBy": this.username,
        "cancelReason": "",
        "lastUpdateBy": this.username,
        "lastUpdateDate": this.dateOfYear
      }
      this.sendingAppRejData(poType, details);
    }
    else {
      let poType: string = 'poLine';
      let details = {
        "poLineStatus": this.poLineStatus,
        "approverRemarks": this.remarks,
        "action": "Reject",
        "approveRejectedBy": this.username,
        "cancelReason": "",
        "lastUpdateBy": this.username,
        "lastUpdateDate": this.dateOfYear
      }

      this.sendingAppRejData(poType, details);
    }
  }

  disableButtonApp(poStat) {
    let userRoleIndex = this.userRole.indexOf('HOD');
    if ((poStat == 'Pending Recall' || poStat == 'Pending Approval') && userRoleIndex >= 0) {

      return false;
    }
    else {
      return true;
    }

  }
  disableButtonRej(poStat) {
    let userRoleIndex = this.userRole.indexOf('HOD');
    if ((poStat == 'Pending Recall' || poStat == 'Pending Approval') && userRoleIndex >= 0) {

      return false;
    }
    else {
      return true;
    }

  }


  setDataApp(stat, artQty, shipQty, canQty, id1) {
    this.poLineStatus = stat;
    this.poArtQty = artQty;
    this.shippedQty = shipQty;
    this.cancelledQty = canQty;
    this.id = id1;
    this.remarks = '';
    this.responseMessage = '';
  }

  setDataRej(stat, shipQty, id1, delvStartDate, delvEndDate) {
    this.responseMessage = '';
    this.poLineStatus = stat;
    this.shippedQty = shipQty;
    this.id = id1;
    this.deliveryOldStartDate = delvStartDate;
    this.deliveryOldEndDate = delvEndDate;
    let dS = delvStartDate.split('/');
    let dS1 = dS[2] + '/' + dS[1] + '/' + dS[0];
    let dE = delvEndDate.split('/');
    let dE1 = dE[2] + '/' + dE[1] + '/' + dE[0];
    let myDate1 = new Date(dS1);
    let myDate2 = new Date(dE1);
    this.arr = ['fromDate', 'toDate'];
    this.getWeekNumber(myDate1, this.arr[0]);
    this.getWeekNumber(myDate2, this.arr[1]);
    this.remarks = '';
  }

  getWeekNumber(date, key) {
    // d = new Date(+d);
    // let year = d.getFullYear();
    // d.setHours(0, 0, 0);
    // d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // var yearStart = new Date(d.getFullYear(), 0, 1);
    // var weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7).toString();
    // if(weekNo.length == 1){
    //   weekNo = '0'+weekNo;
    // }
    // let weekYear = year + weekNo;
    // console.log('weekYearValue'+'  '+weekYear);
    // return weekYear;
    console.log('date   ' + date);
    let weekNo: any;
    let dateTimeFormatStr: string = 'dd/MM/yyyy';
    let changedDateStr = this.datePipe.transform(date, dateTimeFormatStr);
    this._updatePendingPoService.getWeekAndYear(changedDateStr).subscribe(
      (res: any) => {
        let week:string = res.dataList[0].bataWeek.toString();
        if(week.length == 1){
          week = '0'+week;
        }
        weekNo = res.dataList[0].bataYear.toString() + week;
        if (key == 'fromDate') {
          this.deliveryFromWeek = weekNo;
        }
        else {
          this.deliveryToWeek = weekNo;
        }
      }
    );
  }

  sendingAppRejData(poType, details) {
    let deleteDetails = new DeleteDetails();
    this._requestParameterModelArray = [];
    this._requestParameterModelArray = this._utilsService.createRequestParametersModelArray(details);
    console.log(deleteDetails);
    deleteDetails.type = poType;
    deleteDetails.id = this.id;
    deleteDetails.updatedDataList = this._requestParameterModelArray;
    console.log(JSON.stringify(deleteDetails));
    this._updatePendingPoService.getPoDeleted(deleteDetails).subscribe(
      (response: ResponseMessage) => {
        console.log(response);
        if (SUCCESS_MSG === response.status.toLowerCase() && details.action == 'Approved') {
          console.log('Approved Successfully');
          this.responseMessage = "Approved Successfully";
          this.remarks = '';
          this.searchedPoLines = [];
          this.myFunc();
        }
        else if (SUCCESS_MSG === response.status.toLowerCase() && details.action == 'Reject') {
          console.log('Rejection Successfully');
          this.responseMessage = "Rejected Successfully";
          this.remarks = '';
          this.searchedPoLines = [];
          this.myFunc();
        }
        else {
          console.log('Could not delete');
          this.responseMessage = "Error Occured";
        }
      },
      (err) => {
        this.responseMessage = err;
      }
    )
  }


}
