import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  //styleUrls: ['./logout.component.css']
  styleUrls: ['../../assets/css/bootstrap.css','../../assets/css/iofrm-style.css','./logout.component.css']
})
export class LogoutComponent implements OnInit {
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;


  logoutForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  messg: string;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
    ) {
      if (this.authenticationService.currentUserValue) { 
        this.router.navigate(['/']);
      }

   }

  ngOnInit() {

    if(typeof localStorage.getItem('errMessage') !=='undefined'){
      console.log(localStorage);
      this.error=localStorage.getItem('errMessage');
      localStorage.clear();
      console.log(localStorage);
    }
    

    setTimeout(()=>{
      this.loaDer.nativeElement.style.display = 'none';    
    },100);

    this.logoutForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  console.log(this.returnUrl);
}
get f() { return this.logoutForm.controls; } 

home() {
    this.router.navigate(['/login']);
}

// onSubmit() {
//   this.submitted = true;
//   this.error = null;
//   // reset alerts on submit
//   this.alertService.clear();

//   // stop here if form is invalid
//   if (this.logoutForm.invalid) {
//       return;
//   }

//   this.loading = true;
//   this.loaDer.nativeElement.style.display = 'block';
//    console.log(this.f.username.value+ ' ---- '+ this.f.password.value);
//   this.authenticationService.logout(this.f.username.value, this.f.password.value)
//       .pipe(first())
//       .subscribe(
//           data => {
//             console.log(JSON.stringify(data));
//             /* Modified for testing start*/
//             if(typeof data.groupAssignmentModels !=='undefined'  ){
//               console.log('check');
//               if(data.status!='false'){
//               console.log('LDAP');
//                 if(data != '' && data.groupAssignmentModels[0].activeFlag == 'A')
//                 {
//                   localStorage.setItem('refresfToken', "false");
//                   this.router.navigate([this.returnUrl]);
//                 } else { 
//                   // this.messg = 'User Is Not Active. Please contact with Administrator.';
//                   this.alertService.success('User Is Not Active. Please contact Administrator.', true); 
//                   this.loading = false;
//                   this.loaDer.nativeElement.style.display = 'none';
//                 }
//               }
//               else{
//                 this.error = 'UserId or Password is incorrect';
//                 this.loading = false;
//                 this.loaDer.nativeElement.style.display = 'none';
//               }

//             }
//             // else{
//             //   console.log('Non LDAP');
//             //     if(data != '' && data[0].activeFlag == 'A')
//             //   {
//             //     this.router.navigate([this.returnUrl]);
//             //     this.loaDer.nativeElement.style.display = 'none';
//             //   } else { 
//             //     // this.messg = 'User Is Not Active. Please contact with Administrator.';
//             //     this.alertService.success('User Is Not Active. Please contact Administrator.', true); 
//             //     this.loading = false;
//             //     this.loaDer.nativeElement.style.display = 'none';
//             //   }
//             //    /* Modified for testing end*/
//             // }
            
              
//           },
//           error => {
//               this.alertService.error(error);
//               this.loading = false;
//               this.loaDer.nativeElement.style.display = 'none';
//           });

//   }

}
