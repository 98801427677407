export class CreateGINModel {
        invoiceNumber: string;
        shopNumber: string;
        asnNumber: string;
        invoiceDate: string;
        onwayValue: string;
        receivedPairQty: string;
        shortagePairQty: string;
        totalPairs: string;
        excessPairQty: string;
        grnStatus: string;
        invoiceValue: string;
        shippedCartonQty: number;
        receivedCartonQty: number;
        shortageCartonQty: number;
        excessCartonQty: string;
        consignorCode: string;
        consignorName: string;
        consigneeCode: string;
        consigneeName: string;
        ewayBillNumber: string;
        lastUpdatedBy: string;

        constructor() {}

}
