import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ManageCcListCreateService } from './manage-cc-list-create.service';
import { Concept } from '../common/concept';
import { CcListSearchDetails } from '../manage-cc-list/manage-cc-list-detail';
import { CcListModel } from '../manage-cc-list/manage-cc-list-model';
import { CcListDetails } from './manage-cc-details';
import { Party } from '@/manage-supplier-access/manage-party-details-model';


@Component({
  selector: 'app-manage-cc-list-create',
  templateUrl: './manage-cc-list-create.component.html',
  styleUrls: ['./manage-cc-list-create.component.css']
})

export class ManageCcListCreateComponent implements OnInit {
  concepts: Concept[];
  // parties: any = [];
  ccTypeList: any = [{ name:'Merchandising', value: 'M'},
                 { name:'Replenishment', value: 'R'},
                 { name:'Sourcing Import', value: 'SI'},
                 { name:'Sourcing Domestic', value: 'SD'},
                 { name:'Sourcing Consumable', value: 'SC'}];
  createCcListModel = new CcListSearchDetails();
  ccList: CcListSearchDetails[] = [];
  createCcList = new CcListSearchDetails();
  ccListSearchParameter = new CcListModel();
  ccDetailList: CcListDetails[] = [];
  selectedUser: number;
  selectedRow: number = 0;
  responseMessage: string;
  isValidMail : boolean;
  ccMails:string[] = [];
  mt1: boolean[] = [];
  mt2: boolean[] = [];
  disableIndex: number = 0;
  ccPassingList: CcListSearchDetails[] = [];
  details:any;
  username:string;
  err:any;
  partyName:any = [];
  emailRegExp = new RegExp('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$', 'i');

  constructor(private _router: Router, private _createCcListService: ManageCcListCreateService) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.createCcListModel = new CcListSearchDetails();
    this.ccList.push(this.createCcListModel);
    this.mt1[0] = false;
    this.mt2[0] = false;
    
    this._createCcListService.getUserConcept().subscribe(
      (data: any) => {
        console.log(data);
        this.concepts = data;
      }
    )
    console.log(this.ccList[this.disableIndex].conceptCode);
    console.log(this.ccList[this.disableIndex].ccType);
    // this._createCcListService.getPartyNumber().subscribe(
    //     (data: any) => {
    //       console.log(data);
    //       this.parties = data.partyNumberList;
    //     }
    //   )
  }

  addCcList() {
    console.log(this.mt1[this.disableIndex]);
    console.log(this.mt2[this.disableIndex]);
    console.log(this.disableIndex);
    console.log(this.ccList);


    if((this.ccList[this.disableIndex].conceptCode == '' || this.ccList[this.disableIndex].conceptCode == undefined || this.ccList[this.disableIndex].conceptCode == null)  ||
    (this.ccList[this.disableIndex].ccType == null || this.ccList[this.disableIndex].ccType == '' || this.ccList[this.disableIndex].ccType == undefined) || (this.ccList[this.disableIndex].ccEmail == '' || this.ccList[this.disableIndex].ccEmail == undefined || this.ccList[this.disableIndex].ccEmail == null)){
      this.responseMessage = 'Please fill Data in the current row';
    }else{
      console.log(this.ccList[this.disableIndex].conceptCode);
      console.log(this.ccList[this.disableIndex].ccType);
      console.log('add...');

      if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
      this.createCcList = new CcListSearchDetails();
      this.ccList.push(this.createCcList);
      this.selectedRow = null;
      this.selectedUser = null;
      this.ccDetailList = [];
      this.ccListSearchParameter = new CcListModel();
      this.disableIndex++;
      this.responseMessage = '';
    }
  }
  console.log(this.disableIndex);


  }

  // onClickSearchUser() {
  //   if(this.ccListSearchParameter.partyNumber == undefined){
  //    this.details =  {
  //      "partyNumber": ""
  //     }
  //   }
  //   else{
  //     this.details =  {
  //       "partyNumber": this.ccListSearchParameter.partyNumber
  //      }
  //   }
  //   console.log(JSON.stringify(this.details));
  //   this._createCcListService.getUserDetails(this.details).subscribe(
  //     (response: CcListDetails[]) => {
  //       console.log(response);
  //       this.ccDetailList = response;
  //     }
  //   )
  // }

  onClickBack() {
    this._router.navigate(['']);
  }

  setIndexForUser(index) {
    this.responseMessage = '';
    this.selectedUser = index;
  }

  // onClickSubmit() {
  //   this.ccList[this.selectedRow].partyNumber = this.ccDetailList[this.selectedUser].partyNumber;
  //   this.ccList[this.selectedRow].partyShortName = this.ccDetailList[this.selectedUser].partyShortName;
  //   this.mt1[this.disableIndex] = true;

  // }

  settingIndexforConceptLine(partyNo,index) {
    this.responseMessage = '';
    this.selectedRow = index;
  }

  setData(ccValue, i) {
    this.responseMessage = '';
    this.ccList[i].ccType = ccValue;
    this.mt2[this.disableIndex] = true;
  }

  setData1(concCode, i) {
    this.responseMessage = '';
    console.log(concCode);
    this.ccList[i].conceptCode = concCode;
    this.mt1[this.disableIndex] = true;
  }



  onClickSaveButton() {
    this.responseMessage = '';
    this.ccPassingList = [];
    console.log(this.mt1[this.disableIndex]);
    console.log(this.mt2[this.disableIndex]);
    this.isValidMail = true;

    if ((this.ccList[this.disableIndex].conceptCode == '' || this.ccList[this.disableIndex].conceptCode == undefined || this.ccList[this.disableIndex].conceptCode == null) ||
      (this.ccList[this.disableIndex].ccType == '' || this.ccList[this.disableIndex].ccType == undefined || this.ccList[this.disableIndex].ccType == null) || (this.ccList[this.disableIndex].ccEmail == '' || this.ccList[this.disableIndex].ccEmail == undefined || this.ccList[this.disableIndex].ccEmail == null)){
      this.responseMessage = 'Please fill Data First';
    }
    else {
      console.log(this.ccList[this.disableIndex].conceptCode);
      console.log(this.ccList[this.disableIndex].ccType);
      console.log(JSON.stringify(this.ccList));


      this.ccList.forEach((conc, index) => {
        if (conc.ccEmail != null
          && conc.ccEmail != undefined
          && conc.ccEmail.length > 0) {
          let userCcMail = conc.ccEmail;
          console.log("userCcMail=" + userCcMail);
          if (userCcMail.includes(",")) {
            console.log("multiple cc mail present");
            this.ccMails = userCcMail.split(",");
            this.ccMails.forEach((individualMail) => {
              if (!this.emailRegExp.test(individualMail)) {
                this.responseMessage = 'Please enter proper email id for CC Mail at row=' + (index + 1);
                this.isValidMail = false;
              }
            });
          } else if (!this.emailRegExp.test(userCcMail)) {
            console.log("single cc mail present");
            this.responseMessage = 'Please enter proper email id for CC Mail at row=' + (index + 1);
            this.isValidMail = false;
          }
        }
      });


      console.log("isValidMail="+this.isValidMail);

      if (this.isValidMail && this.mt2[this.disableIndex] == true && this.mt1[this.disableIndex]) {
        console.log(this.ccList);
        this.ccList.forEach(
          (conc, index) => {
            let ccPassing = new CcListSearchDetails();
            ccPassing.ccType = conc.ccType;
            ccPassing.ccEmail = conc.ccEmail;
            // ccPassing.partyNumber = conc.partyNumber;
            // ccPassing.partyShortName = conc.partyShortName;
            ccPassing.conceptCode = conc.conceptCode;
            ccPassing.isActive = "Y";
            ccPassing.createdBy = this.username;
            ccPassing.lastUpdatedBy = this.username;
            this.ccPassingList.push(ccPassing);
          }
        );
        console.log(this.ccList);
        console.log(JSON.stringify(this.ccPassingList));
        console.log((this.ccPassingList));
        
       if(this.duplicateDetailsValidate(this.ccPassingList)){
        console.log('calling service....');

        this._createCcListService.saveCcDetails(this.ccPassingList).subscribe(
          (response: any) => {
            console.log(response);
            if (response.status == 'Success') {
              this.responseMessage = 'CC Data Created Successfully';

              setTimeout(()=>{
                this.responseMessage = '';
                this._router.navigate(['manage-cc-list']);
              }, 3000);
            
            }
            else {
              this.responseMessage = response.message;
            }
          },
          (err)=>{
            console.log('error');
            console.log(err);
             this.responseMessage = 'Some technical error occurred.';

         }
        )
      }else{
        this.responseMessage=this.err;
  }
      }
    }
  }

  cancelLineDetails(index){
    if (this.disableIndex != 0) {
      this.ccList.splice(index, 1);
      this.disableIndex--;
      this.responseMessage = '';
    }
  }
duplicateDetailsValidate(ccPassingList): any{
    let check = 0;
    let i;
    let ccListLength=ccPassingList.length;
    let concCodeLength=this.concepts.length;
    for(i=0;i<ccListLength;i++){
      check =0;
      this.err = '';
      for(let j=0; j<ccListLength;j++){
        console.log(ccPassingList[i].conceptCode+'  '+ccPassingList[j].conceptCode +'  '+ ccPassingList[i].ccType+'  '+ccPassingList[j].ccType);
        if((ccPassingList[i].conceptCode == ccPassingList[j].conceptCode) && (ccPassingList[i].ccType == ccPassingList[j].ccType)){
          this.err = '';
        check = check + 1;
         }
        }
      console.log(check);
      if(check > 1){    
      console.log('line: '+ (i+1));
      break;
      }
    }
    console.log(check);
   if(check>1){
    this.err = 'Duplicate Data Found';
    return false;
   }
   else
    return true;
    
  }

}
