import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReportHeader } from './report-header';
import { RdsReportInvoiceService } from './rds-report-invoice.service';
import { ReportGeneration } from '@/common/report-generation';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ReportSearch } from './report-header-search';
import { Concept } from '../../common/concept';
import { ReportNames } from '../../common/reports-names';
import { OrderType } from '../../common/orderType';

@Component({
  selector: 'app-rds-report-invoice',
  templateUrl: './rds-report-invoice.component.html',
  styleUrls: ['./rds-report-invoice.component.css']
})
export class RdsReportINVOICEComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myform', { static: false }) myForm: ElementRef;
  enable = false;
  shopEnable = true;
  regionEnable = true;
  distEnable = true;
  destinationEnable = false;
  regions = [{ name: 'North (N)', value: 'N' },
  { name: 'South (S)', value: 'S' },
  { name: 'East (E)', value: 'E' },
  { name: 'West (W)', value: 'W' }];
  articleType = [{name:'Fresh',value:'FRESH'},
                 {name:'Defective',value:'DEFECTIVE'},
                 {name:'Claimed',value:'CLAIMED'}];
  reportModel = new ReportHeader();
  requestParameterModel: RequestParameterModel[];
  searchParameterModel = new ReportSearch();
  searchParameterModelOutward = new ReportSearch();
  searchParameterModelEstimate = new ReportSearch();
  concepts: Concept[];
  conceptswithAll: any[] = [];
  orderTypeWithAll: any[] = [];
  conceptsForRetail: any[] = [];
  reportNames: ReportNames[];
  // loading:boolean=false;
  username: string;
  orderTypes: OrderType[] = [];
  orderTypesList: any[] = [];
  coCode: any;
  responseMessage: string;
  sucessMeg1: string;
  suppNoLength: boolean = false;
  asyncSuccess: string = 'Failed';
  loginUserEmail: string;
  generatebutton: string = 'enable';
  sucessMeg: string;
  showDiv = {
    Daily: false,
    Week: false,
    Fortnightly: false,
    Monthly: false
  }
  showDiv1 = {
    Factory: false,
    Vendor: false,
  }
  showReportList = {
    Reportname: false
  }
  //fromWeek: string;
  errorMessage: string;
  payloadBataWeek = {};
  selectedValue = 'M';
  fromDate: string;
  arr = ['fromDate', 'toDate','invoiceDate'];
  selectedVal: String;
  date: Date;
  toDate: string;
  ShopCodes: any[] = [];
  DistrictCodes: any[] = [];
  names: any[];
  onlyPerfix: any[] = [];
  uniquerper: any[] = [];
  disableReportList = true;
  selectedPrefix: string;
  selectedList: any[] = [];
  uniqueDist: any[]= [];
  routeDetails:any[];
  todayDate:Date;

  obj= {
    GDS: 'Either "RDC Code" / "Region" is REQUIRED.',
    ICCNR: 'Either "RDC Code" / "Region" is REQUIRED.',
    DROR_AR: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    CD_FR: 'Either "RDC Code" / "Region" is REQUIRED.',
    DDR: 'Either "Shop Code" / "Region" is REQUIRED.',
    DAW: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    DISPATCH_SUMMARY: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    OGPR: '"Shop Code" is REQUIRED.'
};

  constructor(private _router: Router, private _generateReportService: RdsReportInvoiceService,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    // this.searchParameterModel.stockTypeDesc = this.articleType[0].name;
    // this.searchParameterModel.stockTypeCode = this.articleType[0].value;
    this.reportModel.reportFormatType = 'xlsx';
    this.todayDate = new Date();
    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.loginUserEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail;
    this.getRouteNumber();
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        this.reportNames = data;
        this.reportNames.forEach(element => {
          let reportPrefixOnly: {} = element.reportName.split("_");
          let rsp_prf = {
            "perfix": reportPrefixOnly[0],
            "reportName": reportPrefixOnly[1],
            "reportKey": element.reportKey
          };
          this.onlyPerfix.push(rsp_prf);
        });
        this.uniquerper = [...new Set(this.onlyPerfix.map(x => x.perfix))];
        this.onSelectPerfix("INVOICE");
      }
    )

    this._generateReportService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        this.concepts.forEach(
          concept => {
            this.conceptswithAll.push(concept);
            if (concept.division == 'RETAIL' && concept.concCode != 'N') {
              this.conceptsForRetail.push(concept);
            }
          }
        );
        let conc = new Concept();
        conc.concCode = 'ALL';
        conc.concept = 'ALL';
        conc.description = 'ALL CONCEPT';
        this.conceptswithAll.unshift(conc);
        //this.searchParameterModel.concept = 'ALL';
      }
    )
  }

  stockChange(val){
    this.errorMessage = "";
    console.log(val);
    // if(val == this.articleType[0].name){
    //   this.searchParameterModel.stockTypeCode = this.articleType[0].value;
    // }else if(val == this.articleType[1].name){
    //   this.searchParameterModel.stockTypeCode = this.articleType[1].value;
    //   console.log(this.searchParameterModel.stockTypeCode);
    // }else{
    //   this.searchParameterModel.stockTypeCode = this.articleType[2].value;
    // }
    let value = val.split(':')[1].trim();
    this.articleType.filter(cs => cs.name == value).forEach(
      each => {
        this.searchParameterModel.stockTypeCode = each.value;
      }
    );
    console.log(this.searchParameterModel.stockTypeCode);
  }

  clearErrorMsg() {
    this.errorMessage = '';
    this.responseMessage = '';
  }
  onSelectConcept(value) {
    this.clearErrorMsg();
    this.loaDer.nativeElement.style.display = 'block';
    this.ShopCodes = [];
    this.DistrictCodes = [];
    let divisionCode: string;
    this.searchParameterModel.concept = undefined;
    this.concepts.filter(cs => cs.concCode === this.searchParameterModel.conceptCode).forEach(
      div => {
        if (value != 'ALL') { 
        this.searchParameterModel.concept = (div.concCode +'-'+ div.description);
        }
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
      }
    )
    let detail: any;
    if (value != 'ALL') {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': divisionCode,
        'userCode': this.username,
        'conceptCode': this.searchParameterModel.conceptCode
      }
    }
    else {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': '5',
        'userCode': this.username
      }
    }
    const requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.distEnable = false;
      this.regionEnable = false;
      this._generateReportService.getLocations(requestParameterModel).subscribe(
        (res: any) => {
          this.ShopCodes = [];
          this.uniqueDist = [];
          if (res.dataList != null) {
            res.dataList.forEach(
              data => {
                this.ShopCodes.push(data);
                this.uniqueDist.push(data);
                this.searchParameterModel.regionCode = undefined;
                this.DistrictCodes = [...new Set(this.uniqueDist.map(x => x.districtNumber))];
              }
            );
           
            console.log(this.DistrictCodes);
            
            // const details =
            // {
            //   "shopNumber": "ALL",
            //   "shopName": "All RDC CODE"
            // };
            // this.ShopCodes.unshift(details);
          }
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    else {
      this.shopEnable = true;
      this.distEnable = true;
      this.regionEnable = true;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onSelectConceptForRdcName(value) {
    this.clearErrorMsg();
    let detail: any;
    if (value === 'ALL') {
      detail = {
        "shopAgencyCode": "5",
        "userCode": this.username
      }
    }
    else {
      detail = {
        "shopAgencyCode": "5",
        "userCode": this.username,
        "conceptCode": this.searchParameterModel.conceptCode
      }
    }
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.regionEnable = false;
      this._generateReportService.getRdcCityName(detail).subscribe(
        (data: any) => {
          this.names = data.cities;
        });
    }
    else {
      this.shopEnable = true;
      this.regionEnable = true;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  public async OnClickGenerate(myForm): Promise<void> {
    this.responseMessage = '';
    this.errorMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.loaDer.nativeElement.style.display = 'block';
    let switchFlag: any;
    let reportKey: any;
    let reportName: any;
    this.sucessMeg = 'Success';
        
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        switchFlag = report.switchingFlag;
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    console.log(switchFlag+' - '+reportKey+' - '+reportName);
    
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    let userDetails = {
      "name": "userId",
      "value": this.username
    }
    this.requestParameterModel.push(userDetails);
    console.log('requesting parameters inside updatedDatalist' + JSON.stringify(this.requestParameterModel));
    let details = new ReportGeneration();
    if (switchFlag == 'N') {
        console.log('Report will get generated online');
        if(this.searchParameterModel.conceptCode != null || this.reportModel.reportKey === 'OGPR'){
        details.reportFormatType = this.reportModel.reportFormatType;
        details.reportInputRequestList = this.requestParameterModel;
        this.callingServiceWithoutBlob(details);
        this.loaDer.nativeElement.style.display = 'none';
      }  else{
        this.errorMessage = 'Concept Code is REQUIRED.';
          this.loaDer.nativeElement.style.display = 'none';
      }
    }
    else {
      console.log('Report will get generated offline');
      let detail = {
        "name": "reportKey",
        "value": reportKey
      }
      let nameDetail = {
        "name": "reportName",
        "value": reportName
      }
      this.requestParameterModel.push(detail);
      this.requestParameterModel.push(nameDetail);
      console.log(JSON.stringify(this.requestParameterModel));
      this._generateReportService.offlineReportGeneration(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          if (response.status == 'success') {
            // this.responseMessage = response.message;
            this.asyncSuccess = 'success';
            this.responseMessage = "Your report will be generated soon, you will be notified over mail. You can check the progress in View Report Status.";
          }
          else {
            this.asyncSuccess = 'Failed';
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.asyncSuccess = 'Failed';
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
  }

  callingServiceWithoutBlob(details) {
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    let reportDetails =
    {
      "name": "reportName",
      "value": reportName
    }
    this.requestParameterModel.push(reportDetails);
    let reportKeySelected =
    {
      "name": "reportKey",
      "value": reportKey
    }
    this.requestParameterModel.push(reportKeySelected);
    this.loginUserEmail
    let userSelectedEmail =
    {
      "name": "userEmail",
      "value": this.loginUserEmail
    }
    this.requestParameterModel.push(userSelectedEmail);
    // if (this.reportModel.report === 'Material receipt - From RDC / FACTORY / VENDOR  / STORE') {
    //   let index = this.requestParameterModel.findIndex(cs => cs.name == 'recievingFromName');
    //   let index1 = this.requestParameterModel.findIndex(cs => cs.name == 'vendorName');
    //   this.requestParameterModel.splice(index, 1);
    //   this.requestParameterModel.splice(index1, 1);
    // }
    // if (this.reportModel.report === 'Dispatch Report One RDC to another RDC') {
    //   let index = this.requestParameterModel.findIndex(cs => cs.name == 'dispatchName');
    //   let index1 = this.requestParameterModel.findIndex(cs => cs.name == 'vendorName');
    //   this.requestParameterModel.splice(index, 1);
    //   this.requestParameterModel.splice(index1, 1);
    // }
    if (this.searchParameterModel.conceptCode === 'ALL') {
      let index = this.requestParameterModel.findIndex(cs => cs.name == 'conceptCode');
      this.requestParameterModel.splice(index, 1);
    }
    this.requestParameterModel.filter(cs => cs.name == 'fromInvoiceCreateDate' || cs.name == 'toInvoiceCreateDate').forEach(
      each => {
        let deliveryFrmDate: any[] = each.value.split('/');
        let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1 , deliveryFrmDate[0]);
        each.value = this._utilsService.transformDateSearchchange(stDate).toUpperCase();
      }
    )
    
    if (this.reportModel.reportKey === 'GOW_1308B_RECAP' ||
      this.reportModel.reportKey === 'GOW_1302_RECAP') {
      let year = new Date().getFullYear();
      // let valueOfWeekFrom =
      // {
      //   "name": "weekFrom",
      //   "value": year + "01"
      // }
      let valueOfWeekFrom =
      {
        "name": "weekFrom",
        "value": "202001"
      }
      this.requestParameterModel.push(valueOfWeekFrom);
    }
    details.reportInputRequestList = this.requestParameterModel;
    console.log(JSON.stringify(details));

    let r=new Map(Object.entries(this.obj));
    if(this.reportModel.reportKey === 'OGPR'){
      this.searchParameterModel.gatePassKey = 'Allow';
    }

      if (this.searchParameterModel.toShopCode != null || this.searchParameterModel.sourceRdc != null || this.searchParameterModel.toDistrictCode != null || this.searchParameterModel.districtNumber != null || this.searchParameterModel.fromRdcDistrictCode != null ||
        this.searchParameterModel.regionCode != null || this.searchParameterModel.shopCode != null || this.searchParameterModel.rdcNo != null
        || this.searchParameterModel.fromShopCode != null || this.searchParameterModel.rdcCode != null || this.searchParameterModel.RdcCode != null ||
        this.searchParameterModel.fromRdcCode != null || this.searchParameterModel.toRdcCode != null ||
        this.searchParameterModel.shopCityName != null || this.searchParameterModel.fromDistrictCode != null || this.searchParameterModel.gatePassKey != null) {
            
        if (this.reportModel.reportKey === 'OGPR') 
        {
          this.searchParameterModel.gatePassKey = undefined;
          let statusCheck: boolean;
          let fromDate:any;
          let toDate:any;
          this.requestParameterModel.filter(cs => cs.name == 'gatePassStartDate' || cs.name == 'gatePassEndDate').forEach(
            each => {
              let deliveryFrmDate: any[] = each.value.split('/');
              let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1 , deliveryFrmDate[0]);
              each.value = this._utilsService.transformDateSearchchange(stDate).toUpperCase();
              if(each.name == 'gatePassStartDate'){
              fromDate = each.value
              }
              else{
                toDate = each.value
              }
            }
          )
          details.reportInputRequestList = this.requestParameterModel;
          if(fromDate != undefined || toDate != undefined || (this.searchParameterModel.gatePassNo != undefined && this.searchParameterModel.gatePassNo != '')){
            statusCheck = this.datecheck(fromDate, toDate); 
              if (statusCheck == true) {
                this.callReportService(details);
              }
          } else{
            this.errorMessage = 'Either From Date & To Date / Gate Pass No is required';
          }
          
        }
        else if (this.reportModel.reportKey === 'NVR') {
          let statusCheck: boolean;
          statusCheck = this.datecheck(this.searchParameterModel.weekFrom, this.searchParameterModel.weekTo);
          if (statusCheck == true) {

            this.callReportService(details);
         }
        }
        else if (this.reportModel.reportKey === 'CD_FR') {
          let statusCheck: string;
          statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.yearWeekFrom, this.searchParameterModel.yearWeekTo,'Bata Week YrWk From','Bata Week YrWk To');
          if (statusCheck == 'success') {
            this.callReportService(details);
          }
          else {
            this.errorMessage = statusCheck;
          }
        }
        else if (this.reportModel.reportKey === 'DROR_AR' ) {
          let statusCheck: string;
          statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.weekFrom, this.searchParameterModel.weekTo,'Year Week From','Year Week To');
          if (statusCheck == 'success') {
            if(this.searchParameterModel.stockTypeCode != null && this.searchParameterModel.stockTypeDesc != null){

            this.callReportService(details);
          }else{
            this.errorMessage = "Stock Type is REQUIRED";
          }
          }
          else {
            this.errorMessage = statusCheck;
          }
        
        }
        else if (this.reportModel.reportKey === 'ICCNR' ) {
          let statusCheck: string;
          statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.fromWeek, this.searchParameterModel.toWeek,'Year Week From','Year Week To');
          if (statusCheck == 'success') {
            this.callReportService(details);
          }
          else {
            this.errorMessage = statusCheck;
          }
        }
        /* ---------------------------------------------Date Format---------------------------------------------------- */
        else if (this.reportModel.reportKey === 'RECEIPT_SUMMARY' || this.reportModel.reportKey === 'WEEKLY_RECEIPT_VENDOR' ||
         this.reportModel.reportKey === 'RSUP_STATUS' || this.reportModel.reportKey === 'DISPATCH_SUMMARY' ||
          this.reportModel.reportKey === 'DAW' || this.reportModel.reportKey === 'RAW' || this.reportModel.reportKey === 'DDR') 
        {
          let statusCheck: boolean;
          if(this.searchParameterModel.reportPeriodType != null && this.reportModel.reportKey !== 'DDR'){
          //statusCheck = this.datecheck(this.searchParameterModel.fromDate, this.searchParameterModel.toDate);
          this.changeSelectedDate(this.searchParameterModel.toDate, this.searchParameterModel.fromDate);
          let frmDateArray: any[] = this.searchParameterModel.fromDate.split('/');
          let fromDateCheck = new Date(frmDateArray[2], frmDateArray[1] - 1, frmDateArray[0]);
          let toDateArray: any[] = this.searchParameterModel.toDate.split('/');
          let toDateCheck = new Date(toDateArray[2], toDateArray[1] - 1, toDateArray[0]);
          statusCheck = this.datecheck(fromDateCheck, toDateCheck);
            if (statusCheck == true) {
            if (this.reportModel.reportKey === 'DAW' || this.reportModel.reportKey === 'DISPATCH_SUMMARY') {
                if(this.searchParameterModel.stockTypeCode != null && this.searchParameterModel.stockTypeDesc != null){
                  this.callReportService(details);
                 } else{
                  this.errorMessage = "Stock Type is REQUIRED";
                }
              }
          }
        } else if(this.reportModel.reportKey === 'DDR')
        {
          if(this.searchParameterModel.stockTypeCode != null && this.searchParameterModel.stockTypeDesc != null)
          {
            if(this.searchParameterModel.reportPeriodType != null || 
              (this.searchParameterModel.fromInvoiceCreateDate != null && this.searchParameterModel.toInvoiceCreateDate != null))
              {
                if(this.searchParameterModel.reportPeriodType != null){
                  this.changeSelectedDate(this.searchParameterModel.toDate, this.searchParameterModel.fromDate);
                  let frmDateArray: any[] = this.searchParameterModel.fromDate.split('/');
                  let fromDateCheck = new Date(frmDateArray[2], frmDateArray[1] - 1, frmDateArray[0]);
                  let toDateArray: any[] = this.searchParameterModel.toDate.split('/');
                  let toDateCheck = new Date(toDateArray[2], toDateArray[1] - 1, toDateArray[0]);
                  statusCheck = this.datecheck(fromDateCheck, toDateCheck);
                } else{
                  this.changeSelectedDate(this.searchParameterModel.toInvoiceCreateDate, this.searchParameterModel.fromInvoiceCreateDate);
                  let frmDateArray: any[] = this.searchParameterModel.fromInvoiceCreateDate.split('/');
                  let fromDateCheck = new Date(frmDateArray[2], frmDateArray[1] - 1, frmDateArray[0]);
                  let toDateArray: any[] = this.searchParameterModel.toInvoiceCreateDate.split('/');
                  let toDateCheck = new Date(toDateArray[2], toDateArray[1] - 1, toDateArray[0]);
                  statusCheck = this.datecheck(fromDateCheck, toDateCheck);
                }
                if (statusCheck == true) {
                this.callReportService(details);
                }
              }
              else{
                this.errorMessage = "From Date & To Date / Type is REQUIRED";
              }
          }else{
            this.errorMessage = "Stock Type is REQUIRED";
          }
        }else{
          this.errorMessage = "Type is REQUIRED";
        this.loaDer.nativeElement.style.display = 'none';
        }
        }
        else {
          this.callReportService(details);
        }
      }else if(this.reportModel.reportKey === 'INVOICE_PRINT_BULK'){
        console.log(this.searchParameterModel);
        
        if(this.searchParameterModel.sourceShopCode != null && this.searchParameterModel.invoiceDate != null)
        {
          this.callReportService(details);
        }else{
          this.errorMessage = "RDC code and Invoice Date are REQUIRED";
        }
        
      } else {
        
        this.errorMessage = r.get(this.reportModel.reportKey);
        this.loaDer.nativeElement.style.display = 'none';
      }
  }

  callReportService(details) {
    this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.reportKey).subscribe(
      (response: any) => {
        if (response.status == 'Success') {
          this.asyncSuccess = 'success';
          if(this.reportModel.reportKey === 'INVOICE_PRINT_BULK'){
            this.responseMessage = 'Report will be generated soon on the SFTP Server.Go to : /projbiscs/BISCSRDC/Bulk_Invoice/BISCSRDC_IN';
          }else{
            this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          }
          
          this.loaDer.nativeElement.style.display = 'none';
          this.generatebutton = 'disable';
          this.delay(5000).then(any => {
            if(this.reportModel.reportKey !== 'OGPR'){
              this.generatebutton = 'enable';
              }
          });
        }
        else {
          this.asyncSuccess = 'Failed';
          this.errorMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.asyncSuccess = 'Failed';
        this.errorMessage = err;
        this.loaDer.nativeElement.style.display = 'none';
      });
  }

  getRouteNumber(){
    this.clearErrorMsg();
    var routeNumberFetch ={
      "activeStatus" : "Y"  
    };
  this._generateReportService.getRouteNumber(routeNumberFetch).subscribe(
    (data: any) => {
       console.log(JSON.stringify(data));
      var flags = [], output = [], l = data.length, i;
      for( i=0; i<l; i++) {
          if( flags[data[i].routeSequence]) continue;
          flags[data[i].routeSequence] = true;
          output.push(data[i]);
      }
      // console.log(output);
      this.routeDetails = output;
    },
    (err) => {
      this.responseMessage = err;
    }
  );
  }

  changeDate(value, key) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    let myDate = new Date(value);
    let dateOfYear1 = this._utilsService.transformDate1(myDate);
    if (key == 'fromDate') {
      this.searchParameterModel.gatePassStartDate = dateOfYear1;
    }
    else {
      this.searchParameterModel.gatePassEndDate = dateOfYear1;
    }
  }

  invoiceChangeDate(value,flag) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    this.searchParameterModel.periodEndNo = undefined;
    this.searchParameterModel.periodStartNo = undefined;
    this.searchParameterModel.fromDate = undefined;
    this.searchParameterModel.toDate = undefined;
    this.searchParameterModel.reportPeriodType = undefined;
    let myDate = new Date(value);
    let dateOfYear1 = this._utilsService.transformDate1(myDate);
    if(flag == 'fromDate'){
      this.searchParameterModel.fromInvoiceCreateDate = dateOfYear1;
    } else if(flag == 'invoiceDate'){
      let reqDate=this._utilsService.transformDateTo(myDate);
      this.searchParameterModel.invoiceDate = reqDate;
      this.searchParameterModel.invoicedStatus="INVOICED";
    }else{
      this.searchParameterModel.toInvoiceCreateDate = dateOfYear1;
    }
      
    }


    changeSelectedDate(todate, fromdate) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    // let deliveryToDate: any[] = todate.split('/');
    // let etDate = new Date(deliveryToDate[2], deliveryToDate[1], deliveryToDate[0]);
    this.requestParameterModel.filter(cs => cs.name == 'fromDate' || cs.name == 'toDate').forEach(
      each => {
        let deliveryFrmDate: any[] = each.value.split('/');
        let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1, deliveryFrmDate[0]);
        each.value = this._utilsService.transformDateSearchchange(stDate);
      }
    )
  }

  datecheck(fromDate, toDate) {
    this.clearErrorMsg();
    let status: boolean = false;
    console.log(fromDate);
    console.log(toDate);
    if (fromDate != undefined || toDate != undefined) {
      if (new Date(fromDate).getTime() > 0 && new Date(toDate).getTime() > 0) {
        console.log(fromDate);
        console.log(toDate);
        if ((new Date(fromDate).getTime() == new Date(toDate).getTime()) || (fromDate == toDate)) {
          console.log('equal');
          status = true;
        }
        else if ((new Date(fromDate).getTime() < new Date(toDate).getTime())) {
          console.log('greater');
          status = true;
        }
        else {
          console.log('smaller');
          status = false;
          this.errorMessage = "From Date should be smaller than To Date";
          this.loaDer.nativeElement.style.display = 'none';
        }
      }
      else {
        this.errorMessage = "Please select both dates";
        this.loaDer.nativeElement.style.display = 'none';
      }
    }
    else {
      status = true;
    }
    return status;
  }

  onClickReset() {
    this.clearErrorMsg();
    this.searchParameterModel = new ReportSearch();
    console.log('reset report search details');
    //this.searchParameterModel.concept = 'ALL';
    // this.searchParameterModel.stockTypeDesc = this.articleType[0].name;
    // this.searchParameterModel.stockTypeCode = this.articleType[0].value;
    this.diableSection();
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.regionEnable = true;
    this.enable = false;
    this.shopEnable = true;
    this.distEnable = true;
    this.searchParameterModel.region = "";
    this.showReportList.Reportname = false;
    this.showDiv1.Factory = false;
    this.showDiv1.Vendor = false;
    this.searchParameterModelOutward = new ReportSearch();
    this.searchParameterModelEstimate = new ReportSearch();
    if (this.reportModel.reportKey === 'OGPR') {
      this.searchParameterModel.copyType = 'Main';
      // this.searchParameterModel.conceptCode = 'ALL';
      // this.onSelectConcept(this.searchParameterModel.conceptCode);
    }
    if(this.reportModel.report != 'Purchase Order Printing'){
      this.reportModel.reportFormatType = 'Pdf';
    }
    else {
      this.reportModel.reportFormatType = 'excel';
    }

    if(this.reportModel.reportKey =='INVOICE_PRINT_BULK' ){
      this.reportModel.reportFormatType = 'Pdf';
    }
  }

  onBlurVendorCode(value) {
    this.clearErrorMsg();
    if (value != '') {
      this.regionEnable = true;
      //this.searchParameterModel.region = 'All';
    }
    else {
      this.regionEnable = false;
    }
    this._generateReportService.getVendorList(value).subscribe(
      (response: any) => {
        console.log(response.dataList);
        if (response.status == "Success") {
          this.searchParameterModel.vendorName = response.dataList[0].partyShortName;
        }
        else {
          this.searchParameterModel.vendorName = "";
        }
      });
  }

  setData() {
    console.log('removed');
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    this.onClickReset();
    this.diableSection();
    // this.searchParameterModel.stockTypeDesc = this.articleType[0].name;
    // this.searchParameterModel.stockTypeCode = this.articleType[0].value;
    //this.searchParameterModel.concept = 'ALL';
    if (this.reportModel.reportKey === 'OGPR') {
      this.searchParameterModel.copyType = 'Main';
      // this.searchParameterModel.conceptCode = 'ALL';
      // this.onSelectConcept(this.searchParameterModel.conceptCode);
    }
    //this.searchParameterModelOutward = new ReportSearch();
    if(this.reportModel.report == 'Purchase Order Printing'){
      this.reportModel.reportFormatType = 'Pdf';
    }
    else {
      this.reportModel.reportFormatType = 'excel';
    }
    if(this.reportModel.reportKey =='INVOICE_PRINT_BULK' ){
      this.reportModel.reportFormatType = 'Pdf';
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  regionChangeShopCode(regionVal) {
    this.clearErrorMsg();
    if (regionVal != '0: undefined') {
      this.shopEnable = true;
      this.distEnable = true;
    }
    else {
      this.shopEnable = false;
      this.distEnable = false;
    }
  }

  onBlurDispatchCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    this.searchParameterModel.dispatchName = undefined;
    this.generatebutton = 'enable';
    if (value != '' && value.length == 5) {
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.dispatchName = response.dataList[0].shopName;
            this.generatebutton = 'enable';
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.dispatchName = "";
            this.generatebutton = 'disable';
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }
  onBlurReceivingCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '') {
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.recievingFromName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.recievingFromName = "";
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }

  changeDistrictCode(distValue) {
    this.clearErrorMsg();
    if (distValue != '0: undefined') {
      this.regionEnable = true;
      this.shopEnable = true;
    }
    else {
      this.regionEnable = false;
      this.shopEnable = false;
    }
  }

  onBlurFromShopCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '') {
      this.regionEnable = true;
      this.distEnable = true;
      //this.searchParameterModel.region = 'All';
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.fromShopName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.fromShopName = "";
          }
        });
    }
    else {
      this.errorMessage = "";
      this.regionEnable = false;
      this.distEnable = false;
    }
  }

  destinationCodeinputValidation(value) {
    console.log(value);
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '' && this.searchParameterModel.destination == "F") {
      this._generateReportService.getFactoryName(value).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.destinationName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid factory Code";
          }
        });
    }
    else if (value != '' && this.searchParameterModel.destination == "V") {
      this._generateReportService.getVendorList(value).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "Success") {
            this.errorMessage = "";
            this.searchParameterModel.destinationName = response.dataList[0].partyShortName;
          }
          else {
            this.errorMessage = "Invalid vendor Code";
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }

  onBlurCode(value) {
    this.clearErrorMsg();
    if (value != '0: undefined') {
      this.regionEnable = true;
      this.distEnable = true;
    }
    else {
      this.regionEnable = false;
      this.distEnable = false;
    }
  }

  onBlurToShopCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    this.searchParameterModel.toShopName = undefined;
    this.generatebutton = 'enable';
    if (value != '' && value.length == 5) {
      this.errorMessage = "";
      // this.regionEnable = true;
      // this.distEnable = true;
      //this.searchParameterModel.region = 'All';
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.toShopName = response.dataList[0].shopName;
            this.generatebutton = 'enable';
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.toShopName = "";
            this.generatebutton = 'disable';
          }
        });
    }
    // else {
    //   this.errorMessage = "";
    //   this.regionEnable = false;
    //   this.distEnable = false;
    // }
  }

  onVendorCode(value) {
    //this.errorMessage = "";
    this.clearErrorMsg();
    this.searchParameterModel.vendorName = undefined;
    this.generatebutton = 'enable';
    if (value != '' && value.length == 3) {
      this.errorMessage = "";
      this._generateReportService.getVendorList(value).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "Success") {
            this.errorMessage = "";
            this.searchParameterModel.vendorName = response.dataList[0].partyShortName;
            this.generatebutton = 'enable';
          }
          else {
            this.errorMessage = "Invalid Vendor Code";
            this.searchParameterModel.vendorName = "";
            this.generatebutton = 'disable';
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }

  destinationchange(value) {
    console.log(value);
    this.clearErrorMsg();
    if (value == '') {
      this.destinationEnable = true;
    }
    else {
      this.destinationEnable = false;
      this.searchParameterModel.destination = value;
    }
  }
  //  selectedDurationtype(event:any) {
  selectedDurationtype(event: any): void {
    console.log(event);
    this.clearErrorMsg();
    this.selectedVal = event;
    this.searchParameterModel.invoiceCreateDate = undefined;
    this.searchParameterModel.periodEndNo = undefined;
    this.searchParameterModel.periodStartNo = undefined;
    console.log(this.searchParameterModel.reportPeriodType);
    // if (this.searchParameterModel.reportPeriodType != undefined) {
    // this.searchParameterModel = new ReportSearch();
    //}
    this.sucessMeg1 = '';
    this.responseMessage = '';
    if (this.selectedVal == 'DAILY') {
      this.showDiv.Daily = true;
      this.showDiv.Week = false; this.showDiv.Monthly = false; this.showDiv.Fortnightly = false;
      this.currentDate();
    }
    else if (this.selectedVal == 'WEEK') {
      this.disableCurrentDate();
      this.showDiv.Week = true;
      this.showDiv.Fortnightly = false; this.showDiv.Monthly = false; this.showDiv.Daily = false;
    }
    else if (this.selectedVal == 'FORTNIGHT') {
      this.disableCurrentDate();
      this.showDiv.Fortnightly = true;
      this.showDiv.Week = false; this.showDiv.Monthly = false; this.showDiv.Daily = false;
    }
    else if (this.selectedVal == 'MONTH') {
      this.disableCurrentDate();
      this.showDiv.Monthly = true;
      this.showDiv.Fortnightly = false; this.showDiv.Week = false; this.showDiv.Daily = false;
    }
    else {
      this.showDiv.Fortnightly = false; this.showDiv.Week = false; this.showDiv.Daily = false; this.showDiv.Monthly = false;
    }
  }

  optionSelect(event: any): void {
    this.clearErrorMsg();
    this.selectedVal = event;
    // if (this.searchParameterModel.reportPeriodType != undefined) {
    //   this.searchParameterModel = new ReportSearch();
    // }
    this.sucessMeg1 = '';
    this.responseMessage = '';
    if (this.selectedVal == 'X' || this.selectedVal == 'F') {
      this.showDiv1.Factory = true;
      this.showDiv1.Vendor = false;
    }
    else if (this.selectedVal == 'V') {
      this.showDiv1.Vendor = true;
      this.showDiv1.Factory = false;
    }
    else {
      this.showDiv1.Factory = false; this.showDiv1.Vendor = false;
    }
      this.searchParameterModel.dispatchCode = undefined;
      this.searchParameterModel.toShopName = undefined;
      this.searchParameterModel.vendorName = undefined;
      this.searchParameterModel.dispatchName = undefined;
      this.searchParameterModel.destinationCode = undefined;
  }

  convertBataWeek(weekValue) {
    this.clearErrorMsg();
    let bataNumber = weekValue.substring(4, 6);
    let bataYear = weekValue.substring(0, 4);
    this.payloadBataWeek = {
      "bataYear": bataYear,
      "bataNumber": bataNumber,
      "searchFlag": "WEEK",
      "fromToFlag": "TO"
    }
    this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.message == "Success") {
          const toDateConvert = this._utilsService.transformDateTo(response.date);
          this.searchParameterModel.weekEndDate = toDateConvert;
        }
      });
    let year = new Date().getFullYear();
    this.payloadBataWeek = {
      // "bataYear": year,
      "bataYear": "2020",
      "bataNumber": "01",
      "searchFlag": "WEEK",
      "fromToFlag": "FROM"
    }
    this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.message == "Success") {
          const toDateConvert = this._utilsService.transformDateTo(response.date);
          this.searchParameterModel.weekStartDate = toDateConvert;
        }
      });
  }

  onBlurBataWeek(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    let validationMsg:any = undefined;
    this.searchParameterModel.fromDate = "";
    this.errorMessage = '';
    if (bataWeekVal.length == 6 && (validationMsg = this._utilsService.monthFortnightValidations(bataWeekVal,searchFlag,fromToFlag)) == 'success') {
      let bataNumber = bataWeekVal.substring(4, 6);
      let bataYear = bataWeekVal.substring(0, 4);
      console.log(bataNumber);
      console.log(bataYear);
      this.payloadBataWeek = {
        "bataYear": bataYear,
        "bataNumber": bataNumber,
        "searchFlag": searchFlag,
        "fromToFlag": fromToFlag
      }
      this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
        (response: any) => {
          if (response.message == "Success") {
            const fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
            this.searchParameterModel.fromDate = fromDateDaily1;
          }
        });
    } else{
        this.errorMessage = validationMsg;
      }
  }

  onSelectPerfix(event) {
    // if(event != undefined){
    //  this.showReportList.Reportname = true;
    // }
    console.log(event);
    this.clearErrorMsg();
    this.selectedList = [];
    this.onlyPerfix.filter(cs => cs.perfix === event).forEach(
      reportkN => {
        this.selectedList.push(reportkN);

      }
    )
    console.log(this.selectedList);

  }

  onBlurBataWeekTo(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    let validationMsg:any = undefined;
    this.searchParameterModel.toDate = "";
    this.errorMessage = '';
    if (bataWeekVal.length == 6 && (validationMsg = this._utilsService.monthFortnightValidations(bataWeekVal,searchFlag,fromToFlag)) == 'success') {
      let bataNumber = bataWeekVal.substring(4, 6);
      let bataYear = bataWeekVal.substring(0, 4);
      console.log(bataNumber);
      this.payloadBataWeek = {
        "bataYear": bataYear,
        "bataNumber": bataNumber,
        "searchFlag": searchFlag,
        "fromToFlag": fromToFlag
      }
      this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
        (response: any) => {
          if (response.message == "Success") {
            let fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
            this.searchParameterModel.toDate = fromDateDaily1;
          }
        });
    }
    else{
        this.errorMessage = validationMsg;
    }
  }

  validDestination(validDes) {
  }

  currentDate() {
    this.clearErrorMsg();
    this.date = new Date();
    this.searchParameterModel.toDate = this._utilsService.transformDate1(this.date);
    this.searchParameterModel.fromDate = this._utilsService.transformDate1(this.date);
    console.log(this.toDate);
  }

  diableSection(){
    this.clearErrorMsg();
    this.showDiv.Week = false;
    this.showDiv.Monthly = false;
    this.showDiv.Fortnightly = false;
    this.showDiv.Daily = false;
    this.responseMessage = '';
    this.sucessMeg1 = '';
    this.errorMessage = "";
  }

  disableCurrentDate(){
    this.clearErrorMsg();
    this.searchParameterModel.fromDate = "";
      this.searchParameterModel.toDate = "";
      this.searchParameterModel.periodEndNo = "";
      this.searchParameterModel.periodStartNo = "";
  }

}
