import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormArray, FormGroup, Validators } from "@angular/forms";
//import { UtilsService } from "../../common/utils-service";
import { RequestParameterModel } from '../../common/request-parameter-model';
import { SearchInventoryBifurcationService } from "./search-inventory-bifurcation.service";

@Component({
  selector: 'app-search-inventory-bifurcation',
  templateUrl: './search-inventory-bifurcation.component.html',
  styleUrls: [
    "../../../assets/css/bootstrap.css",
    "../../../assets/css/rds-bil-style.css",
    "../../../../node_modules/font-awesome/css/font-awesome.css",
    './search-inventory-bifurcation.component.css']
})
export class SearchInventoryBifurcationComponent implements OnInit {

  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  concepts: any[];
  division: string;
  userCode: string;
  ShopCodes: any[] = [];
  yearWk: string;
  responseMessage: string;
  ShowSearchDisable: boolean = false;
  inventoryBifurcationForm: FormGroup;
  assortData: any = {};
  groupCode: string;
  artNumber: string;
  inventoryBifurcationList: any[];
  groupedArray: any[];
  requestParameterModel: RequestParameterModel[];

  constructor(
    private fb: FormBuilder,
    private SearchInventoryBifurcationService: SearchInventoryBifurcationService
    //private _utilsService: UtilsService
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    //this.userCode = "user.test4";
    this.buildForm();
  }

  buildForm(): void {
    this.inventoryBifurcationForm = this.fb.group({
      conceptCode: [null],
      parentShopNumber: [null],
      ratioYrWk: [null],
      userCode: [this.userCode]
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = "none";
      let conceptQueryMas = [
        {
          name: "email",
          value: this.userCode,
        },
      ];
      this.SearchInventoryBifurcationService.getConcepts(conceptQueryMas).subscribe(
        (data: any) => {
          this.concepts = data.dataList.filter(function (list) {
            return (list.division == "RETAIL" && (list.concCode == 'F' || list.concCode == 'M' || list.concCode == 'L'));
          });
        },
        (err) => {
          this.responseMessage = err;
        }
      );
      this.showShopCode();
    }, 500);
  }

  showShopCode() {
    // let details = [
    //   { name: "shopCloseYrWk1", value: "0" },
    //   { name: "shopAgencyCode", value: "5" },
    //   { name: "userCode", value: this.userCode },
    //   { name: "conceptCode", value: conceptCode },
    // ];
    this.SearchInventoryBifurcationService.getShopCode().subscribe(
      (data: any) => {
        this.ShopCodes = data.parentRDCModels;
      },
      (err) => {
        this.responseMessage = err;
      }
    );
  }

  onReset() {
    this.inventoryBifurcationForm.reset();
    setTimeout(() => {
      this.buildForm();
    }, 500);
    this.inventoryBifurcationList = [];
    this.responseMessage = "";
  }

  searchInventoryBifurcation() {

    function compare( a, b ) {
      if ( a.conceptCode < b.conceptCode ){
        return -1;
      }
      if ( a.conceptCode > b.conceptCode ){
        return 1;
      }
      return 0;
     }
    function groupBy(objectArray, property1,property2) {
      return objectArray.reduce((acc, obj) => {
         const key1 = obj[property1];
         const key2 = obj[property2];
         const key = key1+'-'+key2;
         if (!acc[key]) {
            acc[key] = [];
         }
         acc[key].push(obj);                  
         acc[key].sort( compare );
        //  const found1 = acc[key].some(el => el.conceptCode == 'F');
        //  const found2 = acc[key].some(el => el.conceptCode == 'L');
        //  if (!found1) acc[key].push({});
        //  if (!found2) acc[key].push({});
         return acc;
      }, {});
    }
    if (this.inventoryBifurcationForm.controls["conceptCode"].value == null && this.inventoryBifurcationForm.controls["parentShopNumber"].value == null && this.inventoryBifurcationForm.controls["ratioYrWk"].value == null ) {
      this.responseMessage = "Please select any search criteria.";
      return false;
    } else {
      this.responseMessage = "";
      this.inventoryBifurcationList = [];
      this.loaDer.nativeElement.style.display = "block";
      let requsetParameters = new Array<RequestParameterModel>();
      let jsonRequisition = JSON.stringify(this.inventoryBifurcationForm.value);
      console.log(jsonRequisition);
      let jsonObject = JSON.parse(jsonRequisition);
      for (var i in jsonObject) {
        let requestParameterModel = new RequestParameterModel();
        if (jsonObject[i] !== '' && jsonObject[i] !== null && jsonObject[i] !== 'undefined') {
          requestParameterModel.name = i;
          requestParameterModel.value = jsonObject[i];
          requsetParameters.push(requestParameterModel);
        }
      } 
      this.SearchInventoryBifurcationService
        .getinventoryBifurcationDetails(requsetParameters)
        .subscribe(
          (response: any) => {
            this.loaDer.nativeElement.style.display = "none";
            if (response.status == "success") {
              this.inventoryBifurcationList = response.dataList;
              console.log(this.inventoryBifurcationList);   
              if(this.inventoryBifurcationList){
                var groupedArray = groupBy(this.inventoryBifurcationList, 'ratioYrWk','parentShopNumber');
                var x = Object.entries(groupedArray);
                this.groupedArray = x;
                console.log(this.groupedArray);
               }           
            } else {
              this.responseMessage = "No Data Found";
            }
          },
          (err) => {
            this.responseMessage = "No Data Found";
            this.loaDer.nativeElement.style.display = "none";
          },
          () => {
            this.loaDer.nativeElement.style.display = "none";
          }
        );
    }
  }

}
