import { Component, OnInit, Output, EventEmitter, ɵConsole, ElementRef, ViewChild  } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SearchAsnService } from './search-asn.service';
import { AsnHeader } from './asn-header';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { SearchASNResultModel } from './search-asn-result-model';
import { ListOfBataWeek } from '../asn-search/list-of-bata-week';
import { UtilsService } from '../../common/utils-service';
// import { importsavevar } from '..common/importsavevar';
import { Router, RouterModule } from '@angular/router';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { asnTransportData } from './asn-transpoter-data';
import { from } from 'rxjs';
import { AsnLines } from './asn-lines';
import { NgForm } from '@angular/forms';
import { updateTransporterModel } from './update-transporter-model';
import { async } from 'q';


@Component({
  selector: 'app-search',
  templateUrl: './search-asn.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css',
  '../../../../node_modules/font-awesome/css/font-awesome.css', './search-asn.component.css'],
})


export class SearchAsnListComponent implements OnInit {
  @ViewChild('loaDer', { static: true }) loaDer: ElementRef;
  @ViewChild('transForm', {static: true}) formValues;
  @ViewChild('myForm', { static: false }) myInputVariable: NgForm;

  searchASNCriteriaList = new AsnHeader();
  updateTransList = new updateTransporterModel();
  searchData = new RequestParameterModel();
  profileForm: FormGroup;
  errorMessage: string;
  status: string;
  message: string;
  filterASNData: AsnHeader[] = [];
  // tslint:disable-next-line: variable-name
  _SearchResultModel = new AsnHeader();
  errormsg: string;
  isValidDate: boolean;
  dateValue: any[];
  responseMessage: string;
  responsemessage: string;
  frombataWeekzero: string;
  frombataWeekfinal: any;
  statusMessage = '';
  searchText = '';
  enable = false;
  public noResults = false;
  uploadFileName = 'No Choose File';
  bataList: ListOfBataWeek[] = [];
  tobataWeekzero: string;
  tobataWeekfinal: string;
  articleNoOnly: AsnHeader[] = [];
  assortmentDataList: any[] = [];
  requestParameterModel: RequestParameterModel[];
  currentPage:Number=1;
  transpoterList: asnTransportData[] = [];
  names: any;
  SatusList = [];
  uniques: AsnHeader[] = [];
  onCheckTransEnable: boolean;
  arr: any[];
  count: any;
  lrgrDateCorrectFormat: Date;
  newObj: any;
  result: any;
  conceptQueryMas: string;
  statuses: any;
  show: boolean = false;
  stocks;

  // uniqueKeyArray:any[];
  // totalCartonQty:any[];

  searchType='header';
  pageNumber=1;
  itemsPerPage=10;
  totalItems:any;

  private error: any = { isError: false, errorMessage: '' };
  @Output() change = new EventEmitter();
  @Output() groupFilters: EventEmitter<any> = new EventEmitter<any>();


  constructor(private fb: FormBuilder,
              private searchAsnService: SearchAsnService,
              private utilsService: UtilsService, private router: Router,
              private navigationLinkParameterService: NavigationLinkParameterService) {
                this.uploadFileName;
                this.profileForm = this.fb.group({
                  name: [''],
                  file: [''],
                  uploadKey: ['UDESP']
                });
    }


  ngOnInit(): void {

   this.loaDer.nativeElement.style.display = 'block';
  
  this.searchAsnService.getAsnStatusList().subscribe(
        (data: any) => {
          this.statuses = data.configParamModels;
        }
      );

    this.searchAsnService.getInventoryStatus().subscribe(
      (data: any) => {
        this.stocks = data.configParamModels;
      }
    );

   const sessUsername = sessionStorage.getItem('currentUser');
   this.searchASNCriteriaList.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
        // //console.log(this.navigationLinkParameterService);
   if (this.navigationLinkParameterService.asndetails != '' && this.navigationLinkParameterService.asndetails != undefined) {
      this.searchASNCriteriaList = this.navigationLinkParameterService.asndetails;
      ////console.log(this.searchASNCriteriaList);
      //console.log("asnDetails");
      this.searchAsnDataWithValues(this.searchASNCriteriaList);
    }

   this.navigationLinkParameterService.asndetails = '';

   if (this.navigationLinkParameterService.searchasnHeaderData === undefined) {
    ////console.log('New Search has to be made');
    this.loaDer.nativeElement.style.display = 'none';
    } else {
      ////console.log('Automatic Search will be performed');
      this.searchASNCriteriaList = this.navigationLinkParameterService.searchasnHeaderData.searchData;
      this.uniques = this.navigationLinkParameterService.searchasnHeaderData.resultData;
      this.searchASNCriteriaList.pageNumber=this.navigationLinkParameterService.asnPageNumber;
      this.currentPage=this.navigationLinkParameterService.asnPageNumber;
      this.navigationLinkParameterService.asnPageNumber=0;
       this.navigationLinkParameterService.searchasnHeaderData = undefined;
      // //console.log(this.searchASNCriteriaList);
      //console.log(this.currentPage);
      // //console.log("searchasnHeaderData");
      
      this.searchAsnDataWithValues(this.searchASNCriteriaList);
     
    }

  }

  searchPayload() {
    const searchNavigationHold = {
      searchData: this.searchASNCriteriaList,
      resultData: this.uniques
    };
    return searchNavigationHold;
  }
  newSearchAsnDataWithValues(formval:any){
    this.searchASNCriteriaList.pageNumber=1;
    this.currentPage=1;
    //console.log("new SEarch");
    this.searchAsnDataWithValues(this.searchASNCriteriaList);

  }

  searchAsnDataWithValues(filters: any) {
    ////console.log('calling------');
   ////console.log(this.searchASNCriteriaList);
    
    if (this.searchASNCriteriaList.supplyNo == null && this.searchASNCriteriaList.asnNumber == null && this.searchASNCriteriaList.asnStatus == null  && this.searchASNCriteriaList.fromchallanInvoiceDT == null && this.searchASNCriteriaList.tochallanInvoiceDT == null && this.searchASNCriteriaList.consignorCode == null && this.searchASNCriteriaList.bataWeekFrom == null && this.searchASNCriteriaList.partyNumber == null && this.searchASNCriteriaList.bataWeekTo == null && this.searchASNCriteriaList.yrWeekFrom == null && this.searchASNCriteriaList.yrWeekTo == null && this.searchASNCriteriaList.inventoryStatus == null) {
      this.errormsg = "Please select any search criteria.";
    } else {
      this.errormsg = "";
      this.loaDer.nativeElement.style.display = "block";
      // Object.keys(filters).forEach(key => filters[key] === '' ? delete filters[key] : key);
      this.groupFilters.emit(filters);
      this.isValidDate = true;
      ////console.log(this.searchASNCriteriaList);
      let fromDate: Date = this.searchASNCriteriaList.fromchallanInvoiceDT;
      let toDate: Date = this.searchASNCriteriaList.tochallanInvoiceDT;
      let invoiceNO: string = this.searchASNCriteriaList.supplyNo;
      let asnNumber: string = this.searchASNCriteriaList.asnNumber;
      let stats: string = this.searchASNCriteriaList.asnStatus;
      let concept: string = this.searchASNCriteriaList.consignorCode;
      let fromWeekbata: string  = this.searchASNCriteriaList.bataWeekFrom;
     let toWeekbata: string = this.searchASNCriteriaList.bataWeekTo;
     let partyNumber: string = this.searchASNCriteriaList.partyNumber;
     let fromYearWeeksupply: string  = this.searchASNCriteriaList.yrWeekFrom;
     let toYearWeeksupply: string = this.searchASNCriteriaList.yrWeekTo;
     let inventoryStatus: string = this.searchASNCriteriaList.inventoryStatus;
    //  let cartonQty=0;
    //  this.uniqueKeyArray=[];
    //  this.totalCartonQty=[];
     this.searchASNCriteriaList.searchType="header";
     this.searchASNCriteriaList.pageSize=this.itemsPerPage;
     this.searchASNCriteriaList.pageNumber=this.currentPage; 
     //console.log(this.currentPage);
     //console.log(this.searchASNCriteriaList.pageNumber);
     //console.log(this.searchASNCriteriaList);
     

     
      if ( (this.searchASNCriteriaList.supplyNo != null) || (this.searchASNCriteriaList.partyNumber !=null) || (this.searchASNCriteriaList.asnNumber != null) || (this.searchASNCriteriaList.asnStatus != null) || (this.searchASNCriteriaList.inventoryStatus != null)  || (this.searchASNCriteriaList.consignorCode != null) || (this.searchASNCriteriaList.fromchallanInvoiceDT != null && this.searchASNCriteriaList.tochallanInvoiceDT != null) || (this.searchASNCriteriaList.bataWeekFrom != null && this.searchASNCriteriaList.bataWeekTo != null) || (this.searchASNCriteriaList.yrWeekFrom != null && this.searchASNCriteriaList.yrWeekTo != null))
      {
  
      
        if(this.searchASNCriteriaList.bataWeekFrom > this.searchASNCriteriaList.bataWeekTo){
          this.responseMessage = '';
          this.errormsg='BataWeekFrom should be less than BataWeekTo';
          this.loaDer.nativeElement.style.display = 'none';
          return false;
        }
        if(this.searchASNCriteriaList.yrWeekFrom > this.searchASNCriteriaList.yrWeekTo){
          this.responseMessage = '';
          this.errormsg='Supply Year Week From should be less than Supply Year Week To';
          this.loaDer.nativeElement.style.display = 'none';
          return false;
        }
        
        this.searchAsnService.getAsnbyAsnSearchCriteria(this.searchASNCriteriaList).subscribe(
          (response: any) => {
            //console.log(response);
            if (response.status === 'Success') {
              this.show = true;
              this.filterASNData = response.dataList;
              //console.log(this.searchASNCriteriaList);
              this.currentPage=this.searchASNCriteriaList.pageNumber;
              //console.log(this.currentPage);
              this.totalItems=response.recordCount;
              // this.filterASNData.forEach(
              //   asnData => {
              //     let key=asnData.asnNumber;
              //     cartonQty=((asnData.cartonNumber!=null)?parseInt(asnData.cartonNumber):0);
              //     var indexofKey=this.uniqueKeyArray.indexOf(key);
              //     if (indexofKey === -1) {
              //       this.uniqueKeyArray.push(key);
              //       this.totalCartonQty.push(cartonQty);                   
              //   }else{
              //     if(asnData.consignorType === 'SUPPLIER' || asnData.consignorType === 'FACTORY'){
              //       this.totalCartonQty[indexofKey]=this.totalCartonQty[indexofKey]+cartonQty;
              //     }
                  
              //   }
                  
              //   }
              // );

                this.uniques = this.filterASNData.filter(function(a) {
                  // const key = a.supplyNo + '|' + a.consignorCode;
                  const key=a.asnNumber;
                  if (!this[key]) {
                      this[key] = true;
                      return true;
                  }
              }, Object.create(null));
              ////console.log(this.uniques);
              // this.searchGRNObj = response.dataList[0];
            } else {
              ////console.log('else');
              this.responseMessage = '';
              this.errormsg = 'No Data Found';
              this.show = false;
              // this.searchGRNDataList = [];
              this.uniques = [];
            }
            this.loaDer.nativeElement.style.display = 'none';
          
          });
      }
      else{
        this.responseMessage = '';
        if((this.searchASNCriteriaList.bataWeekFrom != null && this.searchASNCriteriaList.bataWeekTo == null) || (this.searchASNCriteriaList.bataWeekFrom == null && this.searchASNCriteriaList.bataWeekTo != null)){
          this.errormsg = 'Please fill BataWeekFrom & BataWeekTo';
        }
        if((this.searchASNCriteriaList.yrWeekFrom == null && this.searchASNCriteriaList.yrWeekTo != null) || (this.searchASNCriteriaList.yrWeekFrom != null && this.searchASNCriteriaList.yrWeekTo == null)){
          this.errormsg = 'Please fill supply year week from & supply year week to';
        }
        
        let fromDateStr: string;
        let toDateStr: string;
          // Validate ToPRDate and FromPRDate
          if (this.searchASNCriteriaList.fromchallanInvoiceDT != null || this.searchASNCriteriaList.tochallanInvoiceDT != null) {
            fromDateStr = this.utilsService.transformDateSearch(fromDate);
            toDateStr = this.utilsService.transformDateSearch(toDate);
            this.isValidDate = this.utilsService.validateDatesSearch(
              fromDateStr,
              toDateStr
            );
            this.errormsg = 'Please fill Invoice From & Invoice To Date';
          }
        this.loaDer.nativeElement.style.display = 'none';
      }
  
    }
}



resetSearchASNResults() {
      this.loaDer.nativeElement.style.display = 'none';
      this.uniques = [];
      this.filterASNData = [];
      this.noResults = false;
      this.searchASNCriteriaList.pageNumber=1;
      this.error = { isError: false, errorMessage: '' };
      this.isValidDate = true;
      this.responseMessage = '';
      this.responsemessage = '';
      this.errormsg = '';
      this.errorMessage = '';
    }



  // async setAsnByAsnSearchCriteria(ASNModel: AsnHeader) {
  //   // this.loaDer.nativeElement.style.display = 'block';
  //   this.responseMessage = '';
  //   this.uniques = [];
  //     this.filterASNData = [];
  
  //   this.searchAsnService.getAsnbyAsnSearchCriteria(this.searchASNCriteriaList).subscribe(
  //           (res: SearchASNResultModel) => {
  //             ////console.log('setAsnByAsnSearchCriteria');
  //             ////console.log(this.searchASNCriteriaList);
  //             if (res != null) {
  //                 this.filterASNData = res.dataList;
  //                 this.status = res.status;
  //                 this.message = res.message;
  //                 //console.log(this.filterASNData);
  //                 if ( this.filterASNData != null) {
  //                   this.totalItems=res.recordCount;
  //                  this.uniques = this.filterASNData.filter(function(a) {
  //                         // const key = a.supplyNo + '|' + a.consignorCode;
  //                         const key=a.asnNumber;
  //                         if (!this[key]) {
  //                             this[key] = true;
  //                             return true;
  //                         }
  //                     }, Object.create(null));
  //                } else {
  //                 this.uniques = null;
  //               }
  //             }
  //             this.loaDer.nativeElement.style.display = 'none';
  //           }

  //         );
 
  // }



onClickBack() {

  this.router.navigate(['']);
}

getPage(page){
  //console.log("getPage");
  //console.log(page);
     this.searchASNCriteriaList.pageNumber=page;
     this.currentPage=page;
    //  this.setAsnByAsnSearchCriteria(this.searchASNCriteriaList);
    //console.log(this.searchASNCriteriaList);
     this.searchAsnDataWithValues(this.searchASNCriteriaList);

}

viewASNDetails(asnID) {
  let selectInd: any;
  ////console.log(this.filterASNData);
  ////console.log(asnID);
  this.filterASNData.filter(cs => cs.asnNumber === asnID).forEach(
    obj => {
      selectInd = obj;
    }
  );
  ////console.log(selectInd);
  // var indexofKey=this.uniqueKeyArray.indexOf(selectInd.asnNumber);
  // var totalCarton =  this.totalCartonQty[indexofKey];
  this.navigationLinkParameterService.challanAsnDetails = selectInd;
  // this.navigationLinkParameterService.totalCarton = totalCarton;
  this.navigationLinkParameterService.searchasnHeaderData = this.searchPayload();
  this.navigationLinkParameterService.asnPageNumber=this.currentPage;
  this.router.navigate(['/rds-asn-details']);

  }


  setDataForUpdating(index) {
    ////console.log('set data for row no. :' + index);
    this.arr = [];
    for (let i = 0; i < this.assortmentDataList.length; i++) {
      this.arr[i] = this.assortmentDataList[i].fulfilledQuantity;
    }
    this.count = this.count + 1;
    if (index > -1) {
      this.onCheckTransEnable = true;
    } else {
      this.onCheckTransEnable = false;
    }
  }

  omit_special_char(event) {
   let k;
   k = event.charCode;
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}

}
