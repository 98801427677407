import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreatePackingMasterService } from './create-packing-master.service';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { ActivatedRoute, Router } from '@angular/router';
import { EditPackMasterDetails } from '../search-packing-master-edit/edit-pack-master';
import { SearchPackMasterDetails } from '../search-packing-master/search-pack-master-details';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
import { importArticle } from '../import-article-price/import_article';
import { SizePackingResponseModel } from '../common/size-pack-master';
import { AssortmentDetails } from '../search-packing-master/assortment-details';
import { DatePipe } from '@angular/common';
import { Batches } from '../batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import { importsave } from '../import-article-price/import-article-save';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Concept } from '../common/concept';
@Component({
  selector: 'app-create-packing-master',
  templateUrl: './create-packing-master.component.html',
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css', './create-packing-master.component.css']
})
export class CreatePackingMasterComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('cancel', { static: false }) cancel: ElementRef;
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  @ViewChild('myInput1', { static: false }) myInputVariable1: ElementRef;
  profileForm: FormGroup;
  profileForm1: FormGroup;
  error: string;
  responseMessage: string;
  createPackMasterDetailModel = new SearchPackMasterDetails();
  createPackMasterDetailsList: SearchPackMasterDetails[] = [];
  requestParameterModel: RequestParameterModel[];
  availableSizes = new Array();
  availableSizesList:any[] = [];
  count: number = 0;
  newAssortment = new AssortmentDetails();
  searchPackingAssortmentDetails: AssortmentDetails[] = [];
  errorMessage: string;
  currentIndex: number;
  fileUpload = { status: '', message: '', filePath: '' };
  uploadFileName: string = 'No Choose File';
  arr = ['concept', 'iCode', 'catCode', 'shopCode', 'packCode', 'groupCode', 'heartSize', 'oldrdc', 'proctag'];
  assortmentType = [{ name: "Assorted", value: "A" }, { name: "Wholesale Assorted", value: "W" }];
  groupCodes = [{ id: "1", value: "A" },{ id: "2", value: "B" },{ id: "3", value: "C" },
  { id: "4", value: "D" },{ id: "5", value: "E" },]
  createdUser: string;
  myDate = new Date();
  currentDate: string;
  isAssortmentFilled: boolean = false;
  ifAssortmentFilled: boolean[] = [];
  mt1: boolean[] = [];
  mt2: boolean[] = [];
  mt3: boolean[] = [];
  mt4: boolean[] = [];
  mt5: boolean[] = [];
  mt6: boolean[] = [];
  mt7: boolean[] = [];
  downloadUrlList: Batches[] = [];
  downloadUrl: string;
  downloadedFile: string;
  checkService: boolean = false;
  enable: boolean = false;
  enabled: boolean = false;
  statusMessage: string = '';
  errorMessage1: string;
  concepts: Concept[];
  categoryCodes:any[] = [];
  ShopCodes:any[] = [];
  userName:string;
  disableRetail:boolean = false;
  disableNonRetail:boolean = false;
  retCount:number = 0;
  NonretCount:number = 0;
  constructor(private _router: Router, private httpClient: HttpClient,
    private fb: FormBuilder, private _createPackingMasterSerivce: CreatePackingMasterService,
    private _utilsService: UtilsService, private datePipe: DatePipe) {
    this.currentDate = this.datePipe.transform(this.myDate, 'dd/MM/yyyy');
    this.profileForm = this.fb.group({
      name: [''],
      file: [''],
      uploadKey: ['PACK_ASSORTED'],
      uploadKey1: ['PACK_WSL_ASSORTED'],
      uploadedBy:['']
    });
  }

  ngOnInit() {

    setTimeout(()=>{
      this.loaDer.nativeElement.style.display = 'none';    
    },100);

    this.createPackMasterDetailModel = new SearchPackMasterDetails();
    this.createPackMasterDetailsList.push(this.createPackMasterDetailModel);
    this.ifAssortmentFilled[0] = false;
    console.log(JSON.stringify(this.createPackMasterDetailsList[0].packingMasterAssortmentsDTO.length));
    this.mt1[0] = true;
    this.mt2[0] = true;
    this.mt3[0] = true;
    this.mt4[0] = true;
    this.mt5[0] = true;
    this.mt6[0] = true;
    this.mt7[0] = true;
    let sessUsername = sessionStorage.getItem('currentUser');
    
    this.createdUser = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    var conceptQueryMas = [{"name":"email","value":JSON.parse(sessUsername).groupAssignmentModels[0].userCode}];
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.profileForm.get('uploadedBy').setValue(this.userName);
    this.retCount = 0;
    this.NonretCount = 0;
    this._createPackingMasterSerivce.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList; 
        const distinctConcept = this.concepts.filter(
          (thing, i, arr) => arr.findIndex(t => t.concCode === thing.concCode) === i
        ); 
        this.concepts = [];
        this.concepts = distinctConcept;
        console.log(this.concepts);
        this.concepts.forEach(
          con => {
            if(con.division == 'RETAIL'){
              this.retCount = this.retCount + 1;
            }
            else{
              this.NonretCount = this.NonretCount + 1;
            }
          }
        )
      }
    )

    console.log(this.currentDate);
    
    this._createPackingMasterSerivce.getCategoryCode().subscribe(
      (res:any) => {
        res.dataList.forEach(
          catCode => {
            this.categoryCodes.push(catCode.categoryCode);
            // this.sortedcategoryCodes = this.categoryCodes.sort((a, b) => {
            //   return a - b;
            // });
          } 
        );
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  addPackingMaster() {
    this.responseMessage = '';
    let currentPackingIndex = this.createPackMasterDetailsList.length - 1;
    if (this.createPackMasterDetailsList[currentPackingIndex].packingMasterAssortmentsDTO.length != 0) {
      this.isAssortmentFilled = true;
      this.mt1[currentPackingIndex + 1] = true;
      this.mt2[currentPackingIndex + 1] = true;
      this.mt3[currentPackingIndex + 1] = true;
      this.mt4[currentPackingIndex + 1] = true;
      this.mt5[currentPackingIndex + 1] = true;
      this.mt6[currentPackingIndex + 1] = true;
      this.mt7[currentPackingIndex + 1] = true;
      console.log('add new row');
      console.log(this.createPackMasterDetailsList[currentPackingIndex]);
      let createPackMasterDetail = new SearchPackMasterDetails();
      this.createPackMasterDetailsList.push(createPackMasterDetail);
      this.ifAssortmentFilled[currentPackingIndex + 1] = false;
    }
  }

  packLineDetails(index) {
    this.responseMessage = '';
    this.errorMessage1 = '';
    this.errorMessage = '';
    this.searchPackingAssortmentDetails = [];
    this.count = 0;
    this.currentIndex = index;
    this.availableSizes = [];
    if(this.ifAssortmentFilled[index] == false){
    let details = {
      "name":"groupCode",
      "value":this.createPackMasterDetailsList[index].groupCode
       }
    this._createPackingMasterSerivce.getSizeDetails(details).subscribe(
      (result: any) => {
        if (result.status === "Success") {
          result.dataList.forEach(
            packDetails => {
              for (var j = this.count; j < this.count + 1; j++) {
                this.availableSizes[j] = packDetails.sizeBucket;
                let newAssortments = new AssortmentDetails();
                newAssortments.articleSize = packDetails.sizeBucket;
                newAssortments.sizeLabel = packDetails.artSize;
                newAssortments.pairQty = '';
                this.searchPackingAssortmentDetails.push(newAssortments);
              }
              this.count++;
              console.log('available sizes' + this.availableSizes);
            }
          );
        }
        else {
          this.errorMessage1 = 'Please Enter Valid Group Code';
        }
      }
    );
    this.availableSizesList.push(this.availableSizes);
    }
    else{
      this.availableSizes = this.availableSizesList[index];
      this.createPackMasterDetailsList[index].packingMasterAssortmentsDTO.forEach(
        each => {
          for (var j = this.count; j < this.count + 1; j++) {
            let newAssortments = new AssortmentDetails();
            let checkFlag:number = 0;
            this.availableSizes.forEach(
              size => {
                if(each.articleSize == size){
                  checkFlag = checkFlag + 1;
                }
              }
            );
            if(checkFlag == 1){
              newAssortments.articleSize = each.articleSize;
              newAssortments.sizeLabel = each.sizeLabel;
              newAssortments.pairQty = each.pairQty;
              this.searchPackingAssortmentDetails.push(newAssortments);
            }
            else{
              this.searchPackingAssortmentDetails.push(newAssortments);
            }
                
          }
          this.count++;
        }
      );
    }
  }

  setData(e, i, name) {
    let value = (Object)(e.target.value);
    this.responseMessage = '';
    if (name == 'concept') {
      this.createPackMasterDetailsList[i].concept = value;
      this.mt1[i] = false;
      let divisionCode:string;
      this.concepts.filter(cs => cs.concCode == this.createPackMasterDetailsList[i].concept).forEach(
        div => {
          if(div.division == 'RETAIL'){
            divisionCode = '5';
          }
          else{
            divisionCode = '4';
          }
        }
      )
      let detail = {
        "shopCloseYrWk1":"000000",
        "shopAgencyCode":divisionCode,
        "userEmail":JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode,
        "conceptCode":this.createPackMasterDetailsList[i].concept
      }
      let requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
      this._createPackingMasterSerivce.getShopCodeByUser(requestParameterModel).subscribe(
        (res:any) => {
          this.ShopCodes[i] = [];
          res.dataList.forEach(
            shopCode => {
              this.ShopCodes[i].push(shopCode.shopNo);
              // this.sortedShopCodes = this.ShopCodes.sort((a, b) => {
              //   return a - b;
              // });
            } 
          );
          
        }
      );
      console.log(this.ShopCodes);
      if (this.createPackMasterDetailsList[i].concept == "" || this.createPackMasterDetailsList[i].concept == 'n') {
        this.mt1[i] = true;
      }
    }
    // if (name == 'iCode') {
    //   this.createPackMasterDetailsList[i].iCode = value;
    //   this.mt2[i] = false;
    //   if (this.createPackMasterDetailsList[i].iCode == "") {
    //     this.mt2[i] = true;
    //   }
    // }
    if (name == 'catCode') {
      this.createPackMasterDetailsList[i].catCode = value;
      this.mt3[i] = false;
      if (this.createPackMasterDetailsList[i].catCode == "" || this.createPackMasterDetailsList[i].catCode == 'n') {
        this.mt3[i] = true;
      }
    }
    if (name == 'shopCode') {
      this.createPackMasterDetailsList[i].shopCode = value;
      this.mt4[i] = false;
      if (this.createPackMasterDetailsList[i].shopCode == "" || this.createPackMasterDetailsList[i].shopCode == 'n') {
        this.mt4[i] = true;
      }
    }
    if (name == 'packCode') {
      this.createPackMasterDetailsList[i].packCode = value;
      this.mt5[i] = false;
      if (this.createPackMasterDetailsList[i].packCode == "") {
        this.mt5[i] = true;
      }
    }
    // if (name == 'groupCode') {
    //   this.createPackMasterDetailsList[i].groupCode = value;
    //   this.mt6[i] = false;
    //   if (this.createPackMasterDetailsList[i].groupCode == "") {
    //     this.mt6[i] = true;
    //   }
    // }
  }

  setGroupCode(e, i) {
    this.responseMessage = '';
    let value = (Object)(e.target.value);
    this.createPackMasterDetailsList[i].groupCode = value;
    this.mt6[i] = false;
    if (this.createPackMasterDetailsList[i].groupCode == "" || this.createPackMasterDetailsList[i].groupCode == 'n') {
      this.mt6[i] = true;
    }
  }

  setAssortmentType(e, i) {
    this.responseMessage = '';
    let value = (Object)(e.target.value);
    this.createPackMasterDetailsList[i].assortmentType = value;
    if(this.createPackMasterDetailsList[i].assortmentType == 'A'){
      this.mt7[i] = false;
      this.mt2[i] = false;
    }
    else{
      this.mt7[i] = false;
      this.mt2[i] = true;
      this.mt1[i] = false;
      this.mt3[i] = false;
      this.mt4[i] = false;
      this.mt5[i] = false;
      this.createPackMasterDetailsList[i].catCode = null;
      this.createPackMasterDetailsList[i].concept = null;
      this.createPackMasterDetailsList[i].packCode = null;
      this.createPackMasterDetailsList[i].shopCode = null;
    }
    
    if (this.createPackMasterDetailsList[i].assortmentType == "" || this.createPackMasterDetailsList[i].assortmentType == 'n') {
      this.mt7[i] = true;
    }
  }

  setPair(e, i) {
    let value = (Object)(e.target.value);
    this.searchPackingAssortmentDetails[i].pairQty = value;
    this.errorMessage = '';
  }

  onClickConfirmChanges() {
    this.errorMessage = '';
    let assortList = [];
    console.log('****new data entered details*****' + JSON.stringify(this.searchPackingAssortmentDetails));
    this.createPackMasterDetailsList[this.currentIndex].packingMasterAssortmentsDTO = this.searchPackingAssortmentDetails;
    this.createPackMasterDetailsList.forEach(
      packingdetail => {
        packingdetail.createdBy = this.createdUser;
        packingdetail.createdDate = this.currentDate;
        packingdetail.lastUpdateDate = this.currentDate;
        packingdetail.lastUpdatedBy = this.createdUser;
        packingdetail.packingMasterAssortmentsDTO.forEach(
          assortmnet => {
            assortmnet.createdBy = this.createdUser;
            assortmnet.createdDate = this.currentDate;
            assortmnet.lastUpdatedBy = this.createdUser;
            assortmnet.lastUpdateDate = this.currentDate;
          }
        );
      }
    );
    assortList = [];
    this.createPackMasterDetailsList[this.currentIndex].packingMasterAssortmentsDTO.forEach(
      assort => {
        if (assort.pairQty != "" && assort.pairQty != '0' && this.createPackMasterDetailsList[this.currentIndex].assortmentType == 'A') {
          assortList.push(assort);
        }
        if (this.createPackMasterDetailsList[this.currentIndex].assortmentType == 'W') {
          this.createPackMasterDetailsList[this.currentIndex].concept =null;
          this.createPackMasterDetailsList[this.currentIndex].catCode =null;
          this.createPackMasterDetailsList[this.currentIndex].shopCode =null;
          this.createPackMasterDetailsList[this.currentIndex].packCode =null;
          if(assort.pairQty == ""){
            assort.pairQty = '0';
            assortList.push(assort);
          }
          else{
            assortList.push(assort);
          }
        }
      }
    );
    if(assortList.length != 0){
    this.createPackMasterDetailsList[this.currentIndex].packingMasterAssortmentsDTO = assortList;
    console.log('------edited data-----' + JSON.stringify(this.createPackMasterDetailsList));
    this.errorMessage = 'Changes Confirmed';
    this.ifAssortmentFilled[this.currentIndex] = true;
    this.delay(1500).then(any=>{
      //your task after delay.
      this.cancel.nativeElement.click();
     });
    }
    else{
      this.errorMessage = 'Please fill Assortment First';
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
}

  onClickSave() {
    this.responseMessage = '';
    let flag = 0;
    let packingLength = this.createPackMasterDetailsList.length
    this.ifAssortmentFilled.forEach(
      obj => {
        if(obj == true){
          flag = flag + 1;
        }
      }
    )
    if (flag == packingLength) {
      console.log('edited data' + JSON.stringify(this.createPackMasterDetailsList));
      this._createPackingMasterSerivce.saveEditPackingDetails(this.createPackMasterDetailsList).subscribe(
        (response: ResponseMessage) => {
          console.log(response);
          if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
            this.responseMessage = 'Packing Master Data Created successfully';
          }
          if (response != null && response.status == 'ERROR') {
            this.responseMessage = response.message;
          }
        },
        (err) =>{
          this.responseMessage = err;
        }
      );
    }
    else {
      this.responseMessage = 'Please fill the Data first';
    }
  }

  deleteDetail(i) {
    if (i != 0) {
      this.createPackMasterDetailsList.splice(i, 1);
      this.responseMessage = '';
      this.errorMessage1 = '';
      this.errorMessage = '';
      this.ifAssortmentFilled[i] = false;
    }
  }

  onSelectFile(event) {

    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
    }
  }

  setValue() {
    this.uploadFileName = '';
    this.myInputVariable.nativeElement.value = "";
  }

  onSubmit() {
    this.statusMessage = '';
    this.responseMessage = '';
    if (this.myInputVariable.nativeElement.files.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage='error';
      return false;
    }
    let newFileArray:any[] = this.uploadFileName.split('.');
    newFileArray[0] = newFileArray[0].replace(/ /g,"_");
     let newFileFormat = newFileArray[0]+'_'+ this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
     //this.profileForm.get('file').setValue(newFileFormat);
     const formData = new FormData();
     //formData.append('name', this.profileForm.get('name').value);
     formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData.append('uploadKey', this.profileForm.get('uploadKey').value);
    formData.append('uploadedBy', this.profileForm.get('uploadedBy').value);
    this.loaDer.nativeElement.style.display = 'block';
    this._createPackingMasterSerivce.upload(formData).subscribe(
      (response: importsave) => {
        this.responseMessage = '';
        this.statusMessage = '';
        if (response.status == "success") {

          this.responseMessage = response.message;
          this.statusMessage = 'success';
          this.enable = true;
        }
        else {
          this.responseMessage = response.message;
          this.statusMessage = 'error';
          this.enable = false;
        }
        this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.enable = false;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  onSubmit1() {
    this.statusMessage = '';
    this.responseMessage = '';
    if (this.myInputVariable1.nativeElement.files.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage='error';
      return false;
    }
    let newFileArray:any[] = this.uploadFileName.split('.');
    newFileArray[0] = newFileArray[0].replace(/ /g,"_");
     let newFileFormat = newFileArray[0]+'_'+ this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
     //this.profileForm.get('file').setValue(newFileFormat);
     const formData1 = new FormData();
     //formData.append('name', this.profileForm.get('name').value);
     formData1.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData1.append('uploadKey', this.profileForm.get('uploadKey1').value);
    formData1.append('uploadedBy', this.profileForm.get('uploadedBy').value);
    this.loaDer.nativeElement.style.display = 'block';
    this._createPackingMasterSerivce.upload1(formData1).subscribe(
      (response: ResponseMessage) => {
        this.responseMessage = '';
        this.statusMessage = '';
        if (response.status == "success") {
          this.responseMessage = 'File Uploaded successfully';
          this.statusMessage = 'success';
          this.enabled = true;
        }
        this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err) => {
        this.responseMessage = err;
        this.statusMessage = 'error';
        this.enabled = false;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }
  public async downloadFile(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    console.log('download template called');
    this._createPackingMasterSerivce.getDownloadFile().subscribe(
      (res: BatchesData) => {
        this.downloadUrlList = res['configParamModels'];
        // if (this.downloadUrlList[0].paramName == 'template_url') {
        //   console.log('inside if check');
        //   this.downloadUrl = this.downloadUrlList[0].paramValue;
        //   //console.log(this.downloadUrl);
        //   this.getDownloadedFile(this.downloadUrl);
        //   return this.checkService = true;
        // }
        this.downloadUrlList.filter(cs=> cs.paramName == 'template_url').forEach(
          each => {
            this.downloadUrl = each.paramValue;
          }
        )
        this.getDownloadedFile(this.downloadUrl);
        return this.checkService = true;
      }
    );
  }

  public async getDownloadedFile(downloadUrl): Promise<void> {
    console.log(downloadUrl);
    let fullDownloadUrl: string = downloadUrl + "Packing_Master_Assorted_Master_Template.xls";
    const file = await this.httpClient.get<Blob>(fullDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    console.log('downloaded:: ' + blob);
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "Packing_Master_Assorted_Master_Template.xls";
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none'; 
  }

  public async downloadFile1(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    this._createPackingMasterSerivce.getDownloadFile().subscribe(
      (res: BatchesData) => {
        this.downloadUrlList = res['configParamModels'];
        // if (this.downloadUrlList[0].paramName == 'template_url') {
        //   console.log('inside if check');
        //   this.downloadUrl = this.downloadUrlList[0].paramValue;
        //   //console.log(this.downloadUrl);
        //   this.getDownloadedFile(this.downloadUrl);
        //   return this.checkService = true;
        // }
        this.downloadUrlList.filter(cs=> cs.paramName == 'template_url').forEach(
          each => {
            this.downloadUrl = each.paramValue;
          }
        )
        this.getDownloadFile(this.downloadUrl);
        return this.checkService = true;
      }
    );
  }
  public async getDownloadFile(downloadUrl): Promise<void> {
    let fullDownloadUrl: string = downloadUrl + "Packing_Master_WA_Master_Template.xls";
    const file = await this.httpClient.get<Blob>(fullDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    console.log('downloaded:: ' + blob);
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "Packing_Master_WA_Master_Template.xls";
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none'; 
  }
  generateImportPo() {

    this.responseMessage = ''
    let uploadkeyValues: any =
      { "uploadKeyValues": "PACK_ASSORTED",
        "uploadedBy":JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode   
       };
      this.loaDer.nativeElement.style.display = 'block';
    let importPo: any = this._createPackingMasterSerivce.getGenerate(uploadkeyValues).subscribe(
      (response: importsave) => {
        console.log('success');
        console.log(response);
        if (response.status == "success") {
          this.responseMessage = response.message;
          this.statusMessage = 'success';
        }
        else {
          this.responseMessage = response.message;
          this.statusMessage = 'error';
        }
        this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err) => {
        this.responseMessage = err.message;
        this.statusMessage = 'error';
        this.loaDer.nativeElement.style.display = 'none';
      }
    );

    this.enable = false;
  }

  generateWholesale() {
    this.responseMessage = '';
    let uploadkeyValues: any =
      { "uploadKeyValues": "PACK_WSL_ASSORTED",
        "uploadedBy":JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode
       };
      this.loaDer.nativeElement.style.display = 'block';
    let importPo: any = this._createPackingMasterSerivce.getGenerated(uploadkeyValues).subscribe(
      (response: importsave) => {
        if (response.status == "success") {
          this.responseMessage = response.message;
          this.statusMessage = 'success';
        }
        else {
          this.responseMessage = response.message;
          this.statusMessage = 'error';

        }
        this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err) => {
        this.responseMessage = err.message;
        this.loaDer.nativeElement.style.display = 'none';
      }

    );
    this.enabled = false;

  }

  resetSearchPurchaseOrderResults() {
    this.profileForm.reset;
    this.responseMessage = '';
    this.uploadFileName = '';
    this.loaDer.nativeElement.style.display = 'none';
    this.myInputVariable.nativeElement.value = "";
  }

}
