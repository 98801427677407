import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateRdcMaintenanceService } from './create-rdc-maintenance.service';
import { UtilsService } from '@/common/utils-service';

@Component({
  selector: 'app-create-rdc-maintenance',
  templateUrl: './create-rdc-maintenance.component.html',
  styleUrls: ['./create-rdc-maintenance.component.css','../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css']
})
export class CreateRdcMaintenanceComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  rdcResultForm:FormGroup;
  userName: any;
  keys: any[];
  saveArray: any[];
  statusMessage: string;
  updateMessage: string;
  added : boolean = false;
  sysdate : any;
  todayDate : any;
  
  constructor(private fb: FormBuilder,private _router: Router,private createRdcMaintenanceService:CreateRdcMaintenanceService,
              private _utilsService : UtilsService) {
    this.rdcResultForm = this.buildRDCResultForm();
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.sysdate = new Date();
    console.log(this.sysdate);console.log('sysdate');
    this.todayDate = this._utilsService.transformDateWithTime(this.sysdate);
    console.log(this.todayDate); console.log('today date');
   }

  ngOnInit() {
    setTimeout(() => {
    this.keys = [{
      "name" : "Yes",
      "value" : "Y"
    },
    {
      "name" : "No",
      "value" : "N"
    }]
    this.loaDer.nativeElement.style.display = 'none';
  }, 1000);
  }

  buildRDCResultForm(): FormGroup {
    return this.fb.group({ rdcmain: this.fb.array([]) });
  }

  addRDCMaintenance(){
    this.statusMessage = '';
    this.updateMessage = '';
    if(this.rdcResultForm.invalid){
      this.added = true;
      return;
    }
    else{ 
      this.added = false;
    let control =  <FormArray>this.rdcResultForm.controls.rdcmain;
    return control.push(this.fb.group(
      {
        shopNo: [null, [Validators.required]],
        facilityCode: [null, [Validators.required]],
        companyCode: [null, [Validators.required]],
        operationalInRDS: [null, [Validators.required]],
        operationalInPO: [null, [Validators.required]],
        parentRDCEnabled: [false, [Validators.required]],
        parentShopNumber: [{value : null, disabled : true},[Validators.required]]
      }
    ));
    }
  }
  onChangeParentRDCEnabled(event :any,index :any){
    console.log(this.rdcResultForm.get(['rdcmain',index]).get('parentRDCEnabled').value);
    this.rdcResultForm.get(['rdcmain',index]).get('parentRDCEnabled').value == true ?
      this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').enable() :  
     (this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').disable(),this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').setValue(null))
    
  }

  onSave(formData : any){
    if(!this.rdcResultForm.invalid){
        this.saveArray = [];
    let control = <FormArray>this.rdcResultForm.controls.rdcmain;
    for(let index=0;index< control.length;index++){
          let saveObj = {
            "shopNumber": this.rdcResultForm.get(['rdcmain',index]).get('shopNo').value ,
            "facilityCode": this.rdcResultForm.get(['rdcmain',index]).get('facilityCode').value.toUpperCase(),
            "companyCode": this.rdcResultForm.get(['rdcmain',index]).get('companyCode').value.toUpperCase(),
            "originInfo":  this.rdcResultForm.get(['rdcmain',index]).get('facilityCode').value.concat(this.rdcResultForm.get(['rdcmain',index]).get('companyCode').value).toUpperCase(),
            "operationalRdsDmsFlag": this.rdcResultForm.get(['rdcmain',index]).get('operationalInRDS').value,
            "operationalLogFireFlag" : this.rdcResultForm.get(['rdcmain',index]).get('operationalInRDS').value,
            "operationalPOFlag": this.rdcResultForm.get(['rdcmain',index]).get('operationalInPO').value,
            "createdBy": this.userName,
            "createdDate": "",
            "parentShopNumber": this.rdcResultForm.get(['rdcmain',index]).get('parentShopNumber').value
              }
              this.saveArray.push(saveObj);
            }
    this.loaDer.nativeElement.style.display = 'block';
    this.statusMessage = '';
    this.updateMessage = '';
    console.log(this.saveArray); console.log('save array');
    
    if(this.saveArray[0] == null){
      this.loaDer.nativeElement.style.display = 'none';
      alert('Please Create the record first');
      return;
    }
  this.createRdcMaintenanceService.getCreateRDC(this.saveArray).subscribe(
    (response : any) =>
     { 
     
       if(response.status == "Success"){
          this.statusMessage = "Completed";
          this.updateMessage = response.message;
          this.loaDer.nativeElement.style.display = 'none';
      }
      else{
        this.statusMessage = "Error";
        this.updateMessage = response.message;
        this.loaDer.nativeElement.style.display = 'none';
      }
     },(err)=>{
       this.statusMessage = "Error";
       this.updateMessage = err;
       this.loaDer.nativeElement.style.display = 'none';
     }

  );
}
  else{
    console.log('in else');
  }
  }

  deleteRecord(index:any){
    let control = <FormArray>this.rdcResultForm.controls.rdcmain;
    control.removeAt(index); 
  }

  onClickBack() { this._router.navigate(['']); }
}
