import { BilApiUrl } from '@/common/bil-api-url';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';
import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadDownloadCompService {
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  uploadfileURL: string = RdsBilApiUrl.UPLOAD_ASN_EXCEL_FILE;
  apiDetailList:any[] = [
    {
      key:"Import_GSTR_Upload", apiName:RdsBilApiUrl.IMPORT_GSTR_UPLOAD
    }
  ]
  constructor(private _http: HttpClient) { }

  upload(uploadFileData){
    return this._http.post(`${this.uploadfileURL}`, uploadFileData);
  }

  getDownloadUrl() {
    return this._http.get(this.urlForDownloadUrl);
  }

  getGenerate(uploadDetails){
    let url = this.apiDetailList.filter(cs => cs.key == uploadDetails.uploadKeyValues)[0].apiName;
    return this._http.post(url, uploadDetails);
  }

  public async getDownloadFile(downloadUrl:string,downloafFileName): Promise<void> {
    let fullDownloadUrl: string = downloadUrl;
    const file = await this._http.get<Blob>(fullDownloadUrl, { responseType: "blob" as "json" }).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = downloafFileName;
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
  }


}
