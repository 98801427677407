import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RequestParameterModel } from '../common/request-parameter-model';
import { PurchaseOrderSearchForm } from './po-search-form';
import {BilApiUrl} from '../common/bil-api-url';
import { DeleteDetails } from '@/common/delete-details';

@Injectable({
  providedIn: 'root'
})
export class SearchPurchaseOrderService {

  purchase_order_search_url: string = BilApiUrl.PO_HEADERS_URL;
  concept_url : string = BilApiUrl.CONCEPT_LIST_URL;
  order_type_url = 'assets/static-data/orderTypes.json';
  delete_po_url = BilApiUrl.DELETE_DETAILS;
  update_po_url = BilApiUrl.UPDATE_DETAILS;
  update_all_po_price_url=BilApiUrl.UPDATE_ALL_PO_PRICE_DETAILS;
  
  constructor(private _http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url,conceptQueryMas);
  }

  getOrderTypes() {
    return this._http.get(this.order_type_url);
  }

  /*
  This class call the PO api service which return the matching 
  purchase orders as per the search criteria.
  */
  getPurchaseOrderBySearchCriteria(purchaseOrderFormData: PurchaseOrderSearchForm, username :string) {

    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    //TODO: shift below code to seperate file.
    let jsonPurchaseOrder = JSON.stringify(purchaseOrderFormData);
    let jsonObject = JSON.parse(jsonPurchaseOrder);
    for (var i in jsonObject) {
      let requestParameterModel = new RequestParameterModel();
      if (jsonObject[i] != null && jsonObject[i] != "") {
        requestParameterModel.name = i;
        requestParameterModel.value = jsonObject[i];
        purchase_order_search_parameters.push(requestParameterModel);
      }
    }
    let user = {
      "name":"userId",
      "value":username
    }
    purchase_order_search_parameters.push(user);
    return this._http.post(this.purchase_order_search_url, purchase_order_search_parameters);
  }

  getPoDeleted(delete_purchase_order_parameters:DeleteDetails){
    //  console.log('Details getting passed to service'+JSON.stringify(delete_purchase_order_parameters));
      console.log('Header id that is getting deleted'+delete_purchase_order_parameters.id);
      return this._http.post(this.delete_po_url, delete_purchase_order_parameters);
    }

    getPoUpdated(update_purchase_order_parameters){
        console.log('Header id that is getting updated'+update_purchase_order_parameters.id);
        return this._http.post(this.update_po_url, update_purchase_order_parameters);
      }

    getPoSearchFromHome(requestModel){
      return this._http.post(this.purchase_order_search_url, requestModel);
    }
  
    updateAllPoPrice(poDetails){
      return this._http.post(this.update_all_po_price_url,poDetails);
    }
}