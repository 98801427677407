import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormArray, FormGroup, Validators } from "@angular/forms";
import { RequestParameterModel } from '../../common/request-parameter-model';
import { CreateInventoryBifurcationService } from "./create-inventory-bifurcation.service";

@Component({
  selector: 'app-create-inventory-bifurcation',
  templateUrl: './create-inventory-bifurcation.component.html',
  styleUrls: [
    "../../../assets/css/bootstrap.css",
    "../../../assets/css/rds-bil-style.css",
    "../../../../node_modules/font-awesome/css/font-awesome.css",
    './create-inventory-bifurcation.component.css']
})
export class CreateInventoryBifurcationComponent implements OnInit {

  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  concepts: any[];
  division: string;
  userCode: string;
  ShopCodes: any[] = [];
  responseMessage: string;
  responseMessage1: string;
  ShowSearchDisable: boolean = false;
  inventoryBifurcationForm: FormGroup;
  assortData: any = {};
  groupCode: string;
  artNumber: string;
  inventoryBifurcationList: any[];
  requestParameterModel: RequestParameterModel[];
  inventoryArray: any[];
  inventoryFinalArray: any[];
  submitBtn:Boolean = false;

  constructor(
    private fb: FormBuilder,
    private CreateInventoryBifurcationService: CreateInventoryBifurcationService
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.buildForm();
  }

  buildForm(): void {
    this.inventoryBifurcationForm = this.fb.group({
      //conceptCode: [null],
      parentShopNumber: [null],
      //ratioPercentage: [""],
      userCode: [this.userCode]
    });
  }

  ngOnInit() {
    this.inventoryArray = [];
    this.inventoryFinalArray = [];
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = "none";
      let conceptQueryMas = [
        {
          name: "email",
          value: this.userCode,
        },
      ];
      this.CreateInventoryBifurcationService.getConcepts(conceptQueryMas).subscribe(
        (data: any) => {
          this.concepts = data.dataList.filter(function (list) {
            return (list.division == "RETAIL" && (list.concCode == 'F' || list.concCode == 'L'));
          });
        },
        (err) => {
          this.responseMessage = err;
        }
      );
      this.showShopCode();
    }, 500);
  }

  showShopCode() {
    this.CreateInventoryBifurcationService.getShopCode().subscribe(
      (data: any) => {
        this.ShopCodes = data.parentRDCModels;
      },
      (err) => {
        this.responseMessage = err;
      }
    );
  }

  onReset() {
    this.inventoryBifurcationForm.reset();
    setTimeout(() => {
      this.buildForm();
    }, 500);
    setTimeout(() => {
      //this.responseMessage = "";
      this.responseMessage1 = "";
    }, 3000);
    this.inventoryBifurcationList = [];
    this.inventoryArray = [];  
    this.inventoryFinalArray = [];     
  }

  // addInventoryBifurcation() {
  //   if (this.inventoryBifurcationForm.controls["conceptCode"].value == null || this.inventoryBifurcationForm.controls["parentShopNumber"].value == null  || this.inventoryBifurcationForm.controls["ratioPercentage"].value == "" ) {
  //     this.responseMessage = "Please fill all fields.";
  //     return false;
  //   } else {
  //     this.responseMessage = "";
  //     var index = this.inventoryArray.findIndex(x => x.conceptCode === this.inventoryBifurcationForm.controls["conceptCode"].value && x.parentShopNumber === this.inventoryBifurcationForm.controls["parentShopNumber"].value);
  //     if(index == -1){
  //       var data = {
  //         conceptCode: this.inventoryBifurcationForm.controls["conceptCode"].value,
  //         parentShopNumber: this.inventoryBifurcationForm.controls["parentShopNumber"].value,
  //         ratioPercentage: this.inventoryBifurcationForm.controls["ratioPercentage"].value,
  //         createdBy: this.userCode
  //       };
  //       this.inventoryArray.push(data);
  //     }
  //   }
  //   this.inventoryBifurcationList = this.inventoryArray;
  // }

  addInventoryBifurcation() {
    if (this.inventoryBifurcationForm.controls["parentShopNumber"].value == null) {
      this.responseMessage = "Please select Shop Location.";
      return false;
    } else {
      this.responseMessage = "";
      var index = this.inventoryArray.findIndex(x => x.parentShopNumber === this.inventoryBifurcationForm.controls["parentShopNumber"].value);
      if(index == -1){
        var data = {
          parentShopNumber: this.inventoryBifurcationForm.controls["parentShopNumber"].value,
          //ratioYrWk:'',
          ratioYr:'',
          ratioWk:'',
          ratioPercentageConceptF: "0",
          ratioPercentageConceptL: "0",
          createdBy: this.userCode
        };
        this.inventoryArray.push(data);
      }
    }
    this.inventoryBifurcationList = this.inventoryArray;
  }

  digitCheck(event,fieldName, index) {
    var regExpr = /[^0-9-.]/g;
    let currentDate = new Date();
    let currentYear= currentDate.getFullYear();
    var ratio = event.target.value;
    if (ratio != "") {
      if(fieldName == 'ratioYr'){
        if(ratio.length != 4 || ratio != currentDate.getFullYear()){
          this.responseMessage = "Year must be current year and of 4 digit for row "+(index+1)+".";
        } else{
          this.responseMessage = "";
          this.inventoryArray[index][fieldName] = ratio.replace(regExpr, ''); 
        }        
      }
      else if(fieldName == 'ratioWk'){
        if(ratio.length != 2){
          this.responseMessage = "Week must be of 2 digit for row "+(index+1)+".";
        } else {
          this.responseMessage = "";
          this.inventoryArray[index][fieldName] = ratio.replace(regExpr, ''); 
        }        
      }
      else {
        if(ratio > 100){
          this.responseMessage = "Ratio Percentage must be less than 100 for row "+(index+1)+".";
          this.inventoryArray[index][fieldName] = '';
        }else{
          this.responseMessage = "";
          this.inventoryArray[index][fieldName] = ratio.replace(regExpr, '');
        }
      }
    } 
  }

  delete(index){
    var parentShopNumber = this.inventoryArray[index].parentShopNumber;
    if(this.inventoryFinalArray && this.inventoryFinalArray.length > 0){
      var newArray = this.inventoryFinalArray.filter(function(element) {
        console.log(element);
      return element.parentShopNumber !== parentShopNumber;
      });
      this.inventoryFinalArray = newArray;
    }
    this.inventoryArray.splice(index, 1);
    this.inventoryBifurcationForm.reset();
  }

  submitInventoryBifurcation() {
    //let inventoryAddArray = [];
    this.submitBtn=true;
    this.responseMessage = ""; 
    this.responseMessage1 = ""; 
    let inputboxValidation = 0;  
    this.inventoryFinalArray = []; 
    if(this.inventoryArray.length > 0){
      this.loaDer.nativeElement.style.display = "block";
      const newArr = [...new Set(this.inventoryArray.map(a => a.parentShopNumber))];
      if(newArr.length > 0){
        for(let i=0; i<newArr.length; i++){
          var shopNumberWiseArray = this.inventoryArray.filter(function(list) {
            return list.parentShopNumber == newArr[i];
          });
          let sum = shopNumberWiseArray.reduce((total, obj) => parseInt(obj.ratioPercentageConceptF) + parseInt(obj.ratioPercentageConceptL) + total,0);
          if(sum != 100){
            this.responseMessage1 += " Summation of ratio percentage must be equal to 100 of parent shop number "+newArr[i];
            this.loaDer.nativeElement.style.display = "none";
            this.submitBtn=false;
            inputboxValidation++;
            return false;
          } 
          // else if(sum == 100){
          //   inventoryAddArray.push(shopNumberWiseArray);
          // }
        }
      } 
      
       if (inputboxValidation == 0) {
         console.log(this.inventoryArray);
         if(this.inventoryArray && this.inventoryArray.length > 0){
           for(let i = 0; i < this.inventoryArray.length; i++){
            if(this.inventoryArray[i].ratioYr == '' || this.inventoryArray[i].ratioYr == undefined || this.inventoryArray[i].ratioWk == '' || this.inventoryArray[i].ratioWk == undefined){
              this.responseMessage1 = "Please enter year and week of parent shop code  "+this.inventoryArray[i].parentShopNumber+".";
              this.submitBtn=false;
              return false;
            }
            let currentDate = new Date();
            console.log(this.inventoryArray[i].ratioYr);
            if(this.inventoryArray[i].ratioYr != currentDate.getFullYear()){
              this.responseMessage1 = "Please enter current year for parent shop code  "+this.inventoryArray[i].parentShopNumber+".";
              this.submitBtn=false;
              return false;
            }
            var index = this.inventoryFinalArray.findIndex(x => x.parentShopNumber === this.inventoryArray[i].parentShopNumber);
              //if(index == -1){
                var data1 = {
                  ratioYrWk : this.inventoryArray[i].ratioYr + this.inventoryArray[i].ratioWk,
                  parentShopNumber: this.inventoryArray[i].parentShopNumber,
                  conceptCode: 'F',
                  ratioPercentage: this.inventoryArray[i].ratioPercentageConceptF,
                  createdBy: this.userCode
                };
                this.inventoryFinalArray.push(data1);
                var data2 = {
                  ratioYrWk : this.inventoryArray[i].ratioYr + this.inventoryArray[i].ratioWk,
                  parentShopNumber: this.inventoryArray[i].parentShopNumber,
                  conceptCode: 'L',
                  ratioPercentage: this.inventoryArray[i].ratioPercentageConceptL,
                  createdBy: this.userCode
                };    
                this.inventoryFinalArray.push(data2);
              //}
           }
         }
         this.loaDer.nativeElement.style.display = "block";
         console.log(this.inventoryFinalArray);
        this.CreateInventoryBifurcationService
        .addinventoryBifurcationDetails(this.inventoryFinalArray)
        .subscribe(
          (response: any) => {
            if (response.status == "success") {
              this.submitBtn=false;
              this.responseMessage = response.message;
              this.loaDer.nativeElement.style.display = "none";
              this.onReset();
            } else {
              this.submitBtn=false;
              this.responseMessage1 = response.message;
              this.loaDer.nativeElement.style.display = "none";
            }
          },
          (err) => {
            this.submitBtn=false;
            this.responseMessage1 = "Something error happened.";
            this.loaDer.nativeElement.style.display = "none";
          }
        );     
       }
    }    
  }

}
