import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '../common/bil-api-url';
import { PO_STATUS, PO_STATUS_PENDING } from '../common/field-name-list';
import { PO_STATUS1 } from '../common/field-name-list';
import { DeleteDetails } from '@/common/delete-details';
import { PendingPurchaseOrderSearchForm } from './pending-po-search-form';

@Injectable({
  providedIn: 'root'
})
export class PendingPoService {

  constructor(private _http: HttpClient) { }

   //search_purchase_order_header_url = BilApiUrl.PO_HEADERS_URL;
   delete_po_url = BilApiUrl.DELETE_DETAILS;
   new_url= BilApiUrl.PO_HEADERS_URL;
  //  new_url= BilApiUrl.PENDING_URL;
  /*
  This class call the PO api service which return the matching 
  purchase orders.
  */
 getPendingPoList(pendingPurchaseOrderFormData: PendingPurchaseOrderSearchForm ,username :string) {

  let purchase_order_search_parameters = new Array<RequestParameterModel>();

    //TODO: shift below code to seperate file.
    let jsonPendingPurchaseOrder = JSON.stringify(pendingPurchaseOrderFormData);
    let jsonObject = JSON.parse(jsonPendingPurchaseOrder);

    for (var i in jsonObject) {
      let requestParameterModel = new RequestParameterModel();
      if (jsonObject[i] != null && jsonObject[i] != "") {
        requestParameterModel.name = i;
        requestParameterModel.value = jsonObject[i];
        purchase_order_search_parameters.push(requestParameterModel);
      }
    }

  let requestParameterModelSTatus = new RequestParameterModel();
  requestParameterModelSTatus.name = PO_STATUS;
  requestParameterModelSTatus.value = PO_STATUS_PENDING;
  purchase_order_search_parameters.push(requestParameterModelSTatus);
  let user = {
    "name":"userId",
    "value":username
  }
  purchase_order_search_parameters.push(user);
  console.log(JSON.stringify(purchase_order_search_parameters));
  return this._http.post(this.new_url, purchase_order_search_parameters);

}

  getPoDeleted(delete_purchase_order_parameters:DeleteDetails){
    //  console.log('Details getting passed to service'+JSON.stringify(delete_purchase_order_parameters));
      //console.log('Header id that is getting deleted'+delete_purchase_order_parameters.id);
      return this._http.post(this.delete_po_url, delete_purchase_order_parameters);
    }

}
