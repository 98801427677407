import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import {FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { MultipleRequisitionService} from './multiple-requisition.service';
import {ResponseMessage} from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { ActivatedRoute, Router } from '@angular/router';
import{Batches} from '../batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import {importsave} from '../import-article-price/import-article-save';
import {UtilsService} from '../common/utils-service';

@Component({
  selector: 'app-multiple-requisition',
  templateUrl: './multiple-requisition.component.html',
  styleUrls: ['./multiple-requisition.component.css']
})
export class MultipleRequisitionComponent implements OnInit {

  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  profileForm: FormGroup;
  error: string;
  responseMessage:string;
  statusMessage:string = '';
  uploadFileName: string = 'No File Chosen';
  downloadUrlList:Batches[] = [];
  downloadUrl:string;
  downloadedFile:string;
  checkService:boolean=false;
  enable:boolean=false;
  fileUpload = {status: '', message: '', filePath: ''};
  userName:string;
  selVal:string;
  Selenable:string;
  selValError:string;
  isDisplay:boolean=false;
  enableFlag:boolean=false;

  constructor( private _router: Router, private fb: FormBuilder, private fileUploadService: MultipleRequisitionService,private httpClient: HttpClient,
    private _utilsService: UtilsService ) { 
      this.uploadFileName;
      this.profileForm=this.fb.group({
        name:[''], 
        file:[''],
        uploadKey:['MULTIPLE_ARTICLE_DISTRIBUTION'],
        uploadedBy:['']
 }); 
  }


  ngOnInit() {
    setTimeout(()=>{
      this.loaDer.nativeElement.style.display = 'none';    
    },100);

    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.profileForm.get('uploadedBy').setValue(this.userName);
    this.isDisplay=true;


  }

  onChange(selectedValue: any) {
    this.selVal = selectedValue;
    this.isDisplay=true;

    console.log(this.selVal);
    // if(this.selVal =='PO_BULK_CANCELLATION'){
    //   this.Selenable = 'CANCEL';
    // }else{
    //   this.isDisplay = false;
    // }
    // if ((this.selVal != 'null')) {
    //   this.enableFlag = true;
    // } else {
    //   this.enableFlag = false;
    // }
    
  }

  // ---------------------- file upload ------------------------ //

  onSelectFile(event) {    
    if(event.target.files.length > 0 ) {
      this.statusMessage='';
      this.responseMessage='';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
    }
  }

  onSubmit()  {
    this.statusMessage='';
    this.responseMessage='';
    if (this.myInputVariable.nativeElement.files.length == 0) {
      this.responseMessage = 'Please select file';
      this.statusMessage='error';
      return false;
    }
    let newFileArray:any[] = this.uploadFileName.split('.');
    newFileArray[0] = newFileArray[0].replace(/ /g,"_");
     let newFileFormat = newFileArray[0]+'_'+ this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
     //this.profileForm.get('file').setValue(newFileFormat);
     const formData = new FormData();
     //formData.append('name', this.profileForm.get('name').value);
     formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
     formData.append('uploadKey','MULTIPLE_ARTICLE_DISTRIBUTION');
     formData.append('uploadedBy', this.profileForm.get('uploadedBy').value);
     this.loaDer.nativeElement.style.display = 'block';

     this.fileUploadService.upload(formData).subscribe(
      (response : importsave) => {
        this.responseMessage = '';
        this.statusMessage='';
        if( response.status == "success" ){
          this.statusMessage='success';
          this.responseMessage = response.message;
          this.enable=true;
         }
         else{
           this.statusMessage='error';
           this.responseMessage=response.message;
           this.enable=false;
         }
         this.loaDer.nativeElement.style.display = 'none'; 
       },
       (err)=>{
        this.statusMessage='error';
        this.responseMessage=err;
        this.enable=false;
        this.loaDer.nativeElement.style.display = 'none';
       }
     );
  }

  // ---------------------- file upload ------------------------ //

  onClickBack() {
    this._router.navigate(['']);
  }

  // ------------------- download template --------------------- //

  public async downloadFile(): Promise<void>{ 
    this.loaDer.nativeElement.style.display = 'block';  
    this.fileUploadService.getDownloadUrl().subscribe(
      (res:BatchesData) => {
        this.downloadUrlList = res['configParamModels'];
        // if (this.downloadUrlList[0].paramName == 'template_url') {
        //   console.log('inside if check');
        //   this.downloadUrl = this.downloadUrlList[0].paramValue;
        //   //console.log(this.downloadUrl);
        //   this.getDownloadedFile(this.downloadUrl);
        //   return this.checkService = true;
        // }
        this.downloadUrlList.filter(cs=> cs.paramName == 'template_url').forEach(
          each => {
            this.downloadUrl = each.paramValue;
          }
        )
        console.log(this.downloadUrl);
        this.getDownloadFile(this.downloadUrl);
        return this.checkService = true;
      }
    );
  }
  
  public async getDownloadFile(downloadUrl): Promise<void>{

    // if (this.selVal == 'null' || this.selVal == undefined || this.selVal == '') {

    //   this.selValError = 'Please Select';
    // }else{

    //   this.selValError = '';

    let fullDownloadUrl:string=downloadUrl+"Upload_Distribution_Master_Template.xls";
    const file = await this.httpClient.get<Blob>(fullDownloadUrl,{responseType: 'blob' as 'json'}).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "Upload_Distribution_Master_Template.xls";
    anchor.href = url;
    anchor.click(); 
    window.URL.revokeObjectURL(url);  
    this.loaDer.nativeElement.style.display = 'none'; 
    
  }

  // ------------------- download template --------------------- //

  // -------------------- reset template ----------------------- //
  resetSearchPurchaseOrderResults(){
    this.uploadFileName='No File Chosen';
    this.statusMessage='';
    this.responseMessage='';
    this.enable=false;
    // this.Selenable='';
    // this.selVal='';
    this.loaDer.nativeElement.style.display = 'none';
    this.myInputVariable.nativeElement.value = "";
  }
  // -------------------- reset template ----------------------- //

  generateImportPo() {
    this.statusMessage='';
    this.responseMessage='';
    let uploadkeyValues:any= {
       "uploadKeyValues":'MULTIPLE_ARTICLE_DISTRIBUTION',
       "reqNumber": null,
       "userId": this.userName
   };
    this.loaDer.nativeElement.style.display = 'block';
    let importPo:any=this.fileUploadService.getGenerate(uploadkeyValues).subscribe(
      (response:importsave) => {
        if (response.status=="success"){
          this.statusMessage='success';
          this.responseMessage=response.message;        
        } else {
          this.statusMessage='error';
          this.responseMessage=response.message;
        }
        this.loaDer.nativeElement.style.display = 'none'; 
      },
      (err)=>{
        this.statusMessage='error';
        this.responseMessage=err;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
    this.enable=false;
  }


}
