import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
// import { ResponseMessage } from '../../common/response-message';
import { UtilsService } from '../../common/utils-service';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { SearchInventoryService } from './search-inventory.service';
import { SearchForm } from './search-form';
import { SearchInventoryHeader } from './header-details';
// import { SearchInventoryLine } from './line-details';
import { Concept } from '../../common/concept';
import { Search } from './data';
// import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../../common/field-name-list';

@Component({
  selector: 'app-search-inventory',
  templateUrl: './search-inventory.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css',
  '../../../../node_modules/font-awesome/css/font-awesome.css',
    './search-inventory.component.css']
})
export class SearchInventoryComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  @ViewChild('myForm', { static: false }) ngForm: NgForm;

  @ViewChild('concept', { static: false }) conceptCodeVariable: ElementRef;
  @ViewChild('shopC', { static: false }) shopCodeVariable: ElementRef;
  @ViewChild('artT', { static: false }) articleTypeVariable: ElementRef;

  requestParameterModel: RequestParameterModel[];
  responseMessage: string;
  errormsg: string;
  currentPage: number = 1;
  inventoryModel = new SearchForm();
  dataDiv = new Search();
  searchInventory: SearchInventoryHeader[] = [];
  searchInventoryLine: any[] = [];
  searchInventoryObj = new SearchInventoryHeader();
  concepts: Concept[];
  data = new Concept();
  ShopCodes: any[] = [];
  division: string;
  userCode: string;
  showP = false;
  showL = false;
  enable = false;
  lastDelDate: string;
  assormentArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15','16','17','18','19'];
  resultArrayPacked: any[] = [];
  resultArrayLoose: any[] = [];
  result: any;
  // choices = ['PACKED', 'LOOSE'];
  types:any;
  stocks:any;
  artnumber: any;
  assortArray: any[];
  assortData: any = {};
  groupCode: string;


  pageNumber=1;
  itemsPerPage=10;
  totalItems:any;

  constructor(private searchInventoryService: SearchInventoryService, private utilsService: UtilsService,
    private navigationLinkParameterService: NavigationLinkParameterService, private router: Router) { }

    onClickBack() { this.router.navigate(['']); }
    
  ngOnInit() {

    this.inventoryModel.conceptCode = null;
    this.inventoryModel.shopCode = null;
    this.inventoryModel.artType = null;
    this.inventoryModel.stockType = null;
    this.inventoryModel.categoryCode = null;
    this.inventoryModel.subCategoryCode = null;

    this.searchInventoryService.getStatus().subscribe(
      (data: any) => {
        this.stocks = data.configParamModels;
      }
    );
    this.searchInventoryService.getArticleType().subscribe(
      (data: any) => {
        this.types = data.configParamModels;
      }
    );

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    const sessUsername = sessionStorage.getItem('currentUser');
    const conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.inventoryModel.userCode = this.userCode;
    this.searchInventoryService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        console.log(this.concepts); console.log('concepts');
      }
    );
  }

  onSelect() {
    this.loaDer.nativeElement.style.display = 'block';
    let divisionCode: string;
    this.concepts.filter(cs => cs.concCode === this.inventoryModel.conceptCode).forEach(
      div => {
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
        const json_string_search = JSON.stringify(div);
        const json_obj = JSON.parse(json_string_search);
        this.dataDiv.division = json_obj.division;
        this.division = json_obj.division;
      }
    )
    let detail = {
      'shopCloseYrWk1': '000000',
      'shopAgencyCode': divisionCode,
      'userCode': this.userCode,
      'conceptCode': this.inventoryModel.conceptCode
    }
    const requestParameterModel = this.utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    this.searchInventoryService.getLocations(requestParameterModel).subscribe(
      (res: any) => {
        this.ShopCodes = [];
        if(res.dataList!=null){
          res.dataList.forEach(
            shopCode => {
              this.enable = true;
              this.ShopCodes.push(shopCode);
            }
          );
        }
        
        console.log(this.ShopCodes); console.log('this.ShopCodes');
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  resetResults() {
    this.searchInventory = [];
    this.ShopCodes = [];
    this.responseMessage = '';
    this.errormsg = '';
    this.resultArrayLoose = [];
    this.resultArrayPacked = [];
    this.loaDer.nativeElement.style.display = 'none';
    this.enable = false;
  }

  submitSearchInventoryForm() {
    this.loaDer.nativeElement.style.display = 'block';
    
    this.inventoryModel.pageSize=this.itemsPerPage;
    this.inventoryModel.pageNumber=1; 
    this.currentPage=1;
    this.inventoryBySearchCriteria(this.inventoryModel);
  }

  inventoryBySearchCriteria(inventoryModel: SearchForm) {
    this.responseMessage = '';
    this.errormsg = '';
    this.requestParameterModel = this.utilsService.createRequestParametersModelArray(inventoryModel);
    if (this.requestParameterModel.length !== 0) {
      if (this.inventoryModel.conceptCode != null && this.inventoryModel.shopCode != null &&
        this.inventoryModel.artType != null) {
        this.searchInventoryService.getInventoryBySearchCriteria(this.requestParameterModel).subscribe(
          (response: any) => {
            // console.log(JSON.stringify(response));
            if (response.dataList.length !== 0) {
              this.searchInventory = response.dataList;
              this.totalItems=response.recordCount;
              this.searchInventory.forEach(
                dates => {
                  console.log(dates.lastDeliveryDate);
                  let d=dates.lastDeliveryDate.toLocaleString();
                  console.log(d);
                  if(d != null && d!="null"){
                  dates.lastDelDate = this.utilsService.transformDate1(dates.lastDeliveryDate);
                }}
              );
            } else {
              this.responseMessage = response.message;
              this.searchInventory = [];
            }
            this.loaDer.nativeElement.style.display = 'none';
          },
          (error) => {
            this.responseMessage = error;
            this.loaDer.nativeElement.style.display = 'none';
          }
        );
      } else {
        this.errormsg = 'Please fill all the mandatory fields.';
        this.loaDer.nativeElement.style.display = 'none';
      }
    } else {
      this.errormsg = 'Please fill any field first.';
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  getPage(page){
       this.inventoryModel.pageNumber=page;
       this.currentPage=page;
       this.inventoryBySearchCriteria(this.inventoryModel);
  
  }

  // onClickView(invenIndex) {
  //   this.searchInventory.filter(cs => cs.inventoryId === invenIndex).forEach(
  //     res => {
  //       this.result = res;
  //     }
  //   )
  //   this.resultArrayPacked = [];
  //   this.resultArrayLoose = [];
  //   this.dataDiv.packedLooseFlag = null;
  //   this.showL = true;
  //   this.showP = true;
  //   this.popUpShow(this.result);
  // }

  // onChange() {
  //   if (this.dataDiv.packedLooseFlag === 'PACKED') {
  //     this.showP = true;
  //     this.showL = false;
  //     this.popUpShow(this.result);
  //   } else if (this.dataDiv.packedLooseFlag === 'LOOSE') {
  //     this.showL = true;
  //     this.showP = false;
  //     this.popUpShow(this.result);
  //   } else {
  //     this.showL = true;
  //     this.showP = true;
  //     this.popUpShow(this.result);
  //   }
  // }

  // popUpShow(result) {

  //   for (let i = 0; i < result.inventoryDistributionModelList.length; i++) {

  //     this.resultArrayPacked[i] = [];
  //     this.resultArrayLoose[i] = [];

  //     if (result.inventoryDistributionModelList[i].packedLooseFlag === 'PACKED') {

  //       this.resultArrayPacked[i].assortmentCode = result.inventoryDistributionModelList[i].assortmentCode;
  //       this.resultArrayPacked[i].cartonQty = result.inventoryDistributionModelList[i].cartonQty;
  //       this.resultArrayPacked[i].distPairQty = result.inventoryDistributionModelList[i].distPairQty;

  //       this.resultArrayPacked[i].sizes = [];

  //       for (let k = 0; k < this.assormentArray.length; k++) {

  //         if (result.inventoryDistributionModelList[i].inventoryAssortmentsModel.length !== 0) {
  //          const index = result.inventoryDistributionModelList[i].inventoryAssortmentsModel.findIndex((x) => x.sizeLabel == this.assormentArray[j]);
  //          const obj = {};
  //           if (index > -1) {
  //             obj['pairQty'] = result.inventoryDistributionModelList[i].inventoryAssortmentsModel[index].pairQty;
  //           } else {
  //             obj['pairQty'] = '';
  //           }
  //           this.resultArrayPacked[i].sizes.push(obj);
  //         } else {
  //           let obj = {};
  //           obj['pairQty'] = '';
  //           this.resultArrayPacked[i].sizes.push(obj);
  //         }
  //       }
  //     } else if (result.inventoryDistributionModelList[i].packedLooseFlag === 'LOOSE') {

  //       this.resultArrayLoose[i].distPairQty = result.inventoryDistributionModelList[i].distPairQty;

  //       this.resultArrayLoose[i].sizes = [];

  //       for (var j = 0; j < this.assormentArray.length; j++) {

  //         if (result.inventoryDistributionModelList[i].inventoryAssortmentsModel.length !== 0) {

  //           let index = result.inventoryDistributionModelList[i].inventoryAssortmentsModel.findIndex((x) => x.sizeLabel == this.assormentArray[j]);
  //           let obj = {};
  //           if (index > -1) {
  //             obj['pairQty'] = result.inventoryDistributionModelList[i].inventoryAssortmentsModel[index].pairQty;
  //           } else {
  //             obj['pairQty'] = '';
  //           }
  //           this.resultArrayLoose[i].sizes.push(obj);
  //         } else {
  //           let obj = {};
  //           obj['pairQty'] = '';
  //           this.resultArrayLoose[i].sizes.push(obj);
  //         }
  //       }
  //     }
  //     console.log(this.resultArrayPacked);
  //     console.log(this.resultArrayLoose);
  //   }
  // }

  onClickView(artnumber,inventoryId) {
    console.log(artnumber,inventoryId);
    this.artnumber = artnumber;
    this.assortArray = [];
    let firstletter = artnumber.charAt(0);
    console.log(this.searchInventory);
    const index = this.searchInventory.findIndex((x) => x.inventoryId === inventoryId);
    console.log(index);
    if (index != -1) {
      this.assortData = this.searchInventory[index];
      console.log(this.assortData);
      let details = [{ "name": "letterOfArticle", "value": firstletter }];
      this.searchInventoryService
      .getSizeDetails(details)
      .subscribe(
        (response: any) => {
          if (response.status == "Success") {
            this.groupCode = response.dataList[0].groupCode;
            this.assortArray = [];
            response.dataList.forEach(data => {
              let preArray = this.assortData.inventoryDistributionModelList;
              console.log(preArray); console.log('preArray');
              let assortmentArray = preArray[0].inventoryAssortmentsModel;
              console.log(assortmentArray); console.log('assortmentArray');
              let found = assortmentArray.findIndex(element => element.artSize == data.artSize);
              if (found !== -1) {
                let assortmentData = { sizeBucket: data.sizeBucket, size: assortmentArray[found].artSize, sizeWiseQty: assortmentArray[found].pairQty, manualPairQty:assortmentArray[found].manualPairQty };
                this.assortArray.push(assortmentData);
              }
            });
          }
        },
        (err) => {
          this.assortArray = [];
        }
      );
    }
  }
}
