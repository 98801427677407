import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import {BilApiUrl} from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
@Injectable({
  providedIn: 'root'
})
export class BlockArticleService {


  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
  shop_location_url = RdsBilApiUrl.SEARCH_LOCATION_URL;

  constructor(private _http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }

  getLocations(requestParameterModel){
    return this._http.post(this.shop_location_url,requestParameterModel);
  }
}
