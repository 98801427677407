import { Component, OnInit } from '@angular/core';
import { UserGroupModel } from '../search-user-group/search-user-group-model';
import { CreateUserGroupService } from './create-user-group.service';
import { Router, RouterModule } from '@angular/router';
import { UserConceptDetails } from '../manage-user-concept-create/manage-user-details';
import { UserGroupSearchDetails } from '../search-user-group/search-user-group-details';
import { GroupListDetails } from '../search-user-group/user-group-list';

@Component({
  selector: 'app-create-user-group',
  templateUrl: './create-user-group.component.html',
  styleUrls: ['./create-user-group.component.css']
})
export class CreateUserGroupComponent implements OnInit {
  userGroupSearchParameter = new UserGroupModel();
  createUserGroup = new UserGroupSearchDetails();
  details: any;
  userDetailList: UserConceptDetails[] = [];
  selectedUser: number;
  selectedRow: number = 0;
  responseMessage: string;
  mt1: boolean[] = [];
  mt2: boolean[] = [];
  createUserGroupModel = new UserGroupSearchDetails();
  userGroupList: UserGroupSearchDetails[] = [];
  disableIndex: number = 0;
  userGroupPassingList: UserGroupSearchDetails[] = [];
  username: string;
  groupListDetails = {
    "groupName": ""
  }
  groupList: GroupListDetails[] = [];

  constructor(private _router: Router, private _createUserGroupService: CreateUserGroupService) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.createUserGroupModel = new UserGroupSearchDetails();
    this.userGroupList.push(this.createUserGroupModel);
    this.mt1[0] = false;
    this.mt2[0] = false;

    this._createUserGroupService.getGroupList(this.groupListDetails).subscribe(
      (response:any) => {
        this.groupList = response;
      }
    )
  }

  onClickSearchUser() {
    if (this.userGroupSearchParameter.userName == undefined) {
      this.details = {
        "userName": ""
      }
    }
    else {
      this.details = {
        "userName": this.userGroupSearchParameter.userName
      }
    }
    console.log(JSON.stringify(this.details));
    this._createUserGroupService.getUserDetails(this.details).subscribe(
      (response: UserConceptDetails[]) => {
        console.log(response);
        this.userDetailList = response;
      }
    )
  }

  settingIndexforGroupLine(index) {
    this.responseMessage = '';
    this.selectedRow = index;
  }

  addUserGroup() {
    if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
      this.createUserGroup = new UserGroupSearchDetails();
      this.userGroupList.push(this.createUserGroup);
      this.selectedRow = null;
      this.selectedUser = null;
      this.userDetailList = [];
      this.userGroupSearchParameter = new UserGroupModel();
      this.disableIndex++;
      this.responseMessage = '';
    }
  }

  onClickSaveButton() {
    this.responseMessage = '';
    this.userGroupPassingList = [];
    if (this.mt1[this.disableIndex] == true && this.mt2[this.disableIndex] == true) {
      this.userGroupList.forEach(
        conc => {
          let userConceptsPassing = new UserGroupSearchDetails();
          userConceptsPassing.groupId = conc.groupId;
          userConceptsPassing.userId = conc.userId;
          userConceptsPassing.createdBy = this.username;
          this.userGroupPassingList.push(userConceptsPassing);
        }
      );
      console.log(JSON.stringify(this.userGroupPassingList));
      this._createUserGroupService.saveUserDetails(this.userGroupPassingList).subscribe(
        (response: any) => {
          console.log(response);
          if (response.code != 'FAILED') {
            this.responseMessage = 'User Role Created Successfully';
          }
          else{
            this.responseMessage = response.message;
          }
        }
      )
    }
    else {
      this.responseMessage = 'Please fill Data First';
    }
  }

  setData(groupId, i) {
    this.responseMessage = '';
    this.userGroupList[i].groupId = groupId;
    this.mt2[this.disableIndex] = true;
  }

  onClickSubmit() {
    this.userGroupList[this.selectedRow].userName = this.userDetailList[this.selectedUser].userName;
    this.userGroupList[this.selectedRow].userId = this.userDetailList[this.selectedUser].userId;
    this.mt1[this.disableIndex] = true;

  }

  setIndexForUser(index) {
    this.responseMessage = '';
    this.selectedUser = index;
  }       

  onClickBack() {
    this._router.navigate(['']);
  }

  cancelLineDetails(index){
    if(this.disableIndex != 0){
    this.userGroupList.splice(index,1);
    this.disableIndex--;
    this.responseMessage = '';
    }
  }
}

