import { Injectable } from '@angular/core';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '../common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { EditPackMasterDetails } from './edit-pack-master';
import { SearchPackMasterDetails } from '../search-packing-master/search-pack-master-details';


@Injectable({
  providedIn: 'root'
})
export class SearchPackingMasterEditService {
  pack_master_url: string = BilApiUrl.ADD_PACK_MASTER;
  editedDataList: SearchPackMasterDetails[] = [];
  article_no_url: string = BilApiUrl.IMPORT_ARTICLE_PRICE_MASTER;
  size_master_url: string = BilApiUrl.FETCH_ASSORTMENT_BY_GROUPCODE_URL;
  apiUrl=BilApiUrl.COMMON_UPLOAD_API_URL;
  URL :string=BilApiUrl.COMMON_DOWNLOAD_URL;
  delete_pack_master_url:string = BilApiUrl.DELETE_PACK_MASTER;
  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
  category_code_url: string = BilApiUrl.CATEGORY_CODE_LIST_URL;
  arr:any[] = [];
  shop_code_url: string = BilApiUrl.SEARCH_LOCATION_URL;

  constructor(private _http: HttpClient) { }

  saveEditPackingDetails(editPackMasterDetails) {
     //this.editedDataList.push(editPackMasterDetails);
     console.log('Data that is getting edited' + JSON.stringify(editPackMasterDetails));
    return this._http.post(this.pack_master_url, editPackMasterDetails);
  }

  getArticleNumber(requestParameterModels: RequestParameterModel[]) {
    console.log('paramters for getting article number' + JSON.stringify(requestParameterModels));
    return this._http.post(this.article_no_url, requestParameterModels);
  }

  getSizeDetails(groupCode){
    console.log('****group Code**** ' +JSON.stringify(groupCode));
    return this._http.post(this.size_master_url, groupCode);
  }

  deletePackMaster(deleteDetails){
    return this._http.post(this.delete_pack_master_url, deleteDetails);
  }

  upload(formData) {
    return this._http.post(`${this.apiUrl}`, formData);
  }

  upload1(formData1) {
    return this._http.post(`${this.apiUrl}`, formData1);
  }

  public async getFile(): Promise<Blob> {
    let details = [
      { "name": "downloadKey", "value": "PACK_ASSORTED" }
    ]
    const file = await this._http.post<Blob>(this.URL, details, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  public async getFile1(): Promise<Blob> {
    const file = await this._http.post<Blob>(this.URL, [{ "name": "downloadKey", "value": "PACK_WSL_ASSORTED" }], { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }

  getCategoryCode(){
    return this._http.post(this.category_code_url, this.arr);
  }

  getShopCodeByUser(requestParameterModel){
    return this._http.post(this.shop_code_url, requestParameterModel);
  }
}
