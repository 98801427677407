
import {SearchInventoryLine} from './line-details';

export class SearchInventoryHeader {

    inventoryId:string;
    artName:string;
    artType :string;
    artNumber:number;
    artTotalQty:number;
    conceptCode:string;
    lastDeliveryDate:Date;
    lastUpdateDate:string;
    lastUpdatedBy:string;
    shopCode:string;
    stockType:string;   
    uom:string;  
    mrp:string;
    totalMrpValue:string;
    stdCost:string;
    totalStdCost:string;
    catogeryCode:string;
    catogeryName:string;
    wsp:string;
    totalWspValue:string;
    settledAmount:string;

    lastDelDate : string;
   
   
    inventoryDistributionModelList: SearchInventoryLine[]=[];

   constructor(){}

}