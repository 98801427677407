import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchGinService } from './search-gin.service';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { Search } from './search-model';
import { SearchGinHeader } from './search-gin-header';
import { ResponseMessage } from '../../common/response-message';
import { UtilsService } from '../../common/utils-service';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../../common/field-name-list';

@Component({
  selector: 'app-search-gin',
  templateUrl: './search-gin.component.html',
  // styleUrls: ['./search-gin.component.css']
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css', './search-gin.component.css']
})
export class SearchGinComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  searchModel = new Search();
  searchGinDetails: SearchGinHeader[] = [];
  responseMessage: string;
  errormsg: string;
  currentPage: number = 1;
  errorMessage: string;
  requestParameterModel: RequestParameterModel[];
  userRole: string;

  constructor(private searchGinService: SearchGinService, private _utilsService: UtilsService,
    private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    let sessUsername = sessionStorage.getItem('currentUser');
    this.userRole = JSON.parse(sessUsername)[0].groupName.split(',');
    console.log(this.userRole);
  }

  resetResults() {
    this.searchGinDetails = [];
    this.responseMessage = '';
    this.errormsg = '';
    this.loaDer.nativeElement.style.display = 'none';
  }

  submitSearchGINForm() {
    this.loaDer.nativeElement.style.display = 'block';
    this.responseMessage = '';
    this.errormsg = '';

    if (this.searchModel.invoiceFromDate != undefined || this.searchModel.invoiceToDate != undefined) {

      if (this.searchModel.invoiceFromDate != null && this.searchModel.invoiceToDate != null) {

        if (this.searchModel.invoiceFromDate.getTime() <= this.searchModel.invoiceToDate.getTime()) {
          this.ginBySearchCriteria(this.searchModel);
        }
        else {
          this.errormsg = 'From Date Should be less than or equal to To Date';
          this.loaDer.nativeElement.style.display = 'none';
        }
      }
      else {
        this.errormsg = 'Please enter both the dates';
        this.loaDer.nativeElement.style.display = 'none';
      }
    }
    else {
      this.ginBySearchCriteria(this.searchModel);
    }
  }

  ginBySearchCriteria(searchModel: Search) {
    this.responseMessage = '';

    if (this.searchModel.invoiceNumber != null || this.searchModel.consignorCode != null
      || this.searchModel.ginNumber != null || this.searchModel.invoiceFromDate != null
      || this.searchModel.invoiceToDate != null || searchModel.fromYrWk != null
      || this.searchModel.toYrWk != null) {
      this.searchGinService.getGinBySearchCriteria(searchModel).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == 'SUCCESS') {

            this.searchGinDetails = response.dataList;
            
            this.searchGinDetails.forEach(
              dates => {
                dates.invoiceDateStr = this._utilsService.transformDate(dates.invoiceDate);
                dates.createDateStr = this._utilsService.transformDate(dates.createDate);
                dates.updateButton = false;
              }
            );
          }
          else {
            this.responseMessage = response.message;
            this.searchGinDetails = [];
          }
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }

    else {
      this.errormsg = 'Please fill any field first';
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onClick(event, ginID) {

    this.responseMessage = "";
    let selectInd: any;
    this.searchGinDetails.filter(cs => cs.ginId = ginID).forEach(
      obj => {
        selectInd = obj;
      }
    );
    if (Number(event) >= Number(selectInd.shippedCartonQty)) {
      this.responseMessage = "Received carton Quantity should be less than total carton Quantity";
      selectInd.shortageCartonQty = Number(selectInd.shippedCartonQty) - Number(selectInd.receivedCartonQty);
      selectInd.updateButton=true;
    }
    else {
      selectInd.receivedCartonQty = event;
      selectInd.shortageCartonQty = Number(selectInd.shippedCartonQty) - Number(selectInd.receivedCartonQty);
      selectInd.updateButton=false;
    }
  }

  updateChanges(ginID) {
    let updInd: any;
    this.searchGinDetails.filter(cs => cs.ginId = ginID).forEach(
      obj => {
        updInd = obj;
      }
    );
    let details = [{
      "ginNumber": updInd.ginNumber,
      "invoiceNumber": updInd.invoiceNumber,
      "shopNumber": updInd.shopNumber,
      "ginId": updInd.ginId,
      "asnNumber": updInd.asnNumber,
      "invoiceDate": updInd.invoiceDate,
      "invoiceValue": updInd.invoiceValue,
      "shippedCartonQty": updInd.shippedCartonQty,
      "receivedCartonQty": updInd.receivedCartonQty,
      "shortageCartonQty": updInd.shortageCartonQty,
      "excessCartonQty": updInd.excessCartonQty,
      "consignorCode": updInd.consignorCode,
      "consignorName": updInd.consignorName,
      "consigneeCode": updInd.consigneeCode,
      "consigneeName": updInd.consigneeName,
      "ewayBillNumber": updInd.ewayBillNumber,
      "createDate": updInd.createDate,
      "lastUpdatedBy": updInd.lastUpdatedBy,
      "lastUpdated": updInd.lastUpdated,
      "bataWeek": updInd.bataWeek,
      "onwayValue": updInd.onwayValue,
      "receivedPairQty": updInd.receivedPairQty,
      "shortagePairQty": updInd.shortagePairQty,
      "totalPairs": updInd.totalPairs,
      "excessPairQty": updInd.excessPairQty,
      "grnStatus": updInd.grnStatus
    }]
    console.log(details);
    this.loaDer.nativeElement.style.display = 'block';
    this.searchGinService.updateGINData(details).subscribe(
      (response: ResponseMessage) => {
        console.log(response); 
        if (response.status == 'SUCCESS') {    
          this.responseMessage = 'Data Updated Successfully';       
      }
      else{
        this.responseMessage = 'Failed To Update Data';   
      }
      this.loaDer.nativeElement.style.display = 'none';
    }
    );
  }
}

