import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { AsndetailModel } from './asn-detailmodel';
import { AsnLines } from '../asn-lines';
import { NavigationLinkParameterService } from '../../../common/navigation-link-parameters-service';
import { AsnDetailService } from './asn-detail.service';
import { ASNDetailResponseModel } from '../../../common/asn-detail-model';
import { DatePipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { GinNumber } from '../gin-model-model';
import { CreateGINModel } from './create-gin-model';
import { LoginComponent } from '@/login/login.component';

type NewType = number;

@Component({
  selector: 'app-asn-detail',
  templateUrl: './asn-detail.component.html',
  styleUrls: ["../../../../assets/css/bootstrap.css",
  "../../../../assets/css/rds-bil-style.css",
  "../../../../../node_modules/font-awesome/css/font-awesome.css",'./asn-detail.component.css']
})
export class AsnDetailComponent implements OnInit {
  @ViewChild('loaDer', { static: true }) loaDer: ElementRef;
  searchedASNDetails: any;
  searchASNLineDetails: any[] = [];
  myDate: Date = new Date();
  ginData: AsnLines[] = [];
  responsemessage: string;
  ginNumberModel: GinNumber[] = [];
  ginCartonRecived = new CreateGINModel();
  eachObj = new AsnLines();
  total = 0;
  shortageCartonQty: NewType = 0;
  disableBtn = true;
  totalInv: number;
  sumOfInvoice = 0;
  totalInvAmut: any = 0;
  dataRefresher: any;
  cartonDetail: any;
  challanId: any;
  artnumber: any;
  assortData: any = {};
  assortData1: any = {};
  conceptQueryMas: string;
  totalInvoiceValHeader: string;
  articleFirstDigit: any;
  groupCode: any;
  articleMaster: any[] = [];
  supNo: any;
  manualGrn: any;
  supplyNo: any;
  artNum: any;
  value: any;
  arrayManualGrn:any[]=[];
  grantSum: any;
  sumofArt : any;
  assortListFull:any = {};
  assortList: any = {};
  assortcombine: any[] = [];
  assortCombineFull: any[] =[];
  sucessMsgGrn: any;
  grnWiseSum1: any[] = [];
  savePayload: any;
  currentPage:number=1;
  itemsPerPage:number=10;
  userModule:any;
  searchType='line';
  totalItems:any;
  asnPageNumber:Number;
  uniqueKeyArray:any[];
  totalCartonQty:any;
  asnType:any;
  

  // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: variable-name
  constructor( private navigationLinkParameterService: NavigationLinkParameterService,
               // tslint:disable-next-line: variable-name
               private asnDetailService: AsnDetailService,
               private datePipe: DatePipe,
               // tslint:disable-next-line: variable-name
               private router: Router) { }

  ngOnInit() {

    const sessUsername = sessionStorage.getItem('currentUser');
    this.conceptQueryMas = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.userModule = JSON.parse(sessUsername).groupAssignmentModels[0].userModule;
    // //console.log(this.navigationLinkParameterService.challanAsnDetails);
        this.asnPageNumber=this.navigationLinkParameterService.asnPageNumber;
        //console.log(this.asnPageNumber);
        this.navigationLinkParameterService.asnPageNumber=0;
        this.searchedASNDetails = this.navigationLinkParameterService.challanAsnDetails;
        // this.searchedASNDetails.totalCarton=this.navigationLinkParameterService.totalCarton;
        this.searchedASNDetails.pageNumber=1;
        this.searchedASNDetails.searchType='line';
        this.searchedASNDetails.pageSize=this.itemsPerPage;
        // //console.log(this.searchedASNDetails);

        let cartonQty=0;
        this.uniqueKeyArray=[];
        this.totalCartonQty=0;
        this.asnType = '';
        this.asnDetailService.getInvoiceLineDetails(this.searchedASNDetails,this.conceptQueryMas).subscribe(
         (response: ASNDetailResponseModel) => {
           //console.log(response);
            this.searchASNLineDetails = response.dataList;
            this.totalItems=response.recordCount;
            this.asnType = this.searchASNLineDetails[0].asnType;
            //console.log(this.totalItems);
            //this.searchedASNDetails.asnStatus = this.searchASNLineDetails[0].asnStatus;
            // this.loaDer.nativeElement.style.display = 'none';

          //   for (const data of this.searchASNLineDetails) {
          //       this.total  += data.cartonNumber;
          //       // tslint:disable-next-line: no-string-literal
          //       this.totalInvAmut   += data['invoiceValue'];
          //       this.totalInvoiceValHeader=data['invoiceValue'];
          //  }

          this.searchASNLineDetails.forEach(
            asnData => {
              let key=asnData.asnNumber;
              cartonQty=((asnData.cartonNumber!=null)?parseInt(asnData.cartonNumber):0);
              var indexofKey=this.uniqueKeyArray.indexOf(key);
              if (indexofKey === -1) {
                this.uniqueKeyArray.push(key);
                this.totalCartonQty=cartonQty;                   
            }else{
              if(asnData.consignorType === 'SUPPLIER' || asnData.consignorType === 'FACTORY'){
                this.totalCartonQty=this.totalCartonQty+cartonQty;
              }
              
            }
              
            }
          );
        }
     );

     
     this.navigationLinkParameterService.asndetails = '';

     if (this.navigationLinkParameterService.searchasnHeaderData === undefined) {
      // //console.log('New Search has to be made');
      //this.loaDer.nativeElement.style.display = 'none';
      } else {
        // //console.log('Automatic Search will be performed');
        // this.searchASNCriteriaList = this.navigationLinkParameterService.searchasnHeaderData.searchData;
        // this.uniques = this.navigationLinkParameterService.searchasnHeaderData.resultData;
        //  this.navigationLinkParameterService.searchasnHeaderData = undefined;
        // //console.log(this.searchASNCriteriaList);
        // this.searchAsnDataWithValues(this.searchASNCriteriaList);
        // this.searchPayload();
       
      }
  }


// tslint:disable-next-line: member-ordering
private setting = {
  element: {
    dynamicDownload: null as HTMLElement
  }
};


downloadTextFile() {
  this.asnDetailService.fakeValidateUserData().subscribe((res) => {
    this.dyanmicDownloadByHtmlTag({
      // tslint:disable-next-line: max-line-length
      fileName: 'INV_2D_' + this.searchedASNDetails.supplyNo + '_' + this.datePipe.transform(this.myDate, 'ddMMyyyHHmmss') + '.txt' ,
      text: ''
    });
  });

}



private dyanmicDownloadByHtmlTag(arg: {
  fileName: string,
  text: string
}) {
  if (!this.setting.element.dynamicDownload) {
    this.setting.element.dynamicDownload = document.createElement('a');
  }
  const element = this.setting.element.dynamicDownload;
  const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
  element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
  element.setAttribute('download', arg.fileName);

  const event = new MouseEvent('click');
  element.dispatchEvent(event);
}


// OnClickCreateGin() {
//   this.ginData = [];
//   this.shortageCartonQty = (this.total - this.ginCartonRecived.receivedCartonQty);
//   // this.searchASNLineDetails.forEach(
//   //  eachObj => {

//   const newObj: any = new CreateGINModel();
//   newObj.invoiceNumber = this.searchedASNDetails.supplyNo;
//   newObj.shopNumber = this.searchedASNDetails.shopNumber;
//   newObj.asnNumber = this.searchedASNDetails.asnNumber;
//   newObj.shippedCartonQty = this.total;
//   newObj.receivedCartonQty  = this.ginCartonRecived.receivedCartonQty;
//   newObj.shortageCartonQty = this.shortageCartonQty;
//   newObj.consignorCode = this.searchedASNDetails.consignorCode;
//   newObj.consignorName = this.searchedASNDetails.consignorName;
//   newObj.consigneeCode = this.searchedASNDetails.consigneeCode;
//   newObj.consigneeName = this.searchedASNDetails.consigneeName;
//   newObj.ewayBillNumber = this.searchedASNDetails.ewayBillNumber;
//   newObj.lastUpdatedBy =  this.searchedASNDetails.lastUpdatedBy;
//   newObj.invoiceValue =  this.searchedASNDetails.invoiceValue;
//   newObj.challanInvoiceDT =  this.searchedASNDetails.challanInvoiceDT;
//   this.ginData.push(newObj);

//   //console.log(JSON.stringify(this.ginData));
//   this._asnDetailService.addpost(this.ginData).subscribe(
//     (res: any) => {
//       // tslint:disable-next-line: triple-equals
//       if (res.status == 'SUCCESS') {

//       this.responsemessage = res.message;
//       this.disableBtn = false;
//       this.refreshData();
//     } else {
//       this.responsemessage = 'Gin is already created!';
//     }

//       this._asnDetailService.getGinNo(this.searchedASNDetails.supplyNo, this.searchedASNDetails.consignorCode).subscribe(
//     // tslint:disable-next-line: no-shadowed-variable
//     (res: any) => {
//       this.ginNumberModel = res.dataList;
//     }

//   );
// });
// }
2
getPage(page){
  this.searchedASNDetails.pageNumber=page;
  this.currentPage=page;
        
  this.setAsnByAsnSearchCriteria();

}

setAsnByAsnSearchCriteria(){
  this.asnDetailService.getInvoiceLineDetails(this.searchedASNDetails,this.conceptQueryMas).subscribe(
    (response: ASNDetailResponseModel) => {
       this.searchASNLineDetails = response.dataList;
       this.totalItems=response.recordCount;
       this.asnType = this.searchASNLineDetails[0].asnType;
       //this.searchedASNDetails.asnStatus = this.searchASNLineDetails[0].asnStatus;
       // this.loaDer.nativeElement.style.display = 'none';

       for (const data of this.searchASNLineDetails) {
           this.total  += data.cartonNumber;
           // tslint:disable-next-line: no-string-literal
           this.totalInvAmut   += data['invoiceValue'];
           this.totalInvoiceValHeader=data['invoiceValue'];
      }
   }
);
}

onClickBack() {
  this.navigationLinkParameterService.asnPageNumber=this.asnPageNumber;
  this.navigationLinkParameterService.asnPageNumber=1;
  //console.log(this.navigationLinkParameterService.asnPageNumber);
  this.router.navigate(['rds-asn-search']);
}


refreshData() {
  // //console.log('Page Refresh');

  this.dataRefresher =
   setTimeout(() => {
      this.ngOnInit();
      // Passing the false flag would prevent page reset to 1 and hinder user interaction
    }, 100);
}

setASNAssortmentDataPopup(artnumber,asnId) {
  this.artnumber = artnumber;
  const index = this.searchASNLineDetails.findIndex(
      (x) => x.asnId == asnId
    );
  if (index != -1) {
      this.assortData = this.searchASNLineDetails[index];
     
    }

  this.articleFirstDigit = this.artnumber.toString().slice(0,1); 
  // //console.log(this.articleFirstDigit);

  this.asnDetailService.getSizeMaster(this.articleFirstDigit).subscribe(
    (response: any) => {
      this.articleMaster = response.dataList;
      this.groupCode = response.dataList[index]; 
      // //console.log(this.articleMaster);
      // //console.log(this.groupCode);
      
    }
    );
}


manualGRNPage(SupplyNo,createGRNFlag) {
  // //console.log(SupplyNo);
  
  let selectInd: any;
  this.searchASNLineDetails.filter(cs => cs.supplyNo === SupplyNo).forEach(
    obj => {
      selectInd = obj;
     }
  );
  
  this.navigationLinkParameterService.supplyNo = selectInd;
  this.navigationLinkParameterService.createGRNFlag=createGRNFlag;
  console.log(this.navigationLinkParameterService);
  // this.navigationLinkParameterService.searchasnHeaderData = this.searchPayload();
  this.router.navigate(['/manual-grn']);

  }

  searchPayload() {
    const searchNavigationHold = {
      //searchData: this.searchASNCriteriaList,
      resultData: this.searchASNLineDetails
    };
    return searchNavigationHold;
  }

  savePairQty(articleN){
    //this.enable12 = false;
    // //console.log(articleN);
    
    this.loaDer.nativeElement.style.display = 'block';
    let i = 0;
    // //console.log(this.searchASNLineDetails);
    this.searchASNLineDetails.filter(cs => cs.artNumber === articleN).forEach(( list, indexvalue ) => {
      
      this.assortcombine =[];
 
          list.shipmentAssortment.forEach(element => {
            
            if(element.pairQty != 0 && element.pairQty != null){
                this.assortListFull =   {
                "asnAssortmentId": String(element.asnAssortmentId),
                "asnId" : String(list.asnId), 
                "artSize" : element.articleSize, 
                "pairQuantity" : String(element.pairQty),
                "sizeLabel" : element.sizeLabel, 
                "lastUpdatedBy" : this.conceptQueryMas
                
              };
              this.assortcombine.push(this.assortListFull);
            }

          });

    
   this.savePayload = 
   { 
     "asnNumber" : this.searchedASNDetails.asnNumber,
     "userCode" : this.conceptQueryMas,
     "asnList" : [{
       "asnId": String(list.asnId), 
       "pairQuantity": this.grnWiseSum1,
       "asnAssortmentList": this.assortcombine
     }] 
 
   };
  });

   
    this.asnDetailService.saveAsnDetail(this.savePayload).subscribe(
      (response: any) => {
        this.loaDer.nativeElement.style.display = 'none';
        if (response.status === 'Success'){
        this.sucessMsgGrn = response.message;
        }
      }
    )
   }
   

   grnwq(value, articleNo, sizebucket,grnindex) {
    if (value != null && value != undefined && value != '') {
      this.searchASNLineDetails.filter(cs => cs.artNumber == articleNo).forEach(
        calgrnsum => {
          
          calgrnsum.shipmentAssortment.filter(cs => cs.sizeLabel == sizebucket).forEach(
            checksum => {

              checksum.pairQty = value;
             
              // // this.calculateGrnSum(articleNo,grnindex);
              // this.searchASNLineDetails.filter(cs => cs.artNumber == articleNo).forEach(
              // // this.assortDetail.forEach(
              //   calgrnsum1 => {
             
                checksum.grnWiseSum = 0;
                calgrnsum.shipmentAssortment.forEach(
                    checksum1 => {
                     
                      checksum.grnWiseSum += Number(checksum1.pairQty);
                     });
                  this.grnWiseSum1= checksum.grnWiseSum;
                // })
              
            }
          )
          
          
        }
      )

      // //console.log(this.grnWiseSum1+'grn');
      ////console.log(this.asnWiseSum+'arn');
    }
  }


  closePopup(){
    this.sucessMsgGrn = "";
    this.ngOnInit();
  }

}

