
import { BilApiUrl } from '@/common/bil-api-url';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RouteMasterService {
  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  uploadfileURL: string = RdsBilApiUrl.UPLOAD_TO_STORAGE;
  searchURL: string = RdsBilApiUrl.SEARCH_ROUTE_MASTER;
  updateURL:string = RdsBilApiUrl.UPDATE_ROUTE_MASTER;
  generateRouteMaster: string = RdsBilApiUrl.GENERATE_ROUTE_MAStER;
  shop_location_url: string = RdsBilApiUrl.SEARCH_LOCATION_URL;
  
  constructor(private http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this.http.post(this.concept_url, conceptQueryMas);
  }
  getDownloadUrl() {

    return this.http.get(this.urlForDownloadUrl);

  }

  upload(formData) {

    return this.http.post(`${this.uploadfileURL}`, formData);

  }

  getSearch(deatils:any){
    return this.http.post(this.searchURL,deatils);
  }

  getUpdate(deatils:any){
    return this.http.put(this.updateURL,deatils);
  }

  public getGenerate(uploadKeyValues: any) {
    return this.http.post(this.generateRouteMaster, uploadKeyValues, httpOptions);
  }

  getShopCode(details){
    return this.http.post(this.shop_location_url,details);
  }

}
