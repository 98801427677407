import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PA_DATE__GREATER, FROM_PA_DATE__REQUIRED, TO_PA_DATE__REQUIRED } from '@/common/error-code-messages';
import { RequestParameterModel } from './request-parameter-model';
import { NavigationLinkParameterService } from './navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { ITEMS_PER_PAGE, CURRENT_PAGE } from './field-name-list';

/* RDS*/

// import { DatePipe } from '@angular/common';
// import { Injectable } from '@angular/core';
// import { PA_DATE__GREATER, FROM_PA_DATE__REQUIRED, TO_PA_DATE__REQUIRED } from '@/common/error-code-messages';
import { FROM_BATA_WEEK__REQUIRED, BATA_WEEK_DATE__GREATER, TO_BATA_WEEK__REQUIRED } from '@/common/error-bata-week-message';
import { INVOICE_NUMBER__REQUIRED, CONSIGNOR_CODE__REQUIRED } from './error-invoice-consigor-message';
/* Utility Service class */

@Injectable()
export class UtilsService {
      constructor(private datePipe: DatePipe, private _router: Router, private _navigationLinkParameterService: NavigationLinkParameterService) { }

      public dateFormatStr: string = 'dd-MM-yyyy';
      public dateTimeFormatStr: string = 'dd-MM-yyyy HH:MM:SS';

      //RDS
      public dateFormatStrSearch: string = 'dd/MM/yyyy';
      public dateFormatStrUpdateTrans: string = 'MM/dd/yyyy';
      public dateFormat_DD_MMM_YY: string = "dd-MMM-yy";
      public dateFormat_DD_MMM_YYYY: string = "dd-MMM-yyyy";

      private isValidDate: boolean;
      private error: any = { isError: false, errorMessage: '' };
      non_negative_char_regex_pattern = '[0-9]*$';
      transformDate(date: Date) {
            return this.datePipe.transform(date, this.dateFormatStr);
      }
      transformDateWithTime(date: Date) {
            return this.datePipe.transform(date, this.dateTimeFormatStr);
      }
      //RDS
      transformDate1(date: Date) {
            return this.datePipe.transform(date, this.dateFormatStrSearch);
      }
      transformDateMonthWise(date: Date) {
            return this.datePipe.transform(date, this.dateFormatStrUpdateTrans);
      }
      transformDate_DD_MMM_YYFormat(date: Date) {
            console.log(
                  "date dd-mmm-yy " +
                  this.datePipe.transform(date, this.dateFormat_DD_MMM_YY)
            );
            return this.datePipe.transform(date, this.dateFormat_DD_MMM_YY);
      }
      getCurrentDateTimeFormat(rundate) {
            let currentDate = new Date(rundate);
            let year = currentDate.getFullYear();
            let mnth = ("0" + (currentDate.getMonth() + 1)).slice(-2);
            let day = ("0" + currentDate.getDate()).slice(-2);
            let hour = currentDate.getHours().toString();
            if (hour.length == 1) {
                  hour = '0' + hour;
            }
            let minutes = currentDate.getMinutes().toString();
            if (minutes.length == 1) {
                  minutes = '0' + minutes;
            }
            let seconds = currentDate.getSeconds().toString();
            if (seconds.length == 1) {
                  seconds = '0' + seconds;
            }
            let time = hour + ':' + minutes + ':' + seconds;
            let currentTimeStamp = day + '-' + mnth + '-' + year + ' ' + time;
            return currentTimeStamp;
      }

      getCurrentFormatDate() {
            let currentDate = new Date();
            console.log(currentDate);
            let year = currentDate.getFullYear();
            let mnth = ("0" + (currentDate.getMonth() + 1)).slice(-2);
            let day = ("0" + currentDate.getDate()).slice(-2);
            let hour = currentDate.getHours().toString();
            if (hour.length == 1) {
                  hour = '0' + hour;
            }
            let minutes = currentDate.getMinutes().toString();
            if (minutes.length == 1) {
                  minutes = '0' + minutes;
            }
            let seconds = currentDate.getSeconds().toString();
            if (seconds.length == 1) {
                  seconds = '0' + seconds;
            }
            let time = hour + minutes + seconds;
            let currentTimeStamp = day + mnth + year + '_' + time;
            return currentTimeStamp;
      }

      monthFortnightValidations(value, type, fromToFlag) {
            let weekValue: any = value.substring(4, 6);
            fromToFlag = fromToFlag.substr(0, 1) + fromToFlag.substr(1).toLowerCase();
            type = type.substr(0, 1).toUpperCase() + type.substr(1).toLowerCase();
            if ((type.charAt(0) == 'M') && (Number(weekValue) < 1 || Number(weekValue) > 12)) {
                  console.log('MONTH');
                  return fromToFlag + ' Bata ' + type + ' should be in between 01 and 12';
            } else if ((type.charAt(0) == 'F') && (Number(weekValue) < 1 || Number(weekValue) > 26)) {
                  console.log('FORTNIGHT');
                  return fromToFlag + ' Bata ' + type + ' should be in between 01 and 26';
            } else if ((type.charAt(0) == 'W') && (Number(weekValue) < 1 || Number(weekValue) > 53)) {
                  console.log('WEEK');
                  return fromToFlag + ' Bata ' + type + ' should be in between 01 and 53';
            } else {
                  return 'success';
            }
      }

      transformDateSearchchange(date: Date) {
            return this.datePipe.transform(date, this.dateFormat_DD_MMM_YY);
      }
      transformDateTo(date: Date) {
            return this.datePipe.transform(date, this.dateFormat_DD_MMM_YYYY);
      }

      WeekYearValidationCheck(fromWeek, toWeek, fromFieldName, toFieldName) {
            console.log('fromweek ' + fromWeek + '  ' + 'toWeek ' + toWeek);
            let weekFromValue: any = fromWeek.substring(4, 6);
            let weekToValue: any = toWeek.substring(4, 6);
            if (fromWeek.length < 6) {
                  return 'Please select ' + fromFieldName + ' in correct format';
            } else if (toWeek.length < 6) {
                  return 'Please select ' + toFieldName + ' in correct format';
            } else if (Number(weekFromValue) < 1 || Number(weekFromValue) > 53) {
                  return fromFieldName + ' should be in between 01 and 53';
            } else if (Number(weekToValue) < 1 || Number(weekToValue) > 53) {
                  return toFieldName + ' should be in between 01 and 53';
            } else if (Number(fromWeek) > Number(toWeek)) {
                  return fromFieldName + ' should be less than or equal to ' + toFieldName;
            } else {
                  return 'success';
            }
      }


      calculateDecimalValue(value): string {
            console.log('total value    ' + value);
            let val = value.toString();
            let index = val.indexOf('.');
            let afterDecimal;
            if (index >= 0) {
                  let valueList = val.split('.');
                  if (valueList[1].length >= 2) {
                        afterDecimal = valueList[1].substr(0, 2);
                  }
                  else {
                        afterDecimal = valueList[1] + '0';
                  }
                  let resultValue = valueList[0] + '.' + afterDecimal;
                  console.log('result' + resultValue);
                  return resultValue;
            }
            else {
                  return val;
            }
      }

      validateDates(sDate: any, eDate: any) {
            this.isValidDate = true;
            console.log(' validateDates sDate : ' + sDate);
            console.log(' validateDates eDate : ' + eDate);
            if ((sDate == null && eDate != null)) {
                  this.isValidDate = false;
                  this.createErrorMessage(FROM_PA_DATE__REQUIRED);
            }

            if ((sDate != null && eDate == null)) {
                  this.isValidDate = false;
                  this.createErrorMessage(TO_PA_DATE__REQUIRED);
            }

            if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
                  this.isValidDate = false;
                  console.log(' Error MSG : ' + JSON.stringify(this.error))
                  this.createErrorMessage(PA_DATE__GREATER);
            }
            console.log(' validateDates : ' + this.isValidDate)
            console.log(' Error MSG : ' + JSON.stringify(this.error))
            return this.isValidDate;

      }

      createErrorMessage(errorMessage: string) {
            return this.error = { isError: true, errorMessage: errorMessage };
      }
      //RDS

      createErrorMessageBataWeek(errorMessage: string) {
            return this.error = { isError: true, errorMessage: errorMessage };
      }

      createErrorMessageInvocie(errorMessage: string) {
            return this.error = { isError: true, errorMessage: errorMessage };
      }

      /* called called Coponent classes to fetch Error Details*/
      fetchErrorMessage() {
            return this.error;
      }


      createRequestParametersModelArray(requestParameterModel: any): RequestParameterModel[] {
            let requsetParameters = new Array<RequestParameterModel>();
            let jsonRequisition = JSON.stringify(requestParameterModel);
            console.log(jsonRequisition);
            let jsonObject = JSON.parse(jsonRequisition);
            for (var i in jsonObject) {
                  let requestParameterModel = new RequestParameterModel();
                  if (jsonObject[i] !== '' && jsonObject[i] !== null && jsonObject[i] !== 'undefined') {
                        requestParameterModel.name = i;
                        requestParameterModel.value = jsonObject[i];
                        requsetParameters.push(requestParameterModel);
                  }
            }
            requsetParameters.forEach(
                  params => console.log(' requisition header params name ' + params.name + ' value ' + params.value)
            );
            //console.log('  createRequestModelArray : requsetParameters :'+requsetParameters);
            return requsetParameters;
      }

      filterNullAndEmptyJsonObj(formData: any) {
            let param = [];
            Object.keys(formData).forEach((key) => (formData[key] == null || formData[key] == 'null' || formData[key] == '') && delete formData[key]);
            this.createRequestParametersModelArray(formData)
                  .filter(p => p.value != '' && p.value != null)
                  .forEach(e => param.push(e));
            return param;
      }


      /* function to  page navigate with parameter
         input parameter : routing path and data to populate 
      */

      pageNavigationWithParam(routingPath: string, data: RequestParameterModel[]) {
            console.log('  pageNavigationWithParam : input : routingPath :' + routingPath + '  data : ' + data);
            this._navigationLinkParameterService.details = data;
            this._router.navigate([routingPath]);
      }

      getAnsoluteIndexOfPagination(index) {
            return ITEMS_PER_PAGE * (CURRENT_PAGE - 1) + index;
      }

      validateNumber(n: any) {
            return Math.sign(n);
      }

      ///RDS

      // Added by Seema	  
      transformDateSearch(date: Date) {
            return this.datePipe.transform(date, this.dateFormatStrSearch);
      }

      validateDatesSearch(sDate1: any, eDate1: any) {
            this.isValidDate = true;
            console.log(' validateDates sDate : ' + sDate1);
            console.log(' validateDates eDate : ' + eDate1);
            if ((sDate1 == null && eDate1 != null)) {
                  this.isValidDate = false;
                  this.createErrorMessage(FROM_PA_DATE__REQUIRED);
            }

            if ((sDate1 != null && eDate1 == null)) {
                  this.isValidDate = false;
                  this.createErrorMessage(TO_PA_DATE__REQUIRED);
            }

            if ((sDate1 != null && eDate1 != null) && (eDate1) < (sDate1)) {
                  this.isValidDate = false;
                  console.log(' Error MSG : ' + JSON.stringify(this.error))
                  this.createErrorMessage(PA_DATE__GREATER);
            }
            console.log(' validateDates : ' + this.isValidDate)
            console.log(' Error MSG : ' + JSON.stringify(this.error))
            return this.isValidDate;

      }



      validateBataWeek(sBata: any, eBata: any) {
            this.isValidDate = true;
            console.log(' validatFromBata Week : ' + sBata);
            console.log(' validatToBata Week : ' + eBata);
            if ((sBata == 0 && eBata != null)) {
                  this.createErrorMessageBataWeek(FROM_BATA_WEEK__REQUIRED);
                  this.isValidDate = false;

            }

            if ((sBata != 0 && eBata == null)) {
                  this.createErrorMessageBataWeek(TO_BATA_WEEK__REQUIRED);
                  this.isValidDate = false;

            }

            if ((sBata != null && eBata != null) && (eBata) < (sBata)) {
                  this.isValidDate = false;
                  console.log(' Error MSG : ' + JSON.stringify(this.error))
                  this.createErrorMessageBataWeek(BATA_WEEK_DATE__GREATER);
            }
            console.log(' validatBata Week: ' + this.isValidDate)
            console.log(' Error MSG : ' + JSON.stringify(this.error))
            return this.isValidDate;

      }


      validateInvoiceCosignorCode(SNo: any, ENo: any) {
            this.isValidDate = true;
            console.log(' Invoice Number : ' + SNo);
            console.log(' Consignor Code : ' + ENo);
            if ((SNo == null && ENo != null)) {
                  this.isValidDate = false;
                  this.createErrorMessageInvocie(INVOICE_NUMBER__REQUIRED);
                  console.log(' Error MSG : ' + JSON.stringify(this.error))

            }

            if ((SNo != null && ENo == null)) {
                  this.isValidDate = false;
                  this.createErrorMessageInvocie(CONSIGNOR_CODE__REQUIRED);
                  console.log(' Error MSG : ' + JSON.stringify(this.error))


            }

            return this.isValidDate;

      }


}
