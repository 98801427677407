import { Component, OnInit } from '@angular/core';
import { ManageUserConceptService } from './manage-user-concept.service';
import { UserConceptModel } from './manage-user-concept-model';
import { Concept } from '../common/concept';
import { UserConceptSearchDetails } from './manage-user-concept-details';
import { Router, RouterModule } from '@angular/router';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../common/field-name-list';

@Component({
  selector: 'app-manage-user-concept',
  templateUrl: './manage-user-concept.component.html',
  styleUrls: ['./manage-user-concept.component.css']
})
export class ManageUserConceptComponent implements OnInit {
  userConceptParameter = new UserConceptModel();
  username: string;
  concepts: Concept[];
  userConcepts: UserConceptSearchDetails[] = [];
  responseMessage: string;
  currentPage: number = 1;

  constructor(private _manageUserConceptService: ManageUserConceptService,
    private _router: Router) { }

  ngOnInit() {

    let sessUsername = sessionStorage.getItem('currentUser');
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this._manageUserConceptService.getUserConcept().subscribe(
      (data: any) => {
        console.log(data);
        this.concepts = data;
      }
    )
  }

  onClickSearch() {
    this.responseMessage = '';
    if (this.userConceptParameter.userName != undefined || this.userConceptParameter.conceptCode != undefined) {
      if (this.userConceptParameter.conceptCode == null) {
        this.userConceptParameter.conceptCode = undefined;
      }
      if (this.userConceptParameter.userName == null) {
        this.userConceptParameter.userName = undefined;
      }
      this._manageUserConceptService.getUserDetailBySearch(this.userConceptParameter).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          this.userConcepts = response.dataList;
          if (this.userConcepts.length == 0) {
            console.log('No Data');
            this.responseMessage = 'Data Not Found';
          }
        }
      )
    }
    else{
      this.responseMessage = 'Please give a search criteria';
    }
  }

  resetSearch() {
    this.responseMessage = '';
    this.userConcepts = [];
    this.userConceptParameter = new UserConceptModel();
  }

  checkFlag(flag) {
    if (flag != 'I') {
      return true;
    }
    else {
      return false;
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  changeFlagStatus(index) {
    this.responseMessage = '';
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log('index ---> '+absoluteIndex);
    if (this.userConcepts[absoluteIndex].activeFlag == 'A') {
      this.userConcepts[absoluteIndex].activeFlag = 'I';
    }
    else {
      this.userConcepts[absoluteIndex].activeFlag = 'A';
    }
    let details = {
      "conceptCode": this.userConcepts[absoluteIndex].conceptCode,
      "userId": this.userConcepts[absoluteIndex].userId,
      "statusFlag": this.userConcepts[absoluteIndex].activeFlag,
      "updatedBy": this.username
    }
    this._manageUserConceptService.updateUserFlagStatus(details).subscribe(
      (response: any) => {
        console.log(JSON.stringify(response));
        if (response.status == 200) {
          this.responseMessage = 'User Concept Flag Status Updated';
        }
        else {
          this.responseMessage = 'Something went wrong';
        }
      }
    )
  }
}
