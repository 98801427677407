import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { fromEvent } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PendingNilStockService {

  configDetailsUrl: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  searchNilSockUrl: string = RdsBilApiUrl.SEARCH_NIL_STOCK;
  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  shop_location_url = RdsBilApiUrl.SEARCH_LOCATION_URL;


  constructor(private http: HttpClient) { }

  getStockType(){
    const details: any = [{
      'name': 'paramName',
      'value': 'INVENTORY_STATUS'
  }]
    return this.http.post(this.configDetailsUrl, details);
  }

  nilStockSearch(searchParam:any[]){
    return this.http.post(this.searchNilSockUrl,searchParam);
  }

  getConcepts(conceptQueryMas) {
    return this.http.post(this.concept_url, conceptQueryMas);
  }

  getLocations(requestParameterModel){
    console.log(requestParameterModel);
    
    return this.http.post(this.shop_location_url,requestParameterModel);
  }
  
}
