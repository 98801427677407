import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { UtilsService } from '../../common/utils-service';
import { Router, RouterModule } from '@angular/router';
import { Concept } from '../../common/concept';
import { StockAdjustmentService } from './stock-adjustment.service';
import { SearchForm } from './search-form';
import { Search } from './data';

@Component({
  selector: 'app-stock-adjustment',
  templateUrl: './stock-adjustment.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', '../../../../node_modules/font-awesome/css/font-awesome.css',
  './stock-adjustment.component.css']
})
export class StockAdjustmentComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  concepts: Concept[];
  division: string;
  userCode: string;
  ShopCodes: any[] = [];
  stockModel = new SearchForm();
  dataDiv = new Search();
  enable: boolean = false;

  constructor(private stockAdjustmentService: StockAdjustmentService,private _utilsService: UtilsService) { }

  ngOnInit() {

    this.stockModel.conceptCode = null;
    this.stockModel.shopCode = null;

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{"name":"email","value":JSON.parse(sessUsername).groupAssignmentModels[0].userCode}];
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.stockAdjustmentService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
      }
    );
  }

  onSelect() {

    let divisionCode: string;
    this.concepts.filter(cs => cs.concCode == this.stockModel.conceptCode).forEach(
      div => {
        if (div.division == 'RETAIL') {
          divisionCode = '5';
        }
        else {
          divisionCode = '4';
        }
        let json_string_search = JSON.stringify(div);
        let json_obj = JSON.parse(json_string_search);
        this.dataDiv.division = json_obj.division;
        this.division = json_obj.division;
      }
    )
    let detail = {
      "shopCloseYrWk1": "000000",
      "shopAgencyCode": divisionCode,
      "userEmail": this.userCode,
      "conceptCode": this.stockModel.conceptCode
    }
    let requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    this.stockAdjustmentService.getLocations(requestParameterModel).subscribe(
      (res: any) => {
        this.ShopCodes = [];
        res.dataList.forEach(
          shopCode => {
            this.enable = true;
            this.ShopCodes.push(shopCode);
          }
        );
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

}
