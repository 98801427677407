import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { UtilsService } from '../../common/utils-service';
import { Router, RouterModule } from '@angular/router';
import { Concept } from '../../common/concept';
import { UnblockArticleService } from './unblock-article.service';
import { unBlockArticleForm } from './unblock-form';
import { Search } from './data';


@Component({
  selector: 'app-unblock-article',
  templateUrl: './unblock-article.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css', './unblock-article.component.css']
})
export class UnblockArticleComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  concepts: Concept[];
  segment: string;
  userCode: string;
  shopLoactions: any[] = [];
  articleModel = new unBlockArticleForm();
  dataDiv = new Search();
  enable: boolean = false;
  constructor(private UnblockArticleService: UnblockArticleService, private _utilsService: UtilsService, private _router: Router) { }

  ngOnInit() {
    this.articleModel.concept = null;
    this.articleModel.shopLoaction = null;

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{"name":"email","value":JSON.parse(sessUsername).groupAssignmentModels[0].userCode}];
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;

    this.UnblockArticleService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
      }
    );
  }

  onSelect() {

    let divisionCode: string;
    this.concepts.filter(cs => cs.concCode == this.articleModel.concept).forEach(
      div => {
        if (div.division == 'RETAIL') {
          divisionCode = '5';
        }
        else {
          divisionCode = '4';
        }
        let json_string_search = JSON.stringify(div);
        let json_obj = JSON.parse(json_string_search);
        this.dataDiv.division = json_obj.division;
        this.segment = json_obj.division;
      }
    )
    let detail = {
      "shopCloseYrWk1": "000000",
      "shopAgencyCode": divisionCode,
      "userEmail": this.userCode,
      "conceptCode": this.articleModel.concept
    }
    console.log(detail);
    let requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    this.UnblockArticleService.getLocations(requestParameterModel).subscribe(
      (res: any) => {
        this.shopLoactions = [];
        res.dataList.forEach(
          shopLoaction => {
            this.enable = true;
            this.shopLoactions.push(shopLoaction);
          }
        );
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  onClickBack() {

    this._router.navigate(['']);
  }

}
