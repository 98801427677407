export class GRNHeader {
    
    invoiceNumber:string;
    consignorCode:string;
    grnNumber:string;
    asnNumber:string;
    partyNumber:string;
    userCode:string;
    bataWeekFrom:string;
    bataWeekTo:string;
    searchType:string;
    pageNumber:Number;
    pageSize:Number;
    recordCount:any;
    fromGrnCreateDate:string;
    toGrnCreateDate:string;
   
    constructor(){}
}