import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import { throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { BilApiUrl } from '@/common/bil-api-url';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class BulkRecallService {

  apiUrl=BilApiUrl.COMMON_UPLOAD_API_URL;
  URL :string=BilApiUrl.COMMON_DOWNLOAD_URL;
  urlForDownloadUrl:string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  generateImportPo:string=BilApiUrl.BULK_RECALL;

  constructor(private http:HttpClient) { }

  upload(formData) {
    return this.http.post(`${this.apiUrl}`, formData);
  }
  
  getDownloadUrl(){
    return this.http.get(this.urlForDownloadUrl);
  }

  public getGenerate(uploadKeyValues:any){
    return this.http.post(this.generateImportPo,uploadKeyValues, httpOptions);
  }
}

