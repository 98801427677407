import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { PurchaseOrderHeaderWithLines } from '../po-header-with-lines'
import { RequestParameterModel } from '../../common/request-parameter-model'
import { BilApiUrl } from '../../common/bil-api-url';
import { PO_NUMBER } from '@/common/field-name-list';
import { DeleteDetails } from '@/common/delete-details';

@Injectable({
  providedIn: 'root'
})
export class UpdatePoService {

  purchase_order_details_url: string = BilApiUrl.PO_DETAILS_URL;
  //purchase_order_details_url: string ='../assets/static-data/po-view-update.json';
  delete_po_url = BilApiUrl.DELETE_DETAILS;
  update_po_url = BilApiUrl.UPDATE_DETAILS;
  update_import_po_url = BilApiUrl.IMPORT_PO_UPDATE;
  update_other_po_url = BilApiUrl.OTHER_PO_UPDATE;
  save_podetails_url: string = BilApiUrl.DELETE_DETAILS;
  date_change_url: string = BilApiUrl.DATE_CHANGE;
  configData: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  searchedPoDetailsByService = new PurchaseOrderHeaderWithLines();
  constructor(private _http: HttpClient) { }

  /*
 This class call the PO api service which return the matching 
 purchase orders as per the search criteria.
 */
  getPurchaseOrderBySearchCriteria(purchaseOrderNumber: string) {
    console.log("poNumber" + purchaseOrderNumber);
    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = PO_NUMBER;
    requestParameterModel.value = purchaseOrderNumber;
    purchase_order_search_parameters.push(requestParameterModel);
    console.log(' getPurchaseOrderBySearchCriteria : purchase_order_search_parameters : ' + JSON.stringify(purchase_order_search_parameters));
    return this._http.post(this.purchase_order_details_url, purchase_order_search_parameters);
  }
  getPoDeleted(delete_purchase_order_parameters: DeleteDetails) {
    //  console.log('Details getting passed to service'+JSON.stringify(delete_purchase_order_parameters));
    console.log('Header id that is getting deleted' + delete_purchase_order_parameters.id);
    return this._http.post(this.delete_po_url, delete_purchase_order_parameters);
  }

  getPoUpdated(update_purchase_order_parameters){
    console.log('Line id that is getting updated'+update_purchase_order_parameters.id);
    return this._http.post(this.update_po_url, update_purchase_order_parameters);
  }

  getPoDetailsView(orderType,view_updated_purchase_order_parameters){
    if(orderType == 'I')
    return this._http.post(this.update_import_po_url, view_updated_purchase_order_parameters); 
    else
    return this._http.post(this.update_other_po_url, view_updated_purchase_order_parameters); 

  }
  saveLineDetails(savingDetails) {
    return this._http.post(this.save_podetails_url, savingDetails);
  }

  getWeekAndYear(sendingdate) {
    let details = {
      "name": "date",
      "value": sendingdate
    }
    return this._http.post(this.date_change_url, details);
  }

  configParamList(){
    return this._http.get(this.configData);
  }
}
