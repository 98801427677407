import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup } from "@angular/forms";
import { Observable, of, throwError, from } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";

import { RequestParameterModel } from "../../common/request-parameter-model";
import { ResupplyLine } from "./resupply-lines";
import { ResupplyHeader } from "./resupply-header";
import { RdsBilApiUrl } from "../../common/rds-bil-api-url";
import { UtilsService } from "../../common/utils-service";
import { BilApiUrl } from '@/common/bil-api-url';

@Injectable({
  providedIn: "root",
})
export class ResupplyService {
  /* ---------------------------- API URL's ------------------------*/

  asnUrl: string = RdsBilApiUrl.RDC_SEARCH_LINES;
  bataWeekURL: string = RdsBilApiUrl.RDC_BATA_WEEK_LIST;
  UPLOAD_TO_STORAGE: string = RdsBilApiUrl.UPLOAD_TO_STORAGE;
  SEARCH_RESUPPLY: string = RdsBilApiUrl.SEARCH_RESUPPLY;
  UPDATE_FULFILL_QTY: string = RdsBilApiUrl.UPDATE_FULFILL_QTY;
  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  status_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  //  updated by priti dated 01-10-20
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  //  updated by priti dated 01-10-20
  searchURL: string = RdsBilApiUrl.SEARCH_ROUTE_MASTER;
  cancelResupplyURL : string = RdsBilApiUrl.CANCEL_RESUPPLY;
  manualDispatchURL: string = RdsBilApiUrl.MANUAL_DISPATCH;
  /* ---------------------------- API URL's ------------------------*/

  form: FormGroup;
  frombataWeekzero: any;
  tobataWeekzero: any;
  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  /*-------------------- POST posts from the server -----------------------*/

  getResupplySearchCriteria(requisitionFormData) {
    // tslint:disable-next-line: variable-name
    const requisition_search_parameters = new Array<RequestParameterModel>();
    /* Prepare Request Parameter Start */
    const jsonRequisition = JSON.stringify(requisitionFormData);
    const jsonObject = JSON.parse(jsonRequisition);
    // tslint:disable-next-line: forin
    for (const i in jsonObject) {
      const requestParameterModel = new RequestParameterModel();

      if (
        jsonObject[i] !== "" &&
        jsonObject[i] !== null &&
        jsonObject[i] !== "undefined"
      ) {
        requestParameterModel.name = i;

        if ("toresupplydate".includes(requestParameterModel.name)) {
          requestParameterModel.value = this.utilsService.transformDateSearch(
            requisitionFormData.toresupplydate
          );
        } else if ("fromresupplydate".includes(requestParameterModel.name)) {
          requestParameterModel.value = this.utilsService.transformDateSearch(
            requisitionFormData.fromresupplydate
          );
        } else if ("fromresupplydate".includes(requestParameterModel.name)) {
          requestParameterModel.value = this.utilsService.transformDateSearch(
            requisitionFormData.fromresupplydate
          );
        } else if ("toresupplydate".includes(requestParameterModel.name)) {
          requestParameterModel.value = this.utilsService.transformDateSearch(
            requisitionFormData.toresupplydate
          );
        } else {
          requestParameterModel.value = jsonObject[i];
        }

        requisition_search_parameters.push(requestParameterModel);
      }
    }
    console.log(
      " Log File : SearchResupplyService  Function : getResupplySearchCriteria : Search Resupply Parameters :  " +
        JSON.stringify(requisition_search_parameters)
    );
    /* Prepare Request Parameter End */

    return this.http.post(this.SEARCH_RESUPPLY, requisition_search_parameters);
  }

  /*-------------------- Assortment Service-------------------------*/
  getResupplyAssortmentPopup(
    itnRsnSerialNumber: ResupplyHeader,
    artNumber: ResupplyHeader
  ) {
    // tslint:disable-next-line: variable-name
    const requisition_search_parameters = new Array<RequestParameterModel>();
    const jsonRequisition = JSON.stringify({ itnRsnSerialNumber, artNumber });
    // console.log(jsonRequisition);
    const jsonObject = JSON.parse(jsonRequisition);
    // console.log(jsonObject);
    // tslint:disable-next-line: forin
    for (const i in jsonObject) {
      const requestParameterModel = new RequestParameterModel();

      if (
        jsonObject[i] !== "" &&
        jsonObject[i] !== null &&
        jsonObject[i] !== "undefined"
      ) {
        requestParameterModel.name = i;
        requestParameterModel.value = jsonObject[i];
        requisition_search_parameters.push(requestParameterModel);
      }
    }
    console.log(
      " Log File : SearchResupplyService  Function : getResupplySearchCriteria : Search Resupply Parameters :  " +
        JSON.stringify(requisition_search_parameters)
    );
    /* Prepare Request Parameter End */

    return this.http.post(this.SEARCH_RESUPPLY, requisition_search_parameters);
  }

  /*-------------------- Assortment Service--------------------*/

  /*-------------------- Error service -------------------------*/
  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

  /*-------------------- Error service -------------------------*/

  /*-------------------- Upload Excel service ------------------*/
  upload(formData) {
    console.log(formData);

    return this.http.post(`${this.UPLOAD_TO_STORAGE}`, formData);
  }

  /*-------------------- Upload Excel service -------------------------*/

  /*-------------------- Update FulFill Quantity service --------------*/

  updateFuilfilQuantity(updateData) {
    console.log(JSON.stringify(updateData));
    return this.http.post(this.UPDATE_FULFILL_QTY, updateData);
  }

  /*-------------------- Update FulFill Quantity service ------------------*/

  /*--------------------  Download Text Service  -------------------------*/

  fakeValidateUserData(): any {
    return of("");
  }

  /*-------------------- Download Text Service -------------------------*/

  getConcepts(conceptQueryMas) {
    return this.http.post(this.concept_url, conceptQueryMas);
  }

  getStatus(details) {
    return this.http.post(this.status_url, details);
  }
  //  updated by priti dated 01-10-20
  getSizeDetails(details) {
    return this.http.post(this.size_url, details);
  }
  //  updated by priti dated 01-10-20
  getRouteNumber(deatils:any){
    return this.http.post(this.searchURL,deatils);
  }
  cancelResupply(details:any){
    return this.http.post(this.cancelResupplyURL,details);
  }
  manualDispatch(details:any){
    return this.http.post(this.manualDispatchURL,details);
  }
}
