import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray,Validator, Validators } from '@angular/forms';
import { ManageSizeMasterCreateService } from '@/manage-size-master-create/manage-size-master-create.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-manage-size-master-create',
  templateUrl: './manage-size-master-create.component.html',
  styleUrls: ['./manage-size-master-create.component.css']
})
export class ManageSizeMasterCreateComponent implements OnInit {
  createSizeMasterForm: FormGroup;
  sessUsername: string;
  responseMessage: string;
  letterOfArticle = [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' }
  ];
  groupCode = [ 
    { id: 1, name: 'A' },
    { id: 2, name: 'B' },
    { id: 3, name: 'C' },
    { id: 4, name: 'D' },
    { id: 5, name: 'E' }
];
  added = false;
  constructor(private _manageSizeMasterCreateService: ManageSizeMasterCreateService, private fb: FormBuilder,private _router: Router) { 
    this.createSizeMasterForm = this.fb.group({
      sizemasters: this.fb.array([])
    });
    this.buildSizeMasterForm();
  }

  ngOnInit() {
  }

  buildSizeMasterForm() {
    let control = this.getSizeMasterContol();
    return control.push(this.fb.group(
      {
        sizeBucket: ['', Validators.required],
        letterOfArticle: [null, Validators.required],
        grpDesc: ['', Validators.required],
        groupCode: [null, Validators.required],
        artSize: ['', Validators.required]
      }
    ));
  }

  addSizeMaster() {
    let control = this.getSizeMasterContol();
    if (this.createSizeMasterForm.invalid) {
      this.added = true;
      return;
    } else{
      this.added = false;
    }
    return control.push(this.fb.group(
      {
        sizeBucket: ['', Validators.required],
        letterOfArticle: [null, Validators.required],
        grpDesc: ['', Validators.required],
        groupCode: [null, Validators.required],
        artSize: ['', Validators.required]
      }
    ));
  }
 

  deleteSizeMaster(sizemaster_index: any) {
    let control = this.getSizeMasterContol();
    control.removeAt(sizemaster_index);
  }
  
  getSizeMasterContol(){
    let control = <FormArray>this.createSizeMasterForm.controls.sizemasters;
    return control;
  }

  submitSizeMaster(formData:any){
    this.sessUsername = sessionStorage.getItem('currentUser');
    let userName = JSON.parse(this.sessUsername).groupAssignmentModels[0].userCode;
    //console.log(formData);
    let dataSet: any = [];
    formData.sizemasters.forEach(
      sizemaster => {
        let data: any = {
          artSize: sizemaster.artSize,
          letterOfArticle: sizemaster.letterOfArticle,
          grpDesc: sizemaster.grpDesc,
          groupCode: sizemaster.groupCode,
          sizeBucket: sizemaster.sizeBucket,
          createdBy : userName,
          lastUpdatedBy : userName
        }
        dataSet.push(data);
      }
    );
    console.log(dataSet);
    this._manageSizeMasterCreateService.addSizeMaster(dataSet).subscribe(
      (response: any) => {
        console.log(response);
        this.responseMessage =  response.message;
        // if(response.status!='ERROR'){
        //   this._router.navigate(['']); // Redirect to home page.
        // }
      }
    );
   
  }


  // [
  //   {
  //   "artSize":"100",
  //   "letterOfArticle":"0",
  //   "grpDesc":"Children",
  //   "groupCode":"D",
  //   "sizeBucket":"6",
  //   "createdBy":"ADMIN",
  //   "lastUpdatedBy":"ADMIN"
  //   }
    
  //   ]
}
