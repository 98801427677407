import { Component, OnInit } from '@angular/core';
import { ManageConceptMasterService } from './manage-concept-master.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@/common/utils-service';
import { ManageConceptMasterResponse } from './manage-concept-master-response';
import { NO_RESULT_FOUND } from '../common/field-name-list';
import { SearchRequisitionService } from '@/search-requisition/search-requisition.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RequestParameterModel } from '@/common/request-parameter-model';
@Component({
  selector: 'app-manage-concept-master',
  templateUrl: './manage-concept-master.component.html',
  styleUrls: ['./manage-concept-master.component.css']
})
export class ManageConceptMasterComponent implements OnInit {
  conceptMasterForm: FormGroup;
  manageConceptMasterResponse: any;
  no_result_found: any = NO_RESULT_FOUND;
  responseMessage: string;
  concepts: any;
  noSearchMessage:string;
  constructor(private _manageConceptMasterService: ManageConceptMasterService, private fb: FormBuilder,
    private _utilsService: UtilsService) {
    this.conceptMasterForm = this.createManageConceptMasterForm();
  }

  createManageConceptMasterForm() {
    let serachSizeMasterFields = this.fb.group( 
      {
        division : [null],
        concept : [''],
        concCode : [null],
        distConceptCode : ['']
       }
    ) ;

   return serachSizeMasterFields;
    // let serachConceptMasterFields = {};
    // serachConceptMasterFields['division'] = [null];
    // serachConceptMasterFields['concept'] = '';
    // serachConceptMasterFields['concCode'] = [null];
    // serachConceptMasterFields['distConceptCode'] = '';
    // //    serachConceptMasterFields['Search'] = 'Search';
    // return this.fb.group(serachConceptMasterFields);
  }

  ngOnInit() {
    this.fetchConceptCode();
  }

  //  notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  //   return value !== null && value !== undefined;
  //  }

  submitConceptMasterForm(formData: any) {
    // const array: (string | null)[] = ['foo', 'bar', null, 'zoo', null];
    // const filteredArray: string[] = array.filter(this.notEmpty);
    // console.log(JSON.stringify(filteredArray));
    this.noSearchMessage = '';
    let manage_concept_master_param = this._utilsService.filterNullAndEmptyJsonObj(formData);
    if(manage_concept_master_param != [] && manage_concept_master_param.length != 0){
    this._manageConceptMasterService.getConceptMaster(manage_concept_master_param)
      .subscribe(
        (response: any) => {
          if (response != null) {
            if (response.length > 0 && response.code !== 'E003') {
              this.manageConceptMasterResponse = [];
              this.responseMessage = '';
              this.manageConceptMasterResponse = response;
            }
            if (response.length == 0) {
              this.manageConceptMasterResponse = [];
              this.responseMessage = this.no_result_found;
            }
          }
        }
      );
    }
    else{
      this.noSearchMessage = 'Please give a search criteria';
    }
  }
  
  resetConceptForm() {
    this.conceptMasterForm.reset(
      { 'division': null, 'Search': 'Search' }
    );
    this.manageConceptMasterResponse = [];
    this.responseMessage = '';
    this.noSearchMessage = '';
  }

  fetchConceptCode() {
    this.concepts = [];
    this._manageConceptMasterService.getConcepts().subscribe(
      (data: any[]) => {
        data.forEach(
          d => {
            let { concCode, description } = d;
            this.concepts.push({ concCode, description });
            const distinctConcept = this.concepts.filter(
              (thing, i, arr) => arr.findIndex(t => t.concCode === thing.concCode) === i
            ); 
            this.concepts = [];
            this.concepts = distinctConcept;
          }
        );
      }
    );
  }

}
