import { AssortmentListModel } from './assortment-list-model';
export class AsnLines
    // tslint:disable-next-line: one-line
    {
        shipmentId: string;
        sourcePU: string;
        challanInvoiceNumber: string;
        challanInvoiceDT: string;
        poNumber: string;
        artNumber: string;
        shopNumber: string;
        hsnNumber: string;
        hsnDesc: string;
        srl: string;
        pairQuantity: string;
        cartonNumber: number;
        mrp: string;
        mrpValue: string;
        wsPrice: string;
        wsValue: string;
        stdCost: string;
        costValue: string;
        taxableValue: string;
        igstPrcnt: string;
        cgstPrcnt: string;
        sgstPrcnt: string;
        igstAmnt: string;
        cgstAmnt: string;
        sgstAmnt: string;
        netAmnt: string;
        destState: string;
        fcyState: string;
        fromGstinNumber: string;
        toGstinNumber: string;
        transporterName: string;
        consignmentNO: string;
        consignmentDate: string;
        sizeCat: string;
        invMonthName: string;
        uom: string;
        gatePassNO: string;
        gatePassDate: string;
        shipmentCreationDate: string;
        lastUpdatedBy: string;
        lastUpdateDate: string;
        asnNumber: string;
        asnStatus: string;
        supplyYRWK: string;
        asnType: string;
        inventoryStatus: string;
        legacyPONumber: string;
        conceptCode: string;
        oldInvoiceNumber: string;
        consigneeName: string;
        consigneeCode: string;
        consignorName: string;
        consignorCode: string;
        vehicleNumber: string;
        lRGRDate: string;
        lRGRNumber: string;
        resupplyNumber: string;
        ewayBillNumber: string;
        remarks: string;
        ginNo: string;
        ginDate: string;
        migrationFileName: string;
        invoiceLineAssortments: AssortmentListModel[] = [];
        transportList: string;
        artcategory: string;
        poYrWk: string;
        transpoterCode: string;

        constructor() {}
}
