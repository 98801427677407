import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse,HttpClient  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import * as msal from '@azure/msal-browser';
import { isRefreshing } from '../common/field-name-list';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,private router: Router,private httpClient: HttpClient,private authService: MsalService) {}

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     // add authorization header with jwt token if available\

    //     let currentUser = this.authenticationService.currentUserValue;
    //     var userDetails = JSON.parse(sessionStorage.getItem('currentUser'));
    //     console.log('userDetails');
    //     console.log(userDetails);
    //     if (currentUser) {
            
    //         console.log(request);
    //         /* Modified for testing start*/
    //         if(typeof userDetails.groupAssignmentModels !== 'undefined')
    //         {
    //             console.log(userDetails.groupAssignmentModels[0].userName);
    //             request = request.clone({
    //                 //     // withCredentials:true,
    //                 setHeaders: {
    //                     // Authorization: `Bearer ${currentUser.token}`
    //                     "username": userDetails.groupAssignmentModels[0].userEmail,
    //                     "bilToken": userDetails.bilToken
    //                     // "username":'ABCD',
    //                     // "bilToken":'ggggggggg
    //                 }
    //             });

    //         }else{
    //             request = request.clone({
    //                 setHeaders: { 
    //                     Authorization: `Bearer ${currentUser.token}`
    //                 }
    //             });
    //         }
    //         /* Modified for testing end*/
    //     }
    //     return next.handle(request);
    // }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available\
 
        let currentUser = this.authenticationService.currentUserValue;
        var userDetails = JSON.parse(sessionStorage.getItem('currentUser'));
        // console.log('userDetails');
        // console.log(userDetails);
        if (userDetails) {
             
            // console.log(request);
            /* Modified for testing start*/
            if(typeof userDetails.groupAssignmentModels !== 'undefined')
            {
                 
                let isRefreshing=localStorage.getItem('refresfToken');
                // console.log(isRefreshing);
                const requestObj={
                    scopes:["user.read"]
                };
                this.authService.acquireTokenSilent(requestObj).subscribe((tokenResponse:any) => {
                    console.log(tokenResponse.accessToken);
                    console.log;("tokenResponse");
                    localStorage.setItem('refresfToken', "true");
                    
                  },
                  (error) => {
                     console.log("Error");
                      console.log(error);
                      if (error instanceof msal.InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        return this.authService.acquireTokenRedirect(requestObj)
                          .subscribe((tokenResponse:any) =>{
                               console.log(tokenResponse.accessToken);
                                console.log;("acquireTokenRedirect");
                                localStorage.setItem('refresfToken', "true");
                            },(error2) => {
                            console.error(error2);
                            console.log;("acquireTokenRedirectError");
                            localStorage.setItem('refresfToken', "false");
                          });
                      }else{
                        localStorage.setItem('refresfToken', "false");
                      }
                     
                  });
                // if (isRefreshing=='false') {
                //     this.authenticationService.refreshToken().subscribe(
                //         (response: any) => {
                //             console.log(response);
                //             if(response !== 'success'){
                //                 this.authenticationService.logout();
                //                 localStorage.setItem('errMessage','Session Timed Out! Please Login');
                //                 this.router.navigate(['/login']);
                //             }
                             
                //             localStorage.setItem('refresfToken', "false");
                //         },
                //         (err)=>{
                //                 // this.responseMessage=err;
                //         }
                //     );
                //     console.log('inside');
                //     localStorage.setItem('refresfToken', "true");
                // }else{
                //     console.log('inside else');
                //     localStorage.setItem('refresfToken', "false");
                // }
                // console.log(userDetails.groupAssignmentModels[0].userName);
                request = request.clone({
                    //     // withCredentials:true,
                    setHeaders: {
                        // Authorization: `Bearer ${currentUser.token}`
                        // "username": userDetails.groupAssignmentModels[0].userCode,
                        // "bilToken": userDetails.bilToken
                        // "username":'ABCD',
                        // "bilToken":'ggggggggg
                    }
                });
 
            }
            // else{
            //     // request = request.clone({
            //     //     setHeaders: { 
            //     //         Authorization: `Bearer ${currentUser.token}`
            //     //     }
            //     // });
            // }
            /* Modified for testing end*/
        }
        // return next.handle(request);
        // this.httpClient.get<any>('url',{observe:'response'})
        // .subscribe(resp=>{
        //     console.log(resp.headers.get('SESSION_VALIDATOR_KEY'));
        // });
        return next.handle(request) 
            .pipe(map(event => { 
                if (event instanceof HttpResponse) { 
                // event = event.clone({ 
                //     headers: event.headers.set('x-test-res', 'res-test-header') 
                // }); 
                // console.log(event);
                } 
                return event; 
            })); 
    }
}
