import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReportHeader } from './report-header';
import { RdsReportAsnService } from './rds-report-asn.service';
import { ReportGeneration } from '@/common/report-generation';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ReportSearch } from './report-header-search';
import { Concept } from '../../common/concept';
import { ReportNames } from '../../common/reports-names';
import { NgDropdownPanelService } from '@ng-select/ng-select/lib/ng-dropdown-panel.service';

@Component({
  selector: 'app-rds-report-asn',
  templateUrl: './rds-report-asn.component.html',
  styleUrls: ['./rds-report-asn.component.css']
})
export class RdsReportASNComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myform', { static: false }) myForm: ElementRef;
  enable = false;
  shopEnable = true;
  regionEnable = true;
  distEnable = true;
  destinationEnable = false;
  regions = [{ name: 'North (N)', value: 'N' },
  { name: 'South (S)', value: 'S' },
  { name: 'East (E)', value: 'E' },
  { name: 'West (W)', value: 'W' }];
  reportModel = new ReportHeader();
  requestParameterModel: RequestParameterModel[];
  searchParameterModel = new ReportSearch();
  concepts: Concept[];
  conceptswithAll: any[] = [];
  orderTypeWithAll: any[] = [];
  conceptsForRetail: any[] = [];
  reportNames: ReportNames[];
  username: string;
  coCode: any;
  responseMessage: string;
  sucessMeg1: string;
  asyncSuccess: string = 'Failed';
  loginUserEmail: string;
  generatebutton: string = 'enable';
  sucessMeg: string;
  showReportList = {
    Reportname: false
  }
  fromWeek: string;
  errorMessage: string;
  payloadBataWeek = {};
  fromDate: string;
  arr = ['fromDate', 'toDate'];
  selectedVal: String;
  date: Date;
  toDate: string;
  ShopCodes: any[] = [];
  DistrictCodes: any[] = [];
  names: any[];
  onlyPerfix: any[] = [];
  uniquerper: any[] = [];
  disableReportList = true;
  selectedPrefix: string;
  selectedList: any[] = [];
  uniqueDist: any[]= [];

  obj = {
    GOW_1308B: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    GOW_1302: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    GOW_1308BI: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    MTOWR_F: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    MTOWF_R: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    MTOWR_S: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    GOW_1308B_RECAP: 'Either "RDC Code" / "Region" is REQUIRED.',
    GOW_1302_RECAP: 'Either "RDC City Name" / "Region" is REQUIRED.'
  };

  currentDateSystem: Date;
  responseList: any[] = [];

  constructor(private _router: Router, private _generateReportService: RdsReportAsnService,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    this.reportModel.reportFormatType = 'xlsx';

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.loginUserEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        this.reportNames = data;
        this.reportNames.forEach(element => {
          let reportPrefixOnly: {} = element.reportName.split("_");
          let rsp_prf = {
            "perfix": reportPrefixOnly[0],
            "reportName": reportPrefixOnly[1],
            "reportKey": element.reportKey
          };
          this.onlyPerfix.push(rsp_prf);
        });
        this.uniquerper = [...new Set(this.onlyPerfix.map(x => x.perfix))];
        this.onSelectPerfix("ASN");
      }
    )

    this._generateReportService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        this.concepts.forEach(
          concept => {
            this.conceptswithAll.push(concept);
            if (concept.division == 'RETAIL' && concept.concCode != 'N') {
              this.conceptsForRetail.push(concept);
            }
          }
        );
        let conc = new Concept();
        conc.concCode = 'ALL';
        conc.concept = 'ALL';
        conc.description = 'ALL CONCEPT';
        this.conceptswithAll.unshift(conc);
        //this.searchParameterModel.concept = 'ALL';
      }
    )
  }

  fetchBataWeekFromDate() {
    this.clearErrorMsg();
    this.currentDateSystem = new Date();
    let convertedCurrentDate = this._utilsService.transformDateSearch(this.currentDateSystem);
    this.payloadBataWeek = {
      "name": "date",
      "value": convertedCurrentDate
    }
    this._generateReportService.getBataYearWeek(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.status == "Success") {
          this.responseList = response.dataList;
          let newBataWeek = this.responseList[0].bataWeek;
          let newBataYear = this.responseList[0].bataYear;
          if (newBataWeek.toString().length <= 1) {
            newBataWeek = '0' + newBataWeek.toString();
            this.searchParameterModel.weekTo = newBataYear.toString() + newBataWeek.toString();
          }
          else {
            this.searchParameterModel.weekTo = newBataYear.toString() + newBataWeek.toString();
          }
        }
      });
  }

  onSelectConcept(value) {
    this.clearErrorMsg();
    this.loaDer.nativeElement.style.display = 'block';
    this.ShopCodes = [];
    // this.DistrictCodes = [];
    this.uniqueDist = [];
    let divisionCode: string;
    this.searchParameterModel.concept = undefined;
    this.concepts.filter(cs => cs.concCode === this.searchParameterModel.conceptCode).forEach(
      div => {
        if (value != 'ALL') {
          this.searchParameterModel.concept = (div.concCode + '-' + div.description);
        }
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
      }
    )
    let detail: any;
    if (value != 'ALL') {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': divisionCode,
        'userCode': this.username,
        'conceptCode': this.searchParameterModel.conceptCode
      }
    }
    else {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': '5',
        'userCode': this.username
      }
    }
    const requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.distEnable = false;
      this.regionEnable = false;
      this._generateReportService.getLocations(requestParameterModel).subscribe(
        (res: any) => {
          this.ShopCodes = [];
          // this.DistrictCodes = [];
          this.uniqueDist = [];
          if (res.dataList != null) {
            res.dataList.forEach(
              data => {
                this.ShopCodes.push(data);
                // this.DistrictCodes.push(data);
                this.uniqueDist.push(data);
                this.searchParameterModel.regionCode = undefined;
                this.searchParameterModel.toShopCode = undefined;
                this.searchParameterModel.fromShopCode = undefined;
                this.searchParameterModel.fromRdcDistrictCode = undefined;
                this.searchParameterModel.toDistrictCode = undefined;
                this.DistrictCodes = [...new Set(this.uniqueDist.map(x => x.districtNumber))];
              }
            );
            // const details =
            // {
            //   "shopNumber": "ALL",
            //   "shopName": "All RDC CODE"
            // };
            // this.ShopCodes.unshift(details);
          }
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    else {
      this.shopEnable = true;
      this.distEnable = true;
      this.regionEnable = true;
      this.searchParameterModel.regionCode = undefined;
      this.searchParameterModel.toShopCode = undefined;
      this.searchParameterModel.fromShopCode = undefined;
      this.searchParameterModel.fromRdcDistrictCode = undefined;
      this.searchParameterModel.toDistrictCode = undefined;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onSelectConceptForRdcName(value) {
    let detail: any;
    if (value === 'ALL') {
      detail = {
        "shopAgencyCode": "5",
        "userCode": this.username
      }
    }
    else {
      detail = {
        "shopAgencyCode": "5",
        "userCode": this.username,
        "conceptCode": this.searchParameterModel.conceptCode
      }
    }
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.regionEnable = false;
      this._generateReportService.getRdcCityName(detail).subscribe(
        (data: any) => {
          this.names = data.cities;
          this.searchParameterModel.regionCode = undefined;
          this.searchParameterModel.shopCityName = undefined;
        });
    }
    else {
      this.shopEnable = true;
      this.regionEnable = true;
      this.searchParameterModel.regionCode = undefined;
      this.searchParameterModel.shopCityName = undefined;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  public async OnClickGenerate(myForm): Promise<void> {
    this.responseMessage = '';
    this.errorMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.loaDer.nativeElement.style.display = 'block';
    let switchFlag: any;
    let reportKey: any;
    let reportName: any;
    this.sucessMeg = 'Success';
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        switchFlag = report.switchingFlag;
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    let userDetails = {
      "name": "userId",
      "value": this.username
    }
    this.requestParameterModel.push(userDetails);
    console.log('requesting parameters inside updatedDatalist' + JSON.stringify(this.requestParameterModel));
    let details = new ReportGeneration();
    if (switchFlag == 'N') {
      console.log('Report will get generated online');
      if(this.searchParameterModel.conceptCode != null){
      details.reportFormatType = this.reportModel.reportFormatType;
      details.reportInputRequestList = this.requestParameterModel;
      this.callingServiceWithoutBlob(details);
      this.loaDer.nativeElement.style.display = 'none';
    } else{
      this.errorMessage = 'Concept Code is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
    }
  }
    else {
      console.log('Report will get generated offline');
      let detail = {
        "name": "reportKey",
        "value": reportKey
      }
      let nameDetail = {
        "name": "reportName",
        "value": reportName
      }
      this.requestParameterModel.push(detail);
      this.requestParameterModel.push(nameDetail);
      console.log(JSON.stringify(this.requestParameterModel));
      this._generateReportService.offlineReportGeneration(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          if (response.status == 'success') {
            // this.responseMessage = response.message;
            this.asyncSuccess = 'success';
            this.responseMessage = "Your report will be generated soon, you will be notified over mail. You can check the progress in View Report Status.";
          }
          else {
            this.asyncSuccess = 'Failed';
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.asyncSuccess = 'Failed';
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
  }

  callingServiceWithoutBlob(details) {
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    let reportDetails =
    {
      "name": "reportName",
      "value": reportName
    }
    this.requestParameterModel.push(reportDetails);
    let reportKeySelected =
    {
      "name": "reportKey",
      "value": reportKey
    }
    this.requestParameterModel.push(reportKeySelected);
    this.loginUserEmail
    let userSelectedEmail =
    {
      "name": "userEmail",
      "value": this.loginUserEmail
    }
    this.requestParameterModel.push(userSelectedEmail);
    if (this.searchParameterModel.conceptCode === 'ALL') {
      let index = this.requestParameterModel.findIndex(cs => cs.name == 'conceptCode');
      this.requestParameterModel.splice(index, 1);
    }
    // if (this.reportModel.reportKey === 'GOW_1308B_RECAP' || this.reportModel.reportKey === 'GOW_1302_RECAP') {
    //   let index = this.requestParameterModel.findIndex(cs => cs.name == 'week');
    //   this.requestParameterModel.splice(index, 1);
    // }
    // if (this.reportModel.reportKey === 'GOW_1308B' || this.reportModel.reportKey === 'GOW_1302' ||
    //   this.reportModel.reportKey === 'GOW_1308BI' || this.reportModel.reportKey === 'MTOWR_F' ||
    //   this.reportModel.reportKey === 'MTOWF_R' || this.reportModel.reportKey === 'MTOWR_S') {
    //   let index = this.requestParameterModel.findIndex(cs => cs.name == 'weekTo');
    //   this.requestParameterModel.splice(index, 1);
    // }
    // if (this.reportModel.reportKey === 'GOW_1308B_RECAP' || this.reportModel.reportKey === 'GOW_1302_RECAP') {
      let year = new Date().getFullYear();
      let valueOfWeekFrom =
      {
        "name": "weekFrom",
        "value": year + "01"
      }
      this.requestParameterModel.push(valueOfWeekFrom);
    // }
    details.reportInputRequestList = this.requestParameterModel;
    console.log(JSON.stringify(details));

    let r = new Map(Object.entries(this.obj));

    if (this.searchParameterModel.toShopCode != null || this.searchParameterModel.sourceRdc != null || this.searchParameterModel.toDistrictCode != null || this.searchParameterModel.districtNumber != null || this.searchParameterModel.fromRdcDistrictCode != null ||
      this.searchParameterModel.regionCode != null || this.searchParameterModel.shopCode != null || this.searchParameterModel.rdcNo != null
      || this.searchParameterModel.fromShopCode != null || this.searchParameterModel.rdcCode != null ||
      this.searchParameterModel.fromRdcCode != null || this.searchParameterModel.toRdcCode != null ||
      this.searchParameterModel.shopCityName != null || this.searchParameterModel.fromDistrictCode != null) {

      if (this.reportModel.reportKey === 'GOW_1302_RECAP') {
        if (this.searchParameterModel.shopCityName != undefined) {
          details.rdcList = [];
          let detailrdc: any;
          if (this.searchParameterModel.conceptCode === 'ALL') {
            detailrdc = {
              "city": this.searchParameterModel.shopCityName,
              "userCode": this.username
            }
          }
          else {
            detailrdc = {
              "city": this.searchParameterModel.shopCityName,
              "userCode": this.username,
              "conceptCode": this.searchParameterModel.conceptCode
            }
          }
          this._generateReportService.getRdcNoByCity(detailrdc).subscribe(
            (data: any) => {
              data.rdcByCityList.forEach(
                para => {
                  details.rdcList.push(para.rdcNo);
                }
              )
              this.callReportService(details);
            });
        }
        else {
          this.callReportService(details);
        }
      }
      else {
        this.callReportService(details);
      }
    } else {
      this.errorMessage = r.get(this.reportModel.reportKey);
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  callReportService(details) {
    this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.reportKey).subscribe(
      (response: any) => {
        if (response.status == 'Success') {
          this.asyncSuccess = 'success';
          this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          this.loaDer.nativeElement.style.display = 'none';
          this.generatebutton = 'disable';
          this.delay(5000).then(any => {
            this.generatebutton = 'enable';
          });
        }
        else {
          this.asyncSuccess = 'Failed';
          this.errorMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.asyncSuccess = 'Failed';
        this.errorMessage = err;
        this.loaDer.nativeElement.style.display = 'none';
      });
  }

  onClickReset() {
    //this.fetchBataWeekFromDate();
    // if (this.reportModel.reportKey === 'GOW_1308B' || this.reportModel.reportKey === 'GOW_1302' ||
    //   this.reportModel.reportKey === 'GOW_1308BI' || this.reportModel.reportKey === 'MTOWR_F' ||
    //   this.reportModel.reportKey === 'MTOWF_R' || this.reportModel.reportKey === 'MTOWR_S') {
    //   this.fetchBataWeekFromDate();
    //   this.convertBataWeek();
    // }
    this.searchParameterModel = new ReportSearch();
    console.log('reset report search details');
    //this.searchParameterModel.concept = 'ALL';
    this.responseMessage = '';
    this.errorMessage = "";
    this.sucessMeg1 = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.regionEnable = true;
    this.enable = false;
    this.shopEnable = true;
    this.distEnable = true;
    this.searchParameterModel.region = "";
    this.showReportList.Reportname = false;
  }
  clearErrorMsg() {
    this.errorMessage = '';
    this.responseMessage = '';
  }

  setData() {
    //this.fetchBataWeekFromDate();
    // if (this.reportModel.reportKey === 'GOW_1308B' || this.reportModel.reportKey === 'GOW_1302' ||
    //   this.reportModel.reportKey === 'GOW_1308BI' || this.reportModel.reportKey === 'MTOWR_F' ||
    //   this.reportModel.reportKey === 'MTOWF_R' || this.reportModel.reportKey === 'MTOWR_S') {
    //   this.fetchBataWeekFromDate();
    //   this.convertBataWeek();
    // }
    this.reportModel.reportFormatType = 'xlsx';
    this.responseMessage = '';
    this.sucessMeg1 = '';
    this.errorMessage = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    this.onClickReset();
    //this.searchParameterModel.concept = 'ALL';
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(0), ms)).then(() => console.log("fired"));
  }

  regionChangeShopCode(regionVal) {
    this.clearErrorMsg();
    if (regionVal != '0: undefined') {
      this.shopEnable = true;
      this.distEnable = true;
    }
    else {
      this.shopEnable = false;
      this.distEnable = false;
    }
  }

  changeDistrictCode(distValue) {
    this.clearErrorMsg();
    if (distValue != '0: undefined') {
      this.regionEnable = true;
      this.shopEnable = true;
    }
    else {
      this.regionEnable = false;
      this.shopEnable = false;
    }
  }

  onBlurCode(value) {
    this.clearErrorMsg();
    if (value != '0: undefined') {
      this.regionEnable = true;
      this.distEnable = true;
    }
    else {
      this.regionEnable = false;
      this.distEnable = false;
    }
  }

  yearWeekValidations(){
    this.clearErrorMsg();
   // this.errorMessage = '';
    this.generatebutton = 'enable';
    let yearWeekTo = this.searchParameterModel.weekTo;
    let week:string = yearWeekTo.toString().substr(4,6);
    let yearNum = parseInt(yearWeekTo.toString().substr(0,4));
    if(yearWeekTo.length >= 4){
      console.log(yearWeekTo.toString().substr(4,6));
      if(!((yearNum>=2021) && (yearNum <= new Date().getFullYear()))){
      // if((yearWeekTo.toString().substr(0,4) != new Date().getFullYear().toString()){
        this.errorMessage = 'Year must be a current or previous year.';
        this.generatebutton = 'disable';
      }
      else if(week.length == 2 && (week != '' && week != undefined) && (Number(week) < 1 || Number(week) > 53)){
        this.errorMessage = 'Week should be in between 01 and 53';
        this.generatebutton = 'disable';
      }
      else{
        console.log('Validation Success');
        this.generatebutton = 'enable';
      }
    }
  }

  // convertBataWeek() {
  //   let bataNumber = this.searchParameterModel.week.substring(4, 6);
  //   let bataYear = this.searchParameterModel.week.substring(0, 4);
  //   this.payloadBataWeek = {
  //     "bataYear": bataYear,
  //     "bataNumber": bataNumber,
  //     "searchFlag": "WEEK",
  //     "fromToFlag": "TO"
  //   }
  //   this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
  //     (response: any) => {
  //       if (response.message == "Success") {
  //         const toDateConvert = this._utilsService.transformDateTo(response.date);
  //         this.searchParameterModel.weekEndDate = toDateConvert;
  //       }
  //     });
  //   let year = new Date().getFullYear();
  //   this.payloadBataWeek = {
  //     "bataYear": year,
  //     "bataNumber": "01",
  //     "searchFlag": "WEEK",
  //     "fromToFlag": "FROM"
  //   }
  //   this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
  //     (response: any) => {
  //       if (response.message == "Success") {
  //         const toDateConvert = this._utilsService.transformDateTo(response.date);
  //         this.searchParameterModel.weekStartDate = toDateConvert;
  //       }
  //     });
  // }

  onSelectPerfix(event) {
    // if(event != undefined){
    //  this.showReportList.Reportname = true;
    // }
    this.clearErrorMsg();
    console.log(event);
    this.selectedList = [];
    this.onlyPerfix.filter(cs => cs.perfix === event).forEach(
      reportkN => {
        this.selectedList.push(reportkN);

      }
    )
    console.log(this.selectedList);

  }
}
