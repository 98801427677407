import { Injectable } from '@angular/core';
import { RequestParameterModel } from '../common/request-parameter-model';
import{ PurchaseOrderLines} from '../search-po/po-lines';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { PO_NUMBER } from '@/common/field-name-list';
import { BilApiUrl } from '../common/bil-api-url';
import {ViewShipmentReceiptComponent} from './view-shipment-receipt.component';

@Injectable({
  providedIn: 'root'
})
export class ViewShipmentReceiptService {
  searchedPoDetailsByService = new PurchaseOrderLines();
  purchase_order_details_url: string = BilApiUrl.PO_DETAILS_URL; 
 // private url:string="../assets/static-data/po-search-result.json";
  constructor(private _http: HttpClient,private httpService:HttpClient) {}
   

  getViewShipmentServiceCriteria(purchaseOrderNumber: string) {
    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = PO_NUMBER;
    requestParameterModel.value = purchaseOrderNumber;
    purchase_order_search_parameters.push(requestParameterModel);
    console.log(' getPurchaseOrderBySearchCriteria : purchase_order_search_parameters : ' + JSON.stringify(purchase_order_search_parameters));
    return this._http.post(this.purchase_order_details_url, purchase_order_search_parameters);
  //  return this._http.post(this.url,null);
  // console.log('inside Service...');

  }

  
}

  
