import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { RdsBilApiUrl } from "../../common/rds-bil-api-url";
import { BilApiUrl } from "../../common/bil-api-url";

@Injectable({
  providedIn: "root",
})
export class ShortageAdjustmentService {
  stock_adjustment_details_url: string =
    RdsBilApiUrl.STOCK_ADJUSTMENT_DETAILS_URL;
  status_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  concept_division_url: string = RdsBilApiUrl.CONCEPT_DIVISION_URL;
  perform_shortage_adjustment_url: string =
    RdsBilApiUrl.PERFORM_SHORTAGE_ADJUSTMENT_URL;
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  perform_shortage_adjustment_for_Claimed_url= RdsBilApiUrl.PERFORM_SHORTAGE_ADJUSTMENT_CLAIMED_URL;
  perform_shortage_adjustment_for_defective_url= RdsBilApiUrl.PERFORM_SHORTAGE_ADJUSTMENT_DEFECTIVE_URL;

  constructor(private _http: HttpClient) { }

  getStockAdjustmentDetails(details) {
    return this._http.post(this.stock_adjustment_details_url, details);
  }

  getshortageAdjustmentDrpdwn(details) {
    return this._http.post(this.status_url, details);
  }

  getsegment(details) {
    return this._http.post(this.concept_division_url, details);
  }

  performShortageAdjustment(details,inventoryType) {
    let url:any;
    if(inventoryType == 'CLAIMED'){
      url = this.perform_shortage_adjustment_for_Claimed_url;
    } else if(inventoryType == 'DEFECTIVE'){
      url = this.perform_shortage_adjustment_for_defective_url;
    } else{
      url = this.perform_shortage_adjustment_url;
    }
    return this._http.post(url, details);
  }

  getSizeDetails(details) {
    return this._http.post(this.size_url, details);
  }
}
