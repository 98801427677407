import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class RdsReportGrnService {

  URL: string = '';
  reportsDetails: any = [
    { key: "WR_V", url: BilApiUrl.WEEKLY_RECEIPT_FROM_VENDOR_REPORT},
    { key: "RECEIPT_SUMMARY", url: BilApiUrl.RECEIPT_SUMMARY_REPORT},
    { key: "RAW", url: BilApiUrl.RECEIPT_ARTICLE_WISE},
    { key: "MR_RFVS", url: BilApiUrl.MATERIAL_RECEIPT_FROM_RDC_FACTORY},
    { key: "GRS", url: BilApiUrl.RDC_RECEIPT_SUMMARY_REPORT}
  ];
  
  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
   reports_url: string = BilApiUrl.REPORT_NAMES;
   offline_reports_url: string = BilApiUrl.OFFLINE_REPORT_URL;
   shop_name_url: any = BilApiUrl.OUTWARD_GATE_PASS_SHOP_NAME;
   receipt_Summary_Url: any = BilApiUrl.WEEK_RECEIPT_SUMMARY;
   vendor_list_url: any = BilApiUrl.GENERATE_PO_OTHER_URL; 
   order_type_url = 'assets/static-data/orderTypes.json';
   shop_location_url = RdsBilApiUrl.SEARCH_LOCATION_URL;
   city_name_url = RdsBilApiUrl.RDC_CITY_NAME_URL;
   rdc_no_from_city_url = RdsBilApiUrl.RDC_NO_FROM_CITY_URL;
 
  constructor(private _http: HttpClient) { }

  public async getReportDetails(details, reportKey): Promise<Blob> {
    console.log('sending parameters' + JSON.stringify(details));
    this.reportsDetails.forEach(element => {
      // tslint:disable-next-line: triple-equals
      if (reportKey == element.key) {
        console.log('element key' + element.key + 'element url' + element.url);
        this.URL = element.url;
      }
    });
    const file = await this._http.post<Blob>(this.URL, details, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }
  getRdcCityName(detail) {
    return this._http.post(this.city_name_url, detail);
  }
  getRdcNoByCity(detail) {
    return this._http.post(this.rdc_no_from_city_url, detail);
  }
  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }
  getLocations(requestParameterModel) {
    return this._http.post(this.shop_location_url, requestParameterModel);
  }
  getReports() {
    const reportParameter = [
      {
      name: 'reportModule',
      value: 'RDS'
      }
    ];
    return this._http.post(this.reports_url, reportParameter);
  }
  getOrderTypes() {
    return this._http.get(this.order_type_url);
  }
  offlineReportGeneration(reportParam) {
    return this._http.post(this.offline_reports_url, reportParam);
  }
  getReportDetailsWithoutBlob(details, reportKey) {
    this.reportsDetails.forEach(element => {
      if (reportKey == element.key) {
        console.log('element key' + element.key + 'element url' + element.url);
        this.URL = element.url;
      }
    });
    return this._http.post(this.URL, details);
  }
  getShopName(shopCode){
    let listVariables = [{
      "name" : "shopNo",
      "value" : shopCode
    }];
  return this._http.post(this.shop_name_url, listVariables);
  }
  getFactoryName(shopCode){
    let listVariables = [
      {
      "name":"shopAgencyCode",
      "value":"0"},
      {
        "name":"shopNo",
        "value":shopCode
      },];
  return this._http.post(this.shop_name_url, listVariables);
  }
  getVendorList(vendorCode){
    let listVariables = [
      {
      "name":"partyNo",
      "value": vendorCode
      }
      ];
  return this._http.post(this.vendor_list_url, listVariables);
  }
  getRecepitSummary(searchlist){
    console.log(searchlist);
    console.log( 'element url' +this.receipt_Summary_Url); 
  return this._http.post(this.receipt_Summary_Url, searchlist);
  }
}
