import { Component, OnInit,OnDestroy,ViewChild, ElementRef ,Inject} from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
// import { OAuthService } from 'angular-oauth2-oidc';
// import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
// import { authCodeFlowConfig } from '../sso.config';
import {Subject} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../assets/css/bootstrap.css','../../assets/css/iofrm-style.css','./login.component.css']
})
export class LoginComponent implements OnInit ,OnDestroy{
  @ViewChild('loaDer', {static: false}) loaDer: ElementRef;

  isIframe = false;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  messg: string;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService,private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    //  private oauthService:OAuthService
    ) {
   }

//   ngOnInit() {

//     if(typeof localStorage.getItem('errMessage') !=='undefined'){
//       console.log(localStorage);
//       this.error=localStorage.getItem('errMessage');
//       localStorage.clear();
//       console.log(localStorage);
//     }
    

//     setTimeout(()=>{
//       this.loaDer.nativeElement.style.display = 'none';    
//     },100);

//     this.loginForm = this.formBuilder.group({
//       // username: ['', Validators.required],
//       // password: ['', Validators.required]
//   });
//   this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

// }
// get f() { return this.loginForm.controls; } 

// onSubmit() {
//   this.oauthService.initImplicitFlow();
//   // this.oauthService.initLoginFlow();

//   }


  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    setTimeout(()=>{
      this.loaDer.nativeElement.style.display = 'none';    
    },100);

    this.loginForm = this.formBuilder.group({
      // username: ['', Validators.required],
      // password: ['', Validators.required]
  });
  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.checkAccount();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.checkAccount();
      });
  }
  get f() { return this.loginForm.controls; } 
  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    console.log(this.loggedIn);
  }

  onSubmit() {
    console.log(this.msalGuardConfig);
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
          .subscribe(() => this.checkAccount());
        } else {
          this.authService.loginPopup()
            .subscribe(() => this.checkAccount());
      }
    } else {
      if (this.msalGuardConfig.authRequest){
        console.log('IF');
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        console.log('ELSE');
        this.authService.loginRedirect();
      }
    }
  }
  
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
