import { Batches } from '@/batches/batches';
import { BatchesData } from '@/batches/batchesdata';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { FreightdetailModel } from './freight-detailmodel';
import { NavigationLinkParameterService } from '../../../common/navigation-link-parameters-service';
import { FreightDetailService } from './freight-detail.service';
// import { ASNDetailResponseModel } from '../../../common/asn-detail-model';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@/common/utils-service';
import { importsavevar } from '@/common/import-save-var';

// import { searchreq } from './search-req-payload';
// import { savereq } from './save-req-payload';

@Component({
  selector: 'app-freight-detail',
  templateUrl: './freight-detail.component.html',
  styleUrls: ['./freight-detail.component.css']
})
export class FreightDetailComponent implements OnInit {

  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  routeForm: FormGroup;
  routeResultForm: FormGroup;
  profileForm: FormGroup;
  isDisplayResult: boolean = false;
  responseMessage: any;
  statusMessage:string;
  concepts: any;
  downloadUrlList: Batches[] = [];
  downloadUrl: string;
  checkService = false;
  uploadFileName = 'No File Chosen';
  uploadKey = 'FREIGHT_MASTER';
  searchData :any;
  saveData : any;
  disable : boolean;
  routeDetails: any;
  currentPage : number = 1;
  itemPerPage:number = 5;
  indexUpdate: number;
  indexDeactivate: number;
  userName: string;
  headerData : any; 
  isPrimary: boolean = true;
  transporterData :any;
  isDisabled:boolean = true;

  ftwrRtlCb:boolean = false;
  ftwrWslCb:boolean =false;
  nonFtwrRtlCb:boolean =false;
  nonFtwrWslCb:boolean =false;
  rateCb:boolean = false;

  constructor(private fb: FormBuilder,private _router: Router,private freightDetailService: FreightDetailService, private httpClient: HttpClient,private _utilsService: UtilsService,private navigationLinkParameterService: NavigationLinkParameterService) { 
    this.routeForm = this.buildrouteForm();
    this.routeResultForm = this.buildrouteResultForm();
    this.profileForm = this.buildProfileForm();
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userName = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    
  }

  ngOnInit() {
    this.routeDetails=this.navigationLinkParameterService.freightDetails;
    this.headerData=this.navigationLinkParameterService.searchFreightHeaderData;
    console.log(this.headerData);
    this.navigationLinkParameterService.searchFreightHeaderData="";
    this.navigationLinkParameterService.freightDetails="";
    console.log(this.navigationLinkParameterService.searchFreightHeaderData.frightType);
    if(this.headerData.frightType === 'P'){
      this.isPrimary=true;
    }else{
      this.isPrimary=false;
    }
    this.freightDetailService.getTransporterName(this.headerData.frightType).subscribe(
      (data: any) => {
        console.log(data);
        this.transporterData = data.transporterModels[0];
        console.log(this.transporterData);
      }
    );
    
    let control = <FormArray>this.routeResultForm.controls.route;
    // this.loaDer.nativeElement.style.display = 'block';
    console.log(this.routeDetails);
    console.log(this.routeDetails['activeStatus']);
    if(this.isPrimary){
      this.isDisabled=true;
    }else if(this.isPrimary === false && this.routeDetails.fullTruckLoadYn === 'Y' && this.routeDetails.perCartonYn === "N"){
      this.isDisabled=false;
    }
    // setTimeout(() => {
            control.push(this.fb.group({
              transporterCode:[this.routeDetails.transporterCode],
              transpoterName:[this.routeDetails.transpoterName],
              exLocation:[this.routeDetails.exLocation],
              toLocationCode: [this.routeDetails.toLocationCode],
              toLocation: [{value:this.routeDetails.toLocation,disabled: this.isDisabled}],
              supplierCode: [this.routeDetails.supplierCode],
              sourcingPartners: [{value:this.routeDetails.sourcingPartners,disabled: this.isPrimary}],
              typeOfSupply: [this.routeDetails.typeOfSupply],
              ftwrRtlCb: [this.routeDetails.ftwrRtlCb],
              ftwrWslCb: [this.routeDetails.ftwrWslCb],
              nonFtwrRtlCb: [this.routeDetails.nonFtwrRtlCb],
              nonFtwrWslCb: [this.routeDetails.nonFtwrWslCb],
              rateCb: [this.routeDetails.rateCb],
              groupCode: [this.routeDetails.groupCode],
              fullTruckLoadYn: [this.routeDetails.fullTruckLoadYn],
              perCartonYn: [this.routeDetails.perCartonYn],
              activeStatus:[this.routeDetails.activeStatus],
              createdBy:[this.routeDetails.createdBy],
              createdDate:[this.routeDetails.createdDate],
              frightType:[this.routeDetails.frightType],
              freightMasterId:[this.routeDetails.freightMasterId],
              lastUpdatedBy:[this.routeDetails.lastUpdatedBy],
              lastUpdatedDate: [""]
            }))
         
          console.log(control); console.log('control');
    // },1000);
    
  }

  buildrouteForm() {
    return this.fb.group({
      concept: [null],
      destShopCode: [null],
      route: [null],
      subRoute: [null]
    });
  }

  buildrouteResultForm(): FormGroup {
    return this.fb.group({ route: this.fb.array([]) });
  }

  buildProfileForm(): FormGroup {
    return this.fb.group({
      name: [''],
      file: [''],
      uploadKey: [''],
      uploadedBy: ['']
    });
  }

  onSearch(formData:any){
    this.routeResultForm = this.buildrouteResultForm();
    this.searchData = formData;
    this.routeDetails=[];
    // let control = <FormArray>this.routeResultForm.controls.route;
    this.loaDer.nativeElement.style.display = 'block';
    // this.freightDetailService.getSearch(this.searchData).subscribe(
    //   (response : any) => {
    //     if(response!= ""){
    //     this.isDisplayResult = true;
    //     this.responseMessage='';
    //     response.forEach(
    //       rms => {
    //        const e : searchreq = rms;
    //        this.routeDetails.push(e);
    //        this.disable = true;
          
    //       }
         
    //     );
    //     this.onUpdate(this.disable);
    //   }
    //     else{
    //       console.log('in else');
    //       this.statusMessage='error';
    //       this.responseMessage= 'No Data Found';
    //       this.isDisplayResult = false;
    //       this.loaDer.nativeElement.style.display = 'none';
    //     }
    //   },
    //   (error) => {
    //     console.error('some error occured! while fetching routes from server');
    //     console.log(error);
    //     this.routeResultForm = this.buildrouteResultForm();
    //     this.isDisplayResult = false;
    //     this.loaDer.nativeElement.style.display = 'none';
    //   }, () => {
    //     console.info('all disounts fetched. ');
    //     this.loaDer.nativeElement.style.display = 'none';}
    // );
  }
  onUpdate(disable: any) {
    
    let control = <FormArray>this.routeResultForm.controls.route;
    // this.loaDer.nativeElement.style.display = 'block';
    console.log(this.routeDetails);
    this.routeDetails.forEach(
      rms =>{
    
            control.push(this.fb.group({
              destShopCode: [{ value: rms.destShopCode, disabled: true}],
              shopName: [{ value: rms.destShopName, disabled: true}],
              concept: [{ value: rms.concept, disabled: true}],
              route: [{ value: rms.route, disabled: disable}],
              subRoute: [{ value: rms.subRoute, disabled: disable}],
              oracleSeque: [{ value: rms.oracleSequence, disabled: disable}],
              routeSeque: [{ value: rms.routeSequence, disabled: disable}],
              sourceRDC: [{ value: rms.sourceShopCode, disabled: true}],
            }))
          });
          console.log(control); console.log('control');
          
  }

  resetForm(){
    this.isDisplayResult = false;
    this.responseMessage='';
    this.statusMessage='';
    this.buildrouteResultForm();
    this.profileForm = this.buildProfileForm();
    this.uploadFileName = 'No File Chosen';
    this.myInputVariable.nativeElement.value = '';
  }

  public async downloadTemplate(): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    // this.UploadMsg = '';
    // this.freightDetailService.getDownloadUrl().subscribe(
    //   (res: BatchesData) => {
    //     console.log(res);
    //     this.downloadUrlList = res['configParamModels'];
    //     console.log(this.downloadUrlList);
    //     this.downloadUrlList.forEach(element => {
    //     if (element.paramName == 'ROUTE_MASTER') {
    //       console.log('RouteMaster');
    //       this.downloadUrl = element.paramValue;
    //       console.log(this.downloadUrl);
    //       this.getDownloadFile(this.downloadUrl);
    //       return this.checkService = true;
    //     }
    //   });
    //   }
    // );
  }

  public async getDownloadFile(downloadUrl): Promise<void> {
    
      // const fullDownloadUrl: string = downloadUrl + '' + 'ROUTE_MASTER' + '.xls';
      // console.log(fullDownloadUrl);
      const file = await this.httpClient.get<Blob>(downloadUrl, { responseType: 'blob' as 'json' }).toPromise();
      const blob = file;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'ROUTE_MASTER' + '.xlsx';
      anchor.href = url;
      anchor.click();
      window.URL.revokeObjectURL(url);
      this.loaDer.nativeElement.style.display = 'none';

    
  }

  onSelectFile(event) {
    if (event.target.files.length > 0) {
      this.statusMessage = '';
      this.responseMessage = '';
      this.uploadFileName = event.target.files[0].name;
      const file = event.target.files[0];
      this.profileForm.get('file').setValue(file);
      this.profileForm.get('uploadKey').setValue(this.uploadKey);
      console.log(this.myInputVariable.nativeElement.value);
      console.log(this.myInputVariable.nativeElement.files);
    }
  }

  fileSubmit(){
  this.statusMessage = '';
  this.responseMessage = '';
  if (this.myInputVariable.nativeElement.files.length == 0) {
    this.responseMessage = 'Please select file';
    this.statusMessage = 'error';
    return false;
  } else {
    const newFileArray: any[] = this.profileForm.get('file').value.name.split('.');
    const newFileFormat = newFileArray[0] + '_' + this._utilsService.getCurrentFormatDate() + '.' + newFileArray[1];
    const formData = new FormData();

    formData.append('name', this.profileForm.get('name').value);
    formData.append('file', this.profileForm.get('file').value, newFileFormat.toString());
    formData.append('uploadedBy',this.profileForm.get('uploadedBy').value);
    formData.append('uploadKey', this.profileForm.get('uploadKey').value);
    
    this.loaDer.nativeElement.style.display = 'block';

    // this.freightDetailService.upload(formData).subscribe(
    //   (response: importsavevar) => {
    //     this.responseMessage = '';
    //     this.statusMessage = '';
    //     if (response.status == 'success') {
    //       this.responseMessage = 'File is uploaded successfully';
    //       this.statusMessage = 'success';
    //       // this.enable = true;
    //     } else {
    //       this.responseMessage = response.message;
    //       // this.responseMessage.replace(/\\n/g, '<br/>');
    //       this.statusMessage = 'error';
    //       // this.enable = false;
    //     }
    //    this.loaDer.nativeElement.style.display = 'none';
    //   },
    //   (err) => {
    //     this.responseMessage = err;
    //     // this.responseMessage.replace(/\\n/g, '<br/>');
    //     this.statusMessage = 'error';
    //     this.loaDer.nativeElement.style.display = 'none';
    //     // this.enable = false;
    //   }
    // );
    this.uploadFileName = 'No File Chosen';
    this.profileForm = this.buildProfileForm();
    console.log(this.myInputVariable.nativeElement.value);
    this.myInputVariable.nativeElement.value = '';
    
  }
}

onEdit(index:any){
  this.indexUpdate = this.getAbsoluteIndex(index);
  console.log(this.indexUpdate); console.log('indexUpdate');
  // this.disable = false;
  // this.routeResultForm.get(['conceptChannel', index]).get('isRowEdit').setValue(true);
    this.routeResultForm.get(['route', this.indexUpdate]).get('route').enable();
    this.routeResultForm.get(['route', this.indexUpdate]).get('subRoute').enable();
    this.routeResultForm.get(['route', this.indexUpdate]).get('oracleSeque').enable();
    this.routeResultForm.get(['route', this.indexUpdate]).get('routeSeque').enable();
    // this.currentRow.ChannelCode = this.conceptChannelResultForm.get(['conceptChannel', index]).get('ChannelCode').value;
    // this.currentRow.ChannelDesc = this.conceptChannelResultForm.get(['conceptChannel', index]).get('ChannelDesc').value;
    // console.log('edit row'); console.log( this.currentRow.ChannelDesc); console.log(this.currentRow.ChannelCode);
// if(this.routeDetails != null && this.routeDetails.length>0){
//   console.log('in edit');
//   this.routeResultForm = this.buildrouteResultForm();
//   this.onUpdate(this.disable);
// }
}

private getAbsoluteIndex(index: any) {
  let currentPageNumber = this.currentPage;
  let absoluteIndex = this.itemPerPage * (currentPageNumber - 1) + index;
  return absoluteIndex;
}

onSave(formData:any){
  console.log(this.routeResultForm.value);
  console.log(formData.route[0]);
  let updatedData=formData.route[0];
  this.freightDetailService.updateData(updatedData).subscribe(
    (response : any) => {
      console.log(response);
      if(response.status!== "Failed"){
        this.statusMessage='success';
      this.responseMessage='Data updated successfully';
      }
      else{
        console.log('in else');
        this.statusMessage='error';
        this.responseMessage= 'Data updation failed.'+response.message;
        this.loaDer.nativeElement.style.display = 'none';
        
      }
    }
    ,
    (error) => {
      console.error('some error occured! while fetching freight from server');
      console.log(error);
      // this.routeResultForm = this.buildrouteResultForm();
      this.isDisplayResult = false;
      this.loaDer.nativeElement.style.display = 'none';
    }, () => {
      console.info('all data fetched. ');
      this.loaDer.nativeElement.style.display = 'none';}
  );
  // console.log(this.indexUpdate); console.log('indexUpdate');
  // console.log(this.indexDeactivate); console.log('indexDeactivate');
  // if(this.indexUpdate != undefined){
  // this.saveData = this.routeDetails[this.indexUpdate];
  // console.log(this.saveData); console.log('saveData'); 
  // console.log(this.routeDetails[this.indexUpdate]); console.log('routedetails with indexUpdate');
  // this.saveData.lastUpdatedBy = this.userName;
  
  // }
 
  // else if(this.indexDeactivate != undefined){
  //   this.saveData = this.routeDetails[this.indexDeactivate];
  //   console.log(this.saveData); console.log('saveData'); 
  //   console.log(this.routeDetails[this.indexDeactivate]); console.log('routedetails with indexDeactivate');
  //   this.saveData.lastUpdatedBy = this.userName;
  //   this.saveData.activeStatus = 'N';
  // }
  //  else{
  //  alert('Nothing to update or invalidate');
  // }
}

onDeactivate(event:any, index:any){
  this.indexDeactivate = index;
}

confirmInvalidate(event:any){
  this.onSave(this.routeResultForm.value);
}
onClickBack() {
  this.navigationLinkParameterService.freightDetails="";
  this.navigationLinkParameterService.searchFreightHeaderData=this.headerData;
  this._router.navigate(['freight-master']);
}

countDecimals(x) {
  if(Math.floor(x.valueOf()) === x.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0; 
}
numberCheck(event, name) { 
  var regExpr = /^\d+\.\d{2}$/;
  // var RegularExpression  =  new RegExp(^\d+(\.\d{1,2})?$);
  var userText = event.target.value;
  // console.log(event);
  // console.log(name);
   console.log(userText);
  this.ftwrRtlCb=false;
  this.ftwrWslCb=false;
  this.nonFtwrRtlCb=false;
  this.nonFtwrWslCb=false;
  this.rateCb=false;
  if (userText != "") {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (!isNaN(userText)) {
      console.log('If');
      event.target.value = userText;
    } else {
      console.log('else');
      event.target.value ="";
            
        if (name == "ftwrRtlCb") {      
              this.ftwrRtlCb=true;
        }
        if (name == "ftwrWslCb") {
          this.ftwrWslCb=true;
        }
        if (name == "nonFtwrRtlCb") {
          this.nonFtwrRtlCb=true;
        }
        if (name == "nonFtwrWslCb") {
          this.nonFtwrWslCb=true;
        }
        if (name == "rateCb") {
          this.rateCb=true;
        }
      
    }
    if (event.target.value.indexOf('.') == -1) { return; }
    // console.log(event.target.value.length);
    // console.log(event.target.value.indexOf('.'));
    var lth=new String(userText.split(".")[1]);
    console.log(lth.length);

    if (lth.length > 2) {
          // event.target.value = parseFloat(event.target.value).toFixed(2);
          event.target.value ="";
            
        if (name == "ftwrRtlCb") {      
              this.ftwrRtlCb=true;
        }
        if (name == "ftwrWslCb") {
          this.ftwrWslCb=true;
        }
        if (name == "nonFtwrRtlCb") {
          this.nonFtwrRtlCb=true;
        }
        if (name == "nonFtwrWslCb") {
          this.nonFtwrWslCb=true;
        }
        if (name == "rateCb") {
          this.rateCb=true;
        }
      }
  }
}

}
