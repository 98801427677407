import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReportHeader } from './report-header';
import { GenerateReportService } from './generate-report.service';
//import { SSL_OP_NO_SESSION_RESUMPTION_ON_RENEGOTIATION } from 'constants';
import { ReportGeneration } from '@/common/report-generation';
import { RequestParameterModel } from '../common/request-parameter-model';
import { UtilsService } from '../common/utils-service';
import { ReportSearch } from './report-header-search';
import { Concept } from '../common/concept';
import { ReportNames } from '../common/reports-names';
import { OrderType } from '../common/orderType';


@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.css']
})
export class GenerateReportComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  // reportName = ['Purchase Order Printing', 'Factory Operations Unit(By Order Week/Year)', 'Factory Operations Otb(By Delivery Week/Year)',
  //   'Estimates Report', 'Future Order Report', 'On Order Size For Distributor', 'Onway Report',
  //   'Order Follow Invoice', 'Recap Order Vs Rdc Recv', 'Order Status Report', 'OutStanding For Distributor',
  //   'Supply/Rdc Recv Report', 'Rtl Order Onway', 'Supplier Performance Report', 'Master List PO'];

  formats = [{ name: "Excel", value: 'excel' },
  { name: "Pdf", value: 'Pdf' }];
  seasons = [{ name: "Season-I", value: "01" },
  { name: "Season-II", value: "02" }];
  groupCodes = [{ id: "1", value: "A" }, { id: "2", value: "B" }, { id: "3", value: "C" },
  { id: "4", value: "D" }, { id: "5", value: "E" }];
  masterTable = ['Article Master', 'Category Master', 'Party Master',
    'Shop Master', 'State Master', 'Week Master'];
  reportTypes = ['ALL', 'CONT', 'IMPORT', 'UNIT'];
  assortmentType = [{ name: "Assorted", value: "A" },
  { name: "Wholesale Assorted", value: "W" }];
  reportModel = new ReportHeader();
  requestParameterModel: RequestParameterModel[];
  searchParameterModel = new ReportSearch();
  searchParameterModelEstimate = new ReportSearch();
  concepts: Concept[];
  conceptswithAll: any[] = [];
  orderTypeWithAll: any[] = [];
  conceptsForRetail: any[] = [];
  reportNames: ReportNames[];
  //loading:boolean=false;
  username: string;
  orderTypes: OrderType[] = [];
  coCode: any;
  responseMessage: string;
  suppNoLength: boolean = false;
  asyncSuccess: string = 'Failed';
  loginUserEmail: string;
  generatebutton: string = 'enable';

  constructor(private _router: Router, private _generateReportService: GenerateReportService,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    this.reportModel.reportFormatType = 'excel';

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ "name": "email", "value": JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.loginUserEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        this.reportNames = data;
      }
    )

    this._generateReportService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        const distinctConcept = this.concepts.filter(
          (thing, i, arr) => arr.findIndex(t => t.concCode === thing.concCode) === i
        ); 
        this.concepts = [];
        this.concepts = distinctConcept;
        this.concepts.forEach(
          concept => {
            this.conceptswithAll.push(concept);
            if (concept.division == 'RETAIL' && concept.concCode != 'N') {
              this.conceptsForRetail.push(concept);
            }
          }
        );
        let conc = new Concept();
        conc.concCode = 'ALL';
        conc.description = 'ALL CONCEPT';
        this.conceptswithAll.push(conc);
      }
    )

    this._generateReportService.getOrderTypes().subscribe(
      (data: OrderType[]) => {
        this.orderTypes = data;
        this.orderTypes.forEach(
          ord => {
            this.orderTypeWithAll.push(ord);
          }
        );
        let ordType = new OrderType();
        ordType.code = 'ALL';
        ordType.description = 'ALL';
        this.orderTypeWithAll.push(ordType);
        console.log(JSON.stringify(this.orderTypes));
      }
    )
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  public async OnClickGenerate(myForm): Promise<void> {
    this.responseMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.loaDer.nativeElement.style.display = 'block';
    let switchFlag: any;
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportName == this.reportModel.report).forEach(
      report => {
        switchFlag = report.switchingFlag;
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    if (this.reportModel.report == 'Estimates Report') {
      this.searchParameterModel.estSession = this.searchParameterModelEstimate.session + this.searchParameterModelEstimate.year;
    }
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    let userDetails = {
      "name": "userId",
      "value": this.username
    }
    this.requestParameterModel.push(userDetails);
    // if (switchFlag == 'N') {
    //   if (this.reportModel.report != 'Packing Master') {
    //     this.requestParameterModel.push(userDetails);
    //   }
    // }
    // else {
    //   this.requestParameterModel.push(userDetails);
    // }
    // if (this.reportModel.report == 'State Master' || this.reportModel.report == 'Week Master') {
    //   if (switchFlag == 'N') {
    //     this.requestParameterModel = [
    //       {
    //         "name": "",
    //         "value": ""
    //       }
    //     ]
    //   }
    //   else {
    //     let dummyDetails = 
    //       {
    //         "name": "dummy",
    //         "value": "dummy"
    //       }
    //       this.requestParameterModel.push(dummyDetails);
    //   }
    // }
    this.requestParameterModel.filter(cs => cs.name == 'packCode').forEach(
      checkPack => {
        if (this.searchParameterModel.assortmentType == 'W') {
          checkPack.name = 'assortmentCode';
        }
      }
    )
    this.requestParameterModel.filter(cs => cs.name == 'assosiateNumber').forEach(
      checkPack => {
        this.suppNoLength = false;
        let length = checkPack.value.length;
        if (length == 3 || length == 5) {
          this.suppNoLength = true;
        }
      }
    )
    console.log('requesting parameters inside updatedDatalist' + JSON.stringify(this.requestParameterModel));
    let details = new ReportGeneration();
    if (switchFlag == 'N') {
      console.log('Report will get generated online');
      details.reportFormatType = this.reportModel.reportFormatType;
      details.reportInputRequestList = this.requestParameterModel;
      if (this.reportModel.report != 'Purchase Order Printing') {
        if (this.reportModel.report != 'Supplier Performance Report') {
          this.callingServiceWithoutBlob(details);
          // if(this.reportModel.report != 'Supply Master' && this.reportModel.report != 'Article Master' && 
          // this.reportModel.report != 'Category Master' && this.reportModel.report != 'Party Master' &&
          // this.reportModel.report != 'Shop Master' && this.reportModel.report != 'Concept Master' && this.reportModel.report != 'State Master' &&
          // this.reportModel.report != 'Week Master' && this.reportModel.report != 'Packing Master'){
          // const blob = await this._generateReportService.getReportDetails(details, this.reportModel.report);
          // const url = window.URL.createObjectURL(blob);
          // var anchor = document.createElement("a");
          // if (this.reportModel.reportFormatType == 'excel') {
          //   if (this.reportModel.report != 'Purchase Order Printing') {
          //     anchor.download = this.reportModel.report + '.' + 'xls';
          //   }
          //   else {
          //     let currentDate: string = this._utilsService.getCurrentFormatDate();
          //     let value = this.searchParameterModel.orderNoFrm + '_' + this.searchParameterModel.orderNoTo + '_' + currentDate;
          //     anchor.download = value + '.' + 'xls';
          //   }
          // }
          // if (this.reportModel.reportFormatType == 'Pdf') {
          //   if (this.reportModel.report != 'Purchase Order Printing') {
          //     anchor.download = this.reportModel.report + '.' + 'pdf';
          //   }
          //   else {
          //     let currentDate: string = this._utilsService.getCurrentFormatDate();
          //     let value = this.searchParameterModel.orderNoFrm + '_' + this.searchParameterModel.orderNoTo + '_' + currentDate;
          //     anchor.download = value + '.' + 'pdf';
          //   }
          // }
          // anchor.href = url;
          // anchor.click();
          // window.URL.revokeObjectURL(url);
          // this.loaDer.nativeElement.style.display = 'none';      
          // }
          // else{
          //   let reportDetails = 
          //     {
          //       "name": "reportName",
          //       "value": reportName
          //     }
          //     this.requestParameterModel.push(reportDetails);
          //     let reportKeySelected = 
          //     {
          //       "name": "reportKey",
          //       "value": reportKey
          //     }
          //   this.requestParameterModel.push(reportKeySelected);
          //   this.loginUserEmail
          //   let userSelectedEmail = 
          //     {
          //       "name": "userEmail",
          //       "value": this.loginUserEmail
          //     }
          //   this.requestParameterModel.push(userSelectedEmail);
          //   details.reportInputRequestList = this.requestParameterModel;
          //   console.log(JSON.stringify(details));
          //   this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.report).subscribe();
          //   this.asyncSuccess = 'success';
          //   this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          //   this.loaDer.nativeElement.style.display = 'none';
          //   this.generatebutton = 'disable';
          //   this.delay(2000).then(any=>{
          //     //your task after delay.
          //     this.generatebutton = 'enable';
          //    });
          //   // (response:any) => {
          //   //   if(response.status == 'Success'){
          //   //     this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          //   //   }
          //   //   else{
          //   //     this.responseMessage = response.message;
          //   //   }
          //   //   this.loaDer.nativeElement.style.display = 'none';
          //   // },
          //   // (err) => {
          //   //   this.responseMessage = err;
          //   //   this.loaDer.nativeElement.style.display = 'none';
          //   // } 
          //   // );
          // }
        }
        else {
          if (this.searchParameterModel.assosiateNumber != undefined) {
            if (this.suppNoLength == true) {
              this.callingServiceWithoutBlob(details);
              // const blob = await this._generateReportService.getReportDetails(details, this.reportModel.report);
              // const url = window.URL.createObjectURL(blob);
              // var anchor = document.createElement("a");
              // if (this.reportModel.reportFormatType == 'excel') {
              //   anchor.download = this.reportModel.report + '.' + 'xls';
              // }
              // if (this.reportModel.reportFormatType == 'Pdf') {
              //   anchor.download = this.reportModel.report + '.' + 'pdf';
              // }
              // anchor.href = url;
              // anchor.click();
              // window.URL.revokeObjectURL(url);
              // this.loaDer.nativeElement.style.display = 'none';
            }
            else {
              this.asyncSuccess = 'Failed';
              this.responseMessage = 'Supplier Number should be of 3 or 5 digit';
              this.loaDer.nativeElement.style.display = 'none';
            }
          }
          else {
            this.callingServiceWithoutBlob(details);
            // const blob = await this._generateReportService.getReportDetails(details, this.reportModel.report);
            // const url = window.URL.createObjectURL(blob);
            // var anchor = document.createElement("a");
            // if (this.reportModel.reportFormatType == 'excel') {
            //   anchor.download = this.reportModel.report + '.' + 'xls';
            // }
            // if (this.reportModel.reportFormatType == 'Pdf') {
            //   anchor.download = this.reportModel.report + '.' + 'pdf';
            // }
            // anchor.href = url;
            // anchor.click();
            // window.URL.revokeObjectURL(url);
            // this.loaDer.nativeElement.style.display = 'none';
          }
        }
      }
      else {
        let conceptFromPo: string = this.searchParameterModel.orderNoFrm.substr(0, 1);
        let conceptFromIndexCheck = this.coCode.indexOf(conceptFromPo);
        let conceptToPo: string = this.searchParameterModel.orderNoTo.substr(0, 1);
        let conceptToIndexCheck = this.coCode.indexOf(conceptToPo);
        if (conceptFromIndexCheck >= 0 && conceptToIndexCheck >= 0) {
          const blob = await this._generateReportService.getReportDetails(details, this.reportModel.report);
          const url = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          if (this.reportModel.reportFormatType == 'excel') {
            if (this.reportModel.report != 'Purchase Order Printing') {
              anchor.download = this.reportModel.report + '.' + 'xls';
            }
            else {
              let currentDate: string = this._utilsService.getCurrentFormatDate();
              let value = this.searchParameterModel.orderNoFrm + '_' + this.searchParameterModel.orderNoTo + '_' + currentDate;
              anchor.download = value + '.' + 'xls';
            }
          }
          if (this.reportModel.reportFormatType == 'Pdf') {
            if (this.reportModel.report != 'Purchase Order Printing') {
              anchor.download = this.reportModel.report + '.' + 'pdf';
            }
            else {
              let currentDate: string = this._utilsService.getCurrentFormatDate();
              let value = this.searchParameterModel.orderNoFrm + '_' + this.searchParameterModel.orderNoTo + '_' + currentDate;
              anchor.download = value + '.' + 'pdf';
            }
          }
          anchor.href = url;
          anchor.click();
          window.URL.revokeObjectURL(url);
          this.loaDer.nativeElement.style.display = 'none';
          //this.checkGenerate(details);
        }
        else {
          this.asyncSuccess = 'Failed';
          this.responseMessage = 'Concept access is not available';
          this.loaDer.nativeElement.style.display = 'none';
        }
      }
    }
    else {
      console.log('Report will get generated offline');
      let detail = {
        "name": "reportKey",
        "value": reportKey
      }
      let nameDetail = {
        "name": "reportName",
        "value": reportName
      }
      this.requestParameterModel.push(detail);
      this.requestParameterModel.push(nameDetail);
      console.log(JSON.stringify(this.requestParameterModel));
      this._generateReportService.offlineReportGeneration(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          if (response.status == 'success') {
            // this.responseMessage = response.message;
            this.asyncSuccess = 'success';
            this.responseMessage = "Your report will be generated soon, you will be notified over mail. You can check the progress in View Report Status.";
          }
          else {
            this.asyncSuccess = 'Failed';
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.asyncSuccess = 'Failed';
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
  }

  callingServiceWithoutBlob(details) {
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportName == this.reportModel.report).forEach(
      report => {
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    let reportDetails =
    {
      "name": "reportName",
      "value": reportName
    }
    this.requestParameterModel.push(reportDetails);
    let reportKeySelected =
    {
      "name": "reportKey",
      "value": reportKey
    }
    this.requestParameterModel.push(reportKeySelected);
    this.loginUserEmail
    let userSelectedEmail =
    {
      "name": "userEmail",
      "value": this.loginUserEmail
    }
    this.requestParameterModel.push(userSelectedEmail);
    details.reportInputRequestList = this.requestParameterModel;
    console.log(JSON.stringify(details));
    this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.report).subscribe();
    this.asyncSuccess = 'success';
    this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
    this.loaDer.nativeElement.style.display = 'none';
    this.generatebutton = 'disable';
    this.delay(2000).then(any => {
      //your task after delay.
      this.generatebutton = 'enable';
    });
  }

  onClickReset() {
    console.log('reset report search details');
    this.responseMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    this.searchParameterModelEstimate = new ReportSearch();
    if (this.reportModel.report != 'Purchase Order Printing') {
      this.reportModel.reportFormatType = 'excel';
    }
    else {
      this.reportModel.reportFormatType = 'Pdf';
    }
  }

  setData() {
    this.responseMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    if (this.reportModel.report != 'Purchase Order Printing') {
      this.reportModel.reportFormatType = 'excel';
    }
    else {
      this.reportModel.reportFormatType = 'Pdf';
    }
  }

  onAssortTypeSelect() {
    if (this.reportModel.report == 'Packing Master' && this.searchParameterModel.assortmentType == 'W') {
      this.searchParameterModel.catCode = null;
      this.searchParameterModel.shopCode = null;
      this.searchParameterModel.concept = null;
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }
}
