export class RdsBilApiUrl {
  //public static BASE_URL:string = 'https://130.61.72.144:8011/';
  public static BASE_URL: string = "https://biscs.bata.com/";

  public static RDC_SEARCH_LINES: string =
    RdsBilApiUrl.BASE_URL + "shipment/queryShipmentdetail";
  public static RDC_BATA_WEEK_LIST: string =
    RdsBilApiUrl.BASE_URL + "mdm/weekMasterQueryList";
  public static UPLOAD_ASN_EXCEL_FILE: string =
    RdsBilApiUrl.BASE_URL + "utility/uploadToStorage";
  public static UPLOAD_TO_STORAGE: string =
    RdsBilApiUrl.BASE_URL + "utility/uploadToStorage";
  public static SEARCH_RESUPPLY: string =
    RdsBilApiUrl.BASE_URL + "resup/searchResupply";
  public static UPDATE_FULFILL_QTY: string =
    RdsBilApiUrl.BASE_URL + "resup/updateFulfillQty";
  public static RDC_CREATE_GIN: string = RdsBilApiUrl.BASE_URL + "shipment/gin";
  public static RDC_SEARCH_INVOICE: string =
    RdsBilApiUrl.BASE_URL + "invoice/searchInvoice";
  public static TRANSPORTER_NAME: string =
    RdsBilApiUrl.BASE_URL + "common/getTransportMaster";
  public static GIN_NUMBER: string = RdsBilApiUrl.BASE_URL + "shipment/gin";
  public static BARCODE_SCAN: string =
    RdsBilApiUrl.BASE_URL + "inventory/searchBarcodeScan";
  public static UPDATE_TRANSPORT: string =
    RdsBilApiUrl.BASE_URL + "shipment/updateShipment";
  public static GENERATE: string =
    RdsBilApiUrl.BASE_URL + "shipment/loadShipmentOnline";
  public static GIN_SEARCH: string =
    RdsBilApiUrl.BASE_URL + "shipment/fetchGin";
  public static VALIDATE_BARCODE_DATA: string =
    RdsBilApiUrl.BASE_URL + "inventory/validateGRN";
  public static INVENTORY_SEARCH: string =
    RdsBilApiUrl.BASE_URL + "inventory/searchInventory";
  // public static SEARCH_LOCATION_URL: string =
  //   RdsBilApiUrl.BASE_URL + "common/queryShopMasterByUser";
  public static SEARCH_LOCATION_URL: string =
    RdsBilApiUrl.BASE_URL + "common/queryShopMasterByRdcOrDepotUser";
  public static CREATE_GRN_WITH_REMARKS: string =
    RdsBilApiUrl.BASE_URL + "inventory/grn";
  public static SEARCH_GRN: string =
    RdsBilApiUrl.BASE_URL + "inventory/findGrn";
  public static SAVE_DATA: string =
    RdsBilApiUrl.BASE_URL + "inventory/uploadBarcodeScan";
  public static GST_DATA: string = RdsBilApiUrl.BASE_URL + "/invoice/gstRate";
  public static INSERT_GST: string =
    RdsBilApiUrl.BASE_URL + "invoice/insertGSTMaster";
  public static UPDATE_GST: string =
    RdsBilApiUrl.BASE_URL + "invoice/updateGSTMaster";
  public static RDS_BATCH_LIST: string =
    RdsBilApiUrl.BASE_URL + "common/getBilItfDetails";
  public static USER_GROUP_LIST: string =
    RdsBilApiUrl.BASE_URL + "common/searchGroupMaster";
  public static SCREEN_LIST: string =
    RdsBilApiUrl.BASE_URL + "common/queryScreenList";
  public static ACCESS_LIST: string =
    RdsBilApiUrl.BASE_URL + "common/queryUserAccess";
  public static SAVE_BILLGROUP_SCREEN_DETAILS: string =
    RdsBilApiUrl.BASE_URL + "common/saveBilGroupScreenDetails";
  public static CONCEPT_LIST_URL: string =
    RdsBilApiUrl.BASE_URL + "common/queryConceptMasterByUser";
  public static BIL_GROUP_SCREEN_DETAILS_BY_GROUPID: string =
    RdsBilApiUrl.BASE_URL + "common/getBilGroupScreenDetailsByGroupId";
  public static CYCLE_COUNT_DETAILS_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/getTransactionDetailByTransactionType";
  public static CONFIG_DETAILS_URL: string =
    RdsBilApiUrl.BASE_URL + "mdm/getConfigDetails";
  public static TRANSPORTER_NAME_FETCH: string =
    RdsBilApiUrl.BASE_URL + "mdm/getTransporterDetails";
  public static SHOP_MASTER_URL: string =
    RdsBilApiUrl.BASE_URL + "mdm/queryShopMasterNew";
  public static PARTY_MASTER_URL: string =
    RdsBilApiUrl.BASE_URL + "mdm/querypartymaster";
  public static STOCK_ADJUSTMENT_DETAILS_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/findGrnHavingShortageAmount";
  public static CONCEPT_DIVISION_URL: string =
    RdsBilApiUrl.BASE_URL + "mdm/queryconceptmaster";
  public static PERFORM_SHORTAGE_ADJUSTMENT_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/performShortageAdjustment";
  public static  PERFORM_SHORTAGE_ADJUSTMENT_CLAIMED_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/performShortageAdjustmentClaim";
  public static  PERFORM_SHORTAGE_ADJUSTMENT_DEFECTIVE_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/performShortageAdjustmentDefective";  
  public static SEARCH_FINANCE_DETAILS: string =
    RdsBilApiUrl.BASE_URL + "common/searchFinanceScratching";
  public static DISCARD_FINANCE_DETAILS: string =
    RdsBilApiUrl.BASE_URL + "common/updateFinanceScratching";
  public static SHIPMENT_CREATION_URL: string =
    RdsBilApiUrl.BASE_URL + "invoice/asnCreation ";
  public static GENERATE_NIL_STOCK: string =
    RdsBilApiUrl.BASE_URL + "inventory/createNilStock";
  public static GENERATE_FINANCE_SCRAPPING: string =
    RdsBilApiUrl.BASE_URL + "common/generateFinanceScrapping";
  public static SEARCH_DEBIT_CREDIT_TRANSACTION: string =
    RdsBilApiUrl.BASE_URL + "inventory/searchDebitCreditTransaction";
  public static SEARCH_NIL_STOCK: string =
    RdsBilApiUrl.BASE_URL + "inventory/searchNilStock";
  public static PERFORM_APPROVAL_OF_DEBIT_CREDIT_NOTE: string =
    RdsBilApiUrl.BASE_URL + "inventory/performApprovalOfDebitCreditNote";
  public static PERFORM_APPROVAL_OF_DEBIT_CREDIT_NOTE_CLAIM: string =
    RdsBilApiUrl.BASE_URL + "inventory/performApprovalOfDebitCreditNoteClaim";
  public static PERFORM_APPROVAL_OF_DEBIT_CREDIT_NOTE_DEFECTIVE: string =
    RdsBilApiUrl.BASE_URL + "inventory/performApprovalOfDebitCreditNoteDefective";  
  public static COMPLETE_SHIPMENT: string =
    RdsBilApiUrl.BASE_URL + "invoice/completeInvoice";
  public static STOP_SHIPMENT: string =
    RdsBilApiUrl.BASE_URL + "/invoice/stopShipment";
  public static lOAD_SUPPLY_VENDOR_ASN: string =
    RdsBilApiUrl.BASE_URL + "shipment/loadSupplyAsn";
  public static GENERATE_STOCK_ADJUSTMENT: string =
    RdsBilApiUrl.BASE_URL + "inventory/stockAdjustmentManual";
  public static ASN_LEGACY_ACK: string =
    RdsBilApiUrl.BASE_URL + "shipment/asnLegacyAcknowledgement";
  public static SAVE_MANUAL_GRN_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/createGrnFromAsn";
    public static SAVE_MANUAL_GRN_CLAIM_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/createGrnFromAsnClaim";
  public static SAVE_MANUAL_GRN_DEFECTIVE_API_URL: string =
    RdsBilApiUrl.BASE_URL + "inventory/createGrnFromAsnDefective";   
  public static SAVE_UPDATE_ASN_URL: string =
    RdsBilApiUrl.BASE_URL + "shipment/updateAsn";
  public static GENERATE_GRN_DAMAGE: string =
    RdsBilApiUrl.BASE_URL + "inventory/uploadGrnConsignmentDamage";
  public static GENERATE_ITO_STN: string =
    RdsBilApiUrl.BASE_URL + "resup/uploadItoStn";
  public static SEARCH_ROUTE_MASTER: string = 
    RdsBilApiUrl.BASE_URL + "mdm/searchRouteMaster";
  public static SEARCH_FREIGHT_MASTER: string = 
    RdsBilApiUrl.BASE_URL + "mdm/searchFreightMaster";
  public static UPDATE_FREIGHT_MASTER: string = 
    RdsBilApiUrl.BASE_URL + "mdm/freightMaster";
  public static SEARCH_RDC_MAINTENANCE: string = 
    RdsBilApiUrl.BASE_URL + "mdm/getLogfireTransformDetails";
  public static SAVE_RDC_MAINTENANCE: string =
    RdsBilApiUrl.BASE_URL + "mdm/updateLogFireAsnTransform"; 
  public static CREATE_RDC_MAINTENANCE: string =
    RdsBilApiUrl.BASE_URL + "mdm/createLogFireAsnTransform"; 
  public static RM_40_OUTBOUND_JOB: string =
    RdsBilApiUrl.BASE_URL + "inventory/invokeRm40Job";  
  public static SHORTAGE_ADJUSTMENT_JOB: string =
    RdsBilApiUrl.BASE_URL + "inventory/processShortageAdjustmentJob";  
  public static INTER_TRANSFER_RIMS_OUTBOUND: string =
    RdsBilApiUrl.BASE_URL + "resup/invokeRdcInterTransferOutbound";  
  public static STOCK_VALUATION_OUTBOUND_JOB: string =
    RdsBilApiUrl.BASE_URL + "inventory/processStockValuation ";   
  public static SEARCH_INVENTORY_BIFURCATION_URL: string =
    RdsBilApiUrl.BASE_URL + "common/getInventoryBifurcationRatio";   
  public static SEARCH_PARENT_LOCATION_URL: string =
    RdsBilApiUrl.BASE_URL + "mdm/fetchParentRdc";       
  public static ADD_INVENTORY_BIFURCATION_URL: string =
    RdsBilApiUrl.BASE_URL + "common/addInventoryBifurcationRatio";  
  public static RDC_CITY_NAME_URL: string =
    RdsBilApiUrl.BASE_URL + "common/fetchCities";
  public static RDC_NO_FROM_CITY_URL: string =
    RdsBilApiUrl.BASE_URL + "common/fetchRdcByCity";     
  public static UPDATE_ROUTE_MASTER: string = 
    RdsBilApiUrl.BASE_URL + "mdm/updateRouteMaster"; 
  public static GENERATE_ROUTE_MAStER: string =
    RdsBilApiUrl.BASE_URL + "mdm/uploadRouteMasterData";   
  public static ITF_DETAIL: string =
    RdsBilApiUrl.BASE_URL + "common/getBilItfDetails";
  public static  UPLOAD_URL_RFUC:string=
    RdsBilApiUrl.BASE_URL+'resup/uploadItoStnFactoryClaimed';
  public static  NIL_STOCK_DEFECTIVE_UPLOAD:string=
    RdsBilApiUrl.BASE_URL+'resup/uploadItoStnDefective';
  public static SERACH_CLAIM_TXN:string=
    RdsBilApiUrl.BASE_URL+'resup/searchClaimTxn';
  public static FETCH_CLAIM_TXN:string=
    RdsBilApiUrl.BASE_URL+'resup/fetchClaimTxnTemplate';
  public static DEFECTIVE_ARTICLES_TXN_TEMPLATE:string=
    RdsBilApiUrl.BASE_URL+'resup/downloadDefectiveTxnTemplate';
  public static SEARCH_DEFECTIVE_ARTICLES:string=
    RdsBilApiUrl.BASE_URL+'resup/searchDefectiveArticles';
  public static ASN_LOGFIRE_OUTBOUD:string=
    RdsBilApiUrl.BASE_URL+'shipment/invokeShipmentOutBoundJob';
  public static RESUP_ITO_LOGFIRE_OUTBOUD:string=
    RdsBilApiUrl.BASE_URL+'resup/invokeOutboundLogfireJob'; 
  public static GENERATE_GRN: string =
    RdsBilApiUrl.BASE_URL + "inventory/uploadGrnImport";
  public static GST_STATUS_CHANGE_URL:string=
    RdsBilApiUrl.BASE_URL+'/invoice/deactivateGst'; 
  public static FREIGHT_ADDITIONAL_INFO:string=
    RdsBilApiUrl.BASE_URL+'invoice/freightAddlInfo';
  public static PRINT_CREDIT_NOTE:string=
    RdsBilApiUrl.BASE_URL+'report/rdc/rdcPrintCreditNoteReport';
  public static PRINT_DEBIT_NOTE:string=
    RdsBilApiUrl.BASE_URL+'report/rdc/rdcPrintDebitNoteReport';
  public static SEARCH_FREIGHT_ADDITIONAL_INFO:string=
    RdsBilApiUrl.BASE_URL+'invoice/searchFreightAddlInfo';
  public static PRINT_INVOICE_REPORT_URL:string=
    RdsBilApiUrl.BASE_URL+'report/rdc/printInvoiceReport';
  public static NIL_STOCK_REPORT_URL:string=
    RdsBilApiUrl.BASE_URL+'report/rdc/rdcNilStockReport';
  public static CANCEL_RESUPPLY:string=
    RdsBilApiUrl.BASE_URL+'resup/cancelResupply';
  public static MANUAL_DISPATCH:string=
    RdsBilApiUrl.BASE_URL+'resup/manualDispatch';
  public static SHIPMENT_CREATION_URL_ASYN: string =
    RdsBilApiUrl.BASE_URL + "invoice/asnCreationAsync";
  public static EWAY_ADDITIONAL_INFO: string =
    RdsBilApiUrl.BASE_URL + "invoice/ewayAdditionalInfo";
  public static GRN_LOGFIRE_INBOUND: string =
    RdsBilApiUrl.BASE_URL + "inventory/logfireGrnCreate";
  public static IMPORT_GSTR_UPLOAD: string =
    RdsBilApiUrl.BASE_URL + "inventory/importGstr21";
  public static DISPATCH_LOGFIRE_INBOUND: string =
    RdsBilApiUrl.BASE_URL + "resup/invokeDispatch";  
  public static UPDATE_GATE_PASS_DETAILS: string =
    RdsBilApiUrl.BASE_URL + "invoice/searchUpdateInvoiceHeader";

}
