import { Injectable } from '@angular/core';
import { BilApiUrl } from '@/common/bil-api-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageSizeMasterCreateService {
  private _add_size_master_url: string = BilApiUrl.ADD_SIZE_MASTER;
  constructor(private _http: HttpClient) { }
  addSizeMaster(add_size_master_param:any[]){
    return this._http.post(this._add_size_master_url,add_size_master_param);
  }
}
