import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { UtilsService } from '../../common/utils-service';
import { Router, RouterModule } from '@angular/router';
import { ItfNames } from '../../common/itf-names';
// import { ReportHeader } from '../rds-report/report-header';
// import { RdsReportService } from '../rds-report/rds-report.service';
import { ReprocessJobService } from './reprocess-job.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-reprocess-job',
  templateUrl: './reprocess-job.component.html',
  styleUrls: ['./reprocess-job.component.css']
})
export class ReprocessJobComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  viewReportStatusForm: FormGroup;
  details: any;
  responseMessage: string;
  statusMessage:string;
  itfNames:ItfNames[];
  // reportModel = new ReportHeader();
  userRole: string;
  username: string;
  itfDownloadName:any;
  jobNumber:string;

  constructor(private fb: FormBuilder,private _router: Router,
    // private _generateReportService: RdsReportService,
    private reprocessJobService: ReprocessJobService, private httpClient: HttpClient, private _utilsService: UtilsService) {
    this.viewReportStatusForm = this.buildForm();
  }

  buildForm() {
    return this.fb.group({
      itfKey : ['',[Validators.required]],
      jobNumber:['',[Validators.required]]
     });
  }
  resetForm(){
    this.buildForm();
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 1000);
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userRole=JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    this.username =  JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    console.log(sessUsername);
    let param = [{"name":"itfModule","value":"RDS"},{"name":"reprocessingFlag","value":"Y"}];
    this.reprocessJobService.getBilItfDetails(param).subscribe(
      (data: ItfNames[]) => {
        console.log(data);
        this.itfNames = data['data'];
        // this.viewReportStatusForm.controls.reportName.setValue("");
      }
    );
  }
  get fval() {
    return this.viewReportStatusForm.controls;
  }

  fetchDetailsOnSubmit() {
    console.log(this.viewReportStatusForm.controls);
    this.responseMessage = ''; 
    this.details = '';
    this.loaDer.nativeElement.style.display = 'block';
    let itfName = this.viewReportStatusForm.controls.itfKey.value;
    console.log(itfName);
    console.log(this.viewReportStatusForm.value);
    this.reprocessJobService.invokeProcess(this.viewReportStatusForm.value).subscribe(
      (data: any) => {
        console.log(data);
        // this.responseMessage = data.message;
        if(data.status=="Success"){
          this.responseMessage = data.message;
          this.statusMessage="success";
        }else{
          this.responseMessage = data.message;
          this.statusMessage="error";
        }
        
        this.loaDer.nativeElement.style.display = 'none';
      },
      (error) => {
        console.log(error);
        console.log('hjg');
        this.statusMessage="error";
        this.responseMessage = error;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  public async downloadReport(reportDownloadUrl,reportname,filename): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    const file = await this.httpClient.get<Blob>(reportDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    if(reportname != 'Outward gate pass report '){
      anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'xlsx';
    }
    else{
    // let updatedFilename = filename.toString().substr(14,14);
    // let fileYRWK = updatedFilename.toString().substr(0,6);
    //   anchor.download = 'RTLORDER_ONWAY_' + fileYRWK + '.' + 'xlsx';
    anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'pdf';
    }
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none';
  }

  characterCheck(event, name) {
     var regExpr = /[^a-zA-Z0-9_ ]/g;
    var userText = event.target.value;
    if(this.fval.itfKey.value == "ASN_Logfire_Outbound" || this.fval.itfKey.value == "RESUP_ITO_Logfire_Outbound"){
    if (userText != "") {
      if (name == "jobNumber") {
        this.viewReportStatusForm.controls["jobNumber"].setValue(
          userText.replace(regExpr, "")
        );
        
      }
    }}
  }

  onChange(value){
    this.viewReportStatusForm.get('jobNumber').setValue('');
  }

}