export class UserConceptSearchDetails {
    activeFlag:string;
    conceptCode:string;
    conceptDesc:string;
    lastUpdateDate:string;
    lastUpdatedBy:string;
    userCode:string;
    userConceptId:string;
    userEmail:string;
    userId:string;
    userName:string;
    conceptName:string;
    updatedBy:string;
    constructor(){}
}