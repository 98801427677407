import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { PendingNilStockService } from './pending-nil-stock.service';
import { UtilsService } from '@/common/utils-service';
import { Search } from './data';
import { from } from 'rxjs';
@Component({
  selector: 'app-pending-nil-stock',
  templateUrl: './pending-nil-stock.component.html',
  styleUrls: ['./pending-nil-stock.component.css']
})
export class PendingNilStockComponent implements OnInit {
  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;

  nilStockForm: FormGroup;
  stocktypes: any;
  flag: boolean = false;
  isDisplayResult: boolean = false;
  statusMessage: string;
  responseMessage: string;
  searchObj: any[] = [];
  userCode: string;
  concepts: any;
  dataDiv = new Search();
  division: string;
  ShopCodes: any[] = [];
  enable = false;
  details = [];
  stoc: any;
  nilStkTranNumber: any;
  assortData: any;

  constructor(private router: Router, private pendingNilStockService: PendingNilStockService, private fb: FormBuilder, private _utilsService: UtilsService) {
    this.nilStockForm = this.buildnilStockForm();
   }

  ngOnInit() {

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = "none";
      let sessUsername = sessionStorage.getItem('currentUser');
      var conceptQueryMas = [{"name":"email","value":JSON.parse(sessUsername).groupAssignmentModels[0].userCode}];

      this.pendingNilStockService.getConcepts(conceptQueryMas).subscribe(
        (data: any) => {
          this.concepts = data.dataList;
        }
      );

      this.pendingNilStockService.getStockType().subscribe(
        (data: any) => {
         this.stoc = data.configParamModels;
         this.stocktypes = this.stoc.filter(u => u.paramId == '133' || u.paramId == '134');
        });
      }, 100);
  }

  buildnilStockForm() {
    return this.fb.group({
      articleNumber: [],
      stockType: [],
      conceptCode: [],
      rdcDepotCode: []
    });
  }

  onSubmit(formData: any) {

    let searchObj: any = {
         "articleNumber": this.nilStockForm.get('articleNumber').value,
         "stockType": this.nilStockForm.get('stockType').value,
         "conceptCode": this.nilStockForm.get('conceptCode').value,
         "rdcDepotCode": this.nilStockForm.get('rdcDepotCode').value
       };
     
     console.log(searchObj); console.log('searchObj');
    
     for (var value in searchObj) {
      if(searchObj[value] !== null && searchObj[value] != ""){
        this.flag = true;
        }
      }
    if(this.flag == false){
      this.statusMessage='Error';
      this.responseMessage='Please select any of the search fields';
      return false;
    }
     this.pendingNilStockService.nilStockSearch(searchObj).subscribe(
       (response: any) => {
         if(response.count!=0 && response.count!=null){
        
         this.details = response.dataList;
         console.log(this.details);
         
        // this.searchObj = response.dataList;
         this.isDisplayResult = true;
         this.responseMessage='';
         this.details.forEach(
           d=>{
             console.log(' search response ' + JSON.stringify(d));
           }
         );}
         else{
           this.statusMessage='Error';
           this.responseMessage= 'No Data Found';
           this.isDisplayResult = false;
           this.details = [];
         }
         //console.log('control');console.log(control);
       }, (error) => {
         console.error('some error occured! while fetching discounts from server');
         this.nilStockForm = this.buildnilStockForm();
         this.isDisplayResult = false;
       }, () => {
         console.info('all fetched. ');}
     );
   }

  onClickBack() { this.router.navigate(['']); }

  resetForm(){
    this.isDisplayResult = false;
    this.responseMessage='';
    // this.modalMessage='';
    this.statusMessage='';
    this.flag=false;
    this.buildnilStockForm();
    this.enable = false;
    this.details = [];
  
  } 

  onSelect() {
    this.loaDer.nativeElement.style.display = 'block';
    let divisionCode: string;
    this.concepts.filter(cs => cs.concCode === this.nilStockForm.get('conceptCode').value).forEach(
      div => {
        console.log(div.division);
        
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
        const json_string_search = JSON.stringify(div);
        const json_obj = JSON.parse(json_string_search);
        this.dataDiv.division = json_obj.division;
        this.division = json_obj.division;
      }
    )
    let sessUsername = sessionStorage.getItem('currentUser');
    let detail = {
      'shopCloseYrWk1': '000000',
      'shopAgencyCode': divisionCode,
      'userCode': JSON.parse(sessUsername).groupAssignmentModels[0].userCode,
      'conceptCode': this.nilStockForm.get('conceptCode').value
    }
    const requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    this.pendingNilStockService.getLocations(requestParameterModel).subscribe(
      (res: any) => {
        this.ShopCodes = [];
        
        res.dataList.forEach(
          shopCode => {
            this.enable = true;
            this.ShopCodes.push(shopCode);
          }
        );
        console.log(this.ShopCodes); console.log('this.ShopCodes');
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  setASNAssortmentDataPopup(nilStkTransactionNumber) {
    this.nilStkTranNumber = nilStkTransactionNumber;
  
    
    const index = this.details.findIndex(
        (x) => x.nilStkTransactionNumber == nilStkTransactionNumber
      );
    if (index != -1) {
        this.assortData = this.details[index];
        console.log(this.assortData);
      }
  }

}
