import { Component, OnInit, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormArray } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { SearchInvoiceService } from './search-invoice.service';
import { NavigationLinkParameterService } from '../../common/navigation-link-parameters-service';
import { Router, RouterModule } from '@angular/router';
import { SearchForm } from './search-form';
import { SearchInvoiceHeader } from './search-header';
import { ResponseMessage } from '../../common/response-message';
import { SearchInvoiceResponseModel } from '../../common/search-invoice-response-model';
import { UtilsService } from '../../common/utils-service';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../../common/field-name-list';
import { DatePipe } from '@angular/common';
import { PopUpData } from './pop-up-data';
import { ViewShipmentReceiptDetailsService } from '@/view-shipment-receipt-details/view-shipment-receipt-details.service';


@Component({
  selector: 'app-search-invoice',
  templateUrl: './search-invoice.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/rds-bil-style.css',
    '../../../../node_modules/font-awesome/css/font-awesome.css', './search-invoice.component.css']
})
export class SearchInvoiceComponent implements OnInit {
 
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('loaDer1', { static: false }) loaDer1: ElementRef;
  @ViewChild('btnclose', {static: false}) btnclose: ElementRef;
  @ViewChild('myForm', { static: false }) myInputVariable: NgForm;
  @ViewChild('invStatus', { static: false }) invStatusVariable: ElementRef;

  requestParameterModel: RequestParameterModel[];
  invoiceModel = new SearchForm();
  popUpModel = new PopUpData();
  shipmentForm: FormGroup;
  InvoiceListForm: FormGroup;
  searchInvoice: SearchInvoiceHeader[] = [];
  responseMessage: string;
  errormsg: string;
  public noResults = false;
  
  private error: any = { isError: false, errorMessage: '' };
  errorMessage: string;
  isValidDate: boolean;
  names: any;
  filteredArray:any;
  err:boolean=false;
  ratePerCartoon:string;
  ratePerCrtn:boolean=false;
  checked = false;
  isChecked:any;
  isCheckedCount = 0;
  isCheckedName:any;
  checkedArray= [];
  numArray=[];
  detailsArray=[];
  lrgrNumArray=[];
  lrgrDateArray=[];
  cartCountArray=[];
  arr = ['fromDate', 'toDate','shipmentFromDate','shipmentToDate'];
  dateOfYear1 = '';
  finalArray=[];
  invoiceArray=[];
  invoiceFlagArray=[];
  sourceShopcodeArray=[];
  destShopcodeArray=[];
  partyNumberArray=[];
  invoiceDateArray=[];
  routeNumberArray=[];
  createShipmentArray=[];
  statuses:any;
  datepipe: any;
  submitted = false;
  ShopCodes: any[] = [];
  shopCityName:any;
  callForm:boolean=false;
  dateArray: any = {};  
  routeDetails:any[];
  createShipmentBtn:boolean=false;
  num:any;
  show: Boolean=true;

  searchType='header';
  itemsPerPage:number=100;
  totalItems:any;
  currentPage = 1;

  constructor(private searchInvoiceService: SearchInvoiceService,private formBuilder: FormBuilder, private utilsService: UtilsService,
    private navigationLinkParameterService: NavigationLinkParameterService, private router: Router, private datePipe: DatePipe) { 
      
      // this.InvoiceListForm = this.formBuilder.group({
      //   invoiceList: this.formBuilder.array([])
      // });
      // this.buildInvoiceListForm();
    }
  ngOnInit() {
    this.getRouteNumber();
    const sessUsername = sessionStorage.getItem('currentUser');
    this.invoiceModel.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.invoiceModel.invoiceStatus = null;
    this.invoiceModel.challanInvoiceNumber = null;
    this.invoiceModel.itnRsnSerialNumber = null;
    this.invoiceModel.destShopCode = null;
    this.invoiceModel.frominvoiceCreateDate = null;
    this.invoiceModel.toinvoiceCreateDate = null;
    this.invoiceModel.routeNumber=null;
    this.invoiceModel.vehicleNumber=null;
    this.popUpModel.transporterName = '';
    this.invoiceModel.bataWeekFrom=null;
    this.invoiceModel.bataWeekTo=null;
    this.invoiceModel.sourceShopCode=null;
    this.invoiceModel.partyNumber = '';
    this.invoiceModel.shipmentFromDate = null;
    this.invoiceModel.shipmentToDate = null;
    
    this.getShopCode();
    this.searchInvoiceService.getStatus().subscribe(
      (data: any) => {
        this.statuses = data.configParamModels;
      }
    );


    // console.log(this.navigationLinkParameterService.invoicedetails);
    if(this.navigationLinkParameterService.invoicedetails !== '' && this.navigationLinkParameterService.invoicedetails !== undefined){
      this.invoiceModel = this.navigationLinkParameterService.invoicedetails;
      this.invoiceBySearchCriteria(this.invoiceModel);
    }
    if(this.navigationLinkParameterService.createShipmentArray!=='' &&  this.navigationLinkParameterService.createShipmentArray!== undefined)
    {
      this.createShipmentArray=this.navigationLinkParameterService.createShipmentArray;
      if(this.createShipmentArray.length>0){
        this.num=this.createShipmentArray.length;
        this.checked=true;
      }
    }
    this.navigationLinkParameterService.createShipmentArray="";
    
    this.navigationLinkParameterService.invoicedetails = '';
    if(this.navigationLinkParameterService.createShipmentMessage!=="" && this.navigationLinkParameterService.createShipmentMessage!== undefined){
      this.responseMessage=this.navigationLinkParameterService.createShipmentMessage;
      this.navigationLinkParameterService.createShipmentMessage="";
    }

    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
    //console.log(this.navigationLinkParameterService.searchInvoiceHeaderData);
    if (this.navigationLinkParameterService.searchInvoiceHeaderData === undefined) {
     // console.log('New Search has to be made');
    } else if (this.navigationLinkParameterService.searchInvoiceHeaderData.searchData !=undefined && this.navigationLinkParameterService.searchInvoiceHeaderData.resultData === ''){
      // this.loaDer.nativeElement.style.display = 'block';
      this.invoiceModel = this.navigationLinkParameterService.searchInvoiceHeaderData.searchData;
      this.searchInvoice = this.navigationLinkParameterService.searchInvoiceHeaderData.resultData;
      this.navigationLinkParameterService.searchInvoiceHeaderData = undefined;
      this.callForm=false;
      // let element: HTMLElement = document.getElementById('buttonSubmit') as HTMLElement;
      // element.click();
      this.invoiceBySearchCriteria(this.invoiceModel);
    }else {
     // console.log('Automatic Search will be performed');
      this.invoiceModel = this.navigationLinkParameterService.searchInvoiceHeaderData.searchData;
      // this.searchInvoice = this.navigationLinkParameterService.searchInvoiceHeaderData.resultData;
      this.invoiceBySearchCriteria(this.invoiceModel);
      this.navigationLinkParameterService.searchInvoiceHeaderData = undefined;
    }
    
    // this.searchInvoiceService.getTransporterName().subscribe(
    //   (data: any) => {
    //     console.log(data);
    //     this.names = data.dataList;
    //   }
    // );
  //   this.shipmentForm = this.formBuilder.group({
  //     // lrGrNumber: ['', Validators.required],
  //     transporterName: ['Please Select', [Validators.required]],
  //     // lrGrDate: ['', Validators.required],
  //     vehicleNumber: ['', [Validators.required]]
  // });
//   this.InvoiceListForm = new FormGroup({
//     lrGrNumber: new FormControl(),
//     lrGrDate:new FormControl()

//  });
  this.createShipmentForm();
}

createShipmentForm(){
  this.shipmentForm = this.formBuilder.group({
    transporterName: ['Please Select', [Validators.required]],
    vehicleNumber: ['', [Validators.required]],
    ratePerCarton:['']
});
this.err=false;
}
onChangeTrcode(val){
  //console.log(val);
  if(val !=='Please Select'){
    this.err=false;
    this.ratePerCarton(val);
  }else{
    this.err=true;
  }
}
createForm(){
  let formGroupStructure = {
    lrGrNumber : new FormControl(''),
    lrGrDate: new FormControl('')
  }

  this.searchInvoice.forEach((eachList,eachIndex)=>{
    //console.log(eachList);
      formGroupStructure['lrGrNumber_'+eachList.invoiceId] = new FormControl();
      formGroupStructure['lrGrDate_'+eachList.invoiceId] = new FormControl('');
  });
  this.InvoiceListForm=this.formBuilder.group(formGroupStructure);
  //console.log(this.InvoiceListForm);
}

get fval() {
  return this.shipmentForm.controls;
}
invoiceListFormArrayControl() {
  return <FormArray>this.InvoiceListForm.controls.invoiceList;
}
buildInvoiceListForm() {
  const control = this.invoiceListFormArrayControl();
  return control.push(this.formBuilder.group(
    {
      lrGrNumber: [null],
      lrGrDate: [null]
    }
  ));
}
  onClickBack() { this.router.navigate(['']); }

  searchPayload() {
    const searchNavigationHold = {
      searchData: this.invoiceModel,
      resultData: this.searchInvoice
    };
    return searchNavigationHold;
  }

  resetInvoiceResults() {
    this.searchInvoice = [];
    this.noResults = false;
    this.responseMessage = '';
    this.errormsg = '';
    this.loaDer.nativeElement.style.display = 'none';
    // this.checked = false;
    this.isCheckedCount = 0;
    this.numArray=[];
    this.lrgrNumArray=[];
    this.lrgrDateArray=[];
    this.invoiceArray=[];
    this.invoiceFlagArray=[];
    this.sourceShopcodeArray=[];
    this.destShopcodeArray=[];
    this.invoiceDateArray = [];
    this.partyNumberArray=[];
    this.cartCountArray=[];
    this.routeNumberArray=[];
    this.currentPage=1;
  }
  pageChangeReset(){
    this.numArray=[];
    this.checkedArray=[];
    this.lrgrNumArray=[];
    this.lrgrDateArray=[];
    this.invoiceArray=[];
    this.invoiceFlagArray=[];
    this.cartCountArray=[];
    this.sourceShopcodeArray=[];
    this.destShopcodeArray=[];
    this.invoiceDateArray=[];
    this.partyNumberArray=[];
    this.isCheckedCount = 0;
    this.routeNumberArray=[];
  }
  isExist(invoiceId){
    if(this.numArray.indexOf(invoiceId)>-1){
      return true;
    }else{
      return false;
    }
  }
  isDisabled(invoiceType,invoiceNumber){
    var numCheck = invoiceNumber.substring(0,2);
    // console.log(numCheck);
    if(invoiceType==='DISPATCH_IMPORT' || numCheck==='NV')
    {
      return true;
    }else{
      return false;
    }
  }
  isDisabledCarton(invoice){
    // console.log(invoice);
    if(invoice.invoiceLinesList!=null){
      //console.log(invoice.invoiceLinesList[0].artType);
      // if(invoice.invoiceLinesList[0].artType === 'CONSUMABLES'){
      if(invoice.artType === 'CONSUMABLES'){
        return false;
      }else{
        return true;
      }
    }else{
      return true;
    }
    // if(invoiceType==='DISPATCH_IMPORT' || numCheck==='NV')
    // {
    //   return true;
    // }else{
    //   return false;
    // }
  }
  onChange(e , status , challannum,invoiceNumber,sourceShopCode,destShopCode,partyNumber,invoiceCreateDate,routeNumber,invoiceFlag) {
    // console.log(e);
    // console.log(status);
    let lrgNum = document.getElementsByClassName('lrGrNumber_'+challannum);
    let lrgDate = document.getElementsByClassName('lrGrDate_'+challannum);
    
    if (e.target.checked === true) {
      this.numArray.push(challannum);
      let indofChal=this.numArray.indexOf(challannum);
      this.invoiceArray[indofChal]=invoiceNumber;
      this.invoiceFlagArray[indofChal]=invoiceFlag;
      this.sourceShopcodeArray[indofChal]=sourceShopCode;
      this.destShopcodeArray[indofChal]=destShopCode;
      this.invoiceDateArray[indofChal] = invoiceCreateDate;
      this.partyNumberArray[indofChal]=partyNumber;
      this.routeNumberArray[indofChal]=routeNumber;
      // console.log((<HTMLInputElement>document.getElementById("lrGrNumber_"+challannum)).value);
      (<HTMLElement>lrgNum[0]).removeAttribute("disabled");
      (<HTMLElement>lrgDate[0]).removeAttribute("disabled");
      // inactive
      this.checkedArray.push(status);
      this.isCheckedCount = this.isCheckedCount + 1;
      if((<HTMLInputElement>document.getElementById("lrGrNumber_"+challannum)).value !== ''){
        this.lrgrNum((<HTMLInputElement>document.getElementById("lrGrNumber_"+challannum)).value,challannum);
      }
      if((<HTMLInputElement>document.getElementById("cartCount_"+challannum)).value !== ''){
        this.getCarton((<HTMLInputElement>document.getElementById("cartCount_"+challannum)).value,challannum);
      }
      
      // console.log((<HTMLInputElement>document.getElementById("lrGrDate_"+challannum)).children[0]['children']);
      // (<HTMLInputElement>document.getElementById("lrGrDate_"+challannum)).children[0]['children'][0]['disabled']=false;
      // (<HTMLInputElement>document.getElementById("lrGrDate_"+challannum)).removeAttribute('disabled');
     
    } else {
      // console.log((<HTMLInputElement>document.getElementById("lrGrDate_"+challannum)).children[0]['children']);
      let indofChal=this.numArray.indexOf(challannum);
      (<HTMLElement>lrgNum[0]).setAttribute("disabled","true");
      (<HTMLElement>lrgDate[0]).setAttribute("disabled","true");
      this.numArray.splice(indofChal, 1);
      this.checkedArray.splice(indofChal, 1);
      this.lrgrNumArray.splice(indofChal, 1);
      this.lrgrDateArray.splice(indofChal, 1);
      this.invoiceArray.splice(indofChal, 1);
      this.invoiceFlagArray.splice(indofChal,1);
      this.cartCountArray.splice(indofChal,1);
      this.sourceShopcodeArray.splice(indofChal, 1);
      this.destShopcodeArray.splice(indofChal, 1);
      this.invoiceDateArray.splice(indofChal, 1);
      this.partyNumberArray.splice(indofChal,1);
      this.isCheckedCount = this.isCheckedCount - 1;
      this.routeNumberArray.splice(indofChal, 1);
      // (<HTMLInputElement>document.getElementById("lrGrNumber_"+challannum)).value="";
      (<HTMLInputElement>document.getElementById("lrGrDate_"+challannum)).value="";
      // this.requestForm.get('expected_arrival_date').setValue(new Date());
      // console.log('called');
      // console.log((<HTMLInputElement>document.getElementById("lrGrDate_"+challannum)).children[0]['children']);
      // (<HTMLInputElement>document.getElementById("lrGrDate_"+challannum)).children[0]['children'][0]['disabled']=true;
    }
    
    // console.log(this.checkedArray);
    // console.log(this.numArray);
    this.createShipmentEnable();
    
  }

  createShipmentEnable(){
    // let goAhead=false;
    // console.log(this.routeNumberArray);
    // if(this.routeNumberArray.length>1){
    //   let routeUnq=this.routeNumberArray.every( (val, i, arr) => val === arr[0] );
    //   if(routeUnq === true){
    //     if(this.routeNumberArray[0] === null){
    //       goAhead=false;
    //     }else{
    //       goAhead=true;
    //     }
    //   }else{
    //     goAhead=false;
    //   }
    // }else{
    //   goAhead=true;
    // }
    

    // let shopUnq=this.sourceShopcodeArray.every( (val, i, arr) => val === arr[0] );
    // let destUnq=this.destShopcodeArray.every( (val, i, arr) => val === arr[0] ) ;
    // if(this.partyNumberArray.length>0 && this.partyNumberArray.indexOf('000')==-1){
    //   let partyUnq=this.partyNumberArray.every( (val, i, arr) => val === arr[0] ) ;
    //   if(shopUnq === true && partyUnq === true){
    //     goAhead=true;
    //   }else{
    //     goAhead=false;
    //   }
    // }else if(shopUnq=== true && destUnq=== true){
    //   goAhead=true;
    // }
    
    if (this.isCheckedCount > 0) {
      let shippedInit=this.checkedArray.indexOf('SHIPPED');
      let cancelCheck=this.checkedArray.indexOf('SHIPMENT-STOPPED');
      let shippedCheck=this.checkedArray.indexOf('SHIPMENT-COMPLETED');
      
      // let shippedCheck=this.checkedArray.some(row => row.includes("SHIPPED"));
      // console.log(cancelCheck +'   '+shippedCheck+'  '+shippedInit);
      // console.log(this.numArray.length+' '+this.lrgrDateArray.length+' '+this.lrgrNumArray.length+''+this.cartCountArray.length);
      if(cancelCheck === -1 && shippedCheck === -1 && shippedInit === -1 && this.numArray.length===this.lrgrDateArray.length && this.numArray.length===this.lrgrNumArray.length){        
        this.createShipmentForm();        
        this.checked = true;
      }else{
        this.checked = false;
      }

    } else {
      this.checked = false;
    }
    
  }
  lrgrNum(p,invoiceId){
    // console.log(p);
    // let p=x.target.value;
    let invoiceIndex=this.numArray.indexOf(invoiceId);
    this.lrgrNumArray[invoiceIndex]=p;
    // console.log(x.target.value);
    if(p === ''){
      this.lrgrNumArray.splice(invoiceIndex, 1);
    }
    this.createShipmentEnable();
  }


  getDates(y,invoiceId,invoiceCreateDate,index){
    // console.log(y);
    let d=this.datePipe.transform(y,"dd/MM/yyyy");
    let invoiceIndex=this.numArray.indexOf(invoiceId);
    // console.log(d); 
    const FromDate: any[] = invoiceCreateDate.split('/');
    const stDate = new Date(FromDate[2], FromDate[1], FromDate[0]);
    const ToDate: any[] = d.split('/');
    const etDate = new Date(ToDate[2], ToDate[1], ToDate[0]);  
    // if (stDate.getTime() > etDate.getTime()) {
    //   this.errormsg = 'Invoice create date must be less than to LR GR Date';
    // } else {
    //   this.errormsg = '';
    //   if(invoiceIndex !== -1)
    //   this.lrgrDateArray[invoiceIndex]=d ;
    //   this.createShipmentEnable();
    // }    
    if(invoiceIndex !== -1)
    this.lrgrDateArray[invoiceIndex]=d ;
    this.createShipmentEnable();
  }

  getCarton(p,invoiceId){
    // console.log(p);
    // let p=x.target.value;
    let invoiceIndex=this.numArray.indexOf(invoiceId);
    // console.log(invoiceIndex);
    if(invoiceIndex !== -1)
    {
      this.cartCountArray[invoiceIndex]=p;
    }
    
    // console.log(x.target.value);
    // if(p === ''){
    //   this.cartCountArray.splice(invoiceIndex, 1);
    // }
    // this.createShipmentEnable();
  }
  ratePerCarton(transCode){
    this.loaDer1.nativeElement.style.display = 'block';
    this.ratePerCartoon="";
    const details: any = {
      "frightType" : "S",
      "transporterCode" : transCode,
      "exLocation" : this.shopCityName,
      "toLocationCode" : this.invoiceModel.destShopCode,
      "activeStatus" : "Y",
      "perCartonYn" : "Y"
  }
    this.searchInvoiceService.getRatePerCarton(details).subscribe(
      (data: any) => {
        // console.log(data);
        
        if(data!=''){
          // console.log(data[0]['rateCb']);
          this.ratePerCartoon = data[0]['rateCb'];
        }
        //  this.ratePerCartoon = data;
        this.shipmentForm.controls["ratePerCarton"].setValue(this.ratePerCartoon);
      }
    );
    setTimeout(() => {
    this.loaDer1.nativeElement.style.display = 'none';
    },300);
  }
  
  saveDetails() {
    this.popUpModel.transporterName = '';
    setTimeout(() => {
      this.createShipmentBtn=false;
      // console.log('loader close');
      this.loaDer1.nativeElement.style.display = 'none';
      this.loaDer.nativeElement.style.display = 'none';
    }, 1000);
    this.searchInvoiceService.getTransporterName().subscribe(
      (data: any) => {
        // console.log(data);
        this.names = data.transporterModels[0];
        // console.log(this.names);
        
      }
    );
    let transCode="";let toLocationCode;let exLocation="";
    // this.ratePerCarton(transCode);
    this.createShipmentForm();
  }

  changeDate(value, key) {
    this.responseMessage = '';
    const myDate = new Date(value);
    const mnth = ('0' + (myDate.getMonth() + 1)).slice(-2);
    const day = ('0' + myDate.getDate()).slice(-2);
    this.dateOfYear1 = [day, mnth, myDate.getFullYear()].join('/');
    if (key === 'fromDate') {
      this.invoiceModel.frominvoiceCreateDate = this.dateOfYear1;
    } else if(key === 'toDate') {
      this.invoiceModel.toinvoiceCreateDate = this.dateOfYear1;
    } else if(key === 'shipmentFromDate') {
      this.invoiceModel.shipmentFromDate = this.dateOfYear1;
    } else {
      this.invoiceModel.shipmentToDate = this.dateOfYear1;
    }
  }

  submitSearchInvoiceForm() {
    
    this.responseMessage = '';
    this.errormsg = '';
    // console.log(this.invoiceModel);
    // console.log(this.invoiceModel.challanInvoiceNumber)
    this.resetInvoiceResults();
    this.currentPage=1;
    // if(this.invoiceModel.destShopCode !== null && this.invoiceModel.sourceShopCode !== null){
    if(this.invoiceModel.challanInvoiceNumber === null && this.invoiceModel.itnRsnSerialNumber === null &&  this.invoiceModel.destShopCode === null && this.invoiceModel.invoiceStatus === null && this.invoiceModel.frominvoiceCreateDate === null && this.invoiceModel.toinvoiceCreateDate === null && this.invoiceModel.sourceShopCode === null && this.invoiceModel.routeNumber === null && this.invoiceModel.vehicleNumber === null && this.invoiceModel.shipmentFromDate === null && this.invoiceModel.shipmentToDate === null && (this.invoiceModel.partyNumber === '' || this.invoiceModel.partyNumber ===  null)){
      if(this.invoiceModel.bataWeekFrom === null && this.invoiceModel.bataWeekTo === null){
        this.errormsg = 'Please select at least a search criteria.';
        this.loaDer.nativeElement.style.display = 'none';
        return false;
      }else if((this.invoiceModel.bataWeekFrom === null &&  this.invoiceModel.bataWeekTo !== null)||(this.invoiceModel.bataWeekFrom !== null && this.invoiceModel.bataWeekTo === null)){
        this.errormsg = 'Please enter both Bata Week To and Bata Week From.';
        this.loaDer.nativeElement.style.display = 'none';
        return false;
      }else if(this.invoiceModel.bataWeekFrom > this.invoiceModel.bataWeekTo){
        this.responseMessage = '';
        this.errormsg='BataWeekFrom should be less than BataWeekTo';
        this.loaDer.nativeElement.style.display = 'none';
        return false;
      }
          
    }
    // else{
    //   console.log(this.invoiceModel);
    //   this.errormsg = 'Please select shop location and enter destination shop code';
    //     this.loaDer.nativeElement.style.display = 'none';
    //     return false;
    // }

    if (this.invoiceModel.frominvoiceCreateDate !== null || this.invoiceModel.toinvoiceCreateDate !== null) {
      if (this.invoiceModel.frominvoiceCreateDate !== null && this.invoiceModel.toinvoiceCreateDate !== null) {

        const FromDate: any[] = this.invoiceModel.frominvoiceCreateDate.split('/');
        const stDate = new Date(FromDate[2], FromDate[1], FromDate[0]);
        const ToDate: any[] = this.invoiceModel.toinvoiceCreateDate.split('/');
        const etDate = new Date(ToDate[2], ToDate[1], ToDate[0]);

        if (stDate.getTime() <= etDate.getTime()) {
          this.invoiceBySearchCriteria(this.invoiceModel);
        } else {
          this.errormsg = 'From Date Should be less than or equal to To Date';
          this.loaDer.nativeElement.style.display = 'none';
        }
      } else {
        this.errormsg = 'Please enter both the dates';
        this.loaDer.nativeElement.style.display = 'none';
      }
    } else if (this.invoiceModel.shipmentFromDate !== null || this.invoiceModel.shipmentToDate !== null) {
      if (this.invoiceModel.shipmentFromDate !== null && this.invoiceModel.shipmentToDate !== null) {

        const FromDate: any[] = this.invoiceModel.shipmentFromDate.split('/');
        const stDate = new Date(FromDate[2], FromDate[1], FromDate[0]);
        const ToDate: any[] = this.invoiceModel.shipmentToDate.split('/');
        const etDate = new Date(ToDate[2], ToDate[1], ToDate[0]);

        if (stDate.getTime() <= etDate.getTime()) {
          this.invoiceBySearchCriteria(this.invoiceModel);
        } else {
          this.errormsg = 'Shipment From Date Should be less than or equal to Shipment To Date';
          this.loaDer.nativeElement.style.display = 'none';
        }
      } else {
        this.errormsg = 'Please enter both shipment the dates';
        this.loaDer.nativeElement.style.display = 'none';
      }
    } else {
      // console.log(this.invoiceModel);
      this.callForm=true;
      this.invoiceBySearchCriteria(this.invoiceModel);
    }
  }

  invoiceBySearchCriteria(invoiceModel: SearchForm) {
    this.pageChangeReset();
    this.invoiceModel.searchType=this.searchType;
    this.invoiceModel.pageSize=this.itemsPerPage;
    this.invoiceModel.pageNumber=this.currentPage; 
    // this.responseMessage = '';
    if(this.callForm){
      this.loaDer.nativeElement.style.display = 'block';
      this.callForm=false;
    }
    this.checkedArray= [];
    this.requestParameterModel = this.utilsService.createRequestParametersModelArray(invoiceModel);
    if (this.requestParameterModel.length !== 0) {
      this.searchInvoiceService.getInvoiceBySearchCriteria(this.requestParameterModel).subscribe(
        (response: SearchInvoiceResponseModel) => {
          if (response.dataList !== null) {
            this.searchInvoice = response.dataList;
            this.totalItems=response.recordCount;
            for(let x=0;x<response.dataList.length;x++) {
              const FromDate: any[] = response.dataList[x]['invoiceCreateDate'].split('/');
              const stDate = new Date(FromDate[2], FromDate[1]-1, FromDate[0]);
              this.dateArray[x] = {};
              this.dateArray[x] = stDate;
            }    
            // this.createForm();
          } else {
            // console.log(response.message);
            this.responseMessage = response.message;
            this.searchInvoice = [];
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (error) => {
          this.errormsg = error;
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    } else {
      this.errormsg = 'Please fill any field first';
      this.loaDer.nativeElement.style.display = 'none';
    }

  }

  viewInvoicePage(invID,invoiceNumber,invoiceStatus) {
    //console.log(invoiceStatus);
    let selectInd: any;
    this.searchInvoice.filter(cs => cs.invoiceId === invID).forEach(
      obj => {
        selectInd = obj;
      }
    );
    //console.log(selectInd);
    // console.log(this.invoiceModel.invoiceStatus);
    this.navigationLinkParameterService.invoiceNumber = invoiceNumber;
    this.navigationLinkParameterService.invStatus = invoiceStatus;
    this.navigationLinkParameterService.invoiceDetails= selectInd;
    this.navigationLinkParameterService.searchInvoiceHeaderData = this.searchPayload();
    this.router.navigate(['/rds-view-invoice']);
  }
  updateInvoicePage(invID){
    // let selectInd: any;
    // this.searchInvoice.filter(cs => cs.invoiceId === invID).forEach(
    //   obj => {
    //     selectInd = obj;
    //   }
    // );
    // console.log(selectInd);
    // selectInd.invStatus=this.invoiceModel.invoiceStatus;
    // console.log(this.invoiceModel.invoiceStatus);
    // this.navigationLinkParameterService.invoiceDetails = selectInd;
    this.navigationLinkParameterService.invoiceID=invID;
    // this.navigationLinkParameterService.invoiceStatus= this.invoiceModel.invoiceStatus;
    this.navigationLinkParameterService.searchInvoiceHeaderData = this.searchPayload();
    this.router.navigate(['/rds-update-invoice']);
  }
  convert(str) {
    const date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day,mnth,date.getFullYear()].join("/");
  }
  createShipment(formData: any){
    // console.log(this.shipmentForm.value);
    // console.log(this.err);
    
    this.submitted = true;
    this.finalArray=[];
    //console.log('called');
    // return false;
    // console.log(this.shipmentForm.value);
    if(this.shipmentForm.value.transporterName == 'Please Select'){
       this.err=true;
      return;
    }
    if (this.shipmentForm.invalid) {
      // this.err=true;
      return;
  }
  this.createShipmentBtn=true;
    this.checked=false;
    this.btnclose.nativeElement.click();
  // console.log(this.numArray);
  // console.log(this.checkedArray);
  // console.log(this.lrgrNumArray);
  // console.log(this.cartCountArray);
  // console.log(this.lrgrDateArray);
  // console.log(this.invoiceModel);  
    // const datepipe: DatePipe = new DatePipe('en-US');
    
    // this.filteredArray = this.numArray.filter(function(item, pos){
    //   return this.numArray.indexOf(item)== pos; 
    // });
    // const data: any ={
    //   "transporterCode":this.shipmentForm.value.transporterName,
    //   "lrGrNumber":this.shipmentForm.value.lrGrNumber,
    //   "vehicleNumber":this.shipmentForm.value.vehicleNumber,
    //   "lrGrDate":this.convert(this.shipmentForm.value.lrGrDate),
    //   "userId":this.invoiceModel.userCode,
    //   "invoiceIds":this.numArray
    // };
    
    let i=0;
    this.numArray.forEach((val, index) => {
      let data: any ={
        "transporterCode":this.shipmentForm.value.transporterName,
        "lrGrNumber":this.lrgrNumArray[index],
        "vehicleNumber":this.shipmentForm.value.vehicleNumber,
        "lrGrDate":this.lrgrDateArray[index],
        "userId":this.invoiceModel.userCode,
        "sourceShopCode":this.sourceShopcodeArray[index],
        "invoiceNumber":this.invoiceArray[index],
        "invoiceFlag":this.invoiceFlagArray[index],
        "invoiceIds":val,
        "ratePerCarton":this.shipmentForm.value.ratePerCarton,
        "cartCount":this.cartCountArray[index]
      };
      this.finalArray.push(data);
  });
  this.currentPage=1;
    // console.log(this.finalArray);
    
    this.searchInvoiceService.asnCreation(this.finalArray).subscribe(
      (response: any) => {
        // console.log(JSON.stringify(response.body));
        let res=JSON.parse(response.body);
        // console.log(res.status);
        if (res.status !== 'FAILURE') {
          this.responseMessage = 'Shipment created successfully';
          this.invoiceBySearchCriteria(this.invoiceModel);
        } else {
          this.responseMessage = 'Something went wrong.Shipment not created.';
        }
       this.invoiceBySearchCriteria(this.invoiceModel);
      }
    );
  }
  getShopCode(){
    let detail = [{
      "name":"shopAgencyCode",
      "value": '5',
          },{
            "name":"userCode",
            "value": this.invoiceModel.userCode
    }]
    // let requestParameterModel = this.utilsService.createRequestParametersModelArray(detail);
    // console.log(JSON.stringify(requestParameterModel));
    this.searchInvoiceService.getShopCode(detail).subscribe(
      (res: any) => {
        this.ShopCodes = [];
        res.dataList.forEach(
          shopCode => {
            // this.enable = true;
            this.ShopCodes.push(shopCode);
          }
        );
        // this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }
  getPage(page){
    this.invoiceModel.pageNumber=page;
    this.currentPage=page;
    this.invoiceBySearchCriteria(this.invoiceModel);

}
  
  getRouteNumber(){
    var routeNumberFetch ={
      "activeStatus" : "Y"  
    };
  this.searchInvoiceService.getRouteNumber(routeNumberFetch).subscribe(
    (data: any) => {
      // console.log(data);
      var flags = [], output = [], l = data.length, i;
      for( i=0; i<l; i++) {
          if( flags[data[i].routeSequence]) continue;
          flags[data[i].routeSequence] = true;
          output.push(data[i]);
      }
      // console.log(output);
      this.routeDetails = output;
    },
    (err) => {
      this.responseMessage = err;
    }
  );
  }
  onChangeShopcode(sourceShopCode){
    // console.log(sourceShopCode);
    this.shopCityName="";
    if(sourceShopCode !== 'null'){
      let shopDetails=this.ShopCodes.filter(p => p.shopNumber == sourceShopCode);
      if(shopDetails[0].shopCityName=="THANE"){
        this.shopCityName="BHIWANDI";
      }else{
        this.shopCityName=shopDetails[0].shopCityName;
      }
    }
    
    // console.log(this.shopCityName);
  }
  createShipmentPage(){
    this.navigationLinkParameterService.invoiceNumber = '';
    this.navigationLinkParameterService.invStatus = '';
    this.navigationLinkParameterService.createShipmentArray= this.createShipmentArray;
    this.navigationLinkParameterService.searchInvoiceHeaderData = this.searchPayload();
    this.router.navigate(['/create-shipment']);

  }
  showButton(invoiceRow){
    let index = this.createShipmentArray.findIndex(x => x.invoiceId ===invoiceRow.invoiceId);
    // console.log(index);
    var numCheck = invoiceRow.challanInvoiceNumber.substring(0,2);
    
    if(invoiceRow.invoiceStatus === 'INVOICED' && invoiceRow.scenarioName!="SHIPPING_WIP"){
      if(invoiceRow.invoiceType==='DISPATCH_IMPORT' || numCheck==='NV'){
        this.show=false;
      }else{
        this.show=true;
      }
    }else{
      this.show=false;
    }
    // console.log(this.show);
    if(index != -1){
      return true
    }else{
      return false;
    }
  }
  forCreateShipment(state,invoiceRow){
    // console.log(state);
    // console.log(invoiceRow);
    // console.log(this.createShipmentArray);
    let index = this.createShipmentArray.findIndex(x => x.invoiceId ===invoiceRow.invoiceId);
      //console.log(index);
    if(state =='add'){
      if(index==-1){
        this.createShipmentArray.push(invoiceRow);
      }
    }else{
      
      if(index!=-1){
        this.createShipmentArray.splice(index, 1);
      }
     
    }
    
   // console.log(this.createShipmentArray);
    this.num=this.createShipmentArray.length;
    if(this.num>0){
      this.checked = true;
    }
  }
  resetShipmentArray(){
    this.createShipmentArray=[];
    this.num=this.createShipmentArray.length;
    this.checked = false;
  }
  numberCheck(event, name) { 
    var regExpr = /^\d+\.\d{2}$/;
    // var RegularExpression  =  new RegExp(^\d+(\.\d{1,2})?$);
    var userText = event.target.value;
    // console.log(event);
    // console.log(name);
    // console.log(userText);
    this.ratePerCrtn=false;
    if (userText != "") {
      var validNumber = new RegExp(/^\d*\.?\d*$/);
      if (!isNaN(userText)) {
        event.target.value = userText;
      } else {
        event.target.value ="";
          if (name == "ratePerCrtn") {
            this.ratePerCrtn=true;
          }
        
      }
      if (event.target.value.indexOf('.') == -1) { return; }
      // console.log(event.target.value.length);
      // console.log(event.target.value.indexOf('.'));
      var lth=new String(userText.split(".")[1]);
      // console.log(lth.length);
  
      if (lth.length > 2) {
            // event.target.value = parseFloat(event.target.value).toFixed(2);
            event.target.value ="";
              
          if (name == "ratePerCartoon") {      
                this.ratePerCrtn=true;
          }
        }
    }
  }

}
