import { Injectable } from '@angular/core';
import { BilApiUrl } from '../common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageCcListService {
  concept_url: string = BilApiUrl.CONCEPT_DETAILS;
  // party_url: string = BilApiUrl.SUPPLIER_PARTY_NUMBER;
  ccTypeurl: string = BilApiUrl.FETCH_CONFIG_DETAILS;
  cc_list_url: string = BilApiUrl.FETCH_CC_DETAILS;
  cc_list_update_url: string = BilApiUrl.CREATE_CC_LIST;
  constructor(private _http: HttpClient) { }

  getUserConcept() {
    return this._http.post(this.concept_url, null);
  }

  getCcType() {
    return this._http.get(this.ccTypeurl);
  }

  getCcListBySearch(ccListParameter) {
    console.log('searching parameter' + JSON.stringify(ccListParameter));
    return this._http.post(this.cc_list_url, ccListParameter);
  }

  updateCcFlagStatus(details) {
    console.log('updating parameter' + JSON.stringify(details));
    return this._http.post(this.cc_list_update_url, details, {
      observe: 'response',
      responseType: 'text'
    });
  }
}
