import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ManageConceptMasterCreateService } from './manage-concept-master-create.service';
import { RETAIL_DIVISION, NON_RETAIL_DIVISION, EXPORT_DIVISION } from '@/common/field-name-list';
import { SearchRequisitionService } from '@/search-requisition/search-requisition.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-manage-concept-master-create',
  templateUrl: './manage-concept-master-create.component.html',
  styleUrls: ['./manage-concept-master-create.component.css']
})
export class ManageConceptMasterCreateComponent implements OnInit {
  createConceptMasterForm: FormGroup;
  added = false;
  responseMessage:any;
  constructor(private _manageConceptMasterCreateService: ManageConceptMasterCreateService, private fb: FormBuilder,private _router: Router) {
    this.createConceptMasterForm = this.fb.group({
      concepts: this.fb.array([])
    });
    this.buildConceptForm();
  }

  division = [
    { id: 1, name: RETAIL_DIVISION },
    { id: 2, name: NON_RETAIL_DIVISION }
  ];

  ngOnInit() {
  }

  buildConceptForm() {
    let control = this.conceptFormArrayControl();
    return control.push(this.fb.group(
      {
        division: [null, [Validators.required]],
        concept: ['', [Validators.required]],
        concCode: ['', [Validators.required,Validators.maxLength(1)]],
        description: ['', [Validators.required]],
        orderPrefixInPoSystem: ['', [Validators.required]],
        concInJdeE1: ['', [Validators.required]],
        orderPrefixJdeE1: ['', [Validators.required]],
        usedOnPo: [null, [Validators.required]],
        usedOnSo: [null, [Validators.required]],
        distConceptCode: ['', [Validators.required]]
      }
    ));
  }

  addConcept() {
   
    if (this.createConceptMasterForm.invalid) {
      this.added = true;
      return;
    } else{
      this.added = false;
    }
    let control = this.conceptFormArrayControl();
    return control.push(this.fb.group(
      {
        division: [null, [Validators.required]],
        concept: ['', [Validators.required]],
        concCode: ['', [Validators.required,Validators.maxLength(1)]],
        description: ['', [Validators.required]],
        orderPrefixInPoSystem: ['', [Validators.required]],
        concInJdeE1: ['', [Validators.required]],
        orderPrefixJdeE1: ['', [Validators.required]],
        usedOnPo: [null, [Validators.required]],
        usedOnSo: [null, [Validators.required]],
        distConceptCode: ['', [Validators.required]]
      }
    ));
  }

  deleteConcept(concept_index: any) {
    let control = <FormArray>this.createConceptMasterForm.controls.concepts;
    control.removeAt(concept_index);
  }

  conceptFormArrayControl() {
    return <FormArray>this.createConceptMasterForm.controls.concepts;
  }

  submitConcepts(formData: any) {
    console.log(formData);
    let dataSet: any = [];
    formData.concepts.forEach(
      concept => {
        let data: any = {
          // conceptId: concept.conceptId,
          division: concept.division,
          concept: concept.concept,
          concCode: concept.concCode,
          description: concept.description,
          orderPrefixInPoSystem: concept.orderPrefixInPoSystem,
          concInJdeE1: concept.concInJdeE1,
          orderPrefixJdeE1: concept.orderPrefixJdeE1,
          usedOnPo: concept.usedOnPo,
          usedOnSo: concept.usedOnSo,
          distConceptCode: concept.distConceptCode
        }
        dataSet.push(data);
      }
    );
    console.log(dataSet);
    this._manageConceptMasterCreateService.addConceptMaster(dataSet).subscribe(
      (response: any) => {
        console.log(response);
        this.responseMessage =  response.message;
        if(response.status!='ERROR'){
          this._router.navigate(['']); // Redirect to home page.
        }
      }
    );
  }
}
