export class ReportSearch {
    estSession: string;
    conceptCode: string;
    mCat: string;
    rdcNo: string;
    delvFrmWk: string;
    delvToWk: string;
    suppCode: string;
    poType: string;
    year: string;
    session: string;
    ordyrWkFrm: string;
    ordyrWkTo: string;
    delyrWkFrm: string;
    delyrWkTo: string;
    concept: string;
    unitNo: string;
    type: string;
    orderNo: string;
    artNo: string;
    delWkYrFrm: string;
    delWkYrTo: string;
    supplyWkYrFrm: string;
    supplyWkYrTo: string;
    orderNoTo: string;
    orderNoFrm: string;
    ordNo: string;
    artNumber: string;
    vendorCd: string;
    reportType: string;
    assortmentType: string;
    catCode: string;
    shopCode: string;
    packCode: string;
    supyrwkTo: string;
    supyrwkFrom: string;
    receiveyrWkFrm: string;
    receiveyrWkTo: string;
    orderyrWkFrm: string;
    orderyrWkTo: string;
    groupcode: string;
    partyName: string;
    assosiateNumber: string;
    region: string;
    fromDT: Date;
    ToDT: Date;
    gatePassNoRaneTo: string;
    gatePassNoRaneFrom: string;
    shopName: string;
    fromRdcCode: string;
    fromShopName: string;
    toRdcCode: string;
    toShopName: string;
    BataWkYrFrom: string;
    BataWkYrTo: string;
    destination: string;
    dailyDateFrom: Date;
    dailyDateTo: Date;
    bataFromDate: string;
    bataToDate: string;
    fortFromDate: string;
    fortToDate: String;
    fromDate: string;
    toDate: string;
    durationType: string;

    toShopCode : string;
    fromShopCode: string;
    regionCode : string;
    fromRdcDistrictCode: string;
    toDistrictCode: string;
    // toShopName : string;
    week : string;
    rdcCode: string;
    shopNo : string;
    gatePassStartDate : string;
    gatePassEndDate : string;
    gatePassNoStarts: string;
    gatePassNoEnds: string;
    copyType:string;
    weekTo: string;
    weekFrom: string;
    reportPeriodType: string;
    destinationCode: string;
    destinationName: string;
    weekEndDate: string;
    weekStartDate: string;
     yearWeekFrom: string;
    yearWeekTo: string;
    vendorName: string;
    shopCityName : string;
    fromDistrictCode: string;
    dispatchName: string;
    recievingFromName: string;
    districtNumber:string;
    sourceRdc:string;
     periodEndNo: string;
    periodStartNo: string;
    constructor() { }
}
