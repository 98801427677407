export class UserConceptModel {
    userName: string;
    conceptCode: string;
    constructor() { }
}
export class GroupModel {
    groupname: string;
    groupId: string;
    constructor() {}

}

export class RoleScreenData {
    groupScreenAccessId: string;
    groupId: string;
    screenId: string;
    accessId: string;
    activeFlag: string;
    lastUpdatedBy: string;
    constructor() {}
}
