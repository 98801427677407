import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneratePoService } from './generate-po.service';
import { GeneratePo } from '@/generate-po/generate-po';
import { generatePoHeader } from './generate';
import { generatePoHeader1 } from './generate1';
import { User } from '../models/user';
import { Role } from '../models/role';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-generate-po',
  templateUrl: './generate-po.component.html',
  //styleUrls: ['./generate-po.component.css']
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css']
})
export class GeneratePoComponent implements OnInit {

  distributionDetailsForm: FormGroup; 
  generatePo: GeneratePo[] = [];
  generatePoObj = new GeneratePo();
  enable: boolean = true;
  responseMessage: string;
  generatePos: GeneratePo[] = []; 

  myDate: Date;
  currentUser: User;

  constructor(private generatePoService: GeneratePoService, private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private authenticationService: AuthenticationService, private fb: FormBuilder) { 
      
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    
    }

    get isAdmin() {
      return this.currentUser && this.currentUser.role === Role.Admin;
  }

  ngOnInit() {
    this.myDate = new Date();
    this.generatePo.push(this.generatePoObj);
  }

  addAssortment() {

    this.enable = false;
    let pendingpurchase = new GeneratePo();
    this.generatePo.push(pendingpurchase);
    this.enable = true;
  }

  onKey(event, index) {

    this.generatePos = [];
    let name = (Object)(event.target.value);
    if(name.length==7){

    this.generatePoService.getImportServiceCriteria(name).subscribe(

      (response: generatePoHeader) => {
        if (response.data.length == 0) {
          console.log('length' + response.data.length);
          this.responseMessage = ('Data Unavailable');
        }
       
        //this.generatePo[index]=response.data[0];
        response.data.forEach(
          searchPo => {
            if (name == searchPo.articleNumber) {

              this.generatePo[index].articleName = searchPo.articleName;
              // this.generatePo[index].componentName = searchPo.componentName;
              // this.generatePo[index].customerArticleNumber = searchPo.customerArticleNumber;
              // this.generatePo[index].color = searchPo.color;
              // this.generatePo[index].standardCost = searchPo.standardCost;
              // this.generatePo[index].customer = searchPo.customer;
              // this.generatePo[index].uom = searchPo.uom;
            
              this.generatePos.push(searchPo);
          
              this.responseMessage = '';
              console.log('dropdown' + JSON.stringify(this.generatePos));
              console.log('Name from 2nd service is ' + this.generatePoObj.articleName);
            }
          }
        );
        this.generatePo[index].cmpData=this.generatePos;
      }
    )
  }
  }

  onChangeEvent(event, index) {

    let name = (Object)(event.target.value);

    this.generatePoService.getImportServiceCriteria11(name).subscribe(
      (response: generatePoHeader) => {

        response.data.forEach(
          searchPo => {

            if (name == searchPo.componentCode) {
        
              this.generatePo[index].componentName = searchPo.componentName;
              this.generatePo[index].color = searchPo.color;
              this.generatePo[index].standardCost = searchPo.standardCost;
              this.generatePo[index].customer = searchPo.customer;
              this.generatePo[index].uom = searchPo.uom;
             
              console.log('hynytntnyjsegSDhfjhn ' + this.generatePoObj.componentCode);
            }
          }
        );
      }
    )
  }

  onClickSupp() {

    this.generatePoService.getImportService(this.generatePoObj.partyNo).subscribe(

      (response: generatePoHeader1) => {
        response.dataList.forEach(
          searchPo => {
             if (this.generatePoObj.partyNo == searchPo.partyNo) {
              this.generatePoObj.partyShortName = searchPo.partyShortName;
              console.log('Name from 2nd service is ' + this.generatePoObj.partyShortName);
            }
          }
        );
      }
    )
  }

  onSubmit() {
    console.log("ghhjnjn" + JSON.stringify(this.generatePo));
  }

  deleteRow(index) {
    this.generatePo.splice(index, 1);
  }




}
