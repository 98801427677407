import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// import { OAuthModule } from 'angular-oauth2-oidc';
// import { OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
// import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

import { fakeBackendProvider } from './helpers/fake-backend';
import { JwtInterceptor} from './helpers/jwt.interceptor';
import { ErrorInterceptor} from './helpers/error.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { SearchRequisitionComponent } from './search-requisition/search-requisition.component';
import { MultipleRequisitionComponent } from './multiple-requisition/multiple-requisition.component';
import { SearchPoComponent } from './search-po/search-po.component';
import { PendingPoComponent } from './pending-po/pending-po.component';
import { ImportArticleComponent } from './import-article/import-article.component';
import { StationaryComponent } from './stationary/stationary.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { GeneratePoComponent } from './generate-po/generate-po.component';
import { OrderFulfillmentComponent } from './order-fulfillment/order-fulfillment.component';
import { AlertComponent } from './alert/alert.component';
import { RequisitionDetailsComponent } from './search-requisition/requisition-details/requisition-details.component';
import { NavigationLinkParameterService } from './common/navigation-link-parameters-service';
import { UpdatePoComponent} from '../app/search-po/update-po/update-po.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TabModule } from 'angular-tabs-component';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from 'primeng/calendar'; 
import { TabViewModule } from 'primeng/tabview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { UtilsService } from './common/utils-service';
import { PoListComponent } from './search-requisition/po-list/po-list.component';
import { UpdatePendingPoComponent } from './pending-po/update-pending-po/update-pending-po.component';
import { FactoryPoComponent } from './factory-po/factory-po.component';
import { DistributionDetailComponent } from './distribution-detail/distribution-detail.component';
import { DistributionDetailCompletedComponent } from './distribution-detail-completed/distribution-detail-completed.component';
import { DistributionDetailMultipleComponent } from './distribution-detail-multiple/distribution-detail-multiple.component';
import { ViewShipmentReceiptComponent } from './view-shipment-receipt/view-shipment-receipt.component';
import { ViewShipmentReceiptDetailsComponent } from './view-shipment-receipt-details/view-shipment-receipt-details.component';
import { BulkRecallComponent } from './bulk-recall/bulk-recall.component';
import { PoDateChangeComponent } from './po-date-change/po-date-change.component';
import { BulkCancellationComponent } from './bulk-cancellation/bulk-cancellation.component';
import { ImportArticlePriceComponent } from './import-article-price/import-article-price.component';
import { EstimateUploadComponent } from './estimate-upload/estimate-upload.component';

import { OrderModule } from 'ngx-order-pipe';
import { GenerateReportComponent } from './generate-report/generate-report.component';

import { DistributionDetailPoComponent } from './distribution-detail-po/distribution-detail-po.component';
import {UploadExportComponentComponent} from './upload-export-component/upload-export-component.component';
import {PendingExportComponentComponent} from './pending-export-component/pending-export-component.component';
import{CreatePackingMasterComponent} from './create-packing-master/create-packing-master.component';
import { BatchesComponent } from './batches/batches.component';
import { SearchPackingMasterComponent } from './search-packing-master/search-packing-master.component';
import { SearchPackingMasterEditComponent } from './search-packing-master-edit/search-packing-master-edit.component';

import { GenerateExportPoComponent } from './generate-export-po/generate-export-po.component';
import { ManageSizeMasterComponent } from './manage-size-master/manage-size-master.component';
import { ManageSizeMasterEditComponent } from './manage-size-master-edit/manage-size-master-edit.component';
import { ManageConceptMasterComponent } from './manage-concept-master/manage-concept-master.component';
import { ManageConceptMasterEditComponent } from './manage-concept-master-edit/manage-concept-master-edit.component';
import { ViewUploadStatusComponent } from './view-upload-status/view-upload-status.component';
import { ManageSizeMasterCreateComponent } from './manage-size-master-create/manage-size-master-create.component';
import { ManageConceptMasterCreateComponent } from './manage-concept-master-create/manage-concept-master-create.component';
import { SearchBatchStatusComponent } from './search-batch-status/search-batch-status.component';
import { ManageUserConceptComponent } from './manage-user-concept/manage-user-concept.component';
import { ManageUserConceptCreateComponent } from './manage-user-concept-create/manage-user-concept-create.component';
import { ManageSupplierAccessComponent } from './manage-supplier-access/manage-supplier-access.component';
import { ManageSupplierAccessCreateComponent } from './manage-supplier-access-create/manage-supplier-access-create.component';
import { ManageSubPartyMasterComponent } from './manage-sub-party-master/manage-sub-party-master.component';
import { SearchUserGroupComponent } from './search-user-group/search-user-group.component';
import { CreateUserGroupComponent } from './create-user-group/create-user-group.component';
import { ManageSubPartyMasterCreateComponent } from './manage-sub-party-master-create/manage-sub-party-master-create.component';
import { ManageCcListComponent } from './manage-cc-list/manage-cc-list.component';
import { ManageCcListCreateComponent } from './manage-cc-list-create/manage-cc-list-create.component';
import { ManageCcListEditComponent } from './manage-cc-list-edit/manage-cc-list-edit.component';
import { CreateDistrictMasterComponent } from './create-district-master/create-district-master.component';
import { SearchDistrictMasterComponent } from './search-district-master/search-district-master.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {VersionCheckService} from './common/version-check.service';
import { ViewLocationQuantityComponent } from './view-location-quantity/view-location-quantity.component';
import { ViewReportStatusComponent } from './view-report-status/view-report-status.component';
import { CreateShipmentComponent } from './rds/search-invoice/create-shipment/create-shipment.component';

/*RDS*/

import { RdsModule } from './rds/rds.module';
import { from } from 'rxjs';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration,MsalRedirectComponent } from '@azure/msal-angular';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '9b2cd18f-522d-4519-840c-e44aaa45ecab',
      authority: 'https://login.microsoftonline.com/c32974d0-d6dc-4f13-8d2a-810be92644b8',
      redirectUri: 'https://biscs.bata.com'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    HomeComponent,
    SearchRequisitionComponent,
    MultipleRequisitionComponent,
    SearchPoComponent,
    PendingPoComponent,
    ImportArticleComponent,
    StationaryComponent,
    CreateReportComponent,
    GeneratePoComponent,
    OrderFulfillmentComponent,
    AlertComponent,
    RequisitionDetailsComponent,
    UpdatePoComponent,
    PoListComponent,
    UpdatePendingPoComponent,
    FactoryPoComponent,
    DistributionDetailComponent,
    DistributionDetailCompletedComponent,
    DistributionDetailMultipleComponent,
    ViewShipmentReceiptComponent,
    ViewShipmentReceiptDetailsComponent,
    BulkRecallComponent,
    PoDateChangeComponent,
    ImportArticlePriceComponent,
    EstimateUploadComponent,
    GenerateReportComponent,
    DistributionDetailPoComponent,
    UploadExportComponentComponent,
    PendingExportComponentComponent,
    CreatePackingMasterComponent,
    BatchesComponent,
    SearchPackingMasterComponent,
    SearchPackingMasterEditComponent,
    GenerateExportPoComponent,
    ManageSizeMasterComponent,
    ManageSizeMasterEditComponent,
    ManageConceptMasterComponent,
    ManageConceptMasterEditComponent,
    ViewUploadStatusComponent,
    ManageSizeMasterCreateComponent,
    ManageConceptMasterCreateComponent,
    SearchBatchStatusComponent,
    ManageUserConceptComponent,
    ManageUserConceptCreateComponent,
    ManageSubPartyMasterComponent,
    SearchUserGroupComponent,
    CreateUserGroupComponent,
    ManageSubPartyMasterCreateComponent,
    CreateDistrictMasterComponent,
    SearchDistrictMasterComponent,
    ViewLocationQuantityComponent,
    ViewReportStatusComponent,
    CreateShipmentComponent,
    LogoutComponent,
    BulkCancellationComponent,
    MultipleRequisitionComponent,
    ManageSupplierAccessComponent,
    ManageSupplierAccessCreateComponent,
    ManageCcListComponent,
    ManageCcListCreateComponent,
    ManageCcListEditComponent,
    // MsalRedirectComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    // OAuthModule.forRoot(),
    FormsModule,
    NgxPaginationModule,
    TabModule,
    // AngularFontAwesomeModule,
    FontAwesomeModule,
    CalendarModule,BrowserAnimationsModule,TabViewModule,
    OrderModule,
    NgSelectModule,
    RdsModule,
    MsalModule,
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    // fakeBackendProvider,
    DatePipe,UtilsService,
    NavigationLinkParameterService,
    VersionCheckService,
    // OAuthService,JwksValidationHandler,
    // UrlHelperService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
