import { Injectable } from '@angular/core';
import { BilApiUrl } from '../common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchUserGroupService {
  user_group_url: string = BilApiUrl.USER_GROUP_DETAILS;
  user_groupList_url: string = BilApiUrl.USER_GROUP_LIST;
  user_group_update_url: string = BilApiUrl.UPDATE_USER_GROUP_DETAILS;
  constructor(private _http: HttpClient) { }

  getUserDetailBySearch(userGroupParameter) {
    console.log('searching parameter' + JSON.stringify(userGroupParameter));
    return this._http.post(this.user_group_url, userGroupParameter);
  }

  getGroupList(groupList){
    return this._http.post(this.user_groupList_url, groupList);
  }

  updateUserFlagStatus(details) {
    console.log('updating parameter' + JSON.stringify(details));
    return this._http.post(this.user_group_update_url, details, {
      observe: 'response',
      responseType: 'text'
    });
  }
}
