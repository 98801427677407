import { Injectable } from '@angular/core';
import { BilApiUrl } from '@/common/bil-api-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageConceptMasterService {
  private _manage_concept_master_url: string = BilApiUrl.MANAGE_CONCEPT_MASTER;
  private concept_url: string = BilApiUrl.CONCEPT_LIST_ALL_URL;
  constructor(private _http: HttpClient) { }

  getConceptMaster(manage_concept_master_param:any){
    return this._http.post(this._manage_concept_master_url,manage_concept_master_param);
  }
  
  getConcepts() {
    return this._http.post(this.concept_url, '');
  }
}
