import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { SearchRequisitionComponent } from './search-requisition/search-requisition.component';
import { MultipleRequisitionComponent } from './multiple-requisition/multiple-requisition.component';
import { SearchPoComponent } from './search-po/search-po.component';
import { PendingPoComponent } from './pending-po/pending-po.component';
import { ImportArticleComponent } from './import-article/import-article.component';
import { StationaryComponent } from './stationary/stationary.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { GeneratePoComponent } from './generate-po/generate-po.component';

import { OrderFulfillmentComponent } from './order-fulfillment/order-fulfillment.component';

import { AuthGuard } from './helpers/auth.guard';
import { Role } from './models/role';
import { RequisitionDetailsComponent } from './search-requisition/requisition-details/requisition-details.component';
import { UpdatePoComponent } from './search-po/update-po/update-po.component';
import { PoListComponent } from './search-requisition/po-list/po-list.component';
import { UpdatePendingPoComponent } from './pending-po/update-pending-po/update-pending-po.component';
import {FactoryPoComponent } from './factory-po/factory-po.component';
import { DistributionDetailComponent } from './distribution-detail/distribution-detail.component';
import { DistributionDetailCompletedComponent } from './distribution-detail-completed/distribution-detail-completed.component';
import {DistributionDetailMultipleComponent} from './distribution-detail-multiple/distribution-detail-multiple.component';
import { ViewShipmentReceiptComponent } from './view-shipment-receipt/view-shipment-receipt.component';
import { ViewShipmentReceiptDetailsComponent } from './view-shipment-receipt-details/view-shipment-receipt-details.component';
import {BulkRecallComponent} from './bulk-recall/bulk-recall.component';
import {PoDateChangeComponent} from './po-date-change/po-date-change.component';
import {BulkCancellationComponent} from './bulk-cancellation/bulk-cancellation.component';


import {EstimateUploadComponent} from './estimate-upload/estimate-upload.component';
import {ImportArticlePriceComponent} from './import-article-price/import-article-price.component';
import { GenerateReportComponent } from './generate-report/generate-report.component';
import { DistributionDetailPoComponent } from './distribution-detail-po/distribution-detail-po.component';
import{UploadExportComponentComponent} from './upload-export-component/upload-export-component.component'
import{PendingExportComponentComponent} from './pending-export-component/pending-export-component.component';
import{CreatePackingMasterComponent} from './create-packing-master/create-packing-master.component';

import {BatchesComponent} from './batches/batches.component';
import { SearchPackingMasterComponent } from './search-packing-master/search-packing-master.component';
import { SearchPackingMasterEditComponent } from './search-packing-master-edit/search-packing-master-edit.component';
import { ManageSizeMasterComponent } from './manage-size-master/manage-size-master.component';
import { ManageSizeMasterEditComponent } from './manage-size-master-edit/manage-size-master-edit.component';
import { ManageConceptMasterComponent } from './manage-concept-master/manage-concept-master.component';
import { ManageConceptMasterEditComponent } from './manage-concept-master-edit/manage-concept-master-edit.component';
import { ViewUploadStatusComponent } from './view-upload-status/view-upload-status.component';
import { ManageSizeMasterCreateComponent } from './manage-size-master-create/manage-size-master-create.component';
import { ManageConceptMasterCreateComponent } from './manage-concept-master-create/manage-concept-master-create.component';
import { SearchBatchStatusComponent } from './search-batch-status/search-batch-status.component';
import { ManageUserConceptComponent } from './manage-user-concept/manage-user-concept.component';
import { ManageUserConceptCreateComponent } from './manage-user-concept-create/manage-user-concept-create.component';
import { ManageCcListComponent } from './manage-cc-list/manage-cc-list.component';
import { ManageCcListCreateComponent } from './manage-cc-list-create/manage-cc-list-create.component';
import { ManageCcListEditComponent } from './manage-cc-list-edit/manage-cc-list-edit.component';
import { ManageSupplierAccessComponent } from './manage-supplier-access/manage-supplier-access.component';
import { ManageSupplierAccessCreateComponent } from './manage-supplier-access-create/manage-supplier-access-create.component';
import { ManageSubPartyMasterComponent } from './manage-sub-party-master/manage-sub-party-master.component';
import { SearchUserGroupComponent } from './search-user-group/search-user-group.component';
import { CreateUserGroupComponent } from './create-user-group/create-user-group.component';
import { ManageSubPartyMasterCreateComponent } from './manage-sub-party-master-create/manage-sub-party-master-create.component';
import { CreateDistrictMasterComponent } from './create-district-master/create-district-master.component';
import { SearchDistrictMasterComponent } from './search-district-master/search-district-master.component';
import { ViewLocationQuantityComponent } from './view-location-quantity/view-location-quantity.component';
import { ViewReportStatusComponent } from './view-report-status/view-report-status.component';
import { CreateShipmentComponent } from './rds/search-invoice/create-shipment/create-shipment.component';
import { from } from 'rxjs';

import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  //
  {
    path: 'search-packing-master-edit',
    component: SearchPackingMasterEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-report-status',
    component: ViewReportStatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'search-packing-master',
    component: SearchPackingMasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'generate-report',
    component: GenerateReportComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-shipment-receipt-details',
    component: ViewShipmentReceiptDetailsComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-shipment-receipt',
    component: ViewShipmentReceiptComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'distribution-detail-multiple',
    component: DistributionDetailMultipleComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-location-quantity',
    component: ViewLocationQuantityComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'distribution-detail',
    component: DistributionDetailComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'distribution-detail-completed',
    component: DistributionDetailCompletedComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'factory-po',
    component: FactoryPoComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'po-list',
    component: PoListComponent ,
    canActivate: [AuthGuard]
  },
  {
    path: 'requisition-details',
    component: RequisitionDetailsComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'bulk-recall',
    component: BulkRecallComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'po-date-change',
    component: PoDateChangeComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'bulk-cancellation',
    component: BulkCancellationComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'estimate-upload',
    component: EstimateUploadComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'import-article-price',
    component: ImportArticlePriceComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: HomeComponent,
    // canActivate: [AuthGuard]
    canActivate: [MsalGuard]
},
{ 
    path: 'admin', 
    component: AdminComponent, 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
},
{ 
    path: 'login', 
    component: LoginComponent
},
{ 
  path: 'logout', 
  component: LogoutComponent 
},
{ 
  path: 'search-requisition', 
  component: SearchRequisitionComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'multiple-requisition', 
  component: MultipleRequisitionComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'search-po', 
  component: SearchPoComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'update-po', 
  component: UpdatePoComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'pending-po', 
  component: PendingPoComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'update-pending-po', 
  component: UpdatePendingPoComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'imported-article', 
  component: ImportArticleComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'stationary', 
  component: StationaryComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'generate-report', 
  component: CreateReportComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'generate-export-po', 
  component: GeneratePoComponent, 
  canActivate: [AuthGuard]
},
{ 
  path: 'picklist', 
  component: OrderFulfillmentComponent, 
  canActivate: [AuthGuard]
},
{
  path: 'distribution-detail-po',
  component: DistributionDetailPoComponent ,
  canActivate: [AuthGuard]
},
{
  path: 'upload-export-component',
  component: UploadExportComponentComponent ,
  canActivate: [AuthGuard]
},
{
  path: 'pending-export-component',
  component: PendingExportComponentComponent,
  canActivate: [AuthGuard]
},
{
  path: 'create-packing-master',
  component: CreatePackingMasterComponent,
  canActivate: [AuthGuard]
},
{
  path: 'batches',
  component: BatchesComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-size-master',
  component: ManageSizeMasterComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-size-master-edit',
  component: ManageSizeMasterEditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-concept-master',
  component: ManageConceptMasterComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-concept-master-edit',
  component: ManageConceptMasterEditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'view-upload-status',
  component: ViewUploadStatusComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-size-master-create',
  component: ManageSizeMasterCreateComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-concept-master-create',
  component: ManageConceptMasterCreateComponent,
  canActivate: [AuthGuard]
},
{
  path: 'search-batch-status',
  component: SearchBatchStatusComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-user-concept',
  component: ManageUserConceptComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-user-concept-create',
  component: ManageUserConceptCreateComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-cc-list',
  component: ManageCcListComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-cc-list-edit',
  component: ManageCcListEditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-cc-list-create',
  component: ManageCcListCreateComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-supplier-access',
  component: ManageSupplierAccessComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-supplier-access-create',
  component: ManageSupplierAccessCreateComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-sub-party-master',
  component: ManageSubPartyMasterComponent,
  canActivate: [AuthGuard]
},
{
  path: 'search-user-group',
  component: SearchUserGroupComponent,
  canActivate: [AuthGuard]
},
{
  path: 'create-user-group',
  component: CreateUserGroupComponent,
  canActivate: [AuthGuard]
},
{
  path: 'manage-sub-party-master-create',
  component: ManageSubPartyMasterCreateComponent,
  canActivate: [AuthGuard]
  },
  {
    path: 'create-district-master',
    component: CreateDistrictMasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'search-district-master',
    component: SearchDistrictMasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-shipment',
    component: CreateShipmentComponent,
    canActivate:[AuthGuard]
  },
  
  {
    path: '',
    loadChildren: './rds/rds.module#RdsModule',
    canActivate: [AuthGuard]
  },
// otherwise redirect to home
{ path: '**', redirectTo: '' }
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
