import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UtilsService } from '../../common/utils-service';
import { Router, RouterModule } from '@angular/router';
import { ReportNames } from '../../common/reports-names';
import { ReportHeader } from '../rds-report-asn/report-header';
import { RdsReportAsnService } from '../rds-report-asn/rds-report-asn.service';
import { ViewRdsStatusService } from './view-rds-status.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-view-rds-status',
  templateUrl: './view-rds-status.component.html',
  styleUrls: ['./view-rds-status.component.css']
})
export class ViewRdsStatusComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  viewReportStatusForm: FormGroup;
  details: any;
  responseMessage: string;
  reportNames:ReportNames[];
  reportModel = new ReportHeader();
  userRole: string;
  username: string;
  reportDownloadName:any;
  onlyPerfix: any[] = [];
  uniquerper: any[] = [];

  constructor(private fb: FormBuilder,private _router: Router,
    private _generateReportService: RdsReportAsnService,
    private _viewReportService: ViewRdsStatusService, private httpClient: HttpClient, private _utilsService: UtilsService) {
    this.viewReportStatusForm = this.buildForm();
  }

  buildForm() {
    return this.fb.group({
      reportName : ['']
     });
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 1000);
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userRole=JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    this.username =  JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    console.log(sessUsername);
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        console.log(data);
        this.reportNames = data;
        this.reportNames.forEach(element => {
          let reportPrefixOnly: {} = element.reportName.split("_");
          let n = element.reportName.indexOf('_');          
          var output = element.reportName.substring(n + 1);
          let rsp_prf = {
            "perfix": reportPrefixOnly[0],
            "reportName": output,
            "reportKey": element.reportKey
          };
          this.onlyPerfix.push(rsp_prf);
        });
        this.uniquerper = [...new Set(this.onlyPerfix.map(x => x.perfix))];
        this.onlyPerfix.sort(this.dynamicSort("reportName"));
        this.viewReportStatusForm.controls.reportName.setValue("");
        this.onlyPerfix.forEach((element,index)=>{
          if(element.reportKey == 'INVOICE_PRINT') this.onlyPerfix.splice(index,1);
       });
      }
    );
  }

  fetchDetailsOnSubmit() {
    this.responseMessage = '';
    this.details = '';
    this.loaDer.nativeElement.style.display = 'block';
    let reportName = this.viewReportStatusForm.controls.reportName.value;
    this.reportNames.filter(cs => cs.reportKey == reportName).forEach(
    report => {
      this.reportDownloadName = report.reportName;
    }
    );
    let usrname=this.username;
    if(reportName == "INVOICE_PRINT_BULK_BATCH"){
        usrname="Scheduled";
    }
    let param = [
      {
        "name": "reportKey",
        "value": reportName
      },
      {
        "name": "invokedBy",
        "value": usrname
      }
    ];
   
    this._viewReportService.getReportData(param).subscribe(
      (response: any) => {
        console.log(response);
        let substring="SFTP";
        let checkUrl="";
        if (response.dataList != null && response.dataList.length > 0) {
          this.details = response.dataList;
          this.details.sort((a, b) => {
            return <any>new Date(b.reportRunTime) - <any>new Date(a.reportRunTime);
          });
          this.details.forEach(
            batch => {
              if (batch.reportUrl != null){
               checkUrl=batch.reportUrl.includes(substring);
            }
              console.log(checkUrl);
              if (batch.reportUrl != null && !checkUrl) {
                batch.startingUrl = batch.reportUrl.substr(0,8);
              }
              if(checkUrl){
                batch.records=batch.reportUrl;
              }else{
                batch.records="";
              }
              let RunDate: any;
              RunDate = new Date(batch.reportRunTime);
              batch.reportRunTime = this._utilsService.getCurrentDateTimeFormat(RunDate);
            }
          );
          console.log(this.details);
          
        }
        else {
          this.responseMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (error) => {
        this.responseMessage = error;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  public async downloadReport(reportDownloadUrl,reportname,filename,reportKey): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    const file = await this.httpClient.get<Blob>(reportDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    if(reportKey == 'GRS'){
      anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'pdf';
    }
    else if(reportKey == 'OGPR'){
      anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'pdf';
    }
    else if(reportKey == 'SAR'){
      anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'pdf';
    }
    else if(reportKey == 'GRRM_BC'){
      anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'xls';
    }
    else if(reportKey == 'S_ST' || reportKey == 'INV_PV_VAR'){
      anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'pdf';
    }
    else{
    // let updatedFilename = filename.toString().substr(14,14);
    // let fileYRWK = updatedFilename.toString().substr(0,6);
    //   anchor.download = 'RTLORDER_ONWAY_' + fileYRWK + '.' + 'xlsx';
    anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'xlsx';
    }
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none';
  }
  
    dynamicSort(property) {
     console.log(property);
     
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
}
