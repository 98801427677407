import { Injectable } from '@angular/core';
import { BilApiUrl } from '../common/bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageUserConceptService {
  concept_url: string = BilApiUrl.CONCEPT_DETAILS;
  user_concept_url: string = BilApiUrl.USER_CONCEPT_DETAILS;
  user_concept_update_url: string = BilApiUrl.UPDATE_USER_CONCEPT_DETAILS;
  constructor(private _http: HttpClient) { }


  getUserConcept() {
    return this._http.post(this.concept_url, null);
  }

  getUserDetailBySearch(userConceptParameter) {
    console.log('searching parameter' + JSON.stringify(userConceptParameter));
    return this._http.post(this.user_concept_url, userConceptParameter);
  }

  updateUserFlagStatus(details) {
    console.log('updating parameter' + JSON.stringify(details));
    return this._http.post(this.user_concept_update_url, details, {
      observe: 'response',
      responseType: 'text'
    });
  }
}
