import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { RequestParameterModel } from '../common/request-parameter-model';
import { SearchPackMasterDetails } from '../search-packing-master/search-pack-master-details';
import { SearchPackingMasterEditService } from './search-packing-master-edit.service';
import { UtilsService } from '../common/utils-service';
import { importArticle } from '../import-article-price/import_article';
import { SizePackingResponseModel } from '../common/size-pack-master';
import { AssortmentDetails } from '../search-packing-master/assortment-details';
import { ResponseMessage } from '../common/response-message';
import { SUCCESS_MSG } from '../common/field-name-list';
import { Router, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Concept } from '../common/concept';

@Component({
  selector: 'app-search-packing-master-edit',
  templateUrl: './search-packing-master-edit.component.html',
  styleUrls: ['./search-packing-master-edit.component.css']
})
export class SearchPackingMasterEditComponent implements OnInit {
  @ViewChild('cancel', { static: false }) cancel: ElementRef;
  @ViewChild('closeDelete', { static: false }) closeDelete: ElementRef;
  private _requsetParameterModelArray: RequestParameterModel[];
  concepts: Concept[];
  holdingPackingData = new SearchPackMasterDetails();
  editPackMasterDetails = new SearchPackMasterDetails();
  requestParameterModel: RequestParameterModel[];
  errorMessage: string;
  newAssortment = new AssortmentDetails();
  searchPackingAssortmentDetails: AssortmentDetails[] = [];
  availableSizes = new Array();
  avaliableSizeLabel = new Array();
  ind: number = 0;
  count: number = 0;
  flag: number = 0;
  responseMessage: string;
  assortmentSizes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
  groupCodes = [{ id: "1", value: "A" }, { id: "2", value: "B" }, { id: "3", value: "C" },
  { id: "4", value: "D" }, { id: "5", value: "E" }]
  assortmentType = [{ name: "Assorted", value: "A" }, { name: "Wholesale Assorted", value: "W" }];
  profileForm: FormGroup;
  profileForm1: FormGroup;
  uploadFileName: string = 'No Choose File';
  createdUser: string;
  currentDate: string;
  showModal: boolean = false;
  myDate = new Date();
  myForm: FormGroup;
  selectedIndex: number = 0;
  editedPackingData = [{
    packingId: '',
    concept: '',
    catCode: '',
    shopCode: '',
    packCode: '',
    groupCode: '',
    assortmentType: '',
    createdBy: '',
    createdDate: '',
    assortmentCode:'',
    lastUpdatedBy:'',
    lastUpdateDate:'',
    packingMasterAssortmentsDTO: []
  }]
  obj = {
    "concept": null,
    "catCode": null,
    "shopCode": null,
    "packCode": '',
    "assortmentCode": null,
    "shopcodes":[]
  }
  totalDataPassed = this.editedPackingData;
  categoryCodes: any[] = [];
  ShopCodes: any;
  enableShop: boolean = true;
  paramSelect: boolean = false;
  confirmCheck: boolean[] = [] ;
  holdingShopCode:any[]= [];

  constructor(private _navigationLinkParameterService: NavigationLinkParameterService,
    private _searchPackingmasterEdit: SearchPackingMasterEditService,
    private _utilsService: UtilsService, private fb: FormBuilder,
    private _router: Router, private datePipe: DatePipe) {
    this.currentDate = this.datePipe.transform(this.myDate, 'dd/MM/yyyy');
  }

  ngOnInit() {

    this.editPackMasterDetails.packingMasterAssortmentsDTO = this._navigationLinkParameterService.assortmentDetails;
    if (this._navigationLinkParameterService.details != null && this._navigationLinkParameterService.details.length > 0) {
      this._requsetParameterModelArray = [];
      this._requsetParameterModelArray = this._navigationLinkParameterService.details;
      console.log('packing edit data' + this._requsetParameterModelArray);
      this._requsetParameterModelArray.forEach(
        requsetParameter => {
          if ('packingId' === requsetParameter.name) {
            this.editPackMasterDetails.packingId = requsetParameter.value;
          }
          if ('concept' === requsetParameter.name) {
            this.editPackMasterDetails.concept = requsetParameter.value;
          }
          if ('iCode' === requsetParameter.name) {
            this.editPackMasterDetails.iCode = requsetParameter.value;
          }

          if ('catCode' === requsetParameter.name) {
            this.editPackMasterDetails.catCode = requsetParameter.value;
          }

          if ('shopCode' === requsetParameter.name) {
            this.editPackMasterDetails.shopCode = requsetParameter.value;
          }

          if ('assortmentType' === requsetParameter.name) {
            this.editPackMasterDetails.assortmentType = requsetParameter.value;
          }

          if ('packCode' === requsetParameter.name) {
            this.editPackMasterDetails.packCode = requsetParameter.value;
          }

          if ('groupCode' === requsetParameter.name) {
            this.editPackMasterDetails.groupCode = requsetParameter.value;
          }

          if ('createdBy' === requsetParameter.name) {
            this.editPackMasterDetails.createdBy = requsetParameter.value;
          }
          if ('createdDate' === requsetParameter.name) {
            this.editPackMasterDetails.createdDate = requsetParameter.value;
          }

          if ('lastUpdatedBy' === requsetParameter.name) {
            this.editPackMasterDetails.lastUpdatedBy = requsetParameter.value;
          }
          if ('lastUpdateDate' === requsetParameter.name) {
            this.editPackMasterDetails.lastUpdateDate = requsetParameter.value;
          }
          if ('assortmentCode' === requsetParameter.name) {
            this.editPackMasterDetails.assortmentCode = requsetParameter.value;
          }
        }
      );
      let sessUsername = sessionStorage.getItem('currentUser');
      this.createdUser = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
      for(var k = 0 ; k<this.editPackMasterDetails.packingMasterAssortmentsDTO.length;k++){
        this.confirmCheck[k] = true;
      }
      if (this.editPackMasterDetails.assortmentType == 'W') {
        this.editPackMasterDetails.concept = null;
        this.editPackMasterDetails.catCode = null;
        this.editPackMasterDetails.shopCode = null;
        this.editPackMasterDetails.assortmentCode = this.editPackMasterDetails.assortmentCode;
        //this.editPackMasterDetails.packCode = null;
        // this.editPackMasterDetails.iCode = null;
        this.enableShop = true;
      }
      else {
        this.enableShop = false;
        this.obj.concept = this.editPackMasterDetails.concept;
        this.obj.catCode = this.editPackMasterDetails.catCode;
        this.obj.shopCode = this.editPackMasterDetails.shopCode;
        this.obj.packCode = this.editPackMasterDetails.packCode;
       // this.editPackMasterDetails.assortmentCode = null;
        this.obj.assortmentCode = this.editPackMasterDetails.assortmentCode;
      }
    }

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ "name": "email", "value": JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this._searchPackingmasterEdit.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        const distinctConcept = this.concepts.filter(
          (thing, i, arr) => arr.findIndex(t => t.concCode === thing.concCode) === i
        ); 
        this.concepts = [];
        this.concepts = distinctConcept;
        console.log('concept value--->' + JSON.stringify(this.concepts));
        this.getShop();
      }
    );

    this._searchPackingmasterEdit.getCategoryCode().subscribe(
      (res: any) => {
        res.dataList.forEach(
          catCode => {
            this.categoryCodes.push(catCode.categoryCode);
          }
        );
        console.log(this.categoryCodes);
      }
    );

    this.myForm = this.fb.group({
      companies: this.fb.array([])
    })

    this.setCompanies();
  }

  onSelectFile(event) {
    this.uploadFileName = event.target.files[0].name;
  }

  setAssortmentType(e) {
    this.responseMessage = '';
    this.errorMessage = '';
    let value = (Object)(e.target.value);
    // this.obj = {
    //   "concept":this.holdingPackingData.concept,
    //   "catCode":this.holdingPackingData.catCode,
    //   "shopCode":this.holdingPackingData.shopCode,
    //   "packCode":this.holdingPackingData.packCode
    // }
    if (value == 'W') {
      this.editPackMasterDetails.assortmentCode = this.editPackMasterDetails.assortmentCode;
      this.editPackMasterDetails.concept = null;
      this.editPackMasterDetails.catCode = null;
      this.editPackMasterDetails.shopCode = null;
      this.editPackMasterDetails.packCode = null;
      this.editPackMasterDetails.iCode = null;
      this.enableShop = true;
      this.holdingShopCode = this.ShopCodes;
      this.obj.shopcodes = this.holdingShopCode;
    }
    else {
      this.editPackMasterDetails.concept = this.obj.concept;
      this.editPackMasterDetails.catCode = this.obj.catCode;
      this.editPackMasterDetails.shopCode = this.obj.shopCode;
      this.editPackMasterDetails.packCode = this.obj.packCode;
      if(this.editPackMasterDetails.assortmentType != null){
      this.ShopCodes = this.obj.shopcodes;
      }
      if(this.editPackMasterDetails.shopCode == this.obj.shopCode){
        this.enableShop = false;
      }
    }
    console.log(JSON.stringify(this.editPackMasterDetails));
  }

  setValue() {
    this.uploadFileName = '';
  }

  onConceptChange(e) {
    this.responseMessage = '';
    this.errorMessage = '';
    let value = (Object)(e.target.value);
    //this.editPackMasterDetails.shopCode = null;
    this.editPackMasterDetails.concept = value;
    this.enableShop = false;
    this.editPackMasterDetails.shopCode = "null";
    if(value != "null"){
      this.getShop();
    }
    // this.obj.shopcodes = this.ShopCodes;
  }

  getShop(): any {
    let divisionCode: string;
    console.log('concepts----->' + this.concepts);
    console.log('concept----->' + this.editPackMasterDetails.concept);
    this.concepts.filter(cs => cs.concCode == this.editPackMasterDetails.concept).forEach(
      div => {
        if (div.division == 'RETAIL') {
          divisionCode = '5';
          //this.editPackMasterDetails.assortmentType = 'A';
        }
        else {
          divisionCode = '4';
          // this.editPackMasterDetails.assortmentType = 'W';
        }
      }
    )
    let detail = {
      "shopCloseYrWk1": "000000",
      "shopAgencyCode": divisionCode,
      "userEmail": JSON.parse(sessionStorage.getItem('currentUser')).groupAssignmentModels[0].userCode,
      "conceptCode": this.editPackMasterDetails.concept
    }
    let requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    this._searchPackingmasterEdit.getShopCodeByUser(requestParameterModel).subscribe(
      (res: any) => {
        console.log(JSON.stringify(res));
        this.ShopCodes = res.dataList;
      }
    );
    return this.ShopCodes;
  }

  addAssortment() {
    this.responseMessage = '';
    this.errorMessage = '';
    let control = <FormArray>this.myForm.controls.companies;
    console.log('sizes length--------' + this.availableSizes.length);
    console.log('sizes length--------' + control.length);
    if (this.availableSizes.length === control.length) {
      this.errorMessage = 'No more assortments to add';
    }
    else{
      let control = <FormArray>this.myForm.controls.companies;
      let length = control.length;
      this.confirmCheck[length] = false;
      control.push(
        this.fb.group({
          pckAssortmentId: 0,
          pairs: [''],
          size: [''],
          sizeLabel: [{ value: '', disabled: true }],
          createdBy: [''],
          createdDate: [''],
        })
      )
    }
    
  }

  onSizeSelect(e: any, index) {
    this.responseMessage = '';
    this.errorMessage = '';
    let sizeValue = e.target.selectedOptions[0].label;
    for (var k = 0; k < this.availableSizes.length; k++) {
      if (sizeValue == this.availableSizes[k]) {
        this.myForm.get(['companies', index]).get('sizeLabel').setValue(this.avaliableSizeLabel[k]);
      }
    }
  }

  packLineDetails() {
    this.responseMessage = '';
    this.errorMessage = '';
   let control = <FormArray>this.myForm.controls.companies;
   let editedLength:number = control.length;
   for(var i = 0; i<editedLength;i++){
     if(this.confirmCheck[i] == false){
      let index = (control.length)-1;
       control.removeAt(index);
     }
   }
    this.count = 0;
    this.ind = 0;
    this.searchPackingAssortmentDetails = [];
    this.errorMessage = '';
    let details = {
      "name": "groupCode",
      "value": this.editPackMasterDetails.groupCode
    }
    this._searchPackingmasterEdit.getSizeDetails(details).subscribe(
      (result: any) => {
        this.availableSizes = new Array();
        this.avaliableSizeLabel = new Array();
        result.dataList.forEach(
          packDetails => {
            for (var j = this.count; j < this.count + 1; j++) {
              this.availableSizes[j] = packDetails.sizeBucket;
              this.avaliableSizeLabel[j] = packDetails.artSize;
            }
            this.count++;
          }
        );
      }
    );
  }

  setCompanies() {
    this.selectedIndex = 0;
    let control = <FormArray>this.myForm.controls.companies;
    this.editPackMasterDetails.packingMasterAssortmentsDTO.forEach(x => {
      control.push(this.fb.group({
        pckAssortmentId: x.pckAssortmentId,
        pairs: x.pairQty.toString(),
        size: x.articleSize,
        sizeLabel: x.sizeLabel,
        createdBy: x.createdBy,
        createdDate: x.createdDate
      }))
      this.myForm.get(['companies', this.selectedIndex]).get('size').disable();
      this.myForm.get(['companies', this.selectedIndex]).get('sizeLabel').disable();
      this.selectedIndex++;
    })
  }

  onClickConfirmChanges(assortmentValue) {
    this.responseMessage = '';
    this.errorMessage = '';
    this.editedPackingData = [{
      packingId: this.editPackMasterDetails.packingId,
      concept: this.editPackMasterDetails.concept,
      catCode: this.editPackMasterDetails.catCode,
      shopCode: this.editPackMasterDetails.shopCode,
      packCode: this.editPackMasterDetails.packCode,
      groupCode: this.editPackMasterDetails.groupCode,
      assortmentType: this.editPackMasterDetails.assortmentType,
      assortmentCode:this.editPackMasterDetails.assortmentCode,
      createdBy: this.editPackMasterDetails.createdBy,
      createdDate: this.editPackMasterDetails.createdDate,
      lastUpdatedBy : this.createdUser,
      lastUpdateDate : this.currentDate,
      packingMasterAssortmentsDTO: this.getAssortmentDetails(assortmentValue.companies.controls)
    }];
    console.log(this.editedPackingData);
    if(this.paramSelect == true){
      let closeflag:number = 0;
      this.editedPackingData[0].packingMasterAssortmentsDTO.forEach(
        quantCheck => {
          if(quantCheck.pairQty == '' || quantCheck.pairQty == "null"){
            this.errorMessage = 'Please fill the assortment details';
            closeflag = closeflag + 1;
          }
        }
      );
      if(closeflag == 0){
        this.delay(1500).then(any => {
          //your task after delay.
          this.cancel.nativeElement.click();
        });
      }
    }
  }

  getAssortmentDetails(details) {
    this.paramSelect = false;
    let assortmentArrayList = [];
    for (var i = 0; i < details.length; i++) {
      if (details[i].controls.pckAssortmentId.value != 0 || details[i].controls.createdBy.value != 0
        || details[i].controls.createdDate.value != 0) {
        assortmentArrayList.push(
          {
            pckAssortmentId: details[i].controls.pckAssortmentId.value,
            pairQty: details[i].controls.pairs.value,
            articleSize: details[i].controls.size.value,
            sizeLabel: details[i].controls.sizeLabel.value,
            createdBy: details[i].controls.createdBy.value,
            createdDate: details[i].controls.createdDate.value,
            lastUpdatedBy : this.createdUser,
            lastUpdateDate : this.currentDate
          }
        );
      }
      else {
        assortmentArrayList.push(
          {
            pairQty: details[i].controls.pairs.value,
            articleSize: details[i].controls.size.value,
            sizeLabel: details[i].controls.sizeLabel.value,
            createdBy: this.createdUser,
            createdDate: this.currentDate,
            lastUpdatedBy : this.createdUser,
            lastUpdateDate : this.currentDate
          }
        );
      }
    }
    this.flag = 0;
    for (var i = 0; i < assortmentArrayList.length; i++) {
      for (var j = i + 1; j < assortmentArrayList.length; j++) {
        if (assortmentArrayList[i].articleSize == assortmentArrayList[j].articleSize) {
          this.flag = this.flag + 1;
        }
      }
    }
    console.log('flag caclulated'+this.flag);
    if (this.flag == 0) {
      this.errorMessage = 'Changes have been confirmed';
      this.paramSelect = true;
      for(var k = 0;k<assortmentArrayList.length;k++){
        this.confirmCheck[k] = true;
      }
      console.log(JSON.stringify(assortmentArrayList));
      return assortmentArrayList;
    }
    else {
      this.errorMessage = 'Please enter different size ratio';
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  onClickSave() {
    this.responseMessage = '';
    let packCodeValue:string;
    console.log(this.editPackMasterDetails.assortmentCode);
    if(this.editPackMasterDetails.assortmentCode == null || this.editPackMasterDetails.assortmentCode == undefined){
      console.log('inside if');
      this.editPackMasterDetails.assortmentCode = "null";
    }
    if(this.editPackMasterDetails.assortmentType == 'W'){
      packCodeValue = null;
     }
     else{
       packCodeValue = this.editPackMasterDetails.packCode;
     }
    this.responseMessage = '';
    let packingData: any[];
    if (this.paramSelect == true) {
      console.log('if');
      packingData = this.editedPackingData[0].packingMasterAssortmentsDTO;
    }
    else {
      console.log('else');
      packingData = this.editPackMasterDetails.packingMasterAssortmentsDTO;
    }
    this.totalDataPassed = [
      {
        packingId: this.editPackMasterDetails.packingId,
        concept: this.editPackMasterDetails.concept,
        catCode: this.editPackMasterDetails.catCode,
        shopCode: this.editPackMasterDetails.shopCode,
        packCode: packCodeValue,
        groupCode: this.editPackMasterDetails.groupCode,
        assortmentType: this.editPackMasterDetails.assortmentType,
        assortmentCode:this.editPackMasterDetails.assortmentCode,
        createdBy: this.editPackMasterDetails.createdBy,
        createdDate: this.editPackMasterDetails.createdDate,
        lastUpdatedBy : this.createdUser,
        lastUpdateDate : this.currentDate,
        packingMasterAssortmentsDTO: packingData
      }
    ]
    console.log(JSON.stringify(this.totalDataPassed));
    if((this.totalDataPassed[0].assortmentType == 'A' || (this.totalDataPassed[0].assortmentType != "null" && this.totalDataPassed[0].assortmentType != null)) && ((this.totalDataPassed[0].concept != "null" && this.totalDataPassed[0].concept != null) && 
      (this.totalDataPassed[0].catCode !="null" && this.totalDataPassed[0].catCode !=null) && (this.totalDataPassed[0].shopCode != "null" && this.totalDataPassed[0].shopCode != null) && ((this.totalDataPassed[0].packCode != "null" && this.totalDataPassed[0].packCode != "") && this.totalDataPassed[0].packCode != null))){
        this.savingDetails(this.totalDataPassed);
        console.log('confirm save assorted');
      }
      else{
        this.responseMessage = 'Please fill the packing details';
      }
     if(this.totalDataPassed[0].assortmentType == 'W' && this.totalDataPassed[0].groupCode != "null"){
      this.savingDetails(this.totalDataPassed);
      console.log('confirm save WA');
     }
  }

  savingDetails(savingParam){
    this._searchPackingmasterEdit.saveEditPackingDetails(savingParam).subscribe(
        (response: ResponseMessage) => {
          console.log(response);
          if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
            this.responseMessage = 'Packing Master Data saved successfully';
          }
          if(response != null && response.status != null && response.status == 'ERROR'){
            this.responseMessage = response.message;
          }
        },
        (error) => {
          this.responseMessage = error;
        }
      );
  }

  onClickBack() {
    this._router.navigate(['search-packing-master']);
  }

  deletePackingDetails() {
    let deleteDetails = {
      "name": "packingId",
      "value": this.editPackMasterDetails.packingId
    }
    console.log('deleting details' + JSON.stringify(deleteDetails));
    this._searchPackingmasterEdit.deletePackMaster(deleteDetails).subscribe(
      (response: ResponseMessage) => {
        console.log(response);
        if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
          this.responseMessage = 'Deleted successfully';
          this.delay(1000).then(any => {
            //your task after delay.
            this.closeDelete.nativeElement.click();
          });
          this._router.navigate(['search-packing-master']);
        }
        if(response != null && response.status != null && response.status == 'ERROR'){
          this.responseMessage = response.message;
        }
      },
      (error) => {
        this.responseMessage = error;
      }
    );
  }

}
