import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RdsBilApiUrl } from '../../../common/rds-bil-api-url';
import { BilApiUrl } from '../../../common/bil-api-url';
import { Observable, of, throwError, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FreightDetailService {

 SEARCH_ASN_URL: string = RdsBilApiUrl.RDC_SEARCH_LINES;
 CREATE_GIN_URL: string = RdsBilApiUrl.RDC_CREATE_GIN;
 GIN_NUMBER_URL: any = RdsBilApiUrl.GIN_SEARCH;
 SAVE_UPDATE_ASN_URL: any = RdsBilApiUrl.SAVE_UPDATE_ASN_URL;
 FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL: any = BilApiUrl.FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL;
 transporter_name: string = RdsBilApiUrl.TRANSPORTER_NAME_FETCH;
 updateURL: string = RdsBilApiUrl.UPDATE_FREIGHT_MASTER;
 isPrimary:any;
 isSecondary:any;

// tslint:disable-next-line: variable-name
constructor( private http: HttpClient) { }

  getInvoiceLineDetails(challanInvoice,userCode) {
    const details =
    [{ 
      "name": "supplyNo", 
      "value": challanInvoice
    },
    { "name": "userCode", 
      "value": userCode
    }
     ];

    return this.http.post(this.SEARCH_ASN_URL, details);
  }

  getSizeMaster(articleVal) {
    const details = [{ "name": "letterOfArticle", "value": articleVal }];

    return this.http.post(this.FETCH_EDITABLE_ASSORTMENT_PACKAGES_URL,details );
  }

  addpost(GINNumber) {
    return this.http.post(this.CREATE_GIN_URL , GINNumber);
  }

  saveAsnDetail(assortList) {
    return this.http.post(this.SAVE_UPDATE_ASN_URL , assortList);
  }


  getGinNo(invoiceNumber, consignorCode): any {
    const details = {
      // tslint:disable-next-line: object-literal-key-quotes
      'invoiceNumber': invoiceNumber,
      // tslint:disable-next-line: object-literal-key-quotes
      'consignorCode': consignorCode
     };
     // console.log(this.GIN_NUMBER_URL+"/"+invoiceNumber);
    return  this.http.post<any>(this.GIN_NUMBER_URL, details);
  }
  getTransporterName(freightType) {
   
    if(freightType === "P"){
      this.isPrimary="Y";
      this.isSecondary="N";
      var details =[{

        "configList": [
              {
              "name": "isPrimary",
              "value": this.isPrimary
              }
          ]
        }]
    }else{
      this.isPrimary="N";
      this.isSecondary="Y";
      var details =[{

        "configList": [
              {
                "name": "isSecondary",
                "value": this.isSecondary
            }
          ]
        }]
    }
        
    return this.http.post(this.transporter_name, details);
  }


fakeValidateUserData(): any {
  return of('');
}

updateData(details:any){
    console.log(details);
    return this.http.put(this.updateURL,details);
  }
}
