import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { PurchaseOrderLines } from '../search-po/po-lines';
import { PurchaseOrderHeader } from '../search-po/po-header'
import { RequestParameterModel } from '../common/request-parameter-model';
import { UpdatePoResponseModel } from '../common/update-po-response-model';
import { ViewShipmentReceiptService } from './view-shipment-receipt.service'
import { UtilsService } from '../common/utils-service';
import { Router, RouterModule } from '@angular/router';
import { SUCCESS_MSG, ITEMS_PER_PAGE } from '../common/field-name-list';
import { PurchaseOrderHeaderWithLines } from '../search-po/po-header-with-lines';

@Component({
  selector: 'app-view-shipment-receipt',
  templateUrl: './view-shipment-receipt.component.html',
  //styleUrls: ['./view-shipment-receipt.component.css']
  styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css','./view-shipment-receipt.component.css']
})
export class ViewShipmentReceiptComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  constructor(private _navigationLinkParameterService: NavigationLinkParameterService, private _viewShipmentService: ViewShipmentReceiptService, private _router: Router,
    private _utilsService: UtilsService) { }
  poNumber: string;
  ViewShipmentLines: PurchaseOrderLines[] = [];
  ViewShipmentDetails = new PurchaseOrderLines();
  purchaseOrderHeader: PurchaseOrderHeader;
  requestParameterModel: RequestParameterModel[] = [];
  public noresults = false;
  division: string;
  currentPage: number = 1;
  responseMessage: string;

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);
  }

  onClickSearchpoNumber() {
    this.loaDer.nativeElement.style.display = 'block';
    this.responseMessage = '';
    console.log(' poNumber ' + this.poNumber);
    this._viewShipmentService.getViewShipmentServiceCriteria(this.poNumber).subscribe(
      (response: UpdatePoResponseModel) => {
        console.log(JSON.stringify(response));
        if (response.status == "True") {
          response.dataList.forEach(
            searchPo => {
              if (this.poNumber === searchPo.poNumber) {
                this.ViewShipmentLines = searchPo.poLines;
                this.ViewShipmentLines.forEach(
                  locationObj => {
                    let outstandingQuant = locationObj.poArtQty - locationObj.shippedQty - locationObj.cancelledQty;
                    let onwayQuant = locationObj.shippedQty - locationObj.receivedQty;
                    if (outstandingQuant < 0) {
                      locationObj.outstandingQty = '0';
                    }
                    else {
                      locationObj.outstandingQty = (outstandingQuant).toString();
                    }
                    if (onwayQuant < 0) {
                      locationObj.onWayQty = '0';
                    }
                    else {
                      locationObj.onWayQty = (onwayQuant).toString();
                    }
                  }
                );
                this.division = searchPo.division;
                this.loaDer.nativeElement.style.display = 'none';
              }
            }
          )
        }
        else {
          this.responseMessage = 'No Data Found';
          this.ViewShipmentLines = [];
          this.loaDer.nativeElement.style.display = 'none';
        }
      },
      (error) => {
        this.responseMessage = error;
        this.loaDer.nativeElement.style.display = 'none';
      }
    )
  }

  onClickBack() {
    this._router.navigate(['search-po']);
  }

  onClickSearchpo(index) {
    let absoluteIndex = ITEMS_PER_PAGE * (this.currentPage - 1) + index;
    console.log("Ponumber" + this.poNumber);
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.ViewShipmentLines[absoluteIndex]);
    this._navigationLinkParameterService.details = this.requestParameterModel;
    this._navigationLinkParameterService.id = this.division;
    this._navigationLinkParameterService.podetails = this.poNumber;
    this._router.navigate(['view-shipment-receipt-details']);
  }
  
}



