export const DATE_FIELD_LIST : string[] = ['reqCreateDate','lastUpdatedDate','delvFrmWk','delvToWk','delvToDate','lastUpdateDate'];
export const  PO_NUMBER :string = 'poNumber';
export const  REQUISITION_LINES_DISTRIBUTION_PENDING :string = 'Pending Distribution ';
export const  REQUISITION_LINES_DISTRIBUTION_COMPLETED :string = 'Completed Distribution ';
export const  PO_CREATED :string = 'PO Created';
export const  PO_STATUS :string = 'poStatus';
export const  PO_STATUS1 :string = 'status';
export const REQUISITION_LINES_STATUS_OPEN : string ='Open';
export const REQUISITION_LINES_STATUS_PENDING : string='Ready';
export const REQUISITION_LINES_STATUS_COMPLETED : string= 'Completed';
export const PO_STATUS_PENDING : string= 'Pending';
export const DISTRIBUTION_PAGE_DETAILS_TITLE : string= 'Distribution Details';
export const DISTRIBUTION_PAGE_MANAGE_DETAILS_TITLE : string= 'View & Manage Distribution Details';
export const REQUISITION_DETAILS : string= 'Requisition Details';
export const RETAIL_DIVISION : string='RETAIL';
export const NON_RETAIL_DIVISION : string='NON-RETAIL';
export const EXPORT_DIVISION : string='EXPORT';
export const RETAIL_DIVISION_VALUE : string='5';
export const NON_RETAIL_DIVISION_VALUE : string='4';
export const LOCATION_DIVISION_PARAM : string='000000';
export const NUMBER_OF_SIZE_AVAILABEL = 15;
export const SUCCESS_MSG ='success';
export const ASSORTED ='Assorted'
export const SOLID ='Solid';
export const WHOLE_SALE ='Wholesale Assorted';
export const ASSORTED_CODE ='A';
export const SOLID_CODE ='S';
export const WHOLE_SALE_CODE ='W';
export const ITEMS_PER_PAGE:number=10;
export const CURRENT_PAGE:number=1;
export const PO_COMPONENT_STATUS_PENDING : string= 'PENDING';
export const NO_RESULT_FOUND = '  No results found.';
export const USER_ROLE_ORDERING = 'Ordering';
export const PENDING_CANCEL = 'Pending Cancel';
export const PENDING_APPROVAL = 'Pending Approval';
export const PENDING_RECALL = 'Pending Recall';
export const CANCELLED = 'Cancelled';
export const CLOSED = 'Closed';
export const REJECTED = 'Rejected';
export const isRefreshing=false;