export class SearchForm {
    
    shopCode:string;
    artType:string;
    conceptCode:string;
    artNumber:string;
    stockType:string;
   
   
    constructor(){}
}