import { BilApiUrl } from '@/common/bil-api-url';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class FreightMasterService {
  concept_url: string = RdsBilApiUrl.CONCEPT_LIST_URL;
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  uploadfileURL: string = RdsBilApiUrl.UPLOAD_TO_STORAGE;
  searchURL: string = RdsBilApiUrl.SEARCH_FREIGHT_MASTER;
  updateURL: string = RdsBilApiUrl.UPDATE_FREIGHT_MASTER;
  generateFreightMaster: string = RdsBilApiUrl.UPDATE_FREIGHT_MASTER;
  constructor(private http: HttpClient) { }

  getConcepts(conceptQueryMas) {
    return this.http.post(this.concept_url, conceptQueryMas);
  }
  getDownloadUrl() {

    return this.http.get(this.urlForDownloadUrl);

  }

  upload(formData) {

    return this.http.post(`${this.uploadfileURL}`, formData);

  }

  getSearch(details:any){
    return this.http.post(this.searchURL,details);
  }
  updateStatus(details:any){
    console.log(details);
    return this.http.put(this.updateURL,details);
  }

  public getGenerate(uploadKeyValues: any) {
    return this.http.post(this.generateFreightMaster, uploadKeyValues, httpOptions);
  }
}
