import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RdsRoutingModule } from './rds-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { CalendarModule } from 'primeng/calendar';
import { TabModule } from 'angular-tabs-component';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TabViewModule } from 'primeng/tabview';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { DatePipe } from '@angular/common';
import { OrderModule } from 'ngx-order-pipe';

import { SearchGinComponent } from './search-gin/search-gin.component';
import { SearchGrnComponent } from './search-grn/search-grn.component';
import { CycleCountComponent } from './cycle-count/cycle-count.component';
// import { GenerateGinComponent } from './generate-gin/generate-gin.component';
// import { GinDetailsComponent } from './generate-gin/gin-details/gin-details.component';
import { SearchAsnListComponent } from './asn-search/search-asn.component';
import { ResupplyComponent } from './re-supply-status/re-supply-status.component';
import { SearchInvoiceComponent } from './search-invoice/search-invoice.component';
import { ViewInvoiceComponent } from './search-invoice/view-invoice/view-invoice.component';
import { AsnUploadComponent } from './asn-upload/asn-upload.component';
import { ResupplyUploadComponent } from './resupply-upload/resupply-upload.component';
import { AsnDetailComponent } from './asn-search/asn-detail/asn-detail.component';
import { BarcodeScanComponent } from './barcode-scan/barcode-scan.component';
import { BarcodeScanDispatchComponent } from './barcode-scan-dispatch/barcode-scan-dispatch.component';
import { AsnUpdateComponent } from './asn-search/asn-update/asn-update.component';
import { SearchInventoryComponent } from './search-inventory/search-inventory.component';
import { StockAdjustmentComponent } from './stock-adjustment/stock-adjustment.component';
import { ShortageAdjustmentComponent } from './shortage-adjustment/shortage-adjustment.component';
import { BlockArticleComponent } from './block-article/block-article.component';
import { UnblockArticleComponent } from './unblock-article/unblock-article.component';
import { GstMasterComponent } from './gst-master/gst-master.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ImportArticleUploadComponent } from './import-article-upload/import-article-upload.component';
// import { RdsReportComponent } from './rds-report/rds-report.component';
import { ViewRdsStatusComponent } from './view-rds-status/view-rds-status.component';
import {RDSBatchesComponent} from './batches/batches.component';
import {SearchBatchStatusComponent} from './rds-batch-status/search-batch-status.component';
import {CreateRoleScreenAccessComponent} from './create_role-screen-access/create_role-screen-access.component'; 
import {SearchRoleScreenAccessComponent} from './search_role-screen-access/search_role-screen-access.component';
import { AcknowledgementUploadComponent } from './acknowledgement-upload/acknowledgement-upload.component';
import { ViewGrnComponent } from './search-grn/view-grn/view-grn.component';
import { FinanceScratchingUploadComponent } from './finance-scratching-upload/finance-scratching-upload.component';
import { NilStockUploadComponent } from './nil-stock-upload/nil-stock-upload.component';
import { PendingNilStockComponent } from './pending-nil-stock/pending-nil-stock.component';
import { from } from 'rxjs';
import { AbsoluteValPipe } from './absolute-val.pipe';
import { FinanceScratchingViewComponent } from './finance-scratching-view/finance-scratching-view.component';
import { PendingShortageAdjustmentComponent } from './pending-shortage-adjustment/pending-shortage-adjustment.component';
import { SearchCreditNoteComponent } from './search-credit-note/search-credit-note.component';
import { StockAdjustmentUploadComponent } from './stock-adjustment-upload/stock-adjustment-upload.component';
import { RouteMasterComponent } from './route-master/route-master.component';
import { ManualGrnComponent } from './asn-search/manual-grn/manual-grn.component';
import { FreightMasterComponent } from './freight-master/freight-master.component';
import { RdcMaintenanceComponent } from './rdc-maintenance/rdc-maintenance.component';
import { CreateRdcMaintenanceComponent } from './create-rdc-maintenance/create-rdc-maintenance.component';
import { SearchInventoryBifurcationComponent } from './search-inventory-bifurcation/search-inventory-bifurcation.component';
import { CreateInventoryBifurcationComponent } from './create-inventory-bifurcation/create-inventory-bifurcation.component';
import { FreightDetailComponent } from './freight-master/freight-detail/freight-detail.component';
import { ClaimSlipsComponent } from './claim-slips/claim-slips.component';
import { ReprocessJobComponent } from './reprocess-job/reprocess-job.component';
import { FreightAdditionalUploadComponent } from './freight-report-additional-info/freight-report-additional-info.component';
import { RdsReportASNComponent } from './rds-report-asn/rds-report-asn.component';
import { RdsReportGRNComponent } from './rds-report-grn/rds-report-grn.component';
import { RdsReportINVOICEComponent } from './rds-report-invoice/rds-report-invoice.component';
import { RdsReportGSTComponent } from './rds-report-gst/rds-report-gst.component';
import { RdsReportACCOUNTINGComponent } from './rds-report-accounting/rds-report-accounting.component';
import { RdsReportSTOCKComponent } from './rds-report-stock/rds-report-stock.component';
import { RdsReportFREIGHTComponent } from './rds-report-freight/rds-report-freight.component';
import { RdsReportNILSTOCKComponent } from './rds-report-nilstock/rds-report-nilstock.component';
import { RdsReportSTOCKADJComponent } from './rds-report-stockadj/rds-report-stockadj.component';
import { RdsReportSHORTAGEComponent } from './rds-report-shortage/rds-report-shortage.component';
import { RdsReportRESUPPLYComponent } from './rds-report-resupply/rds-report-resupply.component';
import { RdsReportAGEANALYSISComponent } from './rds-report-ageanalysis/rds-report-ageanalysis.component';
import { RdsReportOthersComponent } from './rds-report-others/rds-report-others.component';
import { UpdateInvoiceComponent } from './search-invoice/update-invoice/update-invoice.component';
import { ResupplyDetailComponent } from './re-supply-status/resupply-detail/resupply-detail.component';
import { MdmReportComponent } from './mdm-report/mdm-report.component';
import { ViewMdmStatusComponent } from './view-mdm-status/view-mdm-status.component';
import {EwayAdditionalUploadComponent} from './e-way-additional-upload/e-way-additional-upload.component';
import { ImportGstr21Component } from './import-gstr21/import-gstr21.component';
import { UploadDownloadCompComponent } from './upload-download-comp/upload-download-comp.component';
import { UpdateGatePassComponent } from './update-gate-pass/update-gate-pass.component';
import { DefectiveArticlesComponent } from './defective-articles/defective-articles.component';



@NgModule({
  declarations: [
    SearchGinComponent,
    SearchGrnComponent,
    CycleCountComponent,
    // GenerateGinComponent,
    // GinDetailsComponent,
    SearchAsnListComponent,
    ResupplyComponent,
    SearchInvoiceComponent,
    ViewInvoiceComponent,
    AsnUploadComponent,
    ResupplyUploadComponent,
    AsnDetailComponent,
    BarcodeScanComponent,
    BarcodeScanDispatchComponent,
    AsnUpdateComponent,
    SearchInventoryComponent,
    StockAdjustmentComponent,
    ShortageAdjustmentComponent,
    BlockArticleComponent,
    UnblockArticleComponent,
    GstMasterComponent,
    InvoiceComponent,
    ImportArticleUploadComponent,
    // RdsReportComponent,
    ViewRdsStatusComponent,
    RDSBatchesComponent,
    SearchBatchStatusComponent,
    CreateRoleScreenAccessComponent,
    SearchRoleScreenAccessComponent,
    AcknowledgementUploadComponent,
    ViewGrnComponent,
    FinanceScratchingUploadComponent,
    NilStockUploadComponent,
    PendingNilStockComponent,
    AbsoluteValPipe,
    FinanceScratchingViewComponent,
    PendingShortageAdjustmentComponent,
    SearchCreditNoteComponent,
    StockAdjustmentUploadComponent,
    RouteMasterComponent,
    ManualGrnComponent,
    FreightMasterComponent,
    RdcMaintenanceComponent,
    CreateRdcMaintenanceComponent,
    SearchInventoryBifurcationComponent,
    CreateInventoryBifurcationComponent,
    FreightDetailComponent,
    ClaimSlipsComponent,
    ReprocessJobComponent,
    FreightAdditionalUploadComponent,
    RdsReportASNComponent,
    RdsReportGRNComponent,
    RdsReportINVOICEComponent,
    RdsReportGSTComponent,
    RdsReportACCOUNTINGComponent,
    RdsReportSTOCKComponent,
    RdsReportFREIGHTComponent,
    RdsReportNILSTOCKComponent,
    RdsReportSTOCKADJComponent,
    RdsReportSHORTAGEComponent,
    RdsReportRESUPPLYComponent,
    RdsReportAGEANALYSISComponent,
    RdsReportOthersComponent,
    UpdateInvoiceComponent,
    ResupplyDetailComponent,
    MdmReportComponent,
    ViewMdmStatusComponent,
    EwayAdditionalUploadComponent,
    ImportGstr21Component,
    UploadDownloadCompComponent,
    UpdateGatePassComponent,
    DefectiveArticlesComponent
  ],
  imports: [
    CommonModule,
    RdsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
     CalendarModule,
    TabModule,
    // AngularFontAwesomeModule,
    // BrowserAnimationsModule,
     // DatePipe,
    OrderModule,
    TabViewModule
  ],

  exports: [
    UploadDownloadCompComponent
  ],

  providers: [
  ],

})
export class RdsModule { }
