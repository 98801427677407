import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReportHeader } from './report-header';
import { RdsReportStockService } from './rds-report-stock.service';
import { ReportGeneration } from '@/common/report-generation';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ReportSearch } from './report-header-search';
import { Concept } from '../../common/concept';
import { ReportNames } from '../../common/reports-names';
import { OrderType } from '../../common/orderType';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rds-report-stock',
  templateUrl: './rds-report-stock.component.html',
  styleUrls: ['./rds-report-stock.component.css']
})
export class RdsReportSTOCKComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myform', { static: false }) myForm: ElementRef;
  enable = false;
  shopEnable = true;
  regionEnable = true;
  distEnable = true;
  destinationEnable = false;
  regions = [{ name: 'North (N)', value: 'N' },
  { name: 'South (S)', value: 'S' },
  { name: 'East (E)', value: 'E' },
  { name: 'West (W)', value: 'W' }];
  articleType = [{name:'Fresh',value:'FRESH'},
                 {name:'Defective',value:'DEFECTIVE'},
                 {name:'Claimed',value:'CLAIMED'}];
  reportModel = new ReportHeader();
  fortNightBataYearWeek: string;
  requestParameterModel: RequestParameterModel[];
  searchParameterModel = new ReportSearch();
  searchParameterModelOutward = new ReportSearch();
  searchParameterModelEstimate = new ReportSearch();
  concepts: Concept[];
  conceptswithAll: any[] = [];
  orderTypeWithAll: any[] = [];
  conceptsForRetail: any[] = [];
  reportNames: ReportNames[];
  // loading:boolean=false;
  username: string;
  orderTypes: OrderType[] = [];
  orderTypesList: any[] = [];
  coCode: any;
  responseMessage: string;
  sucessMeg1: string;
  suppNoLength: boolean = false;
  asyncSuccess: string = 'Failed';
  loginUserEmail: string;
  generatebutton: string = 'enable';
  sucessMeg: string;
  showDiv = {
    Daily: false,
    Week: false,
    Fortnightly: false,
    Monthly: false
  }
  showDiv1 = {
    Factory: false,
    Vendor: false,
  }
  showReportList = {
    Reportname: false
  }
  fromWeek: string;
  errorMessage: string;
  payloadBataWeek = {};
  selectedValue = 'M';
  fromDate: string;
  arr = ['fromDate', 'toDate'];
  selectedVal: String;
  date: Date;
  toDate: string;
  ShopCodes: any[] = [];
  DistrictCodes: any[] = [];
  names: any[];
  onlyPerfix: any[] = [];
  uniquerper: any[] = [];
  disableReportList = true;
  selectedPrefix: string;
  selectedList: any[] = [];
  uniqueDist: any[] = [];
  obj = {
    S_ST: 'Either "RDC Code" / "Region" is REQUIRED.',
    SR_TCRW: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    AGED_STK: 'Either "RDC Code" / "Region" is REQUIRED.',
    WEEKLY_AGED_STK: 'Either "RDC Code" / "Region" is REQUIRED.',
    INV_PV_VAR:  'Either "RDC Code" / "District Code" / "Region" is REQUIRED.'
  };
  asOnDate: string;
  currentDateSystem: Date;

  constructor(private _router: Router, private _generateReportService: RdsReportStockService,
    private _utilsService: UtilsService, private datePipe: DatePipe) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    this.reportModel.reportFormatType = 'xlsx';

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.loginUserEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        this.reportNames = data;
        this.reportNames.forEach(element => {
          let reportPrefixOnly: {} = element.reportName.split("_");
          let rsp_prf = {
            "perfix": reportPrefixOnly[0],
            "reportName": reportPrefixOnly[1],
            "reportKey": element.reportKey
          };
          this.onlyPerfix.push(rsp_prf);
        });
        this.uniquerper = [...new Set(this.onlyPerfix.map(x => x.perfix))];
        this.onSelectPerfix("STOCK");
      }
    )

    this._generateReportService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        this.concepts.forEach(
          concept => {
            this.conceptswithAll.push(concept);
            if (concept.division == 'RETAIL' && concept.concCode != 'N') {
              this.conceptsForRetail.push(concept);
            }
          }
        );
        let conc = new Concept();
        conc.concCode = 'ALL';
        conc.concept = 'ALL';
        conc.description = 'ALL CONCEPT';
        this.conceptswithAll.unshift(conc);
        //this.searchParameterModel.concept = 'ALL';
      }
    )
  }
  clearErrorMsg() {
    this.errorMessage = '';
    this.responseMessage = '';
  }

  fetchCurrentDate() {
    this.clearErrorMsg();
    this.currentDateSystem = new Date();
    this.asOnDate = this._utilsService.transformDateSearch(this.currentDateSystem);
  }

  onSelectConcept(value) {
    this.clearErrorMsg();
    this.loaDer.nativeElement.style.display = 'block';
    this.ShopCodes = [];
    // this.DistrictCodes = [];
    this.uniqueDist = [];
    let divisionCode: string;
    this.searchParameterModel.concept = undefined;
    this.concepts.filter(cs => cs.concCode === this.searchParameterModel.conceptCode).forEach(
      div => {
        if (value != 'ALL') {
          this.searchParameterModel.concept = (div.concCode + '-' + div.description);
        }
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
      }
    )
    let detail: any;
    if (value != 'ALL') {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': divisionCode,
        'userCode': this.username,
        'conceptCode': this.searchParameterModel.conceptCode
      }
    }
    else {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': '5',
        'userCode': this.username
      }
    }
    const requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.distEnable = false;
      this.regionEnable = false;
      this._generateReportService.getLocations(requestParameterModel).subscribe(
        (res: any) => {
          this.ShopCodes = [];
          // this.DistrictCodes = [];
          this.uniqueDist = [];
          if (res.dataList != null) {
            res.dataList.forEach(
              data => {
                this.ShopCodes.push(data);
                // this.DistrictCodes.push(data);
                this.uniqueDist.push(data);
                this.searchParameterModel.regionCode = undefined;
                this.DistrictCodes = [...new Set(this.uniqueDist.map(x => x.districtNumber))];
              }
            );
            // const details =
            // {
            //   "shopNumber": "ALL",
            //   "shopName": "All RDC CODE"
            // };
            // this.ShopCodes.unshift(details);
          }
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    else {
      this.shopEnable = true;
      this.distEnable = true;
      this.regionEnable = true;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  public async OnClickGenerate(myForm): Promise<void> {
    this.responseMessage = '';
    this.errorMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.loaDer.nativeElement.style.display = 'block';
    let switchFlag: any;
    let reportKey: any;
    let reportName: any;
    this.sucessMeg = 'Success';
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        switchFlag = report.switchingFlag;
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    let userDetails = {
      "name": "userId",
      "value": this.username
    }
    this.requestParameterModel.push(userDetails);
    console.log('requesting parameters inside updatedDatalist' + JSON.stringify(this.requestParameterModel));
    let details = new ReportGeneration();
    if (switchFlag == 'N') {
      if(this.searchParameterModel.conceptCode != null){
      console.log('Report will get generated online');
      details.reportFormatType = this.reportModel.reportFormatType;
      details.reportInputRequestList = this.requestParameterModel;
      this.callingServiceWithoutBlob(details);
      this.loaDer.nativeElement.style.display = 'none';
    } else{
      this.errorMessage = 'Concept Code is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
    }
  }
    else {
      console.log('Report will get generated offline');
      let detail = {
        "name": "reportKey",
        "value": reportKey
      }
      let nameDetail = {
        "name": "reportName",
        "value": reportName
      }
      this.requestParameterModel.push(detail);
      this.requestParameterModel.push(nameDetail);
      console.log(JSON.stringify(this.requestParameterModel));
      this._generateReportService.offlineReportGeneration(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          if (response.status == 'success') {
            // this.responseMessage = response.message;
            this.asyncSuccess = 'success';
            this.responseMessage = "Your report will be generated soon, you will be notified over mail. You can check the progress in View Report Status.";
          }
          else {
            this.asyncSuccess = 'Failed';
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.asyncSuccess = 'Failed';
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
  }

  callingServiceWithoutBlob(details) {
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    let reportDetails =
    {
      "name": "reportName",
      "value": reportName
    }
    this.requestParameterModel.push(reportDetails);
    let reportKeySelected =
    {
      "name": "reportKey",
      "value": reportKey
    }
    this.requestParameterModel.push(reportKeySelected);
    this.loginUserEmail
    let userSelectedEmail =
    {
      "name": "userEmail",
      "value": this.loginUserEmail
    }
    this.requestParameterModel.push(userSelectedEmail);
    let r = new Map(Object.entries(this.obj));
    if (this.searchParameterModel.conceptCode === 'ALL') {
      let index = this.requestParameterModel.findIndex(cs => cs.name == 'conceptCode');
      this.requestParameterModel.splice(index, 1);
    }
    if (this.reportModel.reportKey === 'SR_TCRW') {
      let year = new Date().getFullYear();
      let valueOfWeekFrom =
      {
        "name": "weekFrom",
        "value": year + "01"
      }
      this.requestParameterModel.push(valueOfWeekFrom);
    }

    // if (this.reportModel.reportKey === 'WEEKLY_AGED_STK') {
    //   let year = new Date().getFullYear();

    //   let periodWeekFrom =
    //   {
    //     "name": "periodStartNo",
    //     "value": year + "01"
    //   }
    //   this.requestParameterModel.push(periodWeekFrom);
    // }
    details.reportInputRequestList = this.requestParameterModel;
    console.log(JSON.stringify(details));

    if (this.searchParameterModel.toShopCode != null || this.searchParameterModel.sourceRdc != null || this.searchParameterModel.toDistrictCode != null || this.searchParameterModel.districtNumber != null || this.searchParameterModel.fromRdcDistrictCode != null ||
      this.searchParameterModel.regionCode != null || this.searchParameterModel.shopCode != null || this.searchParameterModel.rdcNo != null
      || this.searchParameterModel.fromShopCode != null || this.searchParameterModel.rdcCode != null || this.searchParameterModel.RdcCode != null ||
      this.searchParameterModel.fromRdcCode != null || this.searchParameterModel.toRdcCode != null ||
      this.searchParameterModel.shopCityName != null || this.searchParameterModel.fromDistrictCode != null) {
      
        this.requestParameterModel.filter(cs => cs.name == 'yearWeek' && this.searchParameterModel.reportPeriodType == 'FORTNIGHT' && 
        this.reportModel.reportKey == 'S_ST').forEach(
          each => {
            each.value = this.fortNightBataYearWeek;
          }
        );

        if (this.reportModel.reportKey === 'AGED_STK' || this.reportModel.reportKey === 'INV_PV_VAR') {
          
          if (this.searchParameterModel.reportPeriodType != undefined) {
          if (this.showDiv.Week == true || this.showDiv.Fortnightly == true || (this.showDiv.Daily == true && this.searchParameterModel.toDate != undefined)) {
            this.callReportService(details);
          } else {
            this.errorMessage = "Daily Date is REQUIRED."
          } 
        } else {
          this.errorMessage = "Type is REQUIRED";
          this.loaDer.nativeElement.style.display = 'none';
        }
        } else if (this.reportModel.reportKey === 'WEEKLY_AGED_STK'){
          
          if((this.searchParameterModel.stockTypeCode != null && this.searchParameterModel.stockTypeDesc != null) || (this.searchParameterModel.stockTypeCode != undefined && this.searchParameterModel.stockTypeDesc != undefined)){
            if (this.searchParameterModel.reportPeriodType != undefined) {
              if (this.showDiv.Week == true || this.showDiv.Fortnightly == true || (this.showDiv.Daily == true && this.searchParameterModel.toDate != undefined)) {
                this.callReportService(details);
              } else {
                this.errorMessage = "Daily Date is REQUIRED."
              } 
            } else {
              this.errorMessage = "Type is REQUIRED";
              this.loaDer.nativeElement.style.display = 'none';
            }
          }else{
            this.errorMessage = "Stock Type is REQUIRED";
          }
        }else {
          this.callReportService(details);
        }
    } else {
      this.errorMessage = r.get(this.reportModel.reportKey);
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  callReportService(details) {
    this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.reportKey).subscribe(
      (response: any) => {
        if (response.status == 'Success') {
          this.asyncSuccess = 'success';
          this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          this.loaDer.nativeElement.style.display = 'none';
          this.generatebutton = 'disable';
          this.delay(5000).then(any => {
            this.generatebutton = 'enable';
          });
        }
        else {
          this.asyncSuccess = 'Failed';
          this.errorMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.asyncSuccess = 'Failed';
        this.errorMessage = err;
        this.loaDer.nativeElement.style.display = 'none';
      });
  }

  changeDate(value, key) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    let myDate = new Date(value);
    let dateOfYear1 = this._utilsService.transformDateSearchchange(myDate);
    if (key == 'fromDate') {
      this.searchParameterModel.gatePassStartDate = dateOfYear1;
    }
    else {
      this.searchParameterModel.gatePassEndDate = dateOfYear1;
    }
  }

  changeSelectedDate(todate, fromdate) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    // let deliveryToDate: any[] = todate.split('/');
    // let etDate = new Date(deliveryToDate[2], deliveryToDate[1], deliveryToDate[0]);
    this.requestParameterModel.filter(cs => cs.name == 'fromDate' || cs.name == 'toDate').forEach(
      each => {
        let deliveryFrmDate: any[] = each.value.split('/');
        let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1, deliveryFrmDate[0]);
        each.value = this._utilsService.transformDateSearchchange(stDate);
      }
    )
  }

  datecheck(fromDate, toDate) {
    this.clearErrorMsg();
    let status: boolean = false;
    if (fromDate != undefined || toDate != undefined) {
      if (fromDate != null && toDate != null) {
        console.log(fromDate);
        console.log(toDate);
        if ((new Date(fromDate).getTime() == new Date(toDate).getTime()) || (fromDate == toDate)) {
          console.log('equal');
          status = true;
        }
        else if ((new Date(fromDate).getTime() < new Date(toDate).getTime())) {
          console.log('greater');
          status = true;
        }
        else {
          console.log('smaller');
          status = false;
          this.errorMessage = "From Date should be smaller than To Date";
          this.loaDer.nativeElement.style.display = 'none';
        }
      }
      else {
        this.errorMessage = "Please select both dates";
        this.loaDer.nativeElement.style.display = 'none';
      }
    }
    else {
      status = true;
    }
    return status;
  }

  onClickReset() {
    this.fetchCurrentDate();
    this.searchParameterModel = new ReportSearch();
    console.log('reset report search details');
    //this.searchParameterModel.concept = 'ALL';
    this.responseMessage = ''; 
    this.errorMessage = "";
    this.sucessMeg1 = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.regionEnable = true;
    this.enable = false;
    this.shopEnable = true;
    this.distEnable = true;
    this.searchParameterModel.region = "";
    this.showReportList.Reportname = false;
    this.showDiv1.Factory = false;
    this.showDiv1.Vendor = false;
    this.showDiv.Daily = false;
    this.showDiv.Week = false;
    this.showDiv.Fortnightly = false;
    this.searchParameterModelOutward = new ReportSearch();
    this.searchParameterModelEstimate = new ReportSearch();
    if (this.reportModel.reportKey === 'OGPR') {
      this.searchParameterModel.copyType = 'Main';
    }
    if (this.reportModel.reportKey != 'S_ST' && this.reportModel.reportKey != 'INV_PV_VAR') {
      this.reportModel.reportFormatType = 'xlsx';
    }
    else {
      this.reportModel.reportFormatType = 'pdf';
    }
  }

  yearWeekValidations() {
    this.clearErrorMsg();
    //this.errorMessage = '';
    this.generatebutton = 'enable';
    let yearWeekTo: any;
    if (this.reportModel.reportKey === 'WEEKLY_AGED_STK') {
      yearWeekTo = this.searchParameterModel.periodEndNo;
    }
    else if (this.reportModel.reportKey === 'S_ST' || this.reportModel.reportKey === 'INV_PV_VAR') {
      yearWeekTo = this.searchParameterModel.yearWeek;
    } else if (this.reportModel.reportKey === 'SR_TCRW') {
      yearWeekTo = this.searchParameterModel.weekTo;
      this.searchParameterModel.reportPeriodType = 'WEEK';
    } else {
      yearWeekTo = this.searchParameterModel.weekTo;
    }
    let week: string = yearWeekTo.toString().substr(4, 6);
    let yearNum = parseInt(yearWeekTo.toString().substr(0,4));
    console.log(week);
    if (yearWeekTo.length >= 4) {
      if(!((yearNum>=2021) && (yearNum <= new Date().getFullYear()))){
      // if (yearWeekTo.toString().substr(0, 4) != new Date().getFullYear().toString()) {
        this.errorMessage = 'Year must be a current or previous year.';
        this.generatebutton = 'disable';
      }
      else if (week.length == 2 && (week != '' && week != undefined) && (((Number(week) < 1 || Number(week) > 53) && this.searchParameterModel.reportPeriodType == 'WEEK')
        || ((Number(week) < 1 || Number(week) > 26) && this.searchParameterModel.reportPeriodType == 'FORTNIGHT'))) {
        if (this.searchParameterModel.reportPeriodType == 'WEEK') {
          this.errorMessage = 'Week should be in between 01 and 53';
        } else {
          this.errorMessage = 'Fortnight should be in between 01 and 26';
        }

        this.generatebutton = 'disable';
        console.log('Check success');
      }
      else {
        console.log('Validation Success');
        this.generatebutton = 'enable';
        if ((this.reportModel.reportKey != 'SR_TCRW') && yearWeekTo.length == 6) {
          this.onBlurBataWeekTo(yearWeekTo, this.searchParameterModel.reportPeriodType, 'TO');
          if((this.reportModel.reportKey == 'INV_PV_VAR') && yearWeekTo.length == 6){
            this.onBlurBataWeek(yearWeekTo, this.searchParameterModel.reportPeriodType, 'FROM');
          }
          // if(this.reportModel.reportKey != 'AGED_STK'){
          // this.onBlurBataWeek(new Date().getFullYear()+'01',this.searchParameterModel.reportPeriodType,'FROM');
          // }
        }
      }
    }
  }

  onBlurVendorCode(value) {
    this.clearErrorMsg();
    if (value != '') {
      this.regionEnable = true;
      //this.searchParameterModel.region = 'All';
    }
    else {
      this.regionEnable = false;
    }
    this._generateReportService.getVendorList(value).subscribe(
      (response: any) => {
        console.log(response.dataList);
        if (response.status == "Success") {
          this.searchParameterModel.vendorName = response.dataList[0].partyShortName;
        }
        else {
          this.searchParameterModel.vendorName = "";
        }
      });
  }
  setData() {
    this.fetchCurrentDate();
    this.reportModel.reportFormatType = 'xlsx';
    console.log('removed');
    this.responseMessage = '';
    this.sucessMeg1 = '';
    this.errorMessage = "";
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    this.onClickReset();
    this.showDiv.Week = false;
    this.showDiv.Monthly = false;
    this.showDiv.Fortnightly = false;
    this.showDiv.Daily = false;
    //this.searchParameterModel.concept = 'ALL';
    // if (this.reportModel.reportKey === 'OGPR') {
    //   this.searchParameterModel.copyType = 'Main';
    // }
    //this.searchParameterModelOutward = new ReportSearch();
    if (this.reportModel.reportKey != 'S_ST' && this.reportModel.reportKey != 'INV_PV_VAR') {
      this.reportModel.reportFormatType = 'xlsx';
    }
    else {
      this.reportModel.reportFormatType = 'pdf';
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  regionChangeShopCode(regionVal) {
    this.clearErrorMsg();
    if (regionVal != '0: undefined') {
      this.shopEnable = true;
      this.distEnable = true;
    }
    else {
      this.shopEnable = false;
      this.distEnable = false;
    }
  }

  onBlurDispatchCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '') {
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.dispatchName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.dispatchName = "";
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }
  onBlurReceivingCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '') {
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.recievingFromName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.recievingFromName = "";
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }

  changeDistrictCode(distValue) {
    this.clearErrorMsg();
    if (distValue != '0: undefined') {
      this.regionEnable = true;
      this.shopEnable = true;
    }
    else {
      this.regionEnable = false;
      this.shopEnable = false;
    }
  }

  onBlurFromShopCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '') {
      this.regionEnable = true;
      this.distEnable = true;
      //this.searchParameterModel.region = 'All';
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.fromShopName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.fromShopName = "";
          }
        });
    }
    else {
      this.errorMessage = "";
      this.regionEnable = false;
      this.distEnable = false;
    }
  }

  destinationCodeinputValidation(value) {
    console.log(value);
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '' && this.searchParameterModel.destination == "F") {
      this._generateReportService.getFactoryName(value).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.destinationName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid factory Code";
          }
        });
    }
    else if (value != '' && this.searchParameterModel.destination == "V") {
      this._generateReportService.getVendorList(value).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "Success") {
            this.errorMessage = "";
            this.searchParameterModel.destinationName = response.dataList[0].partyShortName;
          }
          else {
            this.errorMessage = "Invalid vendor Code";
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }

  onBlurCode(value) {
    this.clearErrorMsg();
    if (value != '0: undefined') {
      this.regionEnable = true;
      this.distEnable = true;
    }
    else {
      this.regionEnable = false;
      this.distEnable = false;
    }
  }

  onBlurToShopCode(value) {
    //this.errorMessage = "";
    this.clearErrorMsg();
    if (value != '') {
      this.errorMessage = "";
      this.regionEnable = true;
      this.distEnable = true;
      //this.searchParameterModel.region = 'All';
      this._generateReportService.getShopName(value).subscribe(
        (response: any) => {
          if (response.status == "Success.") {
            this.errorMessage = "";
            this.searchParameterModel.toShopName = response.dataList[0].shopName;
          }
          else {
            this.errorMessage = "Invalid Shop Name";
            this.searchParameterModel.toShopName = "";
          }
        });
    }
    else {
      this.errorMessage = "";
      this.regionEnable = false;
      this.distEnable = false;
    }
  }

  onVendorCode(value) {
    this.clearErrorMsg();
    //this.errorMessage = "";
    if (value != '') {
      this.errorMessage = "";
      this._generateReportService.getVendorList(value).subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "Success") {
            this.errorMessage = "";
            this.searchParameterModel.vendorName = response.dataList[0].partyShortName;
          }
          else {
            this.errorMessage = "Invalid Vendor Code";
            this.searchParameterModel.vendorName = "";
          }
        });
    }
    else {
      this.errorMessage = "";
    }
  }

  destinationchange(value) {
    console.log(value);
    this.clearErrorMsg();
    if (value == '') {
      this.destinationEnable = true;
    }
    else {
      this.destinationEnable = false;
      this.searchParameterModel.destination = value;
    }
  }
  //  selectedDurationtype(event:any) {
  selectedDurationtype(event: any): void {
    console.log(event);
    this.clearErrorMsg();
    this.selectedVal = event;
    this.searchParameterModel.periodEndNo = undefined;
    this.searchParameterModel.toDate = undefined;
    this.searchParameterModel.weekTo = undefined;
    this.searchParameterModel.yearWeek = undefined;
    console.log(this.searchParameterModel.reportPeriodType);
    console.log(this.searchParameterModel.yearWeek);
    // if (this.searchParameterModel.reportPeriodType != undefined) {
    // this.searchParameterModel = new ReportSearch();
    //}
    this.sucessMeg1 = '';
    this.responseMessage = '';
    if (this.selectedVal == 'DAILY') {
      this.showDiv.Daily = true;
      this.showDiv.Week = false; this.showDiv.Monthly = false; this.showDiv.Fortnightly = false;
      //this.currentDate();
    }
    else if (this.selectedVal == 'WEEK') {
      this.showDiv.Week = true;
      this.showDiv.Fortnightly = false; this.showDiv.Monthly = false; this.showDiv.Daily = false;
    }
    else if (this.selectedVal == 'FORTNIGHT') {
      this.showDiv.Fortnightly = true;
      this.showDiv.Week = false; this.showDiv.Monthly = false; this.showDiv.Daily = false;
    }
    else if (this.selectedVal == 'MONTH') {
      this.showDiv.Monthly = true;
      this.showDiv.Fortnightly = false; this.showDiv.Week = false; this.showDiv.Daily = false;
    }
    else {
      this.showDiv.Fortnightly = false; this.showDiv.Week = false; this.showDiv.Daily = false; this.showDiv.Monthly = false;
    }
  }

  optionSelect(event: any): void {
    this.clearErrorMsg();
    this.selectedVal = event;
    if (this.searchParameterModel.reportPeriodType != undefined) {
      this.searchParameterModel = new ReportSearch();
    }
    this.sucessMeg1 = '';
    this.responseMessage = '';
    if (this.selectedVal == 'X' || this.selectedVal == 'F') {
      this.showDiv1.Factory = true;
      this.showDiv1.Vendor = false;
    }
    else if (this.selectedVal == 'V') {
      this.showDiv1.Vendor = true;
      this.showDiv1.Factory = false;
    }
    else {
      this.showDiv1.Factory = false; this.showDiv1.Vendor = false;
    }
  }

  convertBataWeek(weekValue) {
    this.clearErrorMsg();
    let bataNumber = weekValue.substring(4, 6);
    let bataYear = weekValue.substring(0, 4);
    this.payloadBataWeek = {
      "bataYear": bataYear,
      "bataNumber": bataNumber,
      "searchFlag": "WEEK",
      "fromToFlag": "TO"
    }
    this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.message == "Success") {
          const toDateConvert = this._utilsService.transformDateTo(response.date);
          this.searchParameterModel.weekEndDate = toDateConvert;
        }
      });
    let year = new Date().getFullYear();
    this.payloadBataWeek = {
      // "bataYear": year,
      "bataYear": "2020",
      "bataNumber": "01",
      "searchFlag": "WEEK",
      "fromToFlag": "FROM"
    }
    this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.message == "Success") {
          const toDateConvert = this._utilsService.transformDateTo(response.date);
          this.searchParameterModel.weekStartDate = toDateConvert;
        }
      });
  }

  onBlurBataWeek(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    if (bataWeekVal.length == 6) {
      let bataNumber = bataWeekVal.substring(4, 6);
      let bataYear = bataWeekVal.substring(0, 4);
      console.log(bataNumber);
      console.log(bataYear);
      this.payloadBataWeek = {
        "bataYear": bataYear,
        "bataNumber": bataNumber,
        "searchFlag": searchFlag,
        "fromToFlag": fromToFlag
      }
      this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
        (response: any) => {
          if (response.message == "Success") {
            const fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
            this.searchParameterModel.fromDate = fromDateDaily1;
            // let deliveryFrmDate: any[] = fromDateDaily1.split('/');
            // let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1, deliveryFrmDate[0]);
            // this.searchParameterModel.fromDate = this._utilsService.transformDateSearch(stDate);
          }
        });
    }
  }

  onSelectPerfix(event) {
    // if(event != undefined){
    //  this.showReportList.Reportname = true;
    // }
    console.log(event);
    this.clearErrorMsg();
    this.selectedList = [];
    this.onlyPerfix.filter(cs => cs.perfix === event).forEach(
      reportkN => {
        this.selectedList.push(reportkN);

      }
    )
    console.log(this.selectedList);

  }

  onBlurBataWeekTo(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    let bataNumber = bataWeekVal.substring(4, 6);
    let bataYear = bataWeekVal.substring(0, 4);
    console.log(bataNumber);
    this.payloadBataWeek = {
      "bataYear": bataYear,
      "bataNumber": bataNumber,
      "searchFlag": searchFlag,
      "fromToFlag": fromToFlag
    }
    this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
      (response: any) => {
        if (response.message == "Success") {
          let fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
          this.searchParameterModel.toDate = fromDateDaily1;
          let deliveryEndDate: any[] = fromDateDaily1.split('/');
          let stDate = new Date(deliveryEndDate[2], deliveryEndDate[1] - 1, deliveryEndDate[0]);
          if (this.reportModel.reportKey == 'S_ST' && searchFlag == 'FORTNIGHT') {
            let changedDateStr = this.datePipe.transform(new Date(stDate), 'dd/MM/yyyy');
            this._generateReportService.getWeekAndYear(changedDateStr).subscribe(
              (res: any) => {
                let week: string = res.dataList[0].bataWeek.toString();
                if (week.length == 1) {
                  week = '0' + week;
                }
                let weekNo = res.dataList[0].bataYear.toString() + week;
                this.fortNightBataYearWeek = weekNo;
                console.log('check Fortnight yearweek' + this.searchParameterModel.yearWeek);
              }
            )
          } else if(this.reportModel.reportKey != 'INV_PV_VAR'){
            this.searchParameterModel.toDate = this._utilsService.transformDateSearchchange(stDate).toUpperCase();
          }else {
            console.log("success");
          }
        }
      });
  }

  // validDestination(validDes) {
  // }
  
  stockChange(val){
    this.errorMessage = "";
    console.log(val);
    // if(val == this.articleType[0].name){
    //   this.searchParameterModel.stockTypeCode = this.articleType[0].value;
    // }else if(val == this.articleType[1].name){
    //   this.searchParameterModel.stockTypeCode = this.articleType[1].value;
    //   console.log(this.searchParameterModel.stockTypeCode);
    // }else{
    //   this.searchParameterModel.stockTypeCode = this.articleType[2].value;
    // }
    let value = val.split(':')[1].trim();
    this.articleType.filter(cs => cs.name == value).forEach(
      each => {
        this.searchParameterModel.stockTypeCode = each.value;
      }
    );
    console.log(this.searchParameterModel.stockTypeCode);
  }

  currentDate() {
    this.clearErrorMsg();
    this.date = new Date();
    this.searchParameterModel.toDate = this._utilsService.transformDate1(this.date);
    this.searchParameterModel.fromDate = this._utilsService.transformDate1(this.date);
    console.log(this.toDate);
  }

  diableSection() {
    this.clearErrorMsg();
    this.showDiv.Week = false;
    this.showDiv.Monthly = false;
    this.showDiv.Fortnightly = false;
    this.showDiv.Daily = false;
    this.responseMessage = '';
    this.sucessMeg1 = '';
    this.errorMessage = "";
  }

  disableCurrentDate() {
    this.clearErrorMsg();
    this.searchParameterModel.fromDate = "";
    this.searchParameterModel.toDate = "";
    this.searchParameterModel.periodEndNo = "";
    this.searchParameterModel.periodStartNo = "";
  }

  invoiceChangeDate(value) {
    this.clearErrorMsg();
    this.searchParameterModel.toDate = undefined;
    this.searchParameterModel.fromDate = undefined;
    this.searchParameterModel.weekFrom = undefined;
    let myDate = new Date(value);
    let dateOfYear1 = this._utilsService.transformDateSearchchange(myDate).toUpperCase();
    this.searchParameterModel.toDate = dateOfYear1;
  }
}

