import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UtilsService } from '../../common/utils-service';
import { Router, RouterModule } from '@angular/router';
import { ReportNames } from '../../common/reports-names';
import { ReportHeader } from '../mdm-report/report-header';
import { MdmReportService } from '../mdm-report/mdm-report.service';
import { ViewMdmStatusService } from './view-mdm-status.service';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-view-mdm-status',
  templateUrl: './view-mdm-status.component.html',
  styleUrls: ['./view-mdm-status.component.css']
})
export class ViewMdmStatusComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  viewReportStatusForm: FormGroup;
  details: any;
  responseMessage: string;
  reportNames:ReportNames[];
  reportModel = new ReportHeader();
  userRole: string;
  username: string;
  reportDownloadName:any;

  constructor(private fb: FormBuilder,private _router: Router,
    private _generateReportService: MdmReportService,
    private _viewReportService: ViewMdmStatusService, private httpClient: HttpClient, private _utilsService: UtilsService) {
    this.viewReportStatusForm = this.buildForm();
  }

  buildForm() {
    return this.fb.group({
      reportName : ['']
     });
  }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 1000);
    let sessUsername = sessionStorage.getItem('currentUser');
    this.userRole=JSON.parse(sessUsername).groupAssignmentModels[0].groupName.split(',');
    this.username =  JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    console.log(sessUsername);
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        console.log(data);
        this.reportNames = data;
        this.viewReportStatusForm.controls.reportName.setValue("");
      }
    );
  }

  fetchDetailsOnSubmit() {
    this.responseMessage = '';
    this.details = '';
    this.loaDer.nativeElement.style.display = 'block';
    let reportName = this.viewReportStatusForm.controls.reportName.value;
    this.reportNames.filter(cs => cs.reportKey == reportName).forEach(
    report => {
      this.reportDownloadName = report.reportName;
    }
    );
    let param = [
      {
        "name": "reportKey",
        "value": reportName
      },
      {
        "name": "invokedBy",
        "value": this.username
      }
    ];
   
    this._viewReportService.getReportData(param).subscribe(
      (response: any) => {
        console.log(response);
        if (response.dataList != null && response.dataList.length > 0) {
          this.details = response.dataList;
          this.details.sort((a, b) => {
            return <any>new Date(b.reportRunTime) - <any>new Date(a.reportRunTime);
          });
          this.details.forEach(
            batch => {
              if (batch.reportUrl != null) {
                batch.startingUrl = batch.reportUrl.substr(0,8);
              }
              let RunDate: any;
              RunDate = new Date(batch.reportRunTime);
              batch.reportRunTime = this._utilsService.getCurrentDateTimeFormat(RunDate);
            }
          );
        }
        else {
          this.responseMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (error) => {
        this.responseMessage = error;
        this.loaDer.nativeElement.style.display = 'none';
      }
    );
  }

  public async downloadReport(reportDownloadUrl,reportname,filename): Promise<void> {
    this.loaDer.nativeElement.style.display = 'block';
    const file = await this.httpClient.get<Blob>(reportDownloadUrl, { responseType: 'blob' as 'json' }).toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    if(reportname != 'Retail Order Onway'){
      anchor.download = reportname + '_' + this._utilsService.getCurrentFormatDate() + '.' + 'xlsx';
    }
    else{
    let updatedFilename = filename.toString().substr(14,14);
    let fileYRWK = updatedFilename.toString().substr(0,6);
      anchor.download = 'RTLORDER_ONWAY_' + fileYRWK + '.' + 'xlsx';
    }
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    this.loaDer.nativeElement.style.display = 'none';
  }
}