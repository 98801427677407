export class BarcodeScanAssort {
    
    pairQty:string;
    size:string;
    sizeBucket:string;
    artNumber:string;
    manufacturingDate:Date;
    planNumber:string;
    productionUnit:string;
    
    manDateStr:string;

    
   constructor(){}
}