import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { Observable, of, throwError, from } from 'rxjs';
import { ListOfBataWeek } from './list-of-bata-week';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RdsReportGstService {

  URL: string = '';
  reportsDetails: any = [
    { key: 'GSTR18_IT', url: BilApiUrl.GSTR_1_8},
    { key: 'GSTR19_HWSO', url: BilApiUrl.GSTR_1_9_HSN_WISE},
    { key: 'GSTR29_IHS', url: BilApiUrl.GSTR_2_9_INWARD_HSN},
    { key: 'GSTR21', url: BilApiUrl.GSTR_2_1},
    { key: 'GSTR21_IMPORT', url: BilApiUrl.GSTR_2_1_IMPORT},
    { key: 'GRRM_BC', url: BilApiUrl.GST_RECONCILIATION_REPORT},
    { key: 'GSTR_SC', url: BilApiUrl.GSTR_STOCK_CORRECTION_REPORT},
    { key: 'GSTR_S', url: BilApiUrl.GSTR_SHORTAGE},
    { key: 'GSTR11', url: BilApiUrl.GSTR_1_1},
    { key: 'GSTR1_RETURN', url: BilApiUrl.GSTR_RETURN_MONTHLY_WISE},
    { key: "ICCNR", url: BilApiUrl.INVOICE_CANCELLATION_REPORT},
    { key: 'EWAY_BILL', url: BilApiUrl.GSTR_E_WAY_BILL},
    { key: 'GSTR_NV', url: BilApiUrl.GSTR_NIL_VALUE}
  ];
  
  concept_url: string = BilApiUrl.CONCEPT_LIST_URL;
   reports_url: string = BilApiUrl.REPORT_NAMES;
   offline_reports_url: string = BilApiUrl.OFFLINE_REPORT_URL;
   shop_name_url: any = BilApiUrl.OUTWARD_GATE_PASS_SHOP_NAME;
   receipt_Summary_Url: any = BilApiUrl.WEEK_RECEIPT_SUMMARY;
   vendor_list_url: any = BilApiUrl.GENERATE_PO_OTHER_URL; 
   order_type_url = 'assets/static-data/orderTypes.json';
   shop_location_url = RdsBilApiUrl.SEARCH_LOCATION_URL;
   city_name_url = RdsBilApiUrl.RDC_CITY_NAME_URL;
   rdc_no_from_city_url = RdsBilApiUrl.RDC_NO_FROM_CITY_URL;
   bataWeekURL: string = RdsBilApiUrl.RDC_BATA_WEEK_LIST;
  constructor(private _http: HttpClient) { }

  public async getReportDetails(details, reportKey): Promise<Blob> {
    console.log('sending parameters' + JSON.stringify(details));
    this.reportsDetails.forEach(element => {
      // tslint:disable-next-line: triple-equals
      if (reportKey == element.key) {
        console.log('element key' + element.key + 'element url' + element.url);
        this.URL = element.url;
      }
    });
    const file = await this._http.post<Blob>(this.URL, details, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }
  getRdcCityName(detail) {
    return this._http.post(this.city_name_url, detail);
  }
  getRdcNoByCity(detail) {
    return this._http.post(this.rdc_no_from_city_url, detail);
  }
  getConcepts(conceptQueryMas) {
    return this._http.post(this.concept_url, conceptQueryMas);
  }
  getLocations(requestParameterModel) {
    return this._http.post(this.shop_location_url, requestParameterModel);
  }
  getReports() {
    const reportParameter = [
      {
      name: 'reportModule',
      value: 'RDS'
      }
    ];
    return this._http.post(this.reports_url, reportParameter);
  }
  getOrderTypes() {
    return this._http.get(this.order_type_url);
  }
  offlineReportGeneration(reportParam) {
    return this._http.post(this.offline_reports_url, reportParam);
  }
  getReportDetailsWithoutBlob(details, reportKey) {
    let url : any = '';
   // this.URL = '';
    this.reportsDetails.forEach(element => {
      if (reportKey == element.key) {
        console.log('element key' + element.key + 'element url' + element.url);
        url = element.url;
      }
    });
    return this._http.post(url, details);
  }
  getShopName(shopCode){
    let listVariables = [{
      "name" : "shopNo",
      "value" : shopCode
    }];
  return this._http.post(this.shop_name_url, listVariables);
  }
  getFactoryName(shopCode){
    let listVariables = [
      {
      "name":"shopAgencyCode",
      "value":"0"},
      {
        "name":"shopNo",
        "value":shopCode
      },];
  return this._http.post(this.shop_name_url, listVariables);
  }
  getVendorList(vendorCode){
    let listVariables = [
      {
      "name":"partyNo",
      "value": vendorCode
      }
      ];
  return this._http.post(this.vendor_list_url, listVariables);
  }
  getRecepitSummary(searchlist){
    console.log(searchlist);
    console.log( 'element url' +this.receipt_Summary_Url); 
  return this._http.post(this.receipt_Summary_Url, searchlist);
  }
   getlistofbataWeeks() {
    return this._http.get(this.bataWeekURL)
    .pipe(
      tap(data => (data)),
      catchError(this.handleError)
    );

  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
}
}
