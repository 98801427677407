import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { RdsBilApiUrl } from "../../common/rds-bil-api-url";
import { BilApiUrl } from "../../common/bil-api-url";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: "root",
})

export class PendingShortageAdjustmentService {

  status_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  pending_shortage_adjustment_details_url: string = RdsBilApiUrl.SEARCH_DEBIT_CREDIT_TRANSACTION;
  pending_shortage_adjustment_approval_reject_url: string = RdsBilApiUrl.PERFORM_APPROVAL_OF_DEBIT_CREDIT_NOTE;
  pending_shortage_adjustment_approval_reject_url_claim: string = RdsBilApiUrl.PERFORM_APPROVAL_OF_DEBIT_CREDIT_NOTE_CLAIM;
  perform_shortage_adjustment_for_defective_url= RdsBilApiUrl.PERFORM_APPROVAL_OF_DEBIT_CREDIT_NOTE_DEFECTIVE;
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  print_url: string = RdsBilApiUrl.PRINT_DEBIT_NOTE;

  constructor(private _http: HttpClient) { }

  getPendingStockAdjustmentDetails(details) {
    return this._http.post(this.pending_shortage_adjustment_details_url, details);
  }

  getshortageAdjustmentDrpdwn(details) {
    return this._http.post(this.status_url, details);
  }

  pendingShortageApprovalReject(details,inventoryType) {
    let url:any;
    if(inventoryType == "CLAIMED"){
      url = this.pending_shortage_adjustment_approval_reject_url_claim;
    } else if(inventoryType == 'DEFECTIVE'){
      url = this.perform_shortage_adjustment_for_defective_url;
    } else{
      url = this.pending_shortage_adjustment_approval_reject_url;
    }
    return this._http.post(url, details);
  }

  getSizeDetails(details) {
    return this._http.post(this.size_url, details);
  }

  printDebitNote(details) {
    return this._http.post(this.print_url, details , httpOptions);
  }

}
