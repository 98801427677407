import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Observable, of, throwError, from } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { RequestParameterModel } from '../../common/request-parameter-model';
import { AsnLines } from './asn-lines';
import { AsnHeader } from './asn-header';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { UtilsService } from '../../common/utils-service';
import { ListOfBataWeek } from './list-of-bata-week';

@Injectable({
  providedIn: 'root'
})
export class SearchAsnService {

  asnUrl: string = RdsBilApiUrl.RDC_SEARCH_LINES;
  bataWeekURL: string = RdsBilApiUrl.RDC_BATA_WEEK_LIST;
  uploadfileURL: string = RdsBilApiUrl.UPLOAD_ASN_EXCEL_FILE;
  transporterName: string = RdsBilApiUrl.TRANSPORTER_NAME;
  transporterUpdateVal: string = RdsBilApiUrl.UPDATE_TRANSPORT;
  asnStatusList: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  config_details_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;

  form: FormGroup;
  frombataWeekzero: any;
  tobataWeekzero: any;

  constructor(private http: HttpClient, private utilsService: UtilsService ) { }
      getlistofbataWeeks(): Observable<ListOfBataWeek[]>  {
      return this.http.get<ListOfBataWeek[]>(this.bataWeekURL)
      .pipe(
        tap(data => (data)),
        catchError(this.handleError)
      );

    }

  getAsnbyAsnSearchCriteria(asnFormData) {
    const asnSearchParameters = new Array<RequestParameterModel>( );
    const jsonRequisition = JSON.stringify(asnFormData);
    const jsonObject = JSON.parse(jsonRequisition);


    // tslint:disable-next-line: forin
    for (const i in jsonObject) {
        // console.log(jsonObject);
        const requestParameterModel = new RequestParameterModel();

      if (
            jsonObject[i] !== "" &&
            jsonObject[i] !== null &&
            jsonObject[i] !== "undefined"
          ) {
            
          requestParameterModel.name = i;
          if ('tochallanInvoiceDT'.includes(requestParameterModel.name)) {
              requestParameterModel.value = this.utilsService.transformDateSearch(asnFormData.tochallanInvoiceDT);
            } else if ('fromchallanInvoiceDT'.includes(requestParameterModel.name)) {
              requestParameterModel.value = this.utilsService.transformDateSearch(asnFormData.fromchallanInvoiceDT);
            } else if ('fromchallanInvoiceDT'.includes(requestParameterModel.name)) {
              requestParameterModel.value = this.utilsService.transformDateSearch(asnFormData.fromchallanInvoiceDT);
            } else if ('tochallanInvoiceDT'.includes(requestParameterModel.name)) {
              requestParameterModel.value = this.utilsService.transformDateSearch(asnFormData.tochallanInvoiceDT);
             } 
            //else if ('fromWeekchallanInvoiceDT'.includes(requestParameterModel.name)) {
            //       requestParameterModel.value = BataWeekFromSearch;
            // } else if ('toWeekchallanInvoiceDT'.includes(requestParameterModel.name)) {
            //   requestParameterModel.value = BataWeekToSearch;
            // } else if ('toWeekchallanInvoiceDT'.includes(requestParameterModel.name)) {
            //   requestParameterModel.value = BataWeekToSearch;

            // } 
            else {
              requestParameterModel.value = jsonObject[i];
            }
            asnSearchParameters.push(requestParameterModel);

            // let frombataWeek: string = asnFormData.fromWeekchallanInvoiceDT;
            // let frombataYear: String  = asnFormData.fromWeekchallanInvoiceDT;

            /* if(frombataWeek.length < 2 ) {

                this.frombataWeekzero  =  "0" + frombataWeek;
                requestParameterModel.value = this.frombataWeekzero + "" +frombataYear ;

            }else{

              requestParameterModel.value = frombataWeek + "" +frombataYear ;
            }

          }else if ('toWeekchallanInvoiceDT'.includes(requestParameterModel.name)) {

              let tobataWeek =  asnFormData.toWeekchallanInvoiceDT;
              let tobataear = asnFormData.toWeekchallanInvoiceDT;
              if(tobataWeek.length < 2 ) {
                this.tobataWeekzero  =  "0" + tobataWeek;
                requestParameterModel.value = this.tobataWeekzero + "" +tobataear ;
              }else{
                requestParameterModel.value = tobataWeek + "" +tobataear ;
              }
              }
            else{
              requestParameterModel.value = jsonObject[i];
            }*/

      }
  }


    // console.log(asn_search_parameters);
    // console.log(' Log File : ListService  Function : getAsnbyAsnSearchCriteria : ASN Search Parameters :  '
    //   + JSON.stringify(asnSearchParameters));
    /* Prepare Request Parameter End */

    return this.http.post(this.asnUrl, asnSearchParameters);
  }


  private handleError(err) {
      // in a real world app, we may send the server to some remote logging infrastructure
      // instead of just logging it to the console
      let errorMessage: string;
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
      }
      console.error(err);
      return throwError(errorMessage);
  }

  getInventoryStatus() {
    const details: any = [{
      'name': 'paramName',
      'value': 'ASN_INVENTORY_STATUS'
  }]
    return this.http.post(this.config_details_url, details);
  }

  upload(formData) {

    return this.http.post(`${this.uploadfileURL}`, formData);

  }


  getASNAssortmentPopup(shipmentId: AsnHeader) {

    const asnpopupsearchparameters = new Array<RequestParameterModel>();
    const jsonRequisition = JSON.stringify({shipmentId});
    // console.log(jsonRequisition);

    const jsonObject = JSON.parse(jsonRequisition);
    // console.log(jsonObject);

      // tslint:disable-next-line: forin
    for (const i in jsonObject) {
        const requestParameterModel = new RequestParameterModel();
        if (jsonObject[i] !== '' && jsonObject[i] !== null && jsonObject[i] !== 'undefined') {
        requestParameterModel.name = i;
        requestParameterModel.value = jsonObject[i];
        asnpopupsearchparameters.push(requestParameterModel);

      }
    }
    // console.log(' Log File : SearchResupplyService  Function : getResupplyAssortmentPopup : Search Resupply Parameters :  '
    //   + JSON.stringify(asnpopupsearchparameters));
    /* Prepare Request Parameter End */

    return this.http.post(this.asnUrl, asnpopupsearchparameters);
  }


  getTranspoteList(requisitionFormData) {

    const ginsearchparameters = new Array<RequestParameterModel>( );
    const jsonRequisition = JSON.stringify(requisitionFormData);
    const jsonObject = JSON.parse(jsonRequisition);

    // tslint:disable-next-line: forin
    for (const i in jsonObject) {
        const requestParameterModel = new RequestParameterModel();
        // let myArray = {"asnStatus111": "OPEN123", "GINrfggd": "Y" ,i : jsonObject[i]};
        if (jsonObject[i] !== '' && jsonObject[i] !== null && jsonObject[i] !== 'undefined') {
                requestParameterModel.name = i;
                requestParameterModel.value = jsonObject[i];
                // console.log(i.length);
                ginsearchparameters.push(requestParameterModel);
        }

      }
    // console.log(ginsearchparameters);
    // console.log(' Log File : SearchRequisitionService  Function : getRequisitionByRequisitionSearchCriteria : Requisition Search Parameters :  '
    //   + JSON.stringify(ginsearchparameters));
    return this.http.post(this.transporterUpdateVal, ginsearchparameters);
  }


  getTransporterName() {
    const details = {
      isSecondary: 'Y' };
    return this.http.post(this.transporterName, details);
  }

  getAsnStatusList() {
    const status = [{name: 'paramName',
                      value: 'ASN_STATUS'
                    }];
    return this.http.post(this.asnStatusList, status);
  }


  setUpdatedData(updateedData) {
    return this.http.post(this.transporterUpdateVal , updateedData);
  }

}
