export class SearchForm {

    shopCode: string;
    artType: string;
    conceptCode: string;
    artNumber: string;
    stockType: string;
    userCode:string;
    categoryCode:string;
    subCategoryCode:string;
    searchType:string;
    pageNumber:Number;
    pageSize:Number;
    recordCount:any;




    constructor() { }
}