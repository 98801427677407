import { Component, OnInit ,Inject} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NavigationLinkParameterService } from '../common/navigation-link-parameters-service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { UtilsService } from '../common/utils-service';
import { SearchRequisitionService } from '../search-requisition/search-requisition.service';
import { Requisition } from '@/search-requisition/requisition';
import { RequestParameterModel } from '../common/request-parameter-model';
import { SearchRequisitionResultModel } from '../search-requisition/search-requisition-response-model';
import { RequisitionHeader } from '../search-requisition/requisition-header';
import { SearchPurchaseOrderService } from '../search-po/search-po.service';
import { SearchPoResponseModel } from '../common/search-po-response-model';
import { SearchGrnService } from '../rds/search-grn/search-grn.service';
import { GRNHeader } from '@/rds/search-grn/grn-header';

import { AuthenticationService } from '../services/authentication.service';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

import {Subject} from 'rxjs';
import { first,filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
 // styleUrls: ['./home.component.css']
 // tslint:disable-next-line: max-line-length
 styleUrls: ['../../assets/css/bootstrap.css', '../../assets/css/bil-style.css', '../../../node_modules/font-awesome/css/font-awesome.css', './home.component.css']
})
export class HomeComponent implements OnInit {

  totalPair: string;
  totalValue: string;
  totalCost: string;
  username:string;
  userRole:string;
  enable:boolean = false;
  userModule:string;
  grnNumber: string;
  resupNumber: string;
  invoiceNumber: string;
  searchGRNModel = new GRNHeader();
  asnNumber:string;
  profile:any;
  userPrincipalName:any;
  sessUsername:any;
  userRoleIndex:boolean=false;
  userAccess:boolean=true;
  checkUserVendorAccess = false;

  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor( private _navigationLinkParameterService: NavigationLinkParameterService,
    private _router: Router, private _utilsService: UtilsService,private _SearchRequisitionService:SearchRequisitionService,
    private _SearchPoService: SearchPurchaseOrderService, private _SearchGRNService: SearchGrnService,@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,private _http: HttpClient,private authenticationService: AuthenticationService) {

      console.log('HOME CALLING');
      this._http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        console.log(profile);
        this.profile = profile;
        console.log(this.profile.userPrincipalName);
        this.userPrincipalName=this.profile.userPrincipalName;
        let getAccountByUsername=this.authService.instance.getAccountByUsername(this.userPrincipalName);
        console.log(getAccountByUsername);
        this.getUserDetails(this.userPrincipalName);
        this.checkAndSetActiveAccount();
      });
     }

    ngOnInit() {
      // console.log('Calling');
      // this.checkAccount();
      // this._http.get(GRAPH_ENDPOINT)
      // .subscribe(profile => {
      //   console.log(profile);
      //   this.profile = profile;
      //   console.log(this.profile.userPrincipalName);
      //   this.userPrincipalName=this.profile.userPrincipalName;
      //   let getAccountByUsername=this.authService.instance.getAccountByUsername(this.userPrincipalName);
      //   console.log(getAccountByUsername);
      //   this.getUserDetails(this.userPrincipalName);
      // });
      
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        // console.log(result);
        // this.checkAccount();
       
      });
      
    //   let sessUsername = sessionStorage.getItem('currentUser');
      
    //   console.log(JSON.stringify(sessUsername));
    //    /* Modified for testing start*/
    //   if(typeof sessUsername.groupAssignmentModels !== 'undefined'){
    //     var conceptQueryMas = [{ "name": "email", "value": sessUsername.groupAssignmentModels[0].userCode }];
    //      this.username =  sessUsername.groupAssignmentModels[0].userCode;
    //      this.userRole=sessUsername.groupAssignmentModels[0].groupName.split(',');
    //      this.userModule =  sessUsername.groupAssignmentModels[0].userModule;
    //     // this.userModule='RDS';
    //  }
      // else{
      //   var conceptQueryMas = [{ "name": "email", "value": sessUsername[0].userName }];
      //   this.username =  sessUsername[0].userEmail;
      //   this.userRole=sessUsername[0].groupName.split(',');
      // }
      /* Modified for testing end*/
      }
    
  poNumber:string;
  reqNumber:string[];
  searchRequisitionHomeModal=new Requisition();
  requestParameterModel: RequestParameterModel[];
  
  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    // console.log(this.loggedIn);
    // if(this.loggedIn){
      this.getProfile();
    // }
    
  }
  checkAndSetActiveAccount(){
    let activeAccount=this.authService.instance.getActiveAccount();
    if(!activeAccount && this.authService.instance.getAllAccounts().length>0){
      let accounts=this.authService.instance.getAllAccounts();
      // console.log(accounts);
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
  getProfile() {
    this._http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        console.log(profile);
        this.profile = profile;
      });
  }

  getUserDetails(username){
    this.authenticationService.login(username)
    .pipe(first())
    .subscribe(
        data => {
          // console.log(JSON.stringify(data));
          this.sessUsername = data;
          console.log(this.sessUsername.groupAssignmentModels[0].userCode);
          if(typeof this.sessUsername.groupAssignmentModels[0] !== 'undefined'){
            var conceptQueryMas = [{ "name": "email", "value": this.sessUsername.groupAssignmentModels[0].userCode }];
             this.username =  this.sessUsername.groupAssignmentModels[0].userCode;
             this.userRole=this.sessUsername.groupAssignmentModels[0].groupName.split(',');
             console.log(this.userRole);
             if(this.userRole.indexOf('HOD')>=0 || this.userRole.indexOf('Ordering')>=0 || this.userRole.indexOf('OTB')>=0 || this.userRole.indexOf('Merchandising')>=0 || this.userRole.indexOf('Planning')>=0 || this.userRole.indexOf('Sourcing')>=0 || this.userRole.indexOf('IT')>=0){
              this.userRoleIndex = true;
             }

             if(this.userRole.indexOf('Vendor')>=0){
              this.checkUserVendorAccess = true;
             }
             
             this.userModule =  this.sessUsername.groupAssignmentModels[0].userModule;
            // this.userModule='RDS';
         }else{
          console.log('log1');
          this.userAccess=false;
        }
          /* Modified for testing start*/
          // if(typeof data.groupAssignmentModels !=='undefined'  ){
          //   // console.log('check');
          //   if(data.status!='false'){
          //   // console.log('LDAP');
          //     if(data != '' && data.groupAssignmentModels[0].activeFlag == 'A')
          //     {
          //       localStorage.setItem('refresfToken', "false");
          //     } else { 
          //       // this.messg = 'User Is Not Active. Please contact with Administrator.';
          //       this.alertService.success('User Is Not Active. Please contact Administrator.', true); 
                
          //     }
          //   }
          //   else{
          //     this.error = data.message;
          //   }

          // }
        },
        error => {
            // this.alertService.error(error);
            console.log(error);
            console.log('log2');
            this.userAccess=false;
        });

  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  
  searchRequisition() {
    if (this.searchRequisitionHomeModal.reqNumber != undefined) {
      this._SearchRequisitionService.getRequisitionByRequisitionSearchCriteria(this.searchRequisitionHomeModal, this.username).subscribe(
        (res: SearchRequisitionResultModel) => {
          //console.log(JSON.stringify(res));
          if (res != null) {
            this.totalCost = res.totalCost;
            this.totalPair = res.totalPair;
            this.totalValue = res.totalValue;
            let string = JSON.stringify(res.data);
            let obj = JSON.parse(string);
            obj[0].totalCost = this.totalCost;
            obj[0].totalPair = this.totalPair;
            obj[0].totalValue = this.totalValue;
            this.requestParameterModel = [];
            this.requestParameterModel = this._utilsService.createRequestParametersModelArray(obj[0]);
            this._utilsService.pageNavigationWithParam('requisition-details', this.requestParameterModel);
          }
        }
      );
    }
  }

  searchPurchaseOrder() {
    if (this.poNumber != undefined) {
      this._navigationLinkParameterService.id = this.poNumber;
      let details = {
        "poNumber": this.poNumber,
        "userId": this.username
      }
      this.requestParameterModel = [];
      this.requestParameterModel = this._utilsService.createRequestParametersModelArray(details);
      this._SearchPoService.getPoSearchFromHome(this.requestParameterModel).subscribe(
        (response: SearchPoResponseModel) => {
          //console.log(JSON.stringify(response));
          response.dataList.forEach(
            obj => {
              if (obj.poNumber === this.poNumber) {
                this._navigationLinkParameterService.podetails = obj;
                this._router.navigate(['update-po']);
              }
            }
          )
        }
      )
    }
  }
  searchASN() {
    if (this.asnNumber !== undefined) {
      let sessUsername = sessionStorage.getItem('currentUser');
        this._navigationLinkParameterService.id = this.asnNumber;
      let details = {
        'asnNumber':this.asnNumber,
        // 'supplyNo': '',
        // 'fromWeekchallanInvoiceDT': '',
        // 'toWeekchallanInvoiceDT': '',
        // 'fromchallanInvoiceDT': '',
        // 'tochallanInvoiceDT': '',
        // 'asnStatus': '',
        // 'consignorCode': '',
        // 'bataWeekTo':'',
        // 'bataWeekFrom':'',
        // 'yrWeekFrom':'string',
        // 'yrWeekTo':'',
        // 'partyNumber':'',
        // 'consignorType':'',
        // 'cartonNumber':'',
        'userCode': this.sessUsername.groupAssignmentModels[0].userCode
      }
      this._navigationLinkParameterService.asndetails = details;
      this._router.navigate(['rds-asn-search']);
      
    }
  }
  searchGRN() {
    if (this.grnNumber !== undefined) {
      let sessUsername = sessionStorage.getItem('currentUser');
      this._navigationLinkParameterService.id = this.grnNumber;
      let details = {
        'grnNumber': this.grnNumber,
        'invoiceNumber':'',
        'consignorCode':'',
        'userCode': this.sessUsername.groupAssignmentModels[0].userCode
      }
      this._navigationLinkParameterService.grndetails = details;
      this._router.navigate(['rds-search-grn']);
      
    }
  }
  searchReSupply(){
    if (this.resupNumber !== undefined) {
      let sessUsername = sessionStorage.getItem('currentUser');
      this._navigationLinkParameterService.id = this.resupNumber;
      let details = {
        'invoiceNO': this.resupNumber,
        'fromDate':'',
        'toDate':'',
        'stats':'',
        'concept':'',
        'sourceRDC':'',
        'ITONumber':'',
        'userCode': this.sessUsername.groupAssignmentModels[0].userCode
      }
       this._navigationLinkParameterService.resupdetails = details;
       this._router.navigate(['rds-resupply-status']);
    }
  }
  
  searchInvoice(){
    if (this.invoiceNumber !== undefined) {
      let sessUsername = sessionStorage.getItem('currentUser');
      this._navigationLinkParameterService.id = this.invoiceNumber;
      let details = {
        'challanInvoiceNumber': this.invoiceNumber,
        'invoiceStatus': null,
        'frominvoiceCreateDate': '',
        'toinvoiceCreateDate': '',
        'itnRsnSerialNumber': '',
        'destShopCode': null,
        'itoStnNumber': '',
        'transporterName':'',
        'bataWeekFrom':'',
        'bataWeekTo':'',
        'sourceShopCode':null,
        'routeNumber':null,
        'userCode': this.sessUsername.groupAssignmentModels[0].userCode
      }
       this._navigationLinkParameterService.invoicedetails = details;
       this._router.navigate(['rds-search-invoice']);
    }
  }
}
