import { Injectable } from '@angular/core';
import { RdsBilApiUrl } from '@/common/rds-bil-api-url';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimSlipsService {

  factoryUrl : string = RdsBilApiUrl.SHOP_MASTER_URL;
  RDCUrl : string = RdsBilApiUrl.SEARCH_LOCATION_URL;
  searchUrl : string = RdsBilApiUrl.SERACH_CLAIM_TXN;
  downloadUrl : string = RdsBilApiUrl.FETCH_CLAIM_TXN;

  constructor(private http: HttpClient) { }

  getFactory(details : any){
    return this.http.post(this.factoryUrl,details);
  }

  getRDCdata(details : any){
    return this.http.post(this.RDCUrl,details);
  }

  getSearchResult(details : any){
    return this.http.post(this.searchUrl,details);
  }

  // getDownloadTemplate(details : any) : Observable<any>{
  //   return this.http.post<Blob>(this.downloadUrl,details,{responseType: 'blob' as 'json'});
  // }

  public async getDownloadTemplate(details : any): Promise<void> {
    
     const file = await this.http
      .post<Blob>(this.downloadUrl,details, { responseType: "blob" as "json" })
      .toPromise();
    const blob = file;
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = 'ITOSTN_InterfaceTransfer_Factory_Return_Claimed' + ".xlsx";
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
  }
}
