import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../common/bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class ViewLocatonQuantityService {
  purchase_order_location_details_url: string = BilApiUrl.PO_LOCATION_DETAILS_URL;
  constructor(private _http: HttpClient, private httpService: HttpClient) { }


  getLocationServiceCriteria(searchArray) {
    return this._http.post(this.purchase_order_location_details_url, searchArray);
  }
}
