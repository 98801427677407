export class ViewShipmentReceiptDistribution{
    rdcCode:string;
    rdcName:string;
    orderedQuantity:string;
    shippedQuantity:string;
    receivedQuantity:string;
    outstandingQuantity:string;
    onwayQuantity:string;
    cancelledQuantity :string;
    constructor(){}
}