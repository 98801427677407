import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { BilApiUrl } from '../common/bil-api-url';
@Injectable({
  providedIn: 'root'
})
export class SearchBatchStatusService {
  urlBatch: string = BilApiUrl.BATCH_STATUS;
  constructor(private _http: HttpClient) { }

  getBatches(value: any) {
    return this._http.post(this.urlBatch, value);
  }
}