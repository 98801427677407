/*BATA WEEK START*/

/*PA DATE ERROR MESSAGE */
export const FROM_BATA_WEEK__REQUIRED : string = 'From Bata Week is required.';
export const TO_BATA_WEEK__REQUIRED : string = 'To Bata Week is required.';
export const BATA_WEEK_DATE__GREATER : string = 'To Bata Week must be greater than From Bata Week';

/* PR DATE ERROR MESSAGE 
export const FROM_PR_DATE__REQUIRED : string = 'From PR Date is required.';
export const TO_PR_DATE__REQUIRED : string = 'To PR Date is required.';
export const PR_DATE__GREATER : string = 'To PR Date must be greater than From PR Date';*/

/*REQUISITION END*/

/* ADMIN PAGES */
export const NEGATIVE : string = 'Negative numbers not allowed.';
