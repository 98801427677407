import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '../common/bil-api-url';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ImportArticlePriceService {

  apiUrl = BilApiUrl.COMMON_UPLOAD_API_URL;
  //apiUrl="http://01HW588664:8095/mdm/addimportarticleInterface ";
  purchase_order_details_url: string = BilApiUrl.IMPORT_ARTICLE_MASTER;
  url: string = BilApiUrl.IMPORT_ARTICLE_PRICE_MASTER;
  url1: string = BilApiUrl.IMPORT_ARTICLE_MASTER_PRICE_SAVE;
  URL: string = BilApiUrl.COMMON_DOWNLOAD_URL;
  urlForDownloadUrl: string = BilApiUrl.COMMON_CONFIG_FETCH_URL;
  //generateImportPo:string='http://130.61.219.89:8011/mdm/addimportarticleInterface';
  generateImportPo: string = BilApiUrl.GENERATE_FOB_PRICE_URL;
  constructor(private http: HttpClient) { }

  upload(details: any) {
    return this.http.post(`${this.apiUrl}`, details);
  }

  getImportServiceCriteria(details: any) {
    return this.http.post(this.purchase_order_details_url, details);
  }

  getImportService1Criteria(articleNumber: string) {
    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
    requestParameterModel.name = 'art_no';
    requestParameterModel.value = articleNumber;
    purchase_order_search_parameters.push(requestParameterModel);
    return this.http.post(this.url, purchase_order_search_parameters);
  }

  getImportServiceSaveCriteria(details: any) {
    return this.http.post(this.url1, details);
  }

  getDownloadFile() {
    return this.http.get(this.urlForDownloadUrl);
  }

  public getGenerate(uploadKeyValues: any) {
    return this.http.post(this.generateImportPo,uploadKeyValues, httpOptions);
  }

}






