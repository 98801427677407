import { Injectable } from '@angular/core';
// import { BilApiUrl } from '../../common/bil-api-url';
import { RdsBilApiUrl } from '../../common/rds-bil-api-url';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchRoleScreenAccessService {
  user_groupList_url: string = RdsBilApiUrl.USER_GROUP_LIST;
  screen_details_byId_url: string = RdsBilApiUrl.BIL_GROUP_SCREEN_DETAILS_BY_GROUPID;

  role_access_update_url: string = RdsBilApiUrl.SAVE_BILLGROUP_SCREEN_DETAILS;
  constructor(private _http: HttpClient) { }

  getGroupList(groupList) {
    console.log(groupList);
    return this._http.post(this.user_groupList_url, groupList);
  }
  updateRoleFlagStatus(details) {
    console.log('updating parameter' + JSON.stringify(details));
    return this._http.post(this.role_access_update_url, details, {
      observe: 'response',
      responseType: 'text'
    });
  }
  getBilGroupScreenDetailsByGroupId(groupParameter) {
    console.log('searching parameter' + JSON.stringify(groupParameter));
    return this._http.post(this.screen_details_byId_url, groupParameter);
  }
}
