import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { RequestParameterModel } from '../common/request-parameter-model';
import { BilApiUrl } from '../common/bil-api-url';
import { PO_STATUS } from '../common/field-name-list';

@Injectable({
  providedIn: 'root'
})
export class PendingExportComponentService {

  constructor(private _http: HttpClient) { }

  pending_export_url = BilApiUrl.GENERATE_PO_URL;
  approve_export_url=BilApiUrl.CREATE_PO_COMP_URL;

 
  getPendingPoList(pendingStatus:string) {

    let purchase_order_search_parameters = new Array<RequestParameterModel>();
    let requestParameterModel = new RequestParameterModel();
     requestParameterModel.name = "componentStatus";
     requestParameterModel.value = pendingStatus;
    purchase_order_search_parameters.push(requestParameterModel);
    return this._http.post(this.pending_export_url, purchase_order_search_parameters);
  }
  getServiceApprovedCriteria(details:any){
    return this._http.post(this.approve_export_url, details);
  }

}
