import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AsnHeader } from '../asn-header';
import { AsnLines } from '../asn-lines';
import { NavigationLinkParameterService } from '../../../common/navigation-link-parameters-service';
import { AsnUpdateService } from './asn-update.service';
import { UpdateStatusModel} from './update-status-model';
import { from } from 'rxjs';

@Component({
  selector: 'app-asn-update',
  templateUrl: './asn-update.component.html',
  styleUrls: ['./asn-update.component.css']
})
export class AsnUpdateComponent implements OnInit {
  searchedASNDetails = new AsnHeader();
  searchASNLineDetails: AsnLines[] = [];
  updateASNStatus = new UpdateStatusModel();
  updateRemarks: AsnLines[] = [];
  responsemessage: string;
  total = 0;
  disableBtn = false;

  // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: variable-name
  constructor(private _router: Router,
              // tslint:disable-next-line: variable-name
              private _navigationLinkParameterService: NavigationLinkParameterService,
              // tslint:disable-next-line: variable-name
              private _AsnUpdateService: AsnUpdateService) { }

  ngOnInit() {
    this.searchedASNDetails = this._navigationLinkParameterService.challanInvoicePoDetails;
    this._AsnUpdateService.getInvoiceLineDetails(this.searchedASNDetails.supplyNo).subscribe(
      (response: any) => {
        this.searchASNLineDetails = response.dataList;
         // this.loaDer.nativeElement.style.display = 'none';
        for (const data of this.searchASNLineDetails) {
          this.total  += data.cartonNumber;
          // console.log(this.total);
        }
    }
  );

  // this.searchASNLineDetails = this._navigationLinkParameterService.viewPOlist;
    console.log(this.searchASNLineDetails);
  }

  /*  ------------------------Back button method----------------------------- */

onClickBack() {
  this._router.navigate(['rds-asn-search']);
}


/*  ------------------------Back button method----------------------------- */


OnClickUpdateRemarks() {

    this.updateRemarks = [];
    this.searchASNLineDetails.forEach(
      eachObj => {
        const newObj: any = new UpdateStatusModel();
        newObj.remarks = this.updateASNStatus.remarks;
        // tslint:disable-next-line: no-string-literal
        newObj.shipmentId = eachObj['shipmentId'];
        newObj.asnStatus = 'CLOSED';
        this.updateRemarks.push(newObj);
      }
    );
    console.log(JSON.stringify(this.updateRemarks));
    this._AsnUpdateService.addpost(this.updateRemarks).subscribe(
      (res: any) => {
        console.log(res);
        // tslint:disable-next-line: triple-equals
        if (res[0].status == 'Success') {

        this.responsemessage = res[0].message;
        this.disableBtn = true;
      } else {
        this.responsemessage = res[0].message;
      }

    /*this._asnDetailService.getGinNo(this.searchedASNDetails.challanInvoiceNumber).subscribe(
      (res: any) => {
        this.ginNumberModel = res.dataList;
      }

    )*/
  });
  }

}
